import styled from 'styled-components';

export const Header = styled.header`
    width: 100%;
    height: 120px;
`;

export const HeaderTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 63px;
    padding: 0 32px;
`;

export const HeaderStepper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px auto;
    max-width: 1376px;
    @media (max-width: 1440px) {
        margin: 32px 32px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: start;
    width: 100%;
`;
