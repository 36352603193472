import React from 'react';
import {useGate, useUnit} from 'effector-react';
import {add} from 'date-fns';
import {Button, DatePicker, Progress, TextField, Typography} from '@beeline/design-system-react';

import {TitleWithBackButton} from 'Features/TitleWithBackButton';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {Wrapper} from 'shared/styles/commonStyle';
import {TTaskList} from 'shared/types/tasksTypes';
import {CANCEL_BUTTON_TITLE} from 'shared/constants';
import {formatDateToBackendFormat} from 'shared/helpers/formatHelper';

import {
    $closingDocumentsCreationStore,
    $errorStore,
    ClosingDocumentCreationGate,
    invoiceDateChanged,
    invoiceDocNumberChanged,
    ks2DateChanged,
    ks2DocNumberChanged,
    ks3DocNumberChanged,
    positiveButtonClicked,
} from '../model/closingDocuments';
import {ETaskTypeConfirmations} from '../../DSPage/types';

interface ClosingDocumentsCreationProps {
    id: string | undefined;
    taskData: TTaskList;
    handleClose: () => void;
}

interface DocumentPropertiesProps {
    title: string;
    datePickerValue: string;
    isLoading: boolean;
    textFieldValue: string;
    onChangeDate: () => void;
    onChangeNumber: (value: string) => void;
    minDate?: string;
    maxDate?: string;
    dateError: boolean;
    textError: boolean;
    disabled?: boolean;
}

export const ClosingDocumentsCreation = ({
    id,
    taskData,
    handleClose,
}: ClosingDocumentsCreationProps) => {
    useGate(ClosingDocumentCreationGate, id);
    const [
        {
            ks2Date,
            ks3Date,
            invoiceDate,
            invoiceDocNumber,
            ks3DocNumber,
            ks2DocNumber,
            formTouched,
            isLoading,
            shouldCloseWidget,
            invoiceDataShowed,
            initialLoading,
        },
        {
            ks2DateError,
            ks2DocNumberError,
            ks3DateError,
            ks3DocNumberError,
            invoiceDateError,
            invoiceDocNumberError,
        },
    ] = useUnit([$closingDocumentsCreationStore, $errorStore]);
    const workflowData = taskData[0];
    const signButtonData = workflowData.actions.find(
        (item) => item.is_positive && item.type_confirmation === ETaskTypeConfirmations.KZD,
    );
    const signButtonIndex = workflowData.actions.findIndex(
        (item) => item.is_positive && item.type_confirmation === ETaskTypeConfirmations.KZD,
    );
    if (shouldCloseWidget) {
        handleClose();
    }
    const maxDate = formatDateToBackendFormat(new Date(Date.now()).toISOString());

    return (
        <Wrapper>
            <TitleWithBackButton
                title="Создать комплект закрывающих документов"
                onClick={handleClose ?? (() => 1)}
            />
            {initialLoading ? (
                <WrapperFlex height="300px" justifyContent="center" alignItems="center">
                    <Progress shape="circle" cycled />
                </WrapperFlex>
            ) : (
                <>
                    <DocumentProperties
                        title="Введите данные по документу КС-2"
                        datePickerValue={ks2Date}
                        textFieldValue={ks2DocNumber}
                        onChangeDate={ks2DateChanged}
                        onChangeNumber={ks2DocNumberChanged}
                        dateError={formTouched && ks2DateError}
                        textError={formTouched && ks2DocNumberError}
                        isLoading={isLoading}
                        maxDate={maxDate}
                        disabled
                    />
                    <DocumentProperties
                        title="Введите данные по документу КС-3"
                        datePickerValue={ks3Date}
                        textFieldValue={ks3DocNumber}
                        onChangeDate={ks2DateChanged}
                        onChangeNumber={ks3DocNumberChanged}
                        dateError={formTouched && ks3DateError}
                        textError={formTouched && ks3DocNumberError}
                        isLoading={isLoading}
                        maxDate={maxDate}
                        disabled
                    />
                    {invoiceDataShowed && (
                        <DocumentProperties
                            title="Введите данные по документу Счет-фактура"
                            datePickerValue={invoiceDate}
                            textFieldValue={invoiceDocNumber}
                            onChangeDate={invoiceDateChanged}
                            onChangeNumber={invoiceDocNumberChanged}
                            minDate={formatDateToBackendFormat(
                                add(new Date(), {days: -1}).toISOString(),
                            )}
                            maxDate={formatDateToBackendFormat(
                                add(new Date(), {days: 5}).toISOString(),
                            )}
                            dateError={formTouched && invoiceDateError}
                            textError={formTouched && invoiceDocNumberError}
                            isLoading={isLoading}
                        />
                    )}

                    <WrapperFlex gap="16px" alignSelf="flex-end">
                        <Button variant="outlined" size="medium" onClick={handleClose}>
                            {CANCEL_BUTTON_TITLE}
                        </Button>
                        {!!signButtonData && (
                            <Button
                                variant="contained"
                                size="medium"
                                onClick={() => positiveButtonClicked(signButtonIndex)}
                                disabled={isLoading}
                            >
                                {signButtonData.name}
                            </Button>
                        )}
                    </WrapperFlex>
                </>
            )}
        </Wrapper>
    );
};

const DocumentProperties = ({
    title,
    datePickerValue,
    isLoading,
    textFieldValue,
    onChangeDate,
    onChangeNumber,
    dateError,
    textError,
    minDate,
    maxDate,
    disabled,
}: DocumentPropertiesProps) => {
    const documentDateHelper = dateError ? 'Введите корректную дату' : undefined;
    const documentNumberHelper = textError ? 'Введите номер документа' : undefined;
    return (
        <WrapperFlex flexDirection="column" gap="16px">
            <Typography variant="h5">{title}</Typography>
            <WrapperFlex gap="16px">
                <DatePicker
                    className="fieldHelperPosition"
                    label="Дата документа"
                    value={datePickerValue}
                    onChange={onChangeDate}
                    helperText={documentDateHelper}
                    helperPosition="absolute"
                    error={dateError}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled={isLoading || disabled}
                />
                <TextField
                    className="fieldHelperPosition"
                    label="Номер документа"
                    value={textFieldValue}
                    onChange={(e) => onChangeNumber(e.currentTarget.value)}
                    helperText={documentNumberHelper}
                    helperPosition="absolute"
                    error={textError}
                    disabled={isLoading}
                />
            </WrapperFlex>
        </WrapperFlex>
    );
};
