export const createLink = (
    objectType: number,
    objectId: string,
    additionalAgreementNumber?: number | string,
) => {
    let link = `/additional-agreements/${objectId}`;
    switch (objectType) {
        case 1: {
            link = `/jobs/${objectId}`;
            break;
        }
        case 2: {
            link = `/additional-agreements/${objectId}`;
            break;
        }
        case 3: {
            link = `/documents/${objectId}`;
            break;
        }
        case 4: {
            link = `/additional-agreements/${objectId}/${additionalAgreementNumber ?? ''}`;
            break;
        }
        case 5: {
            link = `/completed-works/${objectId}`;
            break;
        }
    }
    return link;
};
