import styled from 'styled-components';
import {Typography} from '@beeline/design-system-react';

export const HeaderBlock = styled.header`
    background-color: var(--color-background-base);
    position: sticky;
    top: 0;
    z-index: 1;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    height: var(--toplineHeight);
    justify-content: space-between;
`;

export const LogoBlock = styled.div`
    display: flex;
    align-items: center;

    a {
        height: 25px;
    }

    & > * {
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const BtnBlock = styled.div`
    display: flex;
    align-items: center;

    & > * {
        margin-right: 24px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const Logo = styled.div`
    height: 25px;
    width: 104px;
    display: inline-block;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;
export const Notification = styled.div`
    background-color: transparent;
    border: none;
    padding: 0;
    position: relative;
    width: 24px;
    height: 24px;

    .icon {
        width: 100%;
        height: 100%;
        color: #5e6171;
    }
`;

export const InvisibleTypography = styled(Typography)`
    color: transparent;
`;
