import React from 'react';
import {
    TableStyled,
    TableStringStyled,
    TableCellStyled,
    TableHeadStyled,
    TableCellContentStyled,
    TableStringSelectedStyled,
} from 'shared/ui/Table/style';

export function Table(props) {
    const {children, minWidth, ...rest} = props;
    return (
        <TableStyled cellSpacing={0} minWidth={minWidth}>
            {children}
        </TableStyled>
    );
}
export function TableString(props) {
    const {children, ...rest} = props;
    return <TableStringStyled {...props}>{children}</TableStringStyled>;
}

export function TableStringSelected(props) {
    const {children, ...rest} = props;
    return <TableStringSelectedStyled {...props}>{children}</TableStringSelectedStyled>;
}

export function TableCell(props) {
    const {children, width, height, minWidth, maxWidth, colspan, rowspan, ...rest} = props;
    return (
        <TableCellStyled
            width={width}
            colSpan={colspan}
            rowSpan={rowspan}
            minWidth={minWidth}
            maxWidth={maxWidth}
            height={height}
            {...rest}
        >
            {children}
        </TableCellStyled>
    );
}

export function TableHead(props) {
    const {children, right, ...rest} = props;
    return <TableHeadStyled right={right}>{children}</TableHeadStyled>;
}

export function TableCellContent(props) {
    const {children, right, column, ...rest} = props;
    return (
        <TableCellContentStyled right={right} column={column} {...rest}>
            {children}
        </TableCellContentStyled>
    );
}
