import styled from 'styled-components/macro';
import {Button} from '@beeline/design-system-react';

export const BaseStationInfoWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 24px;
`;

export const AttachmentsBlock = styled.div`
    max-width: 1376px;
    display: flex;
    gap: 24px;
`;

export const FilesBlock = styled.div`
    flex: 1 1 627px;
`;

export const CommentsBlock = styled.div`
    flex: 1 1 627px;
`;

export const AddCommentUnit = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledButton = styled(Button)`
    align-self: flex-end;
`;

export const HeaderInfoWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
`;
