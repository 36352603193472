import React, {useState} from 'react';
import {Typography} from '@beeline/design-system-react';

import {
    IAdaptedCompletedWorksSpecification,
    TCompletedWorkSpecificationMode,
} from 'shared/types/completedWorksTypes';
import {formatNumber} from 'shared/helpers/formatHelper';

import * as S from './style';
import {TooltipedWrapper} from '../../../../../../../shared/ui/TooltipedWrapper';

type QuantityCellProps = Pick<
    IAdaptedCompletedWorksSpecification,
    'quantity' | 'accuracy' | 'quantity_max' | 'quantity_completed' | 'quantity_full'
> & {
    onChange: (quantity: string) => void;
    disabled: boolean;
    modifyAllowed: boolean;
    mode: TCompletedWorkSpecificationMode;
};

export const QuantityCell = ({
    quantity,
    accuracy,
    quantity_completed,
    quantity_max,
    quantity_full,
    onChange,
    mode,
    modifyAllowed,
    disabled,
}: QuantityCellProps) => {
    const usedQuantity = formatNumber(
        +(mode === 'closed'
            ? quantity_completed
            : +quantity > quantity_max
            ? quantity_max
            : quantity),
        accuracy,
    ).toString();
    const closedQuantity = mode === 'closed' ? quantity_full : quantity_max;

    const [localQuantity, setLocalQuantity] = useState(usedQuantity);

    const handleSetQuantity = (value: string) => {
        const fractionalLength = value.split('.')[1]?.length ?? 0;
        const exceptionList = ['0.0', '0.00', '0.000'];
        let result = exceptionList.includes(value) ? value : +value;
        if (fractionalLength > accuracy) {
            const array = value.split('.');
            array[1] = array[1]?.slice(0, accuracy);
            result = +array.join('.');
        }

        setLocalQuantity(result.toString());
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        let result = +e.target.value;

        if (result > quantity_max) {
            result = quantity_max;
        } else if (result <= 0) {
            result = 1 / Math.pow(10, accuracy);
        }
        setLocalQuantity(result.toString());
        onChange(result.toString());
    };

    return (
        <S.EditableCell alignRight width="101px" $isEditable={!disabled && modifyAllowed}>
            <TooltipedWrapper title={modifyAllowed ? null : 'Редактирование запрещено'}>
                <label>
                    <div className="wrapper">
                        <S.StyledInput
                            value={localQuantity}
                            onChange={(e) => handleSetQuantity(e.target.value)}
                            onBlur={handleOnBlur}
                            onKeyDownCapture={(e) => {
                                if (e.key === 'Enter') {
                                    // @ts-ignore
                                    handleOnBlur(e);
                                }
                            }}
                            type="number"
                            disabled={disabled || !modifyAllowed}
                        />
                        {quantity_max > 0 && (
                            <Typography variant="body3" className="inactiveText">
                                {`Из ${closedQuantity.toFixed(accuracy)}`}
                            </Typography>
                        )}
                    </div>
                </label>
            </TooltipedWrapper>
        </S.EditableCell>
    );
};
