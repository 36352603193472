import {TExtended, TExtendedContractsPage, TExtendedProcessesPage} from 'shared/types/userTypes';
import {IVisibleColumn} from 'shared/types/tableTypes';
import {TTaskParams} from 'shared/services/types/taskParamsTypes';
import {EAccessActions} from 'shared/const/actions';

export const getInitialProcessesPageData = (): TExtendedProcessesPage => {
    return {
        filters: getInitialProcessesPageFilters(),
        tasksColumns: getInitialTasksColumns(),
        processesColumns: getInitialProcessesColumns(),
    };
};

export const getInitialContractsPageData = (): TExtendedContractsPage => {
    return {
        filters: {
            page: 1,
            page_size: 10,
            actionDate: '',
            contractor: '',
        },
        columns: getInitialContractTableColumns(),
    };
};

export const getInitialProcessesPageFilters = (): TTaskParams => ({
    groupByProcesses: false,
    user_tasks: false,
    uncompleted_tasks: true,
    executor_tasks: true,
    page: 1,
    page_size: 10,
    region: '',
    branch: '',
    search: '',
    subtypes_document: '',
    status_document: '',
    status: '',
    gpo: '',
    created_at: '',
    approved_at: '',
});

export const getInitialTasksColumns = (): IVisibleColumn[] => [
    {id: 'name', name: 'Наименование', visible: true},
    {id: 'process__project__project_ext_id', name: 'Проект', visible: true},
    {
        id: 'process__project__bs_number',
        name: 'Базовая станция',
        visible: true,
    },
    {
        id: 'process__subtypes_document__name',
        name: 'Тип документа',
        visible: true,
    },
    {id: 'object_number', name: '№ документа', visible: true},
    {id: 'executor', name: 'Исполнитель', visible: true},
    {id: 'created_at', name: 'Создано', visible: true},
    {id: 'approved_at', name: 'Завершено', visible: true},
    {id: 'region', name: 'Регион', visible: true},
    {id: 'branch', name: 'Филиал', visible: true},
    {id: 'status', name: 'Статус задачи', visible: true},
    {id: 'task_number', name: '№ задачи', visible: true},
];

export const getInitialProcessesColumns = (): IVisibleColumn[] => [
    {
        id: 'name',
        name: 'Наименование',
        visible: true,
    },
    {id: 'project__project_ext_id', name: 'Проект', visible: true},
    {
        id: 'project__bs_number',
        name: 'Базовая станция',
        visible: true,
    },
    {id: 'subtypes_document__name', name: 'Тип документа', visible: true},
    {
        id: 'object_number',
        name: '№ документа',
        visible: true,
    },
    {id: 'documentStatus', name: 'Статус документа', visible: true},
    {
        id: 'created_at',
        name: 'Создано',
        visible: true,
    },
    {id: 'approved_at', name: 'Завершено', visible: true},
    {
        id: 'region',
        name: 'Регион',
        visible: true,
    },
    {id: 'branch', name: 'Филиал', visible: true},
];

export const getInitialContractTableColumns = (): IVisibleColumn[] => [
    {
        id: 'number',
        name: 'Номер',
        visible: true,
    },
    {
        id: 'contractor',
        name: 'ГПО',
        visible: true,
    },
    {
        id: 'KZP',
        name: 'КЗП',
        visible: true,
    },
    {
        id: 'purchasing_event_number',
        name: '№ ЗМ',
        visible: true,
    },
    {
        id: 'conclusion_date',
        name: 'Заключен',
        visible: true,
    },
    {
        id: 'validity_date',
        name: 'Срок действия',
        visible: true,
    },
    {
        id: 'region',
        name: 'Регион',
        visible: true,
    },
    {
        id: 'external_link',
        name: 'Ссылка',
        visible: true,
        action: EAccessActions.ContractListLinkShowing,
    },
];

export const checkContractsPageValidity = (store: TExtended): boolean => {
    const {contractsPage} = store;
    const isValidColumns =
        Array.isArray(contractsPage.columns) &&
        contractsPage.columns.length === getInitialContractTableColumns().length;
    const isValidFilters = !!contractsPage.filters;
    return isValidFilters && isValidColumns;
};
