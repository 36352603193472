import React from 'react';
import {Link} from 'react-router-dom';
import {Label, Typography} from '@beeline/design-system-react';

import {InformationalUnit} from 'Entities/InformationalUnit';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {TAdaptedCompletedWorksAgreement} from 'shared/types/completedWorksTypes';
import {
    ADDITIONAL_AGREEMENT_FULL_TITLE,
    CONTRACT_TITLE,
    CURRENT_COMPLETED_WORKS_DONE_TITLE,
    PREV_COMPLETED_WORKS_DONE_TITLE,
    PROJECT_TITLE,
} from 'shared/constants';

interface HeaderProps {
    agreement: TAdaptedCompletedWorksAgreement;
    currentCompletion: number;
    prevCompletion: number;
}

export const Header = ({agreement, currentCompletion, prevCompletion}: HeaderProps) => {
    const {id, number, project, contract} = agreement;

    const currentCompletionType = currentCompletion + prevCompletion > 99 ? 'success' : 'warning';
    const prevCompletionType = 'info';
    return (
        <>
            <Typography variant="h4">Ведомости выполненных работ</Typography>
            <WrapperFlex justifyContent="flex-end">
                <WrapperFlex gap="24px">
                    <InformationalUnit title={PROJECT_TITLE}>
                        <Typography variant="body2">
                            <Link to={`/projects/${project.id}`} target="_blank">
                                {project.project_ext_id}
                            </Link>
                        </Typography>
                    </InformationalUnit>
                    <InformationalUnit title={ADDITIONAL_AGREEMENT_FULL_TITLE}>
                        <Typography variant="body2">
                            <Link to={`/additional-agreements/${id}`} target="_blank">
                                {number}
                            </Link>
                        </Typography>
                    </InformationalUnit>
                    <InformationalUnit title={CONTRACT_TITLE}>{contract.number}</InformationalUnit>
                    <InformationalUnit title={CURRENT_COMPLETED_WORKS_DONE_TITLE}>
                        <Label title={`${currentCompletion}%`} type={currentCompletionType} />
                    </InformationalUnit>
                    <InformationalUnit
                        title={PREV_COMPLETED_WORKS_DONE_TITLE}
                        children={<Label title={`${prevCompletion}%`} type={prevCompletionType} />}
                    />
                </WrapperFlex>
            </WrapperFlex>
        </>
    );
};
