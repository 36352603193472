import React, {Dispatch, SetStateAction} from 'react';
import {Typography} from '@beeline/design-system-react';

import {TableCellContent} from 'shared/ui/Table/Table';
import {SelectItem} from 'shared/ui/Select/Select';
import {
    PurchasingEventStatusEnum,
    TPurchasingEventInfoForStage1,
} from 'shared/types/purchasesTypes';
import {NonMvpItem, StyledSpan} from 'shared/styles/commonStyle';
import {formatDate} from 'shared/helpers/formatHelper';
import {TTpiWithRemoteTerritory} from 'shared/types/tpiTypes';

import * as S from './style';
import * as D from '../style';

interface PrimeContractorsTableRowProps {
    index: number;
    data: TPurchasingEventInfoForStage1;
    selectedTpiData: Record<string, TTpiWithRemoteTerritory>;
    setSelectedTpiData: (props: Record<string, TTpiWithRemoteTerritory>) => void;
    disabled: boolean;
    setDisabled: Dispatch<SetStateAction<boolean>>;
    selectError: string | null;
}

export const PrimeContractorsTableRow = ({
    index,
    data,
    selectedTpiData,
    setSelectedTpiData,
    disabled,
    setDisabled,
    selectError,
}: PrimeContractorsTableRowProps) => {
    const {
        bidding_participation: {
            id,
            amount,
            percent,
            status,
            organization_short_name,
            organization_inn,
            purchase_lot: {name: purchase_lot_name},
            rank,
            is_screening,
            tpis,
            tpiList,
        },
        purchasing_event: {type, number},
        contract: {date_conclusion, number: ContractNumber, signatory},
    } = data;

    const getStatusTitle = (): string => {
        switch (status) {
            case PurchasingEventStatusEnum.WIN:
                return 'Победитель';
            case PurchasingEventStatusEnum.PRE_WIN:
                return 'Условный победитель';
            case PurchasingEventStatusEnum.RES:
                return 'Выведен из резерва';
            case PurchasingEventStatusEnum.EXCL:
                return 'Исключен';
            default:
                return '';
        }
    };

    const handleSelectValue = (value) => {
        setSelectedTpiData({
            [id]: value,
        });
    };

    const hasSelectError = selectError && selectError === id;

    return (
        <S.RowWrapper>
            <D.CustomTableRow id={`table_row_${index}`}>
                <D.CustomTableCell flex="0 0 102px">
                    <Typography variant="body3">{type}</Typography>
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 100px">
                    <Typography variant="body3">{number}</Typography>
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 80px">
                    <Typography variant="body3">{purchase_lot_name}</Typography>
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 148px" alignItems="flex-end" noPadding>
                    {(tpiList?.length ?? -1) > 0 && (
                        <S.StyledSelect
                            value={selectedTpiData[id] ?? null}
                            valueKey="id"
                            valueLabel="name"
                            title="ТЦП"
                            handleEdit={handleSelectValue}
                            error={hasSelectError}
                            full
                            fullWidth
                        >
                            {tpiList?.map((item, index) => (
                                <SelectItem
                                    key={`${id}${item.id}${item.remote_territory_id}${index}`}
                                    value={item}
                                    selected={[]}
                                    multiple={false}
                                >
                                    {item.name}
                                </SelectItem>
                            ))}
                        </S.StyledSelect>
                    )}
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 210px">
                    <TableCellContent>
                        <S.StyledSpan>
                            <Typography variant="body3">{organization_short_name}</Typography>
                            <Typography className="inactiveText" variant="body3">
                                {organization_inn}
                            </Typography>
                        </S.StyledSpan>
                    </TableCellContent>
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 114px">
                    <Typography variant="body3">{getStatusTitle()}</Typography>
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 202px">
                    <S.StyledSpan>
                        <Typography variant="body3">
                            {ContractNumber} от {formatDate(date_conclusion)}
                        </Typography>
                    </S.StyledSpan>
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 172px">
                    <StyledSpan lineCount={2}>
                        <Typography variant="body3">{signatory}</Typography>
                    </StyledSpan>
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 148px" alignItems="flex-end">
                    <NonMvpItem>
                        {/*<TableCellContent right>{availableAmount.toLocaleString()}</TableCellContent>*/}
                    </NonMvpItem>
                    <Typography variant="body3">{amount.toLocaleString()}</Typography>
                </D.CustomTableCell>
            </D.CustomTableRow>
        </S.RowWrapper>
    );
};
