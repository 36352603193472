import React, {useState, useRef, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {PortalStyled, PortalWrapperStyled} from 'shared/ui/deprecated/Portal/style';

export function Portal(props) {
    const {
        children,
        top,
        left,
        right,
        bottom,
        fullWidth,
        visible,
        vertical,
        horyzontal,
        position = 'top-start',
        select = false,
        rootEl,
        ...rest
    } = props;

    const elem = useRef(null);

    const [widthParent, setWidthParent] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        setWidth(elem.current.childNodes[0].getBoundingClientRect().width);
        setHeight(elem.current.childNodes[0].getBoundingClientRect().height);
        if (rootEl.current) {
            setWidthParent(rootEl?.current.getBoundingClientRect().width);
        }
    }, [visible]);

    return createPortal(
        <PortalStyled
            data-portal
            {...props}
            visible={visible}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            position={position}
            ref={elem}
            width={width}
            height={height}
            widthParent={widthParent}
            fullWidth={fullWidth}
        >
            {children}
        </PortalStyled>,
        document.body,
    );
}

export function PortalTrigger(props) {
    const {children, ...rest} = props;
    return <div {...props}>{children}</div>;
}

export default function PortalWrapper(props) {
    const {
        children,
        type = 'hover',
        portal,
        active,
        vertical = 20,
        horyzontal = 20,
        position = 'top-start',
        trigger,
        elem,
        fullWidth,
        setActive = () => {},
        select = false,
        ignore,
        disabled,
        ...rest
    } = props;

    const rootEl = useRef(null);

    const [visible, setVisible] = useState(false);
    const [tPos, setTPos] = useState(0);
    const [lPos, setLPos] = useState(0);
    const [rPos, setRPos] = useState(0);
    const [bPos, setBPos] = useState(0);

    const handleOver = (e) => {
        setVisible(true);
        const top =
            rootEl.current.getBoundingClientRect().top +
            window.scrollY +
            rootEl.current.getBoundingClientRect().height;
        const bottom = rootEl.current.getBoundingClientRect().top + window.scrollY;
        const left = rootEl.current.getBoundingClientRect().left + window.scrollX;
        const right =
            rootEl.current.getBoundingClientRect().left +
            window.scrollX +
            rootEl.current.getBoundingClientRect().width;
        setTPos(top);
        setLPos(left);
        setRPos(right);
        setBPos(bottom);
    };

    const handleOut = () => {
        setVisible(false);
        setTPos(0);
        setLPos(0);
        setRPos(0);
    };

    const handleClick = (e) => {
        let checks = 0;
        if (ignore) {
            ignore.map((item) => {
                if (item.current.contains(e.target)) {
                    checks += 1;
                }
            });
        }
        if (checks == 0) {
            if (!visible) {
                handleOver();
            } else {
                handleOut();
            }
        }
    };

    const isOpen = useRef(visible);

    useEffect(() => {
        isOpen.current = visible;
        setActive(visible);
    }, [visible]);

    useEffect(() => {
        if (active != undefined) {
            setVisible(active);
        }
    }, [active]);

    const onClose = () => {
        setVisible(false);
    };

    const onClick = (e) => {
        if (isOpen?.current && !elem?.current.contains(e.target)) {
            onClose();
            e.stopPropagation();
        }
    };

    useEffect(() => {
        document.addEventListener('click', onClick, {capture: true});
        return () => document.removeEventListener('click', onClick, {capture: true});
    }, []);

    return (
        <PortalWrapperStyled
            onMouseOver={type === 'hover' ? handleOver : undefined}
            onMouseOut={type === 'hover' ? handleOut : undefined}
            ref={rootEl}
            {...props}
        >
            {children}
            <Portal
                visible={visible}
                top={tPos}
                left={lPos}
                right={rPos}
                bottom={bPos}
                position={position}
                vertical={vertical}
                horyzontal={horyzontal}
                select={select}
                rootEl={rootEl}
                fullWidth={fullWidth}
            >
                {portal}
            </Portal>
            {type === 'click' && (
                <PortalTrigger onClick={!disabled ? handleClick : () => 1}>{trigger}</PortalTrigger>
            )}
        </PortalWrapperStyled>
    );
}
