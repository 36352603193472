import styled from 'styled-components/macro';
import Search from 'shared/ui/Search/Search';

export const SearchStyled = styled(Search)`
    max-width: 400px;
    flex-basis: 400px;

    && input:focus {
        padding-right: 12px;
    }
`;

export const SearchBox = styled.div`
    flex-basis: 400px;
    margin-top: 32px;
    margin-bottom: 28px;
`;
