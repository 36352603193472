import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';
import {Option} from '@beeline/design-system-react';

import {fetchAgreementListForCompletedWorksCreation} from 'shared/services/completedWorks.service';
import {ICompletedWorksAgreement} from 'shared/types/completedWorksTypes';

import {changeSelectedOptions, convertAgreementToSelectList} from './helpers';

export interface IWorksheetOption extends Option<string> {
    checked: boolean;
    disabled: boolean;
    offset?: string;
}

export const CompletedWorksStage1Domain = createDomain();
export const CompletedWorksStage1Gate = createGate<string | undefined>();

// effects
export const getAgreementListFx = CompletedWorksStage1Domain.createEffect(
    async (projectId: string) => {
        const result = await fetchAgreementListForCompletedWorksCreation(projectId);
        return result.data;
    },
);
// events
export const agreementSelected = CompletedWorksStage1Domain.createEvent<Option<string>[]>();
export const worksheetSelected = CompletedWorksStage1Domain.createEvent<IWorksheetOption[]>();
export const worksheetOptionSelected = CompletedWorksStage1Domain.createEvent<IWorksheetOption>();

// stores
const $rawAgreementList = CompletedWorksStage1Domain.createStore<ICompletedWorksAgreement[]>([]);
const $agreementList = CompletedWorksStage1Domain.createStore<Option<string>[]>([]);
const $worksheetList = CompletedWorksStage1Domain.createStore<IWorksheetOption[]>([]);
const $selectedAgreementList = CompletedWorksStage1Domain.createStore<Option<string>[]>([]);
const $selectedWorksheetList = CompletedWorksStage1Domain.createStore<IWorksheetOption[]>([]);
const $agreementListDisabled = getAgreementListFx.pending;

export const $stage1Store = combine({
    agreementList: $agreementList,
    selectedAgreementList: $selectedAgreementList,
    worksheetList: $worksheetList,
    selectedWorksheetList: $selectedWorksheetList,
    agreementListDisabled: $agreementListDisabled,
    worksheetListDisabled: $selectedAgreementList.map((state) => state.length === 0),
    positiveButtonDisabled: $selectedWorksheetList.map((state) => state.length === 0),
});

export const $stage1ToStage2Store = combine({
    selectedAgreementList: $selectedAgreementList,
    selectedWorksheetList: $selectedWorksheetList,
});

sample({
    clock: CompletedWorksStage1Gate.state,
    filter: (gate) => typeof gate === 'string',
    fn: (gate) => gate ?? '',
    target: getAgreementListFx,
});

sample({
    clock: getAgreementListFx.doneData,
    target: $rawAgreementList,
});

// заполнения списка доступных ДС для создания ВВР
$agreementList.on($rawAgreementList, (_, agreementList) =>
    agreementList.map((item) => ({
        id: item.id,
        value: item.number,
    })),
);

// предзаполнение селекта, если доступен только один ДС
sample({
    source: $agreementList,
    filter: (source) => source.length === 1,
    target: $selectedAgreementList,
});

sample({
    clock: agreementSelected,
    target: $selectedAgreementList,
});

// формирование списка ведомостей выбранного ДС
sample({
    clock: $selectedAgreementList,
    source: $rawAgreementList,
    fn: (rawList, selectedAgreementList) =>
        convertAgreementToSelectList(rawList, selectedAgreementList),
    target: $worksheetList,
});

sample({
    source: $worksheetList,
    target: $selectedWorksheetList,
});

// логика отображения выделения чекбоксов в селекете ВИР
sample({
    clock: worksheetOptionSelected,
    source: combine({
        initialList: $worksheetList,
        prevList: $selectedWorksheetList,
    }),
    fn: ({initialList, prevList}, item) => changeSelectedOptions(initialList, prevList, item),
    target: $selectedWorksheetList,
});
