import styled, {css} from "styled-components";

const InputBlockWrapMixin = (props) => css`
  ${props.disabled && css`
    pointer-events: none;
  `}

`
export const InputBlockWrap = styled.div`
  ${(props) => InputBlockWrapMixin(props)}
`

const InputLabelMixin = props => css`
  position: absolute;
  overflow: hidden;
  display: none;
  left: 33px;
  color: rgba(25, 28, 52, 0.7);
  z-index: 2;
  ${(props.leftIcon && props.size === 'medium') && IconMediumPosition};
  ${props.leftIcon && props.size === 'large' && IconLargePosition};
  ${props.filled && css`
    display: block;
    top: 24px;
    font-size: 13px;
    line-height: calc(16 / 13);
  `}
  

`
export const InputLabel = styled.div`
  ${(props) => InputLabelMixin(props)}
`


const InputBlockMixin = props => css`
  &:hover {
    border: 1px solid #202123;
  }
  border: 1px solid transparent;
  border-radius: 12px;
  background: rgba(25, 28, 52, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props.size === 'medium' && MediumInput};
  ${props.focus && BlockInFocus};

  ${props.size === 'small' && SmallInput};
  ${props.size === 'large' && LargeInput};
  ${props.error && css`
    border: 1px solid #FF5555;
    background: rgba(255, 85, 85, 0.08);
  `}
  ${props.color === 'plain' && PlainInput}
  ${props.color === 'outline' && OutlineInput}
  ${props.color === 'contained' && ContainedInput}
  ${props.focus && props.error && css`
  background-color: #ffffff;`};
`

export const InputBlock = styled.div`
  ${(props) => InputBlockMixin(props)}
`

const IconBlockMixin = props => css`
  display: flex;
  ${props.size === 'small' && SmallIcon}
  ${props.size === 'medium' && MediumIcon}
  ${props.size === 'large' && LargeIcon}
`

export const IconBlock = styled.div`
  ${(props) => IconBlockMixin(props)}
`

const IconInputWrapMixin = props => css`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  flex-grow: 3;
  gap: 8px;
`
export const IconInputWrap = styled.div`
  ${(props) => IconInputWrapMixin(props)}
`


const PlainInput = () => css`
  background-color: #ffffff;
`;
const SmallIcon = () => css`
  > *{
    height: 18px;
    width: 18px;
  }
 
`
const MediumIcon = () => css`
  > *{
    height: 20px;
    width: 20px;
  }
`
const LargeIcon = () => css`
  > *{
    height: 24px;
    width: 24px;
  }

`
// const GreyInput = () => css`
//   background: rgba(25, 28, 52, 0.1);
// `;

const OutlineInput = () => css`
  border: 1px solid #202123;
`;
const ContainedInput = () => css`
  background: rgba(25, 28, 52, 0.1);;
`

const BaseInputMixin = (props) => css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  position: relative;
  border: none;
  width: 100%;
  outline: none;
  background: inherit;
  

  ${props.disabled && css`
    opacity: 0.48;
    pointer-events: none;
  `};

  ${props.size === 'small' && css`
  font-size: 15px;
  `}
  ${props.size === 'medium' && css`
  font-size: 17px;
  `}
  ${props.size === 'large' && css`
  font-size: 17px;
  `}

  ${props.filled && props.label && props.size !== 'small' && css`
    padding-top: 10px;
    color: rgba(9, 11, 22, 0.94);

  `}
`
const LargeInput = () => css`
  width: 100%;
  height: 56px;
`;

const IconMediumPosition = () => css`
  left: 60px;
  top: 26px;
`;
const IconLargePosition = () => css`
  left: 64px;
  top: 27px;
`;
const MediumInput = () => css`
  width: 100%;
  height: 48px;
`;
const BlockInFocus = () => css`
  background-color: #ffffff;
  border: 1px solid;
`
const SmallInput = () => css`
  width: 100%;
  height: 40px;
`;

export const InputStyle = styled.input`
  ${(props) => BaseInputMixin(props)}
`

const IconButtonMixin = props => css`
  padding-right: 16px;
  flex-grow: 0;
  display: flex;
  justify-self: start;
  ${props.size === 'small' && SmallIcon}
  ${props.size === 'medium' && MediumIcon}
  ${props.size === 'large' && LargeIcon}
`
export const IconButton = styled.div`
  ${(props) => IconButtonMixin(props)}
`
const InputHelperMixin = props => css`
  margin-top: 4px;
  padding: 0 16px;
  font-size: 13px;
  //transition-duration: 0.4s;
  ${props.error && css`
    color: #ff5555;
  `}
`

export const InputHelper = styled.div`
  ${(props) => InputHelperMixin(props)}
`