import styled from 'styled-components/macro';
import {Icon, TableData} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {BtnText} from 'components/Statement/style';
import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import Popover, {PopoverActionPanel, PopoverButton} from 'shared/ui/deprecated/Popover/Popover';
import {copyToClipboard} from 'shared/helpers/commonFunctions';

interface NameCellProps {
    note?: string;
    name: string;
    withoutWidth?: boolean;
    lineCount?: number;
    className?: string;
}

export function NameCell(props: NameCellProps) {
    const {note, name, withoutWidth, lineCount = 3, className} = props;

    const width = withoutWidth ? undefined : 'calc(100% - 927px)!important';
    return (
        <TableData width={width}>
            <Wrapper>
                <IconWrapper>
                    {note && (
                        <Popover
                            position="top-start"
                            vertical={23}
                            horyzontal={-1}
                            component={
                                <>
                                    <div>{note}</div>
                                    <PopoverActionPanel>
                                        <PopoverButton onClick={() => copyToClipboard(note)}>
                                            Скопировать
                                        </PopoverButton>
                                    </PopoverActionPanel>
                                </>
                            }
                        >
                            <BtnText>
                                <Icon
                                    iconName={Icons.InfoCircled}
                                    size="large"
                                    className={className}
                                />
                            </BtnText>
                        </Popover>
                    )}
                </IconWrapper>
                <TooltipedSpan title={name} lineCount={lineCount} className={className}>
                    {name}
                </TooltipedSpan>
            </Wrapper>
        </TableData>
    );
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const IconWrapper = styled.div`
    flex: 0 0 32px;
`;
