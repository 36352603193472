import styled, {css} from 'styled-components/macro';

export const StyledSelectWrapper = styled.div<{width: string}>`
    ${(p) =>
        p.width &&
        css`
            width: ${p.width};
        `}
`;

export const StyledOptionRow = styled.div<{$isPart?: boolean; $selected?: boolean}>`
    white-space: normal;

    ${(p) =>
        p.$isPart &&
        css`
            padding-left: 20px;
        `}
    ${(p) =>
        p.$selected &&
        css`
            background-color: var(--color-background-base-selected);
        `}
  &:hover {
        background-color: var(--color-background-base-hover);
    }

    &:focus {
        background-color: var(--color-background-base-focused);
    }
`;
