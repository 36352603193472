import {createDomain} from 'effector';
import {createGate} from 'effector-react';
import {SELECT_TPIS_ACTIVE_TAB} from 'pages/VirSelect/types';
import {tcpService} from '../../service/tcp.service';

export const selectTpisDomain = createDomain();

export const SelectTpisGate = createGate<{projectId?: string}>();

export const getTpiSpecificationsFx = selectTpisDomain.createEffect(
    tcpService.getTpiSpecifications,
);

export const setActiveTab = selectTpisDomain.createEvent();

export const $activeTab = selectTpisDomain.createStore<SELECT_TPIS_ACTIVE_TAB>(
    SELECT_TPIS_ACTIVE_TAB.ALL_TPIS,
);
