import styled from 'styled-components';

export const DeadlineWrapper = styled.div`
    flex: 0 1 602px;
    display: flex;
`;

export const TextFieldWrapper = styled.div`
    width: 54px;
    margin: 0 4px;
`;

export const DeadlineInfo = styled.div`
    width: 226px;
    margin-left: 16px;
`;
