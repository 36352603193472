import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Checkbox, Divider, Icon, Progress, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {ActionButtonsUnit} from 'Features/ActionButtonsUnit';
import {$createAdditionalAgreementData} from 'processes/contructor';
import {EAdditionalAgreementType} from 'shared/types/additionalAgreementsTypes';

import {WorksheetCopyTable} from './WorksheetCopyTable';
import {AgreementAutocomplete} from './AgreementAutocomplete';
import * as S from './style';
import {
    $copyAgreementConstructorStore,
    actionButtonClicked,
    coefficientChecked,
    quantityChecked,
    WorksheetCopyGate,
} from '../model';
import {AgreementConstructorHeader} from '../../AddWorkObjects/ui/Header';

export const Stage2CopyAgreement = () => {
    useGate(WorksheetCopyGate);
    const {
        contractName,
        selectedAgreement,
        initialCopiedAgreement,
        finalCopiedAgreement,
        quantityCheck,
        coefficientCheck,
        navigateToConstructor,
        selectedTpiName,
        selectedAgreementLoading,
        goAheadButtonDisabled,
    } = useStore($copyAgreementConstructorStore);
    const agreement = useStore($createAdditionalAgreementData);

    const navigate = useNavigate();

    useEffect(() => {
        if (navigateToConstructor) {
            navigate(`/additional-agreement/constructor`);
        }
    }, [navigateToConstructor]);

    return (
        <S.Wrapper>
            <AgreementConstructorHeader
                agreement={agreement}
                agreementType={EAdditionalAgreementType.Agreement}
            />
            <S.ContentWrapper>
                <S.TitleWrapper>
                    <Typography variant="h4">
                        Выберите ДС и настройте параметры копирования пунктов работ
                    </Typography>
                    <Typography variant="body2">{`Договор ${contractName}`}</Typography>
                </S.TitleWrapper>
                <S.AgreementWrapper>
                    <S.AutocompleteWrapper>
                        <AgreementAutocomplete />
                    </S.AutocompleteWrapper>
                    {selectedAgreement && (
                        <>
                            <Checkbox
                                label="Перенести количество"
                                checked={quantityCheck}
                                onChange={(e) => quantityChecked(e.target.checked)}
                            />
                            <Checkbox
                                label="Перенести коэффициенты"
                                checked={coefficientCheck}
                                onChange={(e) => coefficientChecked(e.target.checked)}
                            />
                        </>
                    )}
                </S.AgreementWrapper>
                <S.SheetWrapper>
                    <S.SheetTitleWrapper>
                        <S.SheetHalfTitleWrapper>
                            <Typography variant="h5">Откуда (выбранное доп. соглашение)</Typography>
                            {initialCopiedAgreement && (
                                <Typography variant="body2">
                                    {initialCopiedAgreement.tpi.name}
                                </Typography>
                            )}
                        </S.SheetHalfTitleWrapper>
                        <Icon iconName={Icons.ArrowRight} size="large" />
                        <S.SheetToTitleWrapper>
                            <Typography variant="h5">Куда (новое доп. соглашение)</Typography>
                            {initialCopiedAgreement && selectedTpiName && (
                                <Typography variant="body2">{selectedTpiName}</Typography>
                            )}
                        </S.SheetToTitleWrapper>
                    </S.SheetTitleWrapper>
                    {!selectedAgreementLoading ? (
                        finalCopiedAgreement &&
                        initialCopiedAgreement &&
                        finalCopiedAgreement.works.map((worksheet, index) => (
                            <WorksheetCopyTable
                                key={worksheet.id}
                                worksheetId={worksheet.id}
                                sheetNumber={index}
                                initialData={initialCopiedAgreement.works[index].work_objects}
                                data={worksheet.work_objects}
                                quantityCheck={quantityCheck}
                                coefficientCheck={coefficientCheck}
                            />
                        ))
                    ) : (
                        <S.ProgressWrapper>
                            <Progress
                                cycled
                                shape="circle"
                                size={44}
                                strokeWidth={4}
                                type="solo"
                                value={75}
                            />
                        </S.ProgressWrapper>
                    )}
                </S.SheetWrapper>
            </S.ContentWrapper>
            <S.BottomWrapper>
                <Divider />
                <S.ActionWrapper>
                    <ActionButtonsUnit
                        negativeBtnLabel="Назад"
                        positiveBtnLabel="Перенести пункты"
                        handleNegativeBtn={() => navigate(-1)}
                        handlePositiveBtn={() => actionButtonClicked()}
                        disabledPositiveBtn={goAheadButtonDisabled}
                    />
                </S.ActionWrapper>
            </S.BottomWrapper>
        </S.Wrapper>
    );
};
