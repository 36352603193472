import styled from 'styled-components/macro';

export const GridWrapper = styled.div`
    margin-top: var(--size-spacing-x4);
    display: grid;
    gap: var(--size-spacing-x6);
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
        'address address creationDate approvingDate'
        'purchase jobType contractor agreement'
        'lot currentCompletion prevCompletion .';
`;
