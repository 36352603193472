import React from 'react';
import Label from 'shared/ui/deprecated/Label/Label';
import PointIcon from 'icons/PointIcon';
import {formatDate} from 'shared/helpers/formatHelper';
import {Tooltip} from 'shared/ui/deprecated/Tooltip/Tooltip';
import {StyledSpan} from 'shared/helpers/projectsColumnSettings/style';
import {Link} from 'react-router-dom';

function years(arr) {
    let yearString = '';
    arr.forEach((item, index) => {
        if (index !== arr.length - 1) {
            if (item !== 'Не указан') {
                yearString = `${yearString + item}, `;
            }
        } else if (item !== 'Не указан') {
            yearString += item;
        } else if (yearString.length > 2) {
            yearString = yearString.slice(0, yearString.length - 2);
        }
    });
    return <div>{yearString}</div>;
}

export function labelVariant(text) {
    switch (text) {
        case '$color-status-error':
            return 'error';
        case '$color-status-success':
            return 'success';
        case '$color-status-info':
            return 'info';
        case '$color-status-warning':
            return 'warning';
        case '$color-status-neutral':
            return 'default';
        case '$color-status-magenta':
            return 'magenta';
        case '$color-status-purple':
            return 'purple';
        case '$color-status-teal':
            return 'teal';
        default:
            return 'default';
    }
}

export function allColumns() {
    return [
        {
            name: 'project_ext_id',
            label: 'Номер',
            width: 'calc(110 / 1900 * 100%)',
            minWidth: '110px',
            visible: true,
        },
        {
            name: 'open_date',
            label: 'Дата открытия',
            width: 'calc(160 / 1900 * 100%)',
            minWidth: '160px',
            visible: true,
        },
        {
            name: 'year',
            label: 'Год ПРС',
            // filter: true,
            width: 'calc(113 / 1900 * 100%)',
            minWidth: '113px',
            visible: true,
        },
        {
            name: 'bs_name',
            label: 'Базовая станция',
            width: 'calc(196 / 1900 * 100%)',
            minWidth: '196px',
            visible: true,
        },
        {
            name: 'pos_code',
            label: 'Номер позиции',
            width: 'calc(167 / 1900 * 100%)',
            minWidth: '167px',
            visible: true,
        },
        {
            name: 'bs_gfk',
            label: 'Код ГФК',
            width: 'calc(180 / 1900 * 100%)',
            minWidth: '180px',
            visible: true,
        },
        {
            name: 'types_project',
            label: 'Тип',
            width: 'calc(135 / 1900 * 100%)',
            minWidth: '135px',
            visible: true,
        },
        {
            name: 'gpo',
            label: 'Основной ГПО',
            width: 'calc(212 / 1900 * 100%)',
            minWidth: '212px',
            visible: true,
        },
        {
            name: 'status',
            label: 'Статус',
            width: 'calc(391 / 1900 * 100%)',
            minWidth: '391px',
            visible: true,
        },
        {
            name: 'branch',
            label: 'Филиал',
            width: 'calc(167 / 1900 * 100%)',
            minWidth: '167px',
            visible: true,
        },
        {
            name: 'region',
            label: 'Регион',
            width: 'calc(167 / 1900 * 100%)',
            minWidth: '167px',
            visible: true,
        },
    ];
}

export const rowContent = (item) => {
    return {
        project_ext_id: <Link to={`/projects/${item.id}`}>{item.project_ext_id}</Link>,
        open_date: <span>{item.open_date && formatDate(item.open_date)}</span>,
        year: <span>{item.years.length !== 0 && years(item.years)}</span>,
        bs_name: (
            <Tooltip
                titleText={`${item.bs_number} ${item.bs_name}`}
                position="top-end"
                className="me-3"
                maxWidth={348}
                horyzontal={0}
                vertical={8}
            >
                <StyledSpan>
                    {item.bs_number}
                    <br />
                    {item.bs_name}
                </StyledSpan>
            </Tooltip>
        ),
        pos_code: <span>{item.pos_code != null ? item.pos_code : ''}</span>,
        bs_gfk: <span>{item.bs_gfk}</span>,
        types_project: (
            <Tooltip
                titleText={item.types_project}
                position="top-end"
                className="me-3"
                maxWidth={348}
                horyzontal={0}
                vertical={8}
            >
                <StyledSpan>{item.types_project}</StyledSpan>
            </Tooltip>
        ),
        gpo: (
            <Tooltip
                titleText={item.prime_contractors}
                position="top-end"
                className="me-3"
                maxWidth={348}
                horyzontal={0}
                vertical={8}
            >
                <StyledSpan>{item.prime_contractors}</StyledSpan>
            </Tooltip>
        ),
        status: item.statuses_project && (
            <Label variant={labelVariant(item.statuses_token)} icon={<PointIcon />} outline>
                {item.statuses_project}
            </Label>
        ),
        branch: <span>{item.branch}</span>,
        region: <span>{item.region}</span>,
    };
};
