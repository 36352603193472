import React, {useEffect, useRef} from 'react';
import {useStore} from 'effector-react';
import {Button, Icon, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont/icons';

import {MobileIdEntrance} from 'widgets/MobileIdEntrance';
import {Logo} from 'components/Header/Header';
import TextField from 'shared/ui/deprecated/TextField/TextField';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';

import {$loginPageStore, sendEmail, setEmail} from 'pages/NewLoginPage/model';

import * as S from './style';

export const NewLoginPage = () => {
    const {email, emailHasSent} = useStore($loginPageStore);

    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
    };
    const ref = useRef<HTMLButtonElement>(null);

    const handleOnChangeLoginInput = (e) => {
        if (e.key === 'Enter') {
            ref.current?.click();
        }
    };

    useEffect(() => {
        document.addEventListener('keypress', handleOnChangeLoginInput);
        return () => {
            document.removeEventListener('keypress', handleOnChangeLoginInput);
        };
    }, []);

    const handleClickLoginBtn = () => {
        sendEmail();
    };

    return (
        <S.LoginWrap>
            <S.Login>
                <WrapperFlex flexDirection="column" alignItems="center">
                    <WrapperOffset offsetBottom="32">
                        <Logo>
                            <img src="/img/logo.svg" alt="Logo" />
                        </Logo>
                    </WrapperOffset>
                </WrapperFlex>
                {emailHasSent ? (
                    <MobileIdEntrance />
                ) : (
                    <>
                        <WrapperOffset>
                            <TextField
                                leftIcon={<Icon iconName={Icons.Mail} />}
                                placeholder="Почта"
                                size="large"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <S.NoteWrapper>
                                <Typography variant="caption">
                                    Push-уведомление для входа придет на номер телефона
                                </Typography>
                            </S.NoteWrapper>
                        </WrapperOffset>
                        <Button
                            className="w-100"
                            variant="contained"
                            size="large"
                            disabled={!validateEmail(email)}
                            onClick={handleClickLoginBtn}
                            ref={ref}
                        >
                            Войти
                        </Button>
                    </>
                )}
            </S.Login>
        </S.LoginWrap>
    );
};
