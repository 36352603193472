import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 48px;
    margin: 32px 0;
`;
export const JobsAndFilesWrapper = styled.div`
    flex: 1 0 567px;
`;
export const FilesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
export const CommentsWrapper = styled.div`
    flex: 1 0 422px;
`;
