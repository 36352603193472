import React, {useState} from 'react';
import {useStore} from 'effector-react';
import {Divider, IconButton, Progress, Tooltip, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {useOutsideClick} from 'shared/hooks/useOutsideClick';
import {TAttachmentFile} from 'shared/types/FileTypes';
import {StyledSpan} from 'shared/styles/commonStyle';

import * as S from './style';
import {$downloadingCardStore, downloadAllAttachments, downloadAttachment} from '../model';

interface IDownloadingCardProps {
    documentList: TAttachmentFile[];
}

export const DownloadingButtonWithCard = ({documentList}: IDownloadingCardProps) => {
    const [cardIsOpen, setCardIsOpen] = useState(false);
    const {isFileDownloading, isAllDownloading} = useStore($downloadingCardStore);

    const handleCloseCard = () => {
        setCardIsOpen(false);
    };
    const handleOpenCard = (e) => {
        e.stopPropagation();
        setCardIsOpen(true);
    };
    const handleDownloadAttachment = (e, attachment: TAttachmentFile) => {
        e.stopPropagation();
        downloadAttachment(attachment);
    };
    const handleDownloadAll = (e) => {
        e.stopPropagation();
        downloadAllAttachments(documentList);
    };
    const ref = useOutsideClick<HTMLDivElement>(handleCloseCard);

    return (
        <S.Wrapper>
            <Tooltip title="Скачать">
                <IconButton iconName={'download' as Icons} size="medium" onClick={handleOpenCard} />
            </Tooltip>
            {cardIsOpen && (
                <S.Card ref={ref} onClick={(e) => e.stopPropagation()}>
                    <S.ItemWrapper>
                        {documentList.map((item) => (
                            <S.CardItem key={item.id}>
                                <S.SpanWrapper>
                                    <Tooltip title={item.file_name}>
                                        <StyledSpan lineCount={1} noCursor typography>
                                            <Typography variant="body2">
                                                {item.file_name}
                                            </Typography>
                                        </StyledSpan>
                                    </Tooltip>
                                </S.SpanWrapper>
                                {isFileDownloading ? (
                                    <Progress value={33} size="mini" shape="circle" cycled />
                                ) : (
                                    <IconButton
                                        iconName={Icons.Download}
                                        size="large"
                                        onClick={(e) => handleDownloadAttachment(e, item)}
                                    />
                                )}
                            </S.CardItem>
                        ))}
                    </S.ItemWrapper>
                    <Divider />
                    <S.MarginButton variant="plain" size="medium" onClick={handleDownloadAll}>
                        Скачать все
                    </S.MarginButton>
                </S.Card>
            )}
        </S.Wrapper>
    );
};
