import {bindAllMethods} from 'shared/helpers/binder';
import {Branch, Region} from '../types/agreement';

import {axiosInstance} from 'shared/api/api';
import {IPaginationResponse} from 'shared/types/apiTypes';
import {
    AdditionalAgreementsParams,
    IAdditionalAgreementListItem,
} from 'shared/types/additionalAgreementsTypes';

export const additionalAgreementsService = bindAllMethods({
    async getAllAdditionalAgreements(params: AdditionalAgreementsParams) {
        return axiosInstance.get<IPaginationResponse<IAdditionalAgreementListItem>>(
            'DS/agreements/',
            {params},
        );
    },

    async getRegions(): Promise<Region[]> {
        return axiosInstance.get(`/catalogs/regions/`);
    },

    async getBranches(region: string): Promise<Branch[]> {
        return axiosInstance.get(`/catalogs/branches/`, {
            params: {region},
        });
    },
});
