import {axiosInstance} from 'shared/api/api';
import {
    BranchesResponseType,
    ProjectPayloadType,
    ProjectResponseType,
    ProjectType,
    RegionsResponseType,
} from 'shared/services/types/types';
import {TProjectParams} from 'shared/types/projectTypes';

export async function getProjectList(payload: ProjectPayloadType) {
    return axiosInstance.get<ProjectResponseType>('/projects/projects/', {
        params: payload,
    });
}

export function getBlobProjectList(payload: Omit<TProjectParams, 'page' | 'page_size'>) {
    return axiosInstance.get<Blob>('projects/projects/load_projects/', {
        params: payload,
        responseType: 'blob',
        timeout: 200000,
    });
}

export function getProjectData(id: string) {
    return axiosInstance.get<ProjectType>(`/projects/projects/${id}/`);
}

export function getProjectId(nriId: number) {
    return axiosInstance.get<{id: string}>(`projects/projects/project_by_ext/`, {
        params: {
            nri_id: nriId,
        },
    });
}

export function getProjectPageSelectRegion() {
    return axiosInstance.get<RegionsResponseType>(`/catalogs/regions/`);
}

export function getProjectPageSelectBranch(region: string) {
    return axiosInstance.get<BranchesResponseType>(`/catalogs/branches/`, {
        params: {region},
    });
}

export function getProjectPageSelectType() {
    return axiosInstance.get(`/catalogs/types_projects/`);
}

export function getProjectPageSelectStatus() {
    return axiosInstance.get(`/catalogs/statuses_projects/`);
}

export function getProjectPageSelectYear() {
    return axiosInstance.get(`/projects/bands/`);
}
