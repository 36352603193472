import {axiosInstance} from 'shared/api/api';
import {
    TDocumentCreation,
    TDocumentsListItem,
    IDocumentsListQueryParams,
    TDocument,
    TDocumentType,
    TPrimeContractorListItem,
    TDocumentTypeListItem,
    TDocumentEditing,
} from 'shared/types/documentTypes';
import {IResponseWithPagination} from 'shared/types/apiParamsTypes';

export const fetchDocumentsList = (params: IDocumentsListQueryParams) => {
    return axiosInstance.get<IResponseWithPagination<TDocumentsListItem>>('documents/documents/', {
        params: params,
    });
};

export const fetchDocumentSubtypeList = (projectId: string) => {
    return axiosInstance.get<TDocumentTypeListItem[]>('documents/documents/subtypes_document/', {
        params: {
            project_id: projectId,
        },
    });
};

export const fetchPrimeContractorList = (projectId: string) => {
    return axiosInstance.get<TPrimeContractorListItem[]>('documents/documents/organization_gpo/', {
        params: {
            project_id: projectId,
        },
    });
};

export const postDocumentCreation = (data: TDocumentCreation) => {
    return axiosInstance.post<{id: string}>('documents/documents/', data);
};

export const putDocumentEditing = (data: TDocumentEditing, documentId: string) => {
    return axiosInstance.put<{id: string}>(`documents/documents/${documentId}/`, data);
};

// для страницы создания неоц документа
export const fetchAvailableDocumentSubtypeList = (projectId: string) => {
    return axiosInstance.get<TDocumentType[]>('catalogs/subtypes_documents/', {
        params: {
            project_id: projectId,
            is_selectable: true,
        },
    });
};

export const fetchRawDocument = (id: string) => {
    return axiosInstance.get<TDocument>(`documents/documents/${id}/`);
};

export const postDocumentComment = (data: {id: string; comment: string}) => {
    const {id, comment} = data;
    return axiosInstance.post(`/documents/documents/${id}/add_comment/`, {
        comment: comment,
    });
};

export const fetchDocumentTypeList = () => {
    return axiosInstance.get<TDocumentType[]>('catalogs/subtypes_documents/');
};