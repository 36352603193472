import styled from 'styled-components/macro';

export const Cell = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const Value = styled.span`
    min-width: 20px;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const ActionUnit = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
`;