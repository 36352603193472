import {createEvent, createStore} from 'effector';

import {forwardPayload} from 'shared/helpers/effector';


export const snackbarWasReset = createEvent();
export const snackbarNotificationChanged = createEvent<string>();

export const $snackbarMessage = createStore('');

$snackbarMessage.on(snackbarNotificationChanged, forwardPayload()).reset(snackbarWasReset);
