import {useStore} from 'effector-react';
import {ReactNode} from 'react';
import {TableCell, TableCellContent, TableString} from 'shared/ui/Table/Table';
import {Skeleton} from 'shared/ui/deprecated/Skeleton/Skeleton';
import {$allTpisPaginationParams} from '../../model/AllTpisTab';

export function TableSkeleton({columns}: {columns: {content: ReactNode; width: string}[]}) {
    const {pageSize} = useStore($allTpisPaginationParams);
    return (
        <>
            {[...Array(pageSize)].map((el, idx) => (
                <TableString key={idx}>
                    {columns.map((col, idx) => (
                        <TableCell key={idx} width={col.width}>
                            <TableCellContent>
                                <Skeleton height={20} />
                            </TableCellContent>
                        </TableCell>
                    ))}
                </TableString>
            ))}
        </>
    );
}
