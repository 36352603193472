import React from 'react';
import {Avatar, Tooltip} from '@beeline/design-system-react';

import {IEmployee} from 'shared/types/userTypes';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {getAvatarColorType} from 'shared/helpers/colors';
import {TooltipedSpan} from 'shared/ui/TooltipedSpan';

import * as S from './style';

interface MultiAvatarProps {
    executor: IEmployee | null;
    user: IEmployee;
    co_users: IEmployee[];
}

export const MultiAvatar = ({user, executor, co_users}: MultiAvatarProps) => {
    const getLetter = (person: IEmployee) => {
        return `${person.first_name[0] ?? ' '}${person.last_name[0] ?? ' '}`;
    };

    return (
        <div>
            <WrapperFlex alignItems="center" gap="8px">
                {co_users.length === 0 ? (
                    <>
                        <Avatar
                            letter={getLetter(executor ?? user)}
                            color={getAvatarColorType(executor ?? user)}
                        />
                        <span>{(executor ?? user).short_name}</span>
                    </>
                ) : (
                    <>
                        <S.MultiAvatarWrapper>
                            {[user, ...co_users.slice(0, 3)].map((item, index) => (
                                <Tooltip title={`${item.short_name}`} key={`${item.id}${index}`}>
                                    <Avatar
                                        letter={getLetter(item)}
                                        color={getAvatarColorType(item)}
                                    />
                                </Tooltip>
                            ))}
                        </S.MultiAvatarWrapper>
                        {co_users.length > 3 && (
                            <TooltipedSpan
                                lineCount={1}
                                title={
                                    <>
                                        {co_users.slice(3).map((item, index) => {
                                            return (
                                                <p key={`${item.short_name}${index}`}>
                                                    {item.short_name}
                                                </p>
                                            );
                                        })}
                                    </>
                                }
                            >
                                +{co_users.length > 3 ? co_users.length - 3 : co_users.length}
                            </TooltipedSpan>
                        )}
                    </>
                )}
            </WrapperFlex>
        </div>
    );
};
