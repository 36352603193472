import styled from 'styled-components';

export const SelectWrap = styled.div`
    display: flex;
    gap: 16px;
    @media (max-width: 1050px) {
        justify-content: space-between;
        & > * {
            max-width: calc(50% - 8px);
            width: calc(50% - 8px);
            --selectWidth: 100%;
        }
    }
    @media (max-width: 1440px) and (min-width: 1050px) {
        --selectWidth: 240px;
    }
    @media (max-width: 1440px) and (min-width: 1200px) {
        --selectWidth: 240px;
    }
    @media (max-width: 1600px) and (min-width: 1440px) {
        --selectWidth: 280px;
    }
    --selectListWidth: 320px;
`;
