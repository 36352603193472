import {createGate} from 'effector-react';
import {combine, createDomain, merge} from 'effector';
import {getTpiSpecificationsFx} from '../SelectTpisPage';
import {TpiSpecification} from '../../types';
import {resetDomainStoresByEvents} from 'shared/helpers/effector';

export const allTpisTabGate = createDomain();
export const AllTpisTabGate = createGate();

export const filterAllBySearch = allTpisTabGate.createEvent();
export const changeSearchAllInput = allTpisTabGate.createEvent();

export const setPage = allTpisTabGate.createEvent<number>();
export const setPageSize = allTpisTabGate.createEvent<number>();

export const $searchAllTerm = allTpisTabGate.createStore('');
export const $count = allTpisTabGate.createStore(0);

export const $allTpis = allTpisTabGate.createStore<Array<TpiSpecification>>([]);
export const $page = allTpisTabGate.createStore(1);
export const $pageSize = allTpisTabGate.createStore(10);

export const $allTpisPaginationParams = combine({
    page: $page,
    pageSize: $pageSize,
    searchAllTerm: $searchAllTerm,
    count: $count,
    loading: getTpiSpecificationsFx.pending,
    allTpis: $allTpis,
});

export const tableDataFetched = merge([setPage, setPageSize, filterAllBySearch]);

resetDomainStoresByEvents(allTpisTabGate, AllTpisTabGate.close);