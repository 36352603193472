import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';
import {SearchCategoriesType} from '../../../pages/ProjectPage/model/types';
import {forwardPayload} from 'shared/helpers/effector';

export const multiSearchDomain = createDomain();
export const MultiSearchGate = createGate();

// events
export const handleEnterPress = multiSearchDomain.createEvent();
export const setSearchValue = multiSearchDomain.createEvent<string>();
export const setSelectedSearchCategory = multiSearchDomain.createEvent<SearchCategoriesType>();
export const resetALlSearchParameters = multiSearchDomain.createEvent();
// effects

// stores
export const $searchValue = multiSearchDomain
    .createStore<string>('')
    .on(setSearchValue, forwardPayload())
    .reset(resetALlSearchParameters);

const $pushedSearchValue = multiSearchDomain
    .createStore<string>('')
    .reset(resetALlSearchParameters);

export const $selectedSearchCategory = multiSearchDomain
    .createStore<SearchCategoriesType>({
        title: 'Номер БС',
        type: 'bsNumberOnly',
        prefix: 'bs:',
    })
    .on(setSelectedSearchCategory, forwardPayload())
    .reset(resetALlSearchParameters);

export const $multiSearchStore = combine({
    searchValue: $searchValue,
    selectedSearchCategory: $selectedSearchCategory,
    pushedSearchValue: $pushedSearchValue,
});

sample({
    clock: handleEnterPress,
    source: $multiSearchStore,
    fn: ({searchValue, selectedSearchCategory}) => {
        return searchValue ? `${selectedSearchCategory.prefix}${searchValue}` : '';
    },
    target: $pushedSearchValue,
});
