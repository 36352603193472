import {combine, sample} from 'effector';

import {AgreementError} from 'shared/types/additionalAgreementsTypes';

import {
    $createAdditionalAgreementData,
    $touchedConstructorForm,
    $validConstructor,
    ConstructorGate,
    parentSigningDateChanged,
    submitCreateAdditionalAgreement,
    updateSpecDate,
    updateSpecPrice,
    updateSpecQuantity,
    validateSpecs,
    $agreementCreationDisabled,
    $emptyPayrollIndexList,
    $errorBannerShowed,
    deleteVir,
    deleteWorkObject,
    updateSpecAttachments,
    $agreementErrorObject,
    $agreementType,
} from '../stores';
import {getInvalidEndDatesBannerTitle, getInvalidNotOpenedSheetsBannerTitle} from '../../helpers';
import {validateConstructorSpecs} from '../../helpers/validation';

sample({
    clock: [
        submitCreateAdditionalAgreement,
        updateSpecPrice,
        updateSpecQuantity,
        updateSpecDate,
        parentSigningDateChanged,
        updateSpecAttachments,
        deleteVir,
        deleteWorkObject,
    ],
    source: $touchedConstructorForm,
    target: validateSpecs,
});

sample({
    clock: submitCreateAdditionalAgreement,
    fn: () => true,
    target: $touchedConstructorForm,
});

sample({
    clock: validateSpecs,
    source: $createAdditionalAgreementData,
    fn: (ds) => validateConstructorSpecs(ds),
    target: $validConstructor,
});

// Дизейблинг кнопок действия, если конструктор не валиден
sample({
    source: combine({
        touchedConstructor: $touchedConstructorForm,
        validConstructor: $validConstructor,
    }),
    fn: ({touchedConstructor, validConstructor}) => touchedConstructor && !validConstructor,
    target: $agreementCreationDisabled,
});

// создание списка номеров ведомостей, в которых есть ошибки
sample({
    source: combine({
        agreement: $createAdditionalAgreementData,
        agreementType: $agreementType,
        agreementCreationDisabled: $agreementCreationDisabled,
        errorBannerShowed: $errorBannerShowed,
    }),
    filter: ({agreementCreationDisabled, errorBannerShowed}) =>
        agreementCreationDisabled || errorBannerShowed,

    fn: ({agreement, agreementType}) => {
        const bannerTitle = getInvalidNotOpenedSheetsBannerTitle(agreement);
        const endDateErrorTitle = getInvalidEndDatesBannerTitle(agreement, agreementType);

        const result = new AgreementError();

        if (bannerTitle !== '') result.objectError = bannerTitle;
        if (endDateErrorTitle !== '') result.endDateError = endDateErrorTitle;
        return result;
    },
    target: $agreementErrorObject,
});

sample({
    source: $agreementErrorObject,
    fn: (object) => object.objectError !== '' || object.endDateError !== '',
    target: $errorBannerShowed,
});

sample({
    source: combine({
        emptyPayrollIndexList: $emptyPayrollIndexList,
        validationMessage: $agreementErrorObject,
    }),
});

$touchedConstructorForm.reset(ConstructorGate.open);
$validConstructor.reset(ConstructorGate.open);
