import React from 'react';
import {Button} from '@beeline/design-system-react';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

interface ActionButtonsUnitProps {
    negativeBtnLabel: string;
    positiveBtnLabel: string;
    handleNegativeBtn: () => void;
    handlePositiveBtn: () => void;
    alignSelf?: string;
    disabledPositiveBtn?: boolean;
    disableAllButtons?: boolean;
    hidePositiveBtn?: boolean;
}

export const ActionButtonsUnit = ({
    negativeBtnLabel,
    positiveBtnLabel,
    handlePositiveBtn,
    handleNegativeBtn,
    alignSelf,
    disabledPositiveBtn,
    disableAllButtons,
    hidePositiveBtn,
}: ActionButtonsUnitProps) => {
    return (
        <WrapperFlex gap="16px" alignSelf={alignSelf}>
            <Button
                variant="outlined"
                size="medium"
                onClick={handleNegativeBtn}
                disabled={disableAllButtons}
            >
                {negativeBtnLabel}
            </Button>
            {hidePositiveBtn ? (
                <></>
            ) : (
                <Button
                    variant="contained"
                    size="medium"
                    onClick={handlePositiveBtn}
                    disabled={disabledPositiveBtn || disableAllButtons}
                >
                    {positiveBtnLabel}
                </Button>
            )}
        </WrapperFlex>
    );
};
