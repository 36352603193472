import React from 'react';
import {Label} from '@beeline/design-system-react';
import {TASK_REPLAY} from 'shared/const/phrases';
import {Icons} from '@beeline/design-tokens/js/iconfont';
import {TooltipedSpan} from '../../../shared/ui/TooltipedSpan';

interface ReplayBadgeProps {
    count: number | null;
}

export const ReplayBadge = ({count}: ReplayBadgeProps) => {
    return (
        <TooltipedSpan title={TASK_REPLAY} lineCount={1} noCursor>
            {count && count > 1 ? (
                <Label title={count.toString() ?? ''} iconName={Icons.Redo} />
            ) : (
                ''
            )}
        </TooltipedSpan>
    );
};
