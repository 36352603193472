import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

export const LabelWrapper = styled.div`
    padding: 12px 16px;
    cursor: pointer;

    :hover {
        background-color: var(--color-background-base-hover);
    }
`;
