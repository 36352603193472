import styled, {css} from 'styled-components/macro';

const ProjectFilterStyledMixin = (props) => css`
    position: sticky;
    top: var(--toplineHeight);
    background-color: #fff;
    border-left: 1px solid transparent;
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--toplineHeight));
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0;
    transition-duration: 0.4s;
    width: 0;

    & > * {
        opacity: 0;
        transition-duration: 0.1s;
    }

    ${props.active &&
    css`
        border-left: 1px solid #e0e0e0;
        min-width: 320px;
        width: 320px;

        & > * {
            min-width: 320px;
            opacity: 1;
        }
    `}
    ${props.height &&
    css`
        height: ${props.height}px;
    `}
`;

export const ProjectFilterStyled = styled.div`
    ${(props) => ProjectFilterStyledMixin(props)}
`;
export const TitleWrap = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    display: flex;
    justify-content: space-between;
`;
export const CloseBtn = styled.button`
    margin-left: 16px;
    --color: var(--secondary);
    background-color: transparent;
    height: 24px;
    min-width: 24px;
    padding: 0;
    width: 24px;
    border: none;

    &:hover {
        --color: var(--body-color);
    }

    .icon {
        color: var(--color);
        font-size: 24px;
        transition-duration: 0.4s;
    }
`;
export const FilterBody = styled.div`
    padding-bottom: 16px;
`;
export const BtnWrap = styled.div`
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: center;
`;

const ProjectFilterAccordeonTitleMixin = (props) => css`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    span {
        font-size: 19px;
        font-weight: 500;
        line-height: 1.2631578947;
        user-select: none;
    }

    .icon {
        color: var(--body-color);
        font-size: 24px;
        transition-duration: 0.4s;
    }

    ${props.$active &&
    css`
        .icon {
            transform: scaleY(-1);
        }
    `}
`;

export const ProjectFilterAccordeonTitle = styled.div`
    ${(props) => ProjectFilterAccordeonTitleMixin(props)}
`;

const ProjectFilterAccordeonBodyMixin = (props) => css`
    display: none;
    ${props.$active &&
    css`
        display: block;
    `}
`;

export const ProjectFilterAccordeonBody = styled.div`
    ${(props) => ProjectFilterAccordeonBodyMixin(props)}
`;

export const ProjectFooter = styled.div`
    background-color: #fff;
    display: flex;
    height: 80px;
    justify-content: center;
    margin-top: auto;
    padding: 16px;

    & > * {
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

const ProjectSettingsMixin = (props) => css`
    padding-top: 24px;
    overflow: auto;
    height: calc(${props.height}px - var(--toplineHeight));
`;

export const ProjectSettings = styled.div`
    ${(props) => ProjectSettingsMixin(props)}
`;

export const Content = styled.div`
    flex-grow: 1;
    overflow-y: auto;
`;

export const FilterContainer = styled.div`
    height: 1200px;
    position: relative;
`;
