import {TableCell, TableCellContent} from 'shared/ui/Table/Table';
import {TableStringTCP} from 'pages/VirSelect/ui/TpiRow/style';
import Checkbox from 'shared/ui/deprecated/Checkbox/Checkbox';
import Popover, {PopoverActionPanel, PopoverButton} from 'shared/ui/deprecated/Popover/Popover';
import {BtnText} from 'components/Statement/style';
import InfoIcon from 'icons/InfoIcon';
import {ICoefficient} from 'pages/ApplyCoefficientsPage/types';
import {formatNumberToDecimalWithComma} from 'shared/helpers/formatHelper';
import {copyToClipboard} from 'shared/helpers/commonFunctions';
import * as S from './style';

interface CoefficientRowProps {
    item: ICoefficient;
    onSelected: (e: HTMLInputElement) => void;
    checked: boolean;
    isPartly?: boolean;
}

export function CoefficientRow(props: CoefficientRowProps) {
    const {item, onSelected, checked, isPartly = false} = props;

    return (
        <TableStringTCP checked={checked}>
            <TableCell>
                <TableCellContent>
                    <Checkbox isPartly={isPartly} checked={checked} onChange={onSelected} />
                </TableCellContent>
            </TableCell>
            <TableCell>
                <TableCellContent>{item.number}</TableCellContent>
            </TableCell>
            <TableCell>
                <TableCellContent>
                    <S.NameCell>
                        {item?.note && (
                            <Popover
                                // open={open}
                                // onClose={() => setOpen(false)}
                                position="top-start"
                                vertical={23}
                                horyzontal={-1}
                                component={
                                    <>
                                        <div>{item.note}</div>
                                        <PopoverActionPanel>
                                            <PopoverButton onClick={() => copyToClipboard(item.note)}>
                                                Скопировать
                                            </PopoverButton>
                                        </PopoverActionPanel>
                                    </>
                                }
                            >
                                <BtnText>
                                    <InfoIcon />
                                </BtnText>
                            </Popover>
                        )}
                        {item.name}
                    </S.NameCell>
                </TableCellContent>
            </TableCell>
            <TableCell>
                <TableCellContent>
                    {formatNumberToDecimalWithComma(item.coefficient || 1)}
                </TableCellContent>
            </TableCell>
        </TableStringTCP>
    );
}
