import React from 'react';
import {useGate, useStore} from 'effector-react';
import {Link} from 'react-router-dom';

import format from 'date-fns/format';
import {$editorInfo, viewProjectTitleGate} from 'Entities/ViewProjectTitle/model';
import LinkIcon from 'icons/LinkIcon';
import PointIcon from 'icons/PointIcon';
import Label from 'shared/ui/deprecated/Label/Label';
import Breadcrumb from 'shared/ui/deprecated/Breadcrumb/Breadcrumb';
import BreadcrumbItem from 'shared/ui/deprecated/Breadcrumb/BreadcrumbItem';
import Button from 'shared/ui/deprecated/Button/Button';
import {H4} from 'shared/ui/deprecated/HeaderText/HeaderText';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';

import {labelVariant} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {NonMvpItem} from 'shared/styles/commonStyle';
import {ProjectType} from 'shared/services/types/types';

import {EditInfoLink, EditInfo} from './style';

interface ViewProjectTitleProps {
    data: ProjectType;
}

export const ViewPageTitle = ({data}: ViewProjectTitleProps) => {
    useGate(viewProjectTitleGate, data.editor);
    const editorInfo = useStore($editorInfo);
    const editorFullName = `${editorInfo?.last_name ?? ''} ${editorInfo?.first_name ?? ''} ${
        editorInfo?.middle_name ?? ''
    }`;
    const dateFormat = (date) => {
        return format(new Date(date), 'dd.MM.yyyy');
    };
    const editingDate = dateFormat(data.updated_at.slice(0, 10));

    return (
        <WrapperFlex justifyContent="space-between">
            <div>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/projects">Проекты</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>Проект {data.project_ext_id}</BreadcrumbItem>
                </Breadcrumb>
                <WrapperOffset offsetTop="12">
                    <WrapperFlex alignItems="center">
                        <H4 className="me-3">
                            Проект № {data.project_ext_id} от {dateFormat(data.open_date)}
                        </H4>
                        <Label
                            outline
                            variant={labelVariant(data.statuses_token)}
                            icon={<PointIcon />}
                        >
                            {data.statuses_project}
                        </Label>
                    </WrapperFlex>
                </WrapperOffset>
                <WrapperOffset offsetTop="12">
                    <WrapperFlex alignItems="center">
                        <EditInfo className="me-2">
                            {`${editorFullName} редактировал ${editingDate}`}
                        </EditInfo>
                        <NonMvpItem>
                            <EditInfoLink to="#">История изменений</EditInfoLink>
                        </NonMvpItem>
                    </WrapperFlex>
                </WrapperOffset>
            </div>
            <NonMvpItem>
                <WrapperFlex alignItems="center">
                    <Button color="plain" rightIcon={<LinkIcon />} className="me-3">
                        Задачи
                    </Button>
                    <Button color="plain" rightIcon={<LinkIcon />}>
                        Команда
                    </Button>
                </WrapperFlex>
            </NonMvpItem>
        </WrapperFlex>
    );
};
