import styled, {css} from 'styled-components';
import Counter from 'shared/ui/deprecated/Counter/Counter';

export const BtnText = styled.button`
    padding: 0;
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-right: 8px;
    background-color: transparent;
    border: 0;
    .icon {
        font-size: 24px;
        color: var(--secondary);
        pointer-events: none;
    }
`;

export const PriceText = styled.div`
    max-width: calc(100% - 32px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;
export const PriceCaption = styled.div`
    color: var(--secondary);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const PriceAccept = styled.div`
    height: 24px;
    margin-right: 8px;
    min-width: 24px;
    width: 24px;
    --db: none;
    position: relative;
    &:hover {
        --db: block;
    }
    .icon {
        color: var(--secondary);
        font-size: 24px;
    }
`;

export const CommentBtn = styled.button`
    --db: none;
    position: relative;
    background-color: transparent;
    border: none;
    height: 24px;
    padding: 0;
    width: 24px;
    --icon-color: var(--secondary);
    &:hover {
        --icon-color: var(--body-color);
        --db: block;
    }
    .icon {
        color: var(--icon-color);
        font-size: 24px;
        transition-duration: 0.4s;
    }
`;
export const RatioInfo = styled.div`
    --db: none;
    cursor: pointer;
    &:hover {
        --db: block;
    }
    margin-right: 8px;
    .icon {
        color: var(--secondary);
        font-size: 24px;
    }
`;
export const FinalSumString = styled.tr`
    td {
        height: auto;
        padding: 6px 16px;
    }
    border: none;
    font-weight: 500;
`;

export const CounterStatement = styled(Counter)`
    position: absolute;
    z-index: 2;
    bottom: 16px;
    left: 14px;
`;

export const FilesAddsBlock = styled.div`
    min-width: 540px;
    width: 41.5%;
    margin-right: 48px;
    h6 {
        margin-bottom: 8px;
    }
`;
