import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGate, useStore, useUnit} from 'effector-react';
import {Button, Label, Option, TableData, Typography} from '@beeline/design-system-react';
import {AllStatuses} from '@beeline/design-system-react/types/types/status';

import {
    changeDocumentTypeSearchValue,
    changePrimeContractorSearchValue,
    changeSelectedDocumentType,
    changeSelectedPrimeContractor,
    ChangingSelectValueType,
    DocumentTableGate,
    documentTableStore,
    resetAllSelects,
    resetDocumentTypeSearchValue,
    resetPrimeContractorSearchValue,
    setPage,
    setPageSize,
} from 'pages/ProjectViewPage/model/documentTable';
import {Table, TableCell, TableHead, TableString} from 'shared/ui/Table/Table';
import {CoefficientsSkeleton} from 'pages/ApplyCoefficientsPage/ui/CoefficientsSkeleton';
import {NotFound, NotFoundCaption, NotFoundTitle} from 'components/NotFound';
import {TableCellWithTooltip} from 'shared/ui/TableCellWithTooltip';
import {labelVariant} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {formatDateHours} from 'shared/helpers/formatHelper';
import Pagination from 'shared/ui/Pagination/Pagination';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import Select, {SelectItem} from 'shared/ui/Select/Select';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {$isUserGpo, $userActions} from 'shared/model/user';
import {DownloadingButtonWithCard} from 'widgets/DownloadingCard';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions} from 'shared/const/actions';

import * as S from './style';

export const DocumentTable = () => {
    const {projectId} = useParams<{projectId: string}>();
    const navigate = useNavigate();

    useGate(DocumentTableGate, projectId);

    const {
        documentsStore,
        primeContractorsStore,
        documentTypesStore,
        paginationCount,
        tableIsLoading,
        documentListParams: {page, page_size, selectedDocumentType, selectedPrimeContractor},
        primeContractorSearchValue,
        documentTypeSearchValue,
    } = useStore(documentTableStore);
    const [isUserGpo, userActions] = useUnit([$isUserGpo, $userActions]);

    const createButtonVisible =
        userHasPermission(EAccessActions.DocumentsCreation, userActions) ||
        userHasPermission(EAccessActions.FinancialDocumentsCreation, userActions);

    const handleCreateDocument = () => {
        navigate(`document-creation`);
    };

    const handleOpenDocument = (documentId: string) => {
        navigate(`/documents/${documentId}`);
    };

    const handleChangePrimeContractor = (
        value: Option<String>[],
        targetItem: Option<String> | null,
        checked: boolean,
    ) => {
        changeSelectedPrimeContractor({value, targetItem, checked} as ChangingSelectValueType);
    };

    const handleChangeDocumentType = (
        value: Option<String>[],
        targetItem: Option<String> | null,
        checked: boolean,
    ) => {
        changeSelectedDocumentType({value, targetItem, checked} as ChangingSelectValueType);
    };

    const handleResetFilters = () => {
        resetAllSelects();
    };

    return (
        <>
            <S.SelectWrapper>
                {createButtonVisible && (
                    <Button variant="outlined" size="medium" onClick={handleCreateDocument}>
                        Создать
                    </Button>
                )}
                {!isUserGpo && (
                    <>
                        <S.PrimeContractorWrapper>
                            <Select
                                value={selectedPrimeContractor}
                                valueKey="id"
                                valueLabel="value"
                                title="ГПО"
                                handleEdit={handleChangePrimeContractor}
                                searchValue={primeContractorSearchValue}
                                setSearch={changePrimeContractorSearchValue}
                                notAll={primeContractorsStore.length >= 0}
                                handleResetSearch={resetPrimeContractorSearchValue}
                                search
                                multiple
                                fullWidth
                                fullWidthList
                            >
                                {primeContractorsStore.length > 0 ? (
                                    primeContractorsStore.map((item) => (
                                        <SelectItem
                                            key={item.id as string}
                                            value={item}
                                            multiple={false}
                                        >
                                            {item.value}
                                        </SelectItem>
                                    ))
                                ) : (
                                    <WrapperFlex justifyContent="center">
                                        <Typography variant="subtitle3">Нет результатов</Typography>
                                    </WrapperFlex>
                                )}
                            </Select>
                        </S.PrimeContractorWrapper>
                    </>
                )}
                <S.TypeWrapper>
                    <Select
                        value={selectedDocumentType}
                        valueKey="id"
                        valueLabel="value"
                        title="Тип документа"
                        handleEdit={handleChangeDocumentType}
                        searchValue={documentTypeSearchValue}
                        setSearch={changeDocumentTypeSearchValue}
                        notAll={documentTypesStore.length >= 0}
                        handleResetSearch={resetDocumentTypeSearchValue}
                        search
                        multiple
                        fullWidth
                        fullWidthList
                    >
                        {documentTypesStore.length > 0 ? (
                            documentTypesStore.map((item) => (
                                <SelectItem key={item.id as string} value={item} multiple={false}>
                                    {item.value}
                                </SelectItem>
                            ))
                        ) : (
                            <WrapperFlex justifyContent="center">
                                <Typography variant="subtitle3">Нет результатов</Typography>
                            </WrapperFlex>
                        )}
                    </Select>
                </S.TypeWrapper>
            </S.SelectWrapper>
            <S.TableWrapper>
                {documentsStore.length > 0 || tableIsLoading ? (
                    <>
                        <S.TableInnerWrapper>
                            <Table minWidth="1300px">
                                <tbody>
                                    <TableString>
                                        <TableCell>
                                            <TableHead>Тип документа</TableHead>
                                        </TableCell>
                                        <TableCell>
                                            <TableHead>Вложения</TableHead>
                                        </TableCell>
                                        <TableCell width="52px">
                                            <TableHead></TableHead>
                                        </TableCell>
                                        {!isUserGpo && (
                                            <TableCell>
                                                <TableHead>ГПО</TableHead>
                                            </TableCell>
                                        )}
                                        <TableCell width="156px">
                                            <TableHead>Дата создания</TableHead>
                                        </TableCell>
                                        <TableCell width="156px">
                                            <TableHead>Дата ответа</TableHead>
                                        </TableCell>
                                        <TableCell width="84px">
                                            <TableHead>Версия</TableHead>
                                        </TableCell>
                                        <TableCell width="204px">
                                            <TableHead>Автор</TableHead>
                                        </TableCell>
                                        <TableCell width="166px">
                                            <TableHead>Статус</TableHead>
                                        </TableCell>
                                    </TableString>
                                    {tableIsLoading ? (
                                        <CoefficientsSkeleton
                                            pageSize={page_size}
                                            length={isUserGpo ? 8 : 9}
                                        />
                                    ) : (
                                        documentsStore.length > 0 &&
                                        documentsStore.map((item) => {
                                            const attachmentNameList = item.document_file
                                                .map((item) => item.file_name)
                                                .join(', ');
                                            return (
                                                <TableString
                                                    key={item.id}
                                                    onClick={() => handleOpenDocument(item.id)}
                                                    height="53px"
                                                >
                                                    <TableCellWithTooltip
                                                        title={item.subtypes_document}
                                                        lineCount={2}
                                                        dense
                                                    >
                                                        {item.subtypes_document}
                                                    </TableCellWithTooltip>

                                                    <TableCellWithTooltip
                                                        title={attachmentNameList}
                                                        lineCount={2}
                                                        dense
                                                    >
                                                        {attachmentNameList}
                                                    </TableCellWithTooltip>
                                                    <TableData dense>
                                                        <DownloadingButtonWithCard
                                                            documentList={item.document_file}
                                                        />
                                                    </TableData>

                                                    {!isUserGpo && (
                                                        <TableCellWithTooltip
                                                            title={item.organization_gpo ?? '—'}
                                                            lineCount={1}
                                                        >
                                                            {item.organization_gpo ?? '—'}
                                                        </TableCellWithTooltip>
                                                    )}

                                                    <TableCellWithTooltip
                                                        title={formatDateHours(item.created_at)}
                                                        lineCount={1}
                                                    >
                                                        {formatDateHours(item.created_at)}
                                                    </TableCellWithTooltip>

                                                    <TableCellWithTooltip
                                                        title={formatDateHours(item.approved_at)}
                                                        lineCount={1}
                                                    >
                                                        {formatDateHours(item.approved_at)}
                                                    </TableCellWithTooltip>
                                                    <TableCellWithTooltip
                                                        title={`${item.prefix_version.toString()}.${item.version.toString()}`}
                                                        lineCount={1}
                                                    >
                                                        {`${item.prefix_version.toString()}.${item.version.toString()}`}
                                                    </TableCellWithTooltip>

                                                    <TableCellWithTooltip
                                                        title={item.editor.full}
                                                        lineCount={1}
                                                    >
                                                        {item.editor.full}
                                                    </TableCellWithTooltip>

                                                    <TableData dense>
                                                        {item.status ? (
                                                            <Label
                                                                title={item.status.name}
                                                                type={
                                                                    labelVariant(
                                                                        item.status.token,
                                                                    ) as AllStatuses
                                                                }
                                                            />
                                                        ) : (
                                                            '—'
                                                        )}
                                                    </TableData>
                                                </TableString>
                                            );
                                        })
                                    )}
                                </tbody>
                            </Table>
                        </S.TableInnerWrapper>

                        <WrapperOffset offsetY="13" offsetX="16">
                            <Pagination
                                total={paginationCount}
                                page={page}
                                pageSize={page_size}
                                setPage={setPage}
                                setPageSize={setPageSize}
                            />
                        </WrapperOffset>
                    </>
                ) : (
                    <NotFound height="500px">
                        <NotFoundTitle>По заданным фильтрам ничего не найдено</NotFoundTitle>
                        <NotFoundCaption>
                            Попробуйте изменить параметры или сбросьте фильтры
                        </NotFoundCaption>
                        <Button className="mt-3" variant="plain" onClick={handleResetFilters}>
                            Сбросить
                        </Button>
                    </NotFound>
                )}
            </S.TableWrapper>
        </>
    );
};
