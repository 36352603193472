import {ActionLabel} from 'Entities/ActionLabel';
import {TableCell, TableCellContent} from 'shared/ui/Table/Table';

interface StatusCellProps {
    confirmed: boolean;
}

export function StatusCell({confirmed}: StatusCellProps) {
    return (
        <TableCell width="30px">
            <TableCellContent>
                <ActionLabel condition={confirmed} />
            </TableCellContent>
        </TableCell>
    );
}
