import React from 'react';

import {Skeleton} from 'shared/ui/deprecated/Skeleton/Skeleton';
import {IColumn} from 'shared/types/tableTypes';
import {IColumnCompletedWorksListItem} from 'shared/types/completedWorksTypes';
import {OuterTableRow, StyledTableData} from 'shared/styles/commonStyle';

export interface CompletedWorksTableSkeletonProps<T> {
    columns: IColumn<T>[];
    pageSize?: number;
}

export const CompletedWorksTableSkeleton = ({
    columns,
    pageSize = 10,
}: CompletedWorksTableSkeletonProps<IColumnCompletedWorksListItem>) => {
    return (
        <>
            {[...Array(pageSize)].map((x, i) => (
                <OuterTableRow key={i} dense>
                    {columns.map(
                        ({visible = true, ...item}) =>
                            visible && (
                                <StyledTableData key={`${item.name} ${i}`}>
                                    <Skeleton height={20} width="18px" />
                                </StyledTableData>
                            ),
                    )}
                </OuterTableRow>
            ))}
        </>
    );
};
