import React from 'react'
import { WrapperFlexStyled } from 'shared/ui/Wrappers/style'

function WrapperFlex(props) {
	const {
		children,
		flexDirection,
		justifyContent,
		alignItems,
		...rest
	} = props
	return (
		<WrapperFlexStyled {...props}>
			{children}
		</WrapperFlexStyled>
	)
}

export default WrapperFlex;