import {v4 as uuidv4} from 'uuid';
import {round} from 'lodash';
import {format} from 'date-fns';

import {CatalogWorkObject, CatalogWorkObjectOption} from 'pages/VirStart/types';
import {EWorkObjectTypes} from 'shared/types/additionalAgreementsTypes';

export function formattedStringToNumber(string): number {
    const numberWithoutCommas = Number(string?.replace(/\s/g, '').replace(',', '.'));
    return numberWithoutCommas || 0;
}

export function formatNumberToDecimalWithComma(
    number: number | bigint | null | string,
    defaultNumber = 0,
    maximumFractionDigits = 4,
): string {
    if (number === null || number === undefined) {
        return '';
    }
    const convertedNumber = typeof number === 'string' ? +number : number;

    return new Intl.NumberFormat('ru', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: maximumFractionDigits,
    })?.format(convertedNumber || defaultNumber);
}

export function formatDate(date: string | Date | null, dateFormat = 'dd.MM.yyyy') {
    return date ? format(new Date(date), dateFormat) : '—';
}

export function formatDateToBackendFormat(date: string) {
    return date && new Date(date) ? formatDate(date, 'yyyy-MM-dd') : '';
}

export function isDateRangeValid(startDate: string, endDate: string) {
    const currentDate = Date.now();
    return currentDate >= Date.parse(startDate) && currentDate <= Date.parse(endDate);
}

export function formatNumber(number: number, fraction = 2): number {
    if (fraction === 2) {
        let calculatedNumber = +round(number, 4).toFixed(4);
        // избавляемся от четвертого знака после запятой
        calculatedNumber = +(calculatedNumber * 1000).toFixed(1) / 1000;
        return round(calculatedNumber, 2);
    }
    return round(number, fraction);
}

export function formatDateHours(date: string | null) {
    if (!date) {
        return '—';
    }
    return format(new Date(date), 'dd.MM.yyyy, HH:mm');
}

export function formatCurrency(num: string | number) {
    return new Intl.NumberFormat('ru', {minimumFractionDigits: 2}).format(+num);
}

export function addLabelToWorkOptionBasedOnType(
    workObject: CatalogWorkObject,
): CatalogWorkObjectOption[] {
    if (!workObject) return [];
    let bs: CatalogWorkObjectOption = {
        id: `front -${uuidv4()}`,
        label: `БС ${workObject?.bs_gfk} ${workObject?.bs_name}`,
        object_type: EWorkObjectTypes.BS,
        gfk: workObject?.bs_gfk,
        pos_code: workObject?.pos_code,
    };

    let result: CatalogWorkObjectOption[] = [bs];

    if (workObject?.transport_nets) {
        workObject.transport_nets?.forEach((item) => {
            let nets: CatalogWorkObjectOption[] = [];
            const main: CatalogWorkObjectOption = {
                id: `front -${uuidv4()}`,
                label: `${item?.rru_gfk || ''} ${workObject?.bs_name}`,
                object_type: EWorkObjectTypes.MAIN_PART,
                gfk: item?.rru_gfk,
                pos_code: item?.pos_code,
                transport_net_id: item?.transport_net_id,
                is_half_set: true,
            };
            const response: CatalogWorkObjectOption = {
                id: `front -${uuidv4()}`,
                label: `${item?.response_rru_gfk || ''} ${item?.response_rru_name}`,
                object_type: EWorkObjectTypes.RESPONSE_PART,
                transport_net_id: item?.transport_net_id,
                gfk: item?.response_rru_gfk,
                pos_code: item?.response_pos_code,
                is_half_set: true,
            };
            const lav: CatalogWorkObjectOption = {
                id: `front -${uuidv4()}`,
                label: item?.lav_gfk || '',
                nested: false,
                gfk: item?.lav_gfk,
                lavParts: [],
            };
            if (item?.rru_gfk) {
                nets.push(main);
                lav.lavParts?.push({
                    id: `front -${uuidv4()}`,
                    object_type: EWorkObjectTypes.MAIN_PART,
                    is_half_set: false,
                    object_name: main.label,
                    transport_net_id: item?.transport_net_id,
                    gfk: item.rru_gfk,
                });
            }
            if (item?.response_rru_gfk) {
                nets.push(response);
                lav.lavParts?.push({
                    id: `front -${uuidv4()}`,
                    is_half_set: false,
                    transport_net_id: item.transport_net_id,
                    object_type: EWorkObjectTypes.RESPONSE_PART,
                    object_name: response.label,
                    gfk: item.response_rru_gfk,
                });
            }
            if (lav.gfk) {
                nets.forEach((item) => (item.nested = true));
                nets.unshift(lav);
            }
            result = [...result, ...nets];
        });
    }

    return result;
}
