import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import React from 'react';
import HeaderVir from 'components/HeaderVir/HeaderVir';
import {BodyContent, MainWrapper} from 'components/PageWraps';
import {H4} from 'shared/ui/deprecated/HeaderText/HeaderText';
import Button from 'shared/ui/deprecated/Button/Button';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {
    $patternsPage,
    searchByTerm,
    setSearchTerm,
    PatternsPageGate,
} from 'pages/VirPatternsPage/model';
import {useGate, useStore} from 'effector-react';
import {PatternsTable} from 'pages/VirPatternsPage/ui/PattersTable';
import {DeleteTemplateModal} from 'pages/VirPatternsPage/ui/DeletePatterModal';
import * as S from './styled';

export function VirPatternsPage() {
    useGate(PatternsPageGate);
    const {projectId} = useParams();
    const navigate = useNavigate();
    const {selectedPattern, searchTerm} = useStore($patternsPage);

    return (
        <MainWrapper>
            <DeleteTemplateModal />
            <HeaderVir />
            <BodyContent>
                <WrapperOffset offset="32" className="w-100">
                    <S.VirSelectBlock>
                        <H4>Выберите шаблон</H4>
                        <WrapperOffset offsetTop="24" offsetBottom="24">
                            <S.SearchStyled
                                value={searchTerm}
                                onInput={setSearchTerm}
                                placeholder="Поиск по названию"
                                onSearch={searchByTerm}
                            />
                        </WrapperOffset>
                        <PatternsTable />
                        <S.Wrapper>
                            <S.Text>
                                <span> Нет нужного шаблона? </span>
                                <Link to={`/vir/${projectId}/patterns/create`}>
                                    {' '}
                                    Создать новый{' '}
                                </Link>
                            </S.Text>
                            <S.Buttons>
                                <Button
                                    className="me-3"
                                    color="outline"
                                    onClick={() =>
                                        navigate(
                                            `/projects/${projectId}/additional-agreement/create`,
                                        )
                                    }
                                >
                                    Назад
                                </Button>
                                <Button
                                    color="contained"
                                    onClick={() => navigate(`/additional-agreement/constructor`)}
                                    disabled={!selectedPattern}
                                >
                                    Применить
                                </Button>
                            </S.Buttons>
                        </S.Wrapper>
                    </S.VirSelectBlock>
                </WrapperOffset>
            </BodyContent>
        </MainWrapper>
    );
}
