import React from 'react';
import {Skeleton} from '@beeline/design-system-react';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

interface InformationalUnitSkeletonProps {
    gridArea?: string;
}

export const InformationalUnitSkeleton = ({gridArea}: InformationalUnitSkeletonProps) => {
    return (
        <WrapperFlex flexDirection="column" gridArea={gridArea} gap="4px">
            <Skeleton height={16} width={100} variant="text" />
            <Skeleton height={20} width={150} variant="text" />
        </WrapperFlex>
    );
};
