import React from "react";
import { cloneDeep } from 'lodash'

export const ContextApp = React.createContext();

const project = (data) => {
    return {
        // appendix_number: 1,
        name: `БС ${data.code_GFK}`,
        // isnds: false,
        total_cost_ex_vat: 1,
        vat_total: 1,
        total_cost: 1,
        // ds: null,
        // vir_work_type: state.type.id,
        // vat_calculation_scheme: null,
        vir_works: []
    }
}

export const initialState = {
    projectData: {},
    type: {},
    comment: '',
    prepayment: false,
    projectVirs: [],
    currentVir: 0,
    files: [],
};

export const initializer = (initialValue = initialState) => JSON.parse(localStorage.getItem("ds-state")) || initialValue;

const handleAction = (state, action) => {
    switch (action.type) {
        case 'update':
            return {
                ...state,
                ...action.payload
            };
        // case 'updateProjectName':
        //     return {
        //         ...state,
        //         projectVirs: [{ ...project, ...action.payload }]
        //     };
        case 'updateComment':
            return {
                ...state,
                comment: action.payload
            };
        case 'addProject':
            return {
                ...state,
                projectVirs: [
                    ...state.projectVirs,
                    project(action.payload)
                ]
            };
        case 'updateCurrentVir':
            return {
                ...state,
                projectVirs: Object.values({
                    ...state.projectVirs,
                    [state.currentVir]: {
                        ...state.projectVirs[state.currentVir],
                        vir_works: action.payload
                    }
                })
            };
        case 'removeVir':
            return {
                ...state,
                projectVirs: Object.values({
                    ...state.projectVirs,
                    [state.currentVir]: {
                        ...state.projectVirs[state.currentVir],
                        vir_works: state.projectVirs[state.currentVir].vir_works.filter((item, index) => action.payload !== index)
                    }
                })
            };
        case 'updateFiles':
            return {
                ...state,
                files: action.payload
            };
        case 'updateSummary':
            return {
                ...state,
                projectVirs: Object.values({
                    ...state.projectVirs,
                    [state.currentVir]: {
                        ...state.projectVirs[state.currentVir],
                        total_cost_ex_vat: action.payload.total,
                        vat_total: action.payload.nds,
                        total_cost: action.payload.totalNDS,
                    }
                })
            };
        case 'clear':
            return initialState
        default:
            return state
    }
}

export const reducer = (state, action) => {
    const newState = handleAction(state, action)

    const projectVirs = cloneDeep(newState.projectVirs);
    projectVirs.map(vir => {
        vir.vir_works.map(work => {
            work.files = [];
            return work
        })
        return vir
    })

    localStorage.setItem('ds-state', JSON.stringify({
        ...newState,
        files: [],
        projectVirs,
    }))

    return newState
};
