import React from 'react';
import {IconButton} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {TableCell} from 'shared/ui/TableCell';

interface DeleteCellProps {
    onClick: () => void;
}

export const DeleteCell = ({onClick}: DeleteCellProps) => {
    return (
        <TableCell width="52px">
            <IconButton iconName={Icons.Delete} onClick={onClick} />
        </TableCell>
    );
};
