import styled, {css} from 'styled-components/macro';

const CheckboxStyledMixin = (props) => css`
    cursor: pointer;
    border-radius: 0.25em;
    border: 1px solid #d5d6da;
    display: block;
    height: 24px;
    margin-right: 16px;
    width: 24px;
    min-width: 24px;
    appearance: none;
    background-color: #ffffff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    float: left;
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: none !important;
    border-color: #d1d1d1 !important;
    transition-duration: 0.4s;

    &:checked {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4LjY0NjUgNy4wNDY4OEMxOC41MzcxIDcuMTA5MzcgMTcuMjYzNyA4LjQzNzUgMTQuODI2MiAxMS4wMzEyTDExLjE2OTkgMTQuOTIxOUw5LjM0MTggMTMuMDcwM0M4LjQ5ODA1IDEyLjIyNjYgNy45NjY4IDExLjcwMzEgNy43NDgwNSAxMS41QzcuNTQ0OTIgMTEuMjk2OSA3LjQwNDMgMTEuMTg3NSA3LjMyNjE3IDExLjE3MTlDNy4xMDc0MiAxMS4xMDk0IDYuOTA0MyAxMS4xMjUgNi43MTY4IDExLjIxODhDNi41NDQ5MiAxMS4yOTY5IDYuNDA0MyAxMS40Mjk3IDYuMjk0OTIgMTEuNjE3MkM2LjI0ODA1IDExLjcxMDkgNi4yMTY4IDExLjc4OTEgNi4yMDExNyAxMS44NTE2QzYuMjAxMTcgMTEuODk4NCA2LjIwMTE3IDExLjk2ODggNi4yMDExNyAxMi4wNjI1QzYuMjE2OCAxMi4yMDMxIDYuMjQwMjMgMTIuMzEyNSA2LjI3MTQ4IDEyLjM5MDZDNi4zMTgzNiAxMi40NTMxIDcuMTM4NjcgMTMuMzA0NyA4LjczMjQyIDE0Ljk0NTNDMTAuMzQxOCAxNi41ODU5IDExLjE2MjEgMTcuNDA2MiAxMS4xOTM0IDE3LjQwNjJDMTEuMjI0NiAxNy40MDYyIDEyLjY2MjEgMTUuODkwNiAxNS41MDU5IDEyLjg1OTRDMTguMzY1MiA5LjgyODEyIDE5LjgxMDUgOC4yNzM0NCAxOS44NDE4IDguMTk1MzFDMTkuODg4NyA4LjExNzE5IDE5LjkxMjEgNy45OTIxOSAxOS45MTIxIDcuODIwMzFDMTkuOTEyMSA3LjY0ODQ0IDE5Ljg3MyA3LjUgMTkuNzk0OSA3LjM3NUMxOS43MzI0IDcuMjM0MzggMTkuNjM4NyA3LjEzMjgxIDE5LjUxMzcgNy4wNzAzMUMxOS40MDQzIDcuMDA3ODEgMTkuMjYzNyA2Ljk3NjU2IDE5LjA5MTggNi45NzY1NkMxOC45MTk5IDYuOTYwOTQgMTguNzcxNSA2Ljk4NDM4IDE4LjY0NjUgNy4wNDY4OFoiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuODciLz4KPC9zdmc+Cg==') !important;
        background-color: #fdd835;
        border-color: transparent !important;

        &:focus {
            border-color: #202123 !important;
        }

        &:focus:active {
            background-color: #fdb435;
            border-color: transparent !important;
        }
    }

    &:hover:checked {
        background-color: #fdc435;
    }

    &:hover {
        background-color: #dedfe2;
    }

    &:focus {
        border-color: #202123 !important;
    }
    &:focus:active {
        background-color: #c8c9ce;
        border-color: #d1d1d1 !important;
    }

    ${props.disabled &&
    css`
        background-color: var(--light-control);
        cursor: default;
    `}
`;

export const CheckboxStyled = styled.input`
    ${(props) => CheckboxStyledMixin(props)}
`;

const CheckboxPartlyStyledMixin = (props) => css`
    cursor: pointer;
    border-radius: 0.25em;
    border: 1px solid #d5d6da;
    display: block;
    height: 24px;
    margin-right: 16px;
    width: 24px;
    min-width: 24px;
    appearance: none;
    background-color: #ffffff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    float: left;
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: none !important;
    border-color: #d1d1d1 !important;
    transition-duration: 0.4s;

    // &:checked{
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNLjc0Mi4yOTdhLjcxNy43MTcgMCAwIDAtLjQ2OS41MzkuNjYyLjY2MiAwIDAgMCAuMjExLjY4Yy4wOTQuMTA5LjE5Ni4xOC4zMDUuMjEuMTEuMDMyIDIuMTguMDQ3IDYuMjExLjA0NyA0LjAzMSAwIDYuMTAyLS4wMTUgNi4yMS0uMDQ2YS43MDUuNzA1IDAgMCAwIC4zMDYtLjE4OC43OC43OCAwIDAgMCAuMjEtLjMyOC43MS43MSAwIDAgMC0uMDkzLS41NjMuNzExLjcxMSAwIDAgMC0uNDIyLS4zNzVDMTMuMTE3LjI1OCAxMS4wMzkuMjUgNi45NzcuMjVjLTIuNTE2IDAtNC4xNzIuMDA4LTQuOTcuMDIzLS43OCAwLTEuMjAyLjAwOC0xLjI2NS4wMjRaIiBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9Ii44NyIvPjwvc3ZnPg==) !important;
    background-size: 16px;
    background-color: #fdd835;
    border-color: transparent !important;
    // }
    ${props.disabled &&
    css`
        opacity: 0.48;
        pointer-events: none;
    `}
    &:hover {
        background-color: #fdc435;
    }

    &:focus {
        border-color: #202123 !important;
    }

    &:focus:active {
        background-color: #fdb435;
        border-color: transparent !important;
    }
`;

export const CheckboxPartlyStyled = styled.input`
    ${(props) => CheckboxPartlyStyledMixin(props)}
`;

export const CheckboxLabelStyled = styled.label<{disabled: boolean}>`
    background-color: #fff;
    cursor: ${(p) => (p.disabled ? `default` : 'pointer')};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 1.5rem;
    padding: 12px 16px;
    transition-duration: 0.4s;
    ${(p) => p.disabled && `opacity: 0.5;`}
    & > * {
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const CheckboxCaption = styled.span`
    font-size: 17px;
    line-height: calc(24 / 17);
`;
