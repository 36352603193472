export enum EFileUploadStatus {
    Error = 'error',
    Loading = 'loading',
    Upload = 'upload',
}

export const COMMA_SEPARATOR = ',';
export const DOT_SEPARATOR = '.';
export const EMPTY_NUMBERED_VALUE = 0;
export const EMPTY_STRING = '';
export const SPACE_SEPARATOR = ' ';
export const VERTICAL_DIVIDER = '|';
export const EMPTY_DATA_OBJ = {};
export const FORMATS_STRING = 'Форматы файлов';
export const EXTERNAL_ERROR_TEXT = 'Добавьте вложения с обоснованием применения повышающего коэффициента';