import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useStore} from 'effector-react';
import {Icon} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {createNewAdditionalAgreement} from 'processes/contructor/model/stores';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

import {ActionButton} from './style';
import {
    $createVirStartPageData,
    $createVirStartPageOptions,
    $selectedProject,
    $touchedForm,
    validateForm,
} from '../../model';

export function ActionWrap() {
    const navigate = useNavigate();
    const {isFormValid} = useStore($createVirStartPageOptions);
    const {selectedProject, selectedTpi, selectedObject, selectedWorkType} =
        useStore($createVirStartPageData);
    const project_id = useStore($selectedProject)?.project_id;
    const touched = useStore($touchedForm);
    const disabledTCP = !(selectedObject && selectedTpi && selectedWorkType);
    const redirectToPatterns = () => {
        navigate(`/vir/${project_id}/patterns`);
    };

    const handleTCP = () => {
        if (selectedProject) {
            validateForm();
            createNewAdditionalAgreement();
        }
    };

    const handleCopyWorkProgressSheetItems = () => {
        if (selectedProject) {
            navigate(`/projects/${project_id}/additional-agreement/copy-agreement`);
        }
    };

    useEffect(() => {
        if (isFormValid && touched) {
            navigate(`/projects/${project_id}/additional-agreement/select`);
        }
    }, [isFormValid, navigate, project_id, selectedTpi?.tpis_id, touched]);

    return (
        <WrapperOffset offsetTop="36">
            <WrapperFlex gap="24px">
                <ActionButton variant="green" disabled={true}>
                    <Icon iconName={Icons.RulerPencil} contained color="grey" size="medium" />
                    Добавить пункты из задания на ПИР
                </ActionButton>
                <ActionButton disabled={true} variant="blue" onClick={redirectToPatterns}>
                    <Icon iconName={Icons.PageStar} contained color="grey" size="medium" />
                    Применить шаблон
                </ActionButton>
                <ActionButton
                    id="addTpiItems"
                    variant="purple"
                    disabled={disabledTCP}
                    onClick={handleTCP}
                >
                    <Icon
                        iconName={Icons.ListNumbers}
                        contained
                        color={disabledTCP ? 'grey' : 'purple'}
                        size="medium"
                    />
                    Добавить пункты из ТЦП
                </ActionButton>
                <ActionButton
                    variant="teal"
                    disabled={disabledTCP}
                    onClick={handleCopyWorkProgressSheetItems}
                >
                    <Icon
                        iconName={Icons.PagesMultiple}
                        contained
                        color={disabledTCP ? 'grey' : 'green'}
                        size="medium"
                    />
                    Добавить пункты из другого ВИР
                </ActionButton>
            </WrapperFlex>
        </WrapperOffset>
    );
}
