import {ColorTypes} from '@beeline/design-system-react/types/types/status';
import {IEmployeeInfo} from '../types/userTypes';

export const getAvatarColorType = (person: IEmployeeInfo): ColorTypes => {
    const avatarNameLength = `${person.first_name}${person.last_name}${person.position}`.length;
    const index = avatarNameLength % 8;
    switch (index) {
        case 0: {
            return 'grey';
        }
        case 1: {
            return 'red';
        }
        case 2: {
            return 'green';
        }
        case 3: {
            return 'orange';
        }
        case 4: {
            return 'blue';
        }
        case 5: {
            return 'purple';
        }
        case 6: {
            return 'magenta';
        }
        case 7: {
            return 'teal';
        }
        default: {
            return 'grey';
        }
    }
};