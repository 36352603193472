import React from 'react';
import {useStore} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import {InformationalUnit} from 'Entities/InformationalUnit';
import {$createVirStartPageData} from 'pages/VirStart/model';
import {WorkObjectSelect} from 'pages/VirStart/ui/VirStartData/WorkObjectSelect';
import {ProjectSelect} from 'pages/VirStart/ui/VirStartData/ProjectSelect';
import {GFK_CODE_TITLE, POSITION_NUMBER_TITLE} from 'shared/constants';

import {WorkTypeSelect} from './WorkTypeSelect';
import {ContractSelect} from './ContractSelect';
import {TpiSelect} from './TpiSelect';
import * as S from './style';

export function VIRData() {
    const {selectedProject, fromProject, selectedObject} = useStore($createVirStartPageData);

    const title = fromProject ? 'Проверьте данные и укажите тип работ:' : 'Выберите проект';
    return (
        <S.Wrapper>
            <Typography variant="h4">{title}</Typography>

            {!fromProject && <ProjectSelect />}

            {(fromProject || selectedProject) && (
                <S.VIRDataStyled>
                    <S.GridWrapper>
                        <WorkObjectSelect />
                        <WorkTypeSelect />
                        <S.GfkWrapper>
                            {selectedObject?.gfk && (
                                <InformationalUnit title={GFK_CODE_TITLE}>
                                    {selectedObject?.gfk}
                                </InformationalUnit>
                            )}
                        </S.GfkWrapper>
                        <S.PosCodeWrapper>
                            {selectedObject?.pos_code && (
                                <InformationalUnit title={POSITION_NUMBER_TITLE}>
                                    {selectedObject?.pos_code}
                                </InformationalUnit>
                            )}
                        </S.PosCodeWrapper>
                        <ContractSelect />
                        <TpiSelect />
                    </S.GridWrapper>
                </S.VIRDataStyled>
            )}
        </S.Wrapper>
    );
}
