import {ProjectsColumnStateType, ProjectsColumnType} from 'shared/types/projectTypes';

export const defaultColumns: Array<ProjectsColumnType> = [
    {
        name: 'project_ext_id',
        label: 'Номер',
        width: 'calc(110 / 1900 * 100%)',
        minWidth: '110px',
        visible: true,
    },
    {
        name: 'open_date',
        label: 'Дата открытия',
        width: 'calc(160 / 1900 * 100%)',
        minWidth: '160px',
        visible: true,
    },
    {
        name: 'year',
        label: 'Год ПРС',
        // filter: true,
        width: 'calc(113 / 1900 * 100%)',
        minWidth: '113px',
        visible: true,
    },
    {
        name: 'bs_name',
        label: 'Базовая станция',
        width: 'calc(196 / 1900 * 100%)',
        minWidth: '196px',
        visible: true,
    },
    {
        name: 'pos_code',
        label: 'Номер позиции',
        width: 'calc(167 / 1900 * 100%)',
        minWidth: '167px',
        visible: true,
    },
    {
        name: 'bs_gfk',
        label: 'Код ГФК',
        width: 'calc(180 / 1900 * 100%)',
        minWidth: '180px',
        visible: true,
    },
    {
        name: 'types_project',
        label: 'Тип',
        width: 'calc(135 / 1900 * 100%)',
        minWidth: '135px',
        visible: true,
    },
    {
        name: 'gpo',
        label: 'Основной ГПО',
        width: 'calc(212 / 1900 * 100%)',
        minWidth: '212px',
        visible: true,
    },
    {
        name: 'status',
        label: 'Статус',
        width: 'calc(391 / 1900 * 100%)',
        minWidth: '391px',
        visible: true,
    },
    {
        name: 'branch',
        label: 'Филиал',
        width: 'calc(167 / 1900 * 100%)',
        minWidth: '167px',
        visible: true,
    },
    {
        name: 'region',
        label: 'Регион',
        width: 'calc(167 / 1900 * 100%)',
        minWidth: '167px',
        visible: true,
    },
];

const initialState: ProjectsColumnStateType = {
    columns: defaultColumns,
    editColumns: defaultColumns,
};
export const getBaseColumnFilter = () => initialState;

export const getInitialColumnFilter = () => {
    const projectTableLocalState = localStorage.getItem('projectTable');
    if (projectTableLocalState) {
        return JSON.parse(projectTableLocalState) as ProjectsColumnStateType;
    }
    return initialState;
};
