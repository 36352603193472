import React from 'react';
import {Typography} from '@beeline/design-system-react';

import {Comment} from 'Entities/Comment';
import {JobCategoryUnit} from 'Entities/JobCategoryUnit';
import {DownloadableAttachment} from 'Features/DownloadableAttachment';
import {TableString} from 'shared/ui/Table/Table';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {EAttachmentType, TAttachmentFile} from 'shared/types/FileTypes';

import {StyledTableData} from 'shared/styles/commonStyle';
import * as S from './style';
import {IComment} from '../../../../../../DSPage/types';

interface AttachmentRowProps {
    commentList: IComment[];
    parentCommentList: IComment[];
    attachmentList: TAttachmentFile[];
    parentAttachmentList: TAttachmentFile[];
    loadingAttachmentId: string;
    handleDownloadAttachment: (fileId: string, fileName: string, type: EAttachmentType) => void;
    jobCategory: {id: string; work_category: string} | null;
    jobCategoryDescription: string | null;
}

export const AttachmentRow = ({
    commentList,
    parentCommentList,
    attachmentList,
    parentAttachmentList,
    handleDownloadAttachment,
    loadingAttachmentId,
    jobCategory,
    jobCategoryDescription,
}: AttachmentRowProps) => {
    return (
        <TableString>
            <StyledTableData colSpan={11}>
                <S.UnitWrapper>
                    <WrapperFlex flexDirection="column" gap="8px" flex="1 1 500px" minWidth="400px">
                        <JobCategoryUnit
                            jobCategory={jobCategory}
                            jobCategoryDescription={jobCategoryDescription}
                        />
                        {!!attachmentList.length && <Typography variant="h6">Вложения</Typography>}
                        {attachmentList.map((attachment) => (
                            <DownloadableAttachment
                                id={attachment.id}
                                key={attachment.id}
                                type={attachment.type}
                                title={attachment.file_name}
                                handleDownloadAttachment={handleDownloadAttachment}
                                isLoading={loadingAttachmentId === attachment.id}
                                disabled={loadingAttachmentId === attachment.id}
                            />
                        ))}
                        {!!parentAttachmentList.length && (
                            <Typography variant="h6">Вложения из ДС</Typography>
                        )}
                        {parentAttachmentList.map((attachment) => (
                            <DownloadableAttachment
                                id={attachment.id}
                                key={attachment.id}
                                type={attachment.type}
                                title={attachment.file_name}
                                handleDownloadAttachment={handleDownloadAttachment}
                                isLoading={loadingAttachmentId === attachment.id}
                                disabled={loadingAttachmentId === attachment.id}
                            />
                        ))}
                    </WrapperFlex>
                    <S.CommentWrapper>
                        {!!commentList.length && (
                            <>
                                <Typography variant="h6">Комментарии к пункту работ</Typography>
                                {commentList.map((comment) => (
                                    <Comment key={comment.id} {...comment} />
                                ))}
                            </>
                        )}
                        {!!parentCommentList.length && (
                            <S.ParentCommentWrapper>
                                <Typography variant="h6">
                                    Комментарии к пункту работ из ДС
                                </Typography>
                                {parentCommentList.map((comment) => (
                                    <Comment key={comment.id} {...comment} />
                                ))}
                            </S.ParentCommentWrapper>
                        )}
                    </S.CommentWrapper>
                </S.UnitWrapper>
            </StyledTableData>
        </TableString>
    );
};
