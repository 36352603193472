import {memo, Suspense, useCallback} from 'react';
import {Route, Routes} from 'react-router-dom';

import {AppRoutesProps, routeConfig} from 'shared/config/routeConfig';

import {RequireAuth} from './RequireAuth';

function AppRouter() {
    const renderWithWrapper = useCallback((route: AppRoutesProps) => {
        const element = <Suspense fallback={<div>Loading...</div>}>{route.element}</Suspense>;
        return (
            <Route
                key={route.path}
                path={route.path}
                element={
                    route.authOnly ? (
                        <RequireAuth accessAction={route.accessAction}>{element}</RequireAuth>
                    ) : (
                        element
                    )
                }
            />
        );
    }, []);

    return <Routes>{Object.values(routeConfig).map(renderWithWrapper)}</Routes>;
}

export default memo(AppRouter);
