import React from 'react';
import {TableData, TableRow as Row, Typography} from '@beeline/design-system-react';

import {Table, TableCell, TableHead, TableString} from 'shared/ui/Table/Table';
import {TpiSpecificationOutputType} from 'shared/types/jobsTypes';
import {formatCurrency} from 'shared/helpers/formatHelper';

import {TableRow} from './TableRow';
import * as S from './style';

interface JobSpecificationTableProps {
    jobTaskSpecifications: TpiSpecificationOutputType[];
    totalSum: number;
    onChangeQuantity?: (data: {id: string; value: number}) => void;
    onDeleteRow?: (id: string) => void;
}

export const JobSpecificationTable = ({
    jobTaskSpecifications,
    totalSum,
    onChangeQuantity,
    onDeleteRow,
}: JobSpecificationTableProps) => {
    return (
        <S.TableWrapper className="customScrollbar">
            <Table minWidth="848px">
                <tbody>
                    <TableString>
                        <TableCell width="80px">
                            <TableHead>№ п.п.</TableHead>
                        </TableCell>
                        <TableCell width={`calc(100%-${onDeleteRow ? 731 : 679}px)`}>
                            <TableHead>Наименование работ</TableHead>
                        </TableCell>
                        <TableCell width="167px">
                            <TableHead>Ед. измерения</TableHead>
                        </TableCell>
                        <TableCell width="86px">
                            <TableHead right>Кол-во</TableHead>
                        </TableCell>
                        <TableCell width="121px">
                            <TableHead right>Цена за ед., ₽</TableHead>
                        </TableCell>
                        <TableCell width="104px">
                            <TableHead right>Коэф-т</TableHead>
                        </TableCell>
                        <TableCell width="121px">
                            <TableHead right>Стоимость, ₽</TableHead>
                        </TableCell>
                        {onDeleteRow && (
                            <TableCell width="52px">
                                <TableHead></TableHead>
                            </TableCell>
                        )}
                    </TableString>

                    {jobTaskSpecifications.map((item) => (
                        <TableRow
                            key={item.tpi_specification_id}
                            data={item}
                            onChangeQuantity={onChangeQuantity}
                            onDelete={onDeleteRow}
                        />
                    ))}

                    <Row>
                        <TableData colSpan={6} alignRight>
                            <Typography variant="subtitle3">
                                Итоговая стоимость (может быть уточнена в процессе выполнения):
                            </Typography>
                        </TableData>
                        <TableData alignRight>
                            <Typography variant="subtitle3">{formatCurrency(totalSum)}</Typography>
                        </TableData>
                        {onDeleteRow && <TableData />}
                    </Row>
                </tbody>
            </Table>
        </S.TableWrapper>
    );
};
