import {bindAllMethods} from 'shared/helpers/binder';
import {FullVirPattern} from '../types';

export const patternService = bindAllMethods({
    async getPatternById(pattern_id: string): Promise<FullVirPattern> {
        // return axiosInstance.get('/pattern_works/pattern_works/:id', props)
        return new Promise((res) => {
            setTimeout(
                () =>
                    res({
                        id: 's',
                        pattern_work_objects: [
                            {
                                id: 's',
                                object_type: 'ТПУ ответный',
                                pattern_work_specification_list: [
                                    {
                                        id: 'pattern_work_specification_list',
                                        tpi_number: 'tpi_number',
                                        tpi_name: 'tpi_name',
                                        quantity: 'quantity',
                                        coefficient: 'coefficient',
                                        coefficient_remote_territory:
                                            'coefficient_remote_territory',
                                        coefficient_foundation: 'coefficient_foundation',
                                        pattern_work_coefficients: [
                                            {
                                                id: 'id',
                                                is_manual: true,
                                                utpi_specification_id: 'utpi_specification_id',
                                                tpi_specification_id: 'tpi_specification_id',
                                                pattern_work_specification_id:
                                                    'pattern_work_specification_id',
                                                coefficient: 'coefficient',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    }),
                500,
            );
        });
    },
});
