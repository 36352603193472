import {combine, createDomain, forward, sample} from 'effector';
import {createGate} from 'effector-react';
import {getJob, postJobComment} from 'shared/services/jobs.service';
import {JobType, NriRllFileType} from 'shared/types/jobsTypes';
import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';
import {getTask, getTasksList, updateTask} from 'shared/services/tasks.service';
import {ITaskResponse} from 'pages/DSPage/types';
import {additionalAgreementService} from 'pages/DSPage/service';
import {fetchNriRllList} from 'shared/services/files.service';
import {
    downloadAttachmentFileFx,
    downloadNriBsFileFx,
    downloadNriRllFileFx,
} from 'processes/CreationJobProcess/model/stage3';
import {TTaskList} from 'shared/types/tasksTypes';
import {or} from 'patronum';

export const JobViewPageDomain = createDomain();
export const JobViewPageGate = createGate<string>();

//events
export const fetchJob = JobViewPageDomain.createEvent<string>();
export const handleWorkflowBtn = JobViewPageDomain.createEvent<number>();
export const handleAcceptWorkflowBtn = JobViewPageDomain.createEvent<string>();
export const handleCancelWorkflowBtn = JobViewPageDomain.createEvent<string>();
export const setComment = JobViewPageDomain.createEvent<string>();
export const sendComment = JobViewPageDomain.createEvent<number>();
export const hideTextArea = JobViewPageDomain.createEvent();
export const resetStores = JobViewPageDomain.createEvent();
export const resetButtons = JobViewPageDomain.createEvent();

//effects
export const fetchJobFx = JobViewPageDomain.createEffect(async (id: string) => {
    try {
        const result = await getJob(id);
        return result.data;
    } catch (e) {
        console.log(e);
    }
});

export const fetchAvailableTasksFx = JobViewPageDomain.createEffect(async (id: string) => {
    try {
        const result = await getTasksList({id: id});
        return result.data;
    } catch (e) {
        console.log(e);
    }
});
export const fetchWorkflowButtonsFx = JobViewPageDomain.createEffect(async (id: string) => {
    try {
        return await getTask(id);
    } catch (e) {
        console.log(e);
    }
});

export const sendCommentFx = JobViewPageDomain.createEffect(
    async (data: {id: string; comment: string}) => {
        try {
            return await postJobComment(data);
        } catch (e) {
            console.log(e);
        }
    },
);

export const acceptTaskFx = JobViewPageDomain.createEffect(additionalAgreementService.acceptTask);
export const cancelTaskFx = JobViewPageDomain.createEffect(additionalAgreementService.cancelTask);

export const updateTaskFx = JobViewPageDomain.createEffect(async ({id, actionId}) => {
    try {
        return await updateTask({id, actionId});
    } catch (e) {
        console.log(e);
    }
});

export const getNriRllListFx = JobViewPageDomain.createEffect(async (projectId: string) => {
    try {
        const result = await fetchNriRllList(projectId);
        return result.data;
    } catch (e) {
        console.log(e);
    }
});

//stores
export const $jobData = JobViewPageDomain.createStore<JobType | null>(null)
    .on(fetchJobFx.doneData, forwardPayload())
    .reset(resetStores);

export const $availableTasksData = JobViewPageDomain.createStore<TTaskList>([])
    .on(fetchAvailableTasksFx.doneData, forwardPayload())
    .reset(resetStores)
    .reset(resetButtons);

export const $workflowButtonsData = JobViewPageDomain.createStore<ITaskResponse | null>(null)
    .on(fetchWorkflowButtonsFx.doneData, forwardPayload())
    .reset(resetStores);

export const $comment = JobViewPageDomain.createStore<string>('')
    .on(setComment, forwardPayload())
    .reset(hideTextArea);

export const $commentIsValid = JobViewPageDomain.createStore<boolean>(false);
export const $buttonIsTouched = JobViewPageDomain.createStore<boolean>(false);
export const $isCommentAvailable =
    JobViewPageDomain.createStore<boolean>(false).reset(hideTextArea);
export const $isCommentError = JobViewPageDomain.createStore<boolean>(false);
export const $pushedButtonIndex = JobViewPageDomain.createStore<number>(-1)
    .on(sendComment, forwardPayload())
    .reset(hideTextArea);

export const $nriRllList = JobViewPageDomain.createStore<NriRllFileType[]>([]).on(
    getNriRllListFx.doneData,
    forwardPayload(),
);

export const $workflowButtonsStore = combine({
    availableTasks: $availableTasksData,
    workflowButtons: $workflowButtonsData,
    buttonIsLoading: or(
        updateTaskFx.pending,
        acceptTaskFx.pending,
        cancelTaskFx.pending,
        sendCommentFx.pending,
    ),
});

export const $jobViewPageStore = combine({
    jobData: $jobData,
    comment: $comment,
    isValid: $commentIsValid,
    workflowButtons: $workflowButtonsData,
    isTouched: $buttonIsTouched,
    isLoading: fetchJobFx.pending,
    isCommentAvailable: $isCommentAvailable,
    commentError: $isCommentError,
    nriRllList: $nriRllList,
    bsFileIsLoading: downloadNriBsFileFx.pending,
    rllFileIsLoading: downloadNriRllFileFx.pending,
    attachmentFileIsLoading: downloadAttachmentFileFx.pending,
    anyFileIsLoading:
        downloadNriBsFileFx.pending ||
        downloadNriRllFileFx.pending ||
        downloadAttachmentFileFx.pending,
});

forward({from: fetchJob, to: fetchJobFx});
forward({from: JobViewPageGate.state, to: resetStores});

// Запрос на получение списка файлов из NRI после загрузки данных о задании
sample({
    source: $jobData,
    filter: (source) => source?.bs.id !== undefined,
    fn: (source) => source?.bs.id ?? '',
    target: getNriRllListFx,
});

//состояние ошибки textarea
sample({
    source: combine({
        isValid: $commentIsValid,
        isTouched: $buttonIsTouched,
    }),
    fn: ({isValid, isTouched}) => (isTouched ? !isValid : false),
    target: $isCommentError,
});

// "включение" проверки валидности после нажатия на любую воркфлоу кнопку
sample({
    clock: sendComment,
    fn: () => true,
    target: $buttonIsTouched,
});

// отображение/скрытие комментария в зависимости от кнопок воркфлоу
sample({
    source: $workflowButtonsData,
    fn: (source) => (source?.data.actions.length ?? -1) > 0,
    target: $isCommentAvailable,
});
//перезагрузка данных после нажатия на кнопку workflow, либо при изменении id в URL
sample({
    clock: [
        JobViewPageGate.state,
        updateTaskFx.doneData,
        acceptTaskFx.doneData,
        cancelTaskFx.doneData,
    ],
    source: JobViewPageGate.state,
    filter: (gateState) => typeof gateState === 'string',
    target: fetchJob,
});

//Загрузка доступных задач при открытии страницы
sample({
    clock: JobViewPageGate.state,
    filter: (gateState) => typeof gateState === 'string',
    fn: (gate) => gate,
    target: fetchAvailableTasksFx,
});

//после отработки первого запроса на доступные задачи, идет запрос на кнопки
sample({
    source: $availableTasksData,
    filter: (source) => source.length > 0,
    fn: (source) => source[0].id,
    target: fetchWorkflowButtonsFx,
});

// если кнопок нет, обнуляется стор кнопок
sample({
    source: $availableTasksData,
    filter: (source) => source.length === 0,
    fn: () => null,
    target: $workflowButtonsData,
});

// дополнительный reset кнопок
sample({
    clock: updateTaskFx.doneData,
    filter: (clock) => clock?.status === 200,
    target: resetButtons,
});

//Нажатие на workflow кнопку
sample({
    clock: handleWorkflowBtn,
    source: combine({
        buttonsData: $workflowButtonsData,
    }),
    fn: ({buttonsData}, index) => ({
        id: buttonsData?.data.id ?? '',
        actionId: buttonsData?.data.actions[index].id ?? '',
    }),
    target: updateTaskFx,
});

//Нажатие на кнопку "отклонить"
sample({
    clock: sendComment,
    source: combine({
        jobData: $jobData,
        comment: $comment,
        isCommentAvailable: $isCommentAvailable,
        isCommentError: $isCommentError,
    }),
    filter: ({isCommentError}) => !isCommentError,
    fn: ({jobData, comment}) => ({
        id: jobData?.id ?? '',
        comment: comment,
    }),
    target: sendCommentFx,
});

// Логика нажатия кнопки "Отклонить" (сначала отправляется post коммента, после - нажатие)
sample({
    clock: sendCommentFx.doneData,
    source: combine({
        buttonsData: $workflowButtonsData,
        index: $pushedButtonIndex,
    }),
    filter: ({index}, clock) => clock?.status === 200 && index >= 0,
    fn: ({buttonsData, index}) => ({
        id: buttonsData?.data.id ?? '',
        actionId: buttonsData?.data.actions[index].id ?? '',
    }),
    target: updateTaskFx,
});

//Перезагрузка актуальных кнопок workflow после нажатия на кнопку
sample({
    clock: [updateTaskFx.doneData, acceptTaskFx.doneData, cancelTaskFx.doneData],
    source: JobViewPageGate.state,
    fn: (gate) => gate,
    target: fetchAvailableTasksFx,
});

//сброс и скрытие textarea
sample({
    clock: updateTaskFx.doneData,
    target: hideTextArea,
});

// проверка валидности textarea
sample({
    source: $comment,
    fn: (source) => source.length > 0,
    target: $commentIsValid,
});

//нажатие на кнопку "взять в работу"
sample({
    clock: handleAcceptWorkflowBtn,
    target: acceptTaskFx,
});
sample({
    clock: handleCancelWorkflowBtn,
    target: cancelTaskFx,
});

resetDomainStoresByEvents(JobViewPageDomain, JobViewPageGate.close);
