import {IconButton, Tooltip} from '@beeline/design-system-react';
import cn from 'classnames';
import styled from 'styled-components/macro';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {TableCell} from 'shared/ui/Table/Table';
import {formatNumberToDecimalWithComma} from 'shared/helpers/formatHelper';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {PROHIBITED_COEFFICIENT_EDITING_HINT} from 'shared/constants';

interface CoefficientCellProps {
    coefficient: number;
    alignRight?: boolean;
    handleConfigureCoefficient?: () => void;
    disabled?: boolean;
}

export function CoefficientCell(props: CoefficientCellProps) {
    const {coefficient, handleConfigureCoefficient, alignRight = false, disabled} = props;
    const inactiveClassName = cn({inactiveText: disabled});
    return (
        <TableCell width="104px">
            <WrapperFlex
                justifyContent="space-between"
                alignItems="center"
                className={inactiveClassName}
            >
                {alignRight ? (
                    <span>{formatNumberToDecimalWithComma(coefficient)}</span>
                ) : (
                    <RatioText>{formatNumberToDecimalWithComma(coefficient)}</RatioText>
                )}
                {handleConfigureCoefficient && (
                    <Tooltip title={PROHIBITED_COEFFICIENT_EDITING_HINT} disableHover={!disabled}>
                        <IconButton
                            iconName={Icons.Settings}
                            onClick={handleConfigureCoefficient}
                            size="large"
                            disabled={disabled}
                        />
                    </Tooltip>
                )}
            </WrapperFlex>
        </TableCell>
    );
}

export const RatioText = styled.div`
    flex-grow: 1;
`;
