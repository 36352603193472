import React from 'react';
import {Typography} from '@beeline/design-system-react';

import * as S from './style';

export const TableEmptyMessage = () => {
    return (
        <S.Wrapper>
            <Typography className="mb-2" variant="h6">
                По вашему запросу ничего не найдено
            </Typography>
            <Typography className="inactiveText" variant="body2">
                Попробуйте сократить запрос или задать его по-другому
            </Typography>
        </S.Wrapper>
    );
};
