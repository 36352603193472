export function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}


export function compareStringOfNumbers(str1, str2) {
    const arr1 = str1?.split(".");
    const arr2 = str2?.split(".");
    const maxLength = Math.max(arr1?.length, arr2?.length) - 1;

    for (let i = 0; i <= maxLength; i++) {
        let res = arr1[i] - arr2[i];
        if (res !== 0) {
            return res > 0 ? 1 : -1;
        }
    }
    return 0;
}