import styled, {css} from 'styled-components/macro';

const buttonListMixin = (props) => css`
    ${css`
        position: relative;
        --db: none;
        --left: 0;
        --right: auto;
    `}
    ${props.active &&
    css`
        --db: block;
    `}
  ${props.right &&
    css`
        --right: 0;
        --left: auto;
    `}
`;

export const ButtonListStyled = styled.div`
    ${(props) => buttonListMixin(props)}
`;

const ListBlockMixin = (props) => css`
    position: absolute;
    z-index: 2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), 0 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background-color: #ffffff;
    padding: 8px 0;
    display: var(--db);
    min-width: 174px;
    top: 100%;
    left: var(--left);
    right: var(--right);

    ${props.width &&
    css`
        width: ${props.width};
    `}
    ${props.screenX &&
    props.screenY &&
    css`
        left: ${props.screenX}px;
        top: ${props.screenY}px;
    `}
  ${props.maxHeight &&
    css`
        max-height: 300px;

        @media (min-height: 800px) {
            max-height: 400px;
        }
        @media (min-height: 1081px) {
            max-height: 600px;
        }
        overflow-y: auto;
    `}
  & > * {
        background-color: #ffffff;
        display: flex;
        width: 100%;
        border: none;
        align-items: center;
        padding: 12px 16px;
        transition-duration: 0.4s;
        font-size: 17px;
        line-height: calc(22 / 17);
        white-space: nowrap;
        color: var(--body-color);

        &:hover,
        &:active:focus,
        &:focus {
            background-color: #ededef;
        }

        .icon {
            width: 32px;
            padding-left: 8px;
            height: 24px;
            margin-left: auto;
        }
    }
`;

export const ListBlock = styled.div`
    ${(props) => ListBlockMixin(props)}
`;
export const ListItem = styled.div<{disabled?: boolean}>`
    cursor: pointer;
    position: relative;

    p {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    ${(p) =>
        p.disabled &&
        css`
            cursor: auto;
            color: var(--color-text-inactive);
            &:hover {
                background-color: var(--color-background-base);
            }
        `}
`;
