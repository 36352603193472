import {PathToSpec, PathToVir, PathToWorObject} from './types';

export function getPathToWorks() {
    return `works`;
}
export function getPathToVir({virId}: PathToVir) {
    return `works.${virId}`;
}

export function getPathToWorkObjects({virId}: PathToVir) {
    return `works.${virId}.work_objects`;
}

export function getPathToWorkObject({virId, workObjectId}: PathToWorObject) {
    return `works.${virId}.work_objects.${workObjectId}`;
}
export function getPathToSpecs({virId, workObjectId}: PathToWorObject) {
    return `works.${virId}.work_objects.${workObjectId}.work_specifications`;
}

export function getPathToWorkObjectSpecs({virId, workObjectId, specId}: PathToSpec) {
    return `works.${virId}.work_objects.${workObjectId}.work_specifications.${specId}`;
}
