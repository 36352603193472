import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';
import {createDetachedSignature, getUserCertificates, createHash} from 'crypto-pro';
import {Certificate} from 'crypto-pro/lib/api/certificate';

import {snackbarNotificationChanged} from 'Entities/SnackbarNotification/model';
import {handleBackendBtn} from 'pages/DSPage/model';
import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';
import {isDateRangeValid} from 'shared/helpers/formatHelper';
import {fetchFileWithValidation, postJobAttachment} from 'shared/services/files.service';
import {fetchSignerActualization} from 'shared/services/ds.service';
import {EAttachmentType} from 'shared/types/FileTypes';

interface IIncomingObject {
    agreementId: string;
    buttonIndex: number;
    id: string;
    header: string;
    description: string;
    fileId: string;
    specialFileId: string;
    fileType: EAttachmentType;
}

export const CertificateSignatureDomain = createDomain();
export const CertificateSignatureGate = createGate();

// effects

export const getCertificatesFx = CertificateSignatureDomain.createEffect(async () => {
    try {
        const result: Certificate[] = await getUserCertificates(true);
        return result;
    } catch (e) {
        snackbarNotificationChanged(e.message);
        return [] as Certificate[];
    }
});

const signAdditionalAgreementFx = CertificateSignatureDomain.createEffect(
    async ({thumbprint, object}: {thumbprint: string; object: IIncomingObject}) => {
        try {
            const isActual = await fetchSignerActualization(object.agreementId);
            if (isActual.status !== 200) return false;
            const {buttonIndex, fileId, fileType, specialFileId} = object;
            const result = await fetchFileWithValidation(fileId, fileType);
            const arrBuffer = await result.data.arrayBuffer();
            const hash = await createHash(arrBuffer);
            const testResult: string = await createDetachedSignature(thumbprint, hash);
            const testBlobFile = new Blob([testResult]) as File;
            const b = await postJobAttachment(testBlobFile, specialFileId, undefined, 'sig');
            if (b.status === 201) {
                handleBackendBtn(buttonIndex);
                return true;
            }
            return false;
        } catch (e) {
            console.warn(e);
            return false;
        }
    },
);

//events
export const windowClosed = CertificateSignatureDomain.createEvent();
export const certificateSignatureWindowOpened =
    CertificateSignatureDomain.createEvent<IIncomingObject>();
export const certificateSelected = CertificateSignatureDomain.createEvent<string>();
export const positiveBtnClicked = CertificateSignatureDomain.createEvent();

// stores
export const $certificateSignatureWindowOpened = CertificateSignatureDomain.createStore(false);
const $certificateList = CertificateSignatureDomain.createStore<Certificate[]>([]);
const $selectedCertificateId = CertificateSignatureDomain.createStore('');
const $signBtnDisabled = CertificateSignatureDomain.createStore(true);
const $incomingObject = CertificateSignatureDomain.createStore<IIncomingObject>({
    buttonIndex: 0,
    fileType: EAttachmentType.Agreement,
    fileId: '',
    specialFileId: '',
    id: '',
    agreementId: '',
    description: '',
    header: '',
});
const $documentId = CertificateSignatureDomain.createStore('');
const $windowHeader = CertificateSignatureDomain.createStore('');
const $windowDescription = CertificateSignatureDomain.createStore('');
const $fileId = CertificateSignatureDomain.createStore('');
const $fileType = CertificateSignatureDomain.createStore<EAttachmentType>(2);

export const $certificateSignatureStore = combine({
    selectedCertificateId: $selectedCertificateId,
    certificateList: $certificateList,
    documentId: $documentId,
    header: $windowHeader,
    description: $windowDescription,
    signBtnDisabled: $signBtnDisabled,
    certificateListIsLoading: getCertificatesFx.pending,
});

sample({
    clock: CertificateSignatureGate.open,
    target: getCertificatesFx,
});

sample({
    clock: getCertificatesFx.doneData,
    fn: (certificateList) =>
        certificateList.filter(
            (certificate) =>
                certificate.subjectName.includes('СНИЛС=') &&
                certificate.subjectName.includes('ИНН='),
        ),
    target: $certificateList,
});

// автовыбор радио, когда доступен только 1 сертификат
sample({
    source: $certificateList,
    filter: (source) =>
        source.length === 1 && isDateRangeValid(source[0].validFrom, source[0].validTo),
    fn: (source) => source[0].thumbprint,
    target: certificateSelected,
});

// открывает окно с подписями
sample({
    clock: certificateSignatureWindowOpened,
    source: $certificateSignatureWindowOpened,
    fn: (source) => !source,
    target: $certificateSignatureWindowOpened,
});
// заполняются данные
$incomingObject.on(certificateSignatureWindowOpened, forwardPayload());
$documentId.on(certificateSignatureWindowOpened, (_, payload) => payload.id);
$windowHeader.on(certificateSignatureWindowOpened, (_, payload) => payload.header);
$windowDescription.on(certificateSignatureWindowOpened, (_, payload) => payload.description);
$fileId.on(certificateSignatureWindowOpened, (_, payload) => payload.fileId);
$fileType.on(certificateSignatureWindowOpened, (_, payload) => payload.fileType);

sample({
    clock: certificateSelected,
    target: $selectedCertificateId,
});

// нажатие кнопки подписания
sample({
    clock: positiveBtnClicked,
    source: combine({
        thumbprint: $selectedCertificateId,
        object: $incomingObject,
    }),
    filter: (source) => !!source.object,
    target: signAdditionalAgreementFx,
});

// если все прошло успешно, окно закрывается и страница карточки ДС обновится
sample({
    clock: signAdditionalAgreementFx.doneData,
    filter: (isSucceed) => isSucceed,
    target: windowClosed,
});

sample({
    clock: $selectedCertificateId,
    fn: (selectedId) => selectedId === '',
    target: $signBtnDisabled,
});

// закрытие страницы
resetDomainStoresByEvents(CertificateSignatureDomain, windowClosed);
