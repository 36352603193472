import styled from 'styled-components/macro';

export const CountWrap = styled.div`
    padding-left: 37px;
    padding-right: 35px;
    line-height: calc(18 / 15);
`;

export const SelectCount = styled.select`
    border: 0;
    outline: 0;
`;

export const PaginationBtn = styled.button<{disabled?: boolean}>`
    width: 24px;
    height: 24px;
    display: block;
    margin-left: 16px;
    margin-right: 16px;
    background-color: transparent;
    opacity: ${(p) => p.disabled && '0.5'};
    border: none;

    .icon {
        width: 100%;
        height: 100%;
        color: var(--secondary);
        transition-duration: 0.4s;
    }

    &:hover {
        .icon {
            color: ${(p) => !p.disabled && 'var(--body-color)'};
        }
    }
`;
