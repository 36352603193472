import React from 'react';
import {useParams} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {TextArea, Typography} from '@beeline/design-system-react';

import {CommonLayout} from 'shared/ui/CommonLayout';
import {Comment} from 'Entities/Comment';
import {DownloadableAttachment} from 'Features/DownloadableAttachment';
import {downloadAttachmentFile} from 'processes/CreationJobProcess/model/stage3';
import {JobSpecificationTable} from 'widgets/JobSpecificationTable/JobSpecificationTable';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {EAttachmentType} from 'shared/types/FileTypes';

import {JobPageInfo} from './JobPageInfo';
import {JobViewPageTitle} from './JobViewPageTitle';
import * as S from './style';
import {$jobViewPageStore, JobViewPageGate, setComment} from '../model';

export const JobViewPage = () => {
    const {jobId} = useParams<{jobId: string}>();
    useGate(JobViewPageGate, jobId ?? '');
    const {
        jobData,
        comment,
        commentError,
        isCommentAvailable,
        attachmentFileIsLoading,
        anyFileIsLoading,
    } = useStore($jobViewPageStore);

    const handleChangeTextArea = (e) => {
        setComment(e.currentTarget.value);
    };

    const handleDownloadAttachment = (id: string, fileName: string, type: EAttachmentType) => {
        downloadAttachmentFile({id, file_name: fileName, type: type});
    };

    return (
        <CommonLayout>
            <WrapperOffset offset={32}>
                <JobViewPageTitle
                    status={jobData?.status}
                    baseStation={jobData?.bs}
                    jobNumber={jobData?.job_tasks_number}
                    contractorName={jobData ? jobData.contractor.short_name : undefined}
                    editor={jobData?.editor}
                    updatedAt={jobData?.updated_at}
                />
                <JobPageInfo jobData={jobData} />

                <S.CustomTypography variant="h5" margin="36px 0 8px">
                    Поручаемые работы
                </S.CustomTypography>
                {jobData && (
                    <JobSpecificationTable
                        jobTaskSpecifications={jobData.job_task_specification}
                        totalSum={+jobData.job_task_sum}
                    />
                )}
                <S.CustomTypography variant="caption" margin="8px 0 36px" display="block">
                    Все расчеты указаны без учета НДС
                </S.CustomTypography>
                <S.AttachmentsBlock>
                    <S.FilesBlock>
                        <Typography variant="h5" className="mb-2">
                            Вложения
                        </Typography>
                        {jobData &&
                            jobData.job_task_files.length > 0 &&
                            jobData.job_task_files.map((file) => (
                                <DownloadableAttachment
                                    key={file.id}
                                    id={file.id ?? file.file_id}
                                    title={file.file_name}
                                    type={file.type}
                                    handleDownloadAttachment={handleDownloadAttachment}
                                    disabled={anyFileIsLoading}
                                    isLoading={attachmentFileIsLoading}
                                />
                            ))}
                        <S.TextBlock>
                            <Typography variant="h5" className="mb-2">
                                По результатам ПППОИР необходимо предоставить на согласование:
                            </Typography>
                            <Typography variant="body2">
                                1. Проект Ведомости исполнения работ (ВИР) по модернизации Объекта;{' '}
                                <br />
                                2. Стоимость проведения экспертиз (если требуется).
                            </Typography>
                        </S.TextBlock>
                        <Typography variant="h5" className="mb-2">
                            Контактные данные
                        </Typography>{' '}
                        <Typography variant="body2">
                            Контактный телефон и адрес электронной почты для сообщения о прибытии на
                            позицию для выполнения работ и убытии, а также иной информации в
                            соответствии с Договором указаны в Матрице взаимодействия Генерального
                            подрядчика с подразделениями ПАО "ВымпелКом".
                        </Typography>
                    </S.FilesBlock>
                    <S.CommentsBlock>
                        <Typography variant="h5">Комментарии к документу</Typography>
                        {isCommentAvailable && (
                            <TextArea
                                value={comment}
                                label="Комментарий"
                                helperText={commentError ? 'Введите комментарий' : ''}
                                onChange={handleChangeTextArea}
                                error={commentError}
                                fullWidth
                            />
                        )}
                        {jobData?.job_task_comment &&
                            jobData.job_task_comment.map((item) => (
                                <Comment
                                    key={item.id ?? item.created_at}
                                    id={item.id}
                                    creator={item.creator}
                                    created_at={item.created_at}
                                    comment={item.comment}
                                />
                            ))}
                    </S.CommentsBlock>
                </S.AttachmentsBlock>
            </WrapperOffset>
        </CommonLayout>
    );
};
