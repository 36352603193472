import {combine, createDomain} from 'effector';
import {WorkCoefficientTypeNullish} from 'shared/types/additionalAgreementsTypes';

export const customCoefficientsDomain = createDomain();

export const $customCoefficients = customCoefficientsDomain.createStore<
    Array<WorkCoefficientTypeNullish>
>([]);

export const addCoefficient = customCoefficientsDomain.createEvent();
export const deleteCoefficient = customCoefficientsDomain.createEvent<string>();
export const changeNewCoefficient = customCoefficientsDomain.createEvent<string>();
export const updateExistingCoefficient = customCoefficientsDomain.createEvent<{
    id: string;
    newValue: string;
}>();

export const $searchTerm = customCoefficientsDomain.createStore('');
export const $newCoefficient = customCoefficientsDomain.createStore('');

export const $customCoefficientTab = combine({
    customCoefficients: $customCoefficients,
    otherSearchTerm: $searchTerm,
    newCoefficient: $newCoefficient,
});
