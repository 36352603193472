import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useStore} from 'effector-react';
import {Button, Typography} from '@beeline/design-system-react';

import {$createVirStartPageData} from 'pages/VirStart/model';
import {resetAdditionalAgreementCreation} from 'processes/contructor/model/stores';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {isCreationConstructorMode} from 'shared/helpers/additionalAgreements';
import {EAdditionalAgreementType, IUpdatedAgreement} from 'shared/types/additionalAgreementsTypes';
import ModalWindow from 'shared/ui/deprecated/ModalWindow/ModalWindow';
import * as S from './style';

interface AgreementConstructorHeaderProps {
    agreement: IUpdatedAgreement;
    agreementType: EAdditionalAgreementType | null;
}

export const AgreementConstructorHeader = ({
    agreement,
    agreementType,
}: AgreementConstructorHeaderProps) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const {fromProject, projectId} = useStore($createVirStartPageData);
    const {id, agreement_number, status} = agreement;
    const creationConstructorMode = isCreationConstructorMode(status);

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };
    const handleClose = () => {
        resetAdditionalAgreementCreation();
        if (
            fromProject ||
            agreementType === EAdditionalAgreementType.Agreement ||
            agreementType === EAdditionalAgreementType.Addition
        ) {
            navigate(`/projects/${projectId}`);
        } else if (id) {
            navigate(`/additional-agreements/${id}`);
        } else {
            navigate(`/additional-agreements`);
        }
    };

    const getTitleText = () => {
        if (!creationConstructorMode) {
            switch (agreementType) {
                case EAdditionalAgreementType.Addition: {
                    return `Редактирование дополнения к ДС ${agreement_number}`;
                }
                case EAdditionalAgreementType.Agreement: {
                    return 'Редактирование ВИР/ДС';
                }
                case EAdditionalAgreementType.Termination: {
                    return `Редактирование расторжения ДС ${agreement_number}`;
                }
            }
        } else {
            switch (agreementType) {
                case EAdditionalAgreementType.Agreement: {
                    return ' Новый ВИР/ДС';
                }
                case EAdditionalAgreementType.Addition: {
                    return `Новое дополнение к ДС ${agreement_number}`;
                }
                case EAdditionalAgreementType.Termination: {
                    return `Расторжение ДС ${agreement_number}`;
                }
            }
        }
    };

    const modalText = `Завершить ${
        creationConstructorMode
            ? agreementType === EAdditionalAgreementType.Agreement
                ? 'создание'
                : 'создание дополнения к'
            : 'редактирование'
    } ВИР/ДС?`;
    return (
        <>
            <S.HeaderBlock>
                <Typography variant="subtitle1">{getTitleText()}</Typography>
                <Button variant="outlined" onClick={handleOpenModal} size="medium">
                    Отменить
                </Button>
            </S.HeaderBlock>

            <ModalWindow open={open}>
                <S.ModalTitle>{modalText}</S.ModalTitle>
                <S.ModalText>Все внесённые изменения не сохранятся.</S.ModalText>
                <WrapperOffset offsetTop={16}>
                    <WrapperFlex justifyContent="flex-end" gap="16px">
                        <Button variant="outlined" onClick={handleCloseModal} size="medium">
                            Продолжить создание
                        </Button>
                        <Button variant="contained" onClick={handleClose} size="medium">
                            Завершить
                        </Button>
                    </WrapperFlex>
                </WrapperOffset>
            </ModalWindow>
        </>
    );
};
