import React, {useState} from 'react';
import {Button, Typography} from '@beeline/design-system-react';

import ModalWindow from 'shared/ui/deprecated/ModalWindow/ModalWindow';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

import * as S from './style';

interface HeaderProps {
    title: string;
    modalTitle: string;
    onClose: () => void;
    children?: JSX.Element;
}

export const CreationHeader = ({title, modalTitle, onClose, children}: HeaderProps) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <S.HeaderBlock>
            <S.UpperHeaderWrapper>
                <Typography variant="subtitle1">{title}</Typography>
                <Button color="outline" size="medium" onClick={() => setOpenModal(true)}>
                    Отменить
                </Button>
            </S.UpperHeaderWrapper>
            {children}
            <ModalWindow open={openModal}>
                <S.ModalTitle variant="h5">{modalTitle}</S.ModalTitle>
                <Typography variant="body2">Все внесённые изменения не сохранятся.</Typography>
                <WrapperOffset offsetTop={16}>
                    <WrapperFlex justifyContent="flex-end">
                        <Button size="medium" onClick={() => setOpenModal(false)}>
                            Продолжить создание
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            onClick={onClose}
                            className="ms-3"
                        >
                            Завершить
                        </Button>
                    </WrapperFlex>
                </WrapperOffset>
            </ModalWindow>
        </S.HeaderBlock>
    );
};
