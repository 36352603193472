import {TableCell, TableCellContent, TableString} from 'shared/ui/Table/Table';
import {Skeleton} from 'shared/ui/deprecated/Skeleton/Skeleton';
import {IColumn} from 'shared/types/tableTypes';
import {IAdditionalAgreementListItem} from 'shared/types/additionalAgreementsTypes';

export interface AgreementsContentSkeletonProps {
    columns: IColumn<IAdditionalAgreementListItem>[];
    pageSize?: number;
}

export function AgreementsContentSkeleton({
    columns,
    pageSize = 10,
}: AgreementsContentSkeletonProps) {
    return (
        <>
            {[...Array(pageSize)].map((x, i) => (
                <TableString key={i}>
                    {columns.map(
                        ({visible = true, ...item}) =>
                            visible && (
                                <TableCell key={`${item.name} ${i}`}>
                                    <TableCellContent>
                                        <Skeleton height={20} />
                                    </TableCellContent>
                                </TableCell>
                            ),
                    )}
                </TableString>
            ))}
        </>
    );
}
