import React, {useEffect} from 'react';
import {Select, Typography} from '@beeline/design-system-react';

import {CatalogWorkObjectOption} from 'pages/VirStart/types';

import * as S from './style';

interface AgreementObjectSelectProps {
    options: CatalogWorkObjectOption[];
    values: CatalogWorkObjectOption[];
    objectId: string;
    handleSelectOption: ({
        values,
        objectId,
    }: {
        values: CatalogWorkObjectOption[];
        objectId: string;
    }) => void;
    width?: string;
}

interface DropdownOptionProps {
    object: CatalogWorkObjectOption;
    selected?: boolean;
}

export const AgreementObjectSelect = ({
    objectId,
    options,
    values,
    handleSelectOption,
    width = '567px',
}: AgreementObjectSelectProps) => {
    useEffect(() => {
        if (options.length > 0) {
            handleSelectOption({values: options, objectId: objectId});
        }
    }, []);

    return (
        <S.StyledSelectWrapper width={width}>
            <Select<CatalogWorkObjectOption>
                label="Объект"
                options={options}
                values={values}
                renderValue={(values) => values[0]?.label ?? ''}
                onChange={(values) => handleSelectOption({values: values, objectId: objectId})}
                makeOption={(option, selected) => (
                    <DropdownOption object={option} selected={selected} />
                )}
                fullWidth
            />
        </S.StyledSelectWrapper>
    );
};

const DropdownOption = ({object, selected}: DropdownOptionProps) => {
    const subtitleText = object.lavParts
        ? 'Пролёт (полный комплект)'
        : object.object_type === 2
        ? 'Основная часть (полукомплект)'
        : 'Ответная часть (полукомплект)';
    const isPart = object.object_type === 2 || object.object_type === 3;
    return (
        <S.StyledOptionRow $isPart={isPart} $selected={selected}>
            <Typography variant="body2">{object.label}</Typography>
            <Typography variant="body3" className="inactiveText">
                {subtitleText}
            </Typography>
        </S.StyledOptionRow>
    );
};
