import React from 'react';
import {Link} from 'react-router-dom';

import Select, {SelectItem} from 'shared/ui/Select/Select';
import {CatalogWorkObjectOption} from 'pages/VirStart/types';
import {EWorkObjectTypes} from 'shared/types/additionalAgreementsTypes';

import * as S from './style';

interface WorkObjectSelectProps {
    options: CatalogWorkObjectOption[];
    selected: CatalogWorkObjectOption | null;
    onSelect: (option: CatalogWorkObjectOption | null) => void;
    error?: boolean;
}

export function WorkObjectSelect({options, selected, onSelect, error}: WorkObjectSelectProps) {
    return (
        <S.SelectWrapper>
            <Select
                error={error}
                hint={error ? 'Выберите объект' : ''}
                value={selected}
                valueLabel="label"
                handleEdit={onSelect}
                title="Объект"
            >
                {[
                    ...options?.map((item, idx) => {
                        return (
                            <SelectItem
                                key={`${item?.label} ${idx}`}
                                nested={!!item.nested}
                                multiple={false}
                                value={item || []}
                                selected={selected}
                                disabled={!item.object_type}
                            >
                                <S.MainText>
                                    {item.label}
                                    {!item?.object_type && (
                                        <S.SubText active={false}>
                                            Пролёт (полный комплект)
                                        </S.SubText>
                                    )}
                                </S.MainText>
                                <S.SubText active>
                                    {item.object_type === EWorkObjectTypes.MAIN_PART &&
                                        'Основная часть (полукомплект)'}
                                    {item.object_type === EWorkObjectTypes.RESPONSE_PART &&
                                        'Ответная часть (полукомплект)'}
                                </S.SubText>
                            </SelectItem>
                        );
                    }),
                    <S.TipText key="no_object" variant="body2">
                        Нет нужного объекта? Напишите на{' '}
                        <Link to={'mailto:dmc@beeline.ru'}>dmc@beeline.ru</Link>
                    </S.TipText>,
                ]}
            </Select>
        </S.SelectWrapper>
    );
}
