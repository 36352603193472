import React from 'react';
import {Skeleton, TableBody, TableRow} from '@beeline/design-system-react';

import {CustomTableData} from 'shared/styles/commonStyle';
import {IColumn} from 'shared/types/tableTypes';

interface TableBodySkeletonProps<T> {
    pageSize?: number;
    columns: IColumn<T>[];
    height?: string;
    dense?: boolean;
}

export const TableBodySkeleton = <T,>({
    pageSize = 10,
    columns,
    height,
    dense,
}: TableBodySkeletonProps<T>) => {
    return (
        <TableBody>
            {[...Array(pageSize ?? 10)].map((_, index) => (
                <TableRow key={`${index}_skeleton`} dense={dense}>
                    {columns.map((column) => (
                        <CustomTableData key={`${column.name.toString()}_skeleton`} height={height}>
                            <Skeleton variant="line" height={18} width="80%" />
                        </CustomTableData>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    );
};
