import { Column, VirPattern } from "pages/VirPatternsPage/types";

export const columns: Column<VirPattern>[] = [
    {
        label: "Наименование шаблона",
        name: "name",
        id: "name",
        visible: true,
        minWidth: "320px",
        width: "calc(320/1900*100%)",
        sortBy: "name",
    },
    {
        label: "Договор",
        name: "contract",
        id: "contract",
        visible: true,
        minWidth: "",
        width: "210px",
    },
    {
        label: "Автор",
        name: "author",
        id: "author",
        visible: true,
        minWidth: "206px",
        width: "calc(206/1900*100%)",
        sortBy: "author",
    },
    {
        label: "Дата создания",
        name: "created_at",
        id: "created_at",
        visible: true,
        minWidth: "154px",
        width: "calc(154/1900*100%)",
        sortBy: "created_at",
    },
    {
        label: "Последнее изменение",
        name: "updated_at",
        id: "updated_at",
        visible: true,
        width: "calc(182/1900*100%)",
        minWidth: "182px",
    },
    {
        label: "",
        name: "edit",
        id: "edit",
        visible: true,
        minWidth: "52px",
        width: "calc(52/1900*100%)",
    },
    {
        label: "",
        name: "delete",
        id: "delete",
        visible: true,
        minWidth: "52px",
        width: "calc(52/1900*100%)",
    },
    {
        label: "",
        id: "select",
        name: "select",
        visible: true,
        minWidth: "88px",
        width: "calc(88/1900*100%)",
    },
];
