import {ChangeEvent} from 'react';
import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';
import {reset} from 'patronum';
import {cloneDeep, isEqual} from 'lodash';
import {Option} from '@beeline/design-system-react';

import {$isUserVK, $userActions, $userStore, setColumnSettings} from 'shared/model/user';
import {getInitialContractsPageData} from 'shared/model/user/helpers';
import {TExtendedContractsPage} from 'shared/types/userTypes';
import {IContractListItem} from 'shared/types/contractsTypes';
import {formatDateToBackendFormat} from 'shared/helpers/formatHelper';
import {fetchPurchasingEventList} from 'shared/services/catalogs.service';
import {fetchMainContractorsList} from 'shared/services/organizations.service';
import {TContractListParams} from 'shared/services/types/paramsTypes';
import {IVisibleColumn} from 'shared/types/tableTypes';
import {ITerritorialOption} from 'shared/types/regionSelectTypes';
import {getProjectPageSelectRegion} from 'shared/services/projects.service';
import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';
import {fetchContractList} from 'shared/services/contracts.service';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions} from 'shared/const/actions';

import {
    adaptFrontParamsToBackend,
    countAppliedFilters,
    getPurchasingEventSelectArray,
    getSelectedPurchasingEventSelectArray,
} from './helpers';

export const ContractListPageDomain = createDomain();
export const ContractListPageGate = createGate();

// effects
const getContractListFx = ContractListPageDomain.createEffect(
    async (params: TContractListParams) => {
        try {
            const result = await fetchContractList(adaptFrontParamsToBackend(params));
            return [result.data.results, result.data.count] as [IContractListItem[], number];
        } catch (e) {
            console.warn(e);
        }
    },
);

const getContractorListFx = ContractListPageDomain.createEffect(async () => {
    try {
        const result = await fetchMainContractorsList();
        return result.data.map((item) => ({id: item.id, value: item.short_name}));
    } catch (e) {
        console.warn(e);
        return undefined;
    }
});

const getPurchasingEventListFx = ContractListPageDomain.createEffect(async () => {
    try {
        const result = await fetchPurchasingEventList();
        return result.data;
    } catch (e) {
        console.warn(e);
    }
});

const getRegionDataFx = ContractListPageDomain.createEffect(async () => {
    try {
        const result = await getProjectPageSelectRegion();
        return result.data.map(
            (item) =>
                ({
                    id: item.id,
                    value: item.name,
                    externalCode: item.external_code,
                } as ITerritorialOption),
        );
    } catch (e) {
        console.warn(e);
    }
});

// events
export const filtersSaved = ContractListPageDomain.createEvent();
export const filtersDiscarded = ContractListPageDomain.createEvent();
export const resetAllSelectedData = ContractListPageDomain.createEvent();
export const resetRegionData = ContractListPageDomain.createEvent();
export const columnVisibilityChanged = ContractListPageDomain.createEvent<IVisibleColumn>();
export const pageChanged = ContractListPageDomain.createEvent<number>();
export const pageSizeChanged = ContractListPageDomain.createEvent<number>();
export const selectedContractorChanged = ContractListPageDomain.createEvent<Option<string>[]>();
export const selectedPurchasingEventChanged =
    ContractListPageDomain.createEvent<Option<string>[]>();
export const searchValueChanged =
    ContractListPageDomain.createEvent<ChangeEvent<HTMLInputElement>>();
export const searchValueReset = ContractListPageDomain.createEvent();
export const searchValueApplied = ContractListPageDomain.createEvent<string>();
export const tableSorted = ContractListPageDomain.createEvent<string>();
export const selectedRegionDataChanged = ContractListPageDomain.createEvent<ITerritorialOption[]>();
export const activityDateChanged = ContractListPageDomain.createEvent<string>();

// stores
export const $tableData = ContractListPageDomain.createStore<[IContractListItem[], number]>([
    [],
    0,
]);
export const $contractorList = ContractListPageDomain.createStore<Option<string>[]>([]);
export const $selectedContractorList = ContractListPageDomain.createStore<Option<string>[]>([]);

const $purchasingEventList = ContractListPageDomain.createStore<Option<string>[]>([]);

// это локальный стор, для несохраненных изменений
export const $tableSettings = ContractListPageDomain.createStore<TExtendedContractsPage>(
    getInitialContractsPageData(),
);
export const $appliedFiltersCount = ContractListPageDomain.createStore<number>(0);
export const $backendDataLoaded = ContractListPageDomain.createStore<boolean>(false);
export const $availableRegionData = ContractListPageDomain.createStore<ITerritorialOption[]>([])
    .on(getRegionDataFx.doneData, forwardPayload())
    .reset(resetRegionData);
export const $selectedRegionData = ContractListPageDomain.createStore<ITerritorialOption[]>(
    [],
).reset(resetAllSelectedData);
export const $innerSearchValue = ContractListPageDomain.createStore<string>('');
export const $selectedActionDate = ContractListPageDomain.createStore<string>('');
const $linkColumnShown = ContractListPageDomain.createStore(false);

// этот стор "общается" с бэком
const $contractListParams = ContractListPageDomain.createStore<TExtendedContractsPage>(
    getInitialContractsPageData(),
);

export const $contractListPageStore = combine({
    appliedFiltersCount: $appliedFiltersCount,
    params: $contractListParams,
    tableSettings: $tableSettings,
    tableData: $tableData,
    tableDataLoading: getContractListFx.pending,
    linkColumnShown: $linkColumnShown,
});

export const $contractListTerritorialStore = combine({
    regionData: $availableRegionData,
    selectedRegionData: $selectedRegionData,
});

export const $contractListSidesheetStore = combine({
    contractorList: $contractorList,
    purchasingSelect: combine({
        purchasingEventList: $purchasingEventList,
        selectedPurchasingEventList: $tableSettings.map((item) =>
            getSelectedPurchasingEventSelectArray(item.filters.purchasingEvent),
        ),
        isLoading: getPurchasingEventListFx.pending,
    }),
    selectedActionDate: $selectedActionDate,
    selectedContractorList: $selectedContractorList,
    contractorListIsLoading: getContractorListFx.pending,
    contractorSelectShown: $isUserVK,
    linkColumnShown: $linkColumnShown,
});

// гидрация данных с бэка
sample({
    clock: [ContractListPageGate.open, $userStore],
    source: combine({
        backendStore: $userStore,
        currentStore: $contractListParams,
        backendLoaded: $backendDataLoaded,
    }),
    filter: ({backendStore, currentStore, backendLoaded}, _) =>
        !backendLoaded || !isEqual(backendStore.extend.contractsPage, currentStore),
    fn: ({backendStore}) => cloneDeep(backendStore.extend.contractsPage),
    target: $contractListParams,
});

// флаг загрузки данных с бэка
sample({
    clock: $contractListParams.updates,
    fn: () => true,
    target: $backendDataLoaded,
});

// отправка новых данных на бэк
sample({
    clock: $contractListParams,
    source: combine({
        backendStore: $userStore,
        currentStore: $contractListParams,
        isLoaded: $backendDataLoaded,
        gate: ContractListPageGate.status,
    }),
    filter: ({backendStore, currentStore, gate, isLoaded}) =>
        gate && isLoaded && !isEqual(backendStore.extend.contractsPage, currentStore),
    fn: ({backendStore, currentStore}) => ({
        ...backendStore.extend,
        contractsPage: {...currentStore},
    }),
    target: setColumnSettings,
});

// загрузка селектов территорий, селектов фильтров при открывании боковой панели
sample({
    clock: ContractListPageGate.open,
    target: [getRegionDataFx, getContractorListFx, getPurchasingEventListFx],
});

// запрос и перезапросы таблицы договоров
sample({
    clock: [$contractListParams, ContractListPageGate.open],
    source: combine({
        source: $contractListParams,
        gate: ContractListPageGate.status,
    }),
    filter: ({gate}) => gate,
    fn: ({source}) => source.filters,
    target: getContractListFx,
});

// клонирование данных в таблицу непрямых изменений
sample({
    clock: $contractListParams,
    fn: (obj) => cloneDeep(obj),
    target: $tableSettings,
});

sample({
    clock: getContractListFx.doneData,
    filter: (data) => !!data,
    fn: (data) => (!!data ? data : undefined),
    target: $tableData,
});

sample({
    clock: getContractorListFx.doneData,
    filter: (data) => !!data,
    fn: (data) => (!!data ? data : []),
    target: $contractorList,
});

sample({
    clock: getPurchasingEventListFx.doneData,
    filter: (data) => !!data,
    fn: (data) => getPurchasingEventSelectArray(data),
    target: $purchasingEventList,
});

sample({
    clock: [$contractListParams, $availableRegionData],
    source: combine({
        isLoaded: $backendDataLoaded,
        regionData: $availableRegionData,
        params: $contractListParams,
    }),
    filter: ({isLoaded}) => isLoaded,
    fn: ({regionData, params}) => {
        return regionData.filter((region) =>
            params.filters.region?.split(',').some((newRegion) => newRegion === region.id),
        );
    },
    target: $selectedRegionData,
});

sample({
    clock: $tableSettings,
    filter: (settings) => !!settings.filters.actionDate,
    fn: (settings) => settings.filters.actionDate ?? '',
    target: $selectedActionDate,
});

$innerSearchValue
    .on(searchValueChanged, (store, event) => {
        const newValue = event.currentTarget.value;
        if (store === newValue) return undefined;
        return newValue;
    })
    .on($contractListParams, (store, backendStore) => {
        if (backendStore.filters.search === store) return undefined;
        return backendStore.filters.search ?? '';
    })
    .reset(searchValueReset);

$selectedActionDate.on(activityDateChanged, forwardPayload());

// мгновенное применение
$contractListParams
    .on(selectedRegionDataChanged, (state, payload) => {
        const regionString = payload.map((item) => item.id).join(',');
        if (!regionString && !state.filters.region) return undefined;
        const hasProperty = state.hasOwnProperty('branch');
        const result = {
            ...state,
            filters: {
                ...state.filters,
                page: 1,
                region: regionString,
            },
        };
        if (hasProperty) delete result.filters['branch'];
        return state.filters.region !== regionString ? result : undefined;
    })
    .on(pageChanged, (state, payload) => ({
        ...state,
        filters: {
            ...state.filters,
            page: payload,
        },
    }))
    .on(pageSizeChanged, (state, payload) => {
        return {
            ...state,
            filters: {
                ...state.filters,
                page: 1,
                page_size: payload,
            },
        };
    })
    .on(searchValueApplied, (state, payload) => {
        if (payload === state.filters.search) return undefined;
        const newFilters = {...state.filters, page: 1};
        delete newFilters.search;
        if (payload !== '') newFilters.search = payload;
        return {
            ...state,
            filters: newFilters,
        };
    })
    .on(tableSorted, (state, payload) => {
        const result = {...state};
        const ordering = result.filters.ordering;
        if (ordering === payload) {
            result.filters.ordering = `-${payload}`;
        } else if (ordering === `-${payload}`) {
            delete result.filters['ordering'];
        } else {
            result.filters.ordering = payload;
        }
        return result;
    });

// Отложенное применение
$tableSettings
    .on(columnVisibilityChanged, (state, payload) => {
        const changedColumns = [...state.columns];
        const changedColumn = changedColumns.find((item) => item.name === payload.name);
        if (!!changedColumn) {
            changedColumn.visible = !payload.visible;
        }
        return {
            ...state,
            columns: changedColumns,
        };
    })

    .on($selectedContractorList, (state, contractorList) => {
        const newList = contractorList.map((item) => item.id).join(',');
        if (newList === state.filters.contractor) return undefined;
        const newParams = {...state};
        newParams.filters.page = 1;
        newParams.filters.contractor = newList;
    })
    .on($selectedActionDate, (state, selectedActionDate) => {
        const newActivityDate = formatDateToBackendFormat(selectedActionDate);
        if (state.filters.actionDate === newActivityDate) return undefined;
        return {
            ...state,
            filters: {...state.filters, actionDate: newActivityDate, page: 1},
        } as TExtendedContractsPage;
    })
    .on(selectedPurchasingEventChanged, (state, selectedList) => {
        const listString = selectedList.map((item) => item.value).join(',');
        if (state.filters.purchasingEvent === listString) return undefined;
        return {...state, filters: {...state.filters, page: 1, purchasingEvent: listString}};
    });

// выбор селекта ГПО
sample({
    clock: selectedContractorChanged,
    target: $selectedContractorList,
});

sample({
    clock: $contractorList,
    source: $contractListParams,
    fn: (params, source) =>
        source.filter((item) =>
            params.filters.contractor?.split(',').some((id) => item.id.toString() === id),
        ),
    target: $selectedContractorList,
});

// сохранение фильтров
sample({
    clock: filtersSaved,
    source: combine({
        currentSettings: $tableSettings,
        oldSettings: $contractListParams,
        contractorList: $selectedContractorList,
    }),
    fn: ({oldSettings, currentSettings}) => {
        const newParams = cloneDeep(currentSettings);

        if (!isEqual(oldSettings.columns, currentSettings.columns)) {
            newParams.columns = currentSettings.columns;
        }
        return newParams;
    },
    target: $tableSettings,
});

sample({
    source: $userActions,
    fn: (actions) => userHasPermission(EAccessActions.ContractListLinkShowing, actions),
    target: $linkColumnShown,
});

sample({
    clock: filtersSaved,
    source: combine({
        oldSettings: $contractListParams,
        currentSettings: $tableSettings,
    }),
    filter: ({currentSettings, oldSettings}) => !isEqual(currentSettings, oldSettings),
    fn: ({currentSettings}) => cloneDeep(currentSettings),
    target: $contractListParams,
});

// сброс боковых фильтров
reset({
    clock: filtersDiscarded,
    target: [$selectedContractorList, $tableSettings, $appliedFiltersCount, $selectedActionDate],
});

sample({
    clock: filtersDiscarded,
    target: filtersSaved,
});

// сброс всех фильтров
reset({
    clock: resetAllSelectedData,
    target: [
        $selectedContractorList,
        $appliedFiltersCount,
        $contractListParams,
        $innerSearchValue,
        $selectedActionDate,
    ],
});

// применение каунтера при сохранении фильтров
sample({
    source: $contractListParams,
    fn: (params) => countAppliedFilters(params.filters),
    target: $appliedFiltersCount,
});

resetDomainStoresByEvents(ContractListPageDomain, ContractListPageGate.close);
