import {createGate} from 'effector-react';
import {combine, createDomain} from 'effector';
import {ICoefficient} from 'pages/ApplyCoefficientsPage/types';
import {loadCoefficientsFx} from '../index';

export const decreasingCoefficientsDomain = createDomain('decreasingCoefficientsDomain');

export const ApplyDecreasingCoefficientsGate = createGate<string>();

export const setDecreasingSearchTerm = decreasingCoefficientsDomain.createEvent();
export const filterDecreasingBySearchTerm = decreasingCoefficientsDomain.createEvent();

export const toggleDecreasingSelect = decreasingCoefficientsDomain.createEvent<ICoefficient>();

export const $searchDecreasingTerm = decreasingCoefficientsDomain.createStore('');
export const $allDecreasingCoefficients = decreasingCoefficientsDomain.createStore<
    Array<ICoefficient>
>([]);

export const $filteredDecreasingCoefficients = decreasingCoefficientsDomain.createStore<
    Array<ICoefficient>
>([]);

export const $selectedDecreasingCoefficients = decreasingCoefficientsDomain.createStore<
    Array<ICoefficient>
>([]);

export const $decreasingCoefficientTab = combine({
    loading: loadCoefficientsFx.pending,
    decreasingCoefficients: $filteredDecreasingCoefficients,
    selectedDecreasingCoefficients: $selectedDecreasingCoefficients,
    decreasingSearchTerm: $searchDecreasingTerm,
});
