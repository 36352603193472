import {masks} from 'shared/ui/deprecated/MaskInput/typesMasks';
import {IMaskMixin} from 'react-imask';
import TextField from 'shared/ui/deprecated/TextField/TextField';
import React, {useEffect, useState} from 'react';

const IMaskInput = IMaskMixin(({inputRef, ...props}) => {
    return <TextField inputRef={inputRef} {...props} />;
});

function MaskInput(props) {
    const {rightIcon, mask, inputValue, onChange, ...rest} = props;

    const [onFocus, setOnFocus] = useState(false);
    const [value, setValue] = useState('');
    const maskType = masks(mask, onFocus);

    const handlerInput = (mask) => {
        setValue(mask._value);
        mask.masked.isComplete && onChange(mask._value);
    };

    useEffect(() => {
        setValue(inputValue);
    }, [inputValue]);

    return (
        <IMaskInput
            rightIcon={rightIcon}
            value={value}
            onFocus={() => setOnFocus(true)}
            onBlur={() => setOnFocus(false)}
            onAccept={(value, mask) => handlerInput(mask)}
            {...maskType}
            {...rest}
        />
    );
}

export default MaskInput;
