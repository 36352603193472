import React from 'react';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import {Button, Icon, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont/icons';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import BreadcrumbItem from 'shared/ui/deprecated/Breadcrumb/BreadcrumbItem';
import Breadcrumb from 'shared/ui/deprecated/Breadcrumb/Breadcrumb';
import {formatDate} from 'shared/helpers/formatHelper';
import {CONTRACT_TITLE, FROM_TITLE, GO_TO_BASIS_TITLE} from 'shared/constants';

import {SkeletonHeader} from './skeletons/SkeletonHeader';
import {$contractViewPageStore, $externalLinkButtonShowed} from '../model';

export const ContractViewHeader = () => {
    const {contractData} = useStore($contractViewPageStore);
    const buttonShown = useStore($externalLinkButtonShowed);

    if (!contractData) return <SkeletonHeader />;
    const {number, date_conclusion, dcm_hyperlinks} = contractData;
    return (
        <WrapperFlex
            justifyContent="space-between"
            alignItems="flex-start"
            gap="16px"
            flexWrap="wrap"
        >
            <WrapperFlex flexDirection="column" gap="12px">
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/contracts">Договоры</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>{`${CONTRACT_TITLE} ${number}`}</BreadcrumbItem>
                </Breadcrumb>
                <WrapperFlex gap="16px">
                    <Typography variant="h4">{`${CONTRACT_TITLE} ${number} ${FROM_TITLE} ${formatDate(
                        date_conclusion,
                    )}`}</Typography>
                </WrapperFlex>
            </WrapperFlex>
            {buttonShown && (
                <Button
                    startIcon={<Icon iconName={Icons.OpenInWindow} />}
                    href={dcm_hyperlinks}
                    target="_blank"
                >
                    {GO_TO_BASIS_TITLE}
                </Button>
            )}
        </WrapperFlex>
    );
};
