import React, {useEffect, useRef} from 'react';
import {useStore} from 'effector-react';
import {NotFound, NotFoundCaption, NotFoundTitle} from 'components/NotFound';
import {IAgreementWorksheet} from 'pages/DSPage/types';
import {WorkExecutionObjectList} from 'pages/DSPage/ui/WorkExecutionObjectList/WorkExecutionObjectList';
import {TableHead} from 'shared/ui/Table/Table';
import {IStatus} from 'shared/types/statusTypes';
import {formatCurrency} from 'shared/helpers/formatHelper';
import {useWindowSize} from 'shared/hooks/useWindowSize';
import {
    NO_TAX_TITLE,
    SHEET_TOTAL_SUM_TITLE,
    SHEET_TOTAL_SUM_WITHOUT_TAX_TITLE,
    SUM_TITLE,
    TAX_TITLE,
} from 'shared/constants';

import * as S from './style';
import {$currentWidth, currentWidthChanged} from '../../model';

export interface IAdditionalAgreementTableProps {
    vir: IAgreementWorksheet;
    status: IStatus;
    isVK: boolean;
}

export const AdditionalAgreementTable = ({vir, status, isVK}: IAdditionalAgreementTableProps) => {
    const currentWidth = useStore($currentWidth);
    const size = useWindowSize();
    const hasVAT = !!vir.sum_tax && vir.sum_tax > 0;
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (ref.current) {
            const el = document.getElementById('target-width');
            if (!!el) {
                currentWidthChanged(626 + el.offsetWidth);
            }
        }
    }, [ref, size.width]);

    return (
        <S.CustomTable ref={ref}>
            {Object.keys(vir.work_objects || []).length <= 0 ? (
                <S.CustomTableRow>
                    <S.CustomTableCell>
                        <NotFound height="292px">
                            <NotFoundTitle>Объекты не добавлены</NotFoundTitle>
                            <NotFoundCaption>
                                Чтобы включить объекты в ведомость, нажмите на кнопку «Добавить
                                объекты»
                            </NotFoundCaption>
                        </NotFound>
                    </S.CustomTableCell>
                </S.CustomTableRow>
            ) : (
                <WorkExecutionObjectList
                    vir={vir}
                    agreementStatus={status}
                    isVK={isVK}
                    currentWidth={currentWidth - 626}
                />
            )}
            <S.CustomTableRow width="100%" minHeight="152px" noBorder>
                {vir?.work_objects &&
                    Object.keys(vir.work_objects)?.length > 0 &&
                    currentWidth > 0 && (
                        <S.CustomTableCell flex={`0 1 ${currentWidth}px`} noPadding>
                            <S.CustomTableRow width="100%" justifyContent="flex-end" noBorder>
                                <S.CustomTableCell alignItems="flex-end" noPadding>
                                    <TableHead>{SUM_TITLE}</TableHead>
                                </S.CustomTableCell>
                                <S.CustomTableCell alignItems="flex-end" flex="0 0 137px">
                                    <TableHead>
                                        {formatCurrency((vir.sum || 0).toString())}
                                    </TableHead>
                                </S.CustomTableCell>
                            </S.CustomTableRow>
                            <S.CustomTableRow width="100%" justifyContent="flex-end" noBorder>
                                <S.CustomTableCell alignItems="flex-end" noPadding>
                                    <TableHead>{hasVAT ? TAX_TITLE : NO_TAX_TITLE}</TableHead>
                                </S.CustomTableCell>
                                <S.CustomTableCell alignItems="flex-end" flex="0 0 137px">
                                    <TableHead>
                                        {hasVAT && formatCurrency((vir.sum_tax || 0).toString())}
                                    </TableHead>
                                </S.CustomTableCell>
                            </S.CustomTableRow>
                            <S.CustomTableRow width="100%" justifyContent="flex-end" noBorder>
                                <S.CustomTableCell noPadding>
                                    <TableHead>
                                        {hasVAT
                                            ? SHEET_TOTAL_SUM_TITLE
                                            : SHEET_TOTAL_SUM_WITHOUT_TAX_TITLE}
                                    </TableHead>
                                </S.CustomTableCell>
                                <S.CustomTableCell alignItems="flex-end" flex="0 0 137px">
                                    <TableHead>
                                        {formatCurrency((vir.total_sum || 0).toString())}
                                    </TableHead>
                                </S.CustomTableCell>
                            </S.CustomTableRow>
                        </S.CustomTableCell>
                    )}
            </S.CustomTableRow>
        </S.CustomTable>
    );
};
