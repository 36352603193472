import React, {useState} from 'react';
import styled, {css} from 'styled-components/macro';
import {TableCell, TableCellContent} from 'shared/ui/Table/Table';
import Checkbox from 'shared/ui/deprecated/Checkbox/Checkbox';

interface OrdinalCellProps {
    ordinalNumber: number;
}

export function OrdinalCell({ordinalNumber}: OrdinalCellProps) {
    const [checked, setChecked] = useState(false);
    const [shownCheckBox, setShownCheckBox] = useState(false);

    return (
        <TableCell width="56px">
            <StyledCheckBoxCell>
                <StyledCheckbox
                    shownCheckBox={shownCheckBox}
                    onMouseLeave={() => !checked && setShownCheckBox(false)}
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    isPartly={false}
                />

                <SpecificationIndex shownCheckBox={shownCheckBox}>
                    {ordinalNumber + 1}
                </SpecificationIndex>
            </StyledCheckBoxCell>
        </TableCell>
    );
}

export const StyledCheckbox = styled(Checkbox)<{shownCheckBox?: boolean}>`
    position: absolute;
    transition-duration: 0.2s;
    ${(p) =>
        !p.shownCheckBox &&
        css`
            opacity: 0;
            z-index: -1;
        `}
`;

export const StyledCheckBoxCell = styled(TableCellContent)`
    position: relative;
`;

export const SpecificationIndex = styled.div<{shownCheckBox?: boolean}>`
    position: absolute;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0.2s;
    ${(p) =>
        p.shownCheckBox &&
        css`
            opacity: 0;
            z-index: -1;
        `}
`;
