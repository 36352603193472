import styled, { css } from "styled-components";

export const NotFound = styled.div<{height?:string}>`
align-items: center;
display: flex;
flex-direction: column;
flex-grow: 1;
justify-content: center;
min-height:var(--min-nf,none);
    ${props => props.height && css`
        height: ${props.height};
        --min-nf:${props.height};
    `}
`
export const NotFoundTitle = styled.div`
font-weight: 700;
margin-bottom: 8px;
font-size: 17px;
line-height: 1.2941176471;
`

export const NotFoundCaption = styled.div`
font-size: 17px;
line-height: 1.2941176471;
color:var(--secondary);
`