import styled from 'styled-components/macro';

export const Wrapper = styled.dl`
    display: flex;
    margin-bottom: 0;
    gap: 20px;
`;

export const IconCoefficientBlock = styled.div`
    display: flex;
    gap: 12px;
    max-width: 400px;
    margin-left: auto;
`;
