import styled, {css} from 'styled-components/macro';

const baseLabelMixin = () => css`
    padding: 1px 7px;
    color: var(--label-color);
    border-radius: 12px;
    --label-color: var(--outline-body-color);
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    line-height: calc(20 / 15);
    background-color: var(--label-bg);
    border: 1px solid var(--label-bg);
    .icon {
        width: 20px;
        min-width: 20px;
        height: 20px;
        color: var(--label-color);
    }
    span {
        white-space: nowrap;
    }
`;
const Outline = () => css`
    color: var(--label-bg);
    .icon {
        color: var(--label-bg);
    }
    background-color: transparent;
`;
const Icon = () => css`
    padding-left: 3px;
    span {
        margin-left: 4px;
    }
`;
const OnlyIcon = () => css`
    width: 24px;
    height: 24px;
    padding-left: 1px !important;
    padding-right: 1px !important;
`;
const Error = () => css`
    --label-bg: var(--red);
`;
const Success = () => css`
    --label-bg: var(--green);
`;
const Info = () => css`
    --label-bg: var(--blue);
`;
const Warning = () => css`
    --label-bg: var(--orange);
`;
const Neutral = () => css`
    --label-bg: var(--grey);
`;
const Magenta = () => css`
    --label-bg: var(--magenta);
`;
const Purple = () => css`
    --label-bg: var(--purple);
`;
const Teal = () => css`
    --label-bg: var(--teal);
`;
export const labelMixin = (props) => css`
    ${baseLabelMixin()}
    ${props.variant === 'error' && Error}
	${props.variant === 'success' && Success}
	${props.variant === 'info' && Info}
	${props.variant === 'warning' && Warning}
	${props.variant === 'default' && Neutral}
	${props.variant === 'magenta' && Magenta}
	${props.variant === 'purple' && Purple}
	${props.variant === 'teal' && Teal}
	${props.outline && Outline}
	${props.onlyicon && OnlyIcon}
	${props.icon && Icon}
`;

export const LabelStyled = styled.div`
    ${(props) => labelMixin(props)}
`;
