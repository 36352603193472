import {ReactNode} from 'react';
import {TableCell, TableCellContent, TableString} from 'shared/ui/Table/Table';
import {Skeleton} from 'shared/ui/deprecated/Skeleton/Skeleton';

interface TableSkeletonProps {
    columns: {content: ReactNode; width: string}[];
    pageSize: number;
}

export const TableSkeleton = ({columns, pageSize}: TableSkeletonProps) => {
    return (
        <>
            {[...Array(pageSize)].map((el, idx) => (
                <TableString key={idx}>
                    {columns.map((col, idx) => (
                        <TableCell key={idx} width={col.width}>
                            <TableCellContent>
                                <Skeleton height={20} />
                            </TableCellContent>
                        </TableCell>
                    ))}
                </TableString>
            ))}
        </>
    );
};
