import React from 'react';
import {Button, Icon, TextField, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import * as S from './style';

interface DeadlineBlockProps {
    value: number;
    minValue?: number;
    onIncrease: () => void;
    onDecrease: () => void;
}

export const DeadlineBlock = ({
    value,
    minValue = 10,
    onIncrease,
    onDecrease,
}: DeadlineBlockProps) => {
    return (
        <S.DeadlineWrapper>
            <Button
                size="medium"
                startIcon={<Icon iconName={Icons.Remove} />}
                onClick={onDecrease}
                disabled={value <= minValue}
            />
            <S.TextFieldWrapper>
                <TextField value={value} disabled fullWidth />
            </S.TextFieldWrapper>
            <Button size="medium" startIcon={<Icon iconName={Icons.Add} />} onClick={onIncrease} />
            <S.DeadlineInfo>
                <Typography variant="body2">
                    {value} рабочих дней с момента получения исходных данных
                </Typography>
            </S.DeadlineInfo>
        </S.DeadlineWrapper>
    );
};
