export const DOCUMENT_ALLOWED_FORMAT_LIST = [
    'xls',
    'doc',
    'pdf',
    'png',
    'jpg',
    'jpeg',
    'dwg',
    'txt',
    'csv',
    'zip',
    'rar',
    'msg',
    'eml',
];

export const MAX_ALLOWED_UPLOAD_FILE_SIZE = 209_715_201;
