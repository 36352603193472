import styled, {css} from 'styled-components/macro';
import {Typography} from '@beeline/design-system-react';

export const ForbiddenContent = styled.div`
    height: calc(100vh - var(--toplineHeight));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const StyledTypography = styled(Typography)<{margin: string}>`
    ${(props) =>
        props.margin &&
        css`
            margin: ${props.margin};
        `}
`;
