import styled from 'styled-components/macro';

export const Item = styled.div`
    display: flex;
    gap: 12px;
    margin-bottom: 30px;
    align-items: flex-start;
`;

export const Wrapper = styled.div`
    margin-top: 24px;
`;
