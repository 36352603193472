import {combine, sample} from 'effector';
import {forwardPayload} from 'shared/helpers/effector';
import {
    $activeTab,
    $allSelectedCoefficients,
    $branchName,
    $coefficients,
    $formula,
    $mainCoefficient,
    $tpiToConfigCoefficient,
    ApplyCoefficientsGate,
    loadCoefficientsFx,
    setActiveTab,
} from './index';
import './increasingCoefficientsTab/init';
import './decreasingCoefficientsTab/init';
import './otherCoeficientsTab/init';
import {
    $selectedIncreasingCoefficients,
    toggleAllIncreasingSelect,
    toggleIncreasingSelect,
} from './increasingCoefficientsTab';
import {$selectedDecreasingCoefficients, toggleDecreasingSelect} from './decreasingCoefficientsTab';
import {
    $customCoefficients,
    addCoefficient,
    deleteCoefficient,
    updateExistingCoefficient,
} from './otherCoeficientsTab';
import {COEFFICIENT_TYPE_ENUM} from '../types';

import {formatNumber, formatNumberToDecimalWithComma} from 'shared/helpers/formatHelper';
import {$createAdditionalAgreementData} from 'processes/contructor';
import {WorkCoefficientType} from 'shared/types/additionalAgreementsTypes';
import {hasDismantlingCoefficients} from '../../../shared/helpers/coefficients';

$activeTab.on(setActiveTab, forwardPayload()).reset(ApplyCoefficientsGate.close);

sample({
    clock: ApplyCoefficientsGate.open,
    source: $createAdditionalAgreementData,
    filter: (source, clock) => !!source.tpi_id && !!clock,
    fn: (data, id) => {
        const {tpi_id, project_id, remote_territory_id} = data;
        return {
            tpi_specification_id: id || '',
            tpis_id: tpi_id || '',
            remote_territory_id: remote_territory_id,
            project_id: project_id || '',
        };
    },
    target: loadCoefficientsFx,
});

$branchName.on(loadCoefficientsFx.doneData, (state, {data}) => data?.[0]?.branch_name);

sample({
    clock: loadCoefficientsFx.doneData,
    source: $tpiToConfigCoefficient,
    fn: (selected, {data}) => {
        const coefficient_ams = +data?.[0]?.coefficient_ams;
        const coefficient_foundation = +data?.[0]?.coefficient_foundation;
        const coefficient_remote_territory = +data?.[0]?.coefficient_remote_territory;
        const isAms = selected?.spec.is_ams;
        const isFoundation = selected?.spec.is_foundation;
        return {
            remoteTerritory: coefficient_remote_territory,
            ams: isAms ? coefficient_ams : null,
            foundation: isFoundation ? coefficient_foundation : null,
        };
    },
    target: $coefficients,
});

const triggerCalculation = sample({
    clock: [
        toggleIncreasingSelect,
        toggleAllIncreasingSelect,
        toggleDecreasingSelect,
        loadCoefficientsFx.doneData,
        deleteCoefficient,
        addCoefficient,
        updateExistingCoefficient,
    ],
    source: combine({
        increasingRare: $selectedIncreasingCoefficients,
        decreasingRare: $selectedDecreasingCoefficients,
        customRare: $customCoefficients,
        baseCoefficients: $coefficients,
    }),
    fn: ({increasingRare, decreasingRare, customRare, baseCoefficients}) => {
        const increasingByBase = increasingRare.reduce<number[]>(
            (acc, curr) =>
                curr.type === COEFFICIENT_TYPE_ENUM.Increasing ? [...acc, curr.coefficient] : acc,
            [],
        );
        const increasingBySum = increasingRare.reduce<number[]>(
            (acc, curr) =>
                curr.type === COEFFICIENT_TYPE_ENUM.IncreasingBySum
                    ? [...acc, curr.coefficient]
                    : acc,
            [],
        );
        const decreasing = decreasingRare.map((el) => el.coefficient);
        const base = hasDismantlingCoefficients(decreasingRare)
            ? baseCoefficients.remoteTerritory
                ? [baseCoefficients.remoteTerritory]
                : []
            : (Object.values(baseCoefficients).filter(Boolean) as number[]);

        const custom = customRare
            .filter((el) => el.coefficient !== null)
            .map((el) => el.coefficient) as number[];

        return {
            toMultiply: [...(base.length ? base : [1]), ...increasingByBase],
            decreasing: decreasing,
            custom: custom,
            toSum: increasingBySum,
        };
    },
});
sample({
    clock: triggerCalculation,
    fn: ({toMultiply, toSum, custom, decreasing}) => {
        const simple = toMultiply.map((el) => formatNumberToDecimalWithComma(el)).join(' x ');
        const customs = custom.map((el) => formatNumberToDecimalWithComma(el)).join(' x ');
        const decs = decreasing.map((el) => formatNumberToDecimalWithComma(el)).join(' x ');
        let complex = toSum.map((el) => formatNumberToDecimalWithComma(el)).join(' + ');
        if (toSum.length > 1) {
            complex = `(${complex}+ 1 - ${toSum.length})`;
        }
        let finalFormulaString = simple;
        if (toSum.length) {
            finalFormulaString = finalFormulaString.concat(` x ${complex}`).replaceAll('.', ',');
        }
        if (decs.length) {
            finalFormulaString = finalFormulaString.concat(` x ${decs}`);
        }
        if (customs.length) {
            finalFormulaString = finalFormulaString.concat(` x ${customs}`);
        }
        return `k ${finalFormulaString ? `= ${finalFormulaString}` : finalFormulaString}`;
    },
    target: $formula,
});

sample({
    source: combine({
        inc: $selectedIncreasingCoefficients,
        dec: $selectedDecreasingCoefficients,
        custom: $customCoefficients,
    }),
    fn: ({dec, inc, custom}) => {
        const existing = [...inc, ...dec];
        const customs = custom
            .filter((el) => !!el.coefficient)
            .map((el) => ({
                is_manual: true,
                coefficient: el.coefficient,
                id: el.id,
            }));
        return [...existing, ...customs] as unknown as WorkCoefficientType[];
    },
    target: $allSelectedCoefficients,
});

//Рассчет итогового коэффициента
sample({
    clock: triggerCalculation,
    fn: ({toMultiply, decreasing, custom, toSum}) => {
        const multiplySimple = formatNumber(
            [...toMultiply, ...decreasing, ...custom]?.reduce((acc, curr) => acc * curr, 1),
            6,
        );
        const sum = toSum.reduce((acc, curr) => acc + curr, 0) + 1 - toSum.length;
        return formatNumber(Number(multiplySimple * (sum || 1)), 4);
    },
    target: $mainCoefficient,
});
