import {useEffect, useState} from 'react';
import cn from 'classnames';

import {TableCell} from 'shared/ui/Table/Table';
import {useWindowSize} from 'shared/hooks/useWindowSize';
import {formatCurrency} from 'shared/helpers/formatHelper';
import {NO_TAX_TITLE, TAX_TITLE} from 'shared/constants';

import * as S from './style';

export interface SummaryProps {
    sums: {
        sum?: number;
        sum_tax?: number;
        total_sum?: number;
    };
    agreementOnRevision?: boolean;
    unclosed?: boolean;
    isTotal?: boolean;
}

export function Summary({
    sums: {sum, sum_tax, total_sum},
    agreementOnRevision,
    unclosed,
    isTotal,
}: SummaryProps) {
    const [titleWidth, setTitleWidth] = useState(0);
    const [valueWidth, setValueWidth] = useState(0);
    const size = useWindowSize();
    const titleId = 'titleWidth';
    const valueId = 'valueWidth';
    useEffect(() => {
        const titleEl = document.getElementById(titleId);
        const valueEl = document.getElementById(valueId);
        if (!!titleEl) {
            setTitleWidth(titleEl.offsetWidth);
        }
        if (!!valueEl) {
            setValueWidth(valueEl.offsetWidth);
        }
    }, [size.width]);

    const inactiveClassName = cn({inactiveText: unclosed});

    const hasVAT = !!sum_tax && sum_tax > 0;
    const idShown = !agreementOnRevision && !unclosed && !isTotal;
    const titleWidthString = isTotal ? `${titleWidth}px` : undefined;
    const valueWidthString = isTotal ? `${valueWidth}px` : undefined;
    const colspan = isTotal ? undefined : 6;
    return (
        <>
            <S.FinalSumString className={inactiveClassName}>
                <TableCell colspan={colspan} id={idShown ? titleId : undefined}>
                    <S.Name width={titleWidthString}>{`Итого${isTotal ? ' по ВВР' : ''}${
                        unclosed ? ' осталось' : ''
                    } без НДС:`}</S.Name>
                </TableCell>
                <TableCell width="132px" id={idShown ? valueId : undefined}>
                    <S.Value width={valueWidthString} className={inactiveClassName}>
                        {formatCurrency((sum || 0).toString())}
                    </S.Value>
                </TableCell>
                {isTotal && <TableCell width="340px" />}
            </S.FinalSumString>
            <S.FinalSumString>
                <TableCell colspan={colspan}>
                    <S.Name width={titleWidthString} className={inactiveClassName}>
                        {hasVAT ? TAX_TITLE : NO_TAX_TITLE}
                    </S.Name>
                </TableCell>
                {hasVAT && (
                    <TableCell>
                        <S.Value width={valueWidthString} className={inactiveClassName}>
                            {formatCurrency((sum_tax || 0).toString())}
                        </S.Value>
                    </TableCell>
                )}
                {isTotal && <TableCell />}
            </S.FinalSumString>
            <S.FinalSumString>
                <TableCell colspan={colspan}>
                    <S.Name width={titleWidthString} className={inactiveClassName}>{`Итого${
                        unclosed ? ' осталось' : ''
                    } по ${isTotal ? 'ВВР' : 'ведомости'}${hasVAT ? ' с НДС' : ''}:`}</S.Name>
                </TableCell>
                <TableCell width="132px">
                    <S.Value width={valueWidthString} className={inactiveClassName}>
                        {formatCurrency((total_sum || 0).toString())}
                    </S.Value>
                </TableCell>
                {isTotal && <TableCell />}
            </S.FinalSumString>
        </>
    );
}
