import React from 'react';
import {useParams} from 'react-router-dom';
import {useGate, useStore, useUnit} from 'effector-react';
import {Skeleton, Typography} from '@beeline/design-system-react';

import {CommonLayout} from 'shared/ui/CommonLayout';
import {AdditionalAgreementTable} from 'pages/DSPage/ui/AdditionalAgreementTable';
import {DsCommentsBlock} from 'widgets/DsCommentsBlock';
import {ErrorListWidget} from 'widgets/ErrorListWidget';
import {CertificateSignature} from 'Features/CertificateSignature';
import {$certificateSignatureWindowOpened} from 'Features/CertificateSignature/model';
import {AgreementStatusLabel} from 'Entities/AgreementStatusLabel';
import {NotOpenedSheetsBanner} from 'Entities/NotOpenedSheetsBanner';
import {NotFound, NotFoundCaption, NotFoundTitle} from 'components/NotFound';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {Accordion} from 'shared/ui/deprecated/Accordion/Accordion';
import {$isUserVK, $userStore} from 'shared/model/user';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {EAttachmentType} from 'shared/types/FileTypes';
import {EAdditionalAgreementType} from 'shared/types/additionalAgreementsTypes';

import {DSPageTitle} from './DSPageTitle/DSPageTitle';
import {DSPageInfo} from './DSPageInfo/DSPageInfo';
import {DSPageAddenda} from './DSPageAddenda';
import {DSPageIntegrationUnit} from './DSPageIntegrationUnit';
import {TotalSumUnit} from './TotalSumUnit';
import {CompletedWorksUnit} from './CompletedWorksUnit';
import * as S from './style';
import {
    $agreementObject,
    $buttonsDisabled,
    $clickedWorkflowButtonIndex,
    $errorSubPageShowed,
    $tasksData,
    additionalAgreementPage,
    AdditionalAgreementPageGate,
    diadocArchiveDownloaded,
    errorSubPageToggled,
    getDsAttachment,
    handleBackendBtn,
    sendAgreementComment,
    setActiveVir,
} from '../model';

export function DSPage() {
    const {agreementId} = useParams<{agreementId: string}>();
    useGate(AdditionalAgreementPageGate, {projectId: '', agreementId});

    const showCertificateSignWindow = useStore($certificateSignatureWindowOpened);
    const errorSubPageShowed = useStore($errorSubPageShowed);
    const clickedButtonIndex = useStore($clickedWorkflowButtonIndex);
    const buttonIsLoading = useStore($buttonsDisabled);

    const renderElement = () => {
        if (errorSubPageShowed && clickedButtonIndex !== null)
            return (
                <ErrorListWidget
                    agreementId={agreementId ?? ''}
                    onClick={() => handleBackendBtn(clickedButtonIndex)}
                    onClose={() => errorSubPageToggled(false)}
                    isLoading={buttonIsLoading}
                />
            );
        if (showCertificateSignWindow) return <CertificateSignature />;
        return <AdditionalAgreementPageContainer agreementId={agreementId ?? ''} />;
    };
    return (
        <CommonLayout>
            <WrapperOffset offset={32}>{renderElement()}</WrapperOffset>
        </CommonLayout>
    );
}

const AdditionalAgreementPageContainer = ({agreementId}: {agreementId: string}) => {
    const {
        agreement,
        agreementType,
        isLoadingAgreement,
        activeVir,
        isVK,
        loadingAttachmentId,
        virState,
        warningBannerShowed,
    } = useStore(additionalAgreementPage);
    const [agreementObject, isUserVK, tasksData, user] = useUnit([
        $agreementObject,
        $isUserVK,
        $tasksData,
        $userStore,
    ]);

    const dsStatus = tasksData[0]?.status ?? 0;

    const isApproved = !!agreement?.approved_at;
    const showIntegrationUnit = isUserVK && isApproved;

    const handleDownloadArchive = () => {
        diadocArchiveDownloaded(agreement?.id ?? '');
    };
    const handleSaveComment = (comment: string) => {
        sendAgreementComment({id: agreementId ?? '', comment});
    };

    const handleDownloadAttachment = (fileId: string, fileName: string, type: EAttachmentType) => {
        getDsAttachment({fileId, fileName, type});
    };

    const showAccordion = agreementType !== EAdditionalAgreementType.Termination;
    const showAddendaAndCompletedWorks = !!agreement && !isLoadingAgreement;
    return (
        <>
            <DSPageTitle
                agreement={agreement}
                agreementType={agreementType}
                isLoading={isLoadingAgreement}
            />
            <DSPageInfo dsData={agreement} isLoading={isLoadingAgreement} />
            {showIntegrationUnit && (
                <DSPageIntegrationUnit
                    data={agreement?.linked_entities}
                    onDownload={handleDownloadArchive}
                />
            )}
            <WrapperOffset offsetTop={36} gap="36px" flexColumn columnReverse={!isApproved}>
                {showAddendaAndCompletedWorks && (
                    <>
                        <DSPageAddenda addenda={agreement.linked_entities.addendums} />
                        <CompletedWorksUnit
                            completedWorksSheetList={agreement.linked_entities.vvrs}
                        />
                    </>
                )}
                <WrapperFlex flexDirection="column" gap="8px">
                    {showAccordion && (
                        <>
                            <Typography variant="h5">Ведомость работ</Typography>
                            <NotOpenedSheetsBanner
                                openedSheetList={virState}
                                showBanner={warningBannerShowed}
                            />
                            <S.Wrapper>
                                {isLoadingAgreement ? (
                                    <Skeleton height={200} variant="line" />
                                ) : (
                                    <Accordion>
                                        {agreement?.works?.length ? (
                                            <>
                                                {agreement?.works?.map((vir, index) => (
                                                    <S.StyledAccordionItem
                                                        key={vir.id}
                                                        id={vir.id}
                                                        title={`Ведомость ${index + 1}`}
                                                        active={activeVir === vir?.id}
                                                        setActive={() => {
                                                            setActiveVir(vir?.id);
                                                        }}
                                                        rightslot={
                                                            <AgreementStatusLabel
                                                                status={dsStatus}
                                                                virId={vir.id}
                                                                agreementObject={agreementObject}
                                                            />
                                                        }
                                                        noOverflow
                                                    >
                                                        <AdditionalAgreementTable
                                                            vir={vir}
                                                            isVK={isVK}
                                                            status={agreement.status}
                                                        />
                                                    </S.StyledAccordionItem>
                                                ))}
                                                <TotalSumUnit agreement={agreement} />
                                            </>
                                        ) : (
                                            <NotFound height="400px">
                                                <NotFoundTitle>
                                                    По вашему запросу ничего не найдено
                                                </NotFoundTitle>
                                                <NotFoundCaption>
                                                    Попробуйте сократить запрос или задать его
                                                    по-другому
                                                </NotFoundCaption>
                                            </NotFound>
                                        )}
                                    </Accordion>
                                )}
                            </S.Wrapper>
                        </>
                    )}
                    {(!isLoadingAgreement &&
                        agreement?.agreement_attachments != null &&
                        agreement?.agreement_attachments.length !== 0) ||
                    (agreement?.agreement_comments != null &&
                        agreement?.agreement_comments.length !== 0) ||
                    user.user_type === 5 ? (
                        <DsCommentsBlock
                            // arrFile={DSArrFile}
                            attachmentList={agreement?.agreement_attachments}
                            commentList={agreement?.agreement_comments}
                            handleSaveComment={handleSaveComment}
                            handleDownloadAttachment={handleDownloadAttachment}
                            loadingAttachmentId={loadingAttachmentId}
                        />
                    ) : null}
                </WrapperFlex>
            </WrapperOffset>
        </>
    );
};
