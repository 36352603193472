import {createDomain, forward, sample} from 'effector';
import {createGate} from 'effector-react';

import {forwardPayload} from 'shared/helpers/effector';
import {UserType} from 'shared/services/types/userTypes';
import {getUser} from 'shared/services/user.service';

export const viewProjectTitleDomain = createDomain();
export const viewProjectTitleGate = createGate<string>();

export const getEditorInfo = viewProjectTitleDomain.createEvent<string>();

export const fetchEditorInfoFx = viewProjectTitleDomain.createEffect(async (id: string) => {
    try {
        const result = await getUser(id);
        return result.data;
    } catch (e) {
        console.warn(e);
    }
});

export const $editorInfo = viewProjectTitleDomain
    .createStore<UserType | null>(null)
    .on(fetchEditorInfoFx.doneData, forwardPayload());

export const $editorId = viewProjectTitleDomain
    .createStore<string>('')
    .on(viewProjectTitleGate.open, forwardPayload());

sample({
    source: $editorId,
    target: getEditorInfo,
});

forward({from: getEditorInfo, to: fetchEditorInfoFx});
