import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    position: relative;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

export const ContentWrapper = styled.div`
    width: 1278px;
    flex: 1 1 80vh;
    margin: 32px auto 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: auto;
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const AgreementWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 32px;
`;

export const AutocompleteWrapper = styled.div`
    flex: 0 0 692px;
`;
export const SheetWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    &:last-child {
        margin-bottom: 32px;
    }
`;
export const SheetTitleWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    > span {
        margin: 0 16px;
    }
`;
export const SheetHalfTitleWrapper = styled.div`
    flex: 0 0 437px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`;
export const SheetToTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
export const BottomWrapper = styled.div`
    position: sticky;
    bottom: 0;
    width: 100%;
    background: var(--color-background-base);
`;

export const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 17px 162px 24px;
`;

export const ProgressWrapper = styled.div`
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
