import styled from 'styled-components/macro';

export const UnitWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
`;

export const CommentWrapper = styled.div`
    flex: 1 1 500px;
    min-width: 400px;
`;

export const ParentCommentWrapper = styled.div``;
