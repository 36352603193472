import {ConstructorVirType, IUpdatedAgreement} from 'shared/types/additionalAgreementsTypes';
import {deleteFieldsFromObject} from 'shared/helpers/transformObject';
import {formatDateToBackendFormat} from 'shared/helpers/formatHelper';
import {TpiSpecification} from 'pages/VirSelect/types';
import {isEqual} from 'lodash';
import {detectAgreementChanges} from './detectAgreementRootChanges';
import {
    fieldsToDeleteFromAgreement,
    fieldsToDeleteFromTpi,
    fieldsToDeleteFromWork,
    fieldsToDeleteFromWorkObject,
} from './fieldsToDelete';
import {findPropInObject} from '../../../../shared/helpers/commonFunctions';

function extractDeletedItems(oldArray, newArray) {
    return oldArray
        .filter((el) => !newArray.includes(el))
        .map((el) => ({id: el, update_action: 'delete'}));
}

export function getUpdatedAgreement(
    rawNewAgreement: IUpdatedAgreement,
    rawOldAgreement: IUpdatedAgreement,
    lavParts: Record<string, string[]>,
) {
    const newDs = deleteFieldsFromObject(rawNewAgreement, fieldsToDeleteFromAgreement);
    const oldDs = deleteFieldsFromObject(rawOldAgreement, fieldsToDeleteFromAgreement);
    const deletedVirs = extractDeletedItems(Object.keys(oldDs.works), Object.keys(newDs.works));
    const updatedAgreement = detectAgreementChanges({newDs, oldDs});
    const readyToUpdate = {
        ...updatedAgreement,
        works: [
            ...deletedVirs,
            ...Object.entries(newDs.works).map(([workId, work], index) => {
                const oldWork = deleteFieldsFromObject(oldDs.works[workId], fieldsToDeleteFromWork);
                const newWork = {
                    ...deleteFieldsFromObject(work, fieldsToDeleteFromWork),
                    appendix_number: index + 1,
                };
                const testWork = work as ConstructorVirType;
                // формируется массив обьектов с lav_gfk
                const lavId =
                    Object.values(testWork.work_objects).find((item) => !!item.lav_gfk)?.lav_gfk ??
                    '';
                const arrayForComparison = Object.values(testWork.work_objects)
                    .filter((item) => !!item.lav_gfk)
                    .map((item) => item.object_name.split(' ')[0] ?? '');

                const isSaveLav = isEqual(lavParts[lavId], arrayForComparison);

                const deletedWorkObjectIds = Object.keys(oldDs.works?.[workId]?.work_objects || {})
                    .filter(
                        (el) =>
                            !Object.keys(newDs.works?.[workId]?.work_objects || {}).includes(el),
                    )
                    .map((el) => ({id: el, update_action: 'delete'}));

                // @ts-ignore
                const workWithActualAppendix = {...work, appendix_number: index + 1};
                const updatedWork = getUpdates({
                    oldItem: oldWork,
                    newItem: newWork,
                    item: !oldWork.id
                        ? deleteFieldsFromObject(workWithActualAppendix, ['id'])
                        : workWithActualAppendix,
                    id: workId,
                    oldItemIds: Object.keys(oldDs.works),
                }) as ConstructorVirType;
                const work_objects = [
                    ...deletedWorkObjectIds,
                    ...(!updatedWork?.work_objects
                        ? []
                        : Object.entries(updatedWork?.work_objects)?.map(
                              ([workObjectId, workObject]) => {
                                  const updateMode = workObject.object_id.startsWith('front');
                                  const oldWorkObject = deleteFieldsFromObject(
                                      oldDs.works?.[workId]?.work_objects?.[workObjectId],
                                      fieldsToDeleteFromWorkObject,
                                  );
                                  const newWorkObject = deleteFieldsFromObject(
                                      workObject,
                                      fieldsToDeleteFromWorkObject,
                                  );
                                  const oldItemIds = Object.keys(
                                      oldDs.works?.[workId]?.work_objects || {},
                                  );
                                  const updateWorkObject = getUpdates({
                                      oldItem: oldWorkObject,
                                      newItem: newWorkObject,
                                      item: {
                                          ...(oldWorkObject.id ? {id: oldWorkObject.id} : {}),
                                          update_action: newWorkObject?.update_action,
                                          work_type_id: newWorkObject.work_type_id,
                                          object_type: newWorkObject.object_type,
                                          is_half_set: newWorkObject.is_half_set,
                                          lav_gfk: isSaveLav ? newWorkObject.lav_gfk : '',
                                          ...(newWorkObject?.object_type === 3
                                              ? {
                                                    transport_net_id:
                                                        newWorkObject?.transport_net_id,
                                                }
                                              : {}),
                                      },
                                      id: workObjectId,
                                      oldItemIds,
                                  });

                                  const newSpecIds = Object.keys(
                                      workObject.work_specifications || {},
                                  );
                                  const oldSpecIds = Object.keys(
                                      oldDs.works?.[workId]?.work_objects?.[workObjectId]
                                          ?.work_specifications || {},
                                  );
                                  const deletedSpecIds = oldSpecIds
                                      .filter((el) => !newSpecIds.includes(el))
                                      .map((el) => ({id: el, update_action: 'delete'}));
                                  const work_specifications = [
                                      ...deletedSpecIds,
                                      ...Object.entries(workObject?.work_specifications).map(
                                          ([tpiKey, tpi], index) => {
                                              const ordinal_number = index + 1;
                                              const oldTpi = {
                                                  ...oldDs.works?.[workId]?.work_objects?.[
                                                      workObjectId
                                                  ]?.work_specifications?.[tpiKey],
                                              };

                                              const ids = Object.keys(
                                                  oldDs.works?.[workId]?.work_objects?.[
                                                      workObjectId
                                                  ]?.work_specifications || [],
                                              );

                                              const beginDate = tpi?.begin_date
                                                  ? formatDateToBackendFormat(tpi?.begin_date)
                                                  : null;
                                              const endDate = tpi?.end_date
                                                  ? formatDateToBackendFormat(tpi?.end_date)
                                                  : null;
                                              let value = deleteFieldsFromObject(
                                                  {
                                                      ...tpi,
                                                      begin_date: beginDate,
                                                      end_date: endDate,
                                                  },
                                                  fieldsToDeleteFromTpi.slice(updateMode ? 0 : 1),
                                              );
                                              const commentsArray = findPropInObject(
                                                  value,
                                                  'work_specification_comments',
                                              );
                                              if (Array.isArray(commentsArray)) {
                                                  if (
                                                      commentsArray.length > 0 &&
                                                      commentsArray[0].hasOwnProperty('creator')
                                                  ) {
                                                      value['work_specification_comments'] = [];
                                                  }
                                              } else if (commentsArray === undefined) {
                                                  value['work_specification_comments'] = [];
                                              }
                                              const updatedTpi: Omit<TpiSpecification, 'id'> & {
                                                  id?: string;
                                              } = getUpdates({
                                                  oldItem: oldTpi,
                                                  newItem: tpi,
                                                  item: value,
                                                  id: tpiKey,
                                                  oldItemIds: ids,
                                              });
                                              const oldCoefficients =
                                                  oldDs.works?.[workId]?.work_objects?.[
                                                      workObjectId
                                                  ]?.work_specifications?.[tpiKey]
                                                      ?.work_coefficients;
                                              const deletedCoefficients = (
                                                  oldCoefficients?.filter((el) => {
                                                      return !tpi.work_coefficients?.some(
                                                          (newEl) =>
                                                              newEl.id === el.tpi_specification ||
                                                              newEl.id === el.id,
                                                      );
                                                  }) || []
                                              ).map((el) => ({id: el.id, update_action: 'delete'}));

                                              const existingCoefficients =
                                                  tpi?.work_coefficients || [];
                                              const work_coefficients = [...existingCoefficients];

                                              const updatedCoefficients = work_coefficients.map(
                                                  (coefficient) => {
                                                      const oldTPiCoefficient =
                                                          oldCoefficients?.find(
                                                              (el) =>
                                                                  el.tpi_specification ===
                                                                  coefficient.id,
                                                          ) || {};

                                                      const final = getUpdatedCoefficients({
                                                          oldItem: deleteFieldsFromObject(
                                                              oldTPiCoefficient,
                                                              ['update_action'],
                                                          ),
                                                          newItem: deleteFieldsFromObject(
                                                              coefficient,
                                                              ['update_action'],
                                                          ),
                                                          item: deleteFieldsFromObject(
                                                              coefficient,
                                                              ['update_action'],
                                                          ),
                                                          id: coefficient.id,
                                                          oldItemIds: [
                                                              ...(oldCoefficients?.map(
                                                                  (el) => el.id,
                                                              ) ?? []),
                                                              ...(oldCoefficients?.map(
                                                                  (el) =>
                                                                      el.tpi_specification ?? '',
                                                              ) ?? []),
                                                          ],
                                                      });
                                                      if (final.note === null) {
                                                          final.note = '';
                                                      }

                                                      return deleteFieldsFromObject(final, [
                                                          'type',
                                                      ]);
                                                  },
                                              );
                                              const result = {
                                                  ...updatedTpi,
                                                  ordinal_number,
                                                  ...(updatedCoefficients.length ||
                                                  deletedCoefficients.length
                                                      ? {
                                                            work_coefficients: [
                                                                ...deletedCoefficients,
                                                                ...updatedCoefficients,
                                                            ],
                                                        }
                                                      : []),
                                              };
                                              if (result.id?.startsWith('front')) {
                                                  result.id = undefined;
                                              }

                                              return result;
                                          },
                                      ),
                                  ];
                                  return {
                                      ...updateWorkObject,
                                      ...(work_specifications.length ? {work_specifications} : {}),
                                  };
                              },
                          )),
                ];
                return {
                    ...{...updatedWork},
                    ...(work_objects.length ? {work_objects} : {}),
                };
            }),
        ],
    };
    return readyToUpdate;
}

function getUpdates({oldItem, newItem, item, id, oldItemIds}) {
    if (!oldItemIds.includes(id)) {
        item.update_action = 'create';
        return item;
    }
    const commentsArray = findPropInObject(item, 'work_specification_comments');
    let isNewComment;
    if (Array.isArray(commentsArray)) {
        if (commentsArray.length > 0) {
            isNewComment = !commentsArray[0].hasOwnProperty('creator') ?? false;
        }
    }
    if (oldItem.lav_gfk !== item.lav_gfk) {
        item.update_action = 'update';
        return item;
    }

    if (!isEqual(oldItem, newItem) || isNewComment) {
        item.update_action = 'update';
        return item;
    }
    item.update_action = 'nothing';
    return item;
}

const getUpdatedCoefficients = ({oldItem, newItem, item, id, oldItemIds}) => {
    if (!oldItemIds.includes(id)) {
        item.update_action = 'create';
        if (!item.is_manual) {
            item['tpi_specification_id'] = item.id;
        }
        if (item.tpi_specification) {
            item.tpi_specification_id = item.tpi_specification;
            delete item.tpi_specification;
        }
        delete item.id;
        return item;
    }

    if (
        oldItem.tpi_specification !== newItem.id ||
        oldItem.ordinal_number !== newItem.ordinal_number
    ) {
        item.update_action = 'update';
        return item;
    }

    item.update_action = 'nothing';
    return item;
};
