import {createGate} from "effector-react";
import {createDomain} from "effector";
import {patternService} from "../service";
import {FullVirPattern} from "../types";

export const virConstructDomain = createDomain()

export const PatternConstructorGate = createGate()

export const loadPatternByIdFx = virConstructDomain.createEffect(patternService.getPatternById)


export const $fullVirPattern = virConstructDomain.createStore<FullVirPattern|null>(null)