import React from 'react';
import Select, {SelectItem} from 'shared/ui/Select/Select';
import {CatalogWorkType} from '../../../types';
import styled from 'styled-components/macro';

interface WorkTypeSelectProps {
    options: CatalogWorkType[];
    selected: CatalogWorkType | null;
    onSelect: (option: CatalogWorkType | null) => void;
    error?: boolean;
}

export function WorkTypeSelect({options, selected, onSelect, error}: WorkTypeSelectProps) {
    return (
        <SelectWrapper>
            <Select
                error={error}
                hint={error ? 'Укажите тип работ' : ''}
                value={selected || []}
                valueLabel="work_types_name"
                handleEdit={onSelect}
                title="Тип работ"
            >
                {options?.map((item) => (
                    <SelectItem
                        selected={selected}
                        multiple={false}
                        key={item.work_types_id}
                        value={item || []}
                    >
                        {item.work_types_name}
                    </SelectItem>
                ))}
            </Select>
        </SelectWrapper>
    );
}

export const SelectWrapper = styled.div`
    --selectWidth: 300px;
`;
