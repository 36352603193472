import React from 'react';

const MoreVertIcon = () => {
    return (
        <svg className="icon">
            <use xlinkHref="/img/sprite.svg#more-vert" />
        </svg>
    );
};
export default MoreVertIcon;
