import {axiosInstance} from 'shared/api/api';
import {
    BiddingParticipationsRequestType,
    BiddingParticipationsResponseType,
    PurchasingEventTypesResponse,
} from 'shared/types/purchasesTypes';
import {JobTaskTypesType, PostJobResponseType} from 'shared/services/types/configuratorTypes';
import {TaskCreationType} from 'shared/types/tasksTypes';
import {JobType, TJobListItem} from 'shared/types/jobsTypes';

import {IJobListParams} from './types/paramsTypes';
import {IResponseWithPagination} from '../types/apiParamsTypes';

export const getPurchasingEventTypes = () => {
    return axiosInstance.get<PurchasingEventTypesResponse>('catalogs/purchasing_event_types/');
};

export const getPurchasingEventsInfo = (params: BiddingParticipationsRequestType) => {
    const modifiedParams = {...params};
    modifiedParams.statuses = '1,2,3';
    return axiosInstance.get<BiddingParticipationsResponseType>(
        'purchasing_events/bidding_participations/',
        {
            params: modifiedParams,
        },
    );
};

export const getTaskType = (is_job?: boolean) => {
    return axiosInstance.get<JobTaskTypesType[]>('catalogs/job_task_types/', {
        params: {
            is_job: is_job ?? false,
        },
    });
};

export const postNewTask = (data: TaskCreationType) => {
    return axiosInstance.post<PostJobResponseType>('/job_tasks/job_tasks/', data);
};

export const getJob = (id: string) => {
    return axiosInstance.get<JobType>(`job_tasks/job_tasks/${id}/`);
};

export const fetchJobList = (data: IJobListParams) => {
    const {page, projectId, pageSize} = data;
    return axiosInstance.get<IResponseWithPagination<TJobListItem>>(`job_tasks/job_tasks/`, {
        params: {project_id: projectId, page, page_size: pageSize},
    });
};

export const postJobComment = (data: {id: string; comment: string}) => {
    const {id, comment} = data;
    return axiosInstance.post(`/job_tasks/job_tasks/${id}/add_comment/`, {
        comment: comment,
    });
};
