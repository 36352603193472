import styled, {css} from 'styled-components/macro';
import {tabInactiveHoverIndicatorColor} from '@beeline/design-tokens/js/tokens/components/tab';

const TabsBtnStyledMixin = (props) => css`
    --bg-color: transparent;
    background-color: transparent;
    border: none;
    padding: 14px 20px;
    span {
        display: block;
        font-weight: 500;
        line-height: 1.3333333333;
        position: relative;
        transition-duration: 0.4s;
        color: var(--secondary);
        &::after {
            background-color: transparent;
            border-radius: 3px 3px 0 0;
            bottom: -16px;
            content: '';
            height: 4px;
            left: 0;
            position: absolute;
            transform: translateY(-50%);
            transition-duration: 0.4s;
            width: 100%;
        }
    }
    &:hover {
        span {
            color: var(--body-color);
            &::after {
                background-color: ${tabInactiveHoverIndicatorColor};
            }
        }
    }
    ${props.active &&
    css`
        span {
            color: var(--body-color);
            &::after {
                background-color: var(--yellow);
            }
        }
    `}
`;

export const TabsBtnStyled = styled.button`
    ${(props) => TabsBtnStyledMixin(props)}
`;

const TabsBlockStyledMixin = (props) => css`
    display: none;
    ${props.active &&
    css`
        display: block;
    `}
`;

export const TabsBlockStyled = styled.div`
    ${(props) => TabsBlockStyledMixin(props)}
`;

export const TabsBtnWrap = styled.div`
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
`;
