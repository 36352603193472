import styled from 'styled-components/macro';

export const AttachmentUnitWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    padding-right: 24px;
    margin-bottom: 8px;
    flex: 0 1 700px;
    min-width: 350px;
`;
