import styled, {css} from 'styled-components/macro';
import MaskInput from 'shared/ui/deprecated/MaskInput/MaskInput';
import {TableCell} from 'shared/ui/Table/Table';

export const EditableCell = styled(TableCell)<{error?: boolean; isEditable?: boolean}>`
    position: relative;
    padding: 0 16px;
    text-align: center;

    &:hover,
    &:focus-within {
        ${(p) =>
            p.isEditable &&
            !p.error &&
            `background: rgba(25, 28, 52, 0.08); box-shadow:inset 0 0 0 1px black;`};
    }

    ${(p) => p.error && `background:var(--red-avatar); box-shadow:inset 0 0 0 1px  var(--red);`}
`;

export const StyledMaskedInput = styled(MaskInput)<{error?: boolean; textAlign?: string}>`
    &:first-child {
        border: none;
    }

    & div {
        background: transparent;
        padding-left: 0;
        border: none;
        outline: none;
    }

    & .input__block {
        border: none;
        background: transparent;
        border-radius: 0;
        height: 100%;
    }

    & input {
        text-align: left;
        background: none;
    }

    & input::placeholder {
        color: #262626;
    }

    ${(props) =>
        props.textAlign &&
        css`
            input {
                text-align: ${props.textAlign};
            }
        `}
`;

export const Content = styled.div<{editable: boolean}>`
    margin-left: 32px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
`;
