import React from 'react';
import {
    VerticalTabBtnStyled,
    VerticalTabBlockStyled,
    BtnTitle,
    BtnSubtitle,
} from 'pages/ProjectViewPage/ui/ProjectViewVerticalTabs/style';

function VerticalTabBtn(props) {
    const {children, activeTab, value, ...rest} = props;

    return (
        <VerticalTabBtnStyled active={activeTab == props['data-value']} {...props}>
            {children}
        </VerticalTabBtnStyled>
    );
}

function VerticalTabBlock(props) {
    const {children, activeTab, value, ...rest} = props;

    return (
        <VerticalTabBlockStyled active={activeTab == props['data-value']} {...props}>
            {children}
        </VerticalTabBlockStyled>
    );
}

export {VerticalTabBtn, VerticalTabBlock, BtnTitle, BtnSubtitle};
