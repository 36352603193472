import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    .dsb__dropdown-root {
        z-index: 1000;
    }

    span {
        display: none;
    }

    .dsb_datepicker {
        outline: none;
        width: 100%;
        height: 100%;
    }

    && .dsb_text-field-wrapper {
        width: 100%;
        height: 100%;
    }

    && .dsb_input-wrapper {
        width: 100%;
        height: 100%;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 18px !important;
        letter-spacing: 0.2px !important;
        border: none;
    }

    && input {
        height: 100%;
        padding: 0 12px !important;
        background: none;
        border: none !important;
        cursor: pointer;
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: 18px !important;
        letter-spacing: 0.2px !important;

        && .dsb_input-wrapper {
            width: 100%;
            font-size: 15px !important;
            font-weight: 400 !important;
            line-height: 18px !important;
            letter-spacing: 0.2px !important;
        }
    }

    && input::placeholder {
        color: black !important;
    }
`;
