import React from 'react';
import {Avatar, Typography} from '@beeline/design-system-react';
import {ColorTypes} from '@beeline/design-system-react/types/types/status';

import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

import * as S from './style';

interface UserBadgeProps {
    initials: string;
    shortName: string;
    position: string;
    color?: ColorTypes;
}

export const UserBadge = ({color = 'blue', initials, shortName, position}: UserBadgeProps) => {
    return (
        <WrapperFlex alignItems="center">
            <Avatar letter={initials} color={color} />
            <WrapperOffset offsetLeft={12}>
                <Typography variant="body2">{shortName}</Typography>
                <S.UserJob>{position}</S.UserJob>
            </WrapperOffset>
        </WrapperFlex>
    );
};
