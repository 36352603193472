import React from 'react'
import {SkeletonStyled} from "shared/ui/deprecated/Skeleton/style";


export const Skeleton = (props) => {
    const {
        height,
        width,
        variant = 'rectangle',
        animation = 'pulse',
        ...rest
    } = props

    return (
        <SkeletonStyled
            variant={variant}
            height={height}
            width={width}
            animation={animation}
            {...rest}
        />
    )
}
