import React from 'react';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import {TableData} from '@beeline/design-system-react';

import {createLink} from 'shared/helpers/links';
import {hasLinkPermission} from 'shared/helpers/accessCheckers';
import {$userActions} from 'shared/model/user';

interface ConditionalLinkCellProps {
    link: string;
    objectType: number;
    title: string | number;
    blank?: boolean;
}

export const ConditionalLinkCell = ({link, objectType, title, blank}: ConditionalLinkCellProps) => {
    const userActions = useStore($userActions);
    const createdLink = createLink(objectType, link);
    const linkAvailable = hasLinkPermission(objectType, userActions);
    const target = blank ? '_blank' : undefined;
    return (
        <TableData>
            {linkAvailable ? (
                <Link to={createdLink} target={target}>
                    {title}
                </Link>
            ) : (
                <>{title}</>
            )}
        </TableData>
    );
};
