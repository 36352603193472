import {useState} from 'react';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import Button from 'shared/ui/deprecated/Button/Button';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import ModalWindow from 'shared/ui/deprecated/ModalWindow/ModalWindow';
import {useStore} from 'effector-react';
import {useNavigate} from 'react-router-dom';
import {$createVirStartPageData} from 'pages/VirStart/model';
import * as S from './style';
import {resetAdditionalAgreementCreation} from 'processes/contructor/model/stores';

export function HeaderApplyCoefficients() {
    const [open, setOpen] = useState(false);
    const {fromProject, projectId} = useStore($createVirStartPageData);
    const navigate = useNavigate();
    const handleClose = () => {
        if (!fromProject) {
            resetAdditionalAgreementCreation();
            navigate(`/additional-agreements`);
        } else {
            resetAdditionalAgreementCreation();
            navigate(`/projects/${projectId}`);
        }
    };
    return (
        <>
            <S.HeaderBlock>
                <S.Title>Новый ВИР/ДС</S.Title>
                <Button color="outline" onClick={() => setOpen(true)}>
                    Отменить
                </Button>
            </S.HeaderBlock>

            <ModalWindow open={open}>
                <S.ModalTitle>Завершить выбор коэффициентов?</S.ModalTitle>
                <S.ModalText>Все внесённые изменения не сохранятся.</S.ModalText>
                <WrapperOffset offsetTop={16}>
                    <WrapperFlex justifyContent="flex-end">
                        <Button color="outline" onClick={() => setOpen(false)}>
                            Продолжить работу
                        </Button>
                        <Button color="contained" onClick={handleClose} className="ms-3">
                            Завершить
                        </Button>
                    </WrapperFlex>
                </WrapperOffset>
            </ModalWindow>
        </>
    );
}
