import styled, {createGlobalStyle} from 'styled-components/macro';

export const VirStartBlock = styled.div`
    margin: auto;
    @media (min-width: 1200px) {
        max-width: 1020px;
    }

    h6 {
        margin-top: 12px;
        font-weight: 400;
    }
`;

export const GlobalStyles = createGlobalStyle`
  div[data-portal]:nth-child(3) {
    --selectWidth: 300px
  }

  div[data-portal]:nth-child(4) {
    --selectWidth: 500px
  }

  div[data-portal]:nth-child(5) {
    --selectWidth: 500px
  }

  div[data-portal]:nth-child(6),
  div[data-portal]:nth-child(7) {
    --selectWidth: 250px
  }
`;
