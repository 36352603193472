import {bindAllMethods} from 'shared/helpers/binder';
import {Branch, Region} from '../types/agreement';
import {axiosInstance} from 'shared/api/api';

export const additionalAgreementsService = bindAllMethods({
    async getRegions(): Promise<{data: Region[]}> {
        return axiosInstance.get(`/catalogs/regions`);
    },

    async getBranches(region: string): Promise<Branch[]> {
        return axiosInstance.get(`/catalogs/branches`, {
            params: {region},
        });
    },
});
