import React from 'react';
import {useStore} from 'effector-react';

import {TableWithSort} from 'widgets/TableWithSort';
import {IContractListItem} from 'shared/types/contractsTypes';
import {ITableData} from 'shared/types/tableTypes';
import {$isUserVK} from 'shared/model/user';

import {IContractColumns} from './columns';
import {
    $contractListPageStore,
    pageChanged,
    pageSizeChanged,
    resetAllSelectedData,
    tableSorted,
} from '../model';

export const ContractTable = () => {
    const {
        tableData: [data, count],
        tableDataLoading,
        tableSettings,
        params: {columns},
        linkColumnShown,
    } = useStore($contractListPageStore);
    const isUserVk = useStore($isUserVK);
    const {
        filters: {page, page_size, ordering},
    } = tableSettings;

    const dataObject: ITableData<IContractListItem> = {
        data: data,
        count: count,
        isLoading: tableDataLoading,
        page: page,
        pageSize: page_size,
        order: ordering,
    };

    const visibleColumns = columns
        .filter((column) => (isUserVk ? true : column.id !== 'contractor'))
        .filter((column) => (linkColumnShown ? true : column.id !== 'external_link'));

    return (
        <TableWithSort<IContractListItem>
            columns={IContractColumns}
            dataObject={dataObject}
            visibleColumns={visibleColumns}
            onChangePage={pageChanged}
            onChangePageSize={pageSizeChanged}
            rowHeight="52px"
            minTableWidth="1200px"
            onReset={resetAllSelectedData}
            onSort={tableSorted}
            dense
        />
    );
};
