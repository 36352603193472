import styled from 'styled-components/macro';
import Search from 'shared/ui/Search/Search';

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
`;
export const Text = styled.span`
    font-size: 17px;
    display: flex;
    gap: 8px;
`;

export const Buttons = styled.div`
    display: flex;
    gap: 16px;
`;

export const VirSelectBlock = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
`;

export const SearchStyled = styled(Search)`
    max-width: 372px;
`;