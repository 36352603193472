import React from 'react';
import {Link} from 'react-router-dom';
import {Icon, Label, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {TASK_STATUS, TaskStatusBadge, TaskStatusColor, TaskStatusTitle} from 'pages/DSPage/types';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {StyledSpan} from 'shared/styles/commonStyle';
import {createLink} from 'shared/helpers/links';
import {formatDateHours} from 'shared/helpers/formatHelper';

import * as S from '../style';

export interface NotificationItemProps {
    index: number;
    taskTitle: string;
    taskStatus: TASK_STATUS;
    taskObjectId: string;
    taskObjectType: number;
    taskDate: string;
    isUnread?: boolean;
    taskLink?: string;
}

export const NotificationItem = React.forwardRef<HTMLDivElement, NotificationItemProps>(
    (props, ref) => {
        const {
            index,
            isUnread,
            taskStatus,
            taskTitle,
            taskDate,
            taskObjectType,
            taskObjectId,
            taskLink,
        } = props;

        const link = createLink(taskObjectType, taskObjectId);

        return (
            <S.NotificationListItem
                id={`notification_item_${index}`}
                ref={ref}
                isUnread={props.isUnread}
            >
                <S.IconContainer isUnread={isUnread}>
                    <Icon
                        iconName={Icons.Tasks}
                        color={TaskStatusColor[taskStatus]}
                        size="large"
                        contained
                    />
                </S.IconContainer>
                <S.ItemContent>
                    <div>
                        <StyledSpan lineCount={1} noCursor>
                            <Typography variant="body2">{taskTitle}</Typography>
                        </StyledSpan>
                        <StyledSpan lineCount={2} wordBreak="break-word" typography noCursor>
                            <Link to={link}>
                                <S.LinkedTypography variant="body2">{taskLink}</S.LinkedTypography>
                            </Link>
                        </StyledSpan>
                    </div>
                    <WrapperFlex justifyContent="space-between">
                        <Typography variant="body3">{formatDateHours(taskDate)}</Typography>
                        <Label
                            variant="outline"
                            type={TaskStatusBadge[taskStatus]}
                            title={TaskStatusTitle[taskStatus]}
                        />
                    </WrapperFlex>
                </S.ItemContent>
            </S.NotificationListItem>
        );
    },
);
