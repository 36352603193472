import React from 'react'
import {
    ProgressCircularInfiniteStyled,
    ProgressCircularWrap,
    ProgressLinearContainerStyled,
    ProgressLinearStyled
} from "shared/ui/deprecated/Progress/style";

const ProgressCircularInfinite = () => {
    return (
        <ProgressCircularInfiniteStyled/>
    )
}

const ProgressLinear = (props) => {
    return (
        <ProgressLinearStyled {...props}>
            <div/>
        </ProgressLinearStyled>
    )
}

const ProgressLinearContainer = (props) => {
    const {
        progress,
        children,
        ...rest
    } = props;
    return (
        <>
            {React.Children.map(children,
                child => {
                    return React.cloneElement(child, rest);
                })}

            <ProgressLinearContainerStyled progress={progress}>
                <div/>
            </ProgressLinearContainerStyled>
        </>
    )
}

export {
    ProgressCircularInfinite,
    ProgressCircularWrap,
    ProgressLinear,
    ProgressLinearContainer,

}
