import React from 'react';

import * as S from './style';

interface RoundedWrapperProps {
    children: React.ReactNode;
    width: string;
    gap?: string;
}

export const RoundedWrapper = ({children, width, gap}: RoundedWrapperProps) => {
    return <S.RoundedCard width={width} gap={gap}>{children}</S.RoundedCard>;
};
