import styled, {css} from 'styled-components/macro';
import {Typography} from '@beeline/design-system-react';

export const SubText = styled.span<{active: boolean}>`
    margin-top: 4px;
    display: block;
    ${(props) =>
        props.active &&
        css`
            color: var(--bs-gray-600);
        `}
`;

export const MainText = styled.div`
    font-size: 17px;
    line-height: 18px;
`;

export const SelectWrapper = styled.div`
    --selectWidth: 496px;
`;

export const ItemWrapper = styled.div`
    width: 100%;
    grid-area: object;
`;

export const TipText = styled(Typography)`
    padding: 12px 16px;
    color: var(--color-text-grey-inactive);
`;
