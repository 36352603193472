import React, {useEffect} from 'react';
import {useGate, useStore} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import Select, {SelectItem} from 'shared/ui/Select/Select';
import {RegionType} from 'shared/types/regionSelectTypes';
import {BranchType} from 'shared/services/types/types';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

import {SelectWrap} from './style';
import {
    $regionSelectStore,
    resetSearch,
    setBranchInputValue,
    setDefaultBranchData,
    setDefaultRegionData,
    setRegionInputValue,
    setSelectedBranchData,
    setSelectedRegionData,
    territorialSelectGate,
} from '../model';

interface RegionSelectProps {
    defaultSelectedRegion?: string;
    defaultSelectedBranch?: string;
    handleSelectedRegion: (data: string) => void;
    handleSelectedBranch: (data: string) => void;
}

export const RegionSelect = ({
    defaultSelectedRegion,
    defaultSelectedBranch,
    handleSelectedRegion,
    handleSelectedBranch,
}: RegionSelectProps) => {
    useGate(territorialSelectGate);
    const {
        selectedRegionData,
        selectedBranchData,
        selectRegionSearch,
        selectBranchSearch,
        availableRegionData,
        availableBranchData,
        selectedRegionParam,
        selectedBranchParam,
    } = useStore($regionSelectStore);

    useEffect(() => {
        handleSelectedRegion(selectedRegionParam);
    }, [selectedRegionParam]);

    useEffect(() => {
        handleSelectedBranch(selectedBranchParam);
    }, [selectedBranchParam]);

    const handleChangeRegion = (
        value: Array<RegionType>,
        targetItem: RegionType,
        checked: boolean,
    ) => {
        setSelectedRegionData({
            value,
            targetItem,
            checked,
        });
    };

    const handleChangeBranch = (
        value: Array<BranchType>,
        targetItem: BranchType,
        checked: boolean,
    ) => {
        setSelectedBranchData({
            value,
            targetItem,
            checked,
        });
    };

    return (
        <SelectWrap>
            <Select
                id="RegionSelect"
                value={selectedRegionData}
                valueKey="code"
                valueLabel="name"
                title="Регион"
                handleEdit={handleChangeRegion}
                searchValue={selectRegionSearch}
                setSearch={setRegionInputValue}
                notAll={availableRegionData.length >= 0}
                handleResetSearch={resetSearch}
                search
                multiple
            >
                {availableRegionData.length > 0 ? (
                    availableRegionData.map((item) => (
                        <SelectItem key={item.code} value={item} multiple={false}>
                            {item.name}
                        </SelectItem>
                    ))
                ) : (
                    <WrapperFlex justifyContent="center">
                        <Typography variant="subtitle3">Нет результатов</Typography>
                    </WrapperFlex>
                )}
            </Select>
            <Select
                id="BranchSelect"
                value={selectedBranchData}
                valueKey="code"
                valueLabel="name"
                title="Филиал"
                handleEdit={handleChangeBranch}
                searchValue={selectBranchSearch}
                setSearch={setBranchInputValue}
                notAll={selectedRegionData.length >= 0}
                handleResetSearch={resetSearch}
                search
                multiple
            >
                {availableBranchData.length > 0 ? (
                    availableBranchData.map((item) => (
                        <SelectItem key={item.code} value={item} multiple={false}>
                            {item.name}
                        </SelectItem>
                    ))
                ) : (
                    <WrapperFlex justifyContent="center">
                        <Typography variant="subtitle3">Нет результатов</Typography>
                    </WrapperFlex>
                )}
            </Select>
        </SelectWrap>
    );
};
