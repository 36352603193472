import React, { Component } from 'react'

export default class PlusIcon extends Component {
	render() {
		return (
			<svg className="icon">
				<use xlinkHref="/img/sprite.svg#plus" />
			</svg>
		)
	}
}