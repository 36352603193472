import styled, {css} from 'styled-components/macro';

export const Accordion = styled.div`
    border-radius: 4px;
    border: 1px solid #c9c9c9;
    overflow: hidden;

    & > * {
        border-bottom: 1px solid #c9c9c9;

        &:last-child {
            border: none;
        }
    }
`;

const AccordionTitleBlockStyle = (props) => css`
    ${css`
        padding: 14px 16px;
        display: flex;
        min-height: 54px;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        background-color: #ffffff;
        transition-duration: 0.4s;

        &:hover {
            background-color: #f5f5f5;
        }

        & > .icon {
            font-size: 24px;
            margin-right: 8px;
            transition-duration: 0.4s;
        }
    `}
    ${props.$active &&
    css`
        background-color: #f5f5f5;

        & > .icon {
            transform: scaleY(-1);
        }
    `}
`;
export const AccordionTitleBlock = styled.div`
    ${(props) => AccordionTitleBlockStyle(props)}
`;

export const AccordionTitleBlockWrap = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    width: 100%;
    line-height: calc(20 / 15);
    gap: 8px;
`;
export const RightSlot = styled.div`
    margin-left: auto;
    height: 24px;
`;

const AccordionBodyStyle = (props) => css`
    ${css`
        border-top: 1px solid #e0e0e0;
        border-left: 4px solid #fdc435;
        padding-left: 27px;
        padding-right: 32px;
        overflow-y: auto;
        transition-duration: 0.4s;
        height: 0;
        opacity: 0;
        display: none;

        tr {
            &:last-child {
                border: none;
            }
        }
    `}
    ${props.$active &&
    css`
        height: auto;
        opacity: 1;
        display: block;
    `}
  ${props.noOverflow &&
    css`
        overflow-y: visible;
    `}
`;
export const AccordionBody = styled.div`
    ${(props) => AccordionBodyStyle(props)}
`;

export const AccordionFooter = styled.div`
    padding: 14px 16px;
`;

export const AccordionWrapper = styled.div`
    min-height: 54px;
`;

export default {
    Accordion,
    AccordionTitleBlock,
    AccordionTitleBlockWrap,
    AccordionBody,
    AccordionFooter,
};
