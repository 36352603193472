import styled, { css } from "styled-components";


export const ModalStyled = (props) => css`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: ${props.open ? 'flex' : 'none'};
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    z-index: 99;
    padding:30px 0;
    background-color: rgba(0, 0, 0, 0.48);
`

export const Modal = styled.div`
	${(props) => ModalStyled(props)}
`;

export const ModalWrap = styled.div`
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    min-height: 100%;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
`
export const Window = styled.div`
    margin: 50px 0;
    flex-shrink: 0;
    flex-grow: 0;
    background: #fff;
    width: 560px;
    padding: 24px;
    border-radius: 12px;
    max-width: 100%;
    overflow: visible;
    transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
    transform: scale(0.9);
    background-color: white;
`
