import React from 'react';
import {Button, Checkbox, IconButton, Tab, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {IVisibleColumn} from 'shared/types/tableTypes';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

import * as S from './style';

interface TableSettingsProps {
    active: boolean;
    height?: string;
    children: React.ReactNode;
    onClose: () => void;
    onSave: () => void;
    onReset: () => void;
    onSaveColumns: (item: IVisibleColumn) => void;
    columns: IVisibleColumn[];
}

export const TableSettings = ({
    active,
    height,
    onClose,
    onReset,
    onSave,
    onSaveColumns,
    columns,
    children,
}: TableSettingsProps) => {
    return (
        <S.SidesheetWrapper active={active} height={height}>
            <S.TitleWrapper>
                <Typography variant="h5">Фильтры и настройки</Typography>
                <IconButton iconName={Icons.Close} size="medium" onClick={onClose} />
            </S.TitleWrapper>

            <S.StyledTabs>
                <Tab key={1} label="Фильтры">
                    <S.TabWrapper>{children}</S.TabWrapper>
                </Tab>
                <Tab key={2} label="Настройка колонок">
                    <S.TabWrapper>
                        <S.ColumnPickerWrapper>
                            {columns.map((item, index) => (
                                <S.ColumnPicker key={item.id}>
                                    <S.CountWrapper>
                                        <Typography variant="subtitle2">{index + 1}</Typography>
                                    </S.CountWrapper>
                                    <Checkbox
                                        label={item.name}
                                        checked={item.visible}
                                        onChange={() => onSaveColumns(item)}
                                    />
                                </S.ColumnPicker>
                            ))}
                        </S.ColumnPickerWrapper>
                    </S.TabWrapper>
                </Tab>
            </S.StyledTabs>
            <WrapperFlex mt="auto" justifyContent="flex-end" gap="16px">
                <Button variant="plain" size="medium" onClick={onReset}>
                    Сбросить
                </Button>
                <Button variant="contained" size="medium" onClick={onSave}>
                    Сохранить
                </Button>
            </WrapperFlex>
        </S.SidesheetWrapper>
    );
};
