import React, {useReducer} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from '@beeline/design-system-react';
import {ContextApp, initialState, reducer, initializer} from 'reducer';

import {AppRouter} from 'app/providers/router';
import {AppNavigator} from 'app/providers/AppNavigator';
import {SnackbarNotification} from 'Entities/SnackbarNotification';

import './styles/bootstrap.scss';
import './styles/main.scss';

function App() {
    const [state, dispatch] = useReducer(reducer, initialState, initializer);

    return (
        <BrowserRouter>
            <ThemeProvider theme="light" isRoot>
                <ContextApp.Provider value={{dispatch, state}}>
                    <div className="App" id="app">
                        <AppRouter />
                        <AppNavigator />
                        <SnackbarNotification />
                    </div>
                </ContextApp.Provider>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
