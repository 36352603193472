import React from 'react';
import {Link} from 'react-router-dom';
import {Icon, Label} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {
    ESpecificationAction,
    TCompletedWorkSpecificationMode,
    TCompletedWorksSpecAction,
} from 'shared/types/completedWorksTypes';

interface CompletedWorksActionLabelProps {
    action: TCompletedWorksSpecAction;
    mode: TCompletedWorkSpecificationMode;
    completelyClosed?: boolean;
    completedWorksId?: string | null;
    completedWorksNumber: string;
}

interface LinkedLabelProps {
    title: string;
    completedWorksId?: string | null;
}

export const CompletedWorksActionLabel = ({
    action,
    mode,
    completelyClosed = false,
    completedWorksId,
    completedWorksNumber,
}: CompletedWorksActionLabelProps) => {
    return (
        <WrapperFlex flexDirection="column">
            {completelyClosed && mode === 'closed' ? (
                <LinkedLabel
                    title={`в ВВР ${completedWorksNumber}`}
                    completedWorksId={completedWorksId}
                />
            ) : mode === 'closed' ? (
                <LinkedLabel
                    title={`в ВВР ${completedWorksNumber} частично`}
                    completedWorksId={completedWorksId}
                />
            ) : mode === 'available' ? (
                <Label title="Не выполнено" variant="outline" />
            ) : action === ESpecificationAction.DONE ? (
                <Label title="Выполнено" variant="outline" type="success" />
            ) : (
                <Label title="Не требуется" variant="outline" type="warning" />
            )}
        </WrapperFlex>
    );
};

const LinkedLabel = ({title, completedWorksId}: LinkedLabelProps) => (
    <Link to={`/completed-works/${completedWorksId ?? ''}`} target="_blank">
        <Label
            title={
                <WrapperFlex alignItems="center" gap="8px">
                    {title}
                    <Icon iconName={Icons.OpenInWindow} size="small" />
                </WrapperFlex>
            }
            variant="outline"
        />
    </Link>
);
