import {Table, TableData, TableHeaderData, Typography} from '@beeline/design-system-react';
import styled, {css} from 'styled-components';

export const Cell = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const TableWrapper = styled.div`
    margin-top: 24px;
    min-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    border: var(--size-border-width-regular) solid var(--color-border);
    border-radius: var(--size-border-radius-x2);
    padding-bottom: 16px;
`;
export const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
`;

export const StyledTable = styled(Table)`
    table-layout: fixed;
    width: 100%;
    min-width: 1900px;
    border-width: 0;
    border-bottom: var(--size-border-width-regular) solid var(--color-border);
`;

export const InnerTable = styled(Table)`
    table-layout: fixed;
    padding: 32px;
    border-width: 0;
`;

export const StyledTableHeaderData = styled(TableHeaderData)<{
    $hover?: boolean;
    $arrowUp?: boolean;
    $arrowDown?: boolean;
    $alreadySorting?: boolean;
    width?: string;
    minWidth?: string;
}>`
    ${({width}) =>
        width &&
        css`
            width: ${width};
            max-width: ${width};
        `}
    ${({minWidth}) =>
        minWidth &&
        css`
            @media (max-width: 1200px) {
                width: ${minWidth};
                max-width: ${minWidth};
            }
        `}
  ${({$hover, $alreadySorting}) =>
        $hover &&
        css`
            cursor: pointer;

            > div {
                ::after {
                    content: '';
                    width: 20px;
                    height: 20px;
                }

                &:hover {
                    ::after {
                        content: 'arrow_up';
                        font-family: 'BeelineIcons', system-ui;
                        font-size: 20px;
                        color: var(--color-text-inactive);
                        ${$alreadySorting &&
                        css`
                            opacity: 0.37;
                        `}
                    }
                }
            }
        `}
  ${({$arrowUp}) =>
        $arrowUp &&
        css`
            > div {
                ::after {
                    content: 'arrow_up';
                    font-family: 'BeelineIcons', system-ui;
                    font-size: 20px;
                    color: var(--color-text-inactive);
                }
            }
        `}
  ${({$arrowDown}) =>
        $arrowDown &&
        css`
            > div {
                ::after {
                    content: 'arrow_down';
                    font-family: 'BeelineIcons', system-ui;
                    font-size: 20px;
                    color: var(--color-text-inactive);
                }
            }
        `}
`;

export const StyledTableData = styled(TableData)<{width?: string; height?: string}>`
    .dsb_table-cell_flex {
        width: 100%;

        .dsb_table-cell__content {
            width: 100%;
        }
    }

    ${({width}) =>
        width &&
        css`
            max-width: ${width};
            min-width: ${width};
        `}
    ${({height}) =>
        height &&
        css`
            max-height: ${height};
            min-height: ${height};
        `}
`;

export const StyledInnerTableHeaderData = styled(TableHeaderData)<{width?: string}>`
    ${({width}) =>
        width &&
        css`
            max-width: ${width};
            min-width: ${width};
        `}
`;

export const StyledInnerTableData = styled(TableData)`
    padding: 0 20px;
    border-left: 4px var(--color-background-brand) solid;
`;

export const StyledInnerTitle = styled(Typography)`
    margin-top: 8px;
`;
