import styled from 'styled-components';
import {Typography} from '@beeline/design-system-react';

export const LoaderWrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background: rgba(0, 0, 0, 0.1);
`;

export const MainInfoWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px 0 36px;
    width: 100%;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-self: flex-end;
`;

export const PrimeContractorInfo = styled.div`
    flex: 0 0 294px;
`;

export const PurchasingEvent = styled.div`
    flex: 0 0 188px;
`;

export const EndNoteTypography = styled(Typography)`
    margin-bottom: 36px;
`;
