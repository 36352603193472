import styled, {css} from 'styled-components/macro';

export const CustomTable = styled.div<{minWidth?: number | string}>``;

export const CustomTableRow = styled.div<{
    width?: string;
    minWidth?: number | string;
    height?: string;
    minHeight?: string;
    justifyContent?: string;
    alignItems?: string;
    noBorder?: boolean;
    flex?: string;
    disable?: boolean;
}>`
    display: flex;
    border-bottom: 1px solid #e0e0e0;
    transition-duration: 0.2s;
    ${(props) =>
        props.width &&
        css`
            width: ${props.width};
        `}
    ${(props) =>
        props.minWidth &&
        css`
            min-width: ${props.minWidth};
        `}
  ${(props) =>
        props.height &&
        css`
            height: ${props.height};
        `}
    ${(props) =>
        props.minHeight &&
        css`
            height: ${props.minHeight};
        `}
  ${(props) =>
        props.justifyContent &&
        css`
            justify-content: ${props.justifyContent};
        `}
  ${(props) =>
        props.alignItems &&
        css`
            align-items: ${props.alignItems};
        `}

  ${(props) =>
        props.noBorder &&
        css`
            border-bottom: none;
        `}
  ${(props) =>
        props.flex &&
        css`
            flex: ${props.flex};
        `}
  ${(props) =>
        props.disable &&
        css`
            display: none;
        `}
`;

export const CustomTableCell = styled.div<{
    maxWidth?: string;
    justifyContent?: string;
    alignItems?: string;
    flex?: string;
    flexGrow?: number;
    flexBasis?: string;
    flexShrink?: string;
    noPadding?: boolean;
    scroll?: boolean;
    noFlex?: boolean;
    hasElevation?: boolean;
}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 52px;
    padding: 8px 16px;

    ${(props) =>
        props.justifyContent &&
        css`
            justify-content: ${props.justifyContent};
        `}
    ${(props) =>
        props.alignItems &&
        css`
            align-items: ${props.alignItems};
        `}
  ${(props) =>
        props.flex &&
        css`
            flex: ${props.flex};
        `}
  ${(props) =>
        props.noPadding &&
        css`
            padding: 0;
        `}
  ${(props) =>
        props.scroll &&
        css`
            overflow-x: auto;
        `}
  ${(props) =>
        props.noFlex &&
        css`
            display: block;
        `}
  ${(props) =>
        props.hasElevation &&
        css`
            box-shadow: -8px 0 10px -6px rgba(0, 0, 0, 0.08);
        `}
  ${(props) =>
        props.maxWidth &&
        css`
            max-width: ${props.maxWidth};
        `}
`;
