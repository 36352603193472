import {useGate, useStore} from 'effector-react';

import {
    $createVirStartPageData,
    $createVirStartPageOptions,
    ProjectSelectGate,
    selectProject,
} from 'pages/VirStart/model';
import Select, {SelectItem} from 'shared/ui/Select/Select';

import * as S from './style';

export function ProjectSelect() {
    useGate(ProjectSelectGate);
    const {projectOptions} = useStore($createVirStartPageOptions);
    const {selectedProject} = useStore($createVirStartPageData);
    return (
        <S.SelectWrapper>
            <Select
                value={selectedProject}
                valueLabel="project_number"
                handleEdit={selectProject}
                title="Номер проекта"
                id="AgreementConstructorProjectSelect"
            >
                {projectOptions.map((item) => (
                    <SelectItem
                        selected={selectedProject}
                        multiple={false}
                        key={item?.project_id}
                        value={item}
                        id={item.project_id}
                    >
                        {item?.project_number}
                    </SelectItem>
                ))}
            </Select>
        </S.SelectWrapper>
    );
}
