import React from 'react';
import {useGate} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import {CommonLayout} from 'shared/ui/CommonLayout';
import {Content} from 'pages/ProjectPage/style';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';

import {FilterUnit} from './FilterUnit/FilterUnit';
import {CommonTable} from './ProcessesTable/CommonTable';
import {ProcessesTableSettings} from './ProcessesTableSettings/ProcessesTableSettings';
import {changeTableSort, ProcessesPageGate, toggleFilterSidesheet} from '../model';

export const ProcessesPage = () => {
    useGate(ProcessesPageGate);

    return (
        <CommonLayout>
            <Content>
                <WrapperOffset offset={32} overflowY width="true">
                    <Typography variant="h4">Задачи и процессы</Typography>
                    <FilterUnit toggleFilter={toggleFilterSidesheet} />

                    <CommonTable changeTableSort={changeTableSort} />
                </WrapperOffset>

                <ProcessesTableSettings />
            </Content>
        </CommonLayout>
    );
};
