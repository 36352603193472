import {combine, forward, sample} from 'effector';

import {updateAdditionalAgreementFx} from 'processes/contructor';
import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';
import {fetchUserInfoFx} from 'shared/model/user';
import {USER_ROLE_TYPE} from 'shared/services/types/userTypes';
import {EAdditionalAgreementType} from 'shared/types/additionalAgreementsTypes';

import {
    $activeVir,
    $additionalAgreement,
    $agreementObject,
    $agreementType,
    $buttonsDisabled,
    $clickedWorkflowButtonIndex,
    $currentWidth,
    $dsPageParams,
    $errorSubPageShowed,
    $isGPO,
    $isVK,
    $loadingAttachmentId,
    $project,
    $taskButtons,
    $tasksData,
    $virState,
    $warningBannerShowed,
    acceptTaskFx,
    additionalAgreementDomain,
    AdditionalAgreementPageGate,
    cancelTaskFx,
    currentWidthChanged,
    diadocArchiveDownloaded,
    downloadAttachmentFileFx,
    downloadDiadocArchiveFx,
    errorSubPageToggled,
    getAdditionalAgreementFx,
    getDsAttachment,
    getDsAttachmentFx,
    getProjectDataFx,
    getTaskFx,
    getTasksListFx,
    getVirAttachment,
    getVirAttachmentFx,
    handleAcceptWorkflowBtn,
    handleBackendBtn,
    returnWorkflowBtnClicked,
    handleClickPositiveBtn,
    downloadButtonClicked,
    resetTaskButtons,
    sendAgreementComment,
    sendAgreementCommentFx,
    sendApprovedItem,
    sendApprovedItemFx,
    setActiveVir,
    showWarningBanner,
    updateTaskFx,
    workflowButtonIndexSet,
} from './index';

// логика работы аккордеона (раскрытие - сокрытие)
sample({
    clock: setActiveVir,
    source: $activeVir,
    fn: (activeVir, clickedVir) => (clickedVir === activeVir ? '' : clickedVir),
    target: $activeVir,
});

sample({
    clock: AdditionalAgreementPageGate.state,
    source: $dsPageParams,
    filter: (pageParams, gateParams) =>
        gateParams.agreementId !== undefined && pageParams.id !== gateParams.agreementId,
    fn: (pageParams, gateParams) => ({id: gateParams?.agreementId || ''}),
    target: getAdditionalAgreementFx,
});

sample({
    clock: AdditionalAgreementPageGate.state,
    filter: (params) => !!params?.agreementId,
    fn: (params) => ({projectId: params?.projectId ?? '', id: params?.agreementId ?? ''}),
    target: $dsPageParams,
});

sample({
    clock: updateAdditionalAgreementFx.doneData,
    source: $dsPageParams,
    filter: (source) => !!source.id,
    target: getAdditionalAgreementFx,
});

sample({
    source: $tasksData,
    filter: (data) => data.length > 0,
    fn: (data) => data[0].id,
    target: getTaskFx,
});

sample({
    source: $tasksData,
    filter: (data) => data.length === 0,
    target: resetTaskButtons,
});

sample({
    clock: AdditionalAgreementPageGate.state,
    filter: (params) => !!params?.agreementId,
    fn: (params) => ({id: params?.agreementId || ''}),
    target: getTasksListFx,
});

sample({
    clock: getAdditionalAgreementFx.doneData,
    source: $additionalAgreement,
    fn: (data) => data?.project,
    target: getProjectDataFx,
});

// Дефолтное раскрытие ведомости, если в ДС approved_at !== null, тогда аккордеон ведомостей закрыт
sample({
    clock: getAdditionalAgreementFx.doneData,
    source: $additionalAgreement,
    filter: (agreement) => !!agreement?.works?.length,
    fn: (agreement) => (!!agreement?.approved_at ? '' : agreement?.works?.[0].id ?? ''),
    target: $activeVir,
});

// при sendApprovedItemFx - после получения подтверждения от бэка,
// меняется work_specification_approval на выбранный без перезагрузки всего объекта с бэка
$additionalAgreement
    .on(getAdditionalAgreementFx.doneData, (state, payload) => payload?.data)
    .on(sendAgreementCommentFx.doneData, (state, payload) => payload)
    .on(sendApprovedItemFx.doneData, (state, payload) => {
        if (!payload.result || !state) {
            return state;
        } else if (state) {
            const works = [...state.works];
            works.map((work) => {
                work.work_objects.map((workObj) => {
                    workObj.work_specifications?.map((workSpec) => {
                        if (workSpec.id === payload.data.id) {
                            workSpec.work_specification_approval = payload.data.action;
                        }
                        return workSpec;
                    });
                });
            });
            const result = {...state};
            result.works = works;
            return result;
        }
    });

// установка типа ДС (ДС/дополнение/расторжение)
sample({
    source: $additionalAgreement,
    filter: (source) => !!source,
    fn: (agreement) => {
        if (!!agreement?.agreement_id) {
            if (agreement.is_termination) {
                return EAdditionalAgreementType.Termination;
            }
            return EAdditionalAgreementType.Addition;
        }
        return EAdditionalAgreementType.Agreement;
    },
    target: $agreementType,
});
//Подсчет "Отработано пунктов"
sample({
    source: $additionalAgreement,
    filter: (source) => !!source,
    fn: (source) => {
        const result = {};
        source?.works?.forEach((work) => {
            result[work.id] = {};
            work.work_objects?.forEach((workObject) => {
                workObject.work_specifications?.forEach((workSpecification) => {
                    result[work.id][workSpecification.id] = {};
                    result[work.id][workSpecification.id]['myApprovement'] =
                        workSpecification.work_specification_approval;
                    result[work.id][workSpecification.id]['totalApprovement'] =
                        workSpecification.work_specification_approval_total;
                });
            });
        });
        return result;
    },
    target: $agreementObject,
});

$isGPO.on(
    fetchUserInfoFx.doneData,
    (state, payload) => payload?.user_type === USER_ROLE_TYPE.GPO_USER,
);

$isVK.on(
    fetchUserInfoFx.doneData,
    (state, payload) => payload?.user_type === USER_ROLE_TYPE.VK_USER,
);

$project.on(getProjectDataFx.doneData, forwardPayload());

$tasksData.on(getTasksListFx.doneData, forwardPayload());

$taskButtons.on(getTaskFx.doneData, forwardPayload());

// клик на скачивание ДС
sample({
    clock: downloadButtonClicked,
    source: $additionalAgreement,
    fn: (agreement) => agreement?.id ?? '',
    target: downloadAttachmentFileFx,
});

// клик на позитивную кнопку воркфлоу
sample({
    clock: handleClickPositiveBtn,
    source: combine({
        virState: $virState,
        isVK: $isVK,
    }),
    filter: ({virState, isVK}) =>
        !isVK || virState.filter((item) => item.hasOpened === false).length === 0,
    fn: (source, clock) => clock,
    target: handleBackendBtn,
});

// включение проверки открытия всех ведомостей
sample({
    clock: handleClickPositiveBtn,
    source: $isVK,
    filter: (source) => source,
    target: showWarningBanner,
});

// клик на кнопку воркфлоу
sample({
    clock: handleBackendBtn,
    source: $taskButtons,
    fn: (buttonsData, index) => ({
        id: buttonsData?.data.id ?? '',
        actionId: buttonsData?.data.actions[index].id ?? '',
    }),
    target: updateTaskFx,
});

// клик на кнопку "взять в работу"
sample({
    clock: handleAcceptWorkflowBtn,
    source: $tasksData,
    filter: (data) => data.length > 0,
    fn: (data) => data[0].id,
    target: acceptTaskFx,
});

// клик на воркфлоу кнопку
sample({
    clock: returnWorkflowBtnClicked,
    source: $tasksData,
    filter: (data) => data.length > 0,
    fn: (data) => data[0].id,
    target: cancelTaskFx,
});

//перезагрузка списка задач после нажатия на одну из задач
sample({
    clock: [updateTaskFx.doneData, acceptTaskFx.doneData, cancelTaskFx.doneData],
    source: $dsPageParams,
    fn: (taskData) => taskData,
    target: getTasksListFx,
});

//перезагрузка ДС после нажатия на одну из задач (чтобы обновить статус ДС)
sample({
    clock: [updateTaskFx.doneData, acceptTaskFx.doneData, cancelTaskFx.doneData],
    source: $dsPageParams,
    target: getAdditionalAgreementFx,
});

$taskButtons.reset(resetTaskButtons);

$buttonsDisabled
    .on(downloadAttachmentFileFx.pending, forwardPayload())
    .on(acceptTaskFx.pending, forwardPayload())
    .on(cancelTaskFx.pending, forwardPayload())
    .on(updateTaskFx.pending, forwardPayload());

//нажатие на кнопку аппрува строки ВИРа
forward({
    from: sendApprovedItem,
    to: sendApprovedItemFx,
});

forward({
    from: sendAgreementComment,
    to: sendAgreementCommentFx,
});

forward({from: getDsAttachment, to: getDsAttachmentFx});
forward({from: getVirAttachment, to: getVirAttachmentFx});

//на время загрузки вложения, записывается ID этого вложения
sample({
    clock: [getDsAttachment, getVirAttachment],
    fn: (attachmentParams) => attachmentParams.fileId,
    target: $loadingAttachmentId,
});

// установка стейта virState
sample({
    clock: getAdditionalAgreementFx.doneData,
    fn: (clock) =>
        clock.data.works.map((item, index) => ({
            id: item.id,
            index: index + 1,
            hasOpened: index === 0,
        })) ?? [],
    target: $virState,
});

sample({
    clock: setActiveVir,
    source: $virState,
    fn: (source, clock) => {
        const item = source.find((item) => item.id === clock);
        if (item) {
            item.hasOpened = true;
        }
        return source;
    },
    target: $virState,
});

$warningBannerShowed
    .on(showWarningBanner, () => true)
    .on(handleBackendBtn, () => false)
    .on(updateTaskFx.doneData, () => false);

sample({
    clock: diadocArchiveDownloaded,
    target: downloadDiadocArchiveFx,
});

// установка порядкового номера кнопки воркфлоу для подстраницы ошибок
$clickedWorkflowButtonIndex
    .on(workflowButtonIndexSet, forwardPayload())
    .reset(updateTaskFx.doneData);

// открытие/закрытие подстраницы ошибок
$errorSubPageShowed.on(errorSubPageToggled, forwardPayload()).reset(updateTaskFx.doneData);

//сброс ID вложения после его загрузки
$loadingAttachmentId.reset([getDsAttachmentFx.doneData, getVirAttachmentFx.doneData]);

$currentWidth.on(currentWidthChanged, forwardPayload());

//сброс домена
resetDomainStoresByEvents(additionalAgreementDomain, AdditionalAgreementPageGate.close);
