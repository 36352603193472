import {combine, createDomain} from 'effector';
import {createGate} from 'effector-react';

import {modalStateFactory} from 'Features/modalFactory';
import {addWorkObjectDomain} from 'pages/AddWorkObjects/model';
import {addWorkObjectService} from 'pages/AddWorkObjects/service';
import {AddedWorkObject, VirWork} from 'pages/AddWorkObjects/types';
import {additionalAgreementDomain} from 'pages/DSPage/model';
import {TpiSpecification} from 'pages/VirSelect/types';
import {getJobCategoriesList} from 'shared/services/ds.service';
import {JobCategory} from 'shared/types/jobsTypes';
import {
    AgreementError,
    EAdditionalAgreementType,
    IUpdatedAgreement,
} from 'shared/types/additionalAgreementsTypes';
import {TAttachmentFile} from 'shared/types/FileTypes';

import {additionalAgreementService} from '../service';

export interface UpdateCurrentSpec {
    virId: string;
    workObjectId: string;
    spec: TpiSpecification;
    valuesToUpdate: Partial<TpiSpecification>;
}

export const constructorDomain = createDomain('constructorDomain');

export const ConstructorGate = createGate<{agreementId?: string}>();

// effects
export const createAdditionalAgreementFx = constructorDomain.createEffect(
    additionalAgreementService.createAdditionalAgreement,
);

export const updateAdditionalAgreementFx = constructorDomain.createEffect(
    additionalAgreementService.updateAdditionalAgreement,
);

export const fetchJobCategoriesListFx = constructorDomain.createEffect(async () => {
    const result = await getJobCategoriesList();
    return result.data;
});

export const getAdditionalAgreementForConstructorFx = constructorDomain.createEffect(
    additionalAgreementService.getAdditionalAgreement,
);

export const loadWorkObjectsFx = constructorDomain.createEffect(
    addWorkObjectService.getCatalogWorkObjects,
);

// events
export const submitCreateAdditionalAgreement = constructorDomain.createEvent<{
    isReleased?: boolean;
    isDraft?: boolean;
}>();
export const resetAdditionalAgreementCreation = constructorDomain.createEvent();
export const duplicateWorkSpecification = constructorDomain.createEvent<{
    index: number;
    virId: string;
    workObjectId: string;
    specification: TpiSpecification;
}>();
export const deleteWorkSpecification = constructorDomain.createEvent<{
    virId: string;
    workObjectId: string;
    specId: string;
}>();
export const updateSpecQuantity = constructorDomain.createEvent<UpdateCurrentSpec>();
export const updateSpecComment = constructorDomain.createEvent<UpdateCurrentSpec>();
export const updateSpecAttachments = constructorDomain.createEvent<UpdateCurrentSpec>();
export const updateSpecPrice = constructorDomain.createEvent<UpdateCurrentSpec>();
export const updateSpecCoefficient = constructorDomain.createEvent<UpdateCurrentSpec>();
export const updateSpecDate = constructorDomain.createEvent<UpdateCurrentSpec>();

export const setActiveAccordion = constructorDomain.createEvent<string>();
export const createEmptyVir = constructorDomain.createEvent<string>();
export const addWork = constructorDomain.createEvent();
export const createNewAdditionalAgreement = constructorDomain.createEvent();
export const saveCurrentWorkObjectToAddTpis = constructorDomain.createEvent<{
    virId: string;
    workObjectId: string;
}>();

export const addWorkObjectsListToVirs = constructorDomain.createEvent<AddedWorkObject[]>();
export const deleteVir = constructorDomain.createEvent<string>();
export const deleteWorkObject = constructorDomain.createEvent<{
    virId: string;
    workObjectId: string;
}>();

export const checkSpecificationToUpdate = constructorDomain.createEvent<string>();
export const uncheckSpecificationToUpdate = constructorDomain.createEvent<string>();

export const updatePrepayment = constructorDomain.createEvent<boolean>();
export const parentSigningDateChanged = constructorDomain.createEvent<string>();
export const setCommentToAdditionalAgreement = constructorDomain.createEvent<string>();
export const triggerOpeningLastVir = constructorDomain.createEvent();
export const resetSelectedTpis = constructorDomain.createEvent();
export const validateSpecs = constructorDomain.createEvent();
export const setAttachmentToVir = constructorDomain.createEvent<TAttachmentFile[]>();

// stores
export const $activeAccordion = constructorDomain.createStore('');

export const {
    $isOpen: $isDeleteStatementModalOpen,
    openModal: openDeleteStatementModal,
    closeModal: closeDeleteStatementModal,
} = modalStateFactory();

export const {
    $isOpen: $isDeleteObjectModalOpen,
    openModal: openDeleteObjectModal,
    closeModal: closeDeleteObjectModal,
} = modalStateFactory();

export const $virs = addWorkObjectDomain.createStore<VirWork[]>([]);
export const $currentLocationToAddTpis = addWorkObjectDomain.createStore<{
    virId: string;
    workObjectId: string;
} | null>(null);

export const $checkedSpecificationsForUpdate = constructorDomain.createStore<Array<string>>([]);

export const initialState: IUpdatedAgreement = {
    is_released: false,
    is_prepayment: false,
    agreement_id: '',
    project_id: '',
    contract: '',
    contract_id: '',
    tpi_id: '',
    begin_date: '',
    end_date: '',
    parent_signing_date: null,
    agreement_comments: [],
    agreement_attachments: [],
    works: {},
    meta: 'save_draft',
};

export const $createAdditionalAgreementData =
    constructorDomain.createStore<IUpdatedAgreement>(initialState);
export const $lavParts = constructorDomain.createStore<Record<string, string[]>>({});
export const $agreementType = constructorDomain.createStore<EAdditionalAgreementType | null>(1);
export const $agreementId = constructorDomain.createStore('');
export const $loadingAgreement = constructorDomain.createStore(false);
export const $success = additionalAgreementDomain.createStore(false);
export const $createdAgreementId = additionalAgreementDomain.createStore('');
export const $agreementOnRevision = additionalAgreementDomain.createStore(false);
export const $touchedConstructorForm = constructorDomain.createStore(false);
export const $validConstructor = constructorDomain.createStore(false);
export const $allowToSetPrepayment = constructorDomain.createStore(false);
export const $jobCategoriesList = constructorDomain.createStore<Array<JobCategory>>([]);
export const $signingDateIsEditable = constructorDomain.createStore<boolean>(false);
export const $agreementCreationDisabled = constructorDomain.createStore<boolean>(false);
export const $emptyPayrollIndexList = constructorDomain.createStore<number[]>([]);
export const $errorBannerShowed = constructorDomain.createStore<boolean>(false);
export const $agreementErrorObject = constructorDomain.createStore(new AgreementError());

export const $constructorPage = combine({
    agreementType: $agreementType,
    ds: $createAdditionalAgreementData,
    checkedSpecsForUpdate: $checkedSpecificationsForUpdate,
    success: $success,
    validConstructor: $validConstructor,
    touchedConstructor: $touchedConstructorForm,
    createdAgreementId: $createdAgreementId,
    openedWork: $activeAccordion,
    loadingAgreement: $loadingAgreement,
    isAllowToSetPrepayment: $allowToSetPrepayment,
    jobCategoriesList: $jobCategoriesList,
    signingDateIsEditable: $signingDateIsEditable,
    agreementCreationDisabled: $agreementCreationDisabled,
    emptyPayrollIndexList: $emptyPayrollIndexList,
    errorBannerShowed: $errorBannerShowed,
    agreementErrorObject: $agreementErrorObject,
});
