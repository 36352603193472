import styled from 'styled-components/macro';
import {Typography} from '@beeline/design-system-react';

export const HeaderBlock = styled.header``;

export const UpperHeaderWrapper = styled.div`
    display: flex;
    padding: 8px 32px;
    border-bottom: 1px solid #e0e0e0;
    align-items: center;
    height: var(--toplineHeight);
    justify-content: space-between;
`;

export const Title = styled.h3`
    margin-bottom: 0;
    font-size: 19px;
`;

export const ModalTitle = styled(Typography)`
    margin-bottom: 16px;
`;

export const ModalText = styled.div`
    font-size: 17px;
`;
