import styled from "styled-components";
import { H5Styled } from "shared/ui/deprecated/HeaderText/style";


export const HeaderBlock = styled.header`
display: flex;
padding-top:12px;
padding-bottom:12px;
padding-left: 24px;
padding-right: 24px;
border-bottom: 1px solid #e0e0e0;
display: flex;
align-items: center;
height: var(--toplineHeight);
justify-content: space-between;
`

export const Title = styled.h3`
margin-bottom: 0;
font-size: 19px;
`

export const ModalTitle = styled(H5Styled)`
margin-bottom: 16px;
`

export const ModalText = styled.div`
font-size: 17px
`
