import React from 'react';
import {useUnit} from 'effector-react/compat';
import {
    Checkbox,
    PopoverHelper,
    Select,
    TableBody,
    TableHead,
    TableRow,
    Typography,
} from '@beeline/design-system-react';
import {
    EWorkObjectTypes,
    ITemporaryAgreementWorkObject,
} from 'shared/types/additionalAgreementsTypes';
import * as S from './style';
import {ObjectDestination} from './ObjectDestination';
import {worksheetCopyColumns} from './worksheetCopyColumns';
import {PROHIBITED_OBJECT_HELPER_TEXT} from '../const';
import {
    $agreementObjectSelectStore,
    $workTypeOptions,
    allSpecificationsSelected,
    specificationSelected,
    workObjectSelected,
    workTypeOptionSelected,
} from '../../../model';

interface WorksheetCopyTableProps {
    sheetNumber: number;
    worksheetId: string;
    initialData: ITemporaryAgreementWorkObject[];
    data: ITemporaryAgreementWorkObject[];
    quantityCheck: boolean;
    coefficientCheck: boolean;
}

export const WorksheetCopyTable = ({
    sheetNumber,
    initialData,
    data,
    quantityCheck,
    coefficientCheck,
    worksheetId,
}: WorksheetCopyTableProps) => {
    const [workTypeOptions, {availableWorksheetObjects}] = useUnit([
        $workTypeOptions,
        $agreementObjectSelectStore,
    ]);
    let filteredWorksheetCopyColumns = [...worksheetCopyColumns];
    if (!quantityCheck) {
        filteredWorksheetCopyColumns = filteredWorksheetCopyColumns.filter(
            (item) => item.name !== 'quantity',
        );
    }
    if (!coefficientCheck) {
        filteredWorksheetCopyColumns = filteredWorksheetCopyColumns.filter(
            (item) => item.name !== 'coefficient',
        );
    }

    const agreementHasSameObjectType = (objectType: EWorkObjectTypes): boolean =>
        availableWorksheetObjects.filter((item) => item.object_type === objectType).length > 0;

    return (
        <S.CommonWrapper>
            <Typography variant="subtitle2" className="inactiveText">
                {`Ведомость ${sheetNumber + 1}`}
            </Typography>
            {data.map((workObject, index) => {
                const objectId = workObject.id;
                const disabled = !agreementHasSameObjectType(workObject.object_type);
                const allSpecificationsChecked = workObject.work_specifications.every(
                    (specification) => specification.checked,
                );
                const intermediateSpecificationsChecked =
                    !allSpecificationsChecked &&
                    workObject.work_specifications.some((specification) => specification.checked);
                const allSpecsCheckboxChecked =
                    allSpecificationsChecked || intermediateSpecificationsChecked;
                const selectedWorkTypeOption = workTypeOptions.find(
                    (option) => option.id === workObject.work_type_id,
                );
                const selectedWorkTypeOptionValue = selectedWorkTypeOption
                    ? [selectedWorkTypeOption]
                    : [];

                const initialGfk = initialData[index].gfk;
                const initialName = initialData[index].name;

                return (
                    <S.SheetWrapper key={workObject.id}>
                        <S.SheetTitleWrapper>
                            <PopoverHelper
                                body={PROHIBITED_OBJECT_HELPER_TEXT}
                                disableHover={!disabled}
                            >
                                <S.CheckboxWrapper>
                                    <S.StyledCheckbox
                                        label={
                                            <S.CheckboxLabelWrapper>
                                                <Typography variant="subtitle2">{`${initialGfk} ${initialName}`}</Typography>
                                            </S.CheckboxLabelWrapper>
                                        }
                                        checked={workObject.checked}
                                        disabled={disabled}
                                        onChange={() =>
                                            workObjectSelected({
                                                worksheetId: worksheetId,
                                                objectId: workObject.id,
                                                checked: workObject.checked,
                                            })
                                        }
                                    />

                                    <S.StyledTypography variant="body3" $disabled={disabled}>
                                        {initialData[index].work_type_name}
                                    </S.StyledTypography>
                                </S.CheckboxWrapper>
                            </PopoverHelper>

                            {!disabled && workObject.checked && (
                                <>
                                    <ObjectDestination workObject={workObject} />
                                    <S.WorkTypeSelectWrapper>
                                        <Select<{id: string; value: string}>
                                            label="Тип работ"
                                            onChange={(values) => {
                                                workTypeOptionSelected({
                                                    worksheetId: worksheetId,
                                                    objectId: objectId,
                                                    workTypeOption: values[0],
                                                });
                                            }}
                                            values={selectedWorkTypeOptionValue}
                                            options={workTypeOptions}
                                            fullWidth
                                        />
                                    </S.WorkTypeSelectWrapper>
                                </>
                            )}
                        </S.SheetTitleWrapper>
                        <S.StyledDivider />
                        <S.TableWrapper>
                            <S.StyledTable>
                                <TableHead>
                                    <TableRow>
                                        {filteredWorksheetCopyColumns.map((headItem, index) => (
                                            <S.StyledTableHeaderData
                                                key={headItem.name.toString()}
                                                $disabled={disabled}
                                            >
                                                {index === 0 ? (
                                                    <Checkbox
                                                        onChange={() =>
                                                            allSpecificationsSelected({
                                                                worksheetId,
                                                                objectId,
                                                                checked: allSpecsCheckboxChecked,
                                                            })
                                                        }
                                                        checked={allSpecsCheckboxChecked}
                                                        type={
                                                            intermediateSpecificationsChecked
                                                                ? 'indeterminate'
                                                                : 'checkbox'
                                                        }
                                                        disabled={disabled}
                                                    />
                                                ) : (
                                                    headItem.label
                                                )}
                                            </S.StyledTableHeaderData>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {workObject.work_specifications.map(
                                        (workSpecificationItem, index) => (
                                            <TableRow
                                                key={`${workSpecificationItem.id}_${index}`}
                                                hover={!!workSpecificationItem.tpi_specification}
                                            >
                                                {filteredWorksheetCopyColumns.map((columnItem) => (
                                                    <S.StyledTableData
                                                        key={`${columnItem.name.toString()}_${
                                                            workSpecificationItem.id
                                                        }`}
                                                        width={columnItem.width}
                                                        height="53px"
                                                        $disabled={disabled}
                                                        dense
                                                    >
                                                        {columnItem.render &&
                                                            columnItem.render(
                                                                workSpecificationItem,
                                                                disabled,
                                                                () =>
                                                                    specificationSelected({
                                                                        worksheetId,
                                                                        objectId,
                                                                        specificationId:
                                                                            workSpecificationItem.id,
                                                                    }),
                                                            )}
                                                    </S.StyledTableData>
                                                ))}
                                            </TableRow>
                                        ),
                                    )}
                                </TableBody>
                            </S.StyledTable>
                        </S.TableWrapper>
                    </S.SheetWrapper>
                );
            })}
        </S.CommonWrapper>
    );
};
