import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useStore, useUnit} from 'effector-react';
import {DatePicker} from '@beeline/design-system-react';
import {v4 as uuidv4} from 'uuid';

import {
    $constructorPage,
    createEmptyVir,
    saveCurrentWorkObjectToAddTpis,
    parentSigningDateChanged,
} from 'processes/contructor';
import Button from 'shared/ui/deprecated/Button/Button';
import ProjectInfoCell from 'shared/ui/deprecated/ProjectInfoCell/ProjectInfoCell';
import NextArrowIcon from 'icons/NextArrowIcon';
import {Menu} from 'shared/ui/Menu';
import {formatDateToBackendFormat} from 'shared/helpers/formatHelper';
import {EAdditionalAgreementType} from 'shared/types/additionalAgreementsTypes';

import * as S from './style';
import {$selectedTpi} from '../../VirStart/model';

export function TopPanel() {
    const navigate = useNavigate();
    const {
        ds,
        openedWork,
        touchedConstructor,
        validConstructor,
        signingDateIsEditable,
        agreementType,
    } = useStore($constructorPage);
    const selectedLotObject = useUnit($selectedTpi);
    const {
        works,
        contract_name,
        project_id,
        project_number,
        agreement_id,
        agreement_number,
        parent_signing_date,
    } = ds;
    const currentVirWorks = works?.[openedWork]?.work_objects;
    const worksObjects = currentVirWorks ? Object.values(currentVirWorks) : [];
    const subMenu = worksObjects.length
        ? worksObjects.map((el) => ({
              label: el.object_name,
              menuHandler: () => {
                  saveCurrentWorkObjectToAddTpis({
                      virId: el.virId || '',
                      workObjectId: el.object_id || '',
                  });
                  navigate(`/projects/${project_id}/additional-agreement/select`);
              },
          }))
        : [];
    const menuOptions = [
        {
            item: (
                <>
                    Из ТЦП
                    <NextArrowIcon />
                </>
            ),
            subMenu,
        },
    ];
    const parentSigningDateValid =
        !!ds.parent_signing_date &&
        new Date(ds.parent_signing_date).valueOf() <= new Date(Date.now()).valueOf();
    const parentSigningDateHasError =
        touchedConstructor && !validConstructor && !parentSigningDateValid;
    const parentSigningDate = parent_signing_date
        ? parent_signing_date.toLocaleString()
        : undefined;
    const isAgreementAddition = agreementType === EAdditionalAgreementType.Addition;
    return (
        <S.HeaderWrap>
            <S.HeaderBtnWrap>
                <Menu
                    width="280px"
                    title="Добавить работы"
                    disabled={worksObjects.length === 0}
                    options={menuOptions}
                />

                <Button
                    color="outline"
                    onClick={() => {
                        navigate(`/constructor/add_work_objects`);
                    }}
                >
                    Добавить объекты
                </Button>
                <S.StyledButton
                    color="outline"
                    onClick={() => createEmptyVir(`front -${uuidv4()}`)}
                >
                    Создать ведомость
                </S.StyledButton>
            </S.HeaderBtnWrap>
            <S.HeaderInfoWrap>
                <ProjectInfoCell title="Проект">
                    <Link to={`/projects/${project_id}`}>{project_number}</Link>
                </ProjectInfoCell>
                <ProjectInfoCell title="Договор">{contract_name}</ProjectInfoCell>
                {!!selectedLotObject && (
                    <ProjectInfoCell title="Лот">{selectedLotObject.tpis_name}</ProjectInfoCell>
                )}

                {isAgreementAddition && (
                    <>
                        <ProjectInfoCell title="ДС">
                            <Link to={`/additional-agreements/${agreement_id}`}>
                                {agreement_number}
                            </Link>
                        </ProjectInfoCell>
                        <DatePicker
                            label="Дата подписания ДС"
                            maxDate={formatDateToBackendFormat(new Date(Date.now()).toString())}
                            onChange={parentSigningDateChanged}
                            value={parentSigningDate}
                            error={parentSigningDateHasError}
                            disabled={!signingDateIsEditable}
                        />
                    </>
                )}
            </S.HeaderInfoWrap>
        </S.HeaderWrap>
    );
}
