import { NotFound, NotFoundCaption, NotFoundTitle } from "components/NotFound";

export function NoResults() {
    return (
        <NotFound height="687px">
            <NotFoundTitle>По вашему запросу ничего не найдено</NotFoundTitle>
            <NotFoundCaption>
                Попробуйте сократить запрос или задать его по-другому
            </NotFoundCaption>
        </NotFound>
    );
}
