import {$createAdditionalAgreementData, deleteWorkObject} from './stores';
import {getPathToWorkObject} from '../helpers';
import {unset} from 'lodash';

$createAdditionalAgreementData.on(deleteWorkObject, (state, {virId, workObjectId}) => {
    const ds = {...state};
    const isLavPartDeleting = (ds.works[virId].work_objects[workObjectId].lav_gfk ?? '').length > 0;
    const workObjects = ds.works[virId].work_objects;
    if (isLavPartDeleting) {
        const lavGfk = ds.works[virId].work_objects[workObjectId].lav_gfk ?? 'error';
        for (const item in workObjects) {
            if (workObjects[item].lav_gfk === lavGfk && !workObjects[item].is_half_set) {
                workObjects[item].lav_gfk = '';
                workObjects[item].is_half_set = true;
            }
        }
    }
    const workObjectPath = getPathToWorkObject({virId, workObjectId});
    unset(ds, workObjectPath);
    return ds;
});
