import AccordionItem from 'shared/ui/deprecated/Accordion/Accordion';
import styled, {css} from 'styled-components/macro';
import MaskInput from 'shared/ui/deprecated/MaskInput/MaskInput';
import Counter from 'shared/ui/deprecated/Counter/Counter';
import {TableCell, TableCellContent, TableString} from 'shared/ui/Table/Table';
import ButtonList from 'shared/ui/deprecated/ButtonList/ButtonList';
import Checkbox from 'shared/ui/deprecated/Checkbox/Checkbox';
import {DatePickerRange} from '@beeline/design-system-react';

export const StyledAccordionItem = styled(AccordionItem)<{active?: boolean; noOverflow?: boolean}>`
    & > div:first-child {
        ${(p) => p.active && ' background: rgba(25, 28, 52, 0.08);'}
    }
`;

export const ProgressWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 20px;
`;

export const VirContent = styled.div`
    padding: 32px;
    width: 100%;
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    & > * {
        margin-left: 16px;

        &:first-child {
            margin-left: 0;
        }
    }
`;

export const Wrapper = styled.div`
    max-width: 100%;
`;

export const BtnText = styled.button`
    padding: 0;
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-right: 8px;
    background-color: transparent;
    border: 0;

    .icon {
        font-size: 24px;
        color: var(--secondary);
        pointer-events: none;
    }
`;

export const WorkCaption = styled.div`
    line-height: calc(18 / 15);
`;

export const PriceText = styled.div`
    width: calc(100% - 32px);
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
`;

export const PriceCaption = styled.div`
    color: var(--secondary);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const EditableCell = styled(TableCell)<{error?: boolean; isEditable?: boolean}>`
    position: relative;
    padding: 0;
    text-align: center;
`;
export const PriceAccept = styled.div`
    position: absolute;
    height: 24px;
    margin-right: 8px;
    min-width: 24px;
    width: 24px;
    --db: none;
    top: 50%;
    transform: translate(20px, -45%);

    &:hover {
        --db: block;
    }

    .icon {
        color: var(--secondary);
        font-size: 24px;
    }
`;
export const StyledMaskedInput = styled(MaskInput)<{error?: boolean}>`
    &:first-child {
        border: none;
        height: 100%;
    }

    & div {
        background: transparent;
        padding-left: 0;
        border: none;
        outline: none;
    }

    & .input__block {
        border: none;
        background: transparent;
        border-radius: 0;
        height: 100%;
    }

    & input {
        text-align: center;
        padding: 16px;
        background: none;
    }

    & input::placeholder {
        color: #262626;
    }
`;

export const SettingsBtn = styled.div`
    --db: none;
    cursor: pointer;

    &:hover {
        --db: block;
    }

    margin-right: 8px;

    .icon {
        color: var(--secondary);
        font-size: 24px;
    }
`;

export const CommentBtn = styled.button`
    --db: none;
    position: relative;
    background-color: transparent;
    border: none;
    height: 24px;
    padding: 0;
    width: 24px;
    --icon-color: var(--secondary);

    &:hover {
        --icon-color: var(--body-color);
        --db: block;
    }

    .icon {
        color: var(--icon-color);
        font-size: 24px;
        transition-duration: 0.4s;
    }
`;

export const RatioText = styled.div`
    flex-grow: 1;
    display: flex;
    gap: 10px;
    justify-content: center;
`;
export const CounterStatement = styled(Counter)`
    position: absolute;
    z-index: 2;
    bottom: 16px;
    left: 14px;
`;

export const Indexes = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CoefficientIndex = styled.div`
    color: var(--secondary);
`;

export const StyledTableCell = styled(TableCellContent)``;

export const StyledButtonList = styled(ButtonList)`
    button {
        background: none;
        border: none;
        padding: 0;
        border-radius: 3px;

        &:hover,
        &:focus {
            background: none;
        }
    }

    & > div {
        z-index: 4;
        left: -134px;
        top: 24px;
    }
`;

export const ListItem = styled.div`
    cursor: pointer;
`;

export const SpecificationIndex = styled.div<{shownCheckBox?: boolean}>`
    position: absolute;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0.2s;
    ${(p) =>
        p.shownCheckBox &&
        css`
            opacity: 0;
            z-index: -1;
        `}
`;

export const StyledCheckbox = styled(Checkbox)<{shownCheckBox?: boolean}>`
    position: absolute;
`;

export const DateTableCell = styled(TableCell)`
    padding: 0;
`;

export const DateTableCellContent = styled(TableCellContent)`
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
     {
        &:hover {
            background: rgba(25, 28, 52, 0.08);
            box-shadow: inset 0 0 0 1px #202123;
        }
    }
`;

export const StyledDatePickerRange = styled(DatePickerRange)`
    .dsb_datepicker {
        outline: none;
    }

    span {
        display: none;
    }

    .dsb_input-wrapper {
        width: auto;
    }

    && input {
        height: auto;
        padding: 0 10px !important;
        background: none;
        width: 200px;
        border: none !important;
        cursor: pointer;
    }
`;

export const ObjectNameTableString = styled(TableString)`
    background: rgba(25, 28, 52, 0.08);
    padding-top: 60px;
`;
