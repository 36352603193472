import styled from 'styled-components/macro';
import {Typography} from '@beeline/design-system-react';

export const StyledPriceUnit = styled(Typography)`
    display: -webkit-box;
    width: 100%;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    word-wrap: break-word;
`;