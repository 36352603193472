import React, {useState} from 'react';
import {useStore} from 'effector-react';

import {WorkObjectNameRow} from 'Entities/WorkObjectNameRow/ui/WorkObjectNameRow';
import {$agreementOnRevision, $agreementType} from 'processes/contructor';
import {TableCell, TableCellContent, TableHead} from 'shared/ui/Table/Table';

import {ConstructorVirWorkObject} from 'shared/types/additionalAgreementsTypes';
import {SpecificationRow} from '../SpecificationRow/SpecificationRow';
import {DeleteObjectStatement} from '../modals/DeleteObjectStatement';
import {isWorkObjectDeletable} from '../helpers';

interface AdditionalAgreementStatementProps {
    workObject: ConstructorVirWorkObject;
    virId: string;
    touchedConstructor: boolean;
}

export function VirWork({
    workObject,
    virId,
    touchedConstructor,
}: AdditionalAgreementStatementProps) {
    const {object_name, object_id, work_specifications} = workObject;
    const [activeAdds, setActiveAdds] = useState<string | null>(null);
    const specsArr = Object.values(work_specifications);
    const agreementOnRevision = useStore($agreementOnRevision);
    const agreementType = useStore($agreementType);
    const showAdds = (id: string) => {
        if (id === activeAdds) {
            setActiveAdds(null);
        } else {
            setActiveAdds(id);
        }
    };

    return (
        <>
            <WorkObjectNameRow>
                <>
                    <TableCell colspan={agreementOnRevision ? 11 : 10}>
                        <TableHead>{object_name}</TableHead>
                    </TableCell>
                    <TableCell width="56px">
                        <TableCellContent>
                            <DeleteObjectStatement
                                virId={virId}
                                workObjectId={object_id}
                                isDeletable={isWorkObjectDeletable(workObject)}
                            />
                        </TableCellContent>
                    </TableCell>
                </>
            </WorkObjectNameRow>

            {specsArr?.length > 0 &&
                specsArr.map((item, index) => (
                    <SpecificationRow
                        agreementType={agreementType}
                        touchedConstructor={touchedConstructor}
                        currentWorkObjectId={object_id}
                        currentVirId={virId}
                        key={item.id}
                        data={item}
                        index={index}
                        activeAdds={activeAdds}
                        showAdds={showAdds}
                        agreementOnRevision={agreementOnRevision}
                    />
                ))}
        </>
    );
}
