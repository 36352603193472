import {createGate} from 'effector-react';
import {combine, createDomain} from 'effector';
import {AddedWorkObject, CatalogWorkType, VirWork} from '../types';
import {$formErrors} from '../../VirStart/model';
import {addWorkObjectService} from '../service';
import {CatalogWorkObjectOption} from '../../VirStart/types';

export const addWorkObjectDomain = createDomain();
export const AddWorkObjectGate = createGate();

export const loadWorkObjectsFx = addWorkObjectDomain.createEffect(
    addWorkObjectService.getCatalogWorkObjects,
);

export const loadWorkTypesFx = addWorkObjectDomain.createEffect(
    addWorkObjectService.getCatalogWorkTypes,
);

export const selectWorkObject = addWorkObjectDomain.createEvent<
    CatalogWorkObjectOption & {id: string}
>();
export const selectWorkType = addWorkObjectDomain.createEvent<CatalogWorkType & {id: string}>();
export const selectStatement = addWorkObjectDomain.createEvent<VirWork>();

export const addNewRow = addWorkObjectDomain.createEvent();
export const saveRow = addWorkObjectDomain.createEvent<AddedWorkObject>();
export const updateRow = addWorkObjectDomain.createEvent();
export const deleteRow = addWorkObjectDomain.createEvent<string>();

// selectors
export const $workObjects = addWorkObjectDomain.createStore<CatalogWorkObjectOption[]>([]);

export const $workTypes = addWorkObjectDomain.createStore<CatalogWorkType[]>([]);

export const $addedWorkObjectsList = addWorkObjectDomain.createStore<AddedWorkObject[]>([]);

export const $touchedForm = addWorkObjectDomain.createStore(false);

export const $addedWorkObjectPage = combine({
    workObjectOptions: $workObjects,
    workTypes: $workTypes,
    selectedWorkObjects: $addedWorkObjectsList,
    touchedForm: $touchedForm,
    errors: $formErrors,
});
