import React from 'react';
import {InfoTitleStyled, InfoCaptionStyled} from 'shared/ui/deprecated/ProjectInfoCell/style';
import {Wrapper} from './style';

export default function ProjectInfoCell(props) {
    const {title = '', children, flex, ...rest} = props;
    return (
        <Wrapper flex={flex}>
            <InfoTitleStyled {...props}>{title}</InfoTitleStyled>
            <InfoCaptionStyled {...props}>{children}</InfoCaptionStyled>
        </Wrapper>
    );
}

export function InfoTitle(props) {
    const {children, ...rest} = props;
    return (
        <>
            <InfoTitleStyled {...props}>{children}</InfoTitleStyled>
        </>
    );
}

export function InfoCaption(props) {
    const {children, ...rest} = props;
    return (
        <>
            <InfoCaptionStyled {...props}>{children}</InfoCaptionStyled>
        </>
    );
}
