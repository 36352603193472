import React from 'react';
import {Label} from '@beeline/design-system-react';
import {AgreementObjectType, ApprovementObjectType} from 'shared/types/agreementObjectTypes';
import _ from 'lodash';

interface VirLabelProps {
    virId: string;
    status: number;
    agreementObject: AgreementObjectType | null;
}

export const AgreementStatusLabel = ({virId, status, agreementObject}: VirLabelProps) => {
    const object: Record<string, ApprovementObjectType> = agreementObject?.[virId] ?? {};

    if (_.isEmpty(object) || status !== 2) return null;

    const totalAmount = Object.keys(object).length;
    const workedAmount = Object.values(object).filter((item) => item.myApprovement !== null).length;
    const isDone = totalAmount === workedAmount;
    const title = `Отработано пунктов ${workedAmount}/${totalAmount}`;
    const type = isDone ? 'success' : 'default';

    return <Label title={title} type={type} variant="outline" />;
};
