import React, {useState} from 'react';

import {EditableCell, StyledMaskedInput} from 'pages/VirConstructor/SpecificationRow/style';

import {formattedStringToNumber} from 'shared/helpers/formatHelper';
import {Tooltip} from 'shared/ui/deprecated/Tooltip/Tooltip';

interface QuantityCellProps {
    quantity: number;
    onChange: (quantity: number) => void;
}

const QuantityCell = ({quantity, onChange}: QuantityCellProps) => {
    const [touched, setTouched] = useState(false);
    const [value, setValue] = useState(quantity);

    const handleOnChange = () => {
        onChange(formattedStringToNumber(value));
    };

    return (
        <EditableCell error={quantity <= 0 || (touched && !quantity)} isEditable>
            <Tooltip
                titleText={'Укажите количество'}
                position="top-end"
                horyzontal={0}
                vertical={7}
            >
                <StyledMaskedInput
                    mask="numberWithComma"
                    value={`${value}`}
                    onBlur={handleOnChange}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleOnChange();
                        }
                    }}
                    onAccept={setValue}
                />
            </Tooltip>
        </EditableCell>
    );
};

export default QuantityCell;
