import styled from 'styled-components/macro';
import Select from 'shared/ui/Select/Select';
import TextField from 'shared/ui/deprecated/TextField/TextField';
import {CheckboxLabel} from 'shared/ui/deprecated/Checkbox/Checkbox';

export const Content = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 32px;
`;
export const Buttons = styled.div`
    display: flex;
    gap: 16px;
    align-self: flex-end;
    margin-top: 46px;
`;

export const StyledSelect = styled(Select)`
    //margin-bottom: 44px;
    //margin-top: 32px;
    div {
        width: 100%;
    }
`;

export const FormInput = styled.input``;
export const StyledInput = styled(TextField)``;

export const Wrapper = styled.div``;

export const Checkbox = styled(CheckboxLabel)`
    padding: 0;
    margin-bottom: 20px;
`;

export const RadioBox = styled.div`
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    gap: 18px;
`;

export const Title = styled.div`
    display: flex;
    gap: 8px;
`;

export const Fields = styled.div`
    display: flex;
    gap: 32px;
    flex-direction: column;
    margin-bottom: 44px;
`;

export const SubText = styled.span`
    font-size: 15px;
    line-height: 18px;
    margin-top: 4px;
    display: block;
    color: var(--bs-gray-600);
`;

export const MainText = styled.div`
    font-size: 17px;
    line-height: 18px;
`;
