import React, {ReactNode} from 'react';
import {Tooltip} from '@beeline/design-system-react';

import {StyledSpan} from 'shared/styles/commonStyle';

import {CustomTableData} from './style';

interface TableCellProps {
    lineCount: number;
    children: ReactNode;
    dense?: boolean;
    noOverflow?: boolean;
    noCursor?: boolean;
}

const TableCell = React.forwardRef<HTMLSpanElement, TableCellProps>(
    ({lineCount, children, dense, noOverflow, noCursor}: TableCellProps, ref) => {
        return (
            <CustomTableData dense={dense}>
                <StyledSpan ref={ref} lineCount={lineCount} noOverflow={noOverflow} noCursor={noCursor}>
                    {children}
                </StyledSpan>
            </CustomTableData>
        );
    },
);

export const TableCellWithTooltip = ({
    lineCount,
    children,
    title,
    dense,
    noOverflow,
    noCursor,
}: TableCellProps & {title: string}) => {
    return (
        <Tooltip title={title}>
            <TableCell
                lineCount={lineCount}
                dense={dense}
                noOverflow={noOverflow}
                noCursor={noCursor}
            >
                {children}
            </TableCell>
        </Tooltip>
    );
};
