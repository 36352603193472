import {forwardPayload} from 'shared/helpers/effector';
import {getTpiSpecificationsFx, setActiveTab} from 'pages/VirSelect/model/SelectTpisPage';
import {
    $allTpis,
    $count,
    $page,
    $pageSize,
    $searchAllTerm,
    changeSearchAllInput,
    filterAllBySearch,
    setPage,
    setPageSize,
} from './index';
import {v4 as uuidv4} from 'uuid';

$searchAllTerm.on(changeSearchAllInput, forwardPayload()).reset(setActiveTab);
$count.on(getTpiSpecificationsFx.doneData, (state, {data}) => data?.count);
$allTpis.on(getTpiSpecificationsFx.doneData, (state, {data}) =>
    data?.results?.map((el) => ({
        ...el,
        id: `front -${uuidv4()}`,
        tpi_specification_id: el.id,
        default_end_date: el.end_date,
        price: +el.price,
        default_begin_date: el.default_begin_date,
    })),
);

$pageSize.on(setPageSize, forwardPayload());

$page.on(setPage, forwardPayload()).reset([setPageSize, setActiveTab, filterAllBySearch]);
