import {EMPTY_NUMBERED_VALUE, VERTICAL_DIVIDER} from './constants';

import type {
    TFilterFilesByAllowedFormats,
    TFilterFilesByMaxFileSize,
} from './useFileUploader.types';

export const isArrayNonEmpty = (array: Array<unknown> | undefined) => {
    return (array?.length ?? 0) > 0;
};

export const filterFilesByAllowedFormats: TFilterFilesByAllowedFormats = (
    files,
    allowedFileList,
) => {
    const regex = new RegExp(`.(${(allowedFileList || []).join(VERTICAL_DIVIDER)})`);
    const isFilesMustBeFilteredByAllowedFormats = isArrayNonEmpty(allowedFileList);

    let isAnyFileFiltered = false;

    return {
        filteredFiles: isFilesMustBeFilteredByAllowedFormats
            ? [...files].filter(({name}) => {
                  const isFileFitsAllowedFormats = regex.test(name.toLowerCase());
                  isAnyFileFiltered = !isFileFitsAllowedFormats || isAnyFileFiltered;

                  return isFileFitsAllowedFormats;
              })
            : files,
        isAnyFileFiltered,
    };
};

export const filterFilesByMaxFileSize: TFilterFilesByMaxFileSize = (files, maxFileSize) => {
    const isFilesMustBeFilteredByMaxFileSize = (maxFileSize as number) > EMPTY_NUMBERED_VALUE;
    let isAnyFileFiltered = false;

    return {
        filteredFiles: isFilesMustBeFilteredByMaxFileSize
            ? [...files].filter(({size}) => {
                  const isFileFitsAllowedFormats = size <= (maxFileSize as number);
                  isAnyFileFiltered = !isFileFitsAllowedFormats || isAnyFileFiltered;

                  return isFileFitsAllowedFormats;
              })
            : files,
        isAnyFileFiltered,
    };
};
