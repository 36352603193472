import styled from 'styled-components/macro';

export const InfoTitleStyled = styled.div`
    color: var(--secondary);
    line-height: calc(18 / 15);
`;
export const InfoCaptionStyled = styled.div`
    font-size: 17px;
    line-height: calc(22 / 17);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const Wrapper = styled.div<{flex?: string}>`
    ${(p) => p.flex && `flex: ${p.flex};`}
`;
