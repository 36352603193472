import React, {ReactNode} from 'react';
import {Tooltip} from '@beeline/design-system-react';

import * as S from './style';

interface TooltipedWrapperProps {
    children: ReactNode;
}

const Div = React.forwardRef<HTMLDivElement, TooltipedWrapperProps>(
    ({children}: TooltipedWrapperProps, ref) => {
        return <S.StyledDiv ref={ref}>{children}</S.StyledDiv>;
    },
);

export const TooltipedWrapper = ({title, children}: TooltipedWrapperProps & {title: ReactNode}) => {
    if (!title) return <Div>{children}</Div>;
    return (
        <Tooltip title={title}>
            <Div>{children}</Div>
        </Tooltip>
    );
};
