export const isDateRangeEmpty = (range: string[]) => {
    return range[0] === '' && range[0] === range[1];
};

export const transformDateRangeToString = (range: string[]): string | undefined => {
    if (range[1] === '') {
        range[1] = range[0];
    }
    return isDateRangeEmpty(range) ? undefined : range.join(',');
};
