import React from 'react';
import {useGate, useStore} from 'effector-react';
import {Button, Checkbox, Search, Typography} from '@beeline/design-system-react';

import {Table, TableCell, TableHead, TableString} from 'shared/ui/Table/Table';
import VirSelectString from 'pages/VirSelect/ui/TpiRow/VirSelectInfoString';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import Pagination from 'shared/ui/Pagination/Pagination';
import {NotFound, NotFoundCaption, NotFoundTitle} from 'components/NotFound';
import {TableSkeleton} from 'shared/ui/TableSkeleton';
import {TpiSpecification} from 'pages/VirSelect/types';

import * as S from './style';
import {
    $stage2CommonStore,
    changeAllPageSelection,
    changeSelectedTpiData,
    jobProcessGateStage2,
    resetSearchValue,
    resetSelectedTpiData,
    setPage,
    setPageSize,
    setSearchValue,
} from '../../model/stage2';

interface Stage2Props {
    onChangeStage: (stage: number) => void;
}

const getHeads = (firstColumn: JSX.Element) => [
    {
        content: firstColumn,
        width: '72px;',
    },
    {
        content: <>№ п.п</>,
        width: '80p;',
    },
    {
        content: <>Наименование работ</>,
        width: 'calc(100% - 619px);',
    },
    {
        content: <>Ед. измерения</>,
        width: '167px;',
    },
    {
        content: <>Цена за ед., ₽</>,
        width: '145px;',
    },
];

export const Stage2 = ({onChangeStage}: Stage2Props) => {
    useGate(jobProcessGateStage2);
    const {
        tpiData,
        searchValue,
        pageSize,
        page,
        count,
        isLoading,
        selectedTpiData,
        allChecked,
        isMainButtonDisabled,
    } = useStore($stage2CommonStore);

    const handleGoBack = () => {
        resetSelectedTpiData();
        onChangeStage(1);
    };

    const isPartly = selectedTpiData.length !== 0 && !allChecked && !isLoading;

    const handleTransitionThirdStage = () => {
        onChangeStage(3);
    };

    const handleAllSelected = (e) => {
        changeAllPageSelection(e.target.checked);
    };

    const headsArray = getHeads(
        <Checkbox
            type={isPartly ? 'indeterminate' : 'checkbox'}
            onChange={handleAllSelected}
            checked={
                selectedTpiData.some((selectedEl) =>
                    tpiData.some((el) => el.id === selectedEl.id),
                ) && !isLoading
            }
        />,
    );

    const isCheckedItem = (item: TpiSpecification) =>
        selectedTpiData.some((el) => el.id === item.id);

    const handleSelectPoint = (item: TpiSpecification) => {
        changeSelectedTpiData(item);
    };

    return (
        <>
            <Typography variant="h4">
                Выберите пункты работ для внесения в заказ на ПИР и укажите объемы работ
            </Typography>
            <S.SearchWrapper>
                <Search
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onClear={() => resetSearchValue()}
                    placeholder="Поиск по названию, номеру пункта работ"
                    fullWidth
                />
            </S.SearchWrapper>
            <S.TableContent>
                <>
                    {(isLoading || tpiData.length > 0) && (
                        <S.TableWrapper>
                            <Table>
                                <tbody>
                                    <TableString>
                                        {headsArray.map((head, idx) => (
                                            <TableCell key={idx} width={head.width}>
                                                <TableHead>{head.content}</TableHead>
                                            </TableCell>
                                        ))}
                                    </TableString>
                                    {isLoading ? (
                                        <TableSkeleton columns={headsArray} pageSize={pageSize} />
                                    ) : (
                                        tpiData.map((item, index, array) => (
                                            <VirSelectString
                                                pageHasNote={array.some((el) => el.note)}
                                                key={item.id}
                                                item={item}
                                                checked={isCheckedItem(item)}
                                                onSelected={() => handleSelectPoint(item)}
                                            />
                                        ))
                                    )}
                                </tbody>
                            </Table>
                            <WrapperOffset offsetY="13" offsetX="16">
                                <Pagination
                                    total={count}
                                    page={page}
                                    pageSize={pageSize}
                                    setPage={setPage}
                                    setPageSize={setPageSize}
                                />
                            </WrapperOffset>
                        </S.TableWrapper>
                    )}

                    {tpiData.length === 0 && !isLoading && (
                        <S.NotFoundWrapper>
                            <NotFound>
                                <NotFoundTitle>По вашему запросу ничего не найдено</NotFoundTitle>
                                <NotFoundCaption>
                                    Попробуйте сократить запрос или задать его по-другому
                                </NotFoundCaption>
                            </NotFound>
                        </S.NotFoundWrapper>
                    )}
                </>
            </S.TableContent>
            <S.Buttons>
                <Button className="me-3" size="medium" onClick={handleGoBack}>
                    Назад
                </Button>
                <Button
                    variant="contained"
                    size="medium"
                    onClick={handleTransitionThirdStage}
                    disabled={isMainButtonDisabled}
                >
                    Добавить выбранное
                </Button>
            </S.Buttons>
        </>
    );
};
