import React from 'react';
import {Icon} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import * as S from './style';

interface WorkExecutionButtonGroupProps {
    onAccept: () => void;
    onReject: () => void;
    selected: boolean | null;
}

export const WorkExecutionButtonGroup = ({
    onAccept,
    onReject,
    selected,
}: WorkExecutionButtonGroupProps) => {
    return (
        <S.ButtonGroupContainer>
            <S.StyledButton
                size="small"
                startIcon={<Icon iconName={Icons.Check} />}
                position="left"
                selected={selected}
                onClick={onAccept}
            />
            <S.VerticalDivider />
            <S.StyledButton
                size="small"
                startIcon={<Icon iconName={Icons.Close} />}
                position="right"
                selected={selected}
                onClick={onReject}
            />
        </S.ButtonGroupContainer>
    );
};
