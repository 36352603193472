import {ConstructorVirType, ConstructorVirWorkObject} from 'shared/types/additionalAgreementsTypes';

export const isWorksheetDeletable = (item: ConstructorVirType): boolean => {
    const workObjects = item.work_objects;
    for (let key in workObjects) {
        if (!isWorkObjectDeletable(workObjects[key])) return false;
    }
    return true;
};

export const isWorkObjectDeletable = (object: ConstructorVirWorkObject): boolean => {
    const workSpecs = object.work_specifications;
    for (let specKey in workSpecs) {
        if (workSpecs[specKey].is_ws_deletable === false) return false;
    }
    return true;
};
