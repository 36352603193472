import React from 'react';
import {Checkbox, Typography} from '@beeline/design-system-react';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {IVisibleColumn} from 'shared/types/tableTypes';

import * as S from './style';

interface ColumnCheckboxListProps {
    data: IVisibleColumn[];
    onChange: (item: IVisibleColumn) => void;
}

export const ColumnCheckboxList = ({data, onChange}: ColumnCheckboxListProps) => {
    return (
        <S.Wrapper>
            {data.map((item, index) => (
                <WrapperFlex key={item.id} gap="16px" padding="11px 0">
                    <WrapperFlex width="24px">
                        <Typography>{index + 1}</Typography>
                    </WrapperFlex>
                    <Checkbox label={item.name} checked={item.visible} onChange={() => onChange(item)} />
                </WrapperFlex>
            ))}
        </S.Wrapper>
    );
};
