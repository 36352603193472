import {sample} from 'effector';
import {
    $activeAccordion,
    $agreementId,
    $createAdditionalAgreementData,
    addWorkObjectsListToVirs,
    ConstructorGate,
    createAdditionalAgreementFx,
    createEmptyVir,
    deleteVir,
    getAdditionalAgreementForConstructorFx,
    resetAdditionalAgreementCreation,
    setActiveAccordion,
    triggerOpeningLastVir,
} from './stores';
import {forwardPayload} from 'shared/helpers/effector';
import {createdNewAgreement} from './ds';
import {createAgreementAddition, updateAgreement} from './update';

sample({
    clock: getAdditionalAgreementForConstructorFx.doneData,
    fn: ({data}) => data?.works?.[0]?.id || '',
    target: $activeAccordion,
});

sample({
    clock: [updateAgreement, createAgreementAddition],
    fn: (ds) => ds.works?.[0]?.id || '',
    target: $activeAccordion,
});

sample({
    clock: createdNewAgreement,
    fn: ({works}) => Object.keys(works)[0],
    target: $activeAccordion,
});

// открытие первой ведомости при переходе из экрана копирования ДС
sample({
    clock: $createAdditionalAgreementData,
    source: $activeAccordion,
    filter: (source, agreement) => source === '' && Object.values(agreement.works).length > 0,
    fn: (_, agreement) => Object.keys(agreement.works)[0] ?? '',
    target: $activeAccordion,
});

sample({
    clock: ConstructorGate.open,
    fn: ({agreementId}) => agreementId || '',
    target: $agreementId,
});

$activeAccordion
    .on(createEmptyVir, forwardPayload())
    .on(addWorkObjectsListToVirs, (state, payload) => payload?.at?.(-1)?.statement?.virId || state);

// открытие - закрытие аккордеона
sample({
    clock: setActiveAccordion,
    source: $activeAccordion,
    fn: (activeSheet, clickedSheet) => (clickedSheet === activeSheet ? '' : clickedSheet),
    target: $activeAccordion,
});

sample({
    clock: [deleteVir, triggerOpeningLastVir],
    source: $createAdditionalAgreementData,
    fn: (da) => Object.keys(da?.works).at(-1) || '',
    target: $activeAccordion,
});

$activeAccordion.reset([resetAdditionalAgreementCreation, createAdditionalAgreementFx.done]);
