import React from 'react'

const CommentIcon = () => {
	return(
		<svg className="icon">
			<use xlinkHref="/img/sprite.svg#comment" />
		</svg>
	)
}
export default CommentIcon

export const CommentAddIcon = () => {
	return(
		<svg className="icon">
			<use xlinkHref="/img/sprite.svg#comment-with-add" />
		</svg>
	)
}