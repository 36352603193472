import React from 'react';
import {TableDataProps} from '@beeline/design-system-react/types/components/Table';

import * as S from './style';

interface TableCellProps extends TableDataProps {
    children?: React.ReactNode;
}

export const TableCell = React.forwardRef<HTMLTableCellElement, TableCellProps>(
    ({children, dense, width,alignRight}, ref) => {
        return (
            <S.CustomTableData dense={dense} width={width} alignRight={alignRight}>
                <div ref={ref}>{children}</div>
            </S.CustomTableData>
        );
    },
);
