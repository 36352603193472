import React from 'react';
import {useGate, useStore} from 'effector-react';
import {
    Table,
    TableBody,
    TableData,
    TableHead,
    TableHeaderData,
    TableRow,
    Typography,
} from '@beeline/design-system-react';

import {TableBodySkeleton} from 'Entities/skeletons/TableBodySkeleton';
import {CommonLayout} from 'shared/ui/CommonLayout';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {formatDate} from 'shared/helpers/formatHelper';
import {BUSINESS_RULES_PAGE_TITLE, COMMON_DESCRIPTION_SUBTITLE} from 'shared/constants';

import * as S from './styles';
import {$businessRulesPageStore, BusinessRulesPageGate} from '../model';

export const WhiteListPage = () => {
    useGate(BusinessRulesPageGate);
    const {whiteList} = useStore($businessRulesPageStore);

    return (
        <CommonLayout>
            <WrapperFlex flexDirection="column" padding="32px" gap="16px">
                <Typography variant="h4">{BUSINESS_RULES_PAGE_TITLE}</Typography>
                <WrapperFlex flexDirection="column" gap="8px">
                    <Typography variant="subtitle2">{COMMON_DESCRIPTION_SUBTITLE}</Typography>
                    <Typography variant="body3">{`Перед отправкой PR в НФС на стороне DMC производится формирование
                        Dataset PR.
                        При этом производится определение значения boolean атрибута APPROVAL_ALLOWED (Y =>
                        автоутверждение разрешено, N => автоутверждение не разрешено):`}</Typography>
                    <S.StyledList>
                        <li>
                            <Typography variant="body2">если</Typography>
                            <S.StyledList>
                                <li>
                                    <Typography variant="body3">
                                        по всем корректным диапазонам заполнены КТ "Интеграция в
                                        сеть. факт" ("CP_Broadcast", атрибут cpDates.fixed)
                                    </Typography>
                                </li>
                            </S.StyledList>
                        </li>
                        <li>
                            <Typography variant="body2">то</Typography>
                            <S.StyledList>
                                <li>
                                    <Typography variant="body3">APPROVAL_ALLOWED = Y</Typography>
                                </li>
                            </S.StyledList>
                        </li>
                        <li>
                            <Typography variant="body2">иначе</Typography>

                            <S.StyledList>
                                <li>
                                    <Typography variant="body2">если</Typography>
                                    <S.StyledList>
                                        <li>
                                            <Typography variant="body3">
                                                во всех диапазонах значения "imProjectGroup"
                                                ("Привязка к ИМ") соответствуют значениям "белого
                                                списка" (см. ниже)
                                            </Typography>
                                        </li>
                                    </S.StyledList>
                                </li>
                                <li>
                                    <Typography variant="body2">то</Typography>

                                    <S.StyledList>
                                        <li>
                                            <Typography variant="body3">
                                                APPROVAL_ALLOWED = Y
                                            </Typography>
                                        </li>
                                    </S.StyledList>
                                </li>
                                <li>
                                    <Typography variant="body2">иначе</Typography>

                                    <S.StyledList>
                                        <li>
                                            <Typography variant="body3">
                                                APPROVAL_ALLOWED = N
                                            </Typography>
                                        </li>
                                    </S.StyledList>
                                </li>
                            </S.StyledList>
                        </li>
                    </S.StyledList>
                </WrapperFlex>
                <WrapperFlex flexDirection="column" gap="8px">
                    <Typography variant="h5">
                        Список меток привязки к проекту ИМ ("белый список")
                    </Typography>
                    <Typography variant="caption">{`Дата актуализации: ${formatDate(
                        whiteList?.updated_at ?? null,
                    )}`}</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeaderData></TableHeaderData>
                                <TableHeaderData>Актуальные метки</TableHeaderData>
                            </TableRow>
                        </TableHead>
                        {!!whiteList ? (
                            <TableBody>
                                {whiteList.value.imProjectGroup.map((item, index) => (
                                    <TableRow key={`${item}_${index}`}>
                                        <TableData>{index + 1}</TableData>
                                        <TableData>{item}</TableData>
                                    </TableRow>
                                ))}
                            </TableBody>
                        ) : (
                            <TableBodySkeleton columns={[{name: 'number'}, {name: 'info'}]} />
                        )}
                    </Table>
                </WrapperFlex>
            </WrapperFlex>
        </CommonLayout>
    );
};
