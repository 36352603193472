export const getNumberMask = (accuracy: number): string => {
    switch (accuracy ?? 2) {
        case 0: {
            return 'numberWithNoComma';
        }
        case 1: {
            return 'numberWithOneComma';
        }
        case 3: {
            return 'numberWithThreeComma';
        }
        case 4: {
            return 'numberWithFourComma';
        }
        default: {
            return 'numberWithComma';
        }
    }
};