import React, {useState, useEffect, useRef} from 'react';
import {ButtonListStyled, ListBlock, ListItem} from './style';
import Button from 'shared/ui/deprecated/Button/Button';

function ButtonList(props) {
    const {
        leftIcon,
        rightIcon,
        onlyIcon,
        title,
        width,
        color,
        disabled,
        size,
        list,
        icon,
        maxHeight,
    } = props;
    const [active, setActive] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setActive(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });
    return (
        <ButtonListStyled {...props} active={active} ref={ref}>
            <Button
                id="menuBtn"
                color={color}
                size={size}
                leftIcon={leftIcon}
                rightIcon={rightIcon}
                onlyIcon={onlyIcon}
                disabled={disabled}
                onClick={() => setActive(!active)}
            >
                {title} {icon}
            </Button>
            <ListBlock width={width} maxHeight={maxHeight}>
                {list.map(({item, action, ...rest}, index) => (
                    <ListItem
                        id={`menuItemBtn_${index}`}
                        key={index}
                        {...rest}
                        onClick={() => {
                            action && action();
                            setActive(false);
                        }}
                    >
                        {item}
                    </ListItem>
                ))}
            </ListBlock>
        </ButtonListStyled>
    );
}

export default ButtonList;
