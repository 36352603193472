import {useStore} from 'effector-react';
import {Icon, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {formatNumberToDecimalWithComma} from 'shared/helpers/formatHelper';

import * as S from './style';
import {coefficientsPage} from '../../../model';
import {$decreasingCoefficientTab} from '../../../model/decreasingCoefficientsTab';
import {hasDismantlingCoefficients} from '../../../../../shared/helpers/coefficients';

export function FormulaCalculationBlock() {
    const {
        coefficients: {remoteTerritory, ams, foundation},
        branchName,
        formula,
        mainCoefficient,
    } = useStore(coefficientsPage);
    const {selectedDecreasingCoefficients} = useStore($decreasingCoefficientTab);
    const showAmsAndFoundation = !hasDismantlingCoefficients(selectedDecreasingCoefficients);
    return (
        <>
            {remoteTerritory && (
                <WrapperFlex flexDirection="column">
                    <Typography variant="body3" className="inactiveText">
                        Удалённая территория
                    </Typography>
                    <Typography variant="body2">
                        {remoteTerritory === 1 && branchName === '-'
                            ? '—'
                            : `${formatNumberToDecimalWithComma(remoteTerritory)} (${branchName})`}
                    </Typography>
                </WrapperFlex>
            )}
            {showAmsAndFoundation && ams && (
                <WrapperFlex flexDirection="column">
                    <Typography variant="body3" className="inactiveText">
                        АМС
                    </Typography>
                    <Typography variant="body2">{formatNumberToDecimalWithComma(ams)} </Typography>
                </WrapperFlex>
            )}
            {showAmsAndFoundation && foundation && (
                <WrapperFlex flexDirection="column">
                    <Typography variant="body3" className="inactiveText">
                        Фундамент
                    </Typography>
                    <Typography variant="body2">
                        {formatNumberToDecimalWithComma(foundation)}
                    </Typography>
                </WrapperFlex>
            )}
            <S.IconCoefficientBlock>
                <Icon iconName={Icons.InfoCircled} color="blue" contained />
                <WrapperFlex flexDirection="column">
                    <Typography variant="body3" className="inactiveText">
                        Расчёт коэффициента
                    </Typography>
                    <Typography variant="body2">
                        {`${formula} = ${formatNumberToDecimalWithComma(mainCoefficient)}`}
                    </Typography>
                </WrapperFlex>
            </S.IconCoefficientBlock>
        </>
    );
}
