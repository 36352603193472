import styled, { css } from "styled-components";

const baseSearchMixin = () => css`
	--dclosebtn:none;
	position:relative;
	--tclosebtn:12px;
	--rclosebtn:16px;
	--pclosebtn:absolute;
	--tsearchbtn:12px;
	input{
		display: block;
		border-radius: 12px;
		border-width: 1px;
		border-color: transparent;
		padding-left: 56px;
		padding-top: 12px;
		padding-bottom: 12px;
		padding-right: 12px;
		background-color: var(--light-control);
		width: 100%;
		transition-duration: 0.4s;
		font-size: 17px;
		line-height: calc(22 / 17);
		outline: none;
		&:focus{
			background-color:#ffffff;
			border-color:#202123;
			padding-right:56px;
		}
		// &:focus + button{
		// 	--dclosebtn:block;
		// }
	}
	&:hover{
		input{
			border-color:#202123;
		}
	}
`;
const Filter = () => css`
	--tfilterbtn:12px;
	--rfilterbtn:16px;
	--pfilterbtn:absolute;
	--rclosebtn:56px;
	input{
		&:focus{
			padding-right:96px;
		}
	}
`

const Active = () => css`
--dclosebtn:block;
input{
	padding-right:56px;
}
`

const ActiveFilter = () => css`
--dclosebtn:block;
input{
	padding-right:96px;
}
`
const Small = () => css`
input{
	padding-top:8px;
	padding-bottom:8px;
}
--tsearchbtn:8px;
--tclosebtn:8px;
--tfilterbtn:8px !important;
`
export const searchMixin = (props) => css`
	${baseSearchMixin()}
	${props.small && Small}
	${props.active && Active}
	${props.filter && Filter}
	${props.filter && props.active && ActiveFilter}
`;

export const SearchStyled = styled.div`
	${(props) => searchMixin(props)}
`;