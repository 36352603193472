import React from 'react';
import {useUnit} from 'effector-react';
import {Snackbar} from '@beeline/design-system-react';

import {$snackbarMessage, snackbarWasReset} from '../model';

export const SnackbarNotification = () => {
    const [snackbarMessage] = useUnit([$snackbarMessage]);
    return (
        <Snackbar
            open={!!snackbarMessage}
            message={snackbarMessage}
            horizontal="center"
            delay={10000}
            onClose={snackbarWasReset}
        />
    );
};
 