import React from 'react';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import {Label, Skeleton} from '@beeline/design-system-react';
import {AllStatuses} from '@beeline/design-system-react/types/types/status';

import {EditInfo, EditLink} from 'pages/DSPage/ui/DSPageTitle/style';
import {WorkflowButtonsBlock} from 'widgets/WorkflowButtonsBlock';
import Breadcrumb from 'shared/ui/deprecated/Breadcrumb/Breadcrumb';
import BreadcrumbItem from 'shared/ui/deprecated/Breadcrumb/BreadcrumbItem';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import HeaderText from 'shared/ui/deprecated/HeaderText/HeaderText';
import {labelVariant} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {NonMvpItem} from 'shared/styles/commonStyle';
import {IStatus} from 'shared/types/statusTypes';
import {IEmployeeInfo} from 'shared/types/userTypes';
import {formatDateHours} from 'shared/helpers/formatHelper';
import {TBaseStation} from 'shared/types/baseStationsTypes';

import {
    $workflowButtonsStore,
    handleAcceptWorkflowBtn, handleCancelWorkflowBtn,
    handleWorkflowBtn,
    sendComment,
} from '../../model';

interface JobViewPageTitleProps {
    status?: IStatus;
    baseStation?: TBaseStation;
    jobNumber?: string;
    contractorName?: string;
    editor?: IEmployeeInfo;
    updatedAt?: string;
}

export const JobViewPageTitle = ({
    status,
    baseStation,
    jobNumber,
    contractorName,
    editor,
    updatedAt,
}: JobViewPageTitleProps) => {
    const {availableTasks, buttonIsLoading, workflowButtons} = useStore($workflowButtonsStore);
    const buttonsStatus = availableTasks[0]?.status ?? 0;
    const substageType = workflowButtons?.data.substages_type ?? 0;
    const isGroup = workflowButtons?.data.is_group ?? false;

    const handleAcceptWorkflowButton = () => {
        handleAcceptWorkflowBtn(availableTasks[0].id ?? '');
    };
    const handleCancelWorkflowButton = () => {
        handleCancelWorkflowBtn(availableTasks[0].id ?? '');
    };
    const handleWorkflowButton = (index: number) => {
        if (workflowButtons?.data.actions[index].is_positive) {
            handleWorkflowBtn(index);
        } else {
            sendComment(index);
        }
    };

    return (
        <WrapperFlex justifyContent="space-between" alignItems="flex-start">
            <div className="me-3 flex-grow-1">
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/projects">Проекты</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        {baseStation ? (
                            <Link
                                to={`/projects/${baseStation.id}`}
                            >{`Проект ${baseStation.project_number}`}</Link>
                        ) : (
                            <Skeleton variant="line" width={100} height={18} />
                        )}
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        {jobNumber ? (
                            `Задание ${jobNumber}`
                        ) : (
                            <Skeleton variant="line" width={100} height={18} />
                        )}
                    </BreadcrumbItem>
                </Breadcrumb>
                <WrapperOffset offsetTop={12}>
                    <WrapperFlex alignItems="center">
                        <HeaderText className="me-2" component="h4">
                            {jobNumber ? (
                                `Задание ${jobNumber} ${contractorName}`
                            ) : (
                                <Skeleton variant="square" width={300} height={32} />
                            )}
                        </HeaderText>
                        {status ? (
                            <Label
                                title={status.name}
                                type={labelVariant(status.token) as AllStatuses}
                            />
                        ) : (
                            <Skeleton variant="square" width={120} height={32} />
                        )}
                    </WrapperFlex>
                </WrapperOffset>
                <WrapperOffset offsetTop={12}>
                    <WrapperFlex alignItems="center">
                        <EditInfo className="me-1">
                            {editor && updatedAt ? (
                                `${editor.short} редактировал ${formatDateHours(updatedAt)}`
                            ) : (
                                <Skeleton variant="line" width={180} height={16} />
                            )}
                        </EditInfo>
                        <NonMvpItem>
                            <EditLink disabled to="#">
                                История изменений
                            </EditLink>
                        </NonMvpItem>
                    </WrapperFlex>
                </WrapperOffset>
            </div>
            <WorkflowButtonsBlock
                workflowButtons={workflowButtons}
                buttonIsLoading={buttonIsLoading}
                status={buttonsStatus}
                substageType={substageType}
                isGroup={isGroup}
                handleWorkflowButton={handleWorkflowButton}
                handleAcceptWorkflowButton={handleAcceptWorkflowButton}
                handleCancelTask={handleCancelWorkflowButton}
            />
        </WrapperFlex>
    );
};
