import {v4 as uuidv4} from 'uuid';
import {
    $customCoefficients,
    $newCoefficient,
    addCoefficient,
    changeNewCoefficient,
    deleteCoefficient,
    updateExistingCoefficient,
} from './index';
import {forwardPayload} from 'shared/helpers/effector';
import {formattedStringToNumber} from 'shared/helpers/formatHelper';
import {$tpiToConfigCoefficient, ApplyCoefficientsGate} from '../index';
import {combine, sample} from 'effector';

$customCoefficients
    .on(addCoefficient, (state) => [
        ...state,
        {
            id: `front -${uuidv4()}`,
            is_manual: true,
            coefficient: null,
        },
    ])
    .on(updateExistingCoefficient, (state, payload) => {
        const index = state.findIndex((el) => el.id === payload.id);
        return [
            ...state.slice(0, index),
            {
                id: payload.id,
                coefficient: payload.newValue ? formattedStringToNumber(payload.newValue) : null,
                isManual: true,
            },
            ...state.slice(index + 1),
        ];
    })
    .on(deleteCoefficient, (state, payload) => state.filter((el) => el.id !== payload));

sample({
    clock: ApplyCoefficientsGate.open,
    source: combine({selectedSpec: $tpiToConfigCoefficient, customs: $customCoefficients}),
    fn: ({selectedSpec, customs}) => {
        const coefficients =
            selectedSpec?.spec?.work_coefficients?.filter((el) => el.is_manual) || [];
        return [
            ...coefficients,
            {
                id: `front -${uuidv4()}`,
                is_manual: true,
                coefficient: null,
            },
        ];
    },
    target: $customCoefficients,
});

$newCoefficient.on(changeNewCoefficient, forwardPayload()).reset(addCoefficient);
