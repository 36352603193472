import {bindAllMethods} from 'shared/helpers/binder';
import {axiosInstance} from 'shared/api/api';
import {
    AdditionalAgreementQueryParams,
    AdditionalAgreementResponse,
    ITaskResponse,
    UpdateTaskProps,
} from '../types';
import {TTaskList} from 'shared/types/tasksTypes';

export const additionalAgreementService = bindAllMethods({
    async getAdditionalAgreement(
        params: AdditionalAgreementQueryParams,
    ): Promise<AdditionalAgreementResponse> {
        return axiosInstance.get(`/DS/agreements/${params.id}/`);
    },

    async getProject(params: AdditionalAgreementQueryParams): Promise<AdditionalAgreementResponse> {
        return axiosInstance.get('/projects/projects/', {params});
    },

    async getTasksList(params: AdditionalAgreementQueryParams) {
        return axiosInstance.get<TTaskList>('/processes/tasks/task-id/', {
            params: {
                instance_id: params.id,
            },
        });
    },

    async getTask(id: string): Promise<ITaskResponse> {
        return axiosInstance.get(`/processes/tasks/${id}/`);
    },

    async createTask(params: AdditionalAgreementQueryParams): Promise<AdditionalAgreementResponse> {
        return axiosInstance.get('/projects/projects', {params});
    },

    async updateTask({id, actionId}: UpdateTaskProps): Promise<any> {
        return axiosInstance.put(
            `/processes/tasks/${id}/update/`,
            {
                task_action_id: actionId,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
    },

    async acceptTask(id: string): Promise<any> {
        return axiosInstance.put(`/processes/tasks/${id}/accept/`);
    },
    async cancelTask(id: string): Promise<any> {
        return axiosInstance.put(`/processes/tasks/${id}/cancel/`);
    },
});
