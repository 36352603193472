import {combine, sample} from 'effector';
import {
    $selectedContract,
    $selectedProject,
    $selectedTpi,
    $selectedWorkObject,
    $selectedWorkType,
    selectContract,
    selectProject,
    selectTpi,
    selectWorkObject,
    selectWorkType,
} from 'pages/VirStart/model';
import {
    removeFromLocalStorageFx,
    setToLocalStorageFx,
} from 'Features/storeToLocalStorage';
import {resetAdditionalAgreementCreation} from './stores';
import {$selectedTpis} from 'pages/VirSelect/model/SelectedTpisTab';

sample({
    clock: selectProject,
    fn: (project) => ({name: 'selectedProject', value: project}),
    target: setToLocalStorageFx,
});

sample({
    clock: resetAdditionalAgreementCreation,
    fn: () => ({name: 'selectedProject'}),
    target: removeFromLocalStorageFx,
});

sample({
    clock: [selectProject, selectContract, selectTpi, selectWorkType, selectWorkObject],
    source: combine({
        project: $selectedProject,
        tpi: $selectedTpi,
        contract: $selectedContract,
        workType: $selectedWorkType,
        workObject: $selectedWorkObject,
        selectedTpis: $selectedTpis,
    }),
    fn: ({project, contract, workType, tpi, workObject, selectedTpis}) => {
        const data = {
            project: project,
            contract: contract,
            tpi: tpi,
            work_type: workType,
            selectedTpis,
            workObject: workObject,
        };
        return {name: 'virStartData', value: data};
    },
    target: setToLocalStorageFx,
});
