import {combine, createDomain, createEffect, createEvent, forward, sample} from 'effector';
import {createGate} from 'effector-react';
import {defaultColumns, getBaseColumnFilter} from 'pages/ProjectPage/helpers';
import {SearchCategoriesType, UpdateColumnItemType} from 'pages/ProjectPage/model/types';
import {ProjectResponseType} from 'shared/services/types/types';
import {getBlobProjectList, getProjectList} from 'shared/services/projects.service';
import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';
import {ProjectStatusType, StatusSelectType} from 'shared/types/statusSelectTypes';
import {TypeSelectType} from 'shared/types/typeSelectTypes';
import {YearSelectType} from 'shared/types/yearSelectTypes';
import {ProjectsColumnStateType, TProjectParams} from 'shared/types/projectTypes';
import {$userStore, setColumnSettings} from 'shared/model/user';
import {formatDate} from 'shared/helpers/formatHelper';

export const projectsPageDomain = createDomain();
export const projectsPageGate = createGate();

// events
export const getProjectsData = createEvent<Record<string, unknown>>();
const resetProjectList = createEvent();
export const setPage = projectsPageDomain.createEvent<number>();
export const setPageSize = projectsPageDomain.createEvent<number>();
export const resetPage = projectsPageDomain.createEvent();
export const setStatusesProject = projectsPageDomain.createEvent<StatusSelectType>();
export const setTypesProject = projectsPageDomain.createEvent<Array<TypeSelectType>>();
export const setYear = projectsPageDomain.createEvent<Array<YearSelectType>>();
export const setSearch = projectsPageDomain.createEvent<string>();
export const setOrdering = projectsPageDomain.createEvent<string>();
export const setYearOrdering = projectsPageDomain.createEvent<string>();
export const setSortParam = projectsPageDomain.createEvent<string>();
export const setSortType = projectsPageDomain.createEvent<boolean>();
export const setSearchValue = projectsPageDomain.createEvent<string>();
export const setSelectedSearchCategory = projectsPageDomain.createEvent<SearchCategoriesType>();
export const handleEnterPress = projectsPageDomain.createEvent();
export const resetALlSearchParameters = projectsPageDomain.createEvent();
export const setProjectsFilters = projectsPageDomain.createEvent<{
    statuses_project?: Array<ProjectStatusType>;
    types_project?: Array<TypeSelectType>;
    year?: Array<YearSelectType>;
}>();
export const resetAllFilters = projectsPageDomain.createEvent();

export const setColumns = projectsPageDomain.createEvent();
export const updateColumns = projectsPageDomain.createEvent();
export const updateColumnItem = projectsPageDomain.createEvent<UpdateColumnItemType>();
export const resetColumnFilter = projectsPageDomain.createEvent();
export const changeProjectListParams = projectsPageDomain.createEvent<Record<string, unknown>>();
export const projectListDownloaded = projectsPageDomain.createEvent();
export const setRegionParam = projectsPageDomain.createEvent<string>();
export const setBranchParam = projectsPageDomain.createEvent<string>();

// effects
export const fetchProjectListFx = createEffect(async (payload) => {
    const result = await getProjectList(payload);
    if (result.status === 200) {
        return result.data;
    }
    resetProjectList();
});

export const downloadProjectListFx = projectsPageDomain.createEffect(
    async (payload: TProjectParams) => {
        try {
            const resultPayload = {
                region: payload.region,
                branch: payload.branch,
                statuses_project: payload.statuses_project,
                types_project: payload.types_project,
                year: payload.year,
                ordering: payload.ordering,
                year_ordering: payload.year_ordering,
                search: payload.search,
            };
            const result = await getBlobProjectList(resultPayload);

            if (result.status === 200) {
                const blob = result.data;
                const fileName = `DMC_Projects_${formatDate(new Date(), 'yyyyMMdd-HHmmss')}`;
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', decodeURIComponent(fileName));
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        } catch (e) {
            console.warn(e);
        }
    },
);

// stores
export const $projectList = projectsPageDomain
    .createStore<ProjectResponseType>({results: []})
    .on(fetchProjectListFx.doneData, forwardPayload())
    .reset(resetProjectList);

export const $sortParam = projectsPageDomain
    .createStore<string>('')
    .on(setSortParam, forwardPayload());

export const $sortType = projectsPageDomain
    .createStore<boolean>(false)
    .on(setSortType, forwardPayload());

export const $projectListColumnFilter = projectsPageDomain
    .createStore<ProjectsColumnStateType>(getBaseColumnFilter())
    .on(updateColumns, (state) => ({
        ...state,
        columns: [...state.editColumns],
    }))
    .on(updateColumnItem, (state, payload) => ({
        ...state,
        editColumns: [
            ...state.editColumns.slice(0, payload.index),
            payload.item,
            ...state.editColumns.slice(payload.index + 1),
        ],
    }))
    .on(resetColumnFilter, () => getBaseColumnFilter());

export const $searchValue = projectsPageDomain
    .createStore<string>('')
    .on(setSearchValue, forwardPayload());

export const $selectedSearchCategory = projectsPageDomain
    .createStore<SearchCategoriesType>({
        title: 'Номер БС',
        type: 'bsNumberOnly',
    })
    .on(setSelectedSearchCategory, forwardPayload());

export const $projectListParams = projectsPageDomain
    .createStore<TProjectParams>({
        page: 1,
        page_size: 10,
        region: '',
        branch: '',
        statuses_project: '',
        types_project: '',
        year: '',
        ordering: '',
        year_ordering: '',
        search: '',
    })
    .on(setPage, (state, payload) => ({...state, page: payload}))
    .on(setPageSize, (state, payload) => ({...state, page_size: payload}))
    .on(setRegionParam, (state, payload) => {
        if (state.region === payload) return undefined;
        return {...state, region: payload};
    })
    .on(setBranchParam, (state, payload) => ({...state, branch: payload}))
    .on(setProjectsFilters, (state, payload) => {
        const result = {...state};
        result.page = 1;
        if (payload.year) {
            let year = '';
            payload.year.forEach((item, index) => {
                if (index !== payload.year?.length ?? 0 - 1) {
                    if (item.year === 'Не указан') {
                        year = `${year}None,`;
                    } else {
                        year = `${year + item.year},`;
                    }
                } else if (item.year === 'Не указан') {
                    year = `${year}None`;
                } else {
                    year += item.year;
                }
            });
            result.year = year;
        }
        if (payload.statuses_project) {
            result.statuses_project = payload.statuses_project.map((item) => item.name).join(',');
        }
        if (payload.types_project) {
            result.types_project = payload.types_project.map((item) => item.name).join(',');
        }
        return result;
    })
    .on(setStatusesProject, (state, payload) => {
        return {
            ...state,
            statuses_project: payload.map((item) => item.name).join(','),
        };
    })
    .on(setTypesProject, (state, payload) => ({
        ...state,
        types_project: payload.map((item) => item.name).join(','),
    }))
    .on(setYear, (state, payload) => {
        const result = {...state};
        let year = '';
        payload.forEach((item, index) => {
            if (index !== payload.length - 1) {
                if (item.year === 'Не указан') {
                    year = `${year}None,`;
                } else {
                    year = `${year + item.year},`;
                }
            } else if (item.year === 'Не указан') {
                year = `${year}None`;
            } else {
                year += item.year;
            }
        });
        result.year = year;
        return result;
    })
    .on(setOrdering, (state, payload) => ({...state, page: 1, ordering: payload}))
    .on(setYearOrdering, (state, payload) => ({...state, page: 1, year_ordering: payload}))
    .on(changeProjectListParams, (state, payload) => {
        const result = {...state};
        for (let key in payload) {
            if (key in result) {
                result[key] = payload[key];
            }
        }
        return result;
    })
    .on(resetALlSearchParameters, (state, _) => {
        return {...state, search: '', page: 1};
    })
    .reset(resetAllFilters);

const $downloadBtnDisabled = projectsPageDomain.createStore<boolean>(false);

// combines
export const $projectsPage = combine({
    projectList: $projectList,
    searchValue: $searchValue,
    selectedSearchCategory: $selectedSearchCategory,
    projectListParams: $projectListParams,
    isProjectListLoading: fetchProjectListFx.pending,
    sortParam: $sortParam,
    sortType: $sortType,
    isLoading: fetchProjectListFx.pending,
    downloadBtnDisabled: $downloadBtnDisabled,
});

const $tableSortParam = combine({
    sortParam: $sortParam,
    sortType: $sortType,
});

//gate
forward({from: projectsPageGate.open, to: fetchProjectListFx});

forward({from: [updateColumns, updateColumnItem, resetColumnFilter], to: setColumns});

// отправка фильтров на бэк
sample({
    clock: updateColumns,
    source: combine({
        projectListColumnFilter: $projectListColumnFilter,
        userStore: $userStore,
    }),
    fn: ({userStore, projectListColumnFilter}) => ({
        ...userStore.extend,
        projectsPage: {
            columns: projectListColumnFilter.editColumns,
        },
    }),
    target: setColumnSettings,
});

// заполнение фильтров с бэка
sample({
    clock: [$userStore, projectsPageGate.open],
    source: $userStore,
    filter: (source) => (source?.extend?.projectsPage?.columns?.length ?? 0) > 0,
    fn: (source) => ({
        columns: source.extend.projectsPage?.columns,
        editColumns: source.extend.projectsPage?.columns,
    }),
    target: $projectListColumnFilter,
});

// сброс фильтров
sample({
    clock: resetColumnFilter,
    source: $userStore,
    fn: (source) => ({
        ...source.extend,
        projectsPage: {
            columns: defaultColumns,
        },
    }),
    target: setColumnSettings,
});

// refetch data, when anything has changed in $projectListParams
sample({
    clock: $projectListParams,
    fn: (params) => {
        const result = {...params};
        Object.keys(result).forEach((key) => (result[key] === null ? delete result[key] : {}));
        return result;
    },
    target: getProjectsData,
});

sample({
    clock: getProjectsData,
    target: fetchProjectListFx,
});

//change $ordering when sortParam is not "year"
sample({
    source: $tableSortParam,
    fn: ({sortParam, sortType}) => {
        if (!sortParam.includes('year')) {
            return sortType === true ? sortParam : `-${sortParam}`;
        }
        return '';
    },
    target: setOrdering,
});

//change $yearOrdering when sortParam is "year" and clear $ordering
sample({
    source: $tableSortParam,
    fn: ({sortParam, sortType}) => {
        if (sortParam.includes('year')) {
            return sortType === true ? sortParam : `-${sortParam}`;
        }
        return ``;
    },
    target: setYearOrdering,
});

sample({
    source: combine({
        searchValue: $searchValue,
        selectedSearchCategory: $selectedSearchCategory,
    }),
    clock: handleEnterPress,
    fn: ({searchValue, selectedSearchCategory}) => {
        const result: Record<string, unknown> = {};
        result.page = 1;
        const {type} = selectedSearchCategory;
        if (searchValue) {
            switch (type) {
                case 'bsNumberOnly': {
                    result.search = `bs:${searchValue}`;
                    break;
                }
                case 'projectOnly': {
                    result.search = `proj:${searchValue}`;
                    break;
                }
                case 'bsGfkOnly': {
                    result.search = `gfk:${searchValue}`;
                    break;
                }
                case 'mainGPO': {
                    result.search = `gpo:${searchValue}`;
                    break;
                }
                default:
                    result.search = '';
                    break;
            }
        } else {
            result.search = '';
        }
        return result;
    },
    target: changeProjectListParams,
});

sample({
    clock: projectListDownloaded,
    source: $projectListParams,
    target: downloadProjectListFx,
});

// disable кнопки "скачать все проекты"
sample({
    source: combine({
        isDownload: downloadProjectListFx.pending,
        count: $projectList.map((state) => state.count),
    }),
    fn: (source) => source.isDownload || source.count === 0,
    target: $downloadBtnDisabled,
});

forward({from: projectsPageGate.close, to: resetPage});

resetDomainStoresByEvents(projectsPageDomain, resetPage);
