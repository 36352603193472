import React from 'react';
import {PopoverHelper, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {IWorkCoefficient} from 'pages/DSPage/types';
import {COEFFICIENT_TYPE_ENUM} from 'pages/ApplyCoefficientsPage/types';
import {formatNumberToDecimalWithComma} from 'shared/helpers/formatHelper';
import {hasDismantlingCoefficients} from 'shared/helpers/coefficients';

import * as S from './style';

interface CoefficientUnitProps {
    coefficients: IWorkCoefficient[];
    territoryCoefficient?: number;
    amsCoefficient?: number;
    foundationCoefficient?: number;
    totalCoefficient: number;
    position?: string;
    className?: string;
}

export const CoefficientUnit = ({
    coefficients,
    territoryCoefficient,
    amsCoefficient,
    foundationCoefficient,
    totalCoefficient,
    position,
    className,
}: CoefficientUnitProps) => {
    return (
        <S.RatioText position={position}>
            <S.CoefficientWrapper>
                {(coefficients.length !== 0 ||
                    territoryCoefficient !== 1 ||
                    amsCoefficient ||
                    foundationCoefficient) && (
                    <PopoverHelper
                        body={
                            <CoefficientPopoverMessage
                                coefficients={coefficients}
                                territoryCoefficient={territoryCoefficient}
                                totalCoefficient={totalCoefficient}
                                amsCoefficient={amsCoefficient}
                                foundationCoefficient={foundationCoefficient}
                            />
                        }
                    >
                        <S.PointerIcon
                            className="inactiveText"
                            iconName={Icons.InfoCircled}
                            size="small"
                        />
                    </PopoverHelper>
                )}
            </S.CoefficientWrapper>
            <Typography variant="body3" className={className}>
                {formatNumberToDecimalWithComma(totalCoefficient)}
            </Typography>
        </S.RatioText>
    );
};

const CoefficientPopoverMessage = ({
    coefficients,
    territoryCoefficient,
    amsCoefficient,
    foundationCoefficient,
    totalCoefficient,
}: CoefficientUnitProps) => {
    const showAmsAndFoundation = !hasDismantlingCoefficients(coefficients);
    const sumCoefficientsArray: number[] = [];
    const multiplicationCoefficientsArray: number[] = [];

    showAmsAndFoundation && amsCoefficient && multiplicationCoefficientsArray.push(amsCoefficient);
    showAmsAndFoundation &&
        foundationCoefficient &&
        multiplicationCoefficientsArray.push(foundationCoefficient);

    coefficients.forEach((item) =>
        (item.type === COEFFICIENT_TYPE_ENUM.IncreasingBySum
            ? sumCoefficientsArray
            : multiplicationCoefficientsArray
        ).push(item.coefficient),
    );

    let coefficientSum: string = '';
    switch (sumCoefficientsArray.length) {
        case 0: {
            break;
        }
        case 1: {
            coefficientSum = ` x ${sumCoefficientsArray[0]}`;
            break;
        }
        default: {
            const result = sumCoefficientsArray.join(' + ');
            coefficientSum = ` x (${result} + ${sumCoefficientsArray.length > 1 ? '1' : ''} - ${
                sumCoefficientsArray.length
            })`;
            break;
        }
    }
    const coefficientMultiplication: string =
        multiplicationCoefficientsArray.length > 0
            ? ` x ${multiplicationCoefficientsArray.join(' x ')}`
            : '';

    const title = `k = ${
        territoryCoefficient ?? 1
    }${coefficientMultiplication}${coefficientSum} = ${totalCoefficient}`;

    return (
        <S.PopoverWrapper>
            <Typography variant="body3">{title}</Typography>
            <Typography variant="body3">
                {territoryCoefficient} - Коэффициент удаленной территории
            </Typography>
            {amsCoefficient && showAmsAndFoundation && (
                <Typography variant="body3">
                    {amsCoefficient} - Коэффициент на изготовление АМС
                </Typography>
            )}
            {foundationCoefficient && showAmsAndFoundation && (
                <Typography variant="body3">
                    {foundationCoefficient} - Коэффициент на изготовление фундамента
                </Typography>
            )}

            {coefficients.map((item) => (
                <Typography key={item.id} variant="body3">
                    {item.coefficient} - {item.is_manual ? 'ручной коэффициент' : item.name}
                </Typography>
            ))}
        </S.PopoverWrapper>
    );
};
