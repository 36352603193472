import React from 'react';
import {TableData, Typography} from '@beeline/design-system-react';
import {WorkObjectNameRow} from 'Entities/WorkObjectNameRow/ui/WorkObjectNameRow';
import {Summary} from 'pages/VirConstructor/Summary/Summary';
import {IStatus} from 'shared/types/statusTypes';
import {ICompletedWorkObject} from 'shared/types/completedWorksTypes';
import {createAgreementWorkObjectName} from 'shared/helpers/additionalAgreements';

import {SpecificationRow} from './SpecificationRow/SpecificationRow';

interface WorkObjectProps {
    touchedConstructor: boolean;
    workId: string;
    workObject: ICompletedWorkObject;
    status: IStatus;
}

export const WorkObject = ({workId, workObject, touchedConstructor, status}: WorkObjectProps) => {
    const {
        gfk,
        name,
        object_type,
        specifications: {
            ds: workSpecifications,
            vvr_current: completedWorkSpecifications,
            vvr_other: finishedWorkSpecifications,
        },
    } = workObject;
    if (!workSpecifications) return null;

    const sum = completedWorkSpecifications.reduce(
        (previousValue, currentValue) => (+currentValue.sum ?? 0) + previousValue,
        0,
    );
    const taxSum = completedWorkSpecifications.reduce(
        (previousValue, currentValue) => (+currentValue.sum_tax ?? 0) + previousValue,
        0,
    );

    const unclosedSum = workSpecifications.reduce(
        (previousValue, currentValue) => (+currentValue.sum ?? 0) + previousValue,
        0,
    );
    const unclosedTaxSum = workSpecifications.reduce(
        (previousValue, currentValue) => (+currentValue.sum_tax ?? 0) + previousValue,
        0,
    );

    const totalSum = sum + taxSum;
    const unclosedTotalSum = unclosedSum + unclosedTaxSum;
    const completedSpecsLength = completedWorkSpecifications.length;
    const initialSpecsLength = workSpecifications.length;
    const objectTitle = createAgreementWorkObjectName(workObject);
    return (
        <>
            <WorkObjectNameRow>
                <TableData colSpan={11}>
                    <Typography variant="subtitle3">{objectTitle}</Typography>
                </TableData>
            </WorkObjectNameRow>
            {completedWorkSpecifications.map((specification, index) => (
                <SpecificationRow
                    key={`${specification.id}${specification.result ?? ''}`}
                    specification={specification}
                    mode="current"
                    status={status}
                    index={index + 1}
                />
            ))}
            {completedSpecsLength > 0 && (
                <Summary
                    agreementOnRevision={false}
                    sums={{
                        sum: sum,
                        sum_tax: taxSum,
                        total_sum: totalSum,
                    }}
                />
            )}
            {workSpecifications.map((specification) => (
                <SpecificationRow
                    key={`${specification.id}${specification.result ?? ''}`}
                    specification={specification}
                    mode="available"
                    status={status}
                    inactive
                />
            ))}
            {initialSpecsLength > 0 && (
                <Summary
                    agreementOnRevision={false}
                    sums={{
                        sum: unclosedSum,
                        sum_tax: unclosedTaxSum,
                        total_sum: unclosedTotalSum,
                    }}
                    unclosed
                />
            )}
            {finishedWorkSpecifications.map((specification) => (
                <SpecificationRow
                    key={`${specification.id}${specification.result ?? ''}_finished`}
                    specification={specification}
                    mode="closed"
                    status={status}
                    inactive
                />
            ))}
        </>
    );
};
