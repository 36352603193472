import {combine, createDomain, sample, split} from 'effector';
import {createGate} from 'effector-react';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {fetchContract} from 'shared/services/contracts.service';
import {IContract} from 'shared/types/contractsTypes';
import {fetchTpiFile} from 'shared/services/files.service';
import {downloadBlobFile} from 'shared/helpers/blob';
import {ITpiFileParams} from 'shared/services/types/paramsTypes';
import {resetDomainStoresByEvents} from 'shared/helpers/effector';
import {$userActions} from 'shared/model/user';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions} from 'shared/const/actions';

export const ContractViewPageDomain = createDomain();
export const ContractViewPageGate = createGate<string>();

// effects
export const getContractFx = ContractViewPageDomain.createEffect(async (id: string) => {
    try {
        const result = await fetchContract(id);
        return result.data;
    } catch (e) {
        console.warn(e);
    }
});
export const downloadTpiFx = ContractViewPageDomain.createEffect(async (data: ITpiFileParams) => {
    try {
        const result = await fetchTpiFile(data);
        if (result.status === 200) {
            const blob = new Blob([result.data]);
            const fileName = decodeURIComponent(
                result.request
                    .getResponseHeader('content-disposition')
                    .split(`filename*=utf-8''`)[1] ?? 'list',
            );
            downloadBlobFile(blob, fileName);
        }
        return data.tpi_id;
    } catch (e) {
        console.warn(e);
        return data.tpi_id;
    }
});
// events
export const pageIdChanged = ContractViewPageDomain.createEvent<string>();
export const userRedirected = ContractViewPageDomain.createEvent();
export const tableRowToggled = ContractViewPageDomain.createEvent<string>();
export const tpiFileDownloaded = ContractViewPageDomain.createEvent<ITpiFileParams>();

// stores
const $contractData = ContractViewPageDomain.createStore<IContract | null>(null);
const $pageId = ContractViewPageDomain.createStore('');

export const $expandedRowId = ContractViewPageDomain.createStore('');
export const $loadingTpiIdList = ContractViewPageDomain.createStore<string[]>([]);
export const $externalLinkButtonShowed = ContractViewPageDomain.createStore<boolean>(false);
export const $contractViewPageStore = combine({
    contractData: $contractData,
    loadingTpiIdList: $loadingTpiIdList,
    isLoading: getContractFx.pending,
});

split({
    source: ContractViewPageGate.state,
    match: {
        loadPage: (id) => typeof id === 'string',
        goBack: (id) => typeof id === 'undefined',
    },
    cases: {
        loadPage: pageIdChanged,
        goBack: userRedirected,
    },
});

sample({
    clock: pageIdChanged,
    target: $pageId,
});

sample({
    clock: $pageId,
    source: combine({
        id: $pageId,
        gate: ContractViewPageGate.status,
    }),
    filter: ({gate}) => gate,
    fn: ({id}) => id,
    target: getContractFx,
});

sample({
    clock: userRedirected,
    fn: () => ({to: -1}),
    target: navigationInvoked,
});

sample({
    clock: getContractFx.doneData,
    fn: (data) => data ?? null,
    target: $contractData,
});

sample({
    clock: tpiFileDownloaded,
    source: $loadingTpiIdList,
    fn: (list, {tpi_id}) => [...list, tpi_id],
    target: $loadingTpiIdList,
});

sample({
    clock: tpiFileDownloaded,
    target: downloadTpiFx,
});

sample({
    clock: downloadTpiFx.doneData,
    source: $loadingTpiIdList,
    fn: (list, id) => list.filter((item) => item !== id),
    target: $loadingTpiIdList,
});

// работа сворачивания/разворачивания таблицы
$expandedRowId.on(tableRowToggled, (state, payload) => (state === payload ? '' : payload));

// проверка экшна кнопки "Перейти в БАЗИС"
sample({
    source: $userActions,
    fn: (source) => userHasPermission(EAccessActions.ContractListLinkShowing, source),
    target: $externalLinkButtonShowed,
})

resetDomainStoresByEvents(ContractViewPageDomain, ContractViewPageGate.close);
