import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Button, Typography} from '@beeline/design-system-react';
import {addWork, $createAdditionalAgreementData, $agreementType} from 'processes/contructor';
import {BodyContent, MainWrapper} from 'components/PageWraps';

import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {TabsBtn} from 'shared/ui/deprecated/Tabs/Tabs';
import {SelectedTpisTab} from 'pages/VirSelect/ui/SelectedTpisTab';
import {AllTpisTab} from './AllTpicsTab';
import {$activeTab, SelectTpisGate, setActiveTab} from '../model/SelectTpisPage';
import {AgreementConstructorHeader} from '../../AddWorkObjects/ui/Header';
import {SearchTpisInput} from './SearchPointsInput';
import * as S from './style';
import {SELECT_TPIS_ACTIVE_TAB} from '../types';

export default function VirSelectPage() {
    const navigate = useNavigate();
    useGate(SelectTpisGate);
    const activeTab = useStore($activeTab);
    const agreement = useStore($createAdditionalAgreementData);
    const agreementMode = useStore($agreementType);
    const {id: agreementId} = agreement;
    const handleAddTpisToConstructor = () => {
        if (agreementId) {
            navigate(`/additional-agreement/${agreementId}/constructor`);
        } else {
            navigate(`/additional-agreement/constructor`);
        }
        addWork();
    };

    return (
        <MainWrapper>
            <AgreementConstructorHeader agreement={agreement} agreementType={agreementMode} />
            <BodyContent>
                <WrapperOffset offset="32" className="w-100">
                    <S.VirSelectBlock>
                        <Typography variant="h4">Выберите пункты работ из ТЦП</Typography>
                        <SearchTpisInput />
                        <S.TabPanel>
                            <TabsBtn
                                id="allTpiItemsTab"
                                title="Все пункты"
                                value={SELECT_TPIS_ACTIVE_TAB.ALL_TPIS}
                                activeTab={activeTab}
                                onClick={setActiveTab}
                            />
                            <TabsBtn
                                id="selectedTpiItemsTab"
                                title="Выбранные"
                                value={SELECT_TPIS_ACTIVE_TAB.SELECTED_TPIS}
                                activeTab={activeTab}
                                onClick={setActiveTab}
                            />
                        </S.TabPanel>
                        <S.VirSelectBody>
                            <AllTpisTab />
                            <SelectedTpisTab />
                        </S.VirSelectBody>
                        <S.ButtonWrapper>
                            <Button
                                id="backToConstructorStart"
                                variant="outlined"
                                onClick={() => navigate(-1)}
                                size="medium"
                            >
                                Назад
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleAddTpisToConstructor}
                                size="medium"
                                id="addSelectedTpiItems"
                            >
                                Добавить выбранное
                            </Button>
                        </S.ButtonWrapper>
                    </S.VirSelectBlock>
                </WrapperOffset>
            </BodyContent>
        </MainWrapper>
    );
}
