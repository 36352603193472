import React from 'react';
import {Typography} from '@beeline/design-system-react';

import * as S from './style';

interface InformationalUnitProps {
    title: string;
    gridArea?: string;
    children: React.ReactNode;
}

export const InformationalUnit = ({title, gridArea, children}: InformationalUnitProps) => {
    const childrenType = typeof children;
    return (
        <S.StyledWrapper gridArea={gridArea}>
            <Typography variant="body3" className="inactiveText">
                {title}
            </Typography>
            {childrenType === 'object' ? (
                children
            ) : (
                <Typography variant="body2">{children}</Typography>
            )}
        </S.StyledWrapper>
    );
};
