import React from 'react';
import {useStore} from 'effector-react';
import {DatePickerRange, Select} from '@beeline/design-system-react';

import {TableSettings} from 'Features/TableSettings/ui/TableSettings';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {$isUserGpo} from 'shared/model/user';

import {
    $columnsStore,
    $ProcessesPageStore,
    resetFilters,
    saveFilters,
    setProcessesColumns,
    setSelectedCompletionDateRange,
    setSelectedCreationDateRange,
    setSelectedDocumentStatusList,
    setSelectedDocumentTypeList,
    setSelectedMainContractorsList,
    setSelectedTaskStatusList,
    setTasksColumns,
    toggleFilterSidesheet,
} from '../../model';

export const ProcessesTableSettings = () => {
    const {
        sidesheetOpened,
        filtersStore: {
            documentTypeList,
            selectedDocumentTypeList,
            selectedStatusList,
            statusList,
            statusSelectDisabled,
            mainContractorsList,
            selectedMainContractorsList,
            taskStatusList,
            selectedTaskStatusList,
            selectedCreationDateRange,
            selectedCompletionDateRange,
        },
        taskParams: {
            filters: {groupByProcesses},
        },
    } = useStore($ProcessesPageStore);
    const {processesColumnsStore, tasksColumnsStore} = useStore($columnsStore);
    const isUserGpo = useStore($isUserGpo);

    const mainContractorSelectVisible = !isUserGpo;

    const handleDocumentChange = (values) => {
        setSelectedDocumentTypeList(values);
    };
    const handleStatusChange = (values) => {
        setSelectedDocumentStatusList(values);
    };
    const handleTaskStatusChange = (values) => {
        setSelectedTaskStatusList(values);
    };
    const handleMainContractorChange = (values) => {
        setSelectedMainContractorsList(values);
    };
    const handleCreationRangeChange = (values) => {
        setSelectedCreationDateRange(values);
    };
    const handleCompletionRangeChange = (values) => {
        setSelectedCompletionDateRange(values);
    };

    return (
        <TableSettings
            active={sidesheetOpened}
            onClose={toggleFilterSidesheet}
            onSave={saveFilters}
            onReset={resetFilters}
            columns={groupByProcesses ? processesColumnsStore : tasksColumnsStore}
            onSaveColumns={groupByProcesses ? setProcessesColumns : setTasksColumns}
        >
            <WrapperFlex flexDirection="column" gap="32px">
                <Select
                    label="Тип документа"
                    onChange={handleDocumentChange}
                    options={documentTypeList}
                    values={selectedDocumentTypeList}
                    dropdownClassName="whiteSpace ds-theme--light"
                    multiple
                    fullWidth
                    filter
                />
                <Select
                    label="Статус документа"
                    onChange={handleStatusChange}
                    options={statusList}
                    values={selectedStatusList}
                    dropdownClassName="whiteSpace ds-theme--light"
                    disabled={statusSelectDisabled}
                    multiple
                    fullWidth
                    filter
                />
                <Select
                    label="Статус задачи"
                    onChange={handleTaskStatusChange}
                    options={taskStatusList}
                    values={selectedTaskStatusList}
                    dropdownClassName="whiteSpace ds-theme--light"
                    multiple
                    fullWidth
                    filter
                />
                {mainContractorSelectVisible && (
                    <Select
                        label="ГПО"
                        onChange={handleMainContractorChange}
                        options={mainContractorsList}
                        values={selectedMainContractorsList}
                        dropdownClassName="whiteSpace ds-theme--light"
                        multiple
                        fullWidth
                        filter
                    />
                )}

                <DatePickerRange
                    label="Дата создания"
                    onChange={handleCreationRangeChange}
                    value={selectedCreationDateRange}
                    fullWidth
                />
                <DatePickerRange
                    label="Дата завершения"
                    onChange={handleCompletionRangeChange}
                    value={selectedCompletionDateRange}
                    fullWidth
                />
            </WrapperFlex>
        </TableSettings>
    );
};
