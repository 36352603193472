import styled, {css} from 'styled-components';

export const Wrapper = styled.div`
    flex-grow: 1;
    width: 100%;
    display: flex;
`;

export const Container = styled.div<{flexBasis: string}>`
    ${(props) =>
        css`
            flex: 1 1 ${props.flexBasis};
        `}
`;
