import {createGate} from 'effector-react';
import {combine, createDomain} from 'effector';
import {paginationStateFactory} from 'pages/VirPatternsPage/model/paginationFactory';
import {patternService} from '../service';
import {VirPattern} from '../types';

export const patternsPageDomain = createDomain();

export const PatternsPageGate = createGate();

export const loadPatternsFx = patternsPageDomain.createEffect(patternService.getPatterns);
export const deletePatternFx = patternsPageDomain.createEffect(patternService.deletePattern);

export const setSearchTerm = patternsPageDomain.createEvent<string>();
export const searchByTerm = patternsPageDomain.createEvent<string>();
export const selectPattern = patternsPageDomain.createEvent<string>();
export const setIsDeletePatternModalOpen = patternsPageDomain.createEvent<boolean>();
export const deletePattern = patternsPageDomain.createEvent<string>();

export const $patterns = patternsPageDomain.createStore<VirPattern[]>([]);
export const $selectedPattern = patternsPageDomain.createStore('');
export const $searchTerm = patternsPageDomain.createStore('');
export const $isDeletePatternModalOpen = patternsPageDomain.createStore(false);
export const $sortOrder = patternsPageDomain.createStore<boolean | null>(null);
export const $sortByParam = patternsPageDomain.createStore<string>('');

export const {$pagination, setPage, setPageSize} = paginationStateFactory({
    domain: patternsPageDomain,
    loadDataFxHandler: loadPatternsFx,
    resetTriggers: [searchByTerm],
});

export const $patternsPage = combine({
    page: $pagination.$page,
    pageSize: $pagination.$pageSize,
    count: $pagination.$count,
    searchTerm: $searchTerm,
    selectedPattern: $selectedPattern,
    isDeletePatternModalOpen: $isDeletePatternModalOpen,
    loadingForPatterns: loadPatternsFx.pending,
    loadingForDeletePattern: deletePatternFx.pending,
    sortByParam: $sortByParam,
    sortOrder: $sortOrder,
});
