import {useState} from 'react';
import {Button, IconButton, Popover} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {deleteWorkObject} from 'processes/contructor/model/stores';
import {ModalFactory} from 'Features/modalFactory/ui';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {
    CANCEL_BUTTON_TITLE,
    IRREVERSIBLE_ACTION_HINT,
    DELETE_BUTTON_TITLE,
    DELETION_CONFIRMING_HINT,
    PROHIBITED_OBJECT_DELETION_HINT,
} from 'shared/constants';

interface DeleteObjectStatementProps {
    virId: string;
    workObjectId: string;
    isDeletable: boolean;
}

export function DeleteObjectStatement(props: DeleteObjectStatementProps) {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            <Popover
                content={
                    <WrapperOffset offset={8}>{PROHIBITED_OBJECT_DELETION_HINT}</WrapperOffset>
                }
                disableHover={props.isDeletable}
            >
                <IconButton
                    iconName={Icons.Delete}
                    size="large"
                    onClick={openModal}
                    disabled={!props.isDeletable}
                />
            </Popover>

            <ModalFactory
                open={modalOpen}
                title={DELETION_CONFIRMING_HINT}
                description={IRREVERSIBLE_ACTION_HINT}
            >
                <Button variant="outlined" size="medium" onClick={closeModal}>
                    {CANCEL_BUTTON_TITLE}
                </Button>
                <Button
                    variant="contained"
                    size="medium"
                    onClick={() => {
                        deleteWorkObject(props);
                        closeModal();
                    }}
                    className="ms-3"
                >
                    {DELETE_BUTTON_TITLE}
                </Button>
            </ModalFactory>
        </>
    );
}
