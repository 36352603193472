import React from 'react';
import {Typography} from '@beeline/design-system-react';

import {DownloadableAttachment} from 'Features/DownloadableAttachment';
import {Comment} from 'Entities/Comment';
import {AgreementAttachmentType, IComment} from 'pages/DSPage/types';
import {getVirAttachment} from 'pages/DSPage/model';

import * as S from './style';
import {EAttachmentType} from '../../../shared/types/FileTypes';

interface DsSpecificationCommentsAndFilesRowProps {
    attachments?: AgreementAttachmentType[];
    comments?: IComment[];
    loadingAttachmentId: string;
    isCategory: boolean;
    jobCategory: {id: string; work_category: string} | null;
    jobCategoryDescription: string | null;
}

export const DsSpecificationCommentsAndFilesRow = ({
    attachments,
    comments,
    loadingAttachmentId,
    isCategory,
    jobCategory,
    jobCategoryDescription,
}: DsSpecificationCommentsAndFilesRowProps) => {
    const handleDownloadAttachment = (fileId: string, fileName: string, type: EAttachmentType) => {
        getVirAttachment({fileId, fileName, type});
    };

    return (
        <S.Wrapper>
            <S.JobsAndFilesWrapper>
                {isCategory && (
                    <S.FilesWrapper>
                        <Typography variant="h6">Дополнительные работы</Typography>
                        <Typography variant="body2">
                            <>
                                <span className="inactiveText">Категория: </span>
                                {jobCategory?.work_category}
                            </>
                        </Typography>
                        <Typography variant="body2">
                            <>
                                <span className="inactiveText">Описание: </span>
                                {jobCategoryDescription}
                            </>
                        </Typography>
                    </S.FilesWrapper>
                )}
                {attachments && attachments.length > 0 && (
                    <S.FilesWrapper>
                        <Typography variant="h6">Вложения</Typography>
                        <div>
                            {attachments.map((attachment) => (
                                <DownloadableAttachment
                                    id={attachment.id}
                                    key={attachment.id}
                                    type={attachment.type}
                                    title={attachment.file_name}
                                    handleDownloadAttachment={handleDownloadAttachment}
                                    disabled={loadingAttachmentId === attachment.id}
                                />
                            ))}
                        </div>
                    </S.FilesWrapper>
                )}
            </S.JobsAndFilesWrapper>
            {comments && comments.length > 0 && (
                <S.CommentsWrapper>
                    <Typography variant="h6" className="mb-2">
                        Комментарий к пункту работ
                    </Typography>
                    {comments.map((comment) => (
                        <Comment key={comment.id} {...comment} />
                    ))}
                </S.CommentsWrapper>
            )}
        </S.Wrapper>
    );
};
