import React, { Component } from 'react'
import FilterIcon from 'icons/FilterIcon'
import styled from 'styled-components'
const Button = styled.button`
	background-color:transparent;
	border:none;
	width:24px;
	height:24px;
	padding:0;
	position:var(--pfilterbtn);
	right:var(--rfilterbtn);
	top:var(--tfilterbtn);
	transition-duration:0.4s;
	.icon{
		width:100%;
		height:100%
	}
`
export default function FilterSearchBtn() {
	return (
		<Button>
			<FilterIcon />
		</Button>
	)
}
