import React from 'react';
import {useGate, useStore} from 'effector-react';
import {useNavigate} from 'react-router-dom';
import {createPortal} from 'react-dom';

import {Button, Progress, Typography} from '@beeline/design-system-react';
import {AttachmentsAndCommentsBlock} from 'widgets/AttachmentsAndCommentsBlock';
import {JobSpecificationTable} from 'widgets/JobSpecificationTable/JobSpecificationTable';
import {DeadlineBlock} from 'processes/CreationJobProcess/ui/Stage3/DeadlineBlock';

import {NonMvpItem} from 'shared/styles/commonStyle';
import * as S from './style';
import {$projectId, resetAllDomains} from '../../model';
import {$selectedPrimeContractorName, $selectedPurchasingEventInfo} from '../../model/stage1';
import {
    $stage3Store,
    createNewTask,
    decreaseDeadline,
    deleteRow,
    downloadNriRllFile,
    downloadNriBsFile,
    increaseDeadline,
    jobProcessGateStage3,
    setComment,
    setQuantity,
    attachFile,
} from '../../model/stage3';

interface Stage3Props {
    onChangeStage: (stage: number) => void;
}

export const Stage3 = ({onChangeStage}: Stage3Props) => {
    useGate(jobProcessGateStage3);
    const {
        jobTaskSpecifications,
        deadlineCount,
        comment,
        totalSum,
        successfulJobCreation,
        createdJobId,
        submitButtonIsDisabled,
        nriRllFilesList,
        nriRllFilesListIsLoading,
        buttonsState,
        attachmentFilesList,
    } = useStore($stage3Store);
    const purchasingEventInfo = useStore($selectedPurchasingEventInfo);
    const primeContractorName = useStore($selectedPrimeContractorName);
    const projectId = useStore($projectId);

    const navigate = useNavigate();

    const handleGoBack = () => {
        onChangeStage(2);
    };

    const handleSubmitButton = () => {
        createNewTask();
    };

    if (successfulJobCreation && createdJobId) {
        resetAllDomains();
        navigate(`/jobs/${createdJobId}`);
    }

    return (
        <>
            {submitButtonIsDisabled &&
                createPortal(
                    <S.LoaderWrapper>
                        <Progress
                            cycled
                            shape="circle"
                            size={104}
                            strokeWidth={4}
                            type="solo"
                            value={75}
                        />
                    </S.LoaderWrapper>,
                    document.body,
                )}
            <Typography variant="h4">
                Проверьте сформированное задание и укажите сроки выполнения
            </Typography>
            <Typography variant="h5">Сроки выполнения</Typography>
            <S.MainInfoWrapper>
                <DeadlineBlock
                    value={deadlineCount}
                    onIncrease={increaseDeadline}
                    onDecrease={decreaseDeadline}
                />
                <S.PrimeContractorInfo>
                    <Typography className="inactiveText" variant="body3">
                        Ген. подрядчик
                    </Typography>
                    <Typography className="mb-2" variant="body2">
                        {primeContractorName}
                    </Typography>
                    <NonMvpItem>
                        <Typography variant="body2">INDOOR-ПРО-СТ-2021 от 10.09.2021г</Typography>
                    </NonMvpItem>
                </S.PrimeContractorInfo>
                <div>
                    <Typography className="inactiveText" variant="body3">
                        Закупочное мероприятие
                    </Typography>
                    <Typography variant="body2">{purchasingEventInfo}</Typography>
                </div>
            </S.MainInfoWrapper>

            <JobSpecificationTable
                jobTaskSpecifications={jobTaskSpecifications}
                onChangeQuantity={setQuantity}
                totalSum={totalSum}
                onDeleteRow={deleteRow}
            />

            <S.EndNoteTypography variant="caption" className="inactiveText">
                Все расчеты указаны без учета НДС
            </S.EndNoteTypography>

            <AttachmentsAndCommentsBlock
                comment={comment}
                projectId={projectId}
                isLoading={nriRllFilesListIsLoading}
                buttonsState={buttonsState}
                files={nriRllFilesList}
                downloadBsFile={downloadNriBsFile}
                downloadRllFile={downloadNriRllFile}
                handleChangeComment={setComment}
                onAddFile={attachFile}
                attachmentFilesList={attachmentFilesList}
            />

            <S.ButtonsContainer>
                <Button
                    className="me-3"
                    size="medium"
                    onClick={handleGoBack}
                    disabled={submitButtonIsDisabled}
                >
                    Назад
                </Button>
                <Button
                    variant="contained"
                    size="medium"
                    disabled={submitButtonIsDisabled}
                    onClick={handleSubmitButton}
                >
                    Выдать в работу
                </Button>
            </S.ButtonsContainer>
        </>
    );
};
