import {combine, createDomain, forward, sample} from 'effector';
import {postMobileIdAuthorization} from 'shared/services/auth.service';
import {authFailed} from 'shared/model/auth';
import {forwardPayload} from 'shared/helpers/effector';

export const loginPageDomain = createDomain();

export const sendEmail = loginPageDomain.createEvent();
export const setEmail = loginPageDomain.createEvent<string>();
export const resetEmail = loginPageDomain.createEvent();
export const setEmailHasSent = loginPageDomain.createEvent<boolean>();
export const resetEmailHasSent = loginPageDomain.createEvent();
export const resetLoginPageDomain = loginPageDomain.createEvent();

export const sendEmailFx = loginPageDomain.createEffect(async (email: string) => {
    try {
        setEmailHasSent(true);
        const result = await postMobileIdAuthorization(email);
        if (result.status === 200) {
            localStorage.setItem('ticketId', result.data.ticketId);
            // @ts-ignore
        } else if (result.response.status === 301) {
            // @ts-ignore
            localStorage.setItem('ticketId', result.response.data.ticketId);
            // @ts-ignore
            window.location.replace(result.response.data.redirectUrl);
        } else {
            authFailed();
        }
    } catch (e) {
        console.error(e);
    }
});

export const $email = loginPageDomain
    .createStore<string>('')
    .on(setEmail, forwardPayload())
    .reset(resetEmail);

export const $emailHasSent = loginPageDomain
    .createStore<boolean>(false)
    .on(setEmailHasSent, forwardPayload())
    .reset(resetEmailHasSent);
export const $loginPageStore = combine({
    email: $email,
    emailHasSent: $emailHasSent,
});

sample({
    clock: sendEmail,
    source: $email,
    filter: (source) => source !== '',
    target: sendEmailFx,
});

// reset all domain
forward({from: resetLoginPageDomain, to: [resetEmail, resetEmailHasSent]});
