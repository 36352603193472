import styled from 'styled-components/macro';

export const CommentItemBlockStyled = styled.div`
    padding: 32px 60px;
    width: 100%;
    min-width: 1200px;
`;

export const AttachmentUnitWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    padding-right: 24px;
    margin-bottom: 8px;
`;
