import React from 'react';
import {PopoverHelper, Typography} from '@beeline/design-system-react';

import {formatCurrency} from 'shared/helpers/formatHelper';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

import * as S from './style';

interface FixedPriceCellProps {
    price: string | number;
    unit: string;
}

export const FixedPriceCell = ({price, unit}: FixedPriceCellProps) => {
    return (
        <WrapperFlex flexDirection="column">
            <Typography variant="body3">
                {price > 0 ? formatCurrency(price) : 'По согл.'}
            </Typography>
            <PopoverHelper body={unit}>
                <S.StyledPriceUnit variant="body3" className="inactiveText">
                    {unit}
                </S.StyledPriceUnit>
            </PopoverHelper>
        </WrapperFlex>
    );
};
