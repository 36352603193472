import styled, {css} from 'styled-components';
import {Typography} from '@beeline/design-system-react';

export const CustomBtn = styled.div<{disabled?: boolean}>`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 12px;
    color: var(--color-text-disabled);
    gap: 16px;
    ${(p) =>
        !p.disabled &&
        css`
            cursor: pointer;
            color: var(--color-text-active);

            &:hover {
                background-color: var(--color-background-base-hover);
            }
        `}
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const DataWrapper = styled.div`
    width: 100%;
    overflow: hidden;
`;

export const TitleWrapper = styled.div`
  height: 22px;
  overflow: hidden;
`;

export const StyledTitle = styled(Typography)`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    word-break: break-word;
    -webkit-box-orient: vertical;
`;
