import {createDomain, sample} from 'effector';

import {scrollToAnchor} from 'shared/helpers/scroll';

export const AccordionDomain = createDomain();

const scrollToActiveAccordionFx = AccordionDomain.createEffect(async (id: string) => {
    await new Promise(() => setTimeout(() => scrollToAnchor(id), 50));
});

export const activeAccordionSelected = AccordionDomain.createEvent<string>();


// скролл к заголовку открытого аккордеона
sample({
    clock: activeAccordionSelected,
    target: scrollToActiveAccordionFx,
});

