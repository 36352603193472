import React from 'react';
import {useGate, useStore} from 'effector-react';
import {Button, Progress, Typography} from '@beeline/design-system-react';

import {NotFoundPage} from 'pages/NotFoundPage';
import {$authStore} from 'shared/model/auth';
import {$userActions, UserGate} from 'shared/model/user';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions} from 'shared/const/actions';

import {Div, StyledLink} from './style';

export function RequireAuth({
    children,
    accessAction,
}: {
    children: JSX.Element;
    accessAction?: EAccessActions;
}) {
    useGate(UserGate);
    const {isAuth, isLoading, isAuthFinished} = useStore($authStore);
    const userActions = useStore($userActions);
    const reloadPage = () => {
        window.location.replace(window.location.origin);
    };

    const userHasAccess = userHasPermission(accessAction, userActions);

    if (!isAuth || isLoading) {
        return (
            <Div>
                {isLoading && (
                    <Progress
                        cycled
                        shape="circle"
                        size={104}
                        strokeWidth={4}
                        type="solo"
                        value={75}
                    />
                )}
                {!isAuth && !isLoading && isAuthFinished && (
                    <>
                        <Typography variant="h3">
                            Если Вы видите это сообщение, значит что-то пошло не так :(
                        </Typography>
                        <Typography variant="h3">Пожалуйста перезагрузите страницу</Typography>
                        <StyledLink>
                            <Button variant="outlined" onClick={reloadPage}>
                                Перезагрузить страницу
                            </Button>
                        </StyledLink>
                    </>
                )}
            </Div>
        );
    }
    if (accessAction && !userHasAccess) {
        return <NotFoundPage />;
    }
    return children;
}
