import {Link} from 'react-router-dom';
import React, {ReactNode} from 'react';
import {Icon, Label} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {CompletedWorksCompletionPopover} from 'Entities/CompletedWorksCompletionPopover';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {labelVariant} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {formatCurrency, formatDateHours, formatNumber} from 'shared/helpers/formatHelper';
import {createLink} from 'shared/helpers/links';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {IColumn, IColumnWithActions} from 'shared/types/tableTypes';
import {
    IColumnCompletedWorksListItem,
    IToggledCompletedListItem,
} from 'shared/types/completedWorksTypes';
import {EAccessActions} from 'shared/const/actions';

import * as S from './style';

export interface IInnerColumn<T> extends IColumn<T> {
    render?: (data: T, sum?: string) => ReactNode;
}

export const columns: IColumnWithActions<IToggledCompletedListItem>[] = [
    {
        name: 'cw_toggle',
        label: '',
        render: ({isOpen, vvrs}) => (
            <S.Cell>
                {vvrs && vvrs.length > 0 && (
                    <Icon iconName={isOpen ? Icons.NavArrowUp : Icons.NavArrowDown} size="medium" />
                )}
            </S.Cell>
        ),
        width: '50px',
    },
    {
        name: 'completed_works_number',
        render: (item) => <Link to={createLink(2, item.id)}>{`ДС ${item.number_agreement}`}</Link>,
        width: '112px',
    },
    {
        name: 'completion',
        label: 'Выполнено',
        render: ({sum, vvrs}) => {
            const approvedSum = vvrs.reduce<number>(
                (total, item) => (item.approved_at ? total + +item.sum : total),
                0,
            );
            const totalSum = vvrs.reduce<number>((total, item) => total + +item.sum, 0);
            const approvedCompletion = formatNumber((approvedSum / +sum) * 100, 0);
            const totalCompletion = formatNumber((totalSum / +sum) * 100, 0);
            const labelColor = approvedCompletion === 100 ? 'success' : 'warning';
            const labelTitle =
                approvedCompletion === 100 && totalCompletion === 100
                    ? `100%`
                    : `${approvedCompletion} / ${totalCompletion}%`;
            return (
                <WrapperFlex justifyContent="center" width="100%">
                    <CompletedWorksCompletionPopover
                        approvedCompletion={approvedCompletion}
                        totalCompletion={totalCompletion}
                        children={<Label title={labelTitle} type={labelColor} />}
                    />
                </WrapperFlex>
            );
        },
        width: '118px',
    },
    {
        name: 'contract_outer',
        label: 'Договор',
        render: ({contract_id, contract_number}, userActions) => {
            const hasLink =
                userActions && userHasPermission(EAccessActions.ContractCard, userActions);
            return hasLink ? (
                <Link to={`/contracts/${contract_id}`}>{contract_number}</Link>
            ) : (
                <span>{contract_number}</span>
            );
        },
        width: '150px',
    },
    {
        name: 'empty_cell_1',
        width: '112px',
    },
    {
        name: 'main_contractor',
        width: '112px',
    },
    {
        name: 'empty_cell_3',
        width: '112px',
    },
    {
        name: 'empty_cell_4',
        width: '112px',
    },
];

export const innerColumns: IInnerColumn<IColumnCompletedWorksListItem>[] = [
    {name: 'toggle', width: '50px'},
    {
        name: 'completed_works_number',
        label: '№ ВВР',
        render: ({id, number, hasPermission}) => {
            const name = `ВВР ${number}`;
            return hasPermission ? <Link to={`/completed-works/${id}`}>{name}</Link> : name;
        },
        width: '200px',
    },
    {
        name: 'price',
        label: 'Стоимость работ, ₽',
        render: ({sum}) => <span>{formatCurrency(sum)}</span>,
        width: '112px',
    },
    {
        name: 'completion',
        label: 'Выполнено',
        render: ({sum: cwSum}, sum) => {
            if (!sum) return undefined;
            const completion = formatNumber((+cwSum / +sum) * 100, 0);
            const currentCompletionType = completion === 100 ? 'success' : 'info';
            return (
                <WrapperFlex justifyContent="center" width="100%">
                    <Label title={`${completion}%`} type={currentCompletionType} />
                </WrapperFlex>
            );
        },
        width: '118px',
    },
    {
        name: 'contract',
        label: 'Договор',
        width: '150px',
    },
    {
        name: 'main_contractor',
        label: 'ГПО',
        render: ({gpo_short_name}) => <span>{gpo_short_name}</span>,
        width: '150px',
    },
    {
        name: 'purchasing_event_number',
        label: 'Дата создания',
        render: ({created_at}) => <span>{formatDateHours(created_at)}</span>,
        width: '130px',
    },
    {
        name: 'aprroved_date',
        label: 'Дата согласования',
        render: ({approved_at}) => <span>{formatDateHours(approved_at)}</span>,
        width: '130px',
    },
    {
        name: 'completed_works_status',
        label: 'Статус',
        render: (item) => {
            return (
                item.status && (
                    <Label
                        type={labelVariant(item.status?.token)}
                        iconName={Icons.Dot}
                        variant="outline"
                        title={item.status?.name}
                    />
                )
            );
        },
        width: '205px',
    },
];
