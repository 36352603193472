import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';

import {CreationHeader} from 'widgets/CreationHeader';

import {Stage1} from './Stage1/Stage1';
import {Stage2} from './Stage2/Stage2';
import {$completedWorksProcessStore, CompletedWorksProcessGate} from '../model';

export const CompletedWorksProcess = () => {
    useGate(CompletedWorksProcessGate);
    const {projectId} = useParams();
    const {currentStage} = useStore($completedWorksProcessStore);
    const navigate = useNavigate();

    return (
        <div>
            <CreationHeader
                title="Новая ВВР"
                onClose={() => navigate(-1)}
                modalTitle="Завершить создание ВВР?"
            />
            {currentStage === 1 && <Stage1 projectId={projectId} />}
            {currentStage === 2 && <Stage2 mode="creation" />}
        </div>
    );
};
