import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';
import {$stage1ToStage2Store, CompletedWorksStage1Domain} from './stage1';
import {resetDomainStoresByEvents} from '../../../shared/helpers/effector';
import {CompletedWorksStage2Domain} from './stage2';

export const CompletedWorksProcessDomain = createDomain();
export const CompletedWorksProcessGate = createGate();

// events
export const changeStageButtonClicked = CompletedWorksProcessDomain.createEvent<number>();
// stores
const $currentStage = CompletedWorksProcessDomain.createStore(1);
export const $completedWorksProcessStore = combine({
    currentStage: $currentStage,
});

// Смена страницы процесса
sample({
    clock: changeStageButtonClicked,
    source: $stage1ToStage2Store,
    filter: (store) => store.selectedAgreementList.length > 0,
    fn: (_, newStage) => newStage,
    target: $currentStage,
});

resetDomainStoresByEvents(CompletedWorksProcessDomain, CompletedWorksProcessGate.close);
resetDomainStoresByEvents(CompletedWorksStage1Domain, CompletedWorksProcessGate.close);
resetDomainStoresByEvents(CompletedWorksStage2Domain, CompletedWorksProcessGate.close);

