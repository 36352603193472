import {sample} from 'effector';
import {v4 as uuidv4} from 'uuid';
import {addLabelToWorkOptionBasedOnType} from 'shared/helpers/formatHelper';
import {
    $addedWorkObjectsList,
    $workObjects,
    $workTypes,
    addNewRow,
    addWorkObjectDomain,
    AddWorkObjectGate,
    deleteRow,
    loadWorkObjectsFx,
    loadWorkTypesFx,
    selectStatement,
    selectWorkObject,
    selectWorkType,
} from './index';
import {resetDomainStoresByEvents} from 'shared/helpers/effector';
import {$createAdditionalAgreementData} from 'processes/contructor';

resetDomainStoresByEvents(addWorkObjectDomain, AddWorkObjectGate.close);

sample({
    clock: AddWorkObjectGate.open,
    target: loadWorkTypesFx,
});

sample({
    clock: AddWorkObjectGate.open,
    source: $createAdditionalAgreementData,
    filter: ({project_id}) => !!project_id,
    fn: ({project_id}) => ({project_id: project_id || ''}),
    target: loadWorkObjectsFx,
});

$workObjects.on(loadWorkObjectsFx.doneData, (state, payload) =>
    addLabelToWorkOptionBasedOnType(payload.data),
);
$workTypes.on(loadWorkTypesFx.doneData, (state, payload) =>
    payload.data.map((el) => ({work_types_id: el.id, work_types_name: el.name})),
);

function updateList(state, payload, propName) {
    const index = state.findIndex((el) => el.id === payload?.id);
    const item = state.find((el) => el.id === payload?.id);
    const isWorkObject = propName === 'workObject';
    if (item) {
        return [
            ...state.slice(0, index),
            {...item, [`${propName}`]: payload, ...(isWorkObject ? {statement: ''} : {})},
            ...state.slice(index + 1),
        ];
    }
    return state;
}

function fillWithDefault(state) {
    return [
        ...state,
        {
            id: `front -${uuidv4()}`,
            workObject: null,
            workType: null,
            statement: null,
        },
    ];
}

$addedWorkObjectsList
    .on(AddWorkObjectGate.open, (state) => (state.length === 0 ? fillWithDefault(state) : state))
    .on(addNewRow, (state) => fillWithDefault(state))
    .on(selectWorkObject, (state, payload) => {
        const result = {...payload};
        if (payload.lavParts) {
            const lavParts = payload.lavParts.map((item) => {
                item.lav_gfk = result.gfk;
                return item;
            });
            result.lavParts = lavParts;
        }
        return updateList(state, result, 'workObject');
    })
    .on(selectWorkType, (state, payload) => updateList(state, payload, 'workType'))
    .on(selectStatement, (state, payload) => updateList(state, payload, 'statement'))
    .on(deleteRow, (state, payload) => state.filter((el) => el.id !== payload));
