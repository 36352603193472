import styled from 'styled-components/macro';
import {TabsBtnWrap} from 'shared/ui/deprecated/Tabs/style';

export const ContentWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    gap: 16px;
`;

export const Tabs = styled.div`
    --min-nf: 533px;
`;

export const TabPanel = styled(TabsBtnWrap)`
    justify-content: flex-start;
`;

export const TopBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;
