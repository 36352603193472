import styled, {css} from 'styled-components';
import {Icon} from '@beeline/design-system-react';

export const RatioText = styled.div<{position?: string}>`
    display: flex;
    align-items: center;
    gap: 8px;
    flex-grow: 1;
    ${(props) =>
        props.position &&
        props.position === 'top' &&
        css`
            align-items: flex-start;
        `}
`;

export const CoefficientWrapper = styled.div`
    height: 18px;
    flex: 0 0 18px;
`;

export const PopoverWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PointerIcon = styled(Icon)`
    cursor: pointer;
`;
