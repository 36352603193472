import {EAdditionalAgreementType, IUpdatedAgreement} from 'shared/types/additionalAgreementsTypes';
import {getAgreementType} from 'shared/helpers/additionalAgreements';
import {
    checkAgreementHasEmptyWorkSpecifications,
    checkAgreementHasIncreasingCoefficientsWithNoAttachmentsOrComments,
    checkInvalidEndDates,
    getAllVirsSpecs,
} from './getters';

export function validateConstructorSpecs(ds: IUpdatedAgreement): boolean {
    const specs = getAllVirsSpecs(ds);
    const hasProblemsWithCoefficients =
        checkAgreementHasIncreasingCoefficientsWithNoAttachmentsOrComments(specs);
    const hasInvalidWorkSpecification = checkAgreementHasEmptyWorkSpecifications(ds);

    const agreementType = getAgreementType(ds.agreement_id, ds.is_termination);
    const parentSigningDateValidation =
        agreementType === EAdditionalAgreementType.Addition ? !!ds.parent_signing_date : true;
    const hasInvalidSpecificationEndDates = checkInvalidEndDates(
        specs,
        agreementType,
        ds.parent_signing_date,
    );
    return (
        !hasProblemsWithCoefficients &&
        !hasInvalidWorkSpecification &&
        !hasInvalidSpecificationEndDates &&
        specs.every((el) => el.price && el.end_date && el.quantity) &&
        specs.every((el) => {
            if (el.is_category) {
                return (
                    !!el.work_category_id && !!el.category_name && !!el.work_specification_comments
                );
            }
            return true;
        }) &&
        specs.every((el) => {
            if (el.is_agreed_price) {
                return !!el.work_specification_comments;
            }
            return true;
        }) &&
        parentSigningDateValidation
    );
}
