import React from 'react';
import {useStore} from 'effector-react';
import {Button} from '@beeline/design-system-react';

import Pagination from 'shared/ui/Pagination/Pagination';
import {NotFound, NotFoundCaption, NotFoundTitle} from 'components/NotFound';

import * as S from './style';
import {TasksTable} from './TaskTable/TasksTable';
import {ProcessesTable} from './ProcessesTable/ProcessesTable';
import {$ProcessesPageStore, changePage, changePageSize, resetAllData} from '../../model';

interface ProcessesTableProps {
    changeTableSort: (data: string) => void;
}

export const CommonTable = ({changeTableSort}: ProcessesTableProps) => {
    const {
        taskCount,
        isLoading,
        taskParams: {
            filters: {page, page_size, groupByProcesses},
        },
    } = useStore($ProcessesPageStore);
    return (
        <S.TableWrapper>
            <S.TableContainer>
                {!isLoading && taskCount === 0 ? (
                    <NotFound height="500px">
                        <NotFoundTitle>По заданным фильтрам ничего не найдено</NotFoundTitle>
                        <NotFoundCaption>
                            Попробуйте изменить параметры или сбросьте фильтры
                        </NotFoundCaption>
                        <Button className="mt-3" variant="plain" onClick={() => resetAllData()}>
                            Сбросить
                        </Button>
                    </NotFound>
                ) : (
                    <S.StyledTable>
                        {groupByProcesses ? (
                            <ProcessesTable changeTableSort={changeTableSort} />
                        ) : (
                            <TasksTable changeTableSort={changeTableSort} />
                        )}
                    </S.StyledTable>
                )}
            </S.TableContainer>
            <Pagination
                total={taskCount}
                setPageSize={changePageSize}
                setPage={changePage}
                page={page}
                pageSize={page_size}
                isLoading={isLoading}
            />
        </S.TableWrapper>
    );
};
