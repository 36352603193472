import React from 'react';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {Button, Typography} from '@beeline/design-system-react';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {useNavigate} from 'react-router-dom';
import * as S from './style';
// @ts-ignore
import Error from 'shared/img/not-found-image.png';

export const NotFoundPage = () => {
    const navigate = useNavigate();
    const handleOnClick = () => {
        navigate('/');
    };
    return (
        <S.Wrapper>
            <WrapperFlex flexDirection="column" alignItems="center">
                <img src={Error} alt="Страница не найдена" />
                <WrapperOffset offsetTop={48}>
                    <Typography variant="h4">Страница не найдена</Typography>
                </WrapperOffset>
                <WrapperOffset offsetTop={8}>
                    <Typography variant="body1">
                        Возможно, она была удалена, или был указан неверный адрес страницы
                    </Typography>
                </WrapperOffset>
                <WrapperOffset offsetTop={24}>
                    <Button size="medium" variant="contained" onClick={handleOnClick}>
                        На главную
                    </Button>
                </WrapperOffset>
            </WrapperFlex>
        </S.Wrapper>
    );
};
