import {AdditionalAgreementQueryParams, ITaskResponse, UpdateTaskProps} from 'pages/DSPage/types';
import {axiosInstance} from 'shared/api/api';
import {ITaskStatusListItem, TTaskList} from 'shared/types/tasksTypes';

export const getTasksList = (params: AdditionalAgreementQueryParams) => {
    return axiosInstance.get<TTaskList>(`/processes/tasks/task-id/`, {
        params: {
            instance_id: params.id,
        },
    });
};

export const putAcceptTask = (id: string) => {
    return axiosInstance.put(`/processes/tasks/${id}/accept/`);
}

export const putReturnTask = (id: string) => {
    return axiosInstance.put(`/processes/tasks/${id}/cancel/`);
}

export const getTask = (id: string): Promise<ITaskResponse> => {
    return axiosInstance.get(`/processes/tasks/${id}/`);
};

export const updateTask = ({id, actionId}: UpdateTaskProps) => {
    return axiosInstance.put(
        `/processes/tasks/${id}/update/`,
        {
            task_action_id: actionId,
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
};

export const fetchTaskStatusList = () => {
    return axiosInstance.get<ITaskStatusListItem[]>('/processes/tasks/statuses');
};
