import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Divider, Stepper} from '@beeline/design-system-react';
import {StepType} from '@beeline/design-system-react/types/components/Stepper/common/step.types';

import {CreationHeader} from 'widgets/CreationHeader';
import * as S from './style';
import {Stage1} from './Stage1/Stage1';
import {Stage2} from './Stage2/Stage2';
import {Stage3} from './Stage3/Stage3';
import {
    $activeStepId,
    creationJobProcessGate,
    resetAllDomains,
    resetSteps,
    setCurrentStep,
} from '../model';

interface CreationJobProcessProps {
    projectId: string;
}

const initialSteps: StepType[] = [
    {
        activated: true,
        id: 'step1',
        label: 'Генеральный подрядчик',
        signature: 'шаг 1',
        state: 'active',
    },
    {
        activated: false,
        id: 'step2',
        label: 'Поручаемые работы',
        signature: 'шаг 2',
        state: 'disabled',
    },
    {
        activated: false,
        id: 'step3',
        label: 'Сроки выполнения',
        signature: 'шаг 3',
        state: 'disabled',
    },
];

export const JobCreationProcess = ({projectId}: CreationJobProcessProps) => {
    useGate(creationJobProcessGate, {projectId});
    const navigate = useNavigate();
    const activeStepId = useStore($activeStepId);

    const steps = initialSteps.map((item, index) => {
        const resultItem = {...item};
        const currentStepNumber = +activeStepId.slice(-1);
        resultItem.activated = currentStepNumber === index + 1;
        resultItem.state =
            currentStepNumber - 1 === index
                ? 'active'
                : currentStepNumber - 1 > index
                ? 'success'
                : 'disabled';
        return resultItem;
    });

    const handleCloseCreation = () => {
        resetSteps();
        setCurrentStep(1);
        resetAllDomains();
        navigate(-1);
    };

    return (
        <>
            <CreationHeader
                title="Новое задание"
                modalTitle="Завершить создание задания?"
                onClose={handleCloseCreation}
            >
                <>
                    <S.HeaderStepper>
                        <Stepper steps={steps} activeStepId={activeStepId} direction="horizontal" />
                    </S.HeaderStepper>
                    <Divider />
                </>
            </CreationHeader>
            <S.ContentContainer>
                <S.Content>
                    {steps[0].state === 'active' && <Stage1 onChangeStage={setCurrentStep} />}
                    {steps[1].state === 'active' && <Stage2 onChangeStage={setCurrentStep} />}
                    {steps[2].state === 'active' && <Stage3 onChangeStage={setCurrentStep} />}
                </S.Content>
            </S.ContentContainer>
        </>
    );
};
