import {createEffect} from 'effector/compat';

export const setToLocalStorageFx = createEffect(({name, value}) => {
    localStorage.setItem(name, JSON.stringify(value));
});
// TODO add generic
export const getFromLocalStorageFx = createEffect(({name}) => {
    const data = localStorage.getItem(name);
    if (data) {
        return JSON.parse(data);
    }
});

export const removeFromLocalStorageFx = createEffect(({name}) => {
    localStorage.removeItem(name);
});
