import React from 'react';
import {useStore} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import {InformationalUnit} from 'Entities/InformationalUnit';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {formatDate} from 'shared/helpers/formatHelper';

import * as S from './style';
import {SkeletonInfoUnit} from './skeletons/SkeletonInfoUnit';
import {$contractViewPageStore} from '../model';

export const ContractViewInfoUnit = () => {
    const {contractData} = useStore($contractViewPageStore);
    if (!contractData) return <SkeletonInfoUnit />;
    const {gpo_short_name, number, nfs_contract_number, date_conclusion, date_action} =
        contractData;
    const expiryRange = `${formatDate(date_conclusion)} - ${formatDate(date_action)}`;
    return (
        <WrapperFlex flexDirection="column" gap="20px">
            <Typography variant="h5">{gpo_short_name}</Typography>
            <S.InfoUnitGrid>
                <InformationalUnit title={'Срок действия'} gridArea="expiryRange">
                    {expiryRange}
                </InformationalUnit>
                <InformationalUnit title={'Номер'} gridArea="number">
                    {number}
                </InformationalUnit>
                <InformationalUnit title={'КЗП'} gridArea="KZP">
                    {nfs_contract_number}
                </InformationalUnit>
            </S.InfoUnitGrid>
        </WrapperFlex>
    );
};
