import cn from 'classnames';
import type {ChangeEvent, ForwardedRef} from 'react';
import {forwardRef} from 'react';

import styles from './FileUploaderText.module.css';
import type {IFileUploaderTextProps} from './FileUploaderText.types';
import {DRAGGING_TEXT, FILE_UPLOADER_TEXT} from './constants';
import {getFilesRestrictionsText} from './utils';
import {Button, Icon, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

export const FileUploaderText = forwardRef(
    (props: IFileUploaderTextProps, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
        const {
            isDragging,
            mainText: mainTextFromProps,
            uploadActionText: uploadActionTextFromProps,
            onDropFileHandler,
            fileUploaderName,
            acceptFormats,
            maxFileSize,
            maxFiles,
            mode,
            externalError,
        } = props;
        const {defaultMainText, defaultUploadActionText} = FILE_UPLOADER_TEXT;

        const mainText = mainTextFromProps || defaultMainText;
        const uploadActionText = uploadActionTextFromProps || defaultUploadActionText;
        const filesRestrictionsText = getFilesRestrictionsText(
            acceptFormats,
            maxFileSize,
            maxFiles,
        );

        const handleChangeFiles = (event: ChangeEvent<HTMLInputElement>): void => {
            onDropFileHandler(event);
        };
        return (
            <div
                className={cn(
                    {[styles.uploader__disabled_pointer]: isDragging},
                    styles.uploader__text,
                )}
            >
                {mode !== 'editing' ? (
                    isDragging ? (
                        <Typography>{DRAGGING_TEXT}</Typography>
                    ) : (
                        <>
                            <Typography variant={'body3'}>
                                {mainText}
                                <label htmlFor={fileUploaderName}>
                                    <input
                                        value={[]}
                                        type={'file'}
                                        id={fileUploaderName}
                                        className={styles.file_input}
                                        ref={ref}
                                        onChange={handleChangeFiles}
                                        multiple
                                    />
                                    <span
                                        className={styles.uploader__action}
                                    >{` ${uploadActionText}`}</span>
                                </label>
                            </Typography>
                            <Typography variant="body3" className={styles.help__text}>
                                {filesRestrictionsText}
                            </Typography>
                        </>
                    )
                ) : (
                    <Button startIcon={<Icon iconName={Icons.Add} />} variant="plain" size="medium">
                        <label htmlFor={fileUploaderName}>
                            <input
                                value={[]}
                                type={'file'}
                                id={fileUploaderName}
                                className={styles.file_input}
                                ref={ref}
                                onChange={handleChangeFiles}
                                multiple
                            />
                            <span className={styles.uploader__action}>Загрузить еще</span>
                        </label>
                    </Button>
                )}
            </div>
        );
    },
);

FileUploaderText.displayName = 'FileUploaderText';
