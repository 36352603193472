import React from 'react';
import {Icon, Skeleton} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {CountWrap, PaginationBtn, SelectCount} from 'shared/ui/Pagination/style';

export interface PaginationProps {
    total: number;
    page?: number;
    pageSize?: number;
    setPageSize: (pageSize: number) => void;
    setPage: (page: number) => void;
    className?: string;
    isLoading?: boolean;
}

export default function Pagination({
    total,
    page = 1,
    setPage,
    pageSize = 10,
    setPageSize,
    isLoading = false,
    ...rest
}: PaginationProps) {
    const totalPages = total ? Math.ceil(total / pageSize) : 1;
    const from = total === 0 ? 0 : (page - 1) * pageSize + 1;
    const to = page < totalPages ? page * pageSize : total;
    const firstPage = page === 1;
    const lastPage = page === totalPages;

    const changeCurrentPage = (newPageNumber: number) => {
        if (newPageNumber > totalPages || newPageNumber < 1 || newPageNumber === page) {
            return;
        }
        setPage(newPageNumber);
    };
    if (!isLoading && total === 0) return null;

    return (
        <WrapperFlex
            className={rest.className}
            alignItems="center"
            justifyContent="flex-end"
            {...rest}
        >
            <WrapperFlex>
                <p>Строки на странице:</p>
                <SelectCount
                    value={pageSize}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setPageSize(+e.target.value);
                    }}
                >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                </SelectCount>
            </WrapperFlex>
            <CountWrap>
                {isLoading ? (
                    <Skeleton variant="line" width={79} height={18} />
                ) : (
                    `${from} - ${to} из ${total}`
                )}
            </CountWrap>
            <WrapperFlex alignItems="center">
                <PaginationBtn
                    id="firstPagination"
                    disabled={firstPage}
                    onClick={() => changeCurrentPage(1)}
                >
                    <Icon iconName={Icons.FastArrowLeft} size="large" />
                </PaginationBtn>
                <PaginationBtn
                    id="leftPagination"
                    disabled={firstPage}
                    onClick={() => changeCurrentPage(page - 1)}
                >
                    <Icon iconName={Icons.ArrowLeft} size="large" />
                </PaginationBtn>
                <PaginationBtn
                    id="rightPagination"
                    disabled={lastPage}
                    onClick={() => changeCurrentPage(page + 1)}
                >
                    <Icon iconName={Icons.ArrowRight} size="large" />
                </PaginationBtn>
                <PaginationBtn
                    id="endPagination"
                    disabled={lastPage}
                    onClick={() => changeCurrentPage(totalPages)}
                >
                    <Icon iconName={Icons.FastArrowRight} size="large" />
                </PaginationBtn>
            </WrapperFlex>
        </WrapperFlex>
    );
}
