import styled from 'styled-components/macro';
import {TabsBtnWrap} from 'shared/ui/deprecated/Tabs/Tabs';

export const VirSelectBlock = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    gap: 16px;
`;

export const VirSelectBody = styled.div`
    padding-top: 24px;
    --min-nf: 533px;
`;

export const TabPanel = styled(TabsBtnWrap)`
    justify-content: flex-start;
`;
