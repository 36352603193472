import styled from 'styled-components/macro';
import {Sidesheet, Tabs} from '@beeline/design-system-react';

export const StyledSidesheet = styled(Sidesheet)`
    height: calc(100vh - 66px);

    .dsb_sidesheet__sheet__elevation.is-open.coplanar {
        width: 320px;
    }
`;

export const StyledTabs = styled(Tabs)`
    padding-right: 0;

    .dsb_tabs-scroller__content {
        width: 100%;
        justify-content: space-evenly;
    }

    .dsb_tab-new {
        padding: 0 23px;
    }
`;
