import React, {useState} from 'react';
import {
    IconBlock,
    IconButton,
    IconInputWrap,
    InputBlock,
    InputBlockWrap,
    InputHelper,
    InputLabel,
    InputStyle,
} from 'shared/ui/deprecated/TextField/style';

const TextField = (props) => {
    const {
        val,
        filled,
        error,
        color,
        leftIcon,
        rightIcon,
        size,
        inputRef,
        outline,
        placeholder,
        label,
        disabled,
        helper,
        onChange,
        textColor = 'black',
        maskValue,
        children,
        ...rest
    } = props;

    const [noValue, setNoValue] = useState('');
    const [onFocus, setOnFocus] = useState(false);

    const handleChange = (e) => {
        setNoValue(e.target.value);
        onChange?.(e);
    };

    return (
        <InputBlockWrap className={props?.className + ' input__wrapper'} {...props}>
            {label && size !== 'small' && (
                <InputLabel
                    leftIcon={leftIcon}
                    filled={val ? val : noValue}
                    disabled={disabled}
                    size={size}
                >
                    {label}
                </InputLabel>
            )}
            <InputBlock
                className="input__block"
                size={size}
                onFocus={() => setOnFocus(true)}
                onBlur={() => setOnFocus(false)}
                focus={onFocus}
                error={error}
                color={color}
            >
                <IconInputWrap>
                    {leftIcon && <IconBlock size={size}>{leftIcon}</IconBlock>}
                    <InputStyle
                        className="input__native"
                        placeholder={placeholder ? placeholder : label}
                        ref={inputRef}
                        textColor={textColor}
                        filled={val ? val : noValue}
                        disabled={disabled}
                        color={color}
                        label={label}
                        outline={outline}
                        size={size}
                        onChange={(e) => handleChange(e)}
                        {...rest}
                    />
                </IconInputWrap>
                {rightIcon && <IconButton size={size}>{rightIcon}</IconButton>}
            </InputBlock>

            {helper && (
                <InputHelper error={error} disabled={disabled}>
                    {helper}
                </InputHelper>
            )}
        </InputBlockWrap>
    );
};

export default TextField;
