import React from 'react';
import {useRef, useContext} from 'react';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {
    PopoverElement,
    PopoverStyled,
    PopoverButtonStyled,
} from 'shared/ui/deprecated/Popover/style';
import PortalWrapper from 'shared/ui/deprecated/Portal/Portal';

const PopoverContext = React.createContext();

export default function Popover(props) {
    const {
        children,
        // open,
        // setOpen,
        showOnHover = false,
        component,
        pagination,
        closeBtn,
        noButtons = true,
        position = 'top-start',
        vertical = 20,
        horyzontal = 20,
        onClose = () => {},
        ...rest
    } = props;

    const elem = useRef(null);

    return (
        <PopoverContext.Provider value={props}>
            <PortalWrapper
                portal={
                    <PopoverStyled {...props} ref={elem}>
                        {component}
                    </PopoverStyled>
                }
                type={showOnHover ? 'hover' : 'click'}
                position={position}
                vertical={vertical}
                horyzontal={horyzontal}
                trigger={
                    <PopoverElement
                        {...props}
                        // onClick={() => setOpen(!open)}
                    >
                        {children}
                    </PopoverElement>
                }
                // onClose={() => setOpen(false)}
                // setOpen={setOpen}
                // open={open}
                elem={elem}
            />
        </PopoverContext.Provider>
    );
}

function PopoverButton(props) {
    const {children, ...rest} = props;
    return (
        <PopoverButtonStyled size="small" {...props}>
            {children}
        </PopoverButtonStyled>
    );
}

function PopoverActionPanel(props) {
    const {children, ...rest} = props;

    const popoverProps = useContext(PopoverContext);

    return (
        <WrapperOffset offsetTop="8">
            <WrapperFlex justifyContent="space-between" alignItems="center">
                <div>{popoverProps.pagination && <div>{/* нет иконки с точкой */}</div>}</div>
                <WrapperFlex>{children}</WrapperFlex>
            </WrapperFlex>
        </WrapperOffset>
    );
}

export {PopoverActionPanel, PopoverButton};
