import React from 'react';
import {useUnit} from 'effector-react';
import {Icon, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';
import {AgreementObjectSelect} from 'Features/AgreementObjectSelect';

import {
    EWorkObjectTypes,
    ITemporaryAgreementWorkObject,
} from 'shared/types/additionalAgreementsTypes';

import * as S from './style';
import {$agreementObjectSelectStore, destinationWorksheetObjectSelected} from '../../../model';
import {CatalogWorkObjectOption} from '../../../../VirStart/types';

interface ObjectDestinationProps {
    workObject: ITemporaryAgreementWorkObject;
}

export const ObjectDestination = ({workObject}: ObjectDestinationProps) => {
    const [{availableWorksheetObjects}] = useUnit([
        $agreementObjectSelectStore,
    ]);
    const {object_type: objectType} = workObject;

    const responseObjectList = availableWorksheetObjects.filter(
        (item) => item.object_type === EWorkObjectTypes.RESPONSE_PART,
    );

    const selectedTestObject = responseObjectList.find(
        (object) => object.id === workObject.objectId,
    );

    const selectedValues: CatalogWorkObjectOption[] = [];

    if (selectedTestObject) {
        selectedValues.push(selectedTestObject);
    }

    return (
        <S.SelectWrapper>
            <Icon iconName={Icons.ArrowRight} size="large" />
            {objectType === 1 ? (
                <Typography variant="body2">
                    {
                        availableWorksheetObjects.find(
                            (item) => item.object_type === EWorkObjectTypes.BS,
                        )?.label
                    }
                </Typography>
            ) : objectType === 2 ? (
                <Typography variant="body2">
                    {
                        availableWorksheetObjects.find(
                            (item) => item.object_type === EWorkObjectTypes.MAIN_PART,
                        )?.label
                    }
                </Typography>
            ) : responseObjectList.length === 1 ? (
                <Typography variant="body2">
                    {
                        availableWorksheetObjects.find(
                            (item) => item.object_type === EWorkObjectTypes.RESPONSE_PART,
                        )?.label
                    }
                </Typography>
            ) : (
                <AgreementObjectSelect
                    objectId={workObject.id}
                    options={responseObjectList}
                    values={selectedValues}
                    handleSelectOption={destinationWorksheetObjectSelected}
                    width="373px"
                />
            )}
        </S.SelectWrapper>
    );
};
