import styled, {css} from 'styled-components/macro';

export const StyledSpan = styled.span<{lineCount?: number; noCursor?: boolean}>`
    ${(props) =>
        css`
            display: -webkit-box;
            -webkit-line-clamp: ${props.lineCount ?? 2};
            -webkit-box-orient: vertical;
            overflow: hidden;
            cursor: pointer;
        `}
    ${(props) =>
        props.noCursor &&
        css`
            cursor: default;
        `}
`;
