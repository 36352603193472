import styled from 'styled-components';
import {Button} from '@beeline/design-system-react';

export const Wrapper = styled.div`
    position: relative;
`;

export const Card = styled.div`
    position: absolute;
    z-index: 1;
    top: 28px;
    right: -22px;
    width: 330px !important;
    background: var(--color-background-base);
    box-shadow: var(--elevation-medium);
    border-radius: var(--size-border-radius-x4);
`;

export const ItemWrapper = styled.div`
    margin: 8px 0;
`;

export const CardItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;

    :hover {
        background-color: var(--color-background-base-hover);
    }
`;

export const MarginButton = styled(Button)`
    margin: 8px 16px;
`;

export const SpanWrapper = styled.div`
    flex: 0 0 256px;
    height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
`;
