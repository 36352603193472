import {TExtended} from 'shared/types/userTypes';
import {EAccessActions} from '../../const/actions';

export type UserInfoType = {
    email: string;
    first_name: string;
    is_tax: boolean;
    last_name: string;
    telephone: string;
    user_type: USER_ROLE_TYPE;
    owner_id: string | null;
    actions: EAccessActions[];
    extend: TExtended;
};

export type UserType = {
    id?: string;
    last_name: string;
    first_name: string;
    middle_name?: string;
    position: string;
    type: number;
    email: string;
    telephone: string;
    username?: string;
    is_active?: boolean;
    date_joined?: string;
    last_login?: string;
    groups?: Array<number>;
    user_permissions?: Array<number>;
};

export enum USER_ROLE_TYPE {
    SUPERVISOR = 1,
    VK_ADMIN = 2,
    TECHNICAL = 3,
    GPO_LOCAL_ADMIN = 4,
    VK_USER = 5,
    GPO_USER = 6,
}
