import styled, {css} from 'styled-components/macro';

const ActionButtonMixin = (props) => css`
    align-items: center;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    height: 144px;
    justify-content: space-between;
    padding: 24px;
    transition-duration: 0.4s;
    width: 236px;
    font-size: 17px;

    &:hover {
        border-color: transparent;
        box-shadow: 0 0 10px rgb(0 0 0 / 10%), 0 4px 30px rgb(0 0 0 / 10%);
    }

    ${props.variant === 'green' &&
    css`
        --bgColor: var(--green-avatar);
        --iconColor: var(--green);
    `}
    ${props.variant === 'blue' &&
    css`
        --bgColor: var(--blue-avatar);
        --iconColor: var(--blue);
    `}
  ${props.variant === 'purple' &&
    css`
        --bgColor: var(--purple-avatar);
        --iconColor: var(--purple);
    `}
  ${props.variant === 'teal' &&
    css`
        --bgColor: var(--teal-avatar);
        --iconColor: var(--teal);
    `}
  ${props.disabled &&
    css`
        pointer-events: none;
        opacity: 0.8;

        div {
            filter: grayscale(1);
        }
    `}
`;

export const ActionButton = styled.button<{variant: string}>`
    ${(props) => ActionButtonMixin(props)}
`;

export const IconWrap = styled.div`
    align-items: center;
    background-color: var(--bgColor);
    border-radius: 12px;
    display: flex;
    height: 40px;
    justify-content: center;
    position: relative;
    width: 40px;

    .icon {
        color: var(--iconColor);
        height: 24px;
        width: 24px;
    }
`;
