import styled, {css} from 'styled-components/macro';
import {Typography} from '@beeline/design-system-react';

export const AddsWrap = styled.div`
    margin-right: 48px;
    flex: 1 1 576px;
    min-width: 500px;
`;
export const CommentWrap = styled.div`
    flex: 1 1 632px;
    min-width: 400px;
    display: flex;
    flex-direction: column;
`;

export const TextAreaWrapper = styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const RightButton = styled.div`
    align-self: flex-end;
`;

export const CustomWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
    @media (max-width: 1200px) {
        flex-wrap: wrap;
    }
`;

export const StyledTypography = styled(Typography)<{mb?: string; mt?: string}>`
    ${({mb}) =>
        mb &&
        css`
            margin-bottom: ${mb};
        `}
    ${({mt}) =>
        mt &&
        css`
            margin-top: ${mt};
        `}
`;
