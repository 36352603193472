import React, {useEffect, useState} from 'react';
import {useGate, useStore} from 'effector-react';
import {Button, Counter, Icon, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {RegionSelect} from 'Features/RegionSelect';
import {SelectItemSolo} from 'shared/ui/Select/Select';
import SearchCategory from 'shared/ui/SearchCategory/SearchCategory';
import ProjectFilter from 'pages/ProjectPage/ProjectFilter/ProjectFilter';
import ProjectPageTable from 'pages/ProjectPage/ProjectPageTable/ProjectPageTable';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import ProjectPageTableSkeleton from 'pages/ProjectPage/ProjectPageTable/ProjectPageTableSkeleton';
import {
    $projectsPage,
    projectListDownloaded,
    handleEnterPress,
    projectsPageGate,
    resetAllFilters,
    resetALlSearchParameters,
    setBranchParam,
    setPage,
    setPageSize,
    setProjectsFilters,
    setRegionParam,
    setSearchValue,
    setSelectedSearchCategory,
    setSortParam,
    setSortType,
} from 'pages/ProjectPage/model';
import {ProjectPagePagination} from 'pages/ProjectPage/ProjectPagePagination/ui/ProjectPagePagination';
import {CommonLayout} from 'shared/ui/CommonLayout'
import {$appliedFiltersCount, resetProjectsFilter} from 'pages/ProjectPage/ProjectFilter/model';

import {SearchCategoriesType} from './model/types';
import {FunctionWrap, Menu, Content} from './style';
import {resetAllSelectedData} from 'Features/RegionSelect/model';

const categoryList: Array<SearchCategoriesType> = [
    {
        title: 'Номер БС',
        type: 'bsNumberOnly',
    },
    {
        title: 'Номер проекта',
        type: 'projectOnly',
    },
    {
        title: 'Код ГФК',
        type: 'bsGfkOnly',
    },
];

const ProjectPage = function () {
    useGate(projectsPageGate);
    const {
        projectList: {count, results: projectTableData},
        searchValue,
        selectedSearchCategory,
        projectListParams: {page, page_size: pageSize},
        isProjectListLoading,
        sortType,
        sortParam,
        isLoading,
        downloadBtnDisabled,
    } = useStore($projectsPage);
    const appliedFiltersCount = useStore($appliedFiltersCount);
    const totalCount = count ?? 0;

    const [projectFilter, setProjectFilter] = useState(false);

    const toggleFilter = () => {
        setProjectFilter((prevState) => !prevState);
    };

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            handleEnterPress();
        }
    }

    const [filterDisplay, setFilterDisplay] = useState(null);
    const [filterPosition, setFilterPosition] = useState(true);

    function editFilterParams(index) {
        if (filterDisplay !== index) {
            setFilterDisplay(index);
            setFilterPosition(true);
        } else if (filterPosition) {
            setFilterDisplay(index);
            setFilterPosition(false);
        } else {
            setFilterDisplay(index);
            setFilterPosition(true);
        }
    }

    useEffect(() => {
        switch (sortParam) {
            case 'project_ext_id':
                editFilterParams(0);
                break;
            case 'open_date':
                editFilterParams(1);
                break;
            case 'year':
                editFilterParams(2);
                break;
            case 'bs_number':
                editFilterParams(3);
                break;
            case 'pos_code':
                editFilterParams(4);
                break;
            case 'bs_gfk':
                editFilterParams(5);
                break;
            case 'types_project':
                editFilterParams(6);
                break;
            case 'branch':
                editFilterParams(9);
                break;
            case 'region':
                editFilterParams(10);
                break;
            case 'statuses_project':
                editFilterParams(8);
                break;
            default:
                setFilterDisplay(null);
                setFilterPosition(true);
                break;
        }
    }, [sortParam, sortType]);

    const resetFilters = () => {
        resetAllFilters();
        resetAllSelectedData();
        resetProjectsFilter();
    };

    return (
        <CommonLayout>
            <Content>
                <WrapperOffset offset={32} overflowY width="true">
                    <Typography variant="h4" className="mb-4">
                        Проекты
                    </Typography>
                    <Menu>
                        <RegionSelect
                            handleSelectedRegion={setRegionParam}
                            handleSelectedBranch={setBranchParam}
                        />
                        <FunctionWrap>
                            <Counter
                                count={appliedFiltersCount}
                                className={`${appliedFiltersCount === 0 && 'emptyCounter'}`}
                            >
                                <Button
                                    startIcon={<Icon iconName={Icons.Tune} />}
                                    variant="outlined"
                                    size="medium"
                                    onClick={toggleFilter}
                                    id="projectsSettingsBtn"
                                />
                            </Counter>
                            <Button
                                startIcon={<Icon iconName={Icons.Download} />}
                                variant="outlined"
                                size="medium"
                                onClick={() => projectListDownloaded()}
                                disabled={downloadBtnDisabled}
                                id="projectsDownloadBtn"
                            />
                            <SearchCategory
                                onKeyPress={(e) => {
                                    handleKeyPress(e);
                                }}
                                onClick={handleEnterPress}
                                inputValue={searchValue}
                                setInputValue={setSearchValue}
                                categoryValue={selectedSearchCategory}
                                categoryValueLabel="title"
                                categoryValueKey="type"
                                onEditCategory={setSelectedSearchCategory}
                                onResetCategory={resetALlSearchParameters}
                            >
                                {categoryList.map((item) => (
                                    <SelectItemSolo
                                        key={item.type}
                                        value={item}
                                        selected={undefined}
                                        valueKey={undefined}
                                        handleEdit={undefined}
                                        setActive={undefined}
                                        id={item.type}
                                    >
                                        {item.title}
                                    </SelectItemSolo>
                                ))}
                            </SearchCategory>
                        </FunctionWrap>
                    </Menu>
                    {isProjectListLoading ? (
                        <ProjectPageTableSkeleton
                            filterDisplay={filterDisplay}
                            filterPosition={filterPosition}
                        />
                    ) : (
                        <ProjectPageTable
                            projects={projectTableData}
                            sortParam={sortParam}
                            setSortParam={setSortParam}
                            sortType={sortType}
                            setSortType={setSortType}
                            filterDisplay={filterDisplay}
                            filterPosition={filterPosition}
                            isSearching={isLoading}
                            resetFilters={resetFilters}
                        />
                    )}
                    <ProjectPagePagination
                        total={totalCount}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                    />
                </WrapperOffset>
                <ProjectFilter
                    active={projectFilter}
                    toggleMenu={setProjectFilter}
                    changeParams={setProjectsFilters}
                />
            </Content>
        </CommonLayout>
    );
};

export default ProjectPage;
