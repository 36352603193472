import styled from 'styled-components/macro';
import Search from 'shared/ui/Search/Search';

export const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
    flex-wrap: wrap;
    align-items: flex-start;
`;

export const StyledSearch = styled(Search)`
    width: 411px;
    margin-left: auto;
`;
