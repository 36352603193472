import React, {useCallback, useState} from 'react';
import {useGate} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import {CommonLayout} from 'shared/ui/CommonLayout';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {CONTRACTS_TITLE, FILTERS_AND_SETTINGS_SIDESHEET_TITLE} from 'shared/constants';

import * as S from './style';
import {SidesheetContent} from './SidesheetContent';
import {ContractTable} from './ContractTable';
import {ContractFilterUnit} from './ContractFilterUnit';
import {ContractListPageGate} from '../model';
import {Content} from '../../ProjectPage/style';

export const ContractListPage = () => {
    useGate(ContractListPageGate);

    const [sidebarActive, setSidebarActive] = useState(false);

    const toggleSidebar = useCallback(
        () => setSidebarActive((prevState) => !prevState),
        [sidebarActive],
    );

    return (
        <CommonLayout>
            <S.StyledSidesheet
                content={<SidesheetContent />}
                title={FILTERS_AND_SETTINGS_SIDESHEET_TITLE}
                isOpen={sidebarActive}
                onClose={toggleSidebar}
            >
                <Content>
                    <WrapperFlex padding="32px" flexDirection="column" gap="24px" width="100%">
                        <Typography variant="h4">{CONTRACTS_TITLE}</Typography>
                        <ContractFilterUnit toggleSidebar={toggleSidebar} />
                        <ContractTable />
                    </WrapperFlex>
                </Content>
            </S.StyledSidesheet>
        </CommonLayout>
    );
};
