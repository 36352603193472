import {createGate} from 'effector-react';
import {combine, createDomain} from 'effector';
import {catalogService} from '../service/catalogs.service';
import {CatalogContract, CatalogTPI} from '../types';
import {CatalogWorkObjectOption} from '../../VirStart/types';

export const newPatternPageDomain = createDomain();

export const NewPatterPageGate = createGate<string>();

export const loadCatalogWorkObjectsFx = newPatternPageDomain.createEffect(
    catalogService.getCatalogWorkObjects,
);
export const loadContractsFx = newPatternPageDomain.createEffect(
    catalogService.getCatalogContracts,
);
export const loadTpisFx = newPatternPageDomain.createEffect(catalogService.getCatalogTpis);

export const selectWorkObject = newPatternPageDomain.createEvent<CatalogWorkObjectOption>();

export const selectContract = newPatternPageDomain.createEvent();
export const selectTpi = newPatternPageDomain.createEvent();
export const selectProjectData = newPatternPageDomain.createEvent();
export const changePatternName = newPatternPageDomain.createEvent<string>();
export const setTemplateContract = newPatternPageDomain.createEvent<SelectOption>();

export const $projectId = newPatternPageDomain.createStore<string>('');
export const $selectedWorkObject = newPatternPageDomain.createStore<CatalogWorkObjectOption | null>(
    null,
);
export const $selectedContract = newPatternPageDomain.createStore<CatalogContract | null>(null);
export const $selectedTpi = newPatternPageDomain.createStore<CatalogTPI | null>(null);
export const $patternName = newPatternPageDomain.createStore('');

export const $contractOptions = newPatternPageDomain.createStore<CatalogContract[]>([]);
export const $tpiOptions = newPatternPageDomain.createStore<CatalogTPI[]>([]);
export const $workObjectsOptions = newPatternPageDomain.createStore<Array<CatalogWorkObjectOption>>(
    [],
);
export const $templateName = newPatternPageDomain.createStore('');

export const $newPatternPage = combine({
    selectedName: $templateName,
    selectedContract: $selectedContract,
    selectedWorkObject: $selectedWorkObject,
    contractOptions: $contractOptions,
    tpiOptions: $tpiOptions,
    selectedTpi: $selectedTpi,
    patternName: $patternName,
    workProjectOptions: $workObjectsOptions,
});

export interface SelectOption {
    id: string;
    name: string;
}
