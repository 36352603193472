import {getVirWorkObjects} from './getters';
import {GetVir} from './types';
import {formatNumber} from 'shared/helpers/formatHelper';
import {TpiSpecification} from 'pages/VirSelect/types';

export const TAX_INTEREST = 20;

export function calcTaxSum(sum: number, tax = TAX_INTEREST): number {
    return sum >= 0 ? formatNumber((sum * tax) / 100) : 0;
}

export function calcTotal(sum: number, tax = TAX_INTEREST): number {
    return sum >= 0 ? formatNumber(sum + calcTaxSum(sum, tax)) : 0;
}

export function calcSumForOneSpecification(item: TpiSpecification) {
    return formatNumber(
        (item.price >= 0 ? item.price : 0) * (item?.coefficient || 1) * (item.quantity || 1),
    );
}

export function calsSumForSpecs(specs: TpiSpecification[]) {
    return specs.reduce((acc, curr) => acc + calcSumForOneSpecification(curr), 0);
}

export function getCalculatedSpecsWithDefaultQuantity(items: TpiSpecification[]) {
    return items?.reduce(
        (acc, curr) => ({
            ...acc,
            [`${curr.id}`]: {
                ...curr,
                sum: calcSumForOneSpecification(curr),
                sum_tax:
                    curr.is_tax || curr.sum_tax > 0
                        ? calcTaxSum(calcSumForOneSpecification(curr))
                        : 0,
                total_sum:
                    curr.is_tax || curr.sum_tax > 0
                        ? calcTotal(calcSumForOneSpecification(curr))
                        : calcSumForOneSpecification(curr),
                quantity: curr.quantity || 1,
            },
        }),
        {},
    );
}

export function calculateVirTotals({ds, virId}: GetVir): {
    sum: number;
    sum_tax: number;
    total_sum: number;
} {
    const workObjects = getVirWorkObjects({ds, virId});
    const work_specifications = (Object.values(workObjects) || [])
        ?.map((el) => Object?.values(el?.work_specifications || []))
        .flat();
    const vatRate = work_specifications.some((item) => item.is_tax || item.sum_tax > 0) ? 20 : 0;

    const sum = (Object.values(workObjects) || [])
        ?.map((el) => Object?.values(el?.work_specifications || [])?.map((el) => el?.sum || 0))
        ?.flat()
        .reduce((acc, curr) => acc + curr, 0 as number);
    const sum_tax = calcTaxSum(sum, vatRate);
    return {
        sum,
        sum_tax,
        total_sum: formatNumber(sum + sum_tax),
    };
}

export function calculateWorkObjectTotalsBySpecs({specs}: {specs: TpiSpecification[]}): {
    sum: number;
    sum_tax: number;
    total_sum: number;
} {
    const sum = calsSumForSpecs(specs);
    const sum_tax = specs.reduce((sum, item) => {
        return (
            sum +
            (item.is_tax || item.sum_tax > 0 ? calcTaxSum(calcSumForOneSpecification(item)) : 0)
        );
    }, 0);
    return {
        sum,
        sum_tax,
        total_sum: formatNumber(sum + sum_tax),
    };
}
