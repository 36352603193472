import styled from 'styled-components';

export const SearchWrapper = styled.div`
    margin-top: 24px;
    width: 929px;
    @media (max-width: 1000px) {
        width: 100%;
    }
`;

export const TableContent = styled.div`
    padding-top: 24px;
    width: 100%;
    align-self: center;
`;

export const Buttons = styled.div`
    display: flex;
    align-self: flex-end;
    margin-top: 24px;
`;

export const TableWrapper = styled.div`
    border: 1px solid #d1d1d1;
    border-radius: 4px;

    & > * {
        tr {
            &:first-child {
                border-radius: 4px 4px 0 0;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
        }

        &:last-child {
            border-bottom: none;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    --min-w: none;
`;

export const NotFoundWrapper = styled.div`
    padding: 16px;
    height: calc(100vh - 500px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
