import React from 'react';
import {Counter, IconButton} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {TableCell} from 'shared/ui/TableCell';

interface CommentCellProps {
    onClick: () => void;
    hasComments?: boolean;
    showButton?: boolean;
    attachmentCount?: number;
}

export const CommentCell = ({
    onClick,
    hasComments = false,
    showButton = true,
    attachmentCount = 0,
}: CommentCellProps) => {
    const iconName = hasComments ? Icons.MessageText : Icons.Message;
    return (
        <TableCell width="56px">
            {showButton && (
                <Counter count={attachmentCount} className={`${attachmentCount === 0 && 'emptyCounter'}`}>
                    <IconButton iconName={iconName} onClick={onClick} size="large" />
                </Counter>
            )}
        </TableCell>
    );
};

