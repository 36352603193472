import {TTpiWithRemoteTerritory} from 'shared/types/tpiTypes';

export type PurchasingEventType = {
    id: string;
    code: string;
    name: string;
};

export type PurchasingEventTypesResponse = Array<PurchasingEventType>;

export type BiddingParticipationsRequestType = {
    search?: string;
    statuses?: string;
    project: string;
    purchasing_event_type?: string;
    page: number;
    page_size: number;
};

export type BiddingParticipationsResponseType = {
    count: number;
    next?: string;
    previous?: string;
    results: Array<PurchasingEventInfoType>;
};

export interface PurchasingEventInfoType {
    bidding_participation: BiddingParticipationType;
    contract: BiddingContractType;
    purchasing_event: BiddingEventType;
}

export interface BiddingParticipationTypeForStage1 extends BiddingParticipationType {
    tpiList: TTpiWithRemoteTerritory[];
}

export interface TPurchasingEventInfoForStage1 extends PurchasingEventInfoType {
    bidding_participation: BiddingParticipationTypeForStage1;
}

export enum PrimeContractorStatusEnum {
    Winner = 1,
    ContigentWinner = 2,
    Removed = 3,
    Excluded = 4,
}

export type BiddingParticipationType = {
    id: string;
    amount: number;
    is_screening: boolean;
    organization_id: string;
    organization_inn: string;
    organization_short_name: string;
    percent: number;
    purchase_lot: PurchaseLotType;
    rank: number;
    status: PurchasingEventStatusEnum;
    tpis: tpiType[];
};

export type BiddingContractType = {
    date_conclusion: string;
    id: string;
    number: string;
    signatory: string;
};

export type BiddingEventType = {id: string; number: string; type: string};

export interface IPurchasingEvent extends BiddingEventType {
    begin_date: string;
    end_date: string;
}

export type TRemoteTerritory = {id: string; name: string};

export type PurchaseLotType = {
    id: string;
    name: string;
};

export type tpiType = {
    id: string;
    name: string;
    remote_territories?: TRemoteTerritory[];
};

export enum PurchasingEventStatusEnum {
    WIN = 1,
    PRE_WIN = 2,
    RES = 3,
    EXCL = 4,
}

export interface IContractPurchasingEvent {
    begin_date: string;
    end_date: string;
    id: string;
    lots: IPurchasingEventLot[];
    number: string;
    type: string;
    year: number;
}

export interface IPurchasingEventLot {
    branches: string[];
    geography_name: string;
    id: string;
    name: string;
    tpis: tpiType | null;
}
