import React, {useCallback} from 'react';
import {useStore} from 'effector-react';
import {Autocomplete, Option, Typography} from '@beeline/design-system-react';

import {formatDate} from 'shared/helpers/formatHelper';
import {ICopingAdditionalAgreement} from 'shared/types/additionalAgreementsTypes';

import * as S from './style';
import {
    $copyAgreementConstructorStore,
    searchStringChanged,
    copiedAgreementSelected,
} from '../../../model';

interface AutocompleteDropdownRowProps {
    agreement: ICopingAdditionalAgreement;
}

export const AgreementAutocomplete = () => {
    const {agreementListLoading, availableAgreementList, selectedAgreement} = useStore(
        $copyAgreementConstructorStore,
    );

    const handleRender = useCallback((option: Option<ICopingAdditionalAgreement>) => {
        return option.value.number;
    }, []);

    const handleInputChange = (inputValue: string) => {
        searchStringChanged(inputValue);
    };

    const handleInputClear = () => {
        searchStringChanged('');
    };

    return (
        <Autocomplete<ICopingAdditionalAgreement>
            placeholder="Номер доп. соглашения"
            size="medium"
            dropdownClassName="coping_dropdown"
            type="search"
            onChange={copiedAgreementSelected}
            renderValue={handleRender}
            onInputChange={handleInputChange}
            onInputClear={handleInputClear}
            makeOption={(option: Option<ICopingAdditionalAgreement>, _) => (
                <AutocompleteDropdownRow agreement={option.value} />
            )}
            value={selectedAgreement}
            options={availableAgreementList}
            loading={agreementListLoading}
            fullWidth
        />
    );
};

const AutocompleteDropdownRow = ({agreement}: AutocompleteDropdownRowProps) => {
    return (
        <S.RowWrapper>
            <S.AgreementNumberWrapper addition={agreement.addendum_number !== null}>
                <Typography variant="body2">
                    {agreement.addendum_number
                        ? `Дополнение ${agreement.addendum_number}`
                        : agreement.number}
                </Typography>
                <Typography variant="body3" className="inactiveText">
                    {`Создан ${formatDate(agreement.created_at)}`}
                </Typography>
            </S.AgreementNumberWrapper>
            <S.AgreementNameWrapper>
                <Typography variant="body2">{agreement.bs_name}</Typography>
                <Typography variant="body3" className="inactiveText">
                    {agreement.work_types}
                </Typography>
            </S.AgreementNameWrapper>
            <S.AgreementBranchWrapper>
                <Typography variant="body3" className="inactiveText">
                    {`${agreement.branch} филиал`}
                </Typography>
            </S.AgreementBranchWrapper>
        </S.RowWrapper>
    );
};
