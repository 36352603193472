import {combine, createDomain, forward, sample} from 'effector';
import {createGate} from 'effector-react';

import {tcpService} from 'pages/VirSelect/service/tcp.service';
import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';
import {TpiSpecification} from 'pages/VirSelect/types';

import {$activeStepId, $projectId, $tpiStore, resetAllDomains} from './index';

export const jobProcessDomainStage2 = createDomain();
export const jobProcessGateStage2 = createGate();

//events
export const setSearchValue = jobProcessDomainStage2.createEvent<string>();
export const resetSearchValue = jobProcessDomainStage2.createEvent();
export const setPage = jobProcessDomainStage2.createEvent<number>();
export const resetPage = jobProcessDomainStage2.createEvent();
export const setPageSize = jobProcessDomainStage2.createEvent<number>();
export const changeSelectedTpiData = jobProcessDomainStage2.createEvent<TpiSpecification>();
export const resetSelectedTpiData = jobProcessDomainStage2.createEvent();
export const changeAllPageSelection = jobProcessDomainStage2.createEvent<boolean>();
export const resetTpiData = jobProcessDomainStage2.createEvent();

//effects
export const fetchTpiDataFx = jobProcessDomainStage2.createEffect(tcpService.getTpiSpecifications);

//stores
export const $searchValue = jobProcessDomainStage2
    .createStore('')
    .on(setSearchValue, forwardPayload())
    .reset(resetSearchValue);

export const $page = jobProcessDomainStage2
    .createStore(1)
    .on(setPage, forwardPayload())
    .reset(resetPage);

export const $pageSize = jobProcessDomainStage2.createStore(10).on(setPageSize, forwardPayload());

export const $tpiData = jobProcessDomainStage2
    .createStore<TpiSpecification[]>([])
    .on(fetchTpiDataFx.doneData, (state, payload) => payload.data.results)
    .reset(resetTpiData);

export const $count = jobProcessDomainStage2
    .createStore<number>(0)
    .on(fetchTpiDataFx.doneData, (state, payload) => {
        return payload.data.count;
    });

export const $selectedTpiData = jobProcessDomainStage2
    .createStore<TpiSpecification[]>([])
    .on(changeSelectedTpiData, (state, payload) => {
        if (state.some((item) => item.id === payload.id)) {
            return state.filter((item) => item.id !== payload.id);
        } else {
            return [...state, payload];
        }
    })
    .reset(resetSelectedTpiData);

const $allChecked = jobProcessDomainStage2.createStore<boolean>(false);

const $isMainButtonDisabled = jobProcessDomainStage2.createStore<boolean>(true);

export const $tpiRequestParams = sample({
    source: combine({
        tpi_store: $tpiStore,
        project_id: $projectId,
        page: $page,
        page_size: $pageSize,
        search: $searchValue,
    }),
    fn: ({tpi_store, project_id, page, page_size, search}) => ({
        tpi_id: tpi_store.tpi_id,
        remote_territory_id: tpi_store.remote_territory_id,
        project_id,
        page,
        page_size,
        search,
        is_job_tasks: true,
    }),
});

export const $stage2CommonStore = combine({
    tpiData: $tpiData,
    selectedTpiData: $selectedTpiData,
    count: $count,
    isLoading: fetchTpiDataFx.pending,
    searchValue: $searchValue,
    page: $page,
    pageSize: $pageSize,
    allChecked: $allChecked,
    isMainButtonDisabled: $isMainButtonDisabled,
});

//загружает данные при переходе на шаг 2 и при изменении параметров поиска
sample({
    clock: [jobProcessGateStage2.open, $tpiRequestParams],
    source: combine({
        params: $tpiRequestParams,
        currentStep: $activeStepId,
    }),
    filter: ({params, currentStep}) => currentStep === 'step2' && params.tpi_id !== '',
    fn: ({params}) => params,
    target: fetchTpiDataFx,
});

// обновление стора allChecked (все ли пункты выбраны на данной странице)
sample({
    source: combine({
        tpiData: $tpiData,
        selectedTpiData: $selectedTpiData,
    }),
    fn: ({tpiData, selectedTpiData}) => {
        return tpiData.every((el) => selectedTpiData.some((e) => e.id === el.id));
    },
    target: $allChecked,
});

// логика работы чекбокса заголовка таблицы
sample({
    clock: changeAllPageSelection,
    source: combine({
        tpiData: $tpiData,
        selectedTpiData: $selectedTpiData,
    }),
    fn: ({tpiData, selectedTpiData}, clock) => {
        if (clock) {
            return [...selectedTpiData, ...tpiData];
        }
        return selectedTpiData.filter((item) => !tpiData.some((el) => el.id === item.id));
    },
    target: $selectedTpiData,
});

// сброс пагинации при поиске
sample({
    source: $searchValue,
    target: resetPage,
});

sample({
    source: $selectedTpiData,
    fn: (source) => source.length === 0,
    target: $isMainButtonDisabled,
});

forward({from: jobProcessGateStage2.close, to: resetTpiData});

resetDomainStoresByEvents(jobProcessDomainStage2, resetAllDomains);
