import React from 'react';
import {Link} from 'react-router-dom';
import {Checkbox, IconButton, Label, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {snackbarNotificationChanged} from 'Entities/SnackbarNotification/model';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {LINK_COPIED} from 'shared/constants';

import * as S from './style';

interface CheckboxLabelProps {
    errorId: string;
    checked: boolean;
    label: string;
    prompt: string;
    errorCode: string;
    link: string | null;
    linkTitle: string | null;
    copyLink: string | null;
    onChange: (id: string) => void;
    disabled: boolean;
}

export const ErrorItem = (props: CheckboxLabelProps) => {
    const {checked, errorId, disabled, onChange} = props;
    return (
        <Checkbox
            label={<CheckboxLabel {...props} />}
            checked={checked}
            onChange={() => onChange(errorId)}
            disabled={disabled}
        />
    );
};

const CheckboxLabel = ({
    label,
    prompt,
    errorCode,
    link,
    linkTitle,
    copyLink,
}: CheckboxLabelProps) => {
    const onCopy = async () => {
        try {
            if (copyLink) {
                navigator.clipboard.writeText(copyLink);
                snackbarNotificationChanged(LINK_COPIED);
            }
        } catch (e) {
            console.warn(e);
        }
    };

    return (
        <WrapperFlex flexDirection="column" gap="8px" ml="16px">
            <Typography variant="subtitle3">{label}</Typography>
            <Typography variant="body3">{prompt}</Typography>
            <WrapperFlex alignItems="center" gap="16px">
                <Label type="default" title={errorCode} />
                {link && <Link to={link}>{linkTitle}</Link>}
            </WrapperFlex>
            {copyLink && (
                <S.StyledTextField
                    defaultValue={copyLink}
                    endAdornment={<IconButton iconName={Icons.Copy} onClick={onCopy} />}
                    // helperText="Ресурс доступен только в "
                />
            )}
        </WrapperFlex>
    );
};
