import {useStore} from 'effector-react';
import React, {useMemo} from 'react';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {
    $selectedPaginationParams,
    changeSearchSelectedInput,
    filterSelectedBySearchTerm,
} from '../../model/SelectedTpisTab';
import {SELECT_TPIS_ACTIVE_TAB} from '../../types';
import {$activeTab} from '../../model/SelectTpisPage';
import {SearchStyled} from './style';
import {
    $allTpisPaginationParams,
    changeSearchAllInput,
    filterAllBySearch,
} from '../../model/AllTpisTab';
import {debounce} from 'lodash';

export function SearchTpisInput() {
    const activeTab = useStore($activeTab);
    const {searchAllTerm} = useStore($allTpisPaginationParams);
    const {selectedSearchTerm} = useStore($selectedPaginationParams);

    const isAllTab = activeTab === SELECT_TPIS_ACTIVE_TAB.ALL_TPIS;
    const searchTerm = isAllTab ? searchAllTerm : selectedSearchTerm;
    const setSearchTerm = isAllTab ? changeSearchAllInput : changeSearchSelectedInput;
    const searchBySearchTerm = isAllTab ? filterAllBySearch : filterSelectedBySearchTerm;

    const debouncedResults = useMemo(() => {
        return debounce(searchBySearchTerm, 400);
    }, [searchBySearchTerm]);

    return (
        <WrapperOffset offsetTop="24" offsetBottom="24">
            <SearchStyled
                id="tpiSearch"
                value={searchTerm}
                onInput={(value) => {
                    setSearchTerm(value.trim());
                    debouncedResults();
                }}
                placeholder="Поиск по названию, номеру"
            />
        </WrapperOffset>
    );
}
