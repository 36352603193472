import {forwardPayload} from 'shared/helpers/effector';
import {combine, sample} from 'effector';
import {
    $allDecreasingCoefficients,
    $filteredDecreasingCoefficients,
    $searchDecreasingTerm,
    $selectedDecreasingCoefficients,
    filterDecreasingBySearchTerm,
    setDecreasingSearchTerm,
    toggleDecreasingSelect,
} from './index';
import {$tpiToConfigCoefficient, loadCoefficientsFx, setActiveTab} from '../index';

// fill with loaded data
sample({
    clock: loadCoefficientsFx.doneData,
    fn: ({data}) => data?.[0]?.decreasing_coefficients || [],
    target: [$allDecreasingCoefficients, $filteredDecreasingCoefficients],
});

// filter
$searchDecreasingTerm.on(setDecreasingSearchTerm, forwardPayload()).reset(setActiveTab);

sample({
    clock: filterDecreasingBySearchTerm,
    source: combine({searchTerm: $searchDecreasingTerm, coefficients: $allDecreasingCoefficients}),
    fn: ({searchTerm, coefficients}) => {
        const regexp = new RegExp(searchTerm, 'i');
        const searchByNumber = /[\.*\d*]/.test(searchTerm);
        return coefficients.filter((el) => {
            const testName = regexp.test(el.name);
            const testNumber = regexp.test(el.number);
            const testNote = el?.note && regexp.test(el.note);
            return searchTerm
                ? searchByNumber
                    ? testNumber
                    : el?.note
                    ? testName || testNote
                    : testName
                : el;
        });
    },
    target: $filteredDecreasingCoefficients,
});

$selectedDecreasingCoefficients.on(toggleDecreasingSelect, (state, payload) =>
    state.includes(payload) ? [] : [payload],
);

// selectedCoefficients from constructor
sample({
    clock: loadCoefficientsFx.doneData,
    source: combine({
        tpiFromConstructor: $tpiToConfigCoefficient,
        allDecreasingCoefficients: $allDecreasingCoefficients,
    }),
    fn: ({tpiFromConstructor, allDecreasingCoefficients}) => {
        if (!tpiFromConstructor?.spec?.work_coefficients?.length) {
            return [];
        }
        const decreasingCoefficientIds = tpiFromConstructor?.spec?.work_coefficients?.map(
            (el) => el.tpi_specification ?? el.id,
        );
        return allDecreasingCoefficients.filter((el) => decreasingCoefficientIds.includes(el.id));
    },
    target: $selectedDecreasingCoefficients,
});
