import React from 'react';
import {useStore} from 'effector-react';
import {Icon, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {CoefficientUnit} from 'Entities/CoefficientUnit';
import {SealedPriceData} from 'Entities/TableCells/SealedPriceData';
import {DsSpecificationCommentsAndFilesRow} from 'widgets/DsSpecificationCommentsAndFilesRow';
import {$loadingAttachmentId} from 'pages/DSPage/model';
import {CoefficientIndex} from 'pages/VirConstructor/SpecificationRow/Cells/PositionCell';
import CommentIcon, {CommentAddIcon} from 'icons/CommentIcon';
import {formatCurrency, formatNumberToDecimalWithComma} from 'shared/helpers/formatHelper';
import {TableCellContent} from 'shared/ui/Table/Table';
import Popover, {PopoverActionPanel, PopoverButton} from 'shared/ui/deprecated/Popover/Popover';
import {Tooltip} from 'shared/ui/deprecated/Tooltip/Tooltip';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {copyToClipboard} from 'shared/helpers/commonFunctions';
import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import * as S from 'pages/DSPage/ui/customTableStyle';

import {IAgreementWorkSpecification} from '../../types';

export interface WorSpecificationProps {
    index: number;
    data: IAgreementWorkSpecification;
    showAdds: (id: string) => void;
    activeAdds: string | null;
    currentVirId: string;
    currentWorkObjectId: string;
    currentWidth: number;
    taskStatus: number;
    isEditable: boolean;
    isVK: boolean;
}

export function WorkExecutionDataRow(props: WorSpecificationProps) {
    const {index, data, showAdds, activeAdds, currentWidth} = props;
    const loadingAttachmentId = useStore($loadingAttachmentId);
    const isAgreedPrice = data?.is_agreed_price;
    const isCategory = data?.is_category;
    const beginDate = `${
        data.begin_date ? new Date(data.begin_date).toLocaleDateString() : 'Дата подписания'
    } -`;
    const endDate = `${data.end_date ? new Date(data?.end_date).toLocaleDateString() : ''}`;
    const {
        work_specification_comments: comments,
        work_specification_attachments: attachments,
        work_detail,
        category_name,
    } = data;

    const isActiveRow = activeAdds === data?.id;

    const isShowCommentIcon =
        (data.work_specification_attachments?.length &&
            data.work_specification_attachments?.length > 0) ||
        (data.work_specification_comments && data.work_specification_comments.length > 0);

    const appliedCoefficientNumbers = data.work_coefficients
        ?.filter((el) => !el.is_manual && el.number)
        .map((el) => el.number)
        .join(', ');

    // ширина строки контента для корректного отображения границы при горизонтальной прокрутке контента
    const rowWidth = currentWidth + 837;
    return (
        <>
            <S.TableDiv>
                <S.CustomTableRow width={`${rowWidth}px`} height="108px">
                    <S.CustomTableCell flex="0 0 90px" justifyContent="flex-start">
                        {index + 1}
                    </S.CustomTableCell>
                    <S.CustomTableCell flex="0 0 80px" justifyContent="flex-start">
                        <TableCellContent className="col-4">
                            <WrapperFlex flexDirection="column">
                                <span>{data.number}</span>
                                {!!data.coefficient && (
                                    <Tooltip
                                        titleText={appliedCoefficientNumbers}
                                        position="top-start"
                                        vertical={12}
                                        horyzontal={15}
                                    >
                                        <CoefficientIndex>
                                            {appliedCoefficientNumbers}
                                        </CoefficientIndex>
                                    </Tooltip>
                                )}
                            </WrapperFlex>
                        </TableCellContent>
                    </S.CustomTableCell>
                    <S.CustomTableCell flex={`0 0 ${currentWidth}px`} justifyContent="flex-start">
                        <WrapperFlex justifyContent="flex-start" alignItems="flex-start">
                            {data.note && (
                                <Popover
                                    component={
                                        <>
                                            <div>{data.note}</div>
                                            <PopoverActionPanel>
                                                <PopoverButton
                                                    onClick={() => copyToClipboard(data.note)}
                                                >
                                                    Скопировать
                                                </PopoverButton>
                                            </PopoverActionPanel>
                                        </>
                                    }
                                >
                                    <S.IconContainter>
                                        <Icon iconName={Icons.InfoCircled} size="small" />
                                    </S.IconContainter>
                                </Popover>
                            )}
                            <TooltipedSpan
                                lineCount={5}
                                title={<Typography variant="body3">{data.name}</Typography>}
                                noCursor
                            >
                                {data.name}
                            </TooltipedSpan>
                        </WrapperFlex>
                    </S.CustomTableCell>
                    <S.CustomTableCell flex="0 0 78px" justifyContent="flex-start">
                        {formatNumberToDecimalWithComma(data.quantity, 0, 3)}
                    </S.CustomTableCell>
                    <S.CustomTableCell
                        flex="0 0 142px"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <SealedPriceData
                            isAgreedPrice={isAgreedPrice}
                            price={data.price}
                            unit={data.unit}
                        />
                    </S.CustomTableCell>
                    <S.CustomTableCell flex="0 0 104px" justifyContent="flex-start">
                        <CoefficientUnit
                            coefficients={data.work_coefficients}
                            territoryCoefficient={data.coefficient_remote_territory}
                            totalCoefficient={data.coefficient}
                            amsCoefficient={data.is_ams ? data.coefficient_ams : undefined}
                            foundationCoefficient={
                                data.is_foundation ? data.coefficient_foundation : undefined
                            }
                            position="top"
                        />
                    </S.CustomTableCell>
                    <S.CustomTableCell
                        flex="0 0 132px"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                    >
                        {data?.sum && formatCurrency(data.sum.toString())}
                    </S.CustomTableCell>
                    <S.CustomTableCell flex="0 0 161px" justifyContent="flex-start">
                        <TableCellContent>
                            <WrapperFlex flexDirection="column" justifyContent="flex-end">
                                <Typography variant="body3">{beginDate}</Typography>
                                <Typography variant="body3">{endDate}</Typography>
                            </WrapperFlex>
                        </TableCellContent>
                    </S.CustomTableCell>
                    <S.CustomTableCell flex="0 0 50px" justifyContent="flex-start">
                        {isShowCommentIcon && (
                            <TableCellContent>
                                {data.work_specification_attachments?.length ? (
                                    <S.CommentBtn onClick={() => data?.id && showAdds(data?.id)}>
                                        <Tooltip
                                            titleText={
                                                !!data?.work_specification_attachments?.length
                                                    ? 'Комментарий с вложениями'
                                                    : 'Комментарий'
                                            }
                                            position="top-end"
                                            horyzontal={0}
                                            vertical={5}
                                        >
                                            <CommentAddIcon />
                                            {!!data?.work_specification_attachments?.length && (
                                                <S.CounterStatement>
                                                    {data.work_specification_attachments?.length}
                                                </S.CounterStatement>
                                            )}
                                        </Tooltip>
                                    </S.CommentBtn>
                                ) : (
                                    <S.CommentBtn onClick={() => showAdds(data?.id)}>
                                        <Tooltip
                                            titleText={
                                                data.is_agreed_price
                                                    ? 'Заполнение комментария обязательно!'
                                                    : 'Добавить комментарий'
                                            }
                                            position="top-end"
                                            horyzontal={0}
                                            vertical={5}
                                        >
                                            <CommentIcon />
                                            {data.is_agreed_price ? (
                                                <S.CounterStatement error>!</S.CounterStatement>
                                            ) : (
                                                !!data.work_specification_attachments?.length && (
                                                    <S.CounterStatement>
                                                        {
                                                            data.work_specification_attachments
                                                                ?.length
                                                        }
                                                    </S.CounterStatement>
                                                )
                                            )}
                                        </Tooltip>
                                    </S.CommentBtn>
                                )}
                            </TableCellContent>
                        )}
                    </S.CustomTableCell>
                </S.CustomTableRow>
            </S.TableDiv>

            <S.CustomTableRow className="virCommentRow" disable={!isActiveRow} noBorder>
                <S.CustomTableCell flex="1 0 100%" justifyContent="flex-end">
                    <DsSpecificationCommentsAndFilesRow
                        attachments={attachments}
                        comments={comments}
                        loadingAttachmentId={loadingAttachmentId}
                        isCategory={isCategory}
                        jobCategory={work_detail}
                        jobCategoryDescription={category_name}
                    />
                </S.CustomTableCell>
            </S.CustomTableRow>
        </>
    );
}
