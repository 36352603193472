import {useNavigate} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Button, Icon, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {BodyContent, MainWrapper} from 'components/PageWraps';
import {
    $agreementType,
    $createAdditionalAgreementData,
    addWorkObjectsListToVirs,
} from 'processes/contructor/model/stores';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';

import {AgreementConstructorHeader} from './Header';
import * as S from './style';
import {WorkObjectItems} from './WorkObjectItems';
import {$addedWorkObjectPage, addNewRow, AddWorkObjectGate} from '../model';

export function AddWorkObjects() {
    useGate(AddWorkObjectGate);
    const {selectedWorkObjects} = useStore($addedWorkObjectPage);
    const ds = useStore($createAdditionalAgreementData);
    const agreementMode = useStore($agreementType);

    const navigate = useNavigate();

    function handleSaveAddedWorkProjects() {
        addWorkObjectsListToVirs(selectedWorkObjects);
        if (ds?.id) {
            navigate(`/additional-agreement/${ds.id}/constructor/`);
        } else {
            navigate(`/additional-agreement/constructor`);
        }
    }

    return (
        <>
            <S.GlobalStyles />
            <MainWrapper>
                <AgreementConstructorHeader agreement={ds} agreementType={agreementMode} />
                <BodyContent>
                    <WrapperOffset offset="32" className="w-100">
                        <S.ContentWrapper>
                            <Typography variant="h4">Добавить объект</Typography>

                            <WorkObjectItems />

                            <S.StyledButton
                                id="addRow"
                                variant="outlined"
                                startIcon={<Icon iconName={Icons.Add} size="medium" />}
                                size="medium"
                                onClick={() => addNewRow()}
                            >
                                Добавить еще
                            </S.StyledButton>
                            <S.Buttons>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    onClick={() => navigate(-1)}
                                >
                                    Назад
                                </Button>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={handleSaveAddedWorkProjects}
                                >
                                    Сохранить
                                </Button>
                            </S.Buttons>
                        </S.ContentWrapper>
                    </WrapperOffset>
                </BodyContent>
            </MainWrapper>
        </>
    );
}
