import styled, {css} from 'styled-components/macro';

const baseButtonMixin = () => css`
    display: flex;
    align-items: center;
    padding-top: var(--pt);
    padding-bottom: var(--pb);
    padding-left: var(--pl);
    padding-right: var(--pr);
    font-weight: 500;
    font-size: 17px;
    line-height: calc(22 / 17);
    border-radius: 12px;
    outline: none;
    transition-duration: 0.4s;
    box-shadow: none;
    background-color: var(--bs-btn-bg);
    border: 1px solid var(--bs-btn-border-color);
    color: var(--bs-btn-color);
    --bs-btn-bg: #ffffff;
    --bs-btn-border-color: #000000;
    --bs-btn-color: #000000;
    --pt: 12px;
    --pb: 12px;
    --pl: 20px;
    --pr: 20px;
    --iconsize: 20px;

    &:focus {
        border-color: var(--bs-btn-focus-border-color);
        background-color: var(--bs-btn-focus-bg);
        color: var(--bs-btn-focus-color);
    }

    &:active:focus {
        border-color: var(--bs-btn-active-border-color);
        background-color: var(--bs-btn-active-bg);
        color: var(--bs-btn-active-color);
    }

    &:hover {
        background-color: var(--bs-btn-hover-bg);
        border-color: var(--bs-btn-hover-border-color);
        color: var(--bs-btn-hover-color);
    }

    .icon {
        width: var(--iconsize);
        height: var(--iconsize);
        color: var(--bs-btn-color);
    }

    span {
        display: inline-block;
        white-space: nowrap;
    }
`;

const PlainButton = () => css`
    --bs-btn-color: var(--blue);
    --bs-btn-bg: #ffffff;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: var(--blue);
    --bs-btn-hover-bg: #edf4fe;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-focus-border-color: #202123;
    --bs-btn-focus-bg: #ffffff;
    --bs-btn-focus-color: var(--blue);
    --bs-btn-active-color: var(--blue);
    --bs-btn-active-bg: #e3eefc;
    --bs-btn-active-border-color: transparent;
`;

const OutlineButton = () => css`
    --bs-btn-color: var(--body-color);
    --bs-btn-bg: #ffffff;
    --bs-btn-border-color: #d5d6da;
    --bs-btn-hover-color: var(--body-color);
    --bs-btn-hover-bg: #ededef;
    --bs-btn-hover-border-color: #c7c8cd;
    --bs-btn-focus-border-color: #202123;
    --bs-btn-focus-bg: #ffffff;
    --bs-btn-focus-color: var(--body-color);
    --bs-btn-active-color: var(--body-color);
    --bs-btn-active-bg: #d1d2d6;
    --bs-btn-active-border-color: #afb1b8;
`;
const ContainedButton = () => css`
    --bs-btn-color: #171718;
    --bs-btn-bg: #fdd835;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #171718;
    --bs-btn-hover-bg: #fdc435;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-focus-border-color: #202123;
    --bs-btn-focus-bg: #fdd835;
    --bs-btn-focus-color: #171718;
    --bs-btn-active-color: #171718;
    --bs-btn-active-bg: #fdb435;
    --bs-btn-active-border-color: transparent;
`;
const OverlayButton = () => css`
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #141622;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #1f212d;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-focus-border-color: transparent;
    --bs-btn-focus-bg: #141622;
    --bs-btn-focus-color: #ffffff;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #2a2c39;
    --bs-btn-active-border-color: transparent;
`;

const SmallButton = () => css`
    --pt: 9px;
    --pb: 9px;
    --pl: 16px;
    --pr: 16px;
    --iconsize: 18px;
`;
const MediumButton = () => css`
    --pt: 12px;
    --pb: 12px;
    --pl: 20px;
    --pr: 20px;
    --iconsize: 20px;
`;
const LargeButton = () => css`
    --pt: 15px;
    --pb: 15px;
    --pl: 24px;
    --pr: 24px;
    --iconsize: 24px;
`;
const LeftIcon = () => css`
    span {
        margin-left: 8px;
    }
`;
const RightIcon = () => css`
    span {
        margin-right: 8px;
    }
`;
const OnlyIcon = () => css`
    span {
        margin: 0 !important;
    }

    padding: 11px !important;
`;
export const buttonMixin = (props) => css`
    ${baseButtonMixin()}
    ${props.color === 'plain' && PlainButton}
  ${props.color === 'outline' && OutlineButton}
  ${props.color === 'contained' && ContainedButton}
  ${props.color === 'overlay' && OverlayButton}

  ${props.size === 'small' && SmallButton}
  ${props.size === 'medium' && MediumButton}
  ${props.size === 'large' && LargeButton}
  ${props.leftIcon && LeftIcon}
  ${props.rightIcon && RightIcon}
  ${props.onlyIcon && OnlyIcon}
  ${props.fullWidth &&
    css`
        width: 100%;
        justify-content: center;
    `}
  ${props.disabled &&
    css`
        opacity: 0.48;
        pointer-events: none;
    `}
`;

export const ButtonStyled = styled.button`
    ${(props) => buttonMixin(props)}
`;
