import {useStore} from 'effector-react';
import styled from 'styled-components/macro';

import {$createAdditionalAgreementData, UpdateCurrentSpec} from 'processes/contructor/model/stores';
import {SpecInfo, TpiSpecification} from 'pages/VirSelect/types';
import {TableCell, TableCellContent} from 'shared/ui/Table/Table';
import {DatePickerCell} from 'shared/ui/DatePickerCell';
import {EAdditionalAgreementType} from 'shared/types/additionalAgreementsTypes';
import {compareDates, getFutureDate} from 'shared/helpers/dates';

interface DateCellProps {
    agreementType: EAdditionalAgreementType | null;
    data: TpiSpecification;
    specInfo: SpecInfo;
    updateSpecDate: (spec: UpdateCurrentSpec) => void;
    touched: boolean;
    isBegin: boolean;
}

export function DateCell(props: DateCellProps) {
    const {agreementType, data, specInfo, touched, updateSpecDate, isBegin} = props;
    const {parent_signing_date: parentSigningDate} = useStore($createAdditionalAgreementData);
    const requiredDate = isBegin ? data.begin_date : data.end_date;
    const checkDateIsInvalid = () => {
        switch (agreementType) {
            case EAdditionalAgreementType.Agreement: {
                return !!data.end_date && !compareDates(getFutureDate(10), new Date(data.end_date));
            }
            case EAdditionalAgreementType.Addition: {
                return (
                    !data.end_date ||
                    !parentSigningDate ||
                    !compareDates(new Date(parentSigningDate), new Date(data.end_date))
                );
            }
            default: {
                return false;
            }
        }
    };

    const isInvalidDate = checkDateIsInvalid();
    const hasError = touched && !isBegin && (!requiredDate || isInvalidDate);
    const requiredDefaultDate = isBegin ? undefined : data.default_end_date;

    return (
        <DateTableCell error={hasError} width="106px">
            <DateTableCellContent>
                <DatePickerCell
                    dates={{
                        minDate: parentSigningDate,
                        date: requiredDate,
                        default_date: requiredDefaultDate,
                    }}
                    updateDatesStore={([date, defaultDate]) => {
                        updateSpecDate({
                            ...specInfo,
                            valuesToUpdate: isBegin
                                ? {
                                      begin_date: date,
                                  }
                                : {end_date: date, default_end_date: defaultDate},
                        });
                    }}
                    isBegin={isBegin}
                    agreementType={agreementType}
                />
            </DateTableCellContent>
        </DateTableCell>
    );
}

export const DateTableCell = styled(TableCell)`
    padding: 0;
    ${(p) => p.error && 'background:var(--red-avatar); box-shadow:inset 0 0 0 1px  var(--red);'};
`;

export const DateTableCellContent = styled(TableCellContent)`
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &:hover {
        background: rgba(25, 28, 52, 0.08);
        box-shadow: inset 0 0 0 1px #202123;
    }
`;
