import styled, {css} from 'styled-components/macro';

export const FinalSumString = styled.tr`
    td {
        height: auto;
        padding: 6px 0 6px 0;
    }

    border: none;
    font-weight: 500;
`;

export const Name = styled.div<{width?: string}>`
    text-align: right;
    padding-right: 16px;
    ${(props) =>
        props.width &&
        css`
            width: ${props.width};
        `}
`;

export const Value = styled.div<{width?: string}>`
    max-width: 132px;
    text-align: right;
    padding-right: 16px;
    ${(props) =>
        props.width &&
        css`
            width: ${props.width};
        `}
`;
