import {bindAllMethods} from 'shared/helpers/binder';
import {Pagination, ResponseType, VirPattern} from '../types';
import {axiosInstance} from 'shared/api/api';

export const patternService = bindAllMethods({
    async getPatterns(props: Pagination): Promise<ResponseType<VirPattern>> {
        // return axiosInstance.get('/pattern_works/pattern_works', props)
        const {page, page_size, search} = props;
        const arr: Array<VirPattern> = [
            ...Array.from({length: 36}, (_, idx) => ({
                id: 'sdfsdfsdgdfwes' + idx.toLocaleString(),
                name: `Очень длинное название шаблона для` + idx,
                contract_number: 'INDOOR-ПРО-СТ- 2021 от 10.09.2021г',
                contract_id: '5',
                creator_name: 'Констрантинопольский',
                created_at: new Date().toLocaleDateString(),
                updated_at: new Date().toLocaleDateString(),
            })),
        ];

        const filtered = arr
            .filter((el) => (search ? el.name.includes(search) : el))
            .slice(page * page_size, (page + 1) * page_size);

        const re: ResponseType<VirPattern> = {
            count: arr.length,
            results: filtered,
        };
        return new Promise((res) => {
            setTimeout(() => res(re), 1000);
        });
    },
    async deletePattern(pattern_id: string) {
        // return axiosInstance.delete('/pattern_works/pattern_works/pattern_id', props)
        return new Promise((res) => {
            setTimeout(() => res(true), 1000);
        });
    },
});
