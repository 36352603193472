import {createDomain, forward, sample} from 'effector';
import {createGate} from 'effector-react';

import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';

interface ITpiStore {
    tpi_id: string;
    remote_territory_id?: string;
}

export const creationJobProcessDomain = createDomain();
export const creationJobProcessGate = createGate<{projectId: string}>();

//event
export const setCurrentStep = creationJobProcessDomain.createEvent<number>();
export const setBlockedStep = creationJobProcessDomain.createEvent<number>();
export const setSuccessfulStep = creationJobProcessDomain.createEvent<number>();
export const setProjectId = creationJobProcessDomain.createEvent<string>();
export const setTpiStore = creationJobProcessDomain.createEvent<ITpiStore>();
export const resetAllDomains = creationJobProcessDomain.createEvent();
export const resetSteps = creationJobProcessDomain.createEvent();
export const setProjectExtId = creationJobProcessDomain.createEvent<number>();

//stores
export const $activeStepId = creationJobProcessDomain
    .createStore('step1')
    .on(setCurrentStep, (state, payload) => `${state.slice(0, -1)}${payload}`)
    .reset(resetSteps);

export const $projectId = creationJobProcessDomain
    .createStore('')
    .on(setProjectId, forwardPayload());

export const $projectExtId = creationJobProcessDomain
    .createStore<number>(0)
    .on(setProjectExtId, forwardPayload());

export const $tpiStore = creationJobProcessDomain
    .createStore<ITpiStore>({tpi_id: ''})
    .on(setTpiStore, forwardPayload());

//Запись projectId в стор
sample({
    clock: creationJobProcessGate.open,
    fn: (clock) => clock.projectId,
    target: $projectId,
});

//сброс доменов
forward({from: creationJobProcessGate.close, to: resetAllDomains});
resetDomainStoresByEvents(creationJobProcessDomain, resetAllDomains);
