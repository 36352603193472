import styled from 'styled-components/macro';
import {Link} from 'react-router-dom';

export const EditLink = styled(Link)<{disabled?: boolean}>`
    font-size: 13px;
    line-height: calc(16 / 13);
    color: ${(p) => p?.disabled && 'grey;'};
    user-select: ${(p) => p?.disabled && 'none;'};
`;

export const EditInfo = styled.div`
    color: var(--secondary);
    font-size: 13px;
    line-height: calc(16 / 13);
`;
