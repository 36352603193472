import {Icons} from '@beeline/design-tokens/js/iconfont';

import {RoutePath} from 'shared/config/routeConfig';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions} from 'shared/const/actions';

interface Groups {
    title?: string;
    items: GroupItem[];
}

interface GroupItem {
    icon?: Icons;
    name: string;
    path: string;
}

export const getUpperGroups = (userActions: EAccessActions[]): Groups[] => {
    const contractsAccess = userHasPermission(EAccessActions.ContractList, userActions);
    const processesAccess = userHasPermission(EAccessActions.ProcessesList, userActions);
    const agreementListAccess = userHasPermission(EAccessActions.AgreementList, userActions);
    const items: GroupItem[] = [
        {
            icon: Icons.Folder,
            name: 'Проекты',
            path: RoutePath.projects,
        },
    ];
    const agreementListNavigation: GroupItem = {
        icon: Icons.Page,
        name: 'Доп. соглашения',
        path: RoutePath.additional_agreements,
    };
    const tasksNavigation: GroupItem = {
        icon: Icons.Tasks,
        name: 'Задачи',
        path: RoutePath.processes,
    };

    const contractsNavigation: GroupItem = {
        icon: Icons.Journal,
        name: 'Договоры',
        path: RoutePath.contract_list,
    };
    agreementListAccess && items.push(agreementListNavigation);
    processesAccess && items.push(tasksNavigation);
    contractsAccess && items.push(contractsNavigation);
    return [
        {
            title: 'Title 1',
            items: items,
        },
    ];
};

export const getBottomGroups = (userActions: EAccessActions[]): Groups[] => {
    const infoAccess = userHasPermission(EAccessActions.WhiteList, userActions);
    return infoAccess
        ? [
              {
                  title: 'bottom_title',
                  items: [
                      {
                          icon: Icons.Variables,
                          name: 'Справочная информация',
                          path: RoutePath.white_list,
                      },
                  ],
              },
          ]
        : [];
};
