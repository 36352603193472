import {Link} from 'react-router-dom';
import React from 'react';
import {Icon, Label} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import {labelVariant} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {formatDate, formatDateHours} from 'shared/helpers/formatHelper';
import {IColumnWithActions} from 'shared/types/tableTypes';
import {
    IAgreementAddition,
    IToggledAdditionalAgreementListItem,
} from 'shared/types/additionalAgreementsTypes';
import {AGREEMENT_ADDITION_TITLE, AGREEMENT_TERMINATION_TITLE} from 'shared/constants';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions} from 'shared/const/actions';

import * as S from './style';

export const columns: IColumnWithActions<IToggledAdditionalAgreementListItem>[] = [
    {
        name: 'Toggle',
        label: '',
        render: ({addendum, isOpen}) => (
            <S.Cell>
                {addendum && addendum.length > 0 && (
                    <Icon iconName={isOpen ? Icons.NavArrowUp : Icons.NavArrowDown} size="medium" />
                )}
            </S.Cell>
        ),
        width: '50px',
    },
    {
        name: 'number_agreement',
        label: 'ДС',
        render: (item) => (
            <TooltipedSpan
                lineCount={1}
                title={item.number_agreement}
                disableHover={item.number_agreement.length < 25}
            >
                {item.number_agreement}
            </TooltipedSpan>
        ),
        width: '222px',
    },

    {
        name: 'addendum',
        label: 'Доп.',
        render: ({addendum}) => (
            <S.Cell>
                <S.Value>
                    {
                        <Icon
                            iconName={addendum?.length ? Icons.Check : Icons.Remove}
                            size="medium"
                        />
                    }
                </S.Value>
            </S.Cell>
        ),
        width: '64px',
    },
    {
        name: 'purchasing_event_number',
        label: '№ ЗМ',
        render: (item) => <span>{item.purchasing_event_number}</span>,
        width: '90px',
    },
    {
        name: 'contract_number',
        label: 'Договор',
        render: (item, userActions) => {
            const hasLink =
                userActions && userHasPermission(EAccessActions.ContractCard, userActions);
            return hasLink ? (
                <Link
                    to={`/contracts/${item.contract_id}`}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {item?.contract_number}
                </Link>
            ) : (
                <span>{item.contract_number}</span>
            );
        },
        width: '140px',
    },
    {
        name: 'work_types',
        label: 'Тип работ',
        render: ({work_types}) => (
            <TooltipedSpan title={work_types} lineCount={2}>
                {work_types}
            </TooltipedSpan>
        ),
        width: '120px',
    },
    {
        name: 'created_at',
        label: 'Создано',
        render: (item) => <span>{formatDateHours(item?.created_at)}</span>,
        width: '120px',
    },
    {
        name: 'approving_at',
        label: 'Дата согласования',
        render: (item) => <span>{formatDateHours(item.approved_at)}</span>,
        width: '112px',
    },
    {
        name: 'signing_date',
        label: 'Дата подписания',
        render: (item) => <span>{formatDate(item.signing_date)}</span>,
        width: '112px',
    },
    {
        name: 'status',
        label: 'Статус',
        render: (item) => {
            return (
                item.status && (
                    <Label
                        type={labelVariant(item.status?.token)}
                        iconName={Icons.Dot}
                        variant="outline"
                        title={item.status?.name}
                    />
                )
            );
        },
        width: '205px',
    },
];

export const innerColumns: IColumnWithActions<IAgreementAddition>[] = [
    {name: 'toggle'},
    {
        name: 'parent_additional_agreement',
        render: ({agreement_id, id, number_addendum, is_termination}) => {
            const linkText =
                !!agreement_id && is_termination
                    ? AGREEMENT_TERMINATION_TITLE
                    : `${AGREEMENT_ADDITION_TITLE} ${number_addendum ?? '—'}`;
            return <Link to={`/additional-agreements/${id}`}>{linkText}</Link>;
        },
        width: '112px',
    },
    {
        name: 'number_addendum',
        width: '222px',
    },
    {
        name: 'purchasing_event_number',
        label: '№ ЗМ',
        render: (item) => <span>{item.purchasing_event_number}</span>,
        width: '90px',
    },
    {
        name: 'contract_number',
        label: 'Договор',
        render: ({contract_number, contract_id}, userActions) => {
            {
                const hasLink =
                    userActions && userHasPermission(EAccessActions.ContractCard, userActions);
                return hasLink ? (
                    <Link
                        to={`/contracts/${contract_id}`}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {contract_number}
                    </Link>
                ) : (
                    <span>{contract_number}</span>
                );
            }
        },
        width: '140px',
    },
    {
        name: 'work_types',
        width: '120px',
        render: ({work_types}) => (
            <TooltipedSpan title={work_types} lineCount={2}>
                {work_types}
            </TooltipedSpan>
        ),
    },
    {
        name: 'created_at',
        label: 'Создано.',
        render: (item) => <span>{formatDateHours(item.created_at)}</span>,
        width: '120px',
    },
    {
        name: 'approving_at',
        label: 'Дата согласования',
        render: (item) => <span>{formatDateHours(item.approved_at)}</span>,
        width: '112px',
    },
    {
        name: 'inner_signing_date',
        label: 'Дата подписания',
        render: (item) => <span>{formatDate(item.signing_date)}</span>,
        width: '112px',
    },
    {
        name: 'status',
        render: (item) => {
            return (
                item.status && (
                    <Label
                        type={labelVariant(item.status?.token)}
                        iconName={Icons.Dot}
                        variant="outline"
                        title={item.status?.name}
                    />
                )
            );
        },
        width: '205px',
    },
];
