import styled from 'styled-components/macro';
import {TableString} from 'shared/ui/Table/Table';
import {Link} from 'react-router-dom';

export const PaginationBox = styled.div`
    height: 52px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const BorderedWrapper = styled.div`
    border: 1px solid #e0e0e0;
    overflow-x: auto;
`;

export const SelectText = styled.button<{selected: boolean}>`
    color: ${(p) => (p.selected ? '#898B90' : '#1A73E8')}; // TO FIX
    border: none;
    background: transparent;
`;

export const ProjectString = styled(TableString)<{selected?: boolean}>`
    &:hover {
        background-color: #ededef;
    }
    ${({selected}) => selected && `background-color: #ededef`}
`;
export const ContractLink = styled(Link)`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
`;
