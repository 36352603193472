import styled, {css} from 'styled-components';
import {Typography} from '@beeline/design-system-react';

export const NotificationBlock = styled.div<{isOpen: boolean}>`
    position: absolute;
    top: 36px;
    right: 0;
    width: 440px;
    max-height: 557px;
    border: 1px solid var(--color-border);
    box-shadow: var(--elevation-medium);
    border-radius: 12px;
    overflow: hidden;
    background-color: var(--bs-body-bg);
    ${(props) =>
        !props.isOpen &&
        css`
            display: none;
        `}
`;

export const NotificationList = styled.div`
    width: 100%;
    max-height: 492px;
    overflow-y: auto;
`;

export const NotificationListItem = styled.div<{isUnread?: boolean}>`
    width: 100%;
    height: 128px;
    padding: 12px;
    display: flex;
    align-items: flex-start;
    gap: 16px;

    &:hover {
        background-color: var(--color-background-base-hover);
    }

    ${(props) =>
        props.isUnread &&
        css`
            background-color: var(--color-background-base-selected);
        `}
`;

export const IconContainer = styled.div<{isUnread?: boolean}>`
    position: relative;
    ${(p) =>
        p.isUnread &&
        css`
            &::before {
                position: absolute;
                content: '';
                top: 0;
                left: -8px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: var(--color-text-link);
            }
        `}
`;
export const ItemContent = styled.div`
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const ReadAllBlock = styled.div`
    padding: 12px 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const LoadingDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 128px;
`;

export const RefDiv = styled.div`
    height: 1px;
`;

export const LinkedTypography = styled(Typography)`
    color: var(--color-text-link);
`;

export const EmptyNotificationBlock = styled.div`
    height: 492px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const TextWrapper = styled.div`
    margin: 12px 88px 0;
    text-align: center;
`;
