import {createGate} from 'effector-react';
import {combine, createDomain} from 'effector';
import {TpiSpecification} from '../../types';

export const selectedTpisTabDomain = createDomain();
export const SelectedTpisGate = createGate();

export const changeSearchSelectedInput = selectedTpisTabDomain.createEvent();
export const filterSelectedBySearchTerm = selectedTpisTabDomain.createEvent();

export const setPage = selectedTpisTabDomain.createEvent<number>();
export const setPageSize = selectedTpisTabDomain.createEvent<number>();

export const selectPoint = selectedTpisTabDomain.createEvent<TpiSpecification>();
export const unselectPoint = selectedTpisTabDomain.createEvent<TpiSpecification>();
export const unselectPointGroup = selectedTpisTabDomain.createEvent<TpiSpecification>();
export const selectTpis = selectedTpisTabDomain.createEvent<TpiSpecification[]>();
export const unselectAll = selectedTpisTabDomain.createEvent();

export const duplicatePoint = selectedTpisTabDomain.createEvent<TpiSpecification>();

export const $selectedTpis = selectedTpisTabDomain.createStore<Array<TpiSpecification>>([]);
export const $selectedTpisOnPage = selectedTpisTabDomain.createStore<Array<TpiSpecification>>([]);

export const $page = selectedTpisTabDomain.createStore(1);
export const $pageSize = selectedTpisTabDomain.createStore(10);
export const $count = selectedTpisTabDomain.createStore(0);
export const $selectedSearchTerm = selectedTpisTabDomain.createStore('');

export const $virSelectAllTpisTab = combine({
    selectedTpis: $selectedTpis,
    selectedTpisOnPage: $selectedTpisOnPage,
});

export const $selectedPaginationParams = combine({
    page: $page,
    pageSize: $pageSize,
    count: $count,
    selectedSearchTerm: $selectedSearchTerm,
});
