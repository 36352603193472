import React from 'react';
import {Typography} from '@beeline/design-system-react';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {AgreementAddendumCard} from 'Entities/AgreementAddendumCard';

import {IAddendumInfo} from '../../types';

interface DSPageAddendaProps {
    addenda: IAddendumInfo[];
}

export const DSPageAddenda = ({addenda}: DSPageAddendaProps) => {
    if (addenda.length === 0) return null;
    return (
        <div>
            <Typography variant="h5">Дополнения</Typography>
            <WrapperFlex mt="8px" gap="24px">
                {addenda.map((item) => (
                    <AgreementAddendumCard
                        key={item.id}
                        id={item.id}
                        agreementId={item.agreement_id}
                        objectType={2}
                        isTermination={item.is_termination}
                        createdAt={item.created_at}
                        addendumNumber={item.number_addendum}
                        approvedAt={item.approved_at}
                        status={item.status}
                    />
                ))}
            </WrapperFlex>
        </div>
    );
};
