import {IMask} from 'react-imask/dist/react-imask';

export const masks = (mask, focus) => {
    const dateMaskOptions = {
        mask: Date,
        pattern: 'dd.MM.y',
        blocks: {
            dd: {
                mask: IMask.MaskedRange,
                placeholderChar: 'Д',
                from: 1,
                to: 31,
                maxLength: 2,
            },
            MM: {
                mask: IMask.MaskedRange,
                placeholderChar: 'М',
                from: 1,
                to: 12,
                maxLength: 2,
            },
            y: {
                mask: IMask.MaskedRange,
                placeholderChar: 'Г',
                from: 1500,
                to: 2500,
            },
        },
    };

    const masks = {
        number: {mask: Number, radix: '.', thousandsSeparator: ' ', mapToRadix: [',']},
        numberWithNoComma: {
            mask: Number,
            normalizeZeros: true,
            radix: ',',
            scale: 0,
            thousandsSeparator: ' ',
            mapToRadix: ['.'],
        },
        numberWithOneComma: {
            mask: Number,
            normalizeZeros: true,
            radix: ',',
            scale: 1,
            thousandsSeparator: ' ',
            mapToRadix: ['.'],
        },
        numberWithComma: {
            mask: Number,
            normalizeZeros: true,
            radix: ',',
            scale: 2,
            thousandsSeparator: ' ',
            mapToRadix: ['.'],
        },
        numberWithThreeComma: {
            mask: Number,
            normalizeZeros: true,
            radix: ',',
            scale: 3,
            thousandsSeparator: ' ',
            mapToRadix: ['.'],
        },
        numberWithFourComma: {
            mask: Number,
            normalizeZeros: true,
            radix: ',',
            scale: 4,
            thousandsSeparator: ' ',
            mapToRadix: ['.'],
        },
        rangeDate: {
            lazy: !focus,
            mask: 'start - end',
            blocks: {
                start: dateMaskOptions,
                end: dateMaskOptions,
            },
        },
        date: {
            lazy: !focus,
            mask: 'dd.MM.y',
            blocks: {
                dd: {mask: IMask.MaskedRange, placeholderChar: 'Д', from: 1, to: 31},
                MM: {mask: IMask.MaskedRange, placeholderChar: 'М', from: 1, to: 12},
                y: {mask: IMask.MaskedRange, placeholderChar: 'Г', from: 1900, to: 2999},
            },
        },
        dateTime: {
            lazy: !focus,
            mask: 'd.m.Y, H:M:S',
            blocks: {
                d: {mask: IMask.MaskedRange, placeholderChar: 'Д', from: 1, to: 31, maxLength: 2},
                m: {mask: IMask.MaskedRange, placeholderChar: 'М', from: 1, to: 12, maxLength: 2},
                Y: {
                    mask: IMask.MaskedRange,
                    placeholderChar: 'Г',
                    from: 1900,
                    to: 2999,
                    maxLength: 4,
                },
                H: {mask: IMask.MaskedRange, placeholderChar: '0', from: 0, to: 60, maxLength: 2},
                M: {mask: IMask.MaskedRange, placeholderChar: '0', from: 0, to: 60, maxLength: 2},
                S: {mask: IMask.MaskedRange, placeholderChar: '0', from: 0, to: 60, maxLength: 2},
            },
        },
        fullDateTime: {
            lazy: !focus,
            mask: 'd.m.Y, H:M:S:milisec.submilisec',
            blocks: {
                d: {mask: IMask.MaskedRange, placeholderChar: 'Д', from: 1, to: 31, maxLength: 2},
                m: {mask: IMask.MaskedRange, placeholderChar: 'М', from: 1, to: 12, maxLength: 2},
                Y: {
                    mask: IMask.MaskedRange,
                    placeholderChar: 'Г',
                    from: 1900,
                    to: 2999,
                    maxLength: 4,
                },
                H: {mask: IMask.MaskedRange, placeholderChar: '0', from: 0, to: 60, maxLength: 2},
                M: {mask: IMask.MaskedRange, placeholderChar: '0', from: 0, to: 60, maxLength: 2},
                S: {mask: IMask.MaskedRange, placeholderChar: '0', from: 0, to: 60, maxLength: 2},
                milisec: {
                    mask: IMask.MaskedRange,
                    placeholderChar: '0',
                    from: 0,
                    to: 60,
                    maxLength: 1,
                },
                submilisec: {
                    mask: IMask.MaskedRange,
                    placeholderChar: '0',
                    from: 0,
                    to: 60,
                    maxLength: 6,
                },
            },
        },
    };
    return masks[mask];
};
