import {IWorksheetColumn} from 'shared/types/tableTypes';
import {Checkbox, Icon, PopoverHelper, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {CoefficientUnit} from 'Entities/CoefficientUnit';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {TooltipedSpan} from 'shared/ui/TooltipedSpan';

import {ProhibitedItemIcon} from './ProhibitedItemIcon';
import {FixedPriceCell} from 'Entities/FixedPriceCell';
import {ITemporaryAgreementWorkSpecification} from '../../../../../shared/types/additionalAgreementsTypes';

export const worksheetCopyColumns: IWorksheetColumn<ITemporaryAgreementWorkSpecification>[] = [
    {
        name: 'checkbox',
        width: '56px',
        render: (
            {tpi_specification, disabled, checked},
            externallyDisabled,
            handleSpecificationCheck,
        ) =>
            !disabled ? (
                <Checkbox
                    onChange={() => handleSpecificationCheck && handleSpecificationCheck()}
                    checked={checked}
                />
            ) : (
                <ProhibitedItemIcon externallyDisabled={externallyDisabled} />
            ),
    },
    {
        name: 'number',
        label: '№ п.п.',
        width: '80px',
        render: ({number}) => <Typography variant="subtitle3">{number}</Typography>,
    },
    {
        name: 'description',
        label: 'Наименование работ',
        render: ({name, note}) => (
            <WrapperFlex gap="8px" alignItems="center">
                <PopoverHelper body={note}>
                    <Icon iconName={Icons.InfoCircled} size="medium" />
                </PopoverHelper>
                <TooltipedSpan lineCount={2} title={name}>
                    {name}
                </TooltipedSpan>
            </WrapperFlex>
        ),
    },
    {
        name: 'quantity',
        label: 'Кол-во',
        width: '90px',
        render: ({quantity, accuracy}) => (
            <Typography variant="body3">{(+quantity).toFixed(accuracy ?? 2)}</Typography>
        ),
    },
    {
        name: 'price',
        label: 'Цена за ед., ₽',
        width: '146px',
        render: ({price, unit}) => <FixedPriceCell price={price} unit={unit} />,
    },
    {
        name: 'coefficient',
        label: 'Коэф-т',
        width: '104px',
        render: ({
            coefficient,
            is_ams,
            coefficient_ams,
            is_foundation,
            coefficient_foundation,
            coefficient_remote_territory,
            work_coefficients,
        }) => (
            <CoefficientUnit
                coefficients={work_coefficients}
                amsCoefficient={is_ams ? coefficient_ams : undefined}
                territoryCoefficient={coefficient_remote_territory}
                foundationCoefficient={is_foundation ? coefficient_foundation : undefined}
                totalCoefficient={coefficient}
            />
        ),
    },
];
