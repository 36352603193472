import styled, {css} from 'styled-components/macro';
import {Tabs} from '@beeline/design-system-react';

export const SidesheetWrapper = styled.div<{active: boolean; height?: string}>`
    position: sticky;
    top: var(--toplineHeight);
    background-color: #fff;
    border-left: 1px solid transparent;
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--toplineHeight));
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 20px 16px;
    transition-duration: 0.4s;
    width: 0;

    & > * {
        opacity: 0;
        transition-duration: 0.1s;
    }

    ${({active}) =>
        active &&
        css`
            border-left: 1px solid #e0e0e0;
            min-width: 320px;
            width: 320px;

            & > * {
                opacity: 1;
            }
        `}
    ${({height}) =>
        height &&
        css`
            height: ${height}px;
        `}
`;

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TabWrapper = styled.div`
    padding-top: 16px;
`;

export const ColumnPickerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const ColumnPicker = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    padding: 8px 0;
`;

export const CountWrapper = styled.div`
    width: 25px;
    display: flex;
    justify-content: center;
`;

export const StyledTabs = styled(Tabs)`
    .dsb_tabs__header-tabs {
        min-width: 320px;
    }
`;
