import styled from 'styled-components/macro';

export const MultiAvatarWrapper = styled.div`
    display: flex;

    > span:first-child {
        margin-left: 0;
        border: 1px solid #fff;
    }

    > span {
        margin-left: -8px;
        border: 1px solid #fff;
    }

    :first-child {
        margin-left: 0;
    }
`;
