import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Icon, IconButton, Progress, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {GroupCertificateSignature} from 'Features/GroupCertificateSignature';
import {CommonLayout} from 'shared/ui/CommonLayout'
import {Content} from 'pages/ProjectPage/style';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';

import * as S from './style';
import {SignTable} from './SignTable/SignTable';
import {$groupSignPageStore, GroupSignPageGate, signatureSelected, signingRepeated} from '../model';
import {
    getSigningProgress,
    GROUP_SIGNING_PAGE_TITLE,
    PAGE_LEAVING_ALLOWED,
    PAGE_LEAVING_PROHIBITED,
    SIGNING_FAILED,
    SIGNING_IN_PROGRESS,
    SIGNING_SUCCEED,
} from '../constants';

export const GroupSignPage = () => {
    useGate(GroupSignPageGate);
    const {
        documentList,
        documentListCount,
        signedCount,
        signatureSelectionShown,
        isDone,
        hasFailed,
    } = useStore($groupSignPageStore);
    const navigate = useNavigate();

    if (documentList.length === 0) {
        navigate('/processes');
    }

    return (
        <CommonLayout>
            <Content>
                <WrapperOffset offset={32} overflowY width="true">
                    {signatureSelectionShown ? (
                        <GroupCertificateSignature
                            onSign={(thumbprint) => signatureSelected(thumbprint)}
                            onClose={() => navigate(-1)}
                        />
                    ) : (
                        <WrapperFlex
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            gap="28px"
                        >
                            <WrapperFlex alignItems="center" gap="16px" alignSelf="flex-start">
                                <IconButton
                                    iconName={Icons.ArrowLeft}
                                    size="medium"
                                    onClick={() => navigate(-1)}
                                />
                                <Typography variant="h4">{GROUP_SIGNING_PAGE_TITLE}</Typography>
                            </WrapperFlex>
                            <S.IconWrapper>
                                {isDone ? (
                                    hasFailed ? (
                                        <Icon
                                            iconName={Icons.WarningCircled}
                                            color="red"
                                            contained
                                        />
                                    ) : (
                                        <Icon
                                            iconName={Icons.CheckCircled}
                                            color="green"
                                            contained
                                        />
                                    )
                                ) : (
                                    <Progress shape="circle" cycled />
                                )}
                            </S.IconWrapper>

                            <WrapperFlex flexDirection="column" gap="8px" alignItems="center">
                                <Typography variant="h5">
                                    {isDone
                                        ? hasFailed
                                            ? SIGNING_FAILED
                                            : SIGNING_SUCCEED
                                        : SIGNING_IN_PROGRESS}
                                </Typography>
                                <Typography variant="body3">
                                    {isDone ? PAGE_LEAVING_ALLOWED : PAGE_LEAVING_PROHIBITED}
                                </Typography>
                                <Typography variant="body1">
                                    {getSigningProgress(signedCount, documentListCount)}
                                </Typography>
                            </WrapperFlex>

                            <SignTable data={documentList} onRefresh={signingRepeated} />
                        </WrapperFlex>
                    )}
                </WrapperOffset>
            </Content>
        </CommonLayout>
    );
};
