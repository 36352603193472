import React from 'react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {Tooltip} from 'shared/ui/deprecated/Tooltip/Tooltip';
import {formatCurrency} from 'shared/helpers/formatHelper';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {StyledSpan} from 'shared/styles/commonStyle';

import * as S from './style';

interface SealedPriceDataProps {
    isAgreedPrice: boolean;
    price: string;
    unit: string;
}

export const SealedPriceData = ({isAgreedPrice, price, unit}: SealedPriceDataProps) => {
    return (
        <WrapperFlex alignItems="center">
            {isAgreedPrice ? (
                <Tooltip
                    titleText="Цена по согласованию"
                    position="top-end"
                    horyzontal={0}
                    vertical={0}
                >
                    <S.StyledIcon size="medium" iconName={Icons.Handshake} />
                </Tooltip>
            ) : (
                <S.EmptyIcon />
            )}
            <S.ItemPriceContainer>
                {formatCurrency(price)}
                <>
                    {unit?.length ?? 0 >= 22 ? (
                        <Tooltip titleText={unit} position="top-end" horyzontal={0} vertical={7}>
                            <StyledSpan textAlign="right" className="inactiveText">
                                {unit}
                            </StyledSpan>
                        </Tooltip>
                    ) : (
                        <StyledSpan textAlign="right">{unit}</StyledSpan>
                    )}
                </>
            </S.ItemPriceContainer>
        </WrapperFlex>
    );
};
