import {TableCell, TableCellContent, TableString} from 'shared/ui/Table/Table';
import {Skeleton} from 'shared/ui/deprecated/Skeleton/Skeleton';

export function CoefficientsSkeleton({pageSize, length = 4}: {pageSize: number; length?: number}) {
    return (
        <>
            {[...Array(pageSize)].map((x, i) => (
                <TableString key={i}>
                    {Array.from({length: length}).map((el, index) => (
                        <TableCell key={index} height="53px">
                            <TableCellContent>
                                <Skeleton height={20} />
                            </TableCellContent>
                        </TableCell>
                    ))}
                </TableString>
            ))}
        </>
    );
}
