import {AllStatuses} from '@beeline/design-system-react/types/types/status';
import {TToken} from 'shared/types/statusTypes';

export const convertTokenToStatus = (token: TToken): AllStatuses => {
    switch (token) {
        case '$color-status-error':
            return 'error';
        case '$color-status-success':
            return 'success';
        case '$color-status-info':
            return 'info';
        case '$color-status-warning':
            return 'warning';
        case '$color-status-neutral':
            return 'default';
        case '$color-status-magenta':
            return 'magenta';
        case '$color-status-purple':
            return 'purple';
        case '$color-status-teal':
            return 'teal';
        default:
            return 'default';
    }
};
