import {useStore} from 'effector-react';
import {useMemo} from 'react';
import {debounce} from 'lodash';
import {coefficientsPage} from 'pages/ApplyCoefficientsPage/model';
import {COEFFICIENTS_ACTIVE_TAB} from '../../../types';
import {
    $increasingCoefficientTab,
    filterIncreasingBySearchTerm,
    setIncreasingSearchTerm,
} from '../../../model/increasingCoefficientsTab';
import {
    $decreasingCoefficientTab,
    filterDecreasingBySearchTerm,
    setDecreasingSearchTerm,
} from '../../../model/decreasingCoefficientsTab';
import * as S from './style';

export function SearchCoefficientsInput() {
    const {activeTab} = useStore(coefficientsPage);
    const {increasingSearchTerm} = useStore($increasingCoefficientTab);
    const {decreasingSearchTerm} = useStore($decreasingCoefficientTab);

    const searchTerm =
        activeTab === COEFFICIENTS_ACTIVE_TAB.INCREASING
            ? increasingSearchTerm
            : decreasingSearchTerm;
    const handleChangeSearchTerm =
        activeTab === COEFFICIENTS_ACTIVE_TAB.INCREASING
            ? setIncreasingSearchTerm
            : setDecreasingSearchTerm;
    const handleSearch =
        activeTab === COEFFICIENTS_ACTIVE_TAB.INCREASING
            ? filterIncreasingBySearchTerm
            : filterDecreasingBySearchTerm;

    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 400);
    }, [handleSearch]);

    return (
        <S.SearchBox>
            <S.SearchStyled
                value={searchTerm}
                onInput={(value) => {
                    handleChangeSearchTerm(value);
                    debouncedResults();
                }}
                placeholder="Поиск по названию, номеру, примечанию"
            />
        </S.SearchBox>
    );
}
