import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';

export const EditInfoLink = styled(Link)`
    &&& {
        font-size: 13px;
        line-height: calc(16 / 13);
    }
`;
export const EditInfo = styled.div`
    color: var(--secondary);
    font-size: 13px;
    line-height: calc(16 / 13);
`;
