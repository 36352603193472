export type TAttachmentFile = {
    id: string;
    file_name: string;
    file_id?: string;
    history_id?: string;
    history_file?: string;
    type: EAttachmentType;
    registration_at?: string | null;
};

export enum EAttachmentType {
    Error,
    Job = 1,
    Agreement,
    Document,
    WorkSpecification,
}