import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    max-width: 628px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
`;
export const Title = styled.div`
    display: flex;
    align-items: center;
    gap: 9px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-self: flex-end;
    gap: 16px;
`;
