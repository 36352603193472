import React from 'react';
import {IconButton, TableData} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {NameCell} from 'pages/VirConstructor/SpecificationRow/Cells/NameCell';
import {CoefficientCell} from 'pages/VirConstructor/SpecificationRow/Cells/CoefficientCell';
import {PositionCell} from 'pages/VirConstructor/SpecificationRow/Cells/PositionCell';
import {SumCell} from 'pages/VirConstructor/SpecificationRow/Cells/SumCell';
import {TableString} from 'shared/ui/Table/Table';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {formatCurrency, formatNumberToDecimalWithComma} from 'shared/helpers/formatHelper';
import {TpiSpecificationOutputType} from 'shared/types/jobsTypes';

import QuantityCell from './QuantityCell';

interface TableRowProps {
    data: TpiSpecificationOutputType;
    onChangeQuantity?: (data: {id: string; value: number}) => void;
    onDelete?: (id: string) => void;
}

export const TableRow = ({data, onChangeQuantity, onDelete}: TableRowProps) => {
    const {tpi_specification_id, number, name, note, quantity, coefficient, unit, price, sum} =
        data;
    const handleOnChangeQuantity = (value: number) => {
        onChangeQuantity && onChangeQuantity({id: tpi_specification_id, value});
    };

    const handleOnDelete = () => {
        onDelete && onDelete(tpi_specification_id);
    };

    return (
        <TableString>
            <PositionCell
                number={number}
                coefficient={coefficient}
                appliedCoefficientNumbers={''}
            />
            <NameCell name={name} note={note} />
            <TableData>{unit}</TableData>
            {onChangeQuantity ? (
                <QuantityCell quantity={quantity} onChange={handleOnChangeQuantity} />
            ) : (
                <TableData>{formatNumberToDecimalWithComma(quantity)}</TableData>
            )}
            <TableData dense alignRight>
                {formatCurrency(price)}
            </TableData>
            <CoefficientCell coefficient={coefficient} alignRight />
            <SumCell sum={sum} />
            {onDelete && (
                <TableData align="center">
                    <WrapperFlex justifyContent="center" alignItems="center">
                        <IconButton iconName={Icons.Delete} onClick={handleOnDelete} />
                    </WrapperFlex>
                </TableData>
            )}
        </TableString>
    );
};
