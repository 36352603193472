import {combine, createDomain, createStore, forward, sample} from 'effector';
import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';
import {createGate} from 'effector-react';

import {ProjectType} from 'shared/services/types/types';
import {getProjectData, getProjectId} from 'shared/services/projects.service';
import {additionalAgreementsDomain} from 'pages/ProjectViewPage/ui/AdditionalAgreementsTab/model';

import {JobTabDomain} from './jobTab';
import {CompletedWorksTabDomain} from './completedWorksTab';

export const viewProjectDomain = createDomain();
export const viewProjectGate = createGate<string | undefined>();

//Events
export const setStageTabItem = viewProjectDomain.createEvent<number>();
export const fetchProjectData = viewProjectDomain.createEvent<string>();
export const setProjectViewTab = viewProjectDomain.createEvent<number>();

//Effects
export const fetchProjectDataFx = viewProjectDomain.createEffect(async (projectId: string) => {
    try {
        const result = await getProjectData(projectId);
        return result.data;
    } catch (e) {
        console.log(e);
    }
});

export const fetchProjectIdFx = viewProjectDomain.createEffect(async (projectId: number) => {
    try {
        const result = await getProjectId(projectId);
        return result.data.id;
    } catch (e) {
        console.log(e);
    }
});

//Stores
export const $stageTabItem = createStore<number>(1).on(setStageTabItem, forwardPayload());

export const $projectData = viewProjectDomain
    .createStore<ProjectType | null>(null)
    .on(fetchProjectDataFx.doneData, forwardPayload());

export const $projectId = viewProjectDomain
    .createStore<string>('')
    .on(fetchProjectIdFx.doneData, forwardPayload());

export const $projectViewTab = createStore(1).on(setProjectViewTab, forwardPayload());
export const $viewProjectStore = combine({
    projectIdFromNri: $projectId,
    projectData: $projectData,
    currentTab: $projectViewTab,
    isLoading: fetchProjectDataFx.pending,
});

sample({
    source: viewProjectGate.state,
    filter: (clock) => typeof clock === 'string' && !Number.isInteger(+(clock ?? '')),
    fn: (clock) => clock ?? '',
    target: fetchProjectData,
});

forward({from: fetchProjectData, to: fetchProjectDataFx});

sample({
    source: viewProjectGate.state,
    filter: (clock) => Number.isInteger(+(clock ?? '')),
    fn: (clock) => +(clock ?? 0),
    target: fetchProjectIdFx,
});

resetDomainStoresByEvents(viewProjectDomain, viewProjectGate.close);
resetDomainStoresByEvents(additionalAgreementsDomain, viewProjectGate.close);
resetDomainStoresByEvents(CompletedWorksTabDomain, viewProjectGate.close);
resetDomainStoresByEvents(JobTabDomain, viewProjectGate.close);
