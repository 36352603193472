import React from 'react';
import {useStore} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import {NotOpenedSheetsBanner} from 'Entities/NotOpenedSheetsBanner';
import {Table} from 'shared/ui/Table/Table';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {Accordion} from 'shared/ui/deprecated/Accordion/Accordion';
import {ICompletedWorksDocument} from 'shared/types/completedWorksTypes';
import {IStatus} from 'shared/types/statusTypes';

import {TableWrapper, TotalTableWrapper} from './style';
import {HeaderRow} from './HeaderRow/HeaderRow';
import {WorkObject} from './WorkObject/WorkObject';
import {$completedWorksBannerStore, activeSheetAccordionChanged} from '../../model';
import * as S from '../../../VirConstructor/VirAccordion/style';
import {Summary} from '../../../VirConstructor/Summary/Summary';

interface CompletedWorksAccordionProps {
    data: ICompletedWorksDocument;
    status: IStatus;
    activeSheet: string;
}

export const CompletedWorksAccordion = ({
    data,
    status,
    activeSheet,
}: CompletedWorksAccordionProps) => {
    const {bannerShown, openedSheetList} = useStore($completedWorksBannerStore);
    return (
        <WrapperFlex flexDirection="column" gap="16px">
            <Typography variant="h5">Ведомости выполненных работ</Typography>
            <NotOpenedSheetsBanner openedSheetList={openedSheetList} showBanner={bannerShown} />
            <TableWrapper>
                <Accordion>
                    {data.works.map((worksheet, index) => (
                        <S.StyledAccordionItem
                            key={worksheet.id}
                            id={worksheet.id}
                            title={`Ведомость ${index + 1}`}
                            active={activeSheet === worksheet.id}
                            setActive={() => activeSheetAccordionChanged(worksheet.id)}
                        >
                            <Table minWidth="800px" tableLayout>
                                <tbody>
                                    <HeaderRow showAction={true} />
                                    {worksheet.work_objects.map((workObject, index) => (
                                        <WorkObject
                                            key={`obj_${index}`}
                                            touchedConstructor={false}
                                            workId={worksheet?.id ?? ''}
                                            workObject={workObject}
                                            status={status}
                                        />
                                    ))}
                                </tbody>
                            </Table>
                        </S.StyledAccordionItem>
                    ))}
                </Accordion>
                <TotalTableWrapper>
                    <Table minWidth="800px" tableLayout>
                        <tbody>
                            <Summary
                                agreementOnRevision={false}
                                sums={{
                                    sum: +data.sum,
                                    sum_tax: +data.sum_tax,
                                    total_sum: +data.total_sum,
                                }}
                                isTotal
                            />
                        </tbody>
                    </Table>
                </TotalTableWrapper>
            </TableWrapper>
        </WrapperFlex>
    );
};
