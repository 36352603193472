import React from 'react';
import {Typography} from '@beeline/design-system-react';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

interface JobCategoryUnitProps {
    jobCategory: {id: string; work_category: string} | null;
    jobCategoryDescription: string | null;
}

export const JobCategoryUnit = ({jobCategory, jobCategoryDescription}: JobCategoryUnitProps) => {
    if (!jobCategory || !jobCategoryDescription) return null;
    return (
        <WrapperFlex flexDirection="column" gap="8px" mb="16px">
            {jobCategory && jobCategoryDescription && (
                <>
                    <Typography variant="h6">Дополнительные работы</Typography>
                    <Typography variant="body2">
                        <>
                            <span className="inactiveText">Категория: </span>
                            {jobCategory?.work_category}
                        </>
                    </Typography>
                    <Typography variant="body2">
                        <>
                            <span className="inactiveText">Описание: </span>
                            {jobCategoryDescription}
                        </>
                    </Typography>
                </>
            )}
        </WrapperFlex>
    );
};
