import styled from 'styled-components/macro';

export const Div = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 20px;
`;

export const StyledLink = styled.div`
    margin-top: 20px;
`;
