import styled, {createGlobalStyle} from 'styled-components/macro';

export const ProgressWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 20px;
`;

export const VirContent = styled.div`
    padding: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
`;

export const GlobalStyle = createGlobalStyle`
  /*date picker range styles */
  .dsb_calendar-navigation {
    color: transparent;
  }

  .dsb_calendar-navigation span {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 22px;
    height: 22px
  }

  .dsb_calendar-navigation span::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    left: 3px
  }


  .dsb_calendar-panels .dsb_calendar-panel:first-child .dsb_calendar-navigation span::after {
    width: 8px !important;
    height: 8px !important;
    border-bottom: 2px solid !important;
    border-left: 2px solid !important;
    transform: rotate(45deg) !important;
    bottom: 6px !important;
    color: black !important;
  }


  .dsb_calendar-panels .dsb_calendar-panel:nth-child(3) .dsb_calendar-navigation span::after {
    width: 8px !important;
    height: 8px !important;
    border-bottom: 2px solid !important;
    border-left: 2px solid !important;
    transform: rotate(225deg) !important;
    bottom: 6px !important;
    color: black !important;
  }

  .dsb__positioner {
    z-index: 2;
    background: white !important;
  }

  .dsb_calendar-footer {
    display: none !important;
  }

  .dsb_calendar.dsb_calendar-range {
    width: 512px;
    padding-bottom: 15px;
  }


`;
