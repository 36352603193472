import React from 'react';
import styled, {css} from 'styled-components/macro';

export const MainWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;
export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 32px;
    align-items: flex-start;

    & > * {
        margin-right: 16px;
        margin-bottom: 16px;
    }
`;

export const BodyContent = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    width: calc(100vw - 15px);
    min-height: calc(100vh - 65px);
`;

const MainContentMixin = (props) => css`
    flex: 0 100 100%;
    overflow-x: auto;
    ${props.activeSidebar &&
    css`
        max-width: calc(100% - 256px);
    `}
    ${props.noSidebar &&
    css`
        max-width: 100%;
    `}
`;
const MainContentStyled = styled.div`
    ${(props) => MainContentMixin(props)}
`;

export function MainContent(props) {
    const {children, ...rest} = props;
    return <MainContentStyled {...props}>{children}</MainContentStyled>;
}
