import React, {useState, useEffect, useCallback} from 'react';
import {debounce} from 'lodash';
import {Switch, Typography} from '@beeline/design-system-react';

import CloseIcon from 'icons/CloseIcon';
import Button from 'shared/ui/deprecated/Button/Button';
import {TabsBtn, TabsBlock} from 'shared/ui/deprecated/Tabs/Tabs';
import Select, {SelectItem} from 'shared/ui/Select/Select';
import {H5} from 'shared/ui/deprecated/HeaderText/HeaderText';
import {
    getProjectPageSelectType,
    getProjectPageSelectStatus,
    getProjectPageSelectYear,
} from 'shared/services/projects.service';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';

import {NonMvpItem} from 'shared/styles/commonStyle';
import {resetColumnFilter, updateColumns} from 'pages/ProjectPage/model';

import {
    projectFilterStore,
    resetProjectsFilter,
    setAppliedFilterCount,
    setStatusFilter,
    setTypeFilter,
    setYearFilter,
} from './model';
import {
    BtnWrap,
    CloseBtn,
    Content,
    ProjectFilterStyled,
    ProjectFooter,
} from './style';
import {ProjectFilterSettings} from './ProjectFilterSettings';
import {useStore} from 'effector-react';

const yearNotSpecified = {
    id: 'notYearId',
    year: 'Не указан',
};

export default function ProjectFilter(props) {
    const {active, toggleMenu, changeParams} = props;
    const {statusFilter, typeFilter, yearFilter} = useStore(projectFilterStore);
    const [accordeon, setAccordeon] = useState(false);
    const [tab, setTab] = useState(1);

    const onToggle = () => {
        toggleMenu(!active);
    };

    const [isLoading, setLoading] = useState(false);

    const [selectTypeData, setSelectTypeData] = useState([]);
    const [selectTypeSearch, setSelectTypeSearch] = useState();
    const getSelectTypeData = useCallback(
        debounce(async (search) => {
            setSelectTypeData([]);
            setLoading(true);
            try {
                const {data: results} = await getProjectPageSelectType();
                if (search != undefined) {
                    setSelectTypeData(
                        results.filter(
                            (item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1,
                        ),
                    );
                } else {
                    setSelectTypeData(results);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }, 400),
        [],
    );

    useEffect(() => {
        getSelectTypeData(selectTypeSearch);
    }, [selectTypeSearch]);

    const [selectYearData, setSelectYearData] = useState([]);
    const [selectYearSearch, setSelectYearSearch] = useState();

    const getSelectYearData = useCallback(
        debounce(async (search) => {
            setSelectYearData([]);
            setLoading(true);
            try {
                const {data: results} = await getProjectPageSelectYear();
                setSelectYearData([yearNotSpecified, ...results]);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }, 400),
        [],
    );

    useEffect(() => {
        getSelectYearData(selectYearSearch);
    }, [selectYearSearch]);

    const [selectStatusData, setSelectStatusData] = useState([]);
    const [selectStatusSearch, setSelectStatusSearch] = useState();
    const getSelectStatusData = useCallback(
        debounce(async (search) => {
            setSelectStatusData([]);
            setLoading(true);
            try {
                const {data: results} = await getProjectPageSelectStatus();
                if (search != undefined) {
                    setSelectStatusData(
                        results.filter(
                            (item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1,
                        ),
                    );
                } else {
                    setSelectStatusData(results);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }, 400),
        [],
    );

    useEffect(() => {
        getSelectStatusData(selectStatusSearch);
    }, [selectStatusSearch]);

    const getAppliedFiltersCount = () => {
        let appliedFiltersCount = 0;
        if (typeFilter.length > 0) {
            appliedFiltersCount++;
        }
        if (yearFilter.length > 0) {
            appliedFiltersCount++;
        }
        if (statusFilter.length > 0) {
            appliedFiltersCount++;
        }

        return appliedFiltersCount;
    };

    const handleSave = () => {
        changeParams({
            statuses_project: statusFilter,
            year: yearFilter,
            types_project: typeFilter,
        });
        setAppliedFilterCount(getAppliedFiltersCount());
    };

    const handleClear = () => {
        resetProjectsFilter();
        changeParams({
            statuses_project: [],
            year: [],
            types_project: [],
        });
    };

    return (
        <ProjectFilterStyled {...props}>
            <WrapperOffset offsetY="20" offsetX="16">
                <WrapperFlex justifyContent="space-between" alignItems="center">
                    <H5>Фильтры и настройки</H5>
                    <CloseBtn onClick={onToggle}>
                        <CloseIcon />
                    </CloseBtn>
                </WrapperFlex>
            </WrapperOffset>
            <WrapperOffset offsetX={16}>
                <BtnWrap>
                    <TabsBtn title="Фильтры" value={1} activeTab={tab} onClick={setTab} />
                    <TabsBtn title="Настройка колонок" value={2} activeTab={tab} onClick={setTab} />
                </BtnWrap>
            </WrapperOffset>
            <Content>
                <TabsBlock value={1} activeTab={tab} className="h-100">
                    <WrapperOffset offsetX={16} offsetTop={24} className="h-100">
                        <WrapperFlex flexDirection="column" className="h-100">
                            <NonMvpItem>
                                <Switch label="Показать только мои проекты" className="mb-4" />
                            </NonMvpItem>
                            <Select
                                value={typeFilter}
                                valueKey="id"
                                valueLabel="name"
                                handleEdit={setTypeFilter}
                                search
                                multiple
                                notAll
                                title="Тип"
                                searchValue={selectTypeSearch}
                                setSearch={setSelectTypeSearch}
                                className="mb-4"
                            >
                                {selectTypeData.length > 0 ? (
                                    selectTypeData.map((item) => (
                                        <SelectItem key={item.id} value={item}>
                                            {item.name}
                                        </SelectItem>
                                    ))
                                ) : (
                                    <WrapperFlex justifyContent="center">
                                        <Typography variant="subtitle3">Нет результатов</Typography>
                                    </WrapperFlex>
                                )}
                            </Select>
                            <Select
                                value={statusFilter}
                                valueKey="id"
                                valueLabel="name"
                                handleEdit={setStatusFilter}
                                search
                                multiple
                                notAll
                                title="Статус"
                                searchValue={selectStatusSearch}
                                setSearch={setSelectStatusSearch}
                                className="mb-4"
                            >
                                {selectStatusData.length > 0 ? (
                                    selectStatusData.map((item) => (
                                        <SelectItem key={item.id} value={item}>
                                            {item.name}
                                        </SelectItem>
                                    ))
                                ) : (
                                    <WrapperFlex justifyContent="center">
                                        <Typography variant="subtitle3">Нет результатов</Typography>
                                    </WrapperFlex>
                                )}
                            </Select>
                            <Select
                                value={yearFilter}
                                valueKey="id"
                                valueLabel="year"
                                handleEdit={setYearFilter}
                                multiple
                                notAll
                                title="Год ПРС"
                                className="mb-4"
                            >
                                {selectYearData.map((item) => (
                                    <SelectItem key={item.id} value={item}>
                                        {item.year}
                                    </SelectItem>
                                ))}
                            </Select>
                            <ProjectFooter className="mt-auto">
                                <Button color="plain" onClick={handleClear}>
                                    Сбросить
                                </Button>
                                <Button color="contained" onClick={handleSave}>
                                    Применить
                                </Button>
                            </ProjectFooter>
                        </WrapperFlex>
                    </WrapperOffset>
                </TabsBlock>
                <TabsBlock value={2} activeTab={tab} className="h-100">
                    <WrapperFlex flexDirection="column" className="h-100">
                        <ProjectFilterSettings />
                        <ProjectFooter>
                            <Button color="plain" onClick={resetColumnFilter}>
                                Сбросить
                            </Button>
                            <Button color="contained" onClick={updateColumns}>
                                Применить
                            </Button>
                        </ProjectFooter>
                    </WrapperFlex>
                </TabsBlock>
            </Content>
        </ProjectFilterStyled>
    );
}
