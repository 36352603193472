import styled from 'styled-components/macro';

export const VIRDataStyled = styled.div`
    padding-top: 20px;
    padding-bottom: 48px;
`;

export const Wrapper = styled.div`
    margin-bottom: 36px;
`;

export const GfkWrapper = styled.div`
    grid-area: gfk;
    display: flex;
    align-items: center;
`;

export const PosCodeWrapper = styled.div`
    grid-area: posCode;
    display: flex;
    align-items: center;
`;

export const GridWrapper = styled.div`
    display: grid;
    row-gap: var(--size-spacing-x3);
    column-gap: var(--size-spacing-x6);
    grid-template-columns: repeat(4, 2fr);
    grid-template-areas:
        'object object job job'
        'gfk posCode contract tpi';
    @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 4fr);
        grid-template-areas:
            'object object'
            'job job'
            'gfk posCode'
            'contract tpi';
    }
`;
