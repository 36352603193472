import {combine, createDomain, createEvent, sample} from 'effector';
import {createGate} from 'effector-react';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {$userHasVAT} from 'shared/model/user';
import {
    TAdaptedCompletedWorksAgreement,
    TBackendCompletedWorksAgreement,
    ICompletedWorksDocument,
} from 'shared/types/completedWorksTypes';
import {TMeta} from 'shared/types/commonTypes';
import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';
import {EAttachmentType, TAttachmentFile} from 'shared/types/FileTypes';
import {
    fetchAgreementForCompletedWorksCreation,
    postCompletedWorksAgreement,
    putCompletedWorksAgreement,
} from 'shared/services/completedWorks.service';
import {fetchFileWithValidation} from 'shared/services/files.service';

import {downloadBlobFile} from 'shared/helpers/blob';
import {$stage1ToStage2Store} from './stage1';
import {
    adaptCompletedWorksAgreement,
    toggleSpecificationCheckbox,
    ISelectedSpecification,
    toggleWorksheetCheckbox,
    addAllSelectedSpecifications,
    IAppliedSpecification,
    changeSpecificationAction,
    ISpecificationLocation,
    addSingleSpecification,
    adaptAgreementForBackend,
    removeCheckedSpecification,
    ISpecificationComment,
    setCommentToSpecification,
    ISpecificationQuantity,
    changeSpecificationQuantity,
    adaptCompletedWorksViewDataToEditingConstructor,
    TMode,
    getMockBackendCompletedWorksAgreement,
    setCommonComment,
    setCommonAttachments,
    ISpecificationAttachments,
    setAttachmentsToSpecification,
    checkAgreementForErrors,
    calcCompletion,
} from './helpers';

export const CompletedWorksStage2Domain = createDomain();
export const CompletedWorksStage2Gate = createGate<TMode>();

// effects
export const getAgreementForCompletedWorksCreatingFx = CompletedWorksStage2Domain.createEffect(
    async (agreementId: string) => {
        try {
            const result = await fetchAgreementForCompletedWorksCreation(agreementId);
            return result.data;
        } catch (e) {
            console.warn(e);
        }
    },
);

export const createCompletedWorksAgreementFx = CompletedWorksStage2Domain.createEffect(
    async (agreement: TBackendCompletedWorksAgreement) => {
        try {
            const result = await postCompletedWorksAgreement(agreement);
            return result.data.id;
        } catch (e) {
            console.warn(e);
        }
    },
);

export const editCompletedWorksAgreementFx = CompletedWorksStage2Domain.createEffect(
    async ({data, id}: {data: TBackendCompletedWorksAgreement; id: string}) => {
        try {
            const result = await putCompletedWorksAgreement(data, id);
            return result.data.id;
        } catch (e) {
            console.warn(e);
        }
    },
);

const getAttachmentFx = CompletedWorksStage2Domain.createEffect(
    async ({fileName, fileId, type}: {fileName: string; fileId: string; type: EAttachmentType}) => {
        try {
            const result = await fetchFileWithValidation(fileId, type);
            if (result.status === 200) {
                const blob = new Blob([result.data]);
                downloadBlobFile(blob, fileName);
            }
        } catch (e) {
            console.warn(e);
        }
    },
);

// events
export const specificationSelected =
    CompletedWorksStage2Domain.createEvent<ISelectedSpecification>();
export const WorksheetToggled = CompletedWorksStage2Domain.createEvent<ISelectedSpecification>();
export const selectedSpecificationsAdded = CompletedWorksStage2Domain.createEvent<string>();
export const singleSpecificationAdded =
    CompletedWorksStage2Domain.createEvent<ISpecificationLocation>();
export const checkedSpecificationDeleted =
    CompletedWorksStage2Domain.createEvent<ISpecificationLocation>();
export const specificationCommentChanged =
    CompletedWorksStage2Domain.createEvent<ISpecificationComment>();
export const specificationAttachmentsChanged =
    CompletedWorksStage2Domain.createEvent<ISpecificationAttachments>();
export const selectedSpecificationApplied =
    CompletedWorksStage2Domain.createEvent<IAppliedSpecification>();
export const creationBtnClicked = CompletedWorksStage2Domain.createEvent<TMeta>();
export const specificationQuantityChanged =
    CompletedWorksStage2Domain.createEvent<ISpecificationQuantity>();
export const commonCommentChanged = CompletedWorksStage2Domain.createEvent<string>();
export const commonAttachmentsChanged = CompletedWorksStage2Domain.createEvent<TAttachmentFile[]>();
export const attachmentFetched = CompletedWorksStage2Domain.createEvent<{
    fileName: string;
    fileId: string;
    type: EAttachmentType;
}>();
export const completedWorksDocumentEdited = createEvent<ICompletedWorksDocument | null>();

// stores
const $initialCompletedWorksAgreement =
    CompletedWorksStage2Domain.createStore<TAdaptedCompletedWorksAgreement | null>(null);
const $createdCompletedWorksId = CompletedWorksStage2Domain.createStore<string | null>(null);
const $errorMessage = CompletedWorksStage2Domain.createStore<string | null>(null);
const $touchedForm = CompletedWorksStage2Domain.createStore<boolean>(false);
const $viewPageRedirection = CompletedWorksStage2Domain.createStore<boolean>(false);
const $saveButtonDisabled = CompletedWorksStage2Domain.createStore<boolean>(false);
const $currentCompletion = CompletedWorksStage2Domain.createStore(0);
const $prevCompletion = CompletedWorksStage2Domain.createStore(0);

export const $loadingAttachmentId = CompletedWorksStage2Domain.createStore<string>('');
export const $constructorMode = CompletedWorksStage2Domain.createStore<TMode | null>(null);

export const $completedWorksStage2Store = combine({
    agreement: $initialCompletedWorksAgreement,
    createdCompletedWorksId: $createdCompletedWorksId,
    touchedForm: $touchedForm,
    error: $errorMessage,
    viewPageRedirection: $viewPageRedirection,
    saveButtonDisabled: $saveButtonDisabled,
    loadingAttachmentId: $loadingAttachmentId,
    currentCompletion: $currentCompletion,
    prevCompletion: $prevCompletion,
});

sample({
    clock: CompletedWorksStage2Gate.open,
    target: $constructorMode,
});

// запрос данных для последней страницы конструктора
sample({
    clock: CompletedWorksStage2Gate.open,
    source: $stage1ToStage2Store,
    filter: (_, gate) => gate === 'creation',
    fn: ({selectedAgreementList}, _) => selectedAgreementList[0].id.toString(),
    target: getAgreementForCompletedWorksCreatingFx,
});

// редирект пользователя, если обновил страницу в режиме редактирования
sample({
    clock: CompletedWorksStage2Gate.open,
    source: $initialCompletedWorksAgreement,
    filter: (_, gate) => gate !== 'creation',
    fn: (agreement, gate) => gate !== 'creation' && !agreement,
    target: $viewPageRedirection,
});

// первичное преобразование объекта ВВР (добавление полей в пункты спецификации)
sample({
    clock: getAgreementForCompletedWorksCreatingFx.doneData,
    source: $stage1ToStage2Store,
    filter: (_, clock) => !!clock,
    fn: ({selectedWorksheetList}, data) =>
        data ? adaptCompletedWorksAgreement(data, selectedWorksheetList) : null,
    target: $initialCompletedWorksAgreement,
});

// преобразование объекта ВВР (редактирование ВВР)
sample({
    clock: completedWorksDocumentEdited,
    source: $userHasVAT,
    filter: (_, sheet) => !!sheet,
    fn: (hasVAT, clock) =>
        !!clock ? adaptCompletedWorksViewDataToEditingConstructor(clock, hasVAT) : null,
    target: $initialCompletedWorksAgreement,
});

// редирект пользователя в конструктор ВВР
sample({
    clock: completedWorksDocumentEdited,
    fn: (agreement) => ({
        to: `/completed-works/${agreement?.id ?? ''}/editing`,
        options: {state: {mode: agreement?.status.name === 'Черновик' ? 'drafting' : 'editing'}},
    }),
    target: navigationInvoked,
});

// проверка ВВР
sample({
    clock: $initialCompletedWorksAgreement,
    filter: (agreement) => !!agreement,
    fn: (agreement) => (agreement ? checkAgreementForErrors(agreement) : null),
    target: $errorMessage,
});

// Изменение чек-боксов выделения пунктов спецификаций
sample({
    clock: specificationSelected,
    source: $initialCompletedWorksAgreement,
    filter: (agreement) => !!agreement,
    fn: (agreement, clock) =>
        agreement ? toggleSpecificationCheckbox(agreement, clock, clock.checked) : null,
    target: $initialCompletedWorksAgreement,
});

// нажатие на общий чекбокс
sample({
    clock: WorksheetToggled,
    source: $initialCompletedWorksAgreement,
    filter: (agreement) => !!agreement,
    fn: (agreement, clock) =>
        agreement ? toggleWorksheetCheckbox(agreement, clock.worksheetId, clock.checked) : null,
    target: $initialCompletedWorksAgreement,
});

// Нажатие на кнопку "добавить выбранное"
sample({
    clock: selectedSpecificationsAdded,
    source: $initialCompletedWorksAgreement,
    filter: (agreement) => !!agreement,
    fn: (agreement, clock) => (agreement ? addAllSelectedSpecifications(agreement, clock) : null),
    target: $initialCompletedWorksAgreement,
});

// нажатие на кнопку "добавить"
sample({
    clock: singleSpecificationAdded,
    source: combine({
        agreement: $initialCompletedWorksAgreement,
        hasVAT: $userHasVAT,
    }),
    filter: ({agreement}) => !!agreement,
    fn: ({agreement, hasVAT}, clock) =>
        agreement ? addSingleSpecification(agreement, clock, hasVAT) : null,
    target: $initialCompletedWorksAgreement,
});

// нажатие на дропдаун действия
sample({
    clock: selectedSpecificationApplied,
    source: combine({agreement: $initialCompletedWorksAgreement, hasVAT: $userHasVAT}),
    filter: ({agreement}) => !!agreement,
    fn: ({agreement, hasVAT}, clock) =>
        agreement ? changeSpecificationAction(agreement, clock, hasVAT) : null,
    target: $initialCompletedWorksAgreement,
});

// изменение количества в пункте спецификации
sample({
    clock: specificationQuantityChanged,
    source: $initialCompletedWorksAgreement,
    filter: (agreement) => !!agreement,
    fn: (agreement, clock) => (agreement ? changeSpecificationQuantity(agreement, clock) : null),
    target: $initialCompletedWorksAgreement,
});

// изменение общего комментария ВВР
sample({
    clock: commonCommentChanged,
    source: $initialCompletedWorksAgreement,
    filter: (agreement) => !!agreement,
    fn: (agreement, clock) => (agreement ? setCommonComment(agreement, clock) : null),
    target: $initialCompletedWorksAgreement,
});

// изменение общих вложений ВВР
sample({
    clock: commonAttachmentsChanged,
    source: $initialCompletedWorksAgreement,
    filter: (agreement) => !!agreement,
    fn: (agreement, attachmentList) =>
        agreement ? setCommonAttachments(agreement, attachmentList) : null,
    target: $initialCompletedWorksAgreement,
});

// дизейбл кнопки в процессе отправки ВВР на бэк
$saveButtonDisabled.on(
    [createCompletedWorksAgreementFx.pending, editCompletedWorksAgreementFx.pending],
    forwardPayload(),
);

// Срабатывание триггера при создании/редактировани ВВР
sample({
    clock: creationBtnClicked,
    fn: () => true,
    target: $touchedForm,
});

// Создание ВВР
sample({
    clock: creationBtnClicked,
    source: combine({
        agreement: $initialCompletedWorksAgreement,
        mode: CompletedWorksStage2Gate.state,
        error: $errorMessage,
    }),
    filter: ({agreement, mode, error}) => !!agreement && mode === 'creation' && error === null,
    fn: ({agreement}, meta) =>
        !!agreement
            ? adaptAgreementForBackend(agreement, meta)
            : getMockBackendCompletedWorksAgreement(),
    target: createCompletedWorksAgreementFx,
});

// Редактирование ВВР
sample({
    clock: creationBtnClicked,
    source: combine({
        agreement: $initialCompletedWorksAgreement,
        mode: CompletedWorksStage2Gate.state,
        error: $errorMessage,
    }),
    filter: ({agreement, mode, error}) =>
        !!agreement && !!agreement.completed_works_id && mode !== 'creation' && error === null,
    fn: ({agreement}, meta) =>
        !!agreement
            ? {
                  data: adaptAgreementForBackend(agreement, meta),
                  id: agreement.completed_works_id ?? '',
              }
            : {
                  data: getMockBackendCompletedWorksAgreement(),
                  id: '',
              },
    target: editCompletedWorksAgreementFx,
});

// удаление выбранной спецификации
sample({
    clock: checkedSpecificationDeleted,
    source: $initialCompletedWorksAgreement,
    filter: (agreement) => !!agreement,
    fn: (agreement, location) =>
        !!agreement ? removeCheckedSpecification(agreement, location) : null,
    target: $initialCompletedWorksAgreement,
});

// изменение коммента спецификации
sample({
    clock: specificationCommentChanged,
    source: $initialCompletedWorksAgreement,
    filter: (agreement) => !!agreement,
    fn: (agreement, location) =>
        !!agreement ? setCommentToSpecification(agreement, location) : null,
    target: $initialCompletedWorksAgreement,
});

// изменение вложений спецификации
sample({
    clock: specificationAttachmentsChanged,
    source: $initialCompletedWorksAgreement,
    filter: (agreement) => !!agreement,
    fn: (agreement, location) =>
        !!agreement ? setAttachmentsToSpecification(agreement, location) : null,
    target: $initialCompletedWorksAgreement,
});

// заполнение ID для редиректа на карточку ВВР
sample({
    clock: [createCompletedWorksAgreementFx.doneData, editCompletedWorksAgreementFx.doneData],
    filter: (redirectId) => !!redirectId,
    fn: (redirectId) => redirectId ?? null,
    target: $createdCompletedWorksId,
});

// логика работы аттачментов
sample({
    clock: attachmentFetched,
    target: getAttachmentFx,
});

// заполнения стора, показывающего какое вложение сейчас загружается
sample({
    clock: getAttachmentFx,
    fn: (attachmentParams) => attachmentParams.fileId,
    target: $loadingAttachmentId,
});

sample({
    source: $initialCompletedWorksAgreement,
    fn: (agreement) => calcCompletion(agreement, 'completed_work_specifications'),
    target: $currentCompletion,
});

sample({
    clock: $initialCompletedWorksAgreement,
    source: $currentCompletion,
    fn: (currentCompletion, agreement) => {
        let prevCompletion = calcCompletion(agreement, 'finished_work_specifications');
        return currentCompletion + prevCompletion === 101 ? prevCompletion - 1 : prevCompletion;
    },
    target: $prevCompletion,
});

$loadingAttachmentId.reset(getAttachmentFx.doneData);

resetDomainStoresByEvents(CompletedWorksStage2Domain, CompletedWorksStage2Gate.close);
