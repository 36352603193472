import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useStore} from 'effector-react';

import {$navigationPath} from '../model';

export const AppNavigator = () => {
    const navigationPath = useStore($navigationPath);
    const navigate = useNavigate();

    useEffect(() => {
        if (!!navigationPath) {
            typeof navigationPath.to === 'number'
                ? navigate(navigationPath.to)
                : navigate(navigationPath.to, navigationPath.options);
        }
    }, [navigationPath]);

    return <></>;
};
