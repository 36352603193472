import React from 'react';
import {RowStyled} from 'shared/ui/deprecated/Row/style';

const Row = function (props) {
    const {children, gutterX = 16, gutterY = 16, ...rest} = props;

    return (
        <RowStyled className="row" {...props}>
            {children}
        </RowStyled>
    );
};

export default Row;
