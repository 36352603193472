import styled, { css } from "styled-components";

const baseCounterMixin = () => css`
font-weight: 700;
font-size: 10px;
line-height: calc(16/10);
color:#171718;
background-color:#fdd835;
display:inline-flex;
justify-content:center;
height:16px;
min-width:16px;
padding-left:4px;
padding-right:4px;
text-transform:uppercase;
border-radius:8px;
`;

const Error = () => css`
background-color:var(--red);
color:var(--outline-body-color);
`

export const counterMixin = (props) => css`
	${baseCounterMixin()}
	${props.error && Error}
`;

export const CounterStyled = styled.span`
	${(props) => counterMixin(props)}
`;