import {resetDomainStoresByEvents} from 'shared/helpers/effector';
import {virCreatePageDomain} from 'pages/VirStart/model';
import {constructorDomain, resetAdditionalAgreementCreation} from '../stores';
import {decreasingCoefficientsDomain} from 'pages/ApplyCoefficientsPage/model/decreasingCoefficientsTab';
import {increasingCoefficientDomain} from 'pages/ApplyCoefficientsPage/model/increasingCoefficientsTab';
import {customCoefficientsDomain} from 'pages/ApplyCoefficientsPage/model/otherCoeficientsTab';
import {applyCoefficientsDomain} from 'pages/ApplyCoefficientsPage/model';
import {addWorkObjectDomain} from 'pages/AddWorkObjects/model';
import {allTpisTabGate} from 'pages/VirSelect/model/AllTpisTab';
import {selectedTpisTabDomain} from 'pages/VirSelect/model/SelectedTpisTab';
import {selectTpisDomain} from 'pages/VirSelect/model/SelectTpisPage';
import {updateAgreementDomain} from 'processes/contructor/model/update';

resetDomainStoresByEvents(virCreatePageDomain, resetAdditionalAgreementCreation);
resetDomainStoresByEvents(constructorDomain, resetAdditionalAgreementCreation);
resetDomainStoresByEvents(decreasingCoefficientsDomain, resetAdditionalAgreementCreation);
resetDomainStoresByEvents(increasingCoefficientDomain, resetAdditionalAgreementCreation);
resetDomainStoresByEvents(customCoefficientsDomain, resetAdditionalAgreementCreation);
resetDomainStoresByEvents(applyCoefficientsDomain, resetAdditionalAgreementCreation);
resetDomainStoresByEvents(addWorkObjectDomain, resetAdditionalAgreementCreation);
resetDomainStoresByEvents(allTpisTabGate, resetAdditionalAgreementCreation);
resetDomainStoresByEvents(selectedTpisTabDomain, resetAdditionalAgreementCreation);
resetDomainStoresByEvents(selectTpisDomain, resetAdditionalAgreementCreation);
resetDomainStoresByEvents(updateAgreementDomain, resetAdditionalAgreementCreation);
