import React, {ChangeEvent} from 'react';
import {Button, Checkbox, Icon, TableHeaderData, TableRow} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';
import {CheckboxType} from '@beeline/design-system-react/types/components/Checkbox/Checkbox.types';

import {TableCellContent, TableHead} from 'shared/ui/Table/Table';
import {selectedSpecificationsAdded, WorksheetToggled} from '../../../../../model/stage2';

interface TableHeaderProps {
    checked: boolean;
    checkboxType: CheckboxType;
    worksheetId: string;
}

export const TableHeader = ({checked, checkboxType, worksheetId}: TableHeaderProps) => {
    const handleOnChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        WorksheetToggled({
            worksheetId: worksheetId,
            objectId: '',
            specificationId: '',
            checked: e.target.checked,
        });
    };
    return (
        <TableRow>
            <TableHeaderData width="56px">
                <TableCellContent>
                    <Checkbox
                        checked={checked}
                        type={checkboxType}
                        onChange={handleOnChangeCheckbox}
                    />
                </TableCellContent>
            </TableHeaderData>
            {checked ? (
                // @ts-ignore
                <TableHeaderData width="80px" colSpan={2} dense>
                    <Button
                        variant="plain"
                        startIcon={<Icon iconName={Icons.Add} />}
                        onClick={() => selectedSpecificationsAdded(worksheetId)}
                        size="small"
                    >
                        Добавить выбранное
                    </Button>
                </TableHeaderData>
            ) : (
                <>
                    <TableHeaderData>№ п.п.</TableHeaderData>
                    <TableHeaderData>
                        <TableHead>Наименование работ</TableHead>
                    </TableHeaderData>
                    <TableHeaderData width="101px" alignRight>
                        Кол-во
                    </TableHeaderData>
                    <TableHeaderData width="146px" alignRight>
                        Цена за ед., ₽
                    </TableHeaderData>
                    <TableHeaderData width="104px">Коэф-т</TableHeaderData>
                    <TableHeaderData width="132px" alignRight>
                        Стоимость, ₽
                    </TableHeaderData>
                    <TableHeaderData width="172px">Результат</TableHeaderData>
                    <TableHeaderData width="52px"></TableHeaderData>
                    <TableHeaderData width="52px"></TableHeaderData>
                </>
            )}
        </TableRow>
    );
};
