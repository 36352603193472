import {snackbarNotificationChanged} from 'Entities/SnackbarNotification/model';
import {TEXT_COPIED} from '../constants';

export const isSubstring = (str: string, substr: string) => {
    return str.toLowerCase().indexOf(substr.toLowerCase()) > -1;
};

export const findPropInObject = (obj, lookFor) => {
    for (let prop in obj) {
        if (prop === lookFor) return obj[prop];
        if (typeof obj[prop] == 'object') {
            const checkNested = findPropInObject(obj[prop], lookFor);
            if (checkNested) return checkNested;
        }
    }
    return undefined;
};

export const copyToClipboard = (text?: string) => {
    if (text) {
        navigator.clipboard.writeText(text);
        snackbarNotificationChanged(TEXT_COPIED);
    }
};
