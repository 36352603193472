import React, {useEffect} from 'react';
import {useStore} from 'effector-react';

import {SelectItemSolo} from 'shared/ui/Select/Select';
import SearchCategory from 'shared/ui/SearchCategory/SearchCategory';
import {SearchCategoriesType} from 'pages/ProjectPage/model/types';

import {
    $multiSearchStore,
    handleEnterPress,
    resetALlSearchParameters,
    setSearchValue,
    setSelectedSearchCategory,
} from '../model';

const categoryList: Array<SearchCategoriesType> = [
    {
        title: 'Номер БС',
        type: 'bsNumberOnly',
        prefix: 'bs:',
    },
    {
        title: 'Номер проекта',
        type: 'projectOnly',
        prefix: 'proj:',
    },
    {
        title: 'Код ГФК',
        type: 'bsGfkOnly',
        prefix: 'gfk:',
    },
];

interface MultiSearchProps {
    handleSearchValue: (value: string) => void;
}

export const MultiSearch = ({handleSearchValue}: MultiSearchProps) => {
    const {searchValue, selectedSearchCategory, pushedSearchValue} = useStore($multiSearchStore);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleEnterPress();
        }
    };

    useEffect(() => {
        handleSearchValue(pushedSearchValue);
    }, [pushedSearchValue]);

    return (
        <SearchCategory
            onKeyPress={(e) => {
                handleKeyPress(e);
            }}
            onClick={handleEnterPress}
            inputValue={searchValue}
            setInputValue={setSearchValue}
            categoryValue={selectedSearchCategory}
            categoryValueLabel="title"
            categoryValueKey="type"
            onEditCategory={setSelectedSearchCategory}
            onResetCategory={resetALlSearchParameters}
        >
            {categoryList.map((item) => (
                <SelectItemSolo
                    key={item.type}
                    value={item}
                    selected={undefined}
                    valueKey={undefined}
                    handleEdit={undefined}
                    setActive={undefined}
                    id={item.type}
                >
                    {item.title}
                </SelectItemSolo>
            ))}
        </SearchCategory>
    );
};
