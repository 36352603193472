import {axiosInstance} from 'shared/api/api';
import {UserInfoType, UserType} from 'shared/services/types/userTypes';
import {TExtended} from 'shared/types/userTypes';

export const getAccountInfo = () => {
    return axiosInstance.get<UserInfoType>('accounts/info');
};

export const getUser = async (id: string) => {
    return await axiosInstance.get<UserType>(`accounts/users/${id}/`);
};

export const postUserSettings = (data: TExtended) => {
    return axiosInstance.post<{extend: TExtended}>(`accounts/info`, {
        extend: data,
    });
};
