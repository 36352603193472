import React from 'react';
import {Button, Icon} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {TASK_STATUS} from 'pages/DSPage/types';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {IStatus} from 'shared/types/statusTypes';
import {TTaskList} from 'shared/types/tasksTypes';

interface ExecutionButtonGroupProps {
    documentStatus: IStatus;
    taskData: TTaskList;
    handleAcceptButton: () => void;
    handleWorkflowButton: (number) => void;
    handleEditButton: () => void;
    handleReturnButton: () => void;
    handleDownloadButton?: () => void;
    buttonsDisabled: boolean;
    positiveButtonDisabled: boolean;
    negativeButtonDisabled: boolean;
}

export const ExecutionButtonGroup = ({
    documentStatus,
    taskData,
    handleAcceptButton,
    handleReturnButton,
    handleWorkflowButton,
    handleEditButton,
    handleDownloadButton,
    buttonsDisabled,
    positiveButtonDisabled,
    negativeButtonDisabled,
}: ExecutionButtonGroupProps) => {
    const workflowData = taskData[0];

    const showEditButton = documentStatus?.is_editable ?? false;
    const showAcceptButton = workflowData?.status === TASK_STATUS.PLAN;
    const showCancelButton = workflowData?.status === TASK_STATUS.RUN && workflowData?.is_group;

    const getIsButtonDisabled = (isPositive: boolean | null) => {
        const isDisabled = () => {
            switch (isPositive) {
                case true: {
                    return positiveButtonDisabled;
                }
                case false: {
                    return negativeButtonDisabled || !positiveButtonDisabled;
                }
                default: {
                    return false;
                }
            }
        };
        return buttonsDisabled || isDisabled();
    };

    return (
        <WrapperFlex gap="16px">
            {showAcceptButton ? (
                <Button
                    color="outline"
                    size="medium"
                    onClick={handleAcceptButton}
                    disabled={buttonsDisabled}
                >
                    Взять в работу
                </Button>
            ) : (
                <>
                    {showCancelButton && (
                        <Button
                            onClick={handleReturnButton}
                            size="medium"
                            disabled={buttonsDisabled}
                        >
                            Вернуть на группу
                        </Button>
                    )}
                    {workflowData?.actions.map((buttonData, number) => (
                        <Button
                            key={buttonData.id}
                            onClick={() => handleWorkflowButton(number)}
                            size="medium"
                            variant={buttonData.is_positive ? 'contained' : undefined}
                            disabled={getIsButtonDisabled(buttonData.is_positive)}
                        >
                            {buttonData.name}
                        </Button>
                    ))}
                </>
            )}

            {handleDownloadButton && (
                <Button
                    variant="outlined"
                    startIcon={<Icon iconName={Icons.Download} />}
                    size="medium"
                    onClick={handleDownloadButton}
                    disabled={buttonsDisabled}
                />
            )}
            {showEditButton && (
                <Button variant="contained" size="medium" onClick={handleEditButton}>
                    Редактировать
                </Button>
            )}
        </WrapperFlex>
    );
};
