import {combine, createDomain, forward, sample} from 'effector';
import {createGate} from 'effector-react';
import {
    getProjectPageSelectBranch,
    getProjectPageSelectRegion,
} from 'shared/services/projects.service';
import {BranchType} from 'shared/services/types/types';
import {forwardPayload} from 'shared/helpers/effector';
import {RegionType} from 'shared/types/regionSelectTypes';
import {isSubstring} from 'shared/helpers/commonFunctions';

export const territorialDomain = createDomain();
export const territorialSelectGate = createGate<string>();

export const getRegionData = territorialDomain.createEvent();
export const getBranchData = territorialDomain.createEvent();
export const setDefaultRegionData = territorialDomain.createEvent<Array<RegionType>>();
export const setDefaultBranchData = territorialDomain.createEvent<Array<BranchType>>();
export const setSelectedRegionData = territorialDomain.createEvent<{
    value: Array<RegionType>;
    targetItem: RegionType | null;
    checked: boolean;
}>();
export const setSelectedBranchData = territorialDomain.createEvent<{
    value: Array<BranchType>;
    targetItem: BranchType | null;
    checked: boolean;
}>();
export const setRegionInputValue = territorialDomain.createEvent<string>();
export const setBranchInputValue = territorialDomain.createEvent<string>();
export const resetRegionData = territorialDomain.createEvent();
export const resetBranchData = territorialDomain.createEvent();
export const resetSelectedBranchData = territorialDomain.createEvent();
export const resetSearch = territorialDomain.createEvent();
export const resetAllSelectedData = territorialDomain.createEvent();

export const fetchRegionDataFx = territorialDomain.createEffect(
    async (searchInputValue: string) => {
        try {
            resetRegionData();
            const result = await getProjectPageSelectRegion();
            if (searchInputValue) {
                return result.data.filter((item) => isSubstring(item.name, searchInputValue));
            }
            return result.data;
        } catch (e) {
            console.error(e);
        }
    },
);
export const fetchBranchDataFx = territorialDomain.createEffect(
    async (searchInputValue: string) => {
        try {
            resetBranchData();
            const result = await getProjectPageSelectBranch('');
            if (searchInputValue) {
                return result.data.filter((item) => isSubstring(item.name, searchInputValue));
            }
            return result.data;
        } catch (e) {
            console.error(e);
        }
    },
);

export const $regionData = territorialDomain
    .createStore<Array<RegionType>>([])
    .on(fetchRegionDataFx.doneData, forwardPayload())
    .reset(resetRegionData);

export const $availableRegionData = territorialDomain
    .createStore<Array<RegionType>>([])
    .on(fetchRegionDataFx.doneData, forwardPayload());

export const $selectedRegionData = territorialDomain
    .createStore<Array<RegionType>>([])
    .on(setDefaultRegionData, forwardPayload())
    .reset(resetAllSelectedData);

export const $selectedRegionsStringValue = territorialDomain
    .createStore<string>('')
    .reset(resetAllSelectedData);

export const $branchData = territorialDomain
    .createStore<Array<BranchType>>([])
    .on(fetchBranchDataFx.doneData, forwardPayload())
    .reset(resetBranchData);

export const $availableBranchData = territorialDomain
    .createStore<Array<BranchType>>([])
    .on(fetchBranchDataFx.doneData, (state, payload) =>
        payload?.sort((a, b) => a.name.localeCompare(b.name)),
    );

export const $selectedBranchData = territorialDomain
    .createStore<Array<BranchType>>([])
    .on(setDefaultBranchData, forwardPayload())
    .reset([resetSelectedBranchData, resetAllSelectedData]);

export const $selectedBranchesStringValue = territorialDomain
    .createStore<string>('')
    .reset(resetAllSelectedData);

export const $regionInputValue = territorialDomain
    .createStore<string>('')
    .on(setRegionInputValue, forwardPayload())
    .reset([resetSearch, resetAllSelectedData]);

export const $branchInputValue = territorialDomain
    .createStore<string>('')
    .on(setBranchInputValue, forwardPayload())
    .reset([resetSearch, resetAllSelectedData]);

export const $regionSelectStore = combine({
    availableRegionData: $availableRegionData,
    selectedRegionData: $selectedRegionData,
    selectedBranchData: $selectedBranchData,
    availableBranchData: $availableBranchData,
    selectRegionSearch: $regionInputValue,
    selectBranchSearch: $branchInputValue,
    regionString: $selectedRegionsStringValue,
    selectedRegionParam: $selectedRegionsStringValue,
    selectedBranchParam: $selectedBranchesStringValue,
});
//open gate
forward({from: territorialSelectGate.open, to: [getRegionData, getBranchData]});

sample({
    clock: getRegionData,
    source: $regionInputValue,
    target: fetchRegionDataFx,
});

sample({
    clock: getBranchData,
    source: $branchInputValue,
    target: fetchBranchDataFx,
});

//сортировка доступных регионов по строке поиска
sample({
    clock: $regionInputValue,
    source: combine({
        regionData: $regionData,
        availableRegionData: $availableRegionData,
    }),
    fn: (store, input) => {
        if (input === '') {
            return store.regionData;
        } else {
            return store.regionData.filter((item) =>
                item.name.toLowerCase().includes(input.toLowerCase()),
            );
        }
    },
    target: $availableRegionData,
});

// сортировка доступных филиалов по строке поиска с учетом выбранных регионов
sample({
    clock: $branchInputValue,
    source: combine({
        availableBranchData: $availableBranchData,
        branchData: $branchData,
        regionData: $regionData,
        selectedRegionData: $selectedRegionData,
    }),
    fn: (store, string) => {
        const selectedRegionCodes = (
            store.selectedRegionData.length > 0 ? store.selectedRegionData : store.regionData
        ).map((item) => +item.external_code);
        const result = store.branchData.filter((item) =>
            selectedRegionCodes.includes(item.regions_external_code),
        );
        if (string === '') {
            return result;
        } else {
            return result.filter((item) => item.name.toLowerCase().includes(string.toLowerCase()));
        }
    },
    target: $availableBranchData,
});

// при изменении Region, фильтрует доступные для выбора филиалы
sample({
    clock: $selectedRegionData,
    source: $branchData,
    fn: (branchData, regionData) => {
        if (regionData.length > 0) {
            const selectedRegionCodes = regionData.map((item) => +item.external_code);
            return branchData.filter((item) =>
                selectedRegionCodes.includes(item.regions_external_code),
            );
        } else {
            return branchData;
        }
    },
    target: $availableBranchData,
});

// при выборе региона обновляет стор выбранных регионов, сортируя стор по названию региона
sample({
    clock: setSelectedRegionData,
    source: combine({
        selectedRegionData: $selectedRegionData,
        regionData: $regionData,
    }),
    fn: (store, region) => {
        const {targetItem, checked} = region;
        let result: Array<RegionType> = [];
        if (targetItem === null) {
            if (checked) {
                result = [...store.regionData];
            }
        } else {
            if (
                store.selectedRegionData.some(
                    (item) => item.external_code === targetItem.external_code,
                )
            ) {
                result = store.selectedRegionData.filter(
                    (item) => item.external_code !== targetItem.external_code,
                );
            } else {
                result = [...store.selectedRegionData, targetItem];
            }
        }
        return result.sort((a, b) => a.name.localeCompare(b.name));
    },
    target: $selectedRegionData,
});

// изменение стора выбранных филиалов
sample({
    clock: setSelectedBranchData,
    source: combine({
        selectedBranchData: $selectedBranchData,
        availableBranchData: $availableBranchData,
    }),
    fn: (store, branch) => {
        const {value, targetItem, checked} = branch;
        let result: Array<BranchType> = value.sort((a, b) => a.name.localeCompare(b.name));
        if (targetItem === null) {
            result = checked ? store.availableBranchData : [];
        }
        return result;
    },
    target: $selectedBranchData,
});

// конвертация стора $selectedRegionData в строку $selectedRegionsStringValue
sample({
    source: $selectedRegionData,
    fn: (source) =>
        source
            .sort()
            .map((item) => item.id)
            .join(','),
    target: $selectedRegionsStringValue,
});

//конвертация стора $selectedBranchData в строку $selectedBranchesStringValue
sample({
    source: $selectedBranchData,
    fn: (source) => source.map((item) => item.id).join(','),
    target: $selectedBranchesStringValue,
});
// при изменении региона(oв), удаляет выбор ВСЕХ ранее выбранных филиалов региона(ов)
forward({from: setSelectedRegionData, to: resetSelectedBranchData});
