import React, {ReactNode} from 'react';
import {Tooltip} from '@beeline/design-system-react';

import {StyledSpan} from 'shared/styles/commonStyle';

interface TooltipedSpanProps {
    lineCount: number;
    children: ReactNode;
    disableHover?: boolean;
    noOverflow?: boolean;
    noCursor?: boolean;
    className?: string;
}

const Span = React.forwardRef<HTMLSpanElement, TooltipedSpanProps>(
    ({lineCount, children, noOverflow, noCursor, className}: TooltipedSpanProps, ref) => {
        return (
            <StyledSpan
                ref={ref}
                lineCount={lineCount}
                noOverflow={noOverflow}
                noCursor={noCursor}
                className={className}
            >
                {children}
            </StyledSpan>
        );
    },
);

export const TooltipedSpan = ({
    title,
    children,
    disableHover,
    noCursor,
    lineCount,
    noOverflow,
    className,
}: TooltipedSpanProps & {title: ReactNode}) => {
    return (
        <Tooltip title={title} disableHover={disableHover}>
            <Span
                lineCount={lineCount}
                noOverflow={noOverflow}
                noCursor={noCursor}
                className={className}
            >
                {children}
            </Span>
        </Tooltip>
    );
};
