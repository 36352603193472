import styled, { css } from "styled-components";


export const SmallIconButtonStyled = styled.button`
	width:24px;
	height:24px;
	display:block;
	background-color:transparent;
	border:none;
	.icon{
		width:100%;
		height:100%;
		color:var(--secondary);
		transition-duration:0.4s;
	}
	&:hover{
		.icon{
			color:var(--body-color);
		}
	}
`