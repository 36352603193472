import {forwardPayload} from 'shared/helpers/effector';
import {forward, merge, sample} from 'effector';
import {
    $agreements,
    $page,
    AdditionalAgreementsGate,
    $pageSize,
    setPage,
    setPageSize,
    loadAdditionalAgreementsFx,
    loadBranchesFx,
    loadRegionsFx,
    $count,
    $openedDetailedIds,
    toggleOpenDetails,
    $regionOptions,
    $branchOptions,
    $selectedRegions,
    $selectedBranches,
    changeRegion,
    changeBranch,
    loadBranches,
    $searchValue,
    setSearchTerm,
    searchBySearchTerm,
    $statusId,
    $implementors,
    $contracts,
    $purchasingEvents,
    $approvingAt,
    setSelectBranchSearch,
    setSelectedRegions,
    $inputSearchValue,
} from './index';

$agreements.on(loadAdditionalAgreementsFx.doneData, (state, {data: {results}}) => results);
$regionOptions.on(loadRegionsFx.doneData, (state, payload) => payload.data);
$branchOptions.on(loadBranchesFx.doneData, forwardPayload());
$selectedRegions.on(changeRegion, forwardPayload());
$selectedBranches.on(changeBranch, forwardPayload());

export const paginationTriggers = {
    page: $page,
    page_size: $pageSize,
};

forward({
    from: loadBranches,
    to: setSelectedRegions,
});

export const tableFilters = {
    search: $searchValue,
    regions: $selectedRegions,
    branches: $selectedBranches,
    status_id: $statusId,
    implementers: $implementors,
    purchasing_events: $purchasingEvents,
    contracts: $contracts,
    approving_at: $approvingAt,
};

export const tableTriggers = merge([AdditionalAgreementsGate.open, setPage]);

export const tableSearchTriggers = merge([
    searchBySearchTerm,
    setSelectBranchSearch,
    setSelectedRegions,
    setPageSize,
]);

sample({
    clock: searchBySearchTerm,
    source: $inputSearchValue,
    target: $searchValue,
});

sample({
    clock: [tableTriggers, tableSearchTriggers],
    source: {
        ...tableFilters,
        ...paginationTriggers,
    },
    fn: (stores) => {
        const params = {
            page: stores.page,
            page_size: stores.page_size,
            ...{...(stores?.search ? {search: stores.search} : {})},
        };
        return {...params};
    },
    target: loadAdditionalAgreementsFx,
});

sample({
    clock: loadBranches,
    source: $selectedRegions,
    fn: (regions) => regions.map((el) => el.code).join(','),
    target: loadBranchesFx,
});
//filters

$inputSearchValue.on(setSearchTerm, forwardPayload());

// pagination
$count.on(loadAdditionalAgreementsFx.doneData, (state, {data: {count}}) => count);
$page.on(setPage, forwardPayload()).on(tableSearchTriggers, () => 1);
$pageSize.on(setPageSize, forwardPayload());
$openedDetailedIds
    .on(toggleOpenDetails, (state, payload) => {
        if (state.includes(payload)) {
            return state.filter((el) => el !== payload);
        }
        return [...state, payload];
    })
    .reset([setSearchTerm, setPage, setPageSize]);
