import React from 'react';

import {IntegrationWidget} from 'widgets/IntegrationWidget';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {PO_TITLE, PR_TITLE, SIGNING_TITLE} from 'shared/constants';

import {IAgreementLinkedEntity} from '../../types';

interface DSPageIntegrationUnitProps {
    data?: IAgreementLinkedEntity;
    onDownload: () => void;
}

export const DSPageIntegrationUnit = ({data, onDownload}: DSPageIntegrationUnitProps) => {
    if (!data) return null;

    return (
        <WrapperOffset offsetTop={36} gap="16px" flex>
            {data.payment_request && (
                <IntegrationWidget
                    name={PR_TITLE}
                    orderNumber={data.payment_request.number}
                    date={data.payment_request.approved_at}
                    status={data.payment_request.status}
                />
            )}
            {data.signing && data.signing.diadoc_url && (
                <IntegrationWidget
                    name={SIGNING_TITLE}
                    date={data.signing.implementer_signing_date}
                    status={data.signing.status}
                    externalLink={data.signing.diadoc_url}
                    showSecondLink={data.signing.diadoc_archive}
                    onDownload={onDownload}
                />
            )}
            {data.purchase_order && (
                <IntegrationWidget
                    name={PO_TITLE}
                    orderNumber={data.purchase_order.number}
                    date={data.purchase_order.approved_at}
                    status={data.purchase_order.status}
                    dcmLink={data.purchase_order.dcm_hyperlink}

                />
            )}
        </WrapperOffset>
    );
};
