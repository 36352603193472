import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import Breadcrumb from 'shared/ui/deprecated/Breadcrumb/Breadcrumb';
import {Skeleton} from 'shared/ui/deprecated/Skeleton/Skeleton';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import React from 'react';
import BreadcrumbItem from 'shared/ui/deprecated/Breadcrumb/BreadcrumbItem';

export const ViewProjectTitleSkeleton = () => {
    return (
        <WrapperFlex justifyContent="space-between">
            <div>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Skeleton width={70} height={18} />
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Skeleton width={70} height={18} />
                    </BreadcrumbItem>
                </Breadcrumb>
                <WrapperOffset offsetTop="12">
                    <WrapperFlex alignItems="center">
                        <Skeleton className="me-3" width={343} height={32} />
                        <Skeleton width={143} height={24} />
                    </WrapperFlex>
                </WrapperOffset>
                <WrapperOffset offsetTop="12">
                    <WrapperFlex alignItems="center">
                        <Skeleton className="me-2" height={16} />
                        <Skeleton height={16} />
                    </WrapperFlex>
                </WrapperOffset>
            </div>
            <WrapperFlex alignItems="center">
                <Skeleton className="me-3" width={122} height={48} />
                <Skeleton width={122} height={48} />
            </WrapperFlex>
        </WrapperFlex>
    );
};
