import React from 'react';
import {useGate, useUnit} from 'effector-react';
import {Button, Checkbox, DatePicker, Progress, TextField} from '@beeline/design-system-react';

import {TitleWithBackButton} from 'Features/TitleWithBackButton';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {Wrapper} from 'shared/styles/commonStyle';
import {formatDateToBackendFormat} from 'shared/helpers/formatHelper';
import {
    CANCEL_BUTTON_TITLE,
    FILL_IN_PO_NUMBER_TITLE,
    PO_NUMBER_TITLE,
    UNUSUAL_PO_NUMBER_TITLE,
} from 'shared/constants';

import './style.scss';
import {
    $poDocumentsStore,
    poIsExceptionalChanged,
    poNumberChanged,
    PoNumberCreationGate,
    positiveButtonClicked,
    signingDateChanged,
} from '../model/poDocuments';
import {ECompletedWorksView, PoNumberCreationProps} from '../model/helpers';
import {ETaskTypeConfirmations} from '../../DSPage/types';

export const PoNumberCreation = (props: PoNumberCreationProps) => {
    useGate(PoNumberCreationGate, props);
    const {
        poData: {poNumber, poIsExceptional, signingDate},
        poNumberError,
        signingDateError,
        formTouched,
        shouldCloseSubpage,
        isLoading,
        buttonDisabled,
        signingDateDisabled,
    } = useUnit($poDocumentsStore);
    const {taskData, handleClose, mode} = props;
    const workflowData = taskData[0];
    const signButtonData = workflowData.actions.find(
        (item) =>
            item.is_positive === null &&
            item.type_confirmation ===
                (mode === ECompletedWorksView.po
                    ? ETaskTypeConfirmations.PO
                    : ETaskTypeConfirmations.MandatoryPO),
    );
    const signButtonIndex = workflowData.actions.findIndex(
        (item) =>
            item.is_positive === null &&
            item.type_confirmation ===
                (mode === ECompletedWorksView.po
                    ? ETaskTypeConfirmations.PO
                    : ETaskTypeConfirmations.MandatoryPO),
    );
    if (shouldCloseSubpage) {
        handleClose();
    }
    const textFieldHelper = formTouched && poNumberError ? 'Введите номер ЗП' : undefined;
    const datePickerHelper =
        formTouched && signingDateError
            ? 'Дата подписания должна быть не раньше 1 января 2020 года и не позже текущей даты'
            : undefined;
    return (
        <Wrapper>
            <TitleWithBackButton
                title={FILL_IN_PO_NUMBER_TITLE}
                onClick={handleClose ?? (() => 1)}
            />
            {isLoading ? (
                <WrapperFlex height="300px" justifyContent="center" alignItems="center">
                    <Progress shape="circle" cycled />
                </WrapperFlex>
            ) : (
                <WrapperFlex
                    flexDirection="column"
                    height="calc(100vh - 230px)"
                    justifyContent="space-between"
                >
                    <WrapperFlex flexDirection="column" gap="32px" width="360px">
                        <DatePicker
                            className={'fieldHelperPosition'}
                            label="Дата подписания ДС/доп"
                            value={signingDate ?? undefined}
                            onChange={signingDateChanged}
                            error={formTouched && signingDateError}
                            minDate={'2020-01-01'}
                            maxDate={formatDateToBackendFormat(new Date().toString())}
                            disabled={isLoading || signingDateDisabled}
                            helperText={datePickerHelper}
                            fullWidth
                        />
                        <TextField
                            className={'fieldHelperPosition textField'}
                            label={PO_NUMBER_TITLE}
                            value={poNumber}
                            helperText={textFieldHelper}
                            onChange={(e) => poNumberChanged(e.currentTarget.value)}
                            error={poNumberError && formTouched}
                            fullWidth
                        />
                        <Checkbox
                            label={UNUSUAL_PO_NUMBER_TITLE}
                            checked={poIsExceptional}
                            onChange={(e) => poIsExceptionalChanged(e.currentTarget.checked)}
                        />
                    </WrapperFlex>
                    <WrapperFlex gap="16px" alignSelf="flex-end">
                        <Button variant="outlined" size="medium" onClick={handleClose}>
                            {CANCEL_BUTTON_TITLE}
                        </Button>
                        {!!signButtonData && (
                            <Button
                                variant="contained"
                                size="medium"
                                onClick={() => positiveButtonClicked(signButtonIndex)}
                                disabled={
                                    buttonDisabled ||
                                    isLoading ||
                                    ((poNumberError || signingDateError) && formTouched)
                                }
                            >
                                {signButtonData.name}
                            </Button>
                        )}
                    </WrapperFlex>
                </WrapperFlex>
            )}
        </Wrapper>
    );
};
