import {sample} from 'effector';
import {$fullVirPattern, loadPatternByIdFx, PatternConstructorGate} from './index';
import {$selectedPattern} from 'pages/VirPatternsPage/model'; // TO fix
import {forwardPayload} from 'shared/helpers/effector';

sample({
    clock: PatternConstructorGate.open,
    source: $selectedPattern,
    filter: (id) => !!id,
    target: loadPatternByIdFx,
});

$fullVirPattern.on(loadPatternByIdFx.doneData, forwardPayload());
