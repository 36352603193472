import styled, {css} from 'styled-components/macro';

const baseSearchMixin = () => css`
    position: relative;
    --tsearchbtn: 12px;
    display: flex;
    flex-grow: 1;

    input[type='radio'] {
        display: none;
    }

    input {
        display: block;
        border-radius: 12px 0 0 12px;
        width: 100%;
        border-width: 1px;
        border-color: transparent;
        padding-left: 56px;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 12px;
        background-color: var(--light-control);
        transition-duration: 0.4s;
        font-size: 17px;
        line-height: calc(22 / 17);
        outline: none;
        height: 48px;
        border-right: none;

        &:focus {
            background-color: var(--outline-body-color);
            border-color: #202123;
        }

        &:focus ~ div {
            --close-op: 1;
            --close-pe: all;
            background-color: var(--outline-body-color);
            border-color: #202123;
        }

        &:hover {
            border-color: #202123;
        }

        &:hover ~ div {
            border-color: #202123;
        }
    }
`;

const Active = () => css`
    --close-op: 1;
    --close-pe: all;
`;
const Small = () => css`
    input {
        padding-top: 8px;
        padding-bottom: 8px;
        height: 40px;
    }

    --categ-p: 8px;
    --tsearchbtn: 8px;
    --tclosebtn: 8px;
    --categ-h: 40px;
`;
export const searchMixin = (props) => css`
    ${baseSearchMixin()}
    ${props.small && Small}
  ${props.active && Active}
`;

export const SearchStyled = styled.div`
    ${(props) => searchMixin(props)}
`;

const searchCategoryBlockMixin = (props) => css`
    ${css`
        background-color: var(--light-control);
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuMjg1MTYgNi45MTQwNkM1LjExNTg5IDYuOTc5MTcgNC45OTIxOSA3LjEwMjg2IDQuOTE0MDYgNy4yODUxNkM0Ljg0ODk2IDcuNDY3NDUgNC44NjE5OCA3LjYzNjcyIDQuOTUzMTIgNy43OTI5N0M0Ljk3OTE3IDcuODU4MDcgNS44NTgwNyA4Ljc1IDcuNTg5ODQgMTAuNDY4OEM4Ljc2MTcyIDExLjY0MDYgOS40OTA4OSAxMi4zNjk4IDkuNzc3MzQgMTIuNjU2MkMxMC4wNzY4IDEyLjkyOTcgMTAuMjU5MSAxMy4wNzk0IDEwLjMyNDIgMTMuMTA1NUMxMC40OTM1IDEzLjE0NDUgMTAuNjQ5NyAxMy4xMjUgMTAuNzkzIDEzLjA0NjlDMTAuODU4MSAxMy4wMjA4IDExLjc1IDEyLjE0MTkgMTMuNDY4OCAxMC40MTAyQzE0LjY0MDYgOS4yMzgyOCAxNS4zNjMzIDguNTA5MTEgMTUuNjM2NyA4LjIyMjY2QzE1LjkyMzIgNy45MjMxOCAxNi4wNzk0IDcuNzQwODkgMTYuMTA1NSA3LjY3NTc4QzE2LjE3MDYgNy40MjgzOSAxNi4xMTg1IDcuMjIwMDUgMTUuOTQ5MiA3LjA1MDc4QzE1Ljc3OTkgNi44ODE1MSAxNS41NzE2IDYuODI5NDMgMTUuMzI0MiA2Ljg5NDUzQzE1LjI1OTEgNi45MjA1NyAxNS4wODk4IDcuMDYzOCAxNC44MTY0IDcuMzI0MjJDMTQuNTU2IDcuNTcxNjEgMTMuODk4NCA4LjIyMjY2IDEyLjg0MzggOS4yNzczNEwxMC41IDExLjYyMTFMOC4xNTYyNSA5LjI3NzM0QzcuMTUzNjUgOC4yNzQ3NCA2LjUwMjYgNy42MzY3MiA2LjIwMzEyIDcuMzYzMjhDNS45MTY2NyA3LjA3NjgyIDUuNzQwODkgNi45MjA1NyA1LjY3NTc4IDYuODk0NTNDNS41OTc2NiA2Ljg4MTUxIDUuNTMyNTUgNi44NzUgNS40ODA0NyA2Ljg3NUM1LjQyODM5IDYuODc1IDUuMzYzMjggNi44ODgwMiA1LjI4NTE2IDYuOTE0MDZaIiBmaWxsPSIjMTkxQzM0IiBmaWxsLW9wYWNpdHk9IjAuNyIvPgo8L3N2Zz4K);
        background-position: right 0.75rem center;
        background-repeat: no-repeat;
        background-size: 20px;
        border: 1px solid var(--light-control);
        border-left: none;
        border-radius: 0 12px 12px 0;
        cursor: pointer;
        padding: var(--categ-p, 12px) 44px var(--categ-p, 12px) 16px;
        height: var(--categ-h, 48px);
        transition-duration: 0.4s;
        font-size: 17px;
        line-height: calc(22 / 17);
        position: relative;
        min-width: 147px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &::after {
            background-color: #cacaca;
            content: '';
            height: calc(100% - 24px);
            left: 1px;
            position: absolute;
            top: 12px;
            width: 1px;
            z-index: 0;
        }
    `}
    ${props.active &&
    css`
        background-color: #ffffff;
    `}
`;
export const SearchCategoryBlock = styled.div`
    ${(props) => searchCategoryBlockMixin(props)}
`;

export const CloseBtnWrap = styled.div`
    position: relative;
    border-top: 1px solid var(--light-control);
    border-bottom: 1px solid var(--light-control);
    height: var(--categ-h, 48px);
    width: 56px;
    min-width: 56px;
    background-color: var(--light-control);
    transition-duration: 0.4s;
`;

export const SearchBtn = styled.button`
    position: absolute;
    width: 24px;
    height: 24px;
    top: var(--tsearchbtn);
    left: 16px;
    padding: 0;
    background-color: transparent;
    border: none;
    transition-duration: 0.4s;

    .icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;