import {TableCell, TableHead, TableString} from 'shared/ui/Table/Table';
import {IToggledAdditionalAgreementListItem} from 'shared/types/additionalAgreementsTypes';
import {IColumn} from 'shared/types/tableTypes';

interface AgreementsTableHeadProps {
    columns: IColumn<IToggledAdditionalAgreementListItem>[];
}

export function AgreementsTableHead({columns}: AgreementsTableHeadProps) {
    return (
        <TableString>
            {columns.map((item, index) => (
                <TableCell key={index} {...item}>
                    <TableHead>{item.label}</TableHead>
                </TableCell>
            ))}
        </TableString>
    );
}
