import React from 'react';
import {useGate, useStore} from 'effector-react';

import {ErrorItem} from 'Features/ErrorItem';
import {TitleWithBackButton} from 'Features/TitleWithBackButton';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {ERRORS_SUBTITLE_TEXT, ERRORS_TITLE, SEND_TO_APPROVE} from 'shared/constants';

import * as S from './style';
import {$errorListWidget, ErrorCheckboxToggled, ErrorListWidgetGate} from '../model';

interface ErrorListWidgetProps {
    agreementId: string;
    onClick: () => void;
    onClose: () => void;
    isLoading: boolean;
}

export const ErrorListWidget = ({
    agreementId,
    onClick,
    onClose,
    isLoading,
}: ErrorListWidgetProps) => {
    useGate(ErrorListWidgetGate, {id: agreementId});
    const {errorList, actionButtonDisabled, togglingInProgress} = useStore($errorListWidget);

    const sendButtonDisabled = isLoading || actionButtonDisabled;

    return (
        <WrapperFlex
            flexDirection="column"
            justifyContent="flex-start"
            width="760px"
            height="calc(100vh - 130px)"
            margin="0px auto"
        >
            <TitleWithBackButton title={ERRORS_TITLE} onClick={onClose} />
            <S.Subtitle variant="body3">{ERRORS_SUBTITLE_TEXT}</S.Subtitle>
            <WrapperFlex
                flexDirection="column"
                margin="32px 0px"
                gap="32px"
                height="300px"
                verticalScroll
            >
                {errorList.map((error) => (
                    <ErrorItem
                        key={error.id}
                        errorId={error.id}
                        checked={error.is_fixed}
                        onChange={ErrorCheckboxToggled}
                        label={error.description}
                        prompt={error.how_to_fix}
                        errorCode={error.code}
                        link={error.hyper_link.name}
                        linkTitle={error.hyper_link.name}
                        copyLink={null}
                        disabled={togglingInProgress}
                    />
                ))}
            </WrapperFlex>
            <S.StyledButton onClick={onClick} variant="contained" disabled={sendButtonDisabled}>
                {SEND_TO_APPROVE}
            </S.StyledButton>
        </WrapperFlex>
    );
};
