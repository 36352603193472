import React, {useEffect} from 'react';
import {TextArea, Typography} from '@beeline/design-system-react';

import {Comment} from 'Entities/Comment';
import {JobCategoryUnit} from 'Entities/JobCategoryUnit';
import {DownloadableAttachment} from 'Features/DownloadableAttachment';
import {IComment} from 'pages/DSPage/types';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {TableString} from 'shared/ui/Table/Table';
import {useFileUploader} from 'shared/ui/use-file-uploader';
import {deriveFileList, prepareTFileList} from 'shared/helpers/transformObject';
import {EAttachmentType, TAttachmentFile} from 'shared/types/FileTypes';
import {DOCUMENT_ALLOWED_FORMAT_LIST, MAX_ALLOWED_UPLOAD_FILE_SIZE} from 'shared/const/fileFormats';

import {StyledTableData} from 'shared/styles/commonStyle';
import * as S from './style';

interface AttachmentRowProps {
    id: string;
    comment: string | null;
    readonlyComments: IComment[];
    attachments: TAttachmentFile[];
    parentComment?: IComment[];
    parentAttachments?: TAttachmentFile[];
    loadingAttachmentId: string;
    isReadonly?: boolean;
    onChangeComment: (comment: string) => void;
    onChangeAttachments: (attachmentList: TAttachmentFile[]) => void;
    handleDownloadAttachment: (fileId: string, fileName: string, type: EAttachmentType) => void;
    jobCategory: {id: string; work_category: string} | null;
    jobCategoryDescription: string | null;
}

export const AttachmentRow = ({
    id,
    comment,
    readonlyComments,
    attachments,
    parentComment,
    parentAttachments,
    loadingAttachmentId,
    isReadonly,
    onChangeComment,
    onChangeAttachments,
    handleDownloadAttachment,
    jobCategory,
    jobCategoryDescription,
}: AttachmentRowProps) => {
    const [FileUploader, files, fileUploaderDispatch, isError] = useFileUploader({
        fileUploaderName: `work specification attachments_${id}`,
        maxFileSize: MAX_ALLOWED_UPLOAD_FILE_SIZE,
        acceptFormats: DOCUMENT_ALLOWED_FORMAT_LIST,
        maxFiles: 10,
        mode: 'additionalAgreementCreation',
        initialFiles: prepareTFileList(attachments),
        externalError: false,
    });

    useEffect(() => {
        const fileList = deriveFileList(files);
        onChangeAttachments(fileList);
    }, [files]);

    return (
        <TableString>
            <StyledTableData colSpan={10}>
                <WrapperFlex>
                    <S.AttachmentUnitWrapper>
                        <JobCategoryUnit
                            jobCategory={jobCategory}
                            jobCategoryDescription={jobCategoryDescription}
                        />
                        {(!isReadonly || !!attachments.length) && (
                            <Typography variant="h6">Вложения к пункту</Typography>
                        )}
                        {!isReadonly ? (
                            <FileUploader />
                        ) : (
                            <>
                                {!!attachments.length &&
                                    attachments.map((item) => (
                                        <DownloadableAttachment
                                            id={item.id ?? ''}
                                            key={item.id}
                                            type={item.type}
                                            title={item.file_name}
                                            handleDownloadAttachment={handleDownloadAttachment}
                                            disabled={loadingAttachmentId === item.id}
                                        />
                                    ))}
                            </>
                        )}

                        {!!parentAttachments?.length && (
                            <WrapperOffset offsetTop="16">
                                <Typography variant="h6">Вложения к пункту из ДС</Typography>
                                {parentAttachments.map((item) => (
                                    <DownloadableAttachment
                                        id={item.id ?? ''}
                                        key={item.id}
                                        type={item.type}
                                        title={item.file_name}
                                        handleDownloadAttachment={handleDownloadAttachment}
                                        disabled={loadingAttachmentId === item.id}
                                    />
                                ))}
                            </WrapperOffset>
                        )}
                    </S.AttachmentUnitWrapper>
                    <WrapperFlex flexDirection="column" flex="1 1 440px" gap="8px" minWidth="420px">
                        {!isReadonly ? (
                            <>
                                <Typography variant="h6">Комментарий к пункту работ</Typography>
                                <TextArea
                                    label="Комментарий (при необходимости)"
                                    helperText={''}
                                    error={false}
                                    value={comment ?? undefined}
                                    onChange={(e) => onChangeComment(e.target.value)}
                                    size="medium"
                                    fullWidth
                                />
                            </>
                        ) : (
                            <>
                                {!!readonlyComments?.length && (
                                    <>
                                        <Typography variant="h6">
                                            Комментарий к пункту работ
                                        </Typography>
                                        {readonlyComments.map((item) => (
                                            <Comment key={item.created_at} {...item} />
                                        ))}
                                    </>
                                )}
                            </>
                        )}

                        {!!parentComment?.length && (
                            <WrapperOffset offsetTop="16">
                                <Typography variant="h6">Комментарий к пункту из ДС</Typography>
                                {parentComment.map((item) => (
                                    <Comment key={item.created_at} {...item} />
                                ))}
                            </WrapperOffset>
                        )}
                    </WrapperFlex>
                </WrapperFlex>
            </StyledTableData>
        </TableString>
    );
};
