import {useStore} from 'effector-react';

import Pagination from 'shared/ui/Pagination/Pagination';
import {Table} from 'shared/ui/Table/Table';
import {IColumn, IColumnWithActions} from 'shared/types/tableTypes';
import {
    IAgreementAddition,
    IToggledAdditionalAgreementListItem,
} from 'shared/types/additionalAgreementsTypes';

import {AgreementsContentSkeleton} from './AgreementsTableSkeleton';
import {AgreementsTableHead} from './AgreementsTableHead';
import {AgreementTableContent} from './AgreementTableContent';
import {ProjectTableWrap} from './style';
import {NoResults} from './NoResults';
import {
    $additionalAgreementsPagination,
    $additionalAgreementsTable,
    setPage,
    setPageSize,
    toggleOpenDetails,
} from '../../model';

interface AdditionalAgreementsTableProps {
    columns: IColumnWithActions<IToggledAdditionalAgreementListItem>[];
    innerColumns: IColumn<IAgreementAddition>[];
    withPagination?: boolean;
}

export const AdditionalAgreementsTable = ({
    columns,
    withPagination,
    innerColumns,
}: AdditionalAgreementsTableProps) => {
    const {agreements, loading, openedIds} = useStore($additionalAgreementsTable);
    const {page, pageSize, total} = useStore($additionalAgreementsPagination);

    if (!loading && !agreements.length) {
        return <NoResults />;
    }

    return (
        <>
            <ProjectTableWrap className="scrollbar-horizontal">
                <Table minWidth="unset">
                    <tbody>
                        <AgreementsTableHead columns={columns} />
                        {loading ? (
                            <AgreementsContentSkeleton columns={columns} />
                        ) : (
                            <AgreementTableContent
                                columns={columns}
                                innerColumns={innerColumns}
                                agreements={agreements}
                                openedIds={openedIds}
                                toggleOpenDetails={toggleOpenDetails}
                            />
                        )}
                    </tbody>
                </Table>
            </ProjectTableWrap>
            {withPagination && agreements.length !== 0 && (
                <Pagination
                    total={total}
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    className="py-3"
                />
            )}
        </>
    );
};
