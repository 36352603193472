import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import {Table, TableCell, TableCellContent, TableHead, TableString} from 'shared/ui/Table/Table';
import SmallIconButton from 'shared/ui/deprecated/SmallIconButton/SmallIconButton';
import EditSvg from 'icons/EditIcon';
import {
    $patterns,
    $patternsPage,
    selectPattern,
    setIsDeletePatternModalOpen,
    setPage,
    setPageSize,
} from 'pages/VirPatternsPage/model';
import DeleteSvg from 'icons/DeleteIcon';
import Pagination from 'shared/ui/Pagination/Pagination';
import {Skeleton} from 'shared/ui/deprecated/Skeleton/Skeleton';
import {VirPattern} from 'pages/VirPatternsPage/types';
import {NotFound, NotFoundCaption, NotFoundTitle} from 'components/NotFound';
import {columns} from 'pages/VirPatternsPage/ui/columns';
import * as S from './styled';

export function PatternsTable() {
    const templates: VirPattern[] = useStore($patterns);
    const {pageSize, page, count, loadingForPatterns, selectedPattern} = useStore($patternsPage);

    return (
        <>
            <S.BorderedWrapper className="scrollbar-horizontal">
                <Table minWidth="unset">
                    <tbody>
                        <TableString>
                            {columns.map((item) => (
                                <TableCell
                                    key={item.id}
                                    width={item.width}
                                    minWidth={item.minWidth}
                                >
                                    <TableHead>{item.label}</TableHead>
                                </TableCell>
                            ))}
                        </TableString>

                        {!loadingForPatterns &&
                            templates.map((pattern, index) => {
                                return (
                                    <S.ProjectString
                                        key={pattern.id}
                                        selected={selectedPattern === pattern.id}
                                    >
                                        <TableCell>
                                            <TableCellContent>{pattern.name}</TableCellContent>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellContent>
                                                <S.ContractLink
                                                    to={`/contracts/${pattern.contract_id}`}
                                                >
                                                    {pattern.contract_number}
                                                </S.ContractLink>
                                            </TableCellContent>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellContent>
                                                {pattern.creator_name}
                                            </TableCellContent>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellContent>
                                                {pattern.created_at}
                                            </TableCellContent>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellContent>
                                                {pattern.updated_at}
                                            </TableCellContent>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellContent>
                                                <Link to="/additional-agreement/constructor">
                                                    <SmallIconButton
                                                        onClick={() => {}}
                                                        icon={<EditSvg />}
                                                    />
                                                </Link>
                                            </TableCellContent>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellContent>
                                                <SmallIconButton
                                                    onClick={() => {
                                                        setIsDeletePatternModalOpen(true);
                                                    }}
                                                    icon={<DeleteSvg />}
                                                />
                                            </TableCellContent>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellContent
                                                onClick={() => selectPattern(pattern.id)}
                                            >
                                                <S.SelectText
                                                    selected={selectedPattern === pattern.id}
                                                >
                                                    {selectedPattern === pattern.id
                                                        ? 'Выбран'
                                                        : 'Выбрать'}
                                                </S.SelectText>
                                            </TableCellContent>
                                        </TableCell>
                                    </S.ProjectString>
                                );
                            })}
                        {loadingForPatterns && (
                            <>
                                {[...Array(pageSize)].map((x, i) => (
                                    <S.ProjectString key={i}>
                                        {columns.map(
                                            (item) =>
                                                item.visible && (
                                                    <TableCell key={item.id}>
                                                        <TableCellContent>
                                                            <Skeleton height={20} />
                                                        </TableCellContent>
                                                    </TableCell>
                                                ),
                                        )}
                                    </S.ProjectString>
                                ))}
                            </>
                        )}
                    </tbody>
                </Table>
                {(templates.length !== 0 || loadingForPatterns) && (
                    <S.PaginationBox>
                        <Pagination
                            page={page}
                            pageSize={pageSize}
                            total={count}
                            setPageSize={setPageSize}
                            setPage={setPage}
                        />
                    </S.PaginationBox>
                )}
            </S.BorderedWrapper>
            {!loadingForPatterns && templates.length === 0 && (
                <NotFound height="500px">
                    <NotFoundTitle>По вашему запросу ничего не найдено</NotFoundTitle>
                    <NotFoundCaption>
                        Попробуйте сократить запрос или задать его по-другому
                    </NotFoundCaption>
                </NotFound>
            )}
        </>
    );
}
