import styled, {css} from 'styled-components/macro';

export const TableMixin = (props) => css`
    min-width: ${props.minWidth ? props.minWidth : 'var(--min-w,1300px)'};
    width: 100%;
    ${props.tableLayout &&
    css`
        table-layout: fixed;
    `}
`;
export const TableStyled = styled.table`
    ${(props) => TableMixin(props)}
`;

const TableStringStyledMixin = (props) => css`
    border-bottom: 1px solid #e0e0e0;
    transition-duration: 0.2s;
    ${props.noBorder &&
    css`
        border-color: transparent;
    `}
    ${props.disable &&
    css`
        display: none;
    `}
  ${props.width &&
    css`
        width: ${props.width};
    `}

  ${props.clickable &&
    css`
        cursor: pointer;

        &:hover {
            background: var(--color-background-base-hover);
        }
    `}
`;

export const TableStringStyled = styled.tr`
    ${(props) => TableStringStyledMixin(props)}
`;

const TableStringSelectedMixin = (props) => css`
    &:hover {
        background-color: #ededef;
    }

    ${props.checked &&
    css`
        background-color: #ededef;
    `}
`;

export const TableStringSelectedStyled = styled(TableStringStyled)`
    ${(props) => TableStringSelectedMixin(props)}
`;

export const TableCellMixin = (props) => css`
    ${css`
        height: 52px;
        padding: 8px 16px;
    `}
    ${props.height &&
    css`
        height: ${props.height};
    `}
  ${props.width &&
    props.minWidth &&
    css`
        width: ${props.width};
        min-width: ${props.minWidth};
    `}
  ${props.width &&
    !props.minWidth &&
    css`
        width: ${props.width};
        max-width: ${props.width};
    `}
  ${props.clickable &&
    css`
        cursor: pointer;
    `}
  ${props.hover &&
    css`
        div {
            &:hover {
                ::after {
                    content: 'arrow_up';
                    font-family: 'BeelineIcons';
                    font-size: 20px;
                    color: var(--color-text-inactive);
                    ${props.alreadyHasSorting &&
                    css`
                        opacity: 0.37;
                    `}
                }
            }
        }
    `}
  ${props.arrowUp &&
    css`
        div {
            ::after {
                content: 'arrow_up';
                font-family: 'BeelineIcons';
                font-size: 20px;
                color: var(--color-text-inactive);
            }
        }
    `}
  ${props.arrowDown &&
    css`
        div {
            ::after {
                content: 'arrow_down';
                font-family: 'BeelineIcons';
                font-size: 20px;
                color: var(--color-text-inactive);
            }
        }
    `}
  ${props.height &&
    css`
        height: ${props.height};
    `}
  ${props.noPadding &&
    css`
        padding: 0;
    `}
  ${props.responsive &&
    css`
        @media (min-width: 1200px) {
            width: 1100px;
            min-width: 1100px;
            max-width: 1100px;
        }
    `}
`;

export const TableCellStyled = styled.td`
    ${(props) => TableCellMixin(props)}
`;
export const TableHeadMixin = (props) => css`
    ${css`
        display: flex;
        justify-content: flex-start;
        font-weight: 500;
        line-height: calc(20 / 15);
        align-items: center;
    `}
    ${props.right &&
    css`
        justify-content: flex-end;
    `}
`;
export const TableHeadStyled = styled.div`
    ${(props) => TableHeadMixin(props)}
`;

export const TableCellContentMixin = (props) => css`
    ${css`
        justify-content: flex-start;
        line-height: calc(20 / 15);
        align-items: center;
        display: -webkit-box;
    `}
    ${props.right &&
    css`
        justify-content: flex-end;
    `}
  ${props.column &&
    css`
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    `}
  ${props.column &&
    props.right &&
    css`
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
    `}
`;
export const TableCellContentStyled = styled.div`
    ${(props) => TableCellContentMixin(props)}
`;
