import styled from 'styled-components/macro';
import {TableString} from 'shared/ui/Table/Table';

export const InnerAccordion = styled(TableString)`
    box-shadow: 0 0 0 0 #fdc435, inset 4px 0 0 0 #fdc435;
    min-height: 53px;
`;

export const ProjectTableWrap = styled.div`
    overflow-x: auto;
    border: 1px solid #e0e0e0;
    border-radius: 4px;

    td > div {
        justify-content: flex-start;
    }

    && tr:not(:first-child) {
        &:hover {
            background-color: #ededef;
        }
    }
`;
