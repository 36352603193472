import React from 'react';
import {
    Table,
    TableBody,
    TableData,
    TableHead,
    TableHeaderData,
    TableRow,
} from '@beeline/design-system-react';

import {ConditionalLinkCell} from 'Entities/TableCells/ConditionalLinkCell';
import {ITaskForSign} from 'shared/types/processesTypes';

import {ProgressCell} from './ProgressCell/ProgressCell';

interface SignTableProps {
    data: ITaskForSign[];
    onRefresh: (id: string) => void;
}

export const SignTable = ({data, onRefresh}: SignTableProps) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderData>№</TableHeaderData>
                    <TableHeaderData>№ документа</TableHeaderData>
                    <TableHeaderData>ГПО</TableHeaderData>
                    <TableHeaderData>Филиал</TableHeaderData>
                    <TableHeaderData>Статус</TableHeaderData>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((item, index) => (
                    <TableRow key={item.object.id}>
                        <TableData>{index + 1}</TableData>
                        <ConditionalLinkCell
                            link={item.object.id}
                            objectType={item.object.type_object}
                            title={item.object.number}
                            blank
                        />
                        <TableData>{item.gpo_short_name}</TableData>
                        <TableData>{item.branch}</TableData>
                        <ProgressCell
                            id={item.object.id}
                            progress={item.progress}
                            onClick={onRefresh}
                        />
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
