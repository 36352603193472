import React from 'react'
import styled from 'styled-components/macro'

const BreadcrumbItemStyled = styled.div`
position: relative;
padding-left: 4px;
padding-right: 4px;
line-height: calc(18 / 15);
margin-bottom: 0 !important;
margin-right:34px;
&::after {
	content: "";
	width: 18px;
	height: 18px;
	position: absolute;
	top: 0;
	left: calc(100% + 8px);
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url(/img/chevron-right.svg);
}
&:last-child{
	margin-right:0;
	a {
		color: var(--secondary);
	}
	color: var(--secondary);
	&::after {
		display: none;
	}
}
`
export default function BreadcrumbItem(props) {
	const {
		children
	} = props
	return (
		<BreadcrumbItemStyled>{children}</BreadcrumbItemStyled>
	)
}
