import styled, {css} from 'styled-components/macro';

export const RadioWrapper = styled.div<{selected?: boolean}>`
    border-radius: var(--size-border-radius-x6);
    border: 2px solid var(--color-divider-grey);
    padding: 24px;
    ${(props) =>
        props.selected &&
        css`
            border: 2px solid var(--color-background-brand);
        `}
`;

export const LoaderWrapper = styled(RadioWrapper)<{noBorder?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 134px;
    ${(props) =>
        props.noBorder &&
        css`
            border: none;
        `}
`;

export const DisabledText = styled.div<{active: boolean}>`
    ${(props) =>
        props.active &&
        css`
            p {
                color: red;
            }
        `}
`;
