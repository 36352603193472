import {ListBlock} from 'shared/ui/deprecated/ButtonList/style';
import styled from 'styled-components/macro';

export const SubMenu = ({children, ...rest}) => {
    return <ListItem {...rest}>{children}</ListItem>;
};

export const ListItem = styled(ListBlock)`
    position: absolute;
    left: 100%;
    top: 0;
`;
