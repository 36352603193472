import {useStore} from 'effector-react';

import {$agreementOnRevision, $constructorPage, setActiveAccordion} from 'processes/contructor';
import {NotFound, NotFoundCaption, NotFoundTitle} from 'components/NotFound';
import {Accordion} from 'shared/ui/deprecated/Accordion/Accordion';
import {Table, TableCell, TableCellContent, TableHead, TableString} from 'shared/ui/Table/Table';

import * as S from './style';
import {DeleteVirWork} from '../modals/DeleteVirStatement';
import {Summary} from '../Summary/Summary';
import {VirWork} from '../VirWork/VirWork';
import {isWorksheetDeletable} from '../helpers';

export function VirAccordion() {
    const {openedWork, touchedConstructor, ds, checkedSpecsForUpdate} = useStore($constructorPage);
    const works = ds.works;
    const worksArr = Object.values(works);
    const showedToolbar = checkedSpecsForUpdate.length > 1;
    const agreementOnRevision = useStore($agreementOnRevision);

    return (
        <>
            <Accordion>
                {worksArr.map((vir, index) => {
                    return (
                        <S.StyledAccordionItem
                            key={vir.id}
                            id={vir.id}
                            title={`Ведомость ${index + 1}`}
                            active={openedWork === vir?.id}
                            setActive={() => setActiveAccordion(vir?.id)}
                            rightslot={
                                openedWork === vir.id ? (
                                    <DeleteVirWork
                                        id={vir?.id}
                                        isDeletable={isWorksheetDeletable(vir)}
                                    />
                                ) : (
                                    ''
                                )
                            }
                        >
                            <Table>
                                <tbody>
                                    {Object.keys(vir?.work_objects || [])?.length > 0 &&
                                        (showedToolbar ? (
                                            <TableString>
                                                <TableCell />
                                            </TableString>
                                        ) : (
                                            <TableString>
                                                <TableCell width="56px">
                                                    <TableCellContent></TableCellContent>
                                                </TableCell>
                                                <TableCell width="136px">
                                                    <TableHead>№ п.п.</TableHead>
                                                </TableCell>
                                                <TableCell width="calc(100% - 927px)">
                                                    <TableHead>Наименование работ</TableHead>
                                                </TableCell>
                                                <TableCell width="90px">
                                                    <TableHead>Кол-во</TableHead>
                                                </TableCell>
                                                <TableCell width="142px">
                                                    <TableHead right>Цена за ед., ₽</TableHead>
                                                </TableCell>
                                                <TableCell width="104px">
                                                    <TableHead>Коэф-т</TableHead>
                                                </TableCell>
                                                <TableCell width="132px">
                                                    <TableHead right>Стоимость, ₽</TableHead>
                                                </TableCell>
                                                <TableCell width="112px">
                                                    <TableHead>Начало</TableHead>
                                                </TableCell>
                                                <TableCell width="106px">
                                                    <TableHead>Окончание</TableHead>
                                                </TableCell>
                                                {agreementOnRevision && (
                                                    <TableCell width="30px" colspan={1}>
                                                        <TableHead></TableHead>
                                                    </TableCell>
                                                )}
                                            </TableString>
                                        ))}
                                    {Object.keys(vir.work_objects || []).length > 0 ? (
                                        Object.values(vir.work_objects || []).map((workObject) => (
                                            <VirWork
                                                touchedConstructor={touchedConstructor}
                                                key={workObject?.object_id}
                                                virId={vir?.id}
                                                workObject={workObject}
                                            />
                                        ))
                                    ) : (
                                        <TableString>
                                            <TableCell>
                                                <NotFound height="292px">
                                                    <NotFoundTitle>
                                                        Объекты не добавлены
                                                    </NotFoundTitle>
                                                    <NotFoundCaption>
                                                        Чтобы включить объекты в ведомость, нажмите
                                                        на кнопку «Добавить объекты»
                                                    </NotFoundCaption>
                                                </NotFound>
                                            </TableCell>
                                        </TableString>
                                    )}

                                    {vir?.work_objects &&
                                        Object.keys(vir.work_objects)?.length > 0 && (
                                            <Summary
                                                agreementOnRevision={agreementOnRevision}
                                                sums={{
                                                    sum: works?.[openedWork]?.sum,
                                                    sum_tax: works?.[openedWork]?.sum_tax,
                                                    total_sum: works?.[openedWork]?.total_sum,
                                                }}
                                            />
                                        )}
                                </tbody>
                            </Table>
                        </S.StyledAccordionItem>
                    );
                })}
            </Accordion>
        </>
    );
}
