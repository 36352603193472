import {AdditionalAgreementFull} from '../types';
import {EAdditionalAgreementType} from 'shared/types/additionalAgreementsTypes';
import {formatDate} from 'shared/helpers/formatHelper';

export const getAgreementTitle = (
    agreement: AdditionalAgreementFull | null,
    agreementType: EAdditionalAgreementType | null,
) => {
    const titleStart = () => {
        switch (agreementType) {
            case EAdditionalAgreementType.Agreement: {
                return `ДС ${agreement?.agreement_number ?? ''}`;
            }
            case EAdditionalAgreementType.Addition: {
                return `Дополнение № ${agreement?.number_addendum ?? '—'} к ДС ${
                    agreement?.agreement_number || ''
                }`;
            }
            case EAdditionalAgreementType.Termination: {
                return `Расторжение ДС ${agreement?.agreement_number ?? ''}`;
            }
            default:
                return '';
        }
    };
    const contractorSubstring = agreement?.gpo_short_name ? ` — ${agreement.gpo_short_name}` : '';
    const date = agreement?.signing_date ? ` от ${formatDate(agreement.signing_date)}` : '';
    return `${titleStart()}${
        agreementType === EAdditionalAgreementType.Termination ? date : contractorSubstring
    }`;
};

export const getModalTitle = (agreementType: EAdditionalAgreementType | null) => {
    const getTypeText = () => {
        switch (agreementType) {
            case EAdditionalAgreementType.Agreement: {
                return `ДС`;
            }
            case EAdditionalAgreementType.Addition: {
                return `дополнение к ДС`;
            }
            case EAdditionalAgreementType.Termination: {
                return `расторжение ДС`;
            }
            default: {
                return ``;
            }
        }
    };
    return `Вы уверены, что хотите согласовать ${getTypeText()}?`;
};
