import styled, {css} from 'styled-components/macro';
import {Typography} from '@beeline/design-system-react';

export const CustomTypography = styled(Typography)<{margin: string; display?: string}>`
    ${(props) => css`
        margin: ${props.margin};
    `}
    ${(props) =>
        props.display &&
        css`
            display: ${props.display};
        `}
`;

export const AttachmentsBlock = styled.div`
    max-width: 1376px;
    display: flex;
    gap: 24px;
`;

export const FilesBlock = styled.div`
    flex: 1 1 627px;
`;

export const CommentsBlock = styled.div`
    flex: 1 1 627px;
`;

export const TextBlock = styled.div`
    margin: 36px 0;
`;

export const InfoGridWrapper = styled.div`
    display: grid;
    gap: var(--size-spacing-x6);
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
        'address address creationDate approvingDate'
        'purchase lot jobType deadline'
        'contract contract contract contract';
`;