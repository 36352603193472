import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Button, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {EmptyTab} from 'Entities/EmptyTab';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {$userActions} from 'shared/model/user';
import {EAccessActions} from 'shared/const/actions';

import * as S from './style';
import {CompletedWorksTable} from './CompletedWorksTable';
import {columns, innerColumns} from './allColumns';
import {$completedWorksTabStore, CompletedWorksTabGate} from '../../../model/completedWorksTab';
import {
    COMPLETED_WORKS_CREATION_BUTTON,
    COMPLETED_WORKS_TAB_EMPTY_SUBTITLE,
    COMPLETED_WORKS_TAB_EMPTY_TITLE,
    COMPLETED_WORKS_TAB_TITLE,
} from '../../../constants';

interface CompletedWorksTabProps {
    projectId: string;
}

export const CompletedWorksTab = ({projectId}: CompletedWorksTabProps) => {
    useGate(CompletedWorksTabGate, projectId);
    const navigate = useNavigate();
    const userActions = useStore($userActions);
    const {isEmpty} = useStore($completedWorksTabStore);

    const creationAllowed = userHasPermission(EAccessActions.CompletedWorksCreation, userActions);

    const handleNavigateToCompletedWorkCreation = () =>
        navigate(`/projects/${projectId}/completed-works/creation`);

    return (
        <S.OverallWrapper>
            <Typography variant="h5">{COMPLETED_WORKS_TAB_TITLE}</Typography>
            {!isEmpty ? (
                <>
                    {creationAllowed && (
                        <Button
                            variant="outlined"
                            size="medium"
                            onClick={handleNavigateToCompletedWorkCreation}
                        >
                            {COMPLETED_WORKS_CREATION_BUTTON}
                        </Button>
                    )}
                    <CompletedWorksTable columns={columns} innerColumns={innerColumns} />
                </>
            ) : (
                <EmptyTab
                    iconName={Icons.PageEmpty}
                    showButton={creationAllowed}
                    color="blue"
                    title={COMPLETED_WORKS_TAB_EMPTY_TITLE}
                    subtitle={COMPLETED_WORKS_TAB_EMPTY_SUBTITLE}
                    buttonTitle={COMPLETED_WORKS_CREATION_BUTTON}
                    onClick={handleNavigateToCompletedWorkCreation}
                />
            )}
        </S.OverallWrapper>
    );
};
