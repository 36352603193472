import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Counter, Icon, IconButton, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {$headerStore, headerGate} from 'components/Header/model';
import {navigationInvoked} from 'app/providers/AppNavigator/model';

import {NotificationBlock} from 'Features/NotificationBlock';
import {HeaderBlock, LogoBlock, BtnBlock, Logo, Notification} from './style';

export {Logo};

function Header() {
    useGate(headerGate);
    const {tasksData, taskCount, allTasksAreLoaded} = useStore($headerStore);

    const [notificationBlockIsOpen, setNotificationBlockIsOpen] = useState<boolean>(false);

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        navigationInvoked({to: '/login'});
    };

    const toggleNotificationBlock = () => {
        setNotificationBlockIsOpen((prevState) => !prevState);
    };

    const closeNotificationBlock = () => {
        setNotificationBlockIsOpen(false);
    };

    return (
        <HeaderBlock>
            <LogoBlock>
                <Typography className="inactiveText" variant="productName">
                    dmc
                </Typography>
                <Link to="/">
                    <Logo>
                        <img src="/img/logo.svg" alt="Logo" />
                    </Logo>
                </Link>
                {/*<InvisibleTypography variant="body2">
                    {process.env.REACT_APP_VERSION}
                </InvisibleTypography>*/}
            </LogoBlock>
            <BtnBlock>
                <Notification>
                    <Counter count={taskCount} className={`${taskCount === 0 && 'emptyCounter'}`}>
                        <Icon
                            className="cursor-pointer"
                            iconName={Icons.NotificationNew}
                            size="large"
                            onClick={toggleNotificationBlock}
                        />
                    </Counter>
                    <NotificationBlock
                        isOpen={notificationBlockIsOpen}
                        taskData={tasksData}
                        onClose={closeNotificationBlock}
                        allTasksAreLoaded={allTasksAreLoaded}
                    />
                </Notification>
                <IconButton iconName={Icons.User} onClick={handleLogout} size="large" />
            </BtnBlock>
        </HeaderBlock>
    );
}

export default Header;
