import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';
import {getUserCertificates} from 'crypto-pro';
import {Certificate} from 'crypto-pro/lib/api/certificate';

import {snackbarNotificationChanged} from 'Entities/SnackbarNotification/model';
import {isDateRangeValid} from 'shared/helpers/formatHelper';


const GroupCertificateSignatureDomain = createDomain();
export const GroupCertificateSignatureGate = createGate();

// effects

const getCertificatesFx = GroupCertificateSignatureDomain.createEffect(async () => {
    try {
        return await getUserCertificates(true);
    } catch (e) {
        snackbarNotificationChanged(e.message);
        return [] as Certificate[];
    }
});
// events
export const certificateSelected = GroupCertificateSignatureDomain.createEvent<string>();
// stores

const $certificateList = GroupCertificateSignatureDomain.createStore<Certificate[]>([]);
const $selectedCertificateId = GroupCertificateSignatureDomain.createStore('');
const $signBtnDisabled = GroupCertificateSignatureDomain.createStore(true);

export const $groupCertificateSignatureStore = combine({

    selectedCertificateId: $selectedCertificateId,
    certificateList: $certificateList,
    signBtnDisabled: $signBtnDisabled,
    certificateListIsLoading: getCertificatesFx.pending,
});

sample({
    clock: GroupCertificateSignatureGate.open,
    target: getCertificatesFx,
});

sample({
    clock: getCertificatesFx.doneData,
    fn: (certificateList) =>
        certificateList.filter(
            (certificate) =>
                certificate.subjectName.includes('СНИЛС=') &&
                certificate.subjectName.includes('ИНН='),
        ),
    target: $certificateList,
});

// автовыбор радио, когда доступен только 1 сертификат
sample({
    source: $certificateList,
    filter: (source) =>
        source.length === 1 && isDateRangeValid(source[0].validFrom, source[0].validTo),
    fn: (source) => source[0].thumbprint,
    target: certificateSelected,
});

sample({
    clock: certificateSelected,
    target: $selectedCertificateId,
});

sample({
    clock: $selectedCertificateId,
    fn: (selectedId) => selectedId === '',
    target: $signBtnDisabled,
});

