import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Progress, Typography} from '@beeline/design-system-react';

import {$tokensHaveReceived, mobileIdEntranceGate} from 'widgets/MobileIdEntrance/model';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {resetLoginPageDomain} from 'pages/NewLoginPage/model';

import * as S from 'pages/NewLoginPage/ui/style';

export const MobileIdEntrance = () => {
    useGate(mobileIdEntranceGate);
    const tokensHaveReceived = useStore($tokensHaveReceived);
    const [timeLeft, setTimeLeft] = useState(120);
    const navigate = useNavigate();

    if (tokensHaveReceived !== null) {
        if (tokensHaveReceived) {
            navigate('/');
            resetLoginPageDomain();
        } else {
            resetLoginPageDomain();
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (timeLeft > 0) {
                setTimeLeft((prevState) => prevState - 1);
            } else {
                clearTimeout(timer);
            }
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <WrapperFlex flexDirection="column" alignItems="center">
            <WrapperOffset offsetBottom="16">
                <Typography variant="h5">Подтверждение входа</Typography>
            </WrapperOffset>
            <WrapperOffset offsetBottom="32">
                <S.AlignedText>
                    <Typography variant="body1">
                        Сейчас на экране вашего смартфона отобразится запрос на подтверждение входа
                        или придет SMS
                    </Typography>
                </S.AlignedText>
            </WrapperOffset>
            <WrapperOffset width="100%" offsetBottom="16">
                <Progress strokeWidth={4} value={timeLeft / 1.2} />
            </WrapperOffset>
            <Typography variant="caption">{`Запросить подтверждение повторно можно через ${timeLeft} сек.`}</Typography>
        </WrapperFlex>
    );
};
