import React from 'react';
import {useGate} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import {CommonLayout} from 'shared/ui/CommonLayout';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';

import {ActionPanel} from './ActionPanel';
import {AdditionalAgreementsTable} from './AgreementsTable';
import {columns, innerColumns} from './allColumns';
import {AdditionalAgreementsGate} from '../model';

export const AdditionalAgreementsPage = () => {
    useGate(AdditionalAgreementsGate);

    return (
        <CommonLayout>
            <WrapperOffset offset={32}>
                <Typography variant="h4" className="mb-4">
                    Дополнительные соглашения
                </Typography>
                <ActionPanel />
                <AdditionalAgreementsTable
                    columns={columns}
                    innerColumns={innerColumns}
                    withPagination
                />
            </WrapperOffset>
        </CommonLayout>
    );
};
