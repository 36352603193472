import {EMPTY_DATA_OBJ} from '../constants';

import type {Action, IFileUploaderState, TFile} from '../useFileUploader.types';
import {EActionType} from './constants';

export const fileUploaderReducer = (
    state: IFileUploaderState,
    action: Action,
): IFileUploaderState => {
    switch (action.type) {
        case EActionType.AddFiles: {
            const {files: newFiles} = (action.payload as IFileUploaderState) || EMPTY_DATA_OBJ;
            const {files: currentStateFiles} = state;

            return {...state, files: {...currentStateFiles, ...newFiles}};
        }
        case EActionType.ChangeFile: {
            const {oldFile, newFile} = action.payload;
            const files = {...state.files};
            delete files[oldFile.name];
            return {...state, files: {...files, [newFile.name]: newFile}};
        }
        case EActionType.UpdateFile: {
            const newFileData = action.payload as TFile;
            const {name} = newFileData;
            const {files: currentStateFiles} = state;
            const fileForUpdate = currentStateFiles[name];

            if (!fileForUpdate) return state;

            return {
                ...state,
                files: {...currentStateFiles, [name]: {...fileForUpdate, ...newFileData}},
            };
        }

        case EActionType.DeleteFile: {
            const fileName = action.payload;
            const files = {...state.files};

            delete files[fileName];

            return {...state, files};
        }

        case EActionType.SetError: {
            const errorMessage = action.payload;

            return {...state, error: errorMessage};
        }

        case EActionType.ResetError: {
            return {...state, error: undefined};
        }

        case EActionType.ResetState: {
            return {files: EMPTY_DATA_OBJ, error: undefined};
        }
        case EActionType.AttachDate: {
            const fileName = action.payload.file.name;
            const matchedFile = {...state.files[fileName]};
            matchedFile.registration_at = action.payload.date !== '' ? action.payload.date : null;
            const result = {...state};
            result.files[fileName] = matchedFile;
            return result;
        }
    }
};
