import React from 'react';
import {useGate, useStore} from 'effector-react';
import {IconButton, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {CertificateRadioGroup} from '../../CertificateSignature/ui/CertificateRadioGroup';
import {ActionButtonsUnit} from '../../ActionButtonsUnit';
import {
    $groupCertificateSignatureStore,
    certificateSelected,
    GroupCertificateSignatureGate,
} from '../model';

interface GroupCertificateSignatureProps {
    onClose: () => void;
    onSign: (signature: string) => void;
}

export const GroupCertificateSignature = ({onClose, onSign}: GroupCertificateSignatureProps) => {
    useGate(GroupCertificateSignatureGate);
    const {certificateList, selectedCertificateId, certificateListIsLoading, signBtnDisabled} =
        useStore($groupCertificateSignatureStore);
    return (
        <WrapperFlex flexDirection="column" margin="0 auto" gap="32px" width="628px">
            <WrapperFlex alignItems="center" gap="16px">
                <IconButton iconName={Icons.ArrowLeft} size="medium" onClick={() => onClose()} />
                <Typography variant="h4">Подписать доп. соглашение</Typography>
            </WrapperFlex>
            <Typography variant="body2">Выберите электронный сертификат для подписи</Typography>
            <CertificateRadioGroup
                certificateList={certificateList}
                selectedCertificateId={selectedCertificateId}
                handleClick={certificateSelected}
                isLoading={certificateListIsLoading}
            />
            <ActionButtonsUnit
                negativeBtnLabel="Отменить"
                positiveBtnLabel="Подписать"
                handleNegativeBtn={() => onClose()}
                handlePositiveBtn={() => onSign(selectedCertificateId)}
                alignSelf="flex-end"
                disabledPositiveBtn={signBtnDisabled}
            />
        </WrapperFlex>
    );
};
