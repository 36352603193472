import styled from 'styled-components/macro';
import {
    Checkbox,
    Divider,
    Icon,
    Table,
    TableData,
    TableHeaderData,
    TableRow,
    Typography,
} from '@beeline/design-system-react';
import {css} from 'styled-components';

export const CommonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const SheetWrapper = styled.div`
    border: 1px solid var(--light-border-default, var(--color-border));
    border-radius: 12px;
`;
export const SheetTitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 22px;
    gap: 15px;
`;
export const SelectWrapper = styled.div`
    flex: 0 0 412px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
`;
export const TableWrapper = styled.div`
    margin: 12px 48px;
`;
export const CheckboxWrapper = styled.div`
    flex: 0 0 437px;
`;
export const StyledCheckbox = styled(Checkbox)`
    margin-left: 24px;
`;
export const CheckboxLabelWrapper = styled.div`
    margin-left: 16px;
`;
export const StyledTypography = styled(Typography)<{$disabled?: boolean}>`
    margin-left: 64px;
    ${(p) =>
        p.$disabled &&
        css`
            color: var(--color-text-disabled);
        `}
`;
export const StyledDivider = styled(Divider)`
    margin: 12px 0;
`;

export const StyledTable = styled(Table)`
    border: none;
`;

export const StyledIcon = styled(Icon)`
    color: orange;
`;

export const WorkTypeSelectWrapper = styled.div`
    flex: 0 0 373px;
`;

export const StyledTableData = styled(TableData)<{
    width?: string;
    height?: string;
    $disabled?: boolean;
}>`
    .dsb_table-cell_flex {
        width: 100%;

        .dsb_table-cell__content {
            width: 100%;
        }
    }

    ${({width}) =>
        width &&
        css`
            max-width: ${width};
            min-width: ${width};
        `}
    ${({height}) =>
        height &&
        css`
            max-height: ${height};
            min-height: ${height};
        `}
  ${({$disabled}) =>
        $disabled &&
        css`
            && span,
            p {
                color: var(--color-text-disabled);
            }
        `}
`;

export const StyledTableHeaderData = styled(TableHeaderData)<{$disabled?: boolean}>`
    ${(p) =>
        p.$disabled &&
        css`
            color: var(--color-text-disabled);
        `}
`;

export const StyledTableRow = styled(TableRow)<{$disabled?: boolean}>`
    ${(p) =>
        p.$disabled &&
        css`
            color: var(--color-text-disabled);
        `}
`;
