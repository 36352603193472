import {forwardPayload} from 'shared/helpers/effector';
import {combine, sample} from 'effector';
import {
    $tpiToConfigCoefficient,
    loadCoefficientsFx,
    setActiveTab,
} from 'pages/ApplyCoefficientsPage/model';
import {
    $allIncreasingCoefficients,
    $filteredIncreasingCoefficients,
    $increasingSearchTerm,
    $selectedIncreasingCoefficients,
    filterIncreasingBySearchTerm,
    setIncreasingSearchTerm,
    toggleAllIncreasingSelect,
    toggleIncreasingSelect,
} from './index';

// fill with loaded data
sample({
    clock: loadCoefficientsFx.doneData,
    fn: ({data}) => data?.[0]?.increasing_coefficients || [],
    target: [$allIncreasingCoefficients, $filteredIncreasingCoefficients],
});

// filter
$increasingSearchTerm.on(setIncreasingSearchTerm, forwardPayload()).reset(setActiveTab);

sample({
    clock: filterIncreasingBySearchTerm,
    source: combine({searchTerm: $increasingSearchTerm, coefficients: $allIncreasingCoefficients}),
    fn: ({searchTerm, coefficients}) => {
        const regexp = new RegExp(searchTerm, 'i');
        const searchByNumber = /[\.*\d*]/.test(searchTerm);
        return coefficients.filter((el) => {
            const testName = regexp.test(el.name);
            const testNumber = regexp.test(el.number);
            const testNote = el?.note && regexp.test(el.note);
            return searchTerm
                ? searchByNumber
                    ? testNumber
                    : el?.note
                    ? testName || testNote
                    : testName
                : el;
        });
    },
    target: $filteredIncreasingCoefficients,
});

// select

sample({
    clock: toggleAllIncreasingSelect,
    source: combine({
        selected: $selectedIncreasingCoefficients,
        all: $allIncreasingCoefficients,
    }),
    fn: ({all, selected}) => (selected.length === 0 ? all : []),
    target: $selectedIncreasingCoefficients,
});

$selectedIncreasingCoefficients.on(toggleIncreasingSelect, (state, payload) =>
    state.some((el) => el.id === payload.id)
        ? state.filter((el) => el.id !== payload.id)
        : [...state, payload],
);

// selectedCoefficients from constructor
sample({
    clock: loadCoefficientsFx.doneData,
    source: combine({
        tpiFromConstructor: $tpiToConfigCoefficient,
        allIncreasingCoefficients: $allIncreasingCoefficients,
    }),
    fn: ({tpiFromConstructor, allIncreasingCoefficients}) => {
        if (!tpiFromConstructor?.spec?.work_coefficients?.length) {
            return [];
        }
        const increasingCoefficientIds = tpiFromConstructor?.spec?.work_coefficients?.map(
            (el) => el.tpi_specification ?? el.id,
        );
        return allIncreasingCoefficients.filter((el) => increasingCoefficientIds.includes(el.id));
    },
    target: $selectedIncreasingCoefficients,
});
