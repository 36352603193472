import React, {Fragment, useRef} from 'react';

import {Button, Divider, Progress, Typography} from '@beeline/design-system-react';

import {fetchTasks} from 'components/Header/model';
import {useOutsideClick} from 'shared/hooks/useOutsideClick';
import {useIntersectionObserver} from 'shared/hooks/useIntersectionObserver';
import {NonMvpItem} from 'shared/styles/commonStyle';
import {TTaskList} from 'shared/types/tasksTypes';
// @ts-ignore
import EmptyLogo from 'shared/img/empty-image.png';

import {NotificationItem} from './NotificationItem';
import * as S from './style';

export interface NotificationBlockProps {
    isOpen: boolean;
    taskData: TTaskList;
    onClose: () => void;
    allTasksAreLoaded: boolean;
}

export const NotificationBlock = ({
    isOpen,
    taskData,
    onClose,
    allTasksAreLoaded,
}: NotificationBlockProps) => {
    const ref = useOutsideClick<HTMLDivElement>(onClose);
    const observableRef = useRef<HTMLDivElement>(null);
    const entry = useIntersectionObserver(observableRef, {threshold: 0.1});
    const isVisible = !!entry?.isIntersecting;

    if (!allTasksAreLoaded && isVisible) {
        fetchTasks();
    }

    return (
        <S.NotificationBlock ref={ref} isOpen={isOpen}>
            <S.NotificationList className="scrollbar-vertical">
                {taskData.length > 0 ? (
                    <>
                        {taskData.map((item, index) => (
                            <Fragment key={item.id}>
                                <NotificationItem
                                    index={index}
                                    taskTitle={item.name}
                                    taskObjectId={item.object_id}
                                    taskObjectType={item.object_type}
                                    taskDate={item.created_at}
                                    taskStatus={item.status}
                                    taskLink={item.object_number}
                                />
                                <Divider />
                            </Fragment>
                        ))}
                        <S.RefDiv ref={observableRef}></S.RefDiv>
                        {!allTasksAreLoaded && isVisible && (
                            <S.LoadingDiv>
                                <Progress
                                    cycled
                                    shape="circle"
                                    size={64}
                                    strokeWidth={4}
                                    type="solo"
                                    value={75}
                                />
                            </S.LoadingDiv>
                        )}
                    </>
                ) : (
                    <S.EmptyNotificationBlock>
                        <img src={EmptyLogo} alt="Уведомлений нет" />
                        <Typography variant="subtitle3">Список уведомлений пуст</Typography>
                        <S.TextWrapper>
                            <Typography className="pt-1 px-auto" variant="body3">
                                Здесь будет отображаться список ваших уведомлений
                            </Typography>
                        </S.TextWrapper>
                    </S.EmptyNotificationBlock>
                )}
            </S.NotificationList>
            <Divider />
            <NonMvpItem>
                <S.ReadAllBlock>
                    <Button variant="plain" size="small">
                        Прочитать все
                    </Button>
                </S.ReadAllBlock>
            </NonMvpItem>
        </S.NotificationBlock>
    );
};
