import {bindAllMethods} from 'shared/helpers/binder';
import {
    CatalogContractResponse,
    IProjectCatalog,
    CatalogTPIResponse,
    CatalogWorkObject,
    GetCatalogTPIRequest,
    GetWorkObjectsRequest,
    WorkTypeResponse,
} from 'pages/VirStart/types';
import {axiosInstance} from 'shared/api/api';

export const catalogService = bindAllMethods({
    async getCatalogProjects(): Promise<IProjectCatalog[]> {
        return axiosInstance.get('/catalogs/projects');
    },

    async getCatalogWorkObjects(params: GetWorkObjectsRequest): Promise<{data: CatalogWorkObject}> {
        return axiosInstance.get(`/projects/projects/${params.project_id}/work_objects`, {params});
    },

    async getCatalogWorkTypes(): Promise<{data: WorkTypeResponse[]}> {
        return axiosInstance.get('/catalogs/work_types');
    },

    async getCatalogContracts(): Promise<{data: {results: CatalogContractResponse[]}}> {
        return axiosInstance.get('/contracts/contracts_list/');
    },

    async getCatalogTpis(
        params: GetCatalogTPIRequest,
    ): Promise<{data: {results: CatalogTPIResponse[]}}> {
        return axiosInstance.get('/contracts/tpis/', {params});
    },
});
