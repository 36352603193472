import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useStore} from 'effector-react';
import {Button, Icon, Switch} from '@beeline/design-system-react';

import {Icons} from '@beeline/design-tokens/js/iconfont';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {NonMvpItem} from 'shared/styles/commonStyle';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions} from 'shared/const/actions';
import {$userActions} from 'shared/model/user';

import * as S from './style';
import {
    $ProcessesPageStore,
    signButtonClicked,
    toggleCompletedTaskSwitcher,
    toggleExecutorTaskSwitcher,
    toggleGroupTableByProcesses,
    toggleToSignTaskSwitcher,
    toggleUserTaskSwitcher,
} from '../../../model';

export const SwitchUnit = () => {
    const {
        taskParams: {
            filters: {groupByProcesses, executor_tasks, uncompleted_tasks, signed},
        },
        signedButtonDisabled,
    } = useStore($ProcessesPageStore);
    const userActions = useStore($userActions);
    const navigate = useNavigate();
    const showToSignSwitcher = userHasPermission(EAccessActions.DocumentSigning, userActions);
    const handleGroupSwitcher = (e) => {
        toggleGroupTableByProcesses(e.target.checked);
    };
    const handleCompletedSwitcher = (e) => {
        toggleCompletedTaskSwitcher(e.target.checked);
    };
    const handleToSignSwitcher = (e) => {
        toggleToSignTaskSwitcher(e.target.checked);
    };
    const handleExecutorSwitcher = (e) => {
        toggleExecutorTaskSwitcher(e.target.checked);
    };
    const handleUserSwitcher = (e) => {
        toggleUserTaskSwitcher(e.target.checked);
    };

    const handleClickSignButton = () => {
        signButtonClicked(() => navigate('signing'));
    };
    const switchesDisabled = signed ?? false;
    return (
        <WrapperFlex gap="24px" mt="24px" flexWrap="wrap" alignItems="center">
            <Switch
                label="Сгрупировать по процессам"
                onChange={handleGroupSwitcher}
                onClick={function noRefCheck() {}}
                checked={groupByProcesses}
                disabled={switchesDisabled}
            />
            <Switch
                label="Я исполнитель"
                onChange={handleExecutorSwitcher}
                onClick={function noRefCheck() {}}
                checked={executor_tasks}
                disabled={switchesDisabled}
            />
            <NonMvpItem>
                <Switch
                    label="Я инициатор"
                    onChange={handleUserSwitcher}
                    onClick={function noRefCheck() {}}
                    disabled={switchesDisabled}
                />
            </NonMvpItem>

            <Switch
                label="Только незавершенные"
                onChange={handleCompletedSwitcher}
                onClick={function noRefCheck() {}}
                checked={uncompleted_tasks}
                disabled={switchesDisabled}
            />
            {showToSignSwitcher && (
                <Switch
                    label="На подпись"
                    onChange={handleToSignSwitcher}
                    onClick={function noRefCheck() {}}
                    checked={signed ?? false}
                />
            )}
            <S.ButtonWrapper>
                {showToSignSwitcher && signed && (
                    <Button
                        variant="plain"
                        startIcon={<Icon iconName={Icons.Signature} />}
                        onClick={handleClickSignButton}
                        disabled={signedButtonDisabled}
                    >
                        Подписать
                    </Button>
                )}
            </S.ButtonWrapper>
        </WrapperFlex>
    );
};
