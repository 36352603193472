import React, {FC, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useStore} from 'effector-react';
import {NavigationDrawer} from '@beeline/design-system-react';

import {navigationInvoked} from 'app/providers/AppNavigator/model';
import {BodyContent, MainContent, MainWrapper} from 'components/PageWraps';
import Header from 'components/Header/Header';
import {$userActions} from 'shared/model/user';

import {getBottomGroups, getUpperGroups} from '../helpers/navigationDrawerGroups';

type CommonLayoutProps = {
    children: React.ReactNode;
    hasSideBar?: boolean;
};

export const CommonLayout: FC<CommonLayoutProps> = ({children, hasSideBar = true}) => {
    const userActions = useStore($userActions);
    const location = useLocation();
    const [activeRoute, setActiveRoute] = useState(location.pathname);
    const handleClickItem = (path: string) => {
        setActiveRoute(path);
        navigationInvoked({to: path});
    };

    return (
        <MainWrapper>
            <Header />
            <BodyContent>
                {hasSideBar && (
                    <NavigationDrawer
                        className="navigation-drawer"
                        onClickItem={handleClickItem}
                        groups={getUpperGroups(userActions)}
                        bottom={getBottomGroups(userActions)}
                        active={activeRoute}
                        isGroupTitle={false}
                        isGroupDivider={false}
                        isOpen={false}
                    />
                )}
                <MainContent id="projectPageId">{children}</MainContent>
            </BodyContent>
        </MainWrapper>
    );
};
