import React from 'react';
import {useStore} from 'effector-react';

import {Typography} from '@beeline/design-system-react';
import {formatCurrency} from 'shared/helpers/formatHelper';
import {
    AGREEMENT_SUM_TITLE,
    AGREEMENT_TOTAL_SUM_TITLE,
    AGREEMENT_TOTAL_SUM_WITHOUT_TAX_TITLE,
    NO_TAX_TITLE,
    TAX_TITLE,
} from 'shared/constants';

import * as S from './style';
import {$currentWidth} from '../../model';
import {AdditionalAgreementFull} from '../../types';

interface TotalSumUnitProps {
    agreement: AdditionalAgreementFull | null;
}

export const TotalSumUnit = ({agreement}: TotalSumUnitProps) => {
    const width = useStore($currentWidth);
    if (!agreement) return null;
    const {sum, sum_tax, total_sum} = agreement;
    const hasVAT = +sum_tax > 0;
    return (
        <S.Wrapper>
            <S.RowWrapper width={width}>
                <S.TitleCell>
                    <Typography variant="subtitle3">{AGREEMENT_SUM_TITLE}</Typography>
                </S.TitleCell>
                <S.SumCell>
                    <Typography variant="subtitle3">{formatCurrency(sum)}</Typography>
                </S.SumCell>
            </S.RowWrapper>
            <S.RowWrapper width={width}>
                <S.TitleCell>
                    <Typography variant="subtitle3">{hasVAT ? TAX_TITLE : NO_TAX_TITLE}</Typography>
                </S.TitleCell>
                <S.SumCell>
                    {hasVAT && (
                        <Typography variant="subtitle3">{formatCurrency(sum_tax)}</Typography>
                    )}
                </S.SumCell>
            </S.RowWrapper>
            <S.RowWrapper width={width}>
                <S.TitleCell>
                    <Typography variant="subtitle3">
                        {hasVAT ? AGREEMENT_TOTAL_SUM_TITLE : AGREEMENT_TOTAL_SUM_WITHOUT_TAX_TITLE}
                    </Typography>
                </S.TitleCell>
                <S.SumCell>
                    <Typography variant="subtitle3">{formatCurrency(total_sum)}</Typography>
                </S.SumCell>
            </S.RowWrapper>
        </S.Wrapper>
    );
};
