import React from 'react';
import {useStore} from 'effector-react';
import {Button, Counter, Icon, Select} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {MultiSearch} from 'Features/MultiSearch';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

import {SwitchUnit} from './SwitchUnit/SwitchUnit';
import * as S from '../style';
import {
    $ProcessesPageStore,
    $processesTerritorialStore,
    changeSearchValue,
    setSelectedBranchData,
    setSelectedRegionData,
} from '../../model';
import {FunctionWrap} from '../../../ProjectPage/style';

interface FilterUnitProps {
    toggleFilter: () => void;
}

export const FilterUnit = ({toggleFilter}: FilterUnitProps) => {
    const {appliedFiltersCount} = useStore($ProcessesPageStore);
    const {regionData, branchData, selectedRegionData, selectedBranchData} = useStore(
        $processesTerritorialStore,
    );
    const downloadBtnDisabled = true;

    return (
        <>
            <WrapperFlex justifyContent="space-between" mt="24px" gap="16px">
                <S.SelectWrap>
                    <Select
                        id="RegionSelect"
                        label="Регион"
                        onChange={setSelectedRegionData}
                        options={regionData}
                        values={selectedRegionData}
                        dropdownClassName="whiteSpace ds-theme--light"
                        multiple
                        fullWidth
                        filter
                    />
                    <Select
                        id="BranchSelect"
                        label="Филиал"
                        onChange={setSelectedBranchData}
                        options={branchData}
                        values={selectedBranchData}
                        dropdownClassName="whiteSpace ds-theme--light"
                        multiple
                        fullWidth
                        filter
                    />
                </S.SelectWrap>
                <FunctionWrap>
                    <Counter
                        count={appliedFiltersCount}
                        className={`${appliedFiltersCount === 0 && 'emptyCounter'}`}
                    >
                        <Button
                            startIcon={<Icon iconName={Icons.Tune} />}
                            variant="outlined"
                            size="medium"
                            onClick={toggleFilter}
                        />
                    </Counter>
                    <Button
                        startIcon={<Icon iconName={Icons.Download} />}
                        color="outline"
                        size="medium"
                        //onClick={downloadProjectList}
                        disabled={downloadBtnDisabled}
                    />
                    <MultiSearch handleSearchValue={changeSearchValue} />
                </FunctionWrap>
            </WrapperFlex>
            <SwitchUnit />
        </>
    );
};
