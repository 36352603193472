import styled, {css} from 'styled-components/macro';
import Button from 'shared/ui/deprecated/Button/Button';

export const PopoverElement = styled.div`
    position: relative;
    display: block;
    width: fit-content;
`;

export const PopoverMixin = (props) => css`
     {
        // display: ${props.open ? 'block' : 'none'};
        display: block;
        font-size: 15px;
        letter-spacing: 0.2px;
        min-width: 348px;
        max-width: 480px;
        background-color: #141414;
        color: #fff;
        border-radius: 8px;
        white-space: pre-wrap;
        padding: ${props.noButtons ? '16px' : '16px 16px 8px 16px'};
        // left: 50%;
        // position: absolute;
        // z-index: 2000;
        // ${props.position === 'top-start' &&
        css`
            //     bottom: calc(100% + 16px);
            //     left: 0;
            //     margin-left: 0 !important;
            //
        `}
        // ${props.position === 'top-end' &&
        css`
            //     bottom: calc(100% + 16px);
            //     left: auto;
            //     right: 0;
            //
        `}
        // ${props.position === 'bottom-start' &&
        css`
            //     top: calc(100% + 16px);
            //     left: 0 !important;
            //     margin-left: 0 !important;
            //
        `}
        // ${props.position === 'bottom-end' &&
        css`
            //     top: calc(100% + 16px);
            //     left: auto;
            //     right: 0;
            //
        `}

        ${PopoverElement}:hover & {
            display: ${props.showOnHover && 'block'};
        }
    }
`;

export const PopoverStyled = styled.span`
    ${(props) => PopoverMixin(props)}
`;

export const PopoverButtonStyled = styled(Button)`
    background: unset;
    color: rgba(92, 181, 255, 1) !important;
    border: none;
    font-size: 15px;
    height: 40px;
    margin-left: 8px;
    &:hover {
        background: unset;
    }
    &:focus {
        background: unset;
    }
    &:active:focus {
        background: rgba(42, 44, 57, 1);
    }
`;
