import {useState} from 'react';
import {Button, Skeleton} from '@beeline/design-system-react';

import {TableEmptyMessage} from 'Entities/TableEmptyMessage';
import {PrimeContractorsTableRow} from 'widgets/PrimeContractorsTable/ui/PrimeContractorsTableRow/PrimeContractorsTableRow';
import Pagination from 'shared/ui/Pagination/Pagination';
import {TPurchasingEventInfoForStage1} from 'shared/types/purchasesTypes';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {TTpiWithRemoteTerritory} from 'shared/types/tpiTypes';

import * as S from './style';

const primeContractorsTableHeader = [
    {
        content: <>Тип ЗМ</>,
        width: '102px',
    },
    {
        content: <>Номер ЗМ</>,
        width: '100px',
    },
    {
        content: <>Лот</>,
        width: '80px',
    },
    {
        content: <>ТЦП</>,
        width: '148px',
    },
    {
        content: <>Наименование ГПО</>,
        width: '210px',
    },
    {
        content: <>Статус</>,
        width: '114px',
    },
    {
        content: <>Договор</>,
        width: '202px',
    },
    {
        content: <>ФИО подписанта</>,
        width: '172px',
    },
    {
        content: <>Лимит, ₽</>,
        width: '148px',
        align: 'right',
    },
];

interface PrimeContractorsTableProps {
    data: Array<TPurchasingEventInfoForStage1> | null;
    dataIsLoading: boolean;
    selectedTpiData: Record<string, TTpiWithRemoteTerritory>;
    setSelectedTpiData: () => void;
    count: number;
    page: number;
    pageSize: number;
    setPage: () => void;
    setPageSize: () => void;
    handleTransitionSecondStage: (biddingId: string, tpiId: string) => void;
    buttonsDisabled: boolean;
    selectError: string | null;
}

export const PrimeContractorsTable = ({
    data,
    selectedTpiData,
    setSelectedTpiData,
    dataIsLoading,
    count,
    page,
    pageSize,
    setPage,
    setPageSize,
    handleTransitionSecondStage,
    buttonsDisabled,
    selectError,
}: PrimeContractorsTableProps) => {
    const [buttonsHaveDisabled, setButtonsHaveDisabled] = useState<boolean>(false);

    return (
        <S.TableContent isLoading={dataIsLoading}>
            {(data?.length ?? -1 > 0) || dataIsLoading ? (
                <>
                    {dataIsLoading ? (
                        <Skeleton height={630} width="100%" variant="line" />
                    ) : (
                        <S.TableWrapper>
                            <S.CustomTable>
                                <S.CustomTableRow noBorder>
                                    <S.CustomTableCell
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        flex="1 1 auto"
                                        noPadding
                                        noFlex
                                        scroll
                                    >
                                        <S.RowWrapper>
                                            <S.CustomTableRow>
                                                {primeContractorsTableHeader.map((head, idx) => (
                                                    <S.CustomTableCell
                                                        key={idx}
                                                        flex={`0 0 ${head.width}`}
                                                        alignItems={
                                                            head.align === 'right'
                                                                ? 'flex-end'
                                                                : undefined
                                                        }
                                                    >
                                                        {head.content}
                                                    </S.CustomTableCell>
                                                ))}
                                            </S.CustomTableRow>
                                        </S.RowWrapper>

                                        {data?.map((item, index) => {
                                            return (
                                                <PrimeContractorsTableRow
                                                    index={index + 1}
                                                    key={item.bidding_participation.id}
                                                    data={item}
                                                    selectedTpiData={selectedTpiData}
                                                    setSelectedTpiData={setSelectedTpiData}
                                                    disabled={buttonsHaveDisabled}
                                                    setDisabled={setButtonsHaveDisabled}
                                                    selectError={selectError}
                                                />
                                            );
                                        })}
                                    </S.CustomTableCell>
                                    <S.CustomTableCell
                                        flex="0 0 91px"
                                        justifyContent="flex-start"
                                        noPadding
                                    >
                                        <S.CustomTableRow minWidth="91px">
                                            <S.CustomTableCell></S.CustomTableCell>
                                        </S.CustomTableRow>
                                        {data?.map((item) => (
                                            <S.CustomTableRow
                                                justifyContent="flex-end"
                                                key={`${item.bidding_participation.id}_${item.bidding_participation.id}`}
                                            >
                                                <S.CustomTableCell flex="0 0 91px" noPadding>
                                                    <Button
                                                        variant="plain"
                                                        onClick={() =>
                                                            handleTransitionSecondStage(
                                                                item.bidding_participation.id,
                                                                item.bidding_participation.tpis[0]
                                                                    .id,
                                                            )
                                                        }
                                                        disabled={buttonsDisabled}
                                                    >
                                                        Выбрать
                                                    </Button>
                                                </S.CustomTableCell>
                                            </S.CustomTableRow>
                                        ))}
                                        <S.CustomTableRow height="15px" />
                                    </S.CustomTableCell>
                                </S.CustomTableRow>
                            </S.CustomTable>
                        </S.TableWrapper>
                    )}
                    {count ? (
                        <Pagination
                            className="my-3"
                            total={count}
                            page={page}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            setPage={setPage}
                        />
                    ) : (
                        <WrapperFlex justifyContent="flex-end">
                            <Skeleton className="mt-3" height={30} width={500} variant="line" />
                        </WrapperFlex>
                    )}
                </>
            ) : (
                <TableEmptyMessage />
            )}
        </S.TableContent>
    );
};
