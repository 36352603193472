import React from 'react';

import {Index, SelectItemCheckStyled, StyledCheckbox} from './style';

export const SettingItem = (props) => {
    const {children, value, index, checked = false, handleEdit = () => {}} = props;

    return (
        <SelectItemCheckStyled>
            <Index>{index + 1}</Index>
            <StyledCheckbox label={children} onChange={handleEdit} checked={checked} />
        </SelectItemCheckStyled>
    );
};
