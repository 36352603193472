import {useStore} from 'effector-react';
import styled from 'styled-components/macro';

import {$createAdditionalAgreementData} from 'processes/contructor/model/stores';
import {CatalogWorkObjectOption} from 'pages/VirStart/types';
import Select, {SelectItem} from 'shared/ui/Select/Select';

import {VirWork} from '../../../types';

export interface StatementSelectProps {
    options: VirWork[];
    selected: VirWork | null;
    onSelect: (option: VirWork) => void;
    error?: boolean;
    selectedWorkObject: CatalogWorkObjectOption;
}

export function StatementSelect({
    options,
    selected,
    onSelect,
    error,
    selectedWorkObject,
}: StatementSelectProps) {
    const lavParts = selectedWorkObject?.lavParts;
    const isLav = !!lavParts;
    const lavPartNames = isLav ? lavParts.map((el) => el.object_name) : [];
    const ds = useStore($createAdditionalAgreementData);

    const works = ds?.works;
    return (
        <SelectWrapper>
            <Select
                error={error}
                hint="Выберите, в какую ведомость добавить объект"
                value={selected || []}
                valueLabel="name"
                handleEdit={(e) => onSelect(e)}
                title="Ведомость"
            >
                {options?.map((item) => {
                    const existingWorkObjectNamesInConstructor = Object.values(
                        works?.[item.id || '']?.work_objects || [],
                    ).reduce<string[]>((acc, curr) => [...acc, curr?.object_name], []);
                    const preventCreatingVir = isLav
                        ? lavPartNames.every((lavPartName) =>
                              existingWorkObjectNamesInConstructor.includes(lavPartName),
                          )
                        : existingWorkObjectNamesInConstructor.includes(selectedWorkObject?.label);
                    return (
                        <SelectItem
                            selected={selected}
                            multiple={false}
                            key={item.id}
                            value={item}
                            disabled={preventCreatingVir}
                        >
                            <div>{item.name}</div>
                            {preventCreatingVir && ' Такой объект уже добавлен'}
                        </SelectItem>
                    );
                })}
            </Select>
        </SelectWrapper>
    );
}
export const SelectWrapper = styled.div`
    --selectWidth: 300px;
`;
