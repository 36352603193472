import {
    ConstructorVirType,
    ConstructorVirWorkObject,
    EWorkObjectTypes,
    IAgreementCopingWorksheet,
    ITemporaryAgreementWorkObject,
    ITemporaryAgreementWorkSpecification,
    ITemporaryCopingAgreement,
    IUpdatedAgreement,
    TTemporaryCopingWork,
} from 'shared/types/additionalAgreementsTypes';
import {
    CatalogWorkObjectOption,
    IContractCatalog,
    IProjectCatalog,
    ITpiCatalog,
} from '../../VirStart/types';
import {cloneDeep} from 'lodash';
import {IAgreementWorkObject} from '../../DSPage/types';
import {v4 as uuidv4} from 'uuid';
import {TpiSpecification} from '../../VirSelect/types';
import {calcSumForOneSpecification, calcTaxSum} from '../../../processes/contructor';
import {COEFFICIENT_TYPE_ENUM} from '../../ApplyCoefficientsPage/types';
import {formatNumber} from '../../../shared/helpers/formatHelper';
import {createAgreementWorkObjectName} from '../../../shared/helpers/additionalAgreements';

export const initialState: IUpdatedAgreement = {
    is_released: false,
    is_prepayment: false,
    agreement_id: '',
    project_id: '',
    contract: '',
    contract_id: '',
    tpi_id: '',
    begin_date: '',
    end_date: '',
    parent_signing_date: null,
    agreement_comments: [],
    agreement_attachments: [],
    works: {},
    meta: 'save_draft',
};

export const transformToTemporaryAgreementObject = (
    agreement: IAgreementCopingWorksheet,
    availableObjectList: Array<CatalogWorkObjectOption>,
    selectedTpi: ITpiCatalog,
    selectedContract: IContractCatalog,
    selectedProject: IProjectCatalog,
): ITemporaryCopingAgreement => {
    const startObject = cloneDeep(agreement);
    let isPrepayment = false;
    const result: ITemporaryCopingAgreement = {
        id: startObject.id,
        tpi: startObject.tpi,
        agreement_id: '',
        begin_date: '',
        end_date: '',
        meta: 'save_draft',
        is_released: false,
        is_prepayment: false,
        tpi_id: selectedTpi.tpis_id,
        remote_territory_id: selectedTpi.remote_territory_id,
        contract_id: selectedContract.framework_contract_id,
        contract_name: selectedContract.contract_number,
        project_id: selectedProject.project_id,
        project_number: selectedProject.project_number,
        agreement_attachments: [],
        agreement_comments: [],
        works: [
            ...startObject.works.map((sheet, index) => {
                const temporarySheet: TTemporaryCopingWork = {
                    id: sheet.id,
                    appendix_number: index,
                    sum: 0,
                    sum_tax: 0,
                    total_sum: 0,
                    work_objects: sheet.work_objects.map((workObject) => {
                        const disabled = !workObjectHasDestination(workObject, availableObjectList);
                        const temporaryWorkObject: ITemporaryAgreementWorkObject = {
                            ...workObject,
                            checked: !disabled,
                            disabled: disabled,
                            work_specifications: workObject.work_specifications.map(
                                (specification) => {
                                    if (specification.is_prepayment) isPrepayment = true;

                                    const currentSpecification =
                                        specification.tpi_specification_new;
                                    const specDisabled =
                                        disabled || !specification.tpi_specification;
                                    const specChecked = !specDisabled;
                                    const newSpecification = {
                                        ...currentSpecification,
                                        tpi_specification_id: currentSpecification.id,
                                        quantity: specification.quantity,
                                        sum: '0',
                                        sum_tax: '0',
                                        total_sum: '0',
                                        is_tax: currentSpecification.is_tax,
                                        work_coefficients: specification.work_coefficients_new,
                                        checked: specChecked,
                                        disabled: specDisabled,
                                        type_position: currentSpecification.type_position ?? 0,
                                    };
                                    newSpecification.coefficient =
                                        calcTotalCoefficient(newSpecification);
                                    return newSpecification;
                                },
                            ),
                        };
                        return temporaryWorkObject;
                    }),
                    checked: sheet.work_objects.some((workObject) =>
                        workObjectHasDestination(workObject, availableObjectList),
                    ),
                };
                return temporarySheet;
            }),
        ],
    };
    result.is_prepayment = isPrepayment;
    return result;
};

const workObjectHasDestination = (
    workObject: IAgreementWorkObject,
    destinationList: Array<CatalogWorkObjectOption>,
): boolean => {
    return destinationList.some(
        (destinationObject) => destinationObject.object_type === workObject.object_type,
    );
};

// изменяет тип работ
export const changeObjectWorkType = (
    agreement: ITemporaryCopingAgreement,
    data: {
        worksheetId: string;
        objectId: string;
        workTypeOption: {
            id: string;
            value: string;
        };
    },
): ITemporaryCopingAgreement => {
    const modifiedAgreement = cloneDeep(agreement);
    const workIndex = modifiedAgreement.works.findIndex(
        (worksheet) => worksheet.id === data.worksheetId,
    );
    if (workIndex >= 0) {
        const objectIndex = modifiedAgreement.works[workIndex].work_objects.findIndex(
            (workObject) => workObject.id === data.objectId,
        );
        if (objectIndex >= 0) {
            modifiedAgreement.works[workIndex].work_objects[objectIndex].work_type_id =
                data.workTypeOption.id;
            modifiedAgreement.works[workIndex].work_objects[objectIndex].work_type_name =
                data.workTypeOption.value;
        }
    }

    return modifiedAgreement;
};

// изменяет наименование, ГФК, id транспортной сети объекта во временном общем объекте
export const changeObjectLabel = (
    selectedWorkObject: CatalogWorkObjectOption,
    objectId: string,
    agreement: ITemporaryCopingAgreement,
): ITemporaryCopingAgreement => {
    const modifiedAgreement = cloneDeep(agreement);
    modifiedAgreement.works.forEach((work) => {
        work.work_objects.forEach((object) => {
            if (object.id === objectId) {
                object.objectId = selectedWorkObject.id;
                object.gfk = selectedWorkObject.gfk ?? '';
                object.name = getNameFromObjectLabel(selectedWorkObject);
                object.transport_net_id = selectedWorkObject.transport_net_id;
            }
        });
    });
    return modifiedAgreement;
};

// изменяет выбранные спецификации
export const changeSpecificationChecked = (
    agreement: ITemporaryCopingAgreement,
    data: {
        worksheetId: string;
        objectId: string;
        specificationId: string;
    },
): ITemporaryCopingAgreement => {
    const modifiedAgreement = cloneDeep(agreement);
    const workIndex = modifiedAgreement.works.findIndex(
        (worksheet) => worksheet.id === data.worksheetId,
    );
    if (workIndex >= 0) {
        const objectIndex = modifiedAgreement.works[workIndex].work_objects.findIndex(
            (workObject) => workObject.id === data.objectId,
        );
        if (objectIndex >= 0) {
            modifiedAgreement.works[workIndex].work_objects[objectIndex].checked = true;
            const specificationIndex = modifiedAgreement.works[workIndex].work_objects[
                objectIndex
            ].work_specifications.findIndex(
                (specification) => specification.id === data.specificationId,
            );
            if (specificationIndex >= 0) {
                modifiedAgreement.works[workIndex].work_objects[objectIndex].work_specifications[
                    specificationIndex
                ].checked =
                    !modifiedAgreement.works[workIndex].work_objects[objectIndex]
                        .work_specifications[specificationIndex].checked;
            }
        }

        // если все объекты не выбраны, снять выделение ведомости
        modifiedAgreement.works[workIndex].checked = workSheetHasCheckedObjects(
            modifiedAgreement.works[workIndex],
        );
    }

    return modifiedAgreement;
};

// изменяет выбранные спецификации
export const changeWorkObjectChecked = (
    agreement: ITemporaryCopingAgreement,
    data: {
        worksheetId: string;
        objectId: string;
        checked: boolean;
    },
): ITemporaryCopingAgreement => {
    const modifiedAgreement = cloneDeep(agreement);
    const workIndex = modifiedAgreement.works.findIndex(
        (worksheet) => worksheet.id === data.worksheetId,
    );
    if (workIndex >= 0) {
        const objectIndex = modifiedAgreement.works[workIndex].work_objects.findIndex(
            (workObject) => workObject.id === data.objectId,
        );
        if (objectIndex >= 0) {
            modifiedAgreement.works[workIndex].work_objects[objectIndex].checked = !data.checked;
            modifiedAgreement.works[workIndex].work_objects[
                objectIndex
            ].work_specifications.forEach(
                (specification) => (specification.checked = !data.checked),
            );
        }

        // если все объекты не выбраны, снять выделение ведомости
        modifiedAgreement.works[workIndex].checked = workSheetHasCheckedObjects(
            modifiedAgreement.works[workIndex],
        );
    }

    return modifiedAgreement;
};

// изменяет выбранные спецификации при нажатии на "выбрать все пункты"
export const changeAllSpecificationsChecked = (
    agreement: ITemporaryCopingAgreement,
    data: {
        worksheetId: string;
        objectId: string;
        checked: boolean;
    },
): ITemporaryCopingAgreement => {
    const modifiedAgreement = cloneDeep(agreement);
    const workIndex = modifiedAgreement.works.findIndex(
        (worksheet) => worksheet.id === data.worksheetId,
    );
    if (workIndex >= 0) {
        const objectIndex = modifiedAgreement.works[workIndex].work_objects.findIndex(
            (workObject) => workObject.id === data.objectId,
        );
        if (objectIndex >= 0) {
            if (data.checked) {
                modifiedAgreement.works[workIndex].work_objects[objectIndex].checked = true;
            }

            modifiedAgreement.works[workIndex].work_objects[
                objectIndex
            ].work_specifications.forEach(
                (specification) => (specification.checked = !data.checked),
            );
        }
    }

    return modifiedAgreement;
};

export const prepareCopiedAgreementToConstructor = (
    agreement: ITemporaryCopingAgreement,
    availableObjectList: CatalogWorkObjectOption[],
    hasQuantity: boolean,
    hasCoefficients: boolean,
): IUpdatedAgreement => {
    const finalWorks: Record<string, ConstructorVirType> = {};
    agreement.works.forEach((worksheet) => {
        if (worksheet.checked) {
            const worksheetGeneratedId = `front -${uuidv4()}`;
            const workObjects: Record<string, ConstructorVirWorkObject> = {};

            // объект work_objects
            worksheet.work_objects.forEach((workObject) => {
                const workSpecifications: Record<string, TpiSpecification> = {};
                if (workObject.checked) {
                    const workObjectGeneratedId = `front -${uuidv4()}`;

                    const objectHasToBeChanged =
                        workObject.object_type !== EWorkObjectTypes.RESPONSE_PART ||
                        availableObjectList.filter(
                            (item) => item.object_type === EWorkObjectTypes.RESPONSE_PART,
                        ).length === 1;

                    const copiedObject = availableObjectList.find(
                        (object) => object.object_type === workObject.object_type,
                    );

                    const shouldUseOldData = objectHasToBeChanged && !!copiedObject;

                    workObject.gfk = shouldUseOldData ? copiedObject.gfk ?? '' : workObject.gfk;
                    workObject.name = shouldUseOldData
                        ? getNameFromObjectLabel(copiedObject)
                        : workObject.name;

                    workObject.transport_net_id = shouldUseOldData
                        ? copiedObject.transport_net_id
                        : workObject.transport_net_id;
                    const workObjectName = createAgreementWorkObjectName(workObject);
                    // Заполнение объекта work_specifications
                    workObject.work_specifications.forEach((specification) => {
                        if (specification.checked) {
                            const workSpecificationId = `front -${uuidv4()}`;
                            workSpecifications[workSpecificationId] = {
                                ...specification,
                                id: workSpecificationId,
                                default_begin_date: null,
                                default_end_date: null,
                                is_tax: specification.is_tax ?? false,
                                note: specification.note ?? 'Без описания',
                                tpi_specification_id: specification.tpi_specification_id ?? '',
                                virId: worksheetGeneratedId,
                                workObjectId: workObjectGeneratedId,
                                price: +specification.price,
                                quantity: +specification.quantity,
                                sum: +specification.sum,
                                sum_tax: +specification.sum_tax,
                                total_sum: +specification.total_sum,
                                type_position: specification.type_position,
                                work_coefficients: specification.work_coefficients,
                                work_specification_approval: undefined,
                                work_specification_approval_total: undefined,
                                category_name: specification.category_name,
                            };
                            // тут будет проверка чекбокса "перенести коэффициенты" и пересчет коэф-ов
                            if (!hasCoefficients) {
                                workSpecifications[workSpecificationId].work_coefficients = [];
                                const remoteTerritoryCoefficient =
                                    workSpecifications[workSpecificationId]
                                        .coefficient_remote_territory;
                                const amsCoefficient = workSpecifications[workSpecificationId]
                                    .is_ams
                                    ? workSpecifications[workSpecificationId].coefficient_ams
                                    : 1;
                                const foundationCoefficient = workSpecifications[
                                    workSpecificationId
                                ].is_foundation
                                    ? workSpecifications[workSpecificationId].coefficient_foundation
                                    : 1;
                                workSpecifications[workSpecificationId].coefficient =
                                    remoteTerritoryCoefficient *
                                    amsCoefficient *
                                    foundationCoefficient;
                            }

                            // проверка чекбокса "перенести количество"
                            if (!hasQuantity) {
                                workSpecifications[workSpecificationId].quantity = 1;
                            }

                            const {is_tax} = workSpecifications[workSpecificationId];
                            const currentSpecification = workSpecifications[workSpecificationId];
                            const taxRate = is_tax ? 20 : 0;
                            workSpecifications[workSpecificationId].sum =
                                calcSumForOneSpecification(currentSpecification);
                            workSpecifications[workSpecificationId].sum_tax = calcTaxSum(
                                workSpecifications[workSpecificationId].sum,
                                taxRate,
                            );

                            workSpecifications[workSpecificationId].total_sum =
                                workSpecifications[workSpecificationId].sum +
                                workSpecifications[workSpecificationId].sum_tax;
                        }
                    });

                    workObjects[workObjectGeneratedId] = {
                        ...workObject,
                        is_half_set: !workObject.is_half_set ? true : workObject.is_half_set,
                        virId: worksheetGeneratedId,
                        object_name: workObjectName,
                        object_id: workObjectGeneratedId,
                        work_specifications: workSpecifications,
                    };
                }
            });
            console.log(workObjects);
            finalWorks[worksheetGeneratedId] = {
                id: worksheetGeneratedId,
                sum: 0,
                sum_tax: 0,
                total_sum: 0,
                work_objects: workObjects,
            };

            // рассчет общих сумм ведомостей
            Object.values(finalWorks[worksheetGeneratedId].work_objects).forEach((workObject) => {
                Object.values(workObject.work_specifications).forEach((specification) => {
                    finalWorks[worksheetGeneratedId].sum += specification.sum;
                    finalWorks[worksheetGeneratedId].sum_tax += specification.sum_tax;
                    finalWorks[worksheetGeneratedId].total_sum += specification.total_sum;
                });
            });
        }
    });
    const updatedAgreement: IUpdatedAgreement = {
        is_released: agreement.is_released,
        is_prepayment: agreement.is_prepayment,
        agreement_id: '',
        parent_signing_date: null,
        project_id: agreement.project_id,
        contract: agreement.contract ?? '',
        contract_id: agreement.contract_id,
        tpi_id: agreement.tpi_id,
        begin_date: agreement.begin_date,
        end_date: agreement.end_date,
        agreement_comments: agreement.agreement_comments,
        agreement_attachments: agreement.agreement_attachments,
        works: finalWorks,
        meta: agreement.meta,
        project_number: agreement.project_number,
        contract_name: agreement.contract_name,
        remote_territory_id: agreement.remote_territory_id,
    };
    return updatedAgreement;
};

const workSheetHasCheckedObjects = (worksheet: TTemporaryCopingWork): boolean => {
    return worksheet.work_objects.some((workObject) => workObject.checked);
};

const calcTotalCoefficient = (specification: ITemporaryAgreementWorkSpecification) => {
    const {
        is_ams,
        is_foundation,
        coefficient_ams,
        coefficient_foundation,
        coefficient_remote_territory,
        work_coefficients,
    } = specification;
    let totalCoefficient = formatNumber(
        (is_ams ? coefficient_ams : 1) *
            (is_foundation ? coefficient_foundation : 1) *
            coefficient_remote_territory,
        6,
    );

    const multiplyingCoefficientArray: number[] = [];
    const increasingBySumCoefficientArray: number[] = [];

    work_coefficients.forEach((coefficient) => {
        if (
            coefficient.is_manual ||
            coefficient.type === COEFFICIENT_TYPE_ENUM.Increasing ||
            coefficient.type === COEFFICIENT_TYPE_ENUM.Dismantling
        ) {
            multiplyingCoefficientArray.push(coefficient.coefficient);
        }
        if (coefficient.type === COEFFICIENT_TYPE_ENUM.IncreasingBySum)
            increasingBySumCoefficientArray.push(coefficient.coefficient);
    });
    const multiplyCoefficient = formatNumber(
        multiplyingCoefficientArray.reduce((acc, curr) => acc * curr, 1),
        6,
    );
    const sumCoefficient =
        increasingBySumCoefficientArray.reduce((acc, curr) => acc + curr, 0) +
        1 -
        increasingBySumCoefficientArray.length;

    return formatNumber(totalCoefficient * multiplyCoefficient * (sumCoefficient || 1), 4);
};

const getNameFromObjectLabel = (workObject: CatalogWorkObjectOption) => {
    return workObject.label.substring(workObject.label.indexOf(' ') + 1);
};
