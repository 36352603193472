import React from 'react';
import {Banner, Progress, Radio, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';
import {Certificate} from 'crypto-pro/lib/api/certificate';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {formatDateToBackendFormat, isDateRangeValid} from 'shared/helpers/formatHelper';

import * as S from './style';

interface CertificateRadioGroupProps {
    certificateList: Certificate[];
    selectedCertificateId: string;
    handleClick: (a: string) => void;
    isLoading: boolean;
}

interface RadioLabelProps {
    certificate: Certificate;
    disabled: boolean;
}

export const CertificateRadioGroup = ({
    certificateList,
    selectedCertificateId,
    handleClick,
    isLoading,
}: CertificateRadioGroupProps) => {
    const handleOnChange = (e) => {
        handleClick(e.target.id);
    };
    const bannerTitle = 'Сертификаты не найдены';
    return (
        <WrapperFlex flexDirection="column" gap="16px">
            {isLoading ? (
                <S.LoaderWrapper>
                    <Progress shape="circle" cycled />
                </S.LoaderWrapper>
            ) : (
                <>
                    {certificateList.map((item) => {
                        const radioDisabled = isDateRangeValid(item.validFrom, item.validFrom);
                        return (
                            <S.RadioWrapper key={item.thumbprint}>
                                <Radio
                                    name="certificates"
                                    id={item.thumbprint}
                                    label={
                                        <RadioLabel certificate={item} disabled={radioDisabled} />
                                    }
                                    checked={item.thumbprint === selectedCertificateId}
                                    onChange={handleOnChange}
                                    disabled={radioDisabled}
                                />
                            </S.RadioWrapper>
                        );
                    })}
                </>
            )}
            {!isLoading && certificateList.length === 0 && (
                <S.LoaderWrapper noBorder>
                    <Banner title={bannerTitle} color="error" iconName={Icons.InfoCircled} />
                </S.LoaderWrapper>
            )}
        </WrapperFlex>
    );
};

const RadioLabel = ({certificate, disabled}: RadioLabelProps) => {
    const certificateName = `Сертификат: ${certificate.name}`;
    const subjectNameList = certificate.subjectName.split(', ');
    const name = `Владелец: ${subjectNameList
        .filter((item) => item.startsWith('SN='))[0]
        .slice(3)} ${subjectNameList.filter((item) => item.startsWith('G='))[0].slice(2)}`;
    const validity = `Действителен с ${formatDateToBackendFormat(
        certificate.validFrom,
    )} по ${formatDateToBackendFormat(certificate.validTo)}`;

    return (
        <WrapperFlex flexDirection="column" gap="8px" padding="0 0 0 8px">
            <Typography variant="body2">{certificateName}</Typography>
            <Typography variant="body2">{name}</Typography>
            <S.DisabledText active={disabled}>
                <Typography variant="body2">{validity}</Typography>
            </S.DisabledText>
        </WrapperFlex>
    );
};
