import React from 'react';
import {Label} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

interface ActionLabelProps {
    condition: boolean | null;
}

export const ActionLabel = ({condition}: ActionLabelProps) => {
    switch (condition) {
        case true:
            return <Label type="success" variant="icon" iconName={Icons.Check} />;
        case false:
            return <Label type="error" variant="icon" iconName={Icons.Close} />;
        default:
            return null;
    }
};
