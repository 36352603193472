import React from 'react';
import {Modal, ModalWrap, Window} from 'shared/ui/deprecated/ModalWindow/style';

export default function ModalWindow({children, open = false}) {
    return (
        <Modal
            open={open}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <ModalWrap>
                <Window>{children}</Window>
            </ModalWrap>
        </Modal>
    );
}
