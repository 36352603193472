import React from 'react';
import {
    CheckboxStyled,
    CheckboxPartlyStyled,
    CheckboxLabelStyled,
    CheckboxCaption,
} from 'shared/ui/deprecated/Checkbox/style';

export default function Checkbox({isPartly, ...rest}) {
    return isPartly ? (
        <CheckboxPartlyStyled type="checkbox" {...rest} />
    ) : (
        <CheckboxStyled type="checkbox" {...rest} />
    );
}

export const CheckboxLabel = ({
    title,
    value,
    name,
    isPartly,
    checked = false,
    onChange = () => {},
    ...rest
}) => {
    return (
        <CheckboxLabelStyled {...rest}>
            <Checkbox
                value={value}
                name={name}
                checked={checked}
                onChange={onChange}
                isPartly={isPartly}
                {...rest}
            />
            <CheckboxCaption>{title}</CheckboxCaption>
        </CheckboxLabelStyled>
    );
};
