import styled, {css} from "styled-components";

const baseSkeletonMixin = () => css`
  background: #F1F1F3;
  
  width: 100%;
`;

export const skeletonMixin = (props) => css`
  ${baseSkeletonMixin()}

  ${props.height && css`
    height: ${props.height}px;
  `}

  ${props.width && css`
    width: ${props.width}px;
  `}
  
  ${props.variant && props.variant === 'rectangle' && css`
    border-radius: 12px;
  `}
  
  ${props.variant && props.variant === 'ellipse' && css`
    border-radius: 50%;
  `}
  
  ${props.animation && props.animation === 'pulse' && css`
    animation: pulse 1.5s ease-in-out 0.5s infinite;
    
    @keyframes pulse {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
      100% {
        opacity: 1;
      }
    }
  `}
  
  ${props.animation && props.animation === 'wave' && css`
    background: linear-gradient(90deg, #ececec 8%, #f3f3f3 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s wave linear infinite;
    
    @keyframes wave {
      to {
        background-position-x: -200%;
      }
    }
  `}
  
`;


export const SkeletonStyled = styled.div`
  ${(props) => skeletonMixin(props)}
`;
