import {forwardPayload} from 'shared/helpers/effector';
import {combine, forward, sample} from 'effector';
import {addLabelToWorkOptionBasedOnType} from 'shared/helpers/formatHelper';
import {
    $contractId,
    $contractOptions,
    $formErrors,
    $fromProject,
    $isFormValid,
    $objectsLoaded,
    $ownerId,
    $projectId,
    $selectedContract,
    $selectedProject,
    $selectedTpi,
    $selectedWorkObject,
    $selectedWorkType,
    $touchedForm,
    $tpiId,
    $tpiOptions,
    $workObjectsOptions,
    $workProjectOptions,
    $workTypeId,
    $workTypOptions,
    loadCatalogProjectsFx,
    loadCatalogWorkObjectsFx,
    loadContractsFx,
    loadTpisFx,
    loadWorkTypesFx,
    ProjectSelectGate,
    saveOwnerId,
    selectContract,
    selectProject,
    selectTpi,
    selectWorkObject,
    selectWorkType,
    validateForm,
    VirCreatePageGate,
} from './index';
import {$createAdditionalAgreementData} from 'processes/contructor';
import {ITpiCatalog} from 'pages/VirStart/types';

// загрузка только в случае открытия конструктора не из карточки проекта
sample({
    clock: ProjectSelectGate.open,
    source: $fromProject,
    filter: (isFromProject) => !isFromProject,
    target: loadCatalogProjectsFx,
});

// Открытие конструктора ДС при редактировании ($createAdditionalAgreementData заполнен)
sample({
    clock: VirCreatePageGate.open,
    source: combine({
        agreementData: $createAdditionalAgreementData,
        selectedProject: $selectedProject,
    }),
    filter: ({agreementData, selectedProject}) =>
        !!agreementData.project_id && selectedProject === null,
    fn: ({agreementData}) => ({project_id: agreementData.project_id || ''}),
    target: loadCatalogWorkObjectsFx,
});

sample({
    clock: selectProject,
    source: $selectedProject,
    filter: (selectedProject) => !!selectedProject,
    fn: (selectedProject) => ({project_id: selectedProject?.project_id || ''}),
    target: loadCatalogWorkObjectsFx,
});

$workObjectsOptions.on(loadCatalogWorkObjectsFx.doneData, (state, payload) =>
    addLabelToWorkOptionBasedOnType(payload.data),
);

$objectsLoaded.on(loadCatalogWorkObjectsFx.done, () => true);

sample({
    clock: [selectContract, loadContractsFx.done],
    source: combine({contract: $selectedContract, project: $selectedProject}),
    filter: (source) =>  !!source.contract,
    fn: ({contract, project}) => ({
        framework_contract_id: contract?.framework_contract_id,
        project_id: project?.project_id,
    }),
    target: loadTpisFx,
});

$ownerId.on(saveOwnerId, forwardPayload());

sample({
    clock: selectProject,
    source: combine({
        ownerId: $ownerId,
        project: $selectedProject,
        fromProject: $fromProject,
    }),
    filter: ({ownerId, project}) => !!ownerId && !!project?.project_id,
    fn: ({ownerId, project}) =>
        ownerId ? {contractor_id: ownerId, project_id: project?.project_id} : {},
    target: loadContractsFx,
});

sample({
    clock: VirCreatePageGate.open,
    source: $workTypOptions,
    filter: (options) => options.length === 0,
    target: loadWorkTypesFx,
});

$tpiId.on(selectTpi, (state, payload) => payload?.tpis_id);
$projectId
    .on(VirCreatePageGate.open, (state, payload) => payload?.projectId)
    .on(selectProject, (state, payload) => payload?.project_id);
$fromProject.on(VirCreatePageGate.open, (state, payload) => !!payload?.projectId);

$workProjectOptions.on(loadCatalogProjectsFx.doneData, (state, payload) => {
    return payload.data?.results?.map((el) => ({
        project_id: el.id,
        project_number: el?.project_ext_id?.toString(),
    }));
});

$contractOptions
    .on(loadContractsFx.doneData, (state, payload) =>
        payload?.data?.map((el) => ({framework_contract_id: el.id, contract_number: el.number})),
    )
    .reset(selectProject);

$tpiOptions
    .on(loadTpisFx.doneData, (state, payload) => {
        const result: ITpiCatalog[] = [];
        payload?.data?.forEach((el) => {
            if ((el.remote_territories?.length ?? 0) > 0) {
                el.remote_territories?.forEach((item) => {
                    result.push({
                        tpis_id: el.id,
                        tpis_name: `${el.name} (${item.name})`,
                        remote_territory_id: item.id,
                        purchasing_event_number: el.purchasing_event_number,
                    });
                });
            } else {
                result.push({
                    tpis_id: el.id,
                    tpis_name: el.name,
                    purchasing_event_number: el.purchasing_event_number,
                });
            }
        });
        return result;
    })
    .reset(selectProject);
$workTypOptions.on(loadWorkTypesFx.doneData, (state, payload) =>
    payload?.data?.map((el) => ({work_types_id: el.id, work_types_name: el.name})),
);

$workTypeId.on(selectWorkType, (state, payload) => payload.work_types_id);
$contractId.on(selectContract, (state, payload) => payload.framework_contract_id);

$selectedProject.on(selectProject, forwardPayload());

$selectedWorkObject
    .on(selectWorkObject, (state, payload) => {
        if (payload.lavParts) {
            const result = {...payload};
            const lavParts = payload.lavParts.map((item) => {
                item.is_half_set = item.object_type === 3 ? false : item.is_half_set;
                item.lav_gfk = result.gfk;
                return item;
            });
            result.lavParts = lavParts;
            return result;
        } else {
            return payload;
        }
    })
    .reset(selectProject);
$selectedWorkType.on(selectWorkType, forwardPayload()).reset(selectProject);
$selectedContract
    .on(selectContract, forwardPayload())
    .on(loadContractsFx.doneData, (state, {data}) =>
        data?.length === 1
            ? {framework_contract_id: data[0].id, contract_number: data[0].number}
            : state,
    )
    .reset(selectProject);

$selectedTpi.on(selectTpi, forwardPayload()).reset([selectProject, selectContract]);

sample({
    source: $tpiOptions,
    fn: (source) => {
        return source.length === 1 ? source[0] : null;
    },
    target: $selectedTpi,
});
const updateForm = sample({
    clock: [
        validateForm,
        selectWorkType,
        selectContract,
        selectTpi,
        selectWorkObject,
        selectProject,
    ],
    source: combine({
        object: $selectedWorkObject,
        workType: $selectedWorkType,
        touched: $touchedForm,
        contract: $selectedContract,
        tpi: $selectedTpi,
    }),
    filter: ({touched}) => !!touched,
    fn: ({tpi, contract, object, workType}) => {
        return {
            tpi: !tpi,
            contract: !contract,
            object: !object,
            workType: !workType,
        };
    },
});

$formErrors.reset(VirCreatePageGate.close);

$touchedForm.on(validateForm, () => true).reset(VirCreatePageGate.close);
$isFormValid.reset(VirCreatePageGate.close);

forward({
    from: updateForm,
    to: $formErrors,
});

sample({
    clock: updateForm,
    source: $touchedForm,
    filter: (touched) => touched,
    fn: (_, errors) => Object.values(errors).every((el) => !el),
    target: $isFormValid,
});
