import React from 'react';
import {Button} from '@beeline/design-system-react';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

import {ITaskResponse} from 'pages/DSPage/types';

interface WorkflowButtonsBlockProps {
    buttonIsLoading: boolean;
    handleAcceptWorkflowButton: () => void;
    handleCancelTask: () => void;
    handleWorkflowButton: (num: number) => void;
    isGroup: boolean;
    substageType: number;
    status: number;
    workflowButtons: ITaskResponse | null;
}

export const WorkflowButtonsBlock = ({
    workflowButtons,
    status,
    isGroup,
    substageType,
    handleWorkflowButton,
    handleCancelTask,
    buttonIsLoading,
    handleAcceptWorkflowButton,
}: WorkflowButtonsBlockProps) => {
    const getButtonVariant = (isPositive: boolean | null | undefined) => {
        return isPositive === true ? 'contained' : 'outlined';
    };

    const showCancelButton = status === 2 && isGroup && substageType === 1;

    return (
        <WrapperFlex alignItems="center" gap="16px">
            {showCancelButton && (
                <Button onClick={handleCancelTask} size="medium" disabled={buttonIsLoading}>
                    Вернуть на группу
                </Button>
            )}
            {status === 1 ? (
                <Button
                    color="outline"
                    size="medium"
                    onClick={handleAcceptWorkflowButton}
                    disabled={buttonIsLoading}
                >
                    Взять в работу
                </Button>
            ) : (
                status === 2 &&
                workflowButtons?.data.actions?.map((item, index) => (
                    <Button
                        key={`${item.name}_${index}`}
                        onClick={() => handleWorkflowButton(index)}
                        variant={getButtonVariant(item.is_positive)}
                        size="medium"
                        disabled={buttonIsLoading}
                    >
                        {item.name}
                    </Button>
                ))
            )}
        </WrapperFlex>
    );
};
