import React from 'react';
import {Link} from 'react-router-dom';
import {createLink} from '../../../shared/helpers/links';

interface TypedHyperlinkProps {
    id: string;
    objectType: number;
    additionalAgreementNumber?: number | string;
    target?: string;
    children: React.ReactNode;
}

export const TypedHyperlink = ({
    id,
    objectType,
    additionalAgreementNumber,
    target,
    children,
}: TypedHyperlinkProps) => {
    const link = createLink(objectType, id, additionalAgreementNumber);
    return (
        <Link to={link} target={target}>
            {children}
        </Link>
    );
};
