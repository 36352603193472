import React from 'react';
import {useStore} from 'effector-react';

import {$additionalAgreementsFilters, searchBySearchTerm, setSearchTerm} from '../../model';
import * as S from './styled';
import {$userStore} from 'shared/model/user';
import {USER_ROLE_TYPE} from 'shared/services/types/userTypes';
import Button from 'shared/ui/deprecated/Button/Button';
import {useNavigate} from 'react-router-dom';
import {NonMvpItem} from 'shared/styles/commonStyle';

export function ActionPanel() {
    const navigate = useNavigate();
    const {searchTerm} = useStore($additionalAgreementsFilters);
    const {user_type} = useStore($userStore);
    const isNotVK = user_type !== USER_ROLE_TYPE.VK_USER && user_type !== USER_ROLE_TYPE.VK_ADMIN;
    return (
        <S.Wrapper>
            <NonMvpItem>
                {isNotVK && (
                    <Button
                        color="outline"
                        onClick={() => navigate('/additional-agreement/create')}
                    >
                        Создать
                    </Button>
                )}
            </NonMvpItem>
            <S.StyledSearch
                placeholder="Поиск по названию"
                value={searchTerm}
                onInput={setSearchTerm}
                onSearch={searchBySearchTerm}
            />
        </S.Wrapper>
    );
}
