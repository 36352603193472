import React from 'react';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {Skeleton} from '@beeline/design-system-react';

export const LabelRadioSkeleton = () => {
    return (
        <WrapperFlex>
            <Skeleton className="me-2" height={32} width={92} variant="circle" />
            <Skeleton className="me-2" height={32} width={62} variant="circle" />
            <Skeleton className="me-2" height={32} width={102} variant="circle" />
            <Skeleton className="me-2" height={32} width={92} variant="circle" />
        </WrapperFlex>
    );
};
