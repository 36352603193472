import React from 'react';
import {Link} from 'react-router-dom';
import {useUnit} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import {InformationalUnit} from 'Entities/InformationalUnit';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {formatDateHours} from 'shared/helpers/formatHelper';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {EAccessActions} from 'shared/const/actions';
import {$userActions} from 'shared/model/user';
import {
    ADDRESS_TITLE,
    CONFORMING_DATE,
    CONTRACTOR_SHORT_TITLE,
    CREATION_DATE,
    LOT_TITLE,
    PROCUREMENT_EVENT_TITLE,
    PROJECT_TYPE_TITLE,
    WORK_TYPE_TITLE,
} from 'shared/constants';

import * as S from './style';
import {DSPageInfoSkeleton} from './DSPageInfoSkeleton';
import {AdditionalAgreementFull} from '../../types';

interface DSPageInfoProps {
    dsData: AdditionalAgreementFull | null;
    isLoading: boolean;
}

export function DSPageInfo({dsData, isLoading}: DSPageInfoProps) {
    const actionList = useUnit($userActions);
    const contractLinkAllowed = userHasPermission(EAccessActions.ContractCard, actionList);
    if (!dsData || isLoading) {
        return <DSPageInfoSkeleton />;
    }

    const {
        approved_at,
        bs_number,
        bs_name,
        address,
        contract_name,
        contract,
        created_at,
        gpo_short_name,
        project_type,
        purchasing_event_number,
        purchase_lots_name,
        work_types,
    } = dsData;

    return (
        <WrapperOffset offsetTop={36}>
            <Typography variant="h5">{`БС ${bs_number} — ${bs_name}`}</Typography>
            <S.GridWrapper>
                <InformationalUnit title={ADDRESS_TITLE} gridArea="address">
                    {address}
                </InformationalUnit>
                <InformationalUnit title={CREATION_DATE} gridArea="creationDate">
                    {formatDateHours(created_at.toString())}
                </InformationalUnit>
                <InformationalUnit title={CONFORMING_DATE} gridArea="approvingDate">
                    {formatDateHours(approved_at?.toString() ?? null)}
                </InformationalUnit>
                <InformationalUnit title={WORK_TYPE_TITLE} gridArea="jobType">
                    {work_types}
                </InformationalUnit>
                <InformationalUnit title={PROJECT_TYPE_TITLE} gridArea="projectType">
                    {project_type}
                </InformationalUnit>
                <InformationalUnit title={LOT_TITLE}>{purchase_lots_name ?? '—'}</InformationalUnit>
                <InformationalUnit title={CONTRACTOR_SHORT_TITLE} gridArea="contractor">
                    {gpo_short_name}
                </InformationalUnit>
                <InformationalUnit title={PROCUREMENT_EVENT_TITLE} gridArea="purchase">
                    <>
                        <Typography variant="body2">{purchasing_event_number}</Typography>
                        <Typography variant="body2">
                            {contractLinkAllowed ? (
                                <Link to={`/contracts/${contract}`}>{contract_name}</Link>
                            ) : (
                                contract_name
                            )}
                        </Typography>
                    </>
                </InformationalUnit>
            </S.GridWrapper>
        </WrapperOffset>
    );
}
