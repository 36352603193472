import {bindAllMethods} from 'shared/helpers/binder';
import {axiosInstance} from 'shared/api/api';
import {CatalogWorkObject, GetWorkObjectsRequest} from '../types';
import {WorkTypeResponse} from '../../VirStart/types';

export const addWorkObjectService = bindAllMethods({
    async getCatalogWorkObjects(params: GetWorkObjectsRequest): Promise<{data: CatalogWorkObject}> {
        return axiosInstance.get(`/projects/projects/${params.project_id}/work_objects/`, {params});
    },

    async getCatalogWorkTypes(): Promise<{data: WorkTypeResponse[]}> {
        return axiosInstance.get('/catalogs/work_types/');
    },
});
