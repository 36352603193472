import styled from 'styled-components/macro';
import Button from '../../../shared/ui/deprecated/Button/Button';

export const HeaderBtnWrap = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
`;

export const HeaderInfoWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
`;
export const HeaderWrap = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 32px;
`;

export const StyledButton = styled(Button)`
    align-self: baseline;
`;
