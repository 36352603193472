import styled, {css} from 'styled-components/macro';

export const SelectStyled = styled.div`
    ${(props) => css`
        position: relative;
        width: var(--selectWidth, 280px);
        ${props.fullWidth && `width: 100%;`}
    `}
`;

export const selectInputMixin = (props) => css`
    ${css`
        background-color: ${(p) => (p.error ? 'var(--light-red)' : 'var(--light-control)')};
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuMjg1MTYgNi45MTQwNkM1LjExNTg5IDYuOTc5MTcgNC45OTIxOSA3LjEwMjg2IDQuOTE0MDYgNy4yODUxNkM0Ljg0ODk2IDcuNDY3NDUgNC44NjE5OCA3LjYzNjcyIDQuOTUzMTIgNy43OTI5N0M0Ljk3OTE3IDcuODU4MDcgNS44NTgwNyA4Ljc1IDcuNTg5ODQgMTAuNDY4OEM4Ljc2MTcyIDExLjY0MDYgOS40OTA4OSAxMi4zNjk4IDkuNzc3MzQgMTIuNjU2MkMxMC4wNzY4IDEyLjkyOTcgMTAuMjU5MSAxMy4wNzk0IDEwLjMyNDIgMTMuMTA1NUMxMC40OTM1IDEzLjE0NDUgMTAuNjQ5NyAxMy4xMjUgMTAuNzkzIDEzLjA0NjlDMTAuODU4MSAxMy4wMjA4IDExLjc1IDEyLjE0MTkgMTMuNDY4OCAxMC40MTAyQzE0LjY0MDYgOS4yMzgyOCAxNS4zNjMzIDguNTA5MTEgMTUuNjM2NyA4LjIyMjY2QzE1LjkyMzIgNy45MjMxOCAxNi4wNzk0IDcuNzQwODkgMTYuMTA1NSA3LjY3NTc4QzE2LjE3MDYgNy40MjgzOSAxNi4xMTg1IDcuMjIwMDUgMTUuOTQ5MiA3LjA1MDc4QzE1Ljc3OTkgNi44ODE1MSAxNS41NzE2IDYuODI5NDMgMTUuMzI0MiA2Ljg5NDUzQzE1LjI1OTEgNi45MjA1NyAxNS4wODk4IDcuMDYzOCAxNC44MTY0IDcuMzI0MjJDMTQuNTU2IDcuNTcxNjEgMTMuODk4NCA4LjIyMjY2IDEyLjg0MzggOS4yNzczNEwxMC41IDExLjYyMTFMOC4xNTYyNSA5LjI3NzM0QzcuMTUzNjUgOC4yNzQ3NCA2LjUwMjYgNy42MzY3MiA2LjIwMzEyIDcuMzYzMjhDNS45MTY2NyA3LjA3NjgyIDUuNzQwODkgNi45MjA1NyA1LjY3NTc4IDYuODk0NTNDNS41OTc2NiA2Ljg4MTUxIDUuNTMyNTUgNi44NzUgNS40ODA0NyA2Ljg3NUM1LjQyODM5IDYuODc1IDUuMzYzMjggNi44ODgwMiA1LjI4NTE2IDYuOTE0MDZaIiBmaWxsPSIjMTkxQzM0IiBmaWxsLW9wYWNpdHk9IjAuNyIvPgo8L3N2Zz4K);
        background-position: right 0.75rem center;
        background-repeat: no-repeat;
        background-size: 20px;
        border: 1px solid ${(p) => (p.error ? 'var(--red)' : 'transparent')};
        border-radius: 12px;
        cursor: pointer;
        padding: 12px 44px 12px 16px;
        height: 48px;
        transition-duration: 0.4s;
    `}
    ${props.filled &&
    css`
        padding-bottom: 6px;
        padding-top: 6px;
    `}
  ${props.disabled &&
    css`
        cursor: default;
        color: var(--grey);
        opacity: 0.5;
    `}
  ${props.active &&
    css`
        background-color: var(--bs-white);
        border: 1px solid ${(p) => (p.error ? 'var(--red)' : 'var(--bs-black)')};
    `}
`;

export const SelectInput = styled.div`
    ${(props) => selectInputMixin(props)}
`;
export const Hint = styled.span`
    color: ${(p) => (p.error ? 'var(--red)' : 'var(--secondary)')};
    position: absolute;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-left: 16px;
    margin-top: 4px;
`;

export const selectTextMixin = (props) => css`
    ${css`
        font-size: 17px;
        line-height: 1.2941176471;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: none;
    `}
    ${props.filled &&
    css`
        display: block;
    `}
`;

export const SelectText = styled.div`
    ${(props) => selectTextMixin(props)}
`;
export const selectTitleMixin = (props) => css`
    ${css`
        font-size: 17px;
        line-height: 1.2941176471;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        transition-duration: 0.4s;
        color: #57596b;
    `}
    ${props.filled &&
    css`
        font-size: 13px;
        line-height: 1.2307692308;
    `}
`;

export const SelectTitle = styled.div`
    ${(props) => selectTitleMixin(props)}
`;

export const selectListWrapMixin = (props) => css`
    ${css`
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0 0 10px var(--light-control), 0 4px 30px var(--light-control);
        left: 0;
        overflow: hidden;
        padding-bottom: 12px;
        padding-top: 12px;
        position: absolute;
        top: calc(100% + 2px);
        width: 100%;
        z-index: 2;
        min-width: var(--selectListWidth, 280px);
        display: none;
    `}
    ${props.active &&
    css`
        display: block;
    `}
`;

export const SelectListWrap = styled.div`
    ${(props) => selectListWrapMixin(props)}
`;
export const SelectSearchWrap = styled.div`
    padding: 4px 16px 22px;
`;
export const SelectListAll = styled.div`
    border-bottom: 1px solid #e0e0e0;
`;

export const SelectList = styled.div`
    max-height: 444px;
    overflow-y: auto;
`;

export const SelectItemSoloStyled = styled.label`
    display: block;
    cursor: pointer;

    input:checked + div {
        background-color: #ededef;
    }

    input {
        display: none;
    }

    ${(p) =>
        p.disabled &&
        `
      color: #d1d1d1;
      cursor:default;
      pointer-events: none;
    `}
`;

export const SelectItemSoloText = styled.div`
    background-color: #fff;
    padding: 12px 16px;
    transition-duration: 0.4s;
    font-size: 17px;
    ${(p) => p?.nested && `padding-left: 36px;`};

    &:hover {
        background-color: #ededef;
    }
`;

export const SelectItemCheckStyled = styled.div`
    background-color: #fff;
    cursor: pointer;
    display: block;
    min-height: 1.5rem;
    transition-duration: 0.4s;

    &:hover {
        background-color: #ededef;

        & > * {
            background-color: #ededef;
        }
    }
`;
export const SelectItemCheckText = styled.span`
    font-size: 17px;
    line-height: calc(24 / 17);
`;
export const SelectItemAllStyled = styled(SelectItemCheckStyled)`
    border-bottom: 1px solid #e0e0e0;
`;

export const SelectItemGroupText = styled.div`
    padding: 12px 16px;
    font-weight: 500;
    color: var(--secondary);
`;

export const SelectItemGroupStyled = styled.div`
    ${css`
        input + div {
            padding-left: 1.5rem;
        }
    `}
`;
