import {axiosInstance} from 'shared/api/api';
import {JobAttachmentResponseType, NriRllFileType} from 'shared/types/jobsTypes';
import {EAttachmentType} from '../types/FileTypes';
import {ITpiFileParams} from './types/paramsTypes';

export function fetchNriBsFile(projectId: string) {
    return axiosInstance.get(`/job_tasks/nri_bs_file`, {
        params: {
            project_id: projectId,
        },
    });
}

export function fetchNriRllList(projectId: string) {
    return axiosInstance.get<NriRllFileType[]>(`/job_tasks/nri_rll`, {
        params: {
            project_id: projectId,
        },
    });
}

export function fetchNriRllFile(id: number) {
    return axiosInstance.get<Blob>(`/job_tasks/nri_rll_file`, {
        params: {
            id: id,
        },
        responseType: 'blob',
        timeout: 200000,
    });
}

export function postJobAttachment(
    file: File,
    oldFileId?: string,
    fileName?: string,
    fileType?: string,
) {
    const formSent = new FormData();
    formSent.append(oldFileId ?? 'uploadForm_File', new Blob([file]), fileName);
    return axiosInstance.post<JobAttachmentResponseType>(`/upload_files/`, formSent, {
        headers: {'content-type': 'multipart/form-data'},
        params: fileType
            ? {
                  file_type: fileType,
              }
            : undefined,
    });
}

export function deleteAttachment(id: string) {
    return axiosInstance.delete(`/delete_file/${id}/`);
}

export const fetchFileWithValidation = (fileId: string, type: EAttachmentType) => {
    return axiosInstance.get(`/download_files/${fileId}/`, {
        params: {
            type: type,
        },
        responseType: 'blob',
        timeout: 200000,
    });
};

export const fetchTpiFile = (params: ITpiFileParams) => {
    return axiosInstance.get(`contracts/tpis/tpi_specifications/list/load_file/`, {
        params: params,
        responseType: 'blob',
        timeout: 200000,
    });
};
