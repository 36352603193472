import {VirList} from 'pages/VirSelect/ui/TpiRow/style';
import {Table, TableCell, TableHead, TableString} from 'shared/ui/Table/Table';
import Checkbox from 'shared/ui/deprecated/Checkbox/Checkbox';
import VirSelectString from 'pages/VirSelect/ui/TpiRow/VirSelectInfoString';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {TabsBlock} from 'shared/ui/deprecated/Tabs/Tabs';
import {useGate, useStore} from 'effector-react';
import {
    $virSelectAllTpisTab,
    selectPoint,
    selectTpis,
    unselectAll,
    unselectPointGroup,
} from 'pages/VirSelect/model/SelectedTpisTab';
import {$activeTab} from 'pages/VirSelect/model/SelectTpisPage';
import {SELECT_TPIS_ACTIVE_TAB, TpiSpecification} from 'pages/VirSelect/types';
import {NotFound, NotFoundCaption, NotFoundTitle} from 'components/NotFound';
import Pagination from 'shared/ui/Pagination/Pagination';
import {
    $allTpisPaginationParams,
    AllTpisTabGate,
    setPage,
    setPageSize,
} from '../../model/AllTpisTab';
import {TableSkeleton} from '../TableSkeleton';

export function AllTpisTab() {
    useGate(AllTpisTabGate);
    const activeTab = useStore($activeTab);
    const {count, page, pageSize, loading, allTpis} = useStore($allTpisPaginationParams);
    const noData = count === 0;
    const {selectedTpis} = useStore($virSelectAllTpisTab);
    const allChecked =
        selectedTpis.length > 0 &&
        allTpis
            .filter((item) => item.type_position !== 1)
            .every((item) =>
                selectedTpis.some((el) => el.tpi_specification_id === item.tpi_specification_id),
            ) &&
        !loading;
    const isCheckedItem = (item: TpiSpecification) =>
        selectedTpis.some((el) => el.tpi_specification_id === item.tpi_specification_id);

    const isPartly =
        selectedTpis.length !== 0 &&
        !allChecked &&
        selectedTpis.some((item) =>
            allTpis.some((el) => el.tpi_specification_id === item.tpi_specification_id),
        ) &&
        !loading;

    function handleAllSelected() {
        if (allChecked) {
            unselectAll();
        } else {
            selectTpis(allTpis.filter((item) => item.type_position !== 1));
        }
    }

    function handleSelectPoint(item: TpiSpecification) {
        if (isCheckedItem(item)) {
            unselectPointGroup(item);
        } else {
            selectPoint(item);
        }
    }

    const heads = [
        {
            content: (
                <Checkbox
                    isPartly={isPartly}
                    id="allTpiItemsCheckbox"
                    onChange={handleAllSelected}
                    checked={allChecked}
                />
            ),
            width: '72px;',
        },
        {
            content: <>№ п.п</>,
            width: '80p;',
        },
        {
            content: <>Наименование работ</>,
            width: 'calc(100% - 619px);',
        },
        {
            content: <>Ед. измерения</>,
            width: '167px;',
        },
        {
            content: <>Цена за ед., ₽</>,
            width: '145px;',
        },
    ];

    return (
        <TabsBlock value={SELECT_TPIS_ACTIVE_TAB.ALL_TPIS} activeTab={activeTab}>
            {(loading || !noData) && (
                <VirList>
                    <Table>
                        <tbody>
                            <TableString>
                                {heads.map((head, idx) => (
                                    <TableCell key={idx} width={head.width}>
                                        <TableHead>{head.content}</TableHead>
                                    </TableCell>
                                ))}
                            </TableString>
                            {loading ? (
                                <TableSkeleton columns={heads} />
                            ) : (
                                allTpis.map((item, index, array) => (
                                    <VirSelectString
                                        pageHasNote={array.some((el) => el.note)}
                                        key={item.id}
                                        item={item}
                                        checked={isCheckedItem(item)}
                                        onSelected={() => handleSelectPoint(item)}
                                    />
                                ))
                            )}
                        </tbody>
                    </Table>{' '}
                    <WrapperOffset offsetY="13" offsetX="16">
                        <Pagination
                            total={count}
                            page={page}
                            pageSize={pageSize}
                            setPage={setPage}
                            setPageSize={setPageSize}
                        />
                    </WrapperOffset>
                </VirList>
            )}

            {noData && !loading && (
                <NotFound>
                    <NotFoundTitle>По вашему запросу ничего не найдено</NotFoundTitle>
                    <NotFoundCaption>
                        Попробуйте сократить запрос или задать его по-другому
                    </NotFoundCaption>
                </NotFound>
            )}
        </TabsBlock>
    );
}
