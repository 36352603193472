import React from 'react';
import {useStore} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import {ViewProjectMainInfoSkeleton} from 'Entities/ProjectMainInfo/ui/ViewProjectMainInfoSkeleton';
import {$isUserVK, $userStore} from 'shared/model/user';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import Row from 'shared/ui/deprecated/Row/Row';
import ProjectInfoCell from 'shared/ui/deprecated/ProjectInfoCell/ProjectInfoCell';

export const ViewProjectMainInfo = function ({data, isLoading}) {
    const {email} = useStore($userStore);
    const isVkUser = useStore($isUserVK);

    if (!data || isLoading) {
        return <ViewProjectMainInfoSkeleton />;
    }

    const {
        address,
        bs_name,
        bs_gfk,
        pos_code,
        types_project,
        bs_number,
        project_ext_id,
        ranges,
        years,
        prime_contractors,
    } = data;

    const userId = email.split('@')[0];

    return (
        <WrapperOffset offsetTop="36">
            <Typography variant="h5" className="mb-3">{`БС ${bs_number} — ${bs_name}`}</Typography>
            <Row gutterY="24" gutterX="16">
                <div className="col-4">
                    <Row gutterX="16">
                        <div className="col-6">
                            <ProjectInfoCell title="Код ГФК">{bs_gfk}</ProjectInfoCell>
                        </div>
                        <div className="col-6">
                            <ProjectInfoCell title="Номер позиции">{pos_code}</ProjectInfoCell>
                        </div>
                    </Row>
                </div>
                <div className="col-3">
                    <Row gutterX="16">
                        <div className="col-8">
                            <ProjectInfoCell title="Тип проекта">{types_project}</ProjectInfoCell>
                        </div>
                        <div className="col-4">
                            <ProjectInfoCell title="Год ПРС">{years.join(', ')}</ProjectInfoCell>
                        </div>
                    </Row>
                </div>
                <div className="col-5">
                    <Row gutterX="16">
                        <div className="col-12">
                            <ProjectInfoCell title="Основной ГПО">
                                <WrapperFlex justifyContent="space-between" className="w-100">
                                    {prime_contractors}
                                    {/* <Link to="#">Смотреть всех (6)</Link> */}
                                    {/* <Link to="#">Смотреть всех ({countGPO})</Link> */}
                                </WrapperFlex>
                            </ProjectInfoCell>
                        </div>
                    </Row>
                </div>
                <div className="col-4">
                    <Row gutterX="16">
                        <div className="col-12">
                            <ProjectInfoCell title="Адрес">{address}</ProjectInfoCell>
                        </div>
                    </Row>
                </div>
                <div className="col-3">
                    <Row gutterX="16">
                        {isVkUser && (
                            <div className="col-12">
                                <ProjectInfoCell title="Подробнее в NRI">
                                    <div className="d-flex">
                                        <a
                                            className="me-3"
                                            href={`http://nri-application.vimpelcom.ru/PlanGraphicWeb/plantc?idlprj=${project_ext_id}&form_type=1`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Проект
                                        </a>
                                        <a
                                            className="me-3"
                                            href={`http://nri-application.vimpelcom.ru/terrabyte/api/viewDocuments?uid=${userId}&type=prjbs&id=${project_ext_id}&availdoctype=`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Документы
                                        </a>
                                    </div>
                                </ProjectInfoCell>
                            </div>
                        )}
                    </Row>
                </div>
                <div className="col-5">
                    <Row gutterX="16">
                        <div className="col-12">
                            <ProjectInfoCell title="Диапазоны">{ranges.join('/')}</ProjectInfoCell>
                        </div>
                    </Row>
                </div>
            </Row>
        </WrapperOffset>
    );
};
