import React from 'react';
import {useStore} from 'effector-react';

import Select, {SelectItem} from 'shared/ui/Select/Select';
import {$createVirStartPageData, $createVirStartPageOptions, selectTpi} from 'pages/VirStart/model';

import * as S from './styled';

export function TpiSelect() {
    const {tpiOptions, formErrors, loadingTpis} = useStore($createVirStartPageOptions);
    const {selectedTpi, selectedContract} = useStore($createVirStartPageData);
    return (
        <S.SelectWrapper>
            <Select
                value={selectedTpi}
                valueLabel="tpis_name"
                handleEdit={selectTpi}
                title="ТЦП"
                selectDisabled={!selectedContract || loadingTpis}
                error={formErrors.tpi}
                hint={formErrors?.tpi ? 'Укажите тип работ' : ''}
                id="AgreementConstructorTpiSelect"
                fullWidth
            >
                {tpiOptions.map((item, index) => (
                    <SelectItem
                        multiple={false}
                        selected={selectedTpi}
                        key={`${item.tpis_id}_${index}`}
                        value={item}
                        id={item.tpis_name}
                    >
                        {`ЗМ ${item.purchasing_event_number} ${item.tpis_name}`}
                    </SelectItem>
                ))}
            </Select>
        </S.SelectWrapper>
    );
}
