import styled, {css} from 'styled-components';
import {Button} from '@beeline/design-system-react';

type PositionType = 'left' | 'right';

export const ButtonGroupContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 81px;
    height: 40px;
`;

export const StyledButton = styled(Button)<{position: PositionType; selected?: boolean | null}>`
    ${(props) =>
        props.position === 'left' &&
        css`
            border-radius: 12px 0 0 12px;
            border-right: none;
        `}
    ${(props) =>
        props.position === 'right' &&
        css`
            border-radius: 0 12px 12px 0;
            border-left: none;
            ${props.selected === false &&
            css`
                background-color: var(--color-background-base-activated);
            `}
        `}
  ${(props) =>
        props.position === 'left' &&
        props.selected === true &&
        css`
            background-color: var(--color-background-base-activated);
        `}
  ${(props) =>
        props.position === 'right' &&
        props.selected === false &&
        css`
            background-color: var(--color-background-base-activated);
        `}
`;

export const VerticalDivider = styled.div`
    height: 100%;
    border-right: 1px solid var(--color-divider);
`;
