import React from 'react';
import {useParams} from 'react-router-dom';
import {useGate} from 'effector-react';

import {CommonLayout} from 'shared/ui/CommonLayout';
import {Content} from 'pages/ProjectPage/style';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

import {ContractViewHeader} from './ContractViewHeader';
import {ContractViewInfoUnit} from './ContractViewInfoUnit';
import {ContractViewTable} from './ContractViewTable';

import {ContractViewPageGate} from '../model';

export const ContractViewPage = () => {
    const {id} = useParams();
    useGate(ContractViewPageGate, id);
    return (
        <CommonLayout>
            <Content>
                <WrapperFlex padding="32px" flexDirection="column" gap="24px" width="100%">
                    <ContractViewHeader />
                    <ContractViewInfoUnit />
                    <ContractViewTable />
                </WrapperFlex>
            </Content>
        </CommonLayout>
    );
};
