import {useState} from 'react';
import MoreVertIcon from 'icons/MoreVert';
import styled from 'styled-components/macro';

import {TableCell, TableCellContent} from 'shared/ui/Table/Table';
import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import ButtonList from 'shared/ui/deprecated/ButtonList/ButtonList';
import {
    DELETE_BUTTON_TITLE,
    PROHIBITED_SPEC_DELETION_HINT,
    DUPLICATE_BUTTON_TITLE,
} from 'shared/constants';

interface ActionsCellProps {
    handleDuplicateSpecification: () => void;
    handleDeleteSpecification?: () => void;
}

export function ActionsCell(props: ActionsCellProps) {
    const {handleDeleteSpecification, handleDuplicateSpecification} = props;
    const [open, setOpen] = useState(false);

    const deletionProhibited = !handleDeleteSpecification;
    const list = [
        {
            item: <>{DUPLICATE_BUTTON_TITLE}</>,
            action: handleDuplicateSpecification,
        },
        {
            item: (
                <TooltipedSpan
                    title={PROHIBITED_SPEC_DELETION_HINT}
                    disableHover={!deletionProhibited}
                    lineCount={1}
                >
                    {DELETE_BUTTON_TITLE}
                </TooltipedSpan>
            ),
            action: handleDeleteSpecification,
            disabled: deletionProhibited,
        },
    ];

    return (
        <TableCell width="56px">
            <TableCellContent>
                <StyledButtonList
                    active={open}
                    onClick={() => setOpen(false)}
                    width="80px"
                    color="outline"
                    icon={<MoreVertIcon />}
                    list={list}
                />
            </TableCellContent>
        </TableCell>
    );
}

export const StyledButtonList = styled(ButtonList)`
    button {
        background: none;
        border: none;
        padding: 0;
        border-radius: 3px;

        &:hover,
        &:focus {
            background: none;
        }
    }

    & > div {
        z-index: 4;
        left: -134px;
        top: 24px;
    }
`;
