import styled from 'styled-components/macro';

export const TableWrapper = styled.div`
    width: 100%;
    max-width: 1376px;
    min-width: 848px;
    overflow-x: auto;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    margin-bottom: 8px;

    table {
        table-layout: fixed;
    }
`;
