import {useGate, useStore} from 'effector-react';

import {NotFound, NotFoundCaption, NotFoundTitle} from 'components/NotFound';
import {$activeTab} from 'pages/ApplyCoefficientsPage/model';
import {ICoefficient, COEFFICIENTS_ACTIVE_TAB} from 'pages/ApplyCoefficientsPage/types';
import {Table, TableCell, TableHead, TableString} from 'shared/ui/Table/Table';
import {CoefficientRow} from 'pages/ApplyCoefficientsPage/ui/shared/CoefficientRow';
import {TabsBlock} from 'shared/ui/deprecated/Tabs/Tabs';

import * as S from './style';
import {CoefficientsSkeleton} from '../CoefficientsSkeleton';
import {
    $decreasingCoefficientTab,
    ApplyDecreasingCoefficientsGate,
    toggleDecreasingSelect,
} from '../../model/decreasingCoefficientsTab';

export function DecreasingCoefficientsTab() {
    useGate(ApplyDecreasingCoefficientsGate);
    const {decreasingCoefficients, selectedDecreasingCoefficients, loading} =
        useStore($decreasingCoefficientTab);
    const activeTab = useStore($activeTab);
    const value = COEFFICIENTS_ACTIVE_TAB.DECREASING;

    const isChecked = (item: ICoefficient) =>
        selectedDecreasingCoefficients.some((el) => el.id === item.id);

    return (
        <TabsBlock value={value} activeTab={activeTab}>
            {!decreasingCoefficients.length && !loading ? (
                <NotFound>
                    <NotFoundTitle>По вашему запросу ничего не найдено</NotFoundTitle>
                    <NotFoundCaption>
                        Попробуйте сократить запрос или задать его по-другому
                    </NotFoundCaption>
                </NotFound>
            ) : (
                <S.Wrapper>
                    <Table>
                        <thead>
                            <TableString>
                                <TableCell width="56px" />
                                <TableCell width="80px">
                                    <TableHead>№ п.п</TableHead>
                                </TableCell>
                                <TableCell width="calc(100% - 589px)">
                                    <TableHead>Наименование коэф-та</TableHead>
                                </TableCell>
                                <TableCell width="167px">
                                    <TableHead>Значение</TableHead>
                                </TableCell>
                            </TableString>
                        </thead>
                        <tbody>
                            {loading ? (
                                <CoefficientsSkeleton pageSize={10} />
                            ) : (
                                decreasingCoefficients.map((coefficient) => (
                                    <CoefficientRow
                                        key={coefficient.id}
                                        item={coefficient}
                                        checked={isChecked(coefficient)}
                                        onSelected={() => toggleDecreasingSelect(coefficient)}
                                    />
                                ))
                            )}
                        </tbody>
                    </Table>
                </S.Wrapper>
            )}
        </TabsBlock>
    );
}
