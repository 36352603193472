import {ResponseType} from 'pages/VirPatternsPage/types';
import {TContractListBackendParams} from 'shared/services/types/paramsTypes';
import {axiosInstance} from 'shared/api/api';
import {IContract, IContractListItem} from 'shared/types/contractsTypes';

export const fetchContractList = (params: TContractListBackendParams) => {
    return axiosInstance.get<ResponseType<IContractListItem>>('/contracts/contracts/', {params});
};

export const fetchContract = (id: string) => {
    return axiosInstance.get<IContract>(`/contracts/contracts/${id}/`);
};
