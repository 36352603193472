import {combine, createDomain, forward, sample} from 'effector';
import {createGate} from 'effector-react';
import {isEqual} from 'lodash';
import {Option} from '@beeline/design-system-react';

import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';
import {$userStore, setColumnSettings} from 'shared/model/user';
import {fetchProcesses, fetchTasks} from 'shared/services/processes.service';
import {fetchDocumentTypeList} from 'shared/services/documents.service';
import {fetchLifeCycleStatusList} from 'shared/services/lifeCycle.service';
import {fetchTaskStatusList} from 'shared/services/tasks.service';
import {fetchMainContractorsList} from 'shared/services/organizations.service';
import {
    getProjectPageSelectBranch,
    getProjectPageSelectRegion,
} from 'shared/services/projects.service';
import {
    getInitialProcessesColumns,
    getInitialProcessesPageData,
    getInitialTasksColumns,
} from 'shared/model/user/helpers';
import {IDetailedTask, TListedProcess} from 'shared/types/processesTypes';
import {ITerritorialOption} from 'shared/types/regionSelectTypes';
import {IVisibleColumn} from 'shared/types/tableTypes';
import {TExtendedProcessesPage} from 'shared/types/userTypes';
import {TTaskParams} from 'shared/services/types/taskParamsTypes';

import {transformDateRangeToString} from './helpers';
import {documentDataTransferred} from '../../GroupSignPage/model';

export const ProcessesPageDomain = createDomain();
export const ProcessesPageGate = createGate();

// events
export const changePage = ProcessesPageDomain.createEvent<number>();
export const changePageSize = ProcessesPageDomain.createEvent<number>();
export const toggleGroupTableByProcesses = ProcessesPageDomain.createEvent<boolean>();
export const toggleUserTaskSwitcher = ProcessesPageDomain.createEvent<boolean>();
export const toggleCompletedTaskSwitcher = ProcessesPageDomain.createEvent<boolean>();
export const toggleToSignTaskSwitcher = ProcessesPageDomain.createEvent<boolean>();
export const toggleExecutorTaskSwitcher = ProcessesPageDomain.createEvent<boolean>();
export const changeSelectedRegionArray = ProcessesPageDomain.createEvent<string>();
export const changeSelectedBranchArray = ProcessesPageDomain.createEvent<string>();
export const changeSearchValue = ProcessesPageDomain.createEvent<string>();
export const toggleFilterSidesheet = ProcessesPageDomain.createEvent();
export const changeTableSort = ProcessesPageDomain.createEvent<string>();
export const setOpenId = ProcessesPageDomain.createEvent<string>();
export const setSelectedDocumentTypeList = ProcessesPageDomain.createEvent<Option<string>[]>();
export const setSelectedDocumentStatusList = ProcessesPageDomain.createEvent<Option<string>[]>();
export const setSelectedTaskStatusList = ProcessesPageDomain.createEvent<Option<string>[]>();
export const setSelectedMainContractorsList = ProcessesPageDomain.createEvent<Option<string>[]>();
export const setSelectedCreationDateRange = ProcessesPageDomain.createEvent<string[]>();
export const setSelectedCompletionDateRange = ProcessesPageDomain.createEvent<string[]>();
export const setProcessesColumns = ProcessesPageDomain.createEvent<IVisibleColumn>();
export const setTasksColumns = ProcessesPageDomain.createEvent<IVisibleColumn>();
export const saveFilters = ProcessesPageDomain.createEvent();
export const resetFilters = ProcessesPageDomain.createEvent();
export const resetAllData = ProcessesPageDomain.createEvent();
export const setSelectedRegionData = ProcessesPageDomain.createEvent<ITerritorialOption[]>();
export const setSelectedBranchData = ProcessesPageDomain.createEvent<ITerritorialOption[]>();
export const resetRegionData = ProcessesPageDomain.createEvent();
export const resetBranchData = ProcessesPageDomain.createEvent();
export const resetSelectedBranchData = ProcessesPageDomain.createEvent();
export const resetAllSelectedData = ProcessesPageDomain.createEvent();
export const resetDocumentStatusSelect = ProcessesPageDomain.createEvent();
export const signButtonClicked = ProcessesPageDomain.createEvent<() => void>();

// effects
export const getTasksFx = ProcessesPageDomain.createEffect(async (params: TTaskParams) => {
    const finalParams = {...params};
    delete finalParams.groupByProcesses;
    const result = await fetchTasks(finalParams);
    return result.data;
});

export const getProcessesFx = ProcessesPageDomain.createEffect(async (params: TTaskParams) => {
    const finalParams = {...params};
    delete finalParams.groupByProcesses;
    const result = await fetchProcesses(finalParams);
    return result.data;
});

export const getDocumentTypeListFx = ProcessesPageDomain.createEffect(async () => {
    const result = await fetchDocumentTypeList();
    return result.data.map((item) => ({id: item.id, value: item.name}));
});

export const getLifeCycleStatusListFx = ProcessesPageDomain.createEffect(
    async (selectedSubtypes: string) => {
        const result = await fetchLifeCycleStatusList(selectedSubtypes);
        return result.data.map((item) => ({id: item.code, value: item.name}));
    },
);

export const getTaskStatusListFx = ProcessesPageDomain.createEffect(async () => {
    const result = await fetchTaskStatusList();
    return result.data.map((item) => ({id: `${item.code}`, value: item.name}));
});

export const getMainContractorsListFx = ProcessesPageDomain.createEffect(async () => {
    const result = await fetchMainContractorsList();
    return result.data.map((item) => ({id: item.id, value: item.short_name}));
});

export const getRegionDataFx = ProcessesPageDomain.createEffect(async () => {
    try {
        const result = await getProjectPageSelectRegion();
        return result.data.map(
            (item) =>
                ({
                    id: item.id,
                    value: item.name,
                    externalCode: item.external_code,
                } as ITerritorialOption),
        );
    } catch (e) {
        console.error(e);
    }
});

export const getBranchDataFx = ProcessesPageDomain.createEffect(async () => {
    try {
        const result = await getProjectPageSelectBranch('');
        return result.data.map(
            (item) =>
                ({
                    id: item.id,
                    value: item.name,
                    externalCode: item.regions_external_code.toString(),
                } as ITerritorialOption),
        );
    } catch (e) {
        console.error(e);
    }
});

const redirectUserFx = ProcessesPageDomain.createEffect((func) => {
    func();
});

// stores
export const $tasksStore = ProcessesPageDomain.createStore<IDetailedTask[] | null>(null).on(
    getTasksFx.doneData,
    (_, {results}) => results,
);
export const $processesListStore = ProcessesPageDomain.createStore<TListedProcess[] | null>(
    null,
).on(getProcessesFx.doneData, (_, {results}) => results);

export const $processesColumnsStore = ProcessesPageDomain.createStore<IVisibleColumn[]>([]).on(
    resetFilters,
    () => getInitialProcessesColumns(),
);
export const $tasksColumnsStore = ProcessesPageDomain.createStore<IVisibleColumn[]>([]).on(
    resetFilters,
    () => getInitialTasksColumns(),
);

export const $taskCount = ProcessesPageDomain.createStore<number>(0).on(
    [getTasksFx.doneData, getProcessesFx.doneData],
    (_, payload) => payload.count,
);

export const $filterOpened = ProcessesPageDomain.createStore<boolean>(false).on(
    toggleFilterSidesheet,
    (state, _) => !state,
);

const $openId = ProcessesPageDomain.createStore<string>('').on(setOpenId, (state, payload) =>
    payload === state ? '' : payload,
);

export const $documentTypeList = ProcessesPageDomain.createStore<Option<string>[]>([]).on(
    getDocumentTypeListFx.doneData,
    forwardPayload(),
);

export const $selectedDocumentTypeList = ProcessesPageDomain.createStore<Option<string>[]>([])
    .on(setSelectedDocumentTypeList, forwardPayload())
    .reset(resetFilters);

export const $statusList = ProcessesPageDomain.createStore<Option<string>[]>([])
    .on(getLifeCycleStatusListFx.doneData, forwardPayload())
    .reset(resetDocumentStatusSelect);

export const $selectedStatusList = ProcessesPageDomain.createStore<Option<string>[]>([])
    .on(setSelectedDocumentStatusList, forwardPayload())
    .reset([resetFilters, resetDocumentStatusSelect]);

export const $taskStatusList = ProcessesPageDomain.createStore<Option<string>[]>([]).on(
    getTaskStatusListFx.doneData,
    forwardPayload(),
);

export const $selectedTaskStatusList = ProcessesPageDomain.createStore<Option<string>[]>([])
    .on(setSelectedTaskStatusList, forwardPayload())
    .reset(resetFilters);

export const $mainContractorsList = ProcessesPageDomain.createStore<Option<string>[]>([]).on(
    getMainContractorsListFx.doneData,
    forwardPayload(),
);

export const $selectedMainContractorsList = ProcessesPageDomain.createStore<Option<string>[]>([])
    .on(setSelectedMainContractorsList, forwardPayload())
    .reset(resetFilters);

export const $selectedCreationDateRange = ProcessesPageDomain.createStore<string[]>([])
    .on(setSelectedCreationDateRange, forwardPayload())
    .reset(resetFilters);

export const $selectedCompletionDateRange = ProcessesPageDomain.createStore<string[]>([])
    .on(setSelectedCompletionDateRange, forwardPayload())
    .reset(resetFilters);

export const $appliedFiltersCount = ProcessesPageDomain.createStore<number>(0);

export const $isLoading = ProcessesPageDomain.createStore<boolean>(false).on(
    [getTasksFx.pending, getProcessesFx.pending],
    forwardPayload(),
);

export const $availableRegionData = ProcessesPageDomain.createStore<ITerritorialOption[]>([])
    .on(getRegionDataFx.doneData, forwardPayload())
    .reset(resetRegionData);

export const $selectedRegionData = ProcessesPageDomain.createStore<ITerritorialOption[]>([])
    .on(setSelectedRegionData, forwardPayload())
    .reset(resetAllSelectedData);

export const $branchData = ProcessesPageDomain.createStore<ITerritorialOption[]>([])
    .on(getBranchDataFx.doneData, forwardPayload())
    .reset(resetBranchData);

export const $availableBranchData = ProcessesPageDomain.createStore<ITerritorialOption[]>([]).on(
    getBranchDataFx.doneData,
    (state, payload) => payload?.sort((a, b) => a.value.localeCompare(b.value)),
);

export const $selectedBranchData = ProcessesPageDomain.createStore<ITerritorialOption[]>([])
    .on(setSelectedBranchData, forwardPayload())
    .reset([resetSelectedBranchData, resetAllSelectedData]);

export const $filtersStore = combine({
    documentTypeList: $documentTypeList,
    selectedDocumentTypeList: $selectedDocumentTypeList,
    statusList: $statusList,
    selectedStatusList: $selectedStatusList,
    statusSelectDisabled: $selectedDocumentTypeList.map((state) => state.length === 0),
    mainContractorsList: $mainContractorsList,
    selectedMainContractorsList: $selectedMainContractorsList,
    taskStatusList: $taskStatusList,
    selectedTaskStatusList: $selectedTaskStatusList,
    selectedCreationDateRange: $selectedCreationDateRange,
    selectedCompletionDateRange: $selectedCompletionDateRange,
});

export const $columnsStore = combine({
    tasksColumnsStore: $tasksColumnsStore,
    processesColumnsStore: $processesColumnsStore,
});

export const $taskParamsStore = ProcessesPageDomain.createStore<TExtendedProcessesPage>(
    getInitialProcessesPageData(),
);

const $paramsAreLoaded = ProcessesPageDomain.createStore<boolean>(false);

export const $ProcessesPageStore = combine({
    tasksStore: $tasksStore,
    processesStore: $processesListStore,
    taskCount: $taskCount,
    taskParams: $taskParamsStore,
    isLoading: $isLoading,
    sidesheetOpened: $filterOpened,
    filtersStore: $filtersStore,
    openId: $openId,
    appliedFiltersCount: $appliedFiltersCount,
    signedButtonDisabled: $tasksStore.map((store) => (store?.length ?? 0) === 0),
});

export const $processesTerritorialStore = combine({
    regionData: $availableRegionData,
    selectedRegionData: $selectedRegionData,
    branchData: $availableBranchData,
    selectedBranchData: $selectedBranchData,
});

// гидрация параметров с бэка (загрузка страницы + изменения)
sample({
    clock: [$userStore, ProcessesPageGate.open],
    source: combine({
        params: $taskParamsStore,
        store: $userStore,
        gate: ProcessesPageGate.status,
    }),
    filter: ({params, gate, store}) => gate && !isEqual(params, store.extend.processesPage),
    fn: ({store, params}) => {
        const result = {
            filters: {...store.extend.processesPage.filters},
            tasksColumns: store.extend.processesPage.tasksColumns.map((item) => ({...item})),
            processesColumns: store.extend.processesPage.processesColumns.map((item) => ({
                ...item,
            })),
        };
        result.filters.search = params.filters.search;
        result.filters.page = params.filters.page;

        return result;
    },
    target: $taskParamsStore,
});

// индикатор загрузки параметров после гидрации с бэка
sample({
    clock: $taskParamsStore.updates,
    fn: () => true,
    target: $paramsAreLoaded,
});

sample({
    clock: $taskParamsStore,
    source: combine({
        source: $taskParamsStore,
        gate: ProcessesPageGate.status,
        isLoaded: $paramsAreLoaded,
    }),
    filter: ({source, gate, isLoaded}) =>
        isLoaded && gate && source.filters.groupByProcesses === false,
    fn: ({source}) => source.filters,
    target: getTasksFx,
});

sample({
    clock: $taskParamsStore,
    source: combine({
        source: $taskParamsStore,
        gate: ProcessesPageGate.status,
        isLoaded: $paramsAreLoaded,
    }),
    filter: ({source, gate, isLoaded}) =>
        isLoaded && gate && source.filters.groupByProcesses === true,
    fn: ({source}) => source.filters,
    target: getProcessesFx,
});

$taskParamsStore
    .on(changePage, (state, payload) => ({
        ...state,
        filters: {
            ...state.filters,
            page: payload,
        },
    }))
    .on(changePageSize, (state, payload) => ({
        ...state,
        filters: {
            ...state.filters,
            page_size: payload,
            page: 1,
        },
    }))
    .on(toggleGroupTableByProcesses, (state, payload) => ({
        ...state,
        filters: {
            ...state.filters,
            groupByProcesses: payload,
            signed: false,
            page: 1,
            page_size: 10,
        },
    }))
    .on(toggleCompletedTaskSwitcher, (state, payload) => ({
        ...state,
        filters: {
            ...state.filters,
            uncompleted_tasks: payload,
            page: 1,
        },
    }))
    .on(toggleToSignTaskSwitcher, (state, payload) => ({
        ...state,
        filters: {
            ...state.filters,
            groupByProcesses: payload ? false : state.filters.groupByProcesses,
            executor_tasks: payload ? true : state.filters.executor_tasks,
            uncompleted_tasks: payload ? true : state.filters.executor_tasks,
            signed: payload,
            page: 1,
            page_size: payload ? 30 : 10,
        },
    }))
    .on(toggleExecutorTaskSwitcher, (state, payload) => ({
        ...state,
        filters: {
            ...state.filters,
            executor_tasks: payload,
            page: 1,
        },
    }))
    .on(toggleUserTaskSwitcher, (state, payload) => ({
        ...state,
        filters: {
            ...state.filters,
            user_tasks: payload,
            page: 1,
        },
    }))
    .on(changeSelectedBranchArray, (state, payload) => {
        if (payload === state.filters.branch) return undefined;
        return {
            ...state,
            filters: {
                ...state.filters,
                branch: payload,
                page: 1,
            },
        };
    })
    .on(changeSelectedRegionArray, (state, payload) => {
        if (payload === state.filters.region) return undefined;
        return {
            ...state,
            filters: {
                ...state.filters,
                region: payload,
                page: 1,
            },
        };
    })
    .on(changeSearchValue, (state, payload) => {
        if (payload === state.filters.search) return undefined;
        return {...state, filters: {...state.filters, search: payload, page: 1}};
    })
    .on(changeTableSort, (state, payload) => ({
        ...state,
        filters: {
            ...state.filters,
            page: 1,
            ordering:
                state.filters.ordering === payload
                    ? `-${payload}`
                    : state.filters.ordering === `-${payload}`
                    ? ''
                    : payload,
        },
    }))
    .on(setSelectedRegionData, (state, payload) => {
        const region = payload.map((item) => item.id).join(',');
        return {...state, filters: {...state.filters, page: 1, region: region}};
    })
    .on(setSelectedBranchData, (state, payload) => {
        const branch = payload.map((item) => item.id).join(',');
        return {...state, filters: {...state.filters, page: 1, branch: branch}};
    })
    .on(resetSelectedBranchData, (state) => {
        return {...state, filters: {...state.filters, page: 1, branch: undefined}};
    });

// запись фильтров на бэк
sample({
    clock: $taskParamsStore,
    source: combine({source: $userStore, gate: ProcessesPageGate.status}),
    filter: ({gate}) => {
        return gate;
    },
    fn: ({source}, clock) => {
        const payload = {
            filters: {...clock.filters},
            tasksColumns: [...clock.tasksColumns],
            processesColumns: [...clock.processesColumns],
        };
        payload.filters.search = '';
        return {
            projectsPage: {...source.extend.projectsPage},
            processesPage: {
                filters: {...payload.filters},
                tasksColumns: [...payload.tasksColumns],
                processesColumns: [...payload.processesColumns],
            },
            contractsPage: {...source.extend.contractsPage},
        };
    },
    target: setColumnSettings,
});

sample({
    clock: resetAllData,
    source: $taskParamsStore,
    fn: (source) => {
        const result = getInitialProcessesPageData();
        result.filters.page_size = source.filters.page_size;
        return result;
    },
    target: $taskParamsStore,
});

// гидрация селекта "Тип документа" с бэка
sample({
    source: combine({
        store: $taskParamsStore,
        documentList: $documentTypeList,
    }),
    filter: ({store}) => !!store.filters.subtypes_document,
    fn: ({store, documentList}) => {
        const selectedList =
            !!store.filters.subtypes_document && store.filters.subtypes_document !== ''
                ? store.filters.subtypes_document.split(',')
                : [];
        return documentList.filter((item) =>
            selectedList.some((selectedItem) => item.id === selectedItem),
        );
    },
    target: $selectedDocumentTypeList,
});

// при изменении Region, фильтрует доступные для выбора филиалы
sample({
    clock: [$selectedRegionData, $branchData],
    source: combine({
        selectedRegionData: $selectedRegionData,
        branchData: $branchData,
    }),
    fn: ({branchData, selectedRegionData}) => {
        if (selectedRegionData.length > 0) {
            const selectedRegionCodes = selectedRegionData.map((item) => item.externalCode);
            return branchData.filter((item) => selectedRegionCodes.includes(item.externalCode));
        } else {
            return branchData;
        }
    },
    target: $availableBranchData,
});

// гидрация селекта "регион" с бэка
sample({
    source: combine({
        store: $taskParamsStore,
        regionData: $availableRegionData,
    }),
    filter: ({store}) => !!store.filters.region && store.filters.region !== '',
    fn: ({store, regionData}) => {
        const defaultIdList = store.filters.region?.split(',') ?? [];
        return regionData.filter((item) => defaultIdList.includes(item.id.toString()));
    },
    target: $selectedRegionData,
});

// гидрация селекта "филиал" с бэка
sample({
    source: combine({
        store: $taskParamsStore,
        branchData: $availableBranchData,
    }),
    filter: ({store}) => !!store.filters.branch && store.filters.branch !== '',
    fn: ({store, branchData}) => {
        const defaultIdList = store.filters.branch?.split(',') ?? [];
        return branchData.filter((item) => defaultIdList.includes(item.id.toString()));
    },
    target: $selectedBranchData,
});

// гидрация селекта "Статус документа" с бэка
sample({
    source: combine({
        store: $taskParamsStore,
        statusList: $statusList,
    }),
    filter: ({store}) => !!store.filters.status_document,
    fn: ({store, statusList}) => {
        const selectedList =
            !!store.filters.status_document && store.filters.status_document !== ''
                ? store.filters.status_document.split(',')
                : [];
        return statusList.filter((item) =>
            selectedList.some((selectedItem) => item.id === selectedItem),
        );
    },
    target: $selectedStatusList,
});

// гидрация селекта "Статус задачи" с бэка
sample({
    source: combine({
        store: $taskParamsStore,
        taskStatusList: $taskStatusList,
    }),
    filter: ({store}) => !!store.filters.status,
    fn: ({store, taskStatusList}) => {
        const selectedList =
            !!store.filters.status && store.filters.status !== ''
                ? store.filters.status.split(',')
                : [];
        return taskStatusList.filter((item) =>
            selectedList.some((selectedItem) => item.id === selectedItem),
        );
    },
    target: $selectedTaskStatusList,
});

// внедрение селекта "ГПО" с бэка
sample({
    source: combine({
        store: $taskParamsStore,
        mainContractorsList: $mainContractorsList,
    }),
    filter: ({store}) => !!store.filters.gpo,
    fn: ({store, mainContractorsList}) => {
        const selectedList =
            !!store.filters.gpo && store.filters.gpo !== '' ? store.filters.gpo.split(',') : [];
        return mainContractorsList.filter((item) =>
            selectedList.some((selectedItem) => item.id === selectedItem),
        );
    },
    target: $selectedMainContractorsList,
});

// внедрение селекта "Дата создания" с бэка
sample({
    source: combine({
        store: $taskParamsStore,
    }),
    filter: ({store}) => !!store.filters.created_at,
    fn: ({store}) => {
        return !!store.filters.created_at && store.filters.created_at !== ''
            ? store.filters.created_at.split(',')
            : [];
    },
    target: $selectedCreationDateRange,
});

// внедрение селекта "Дата завершения" с бэка
sample({
    source: combine({
        store: $taskParamsStore,
    }),
    filter: ({store}) => !!store.filters.approved_at,
    fn: ({store}) => {
        return !!store.filters.approved_at && store.filters.approved_at !== ''
            ? store.filters.approved_at.split(',')
            : [];
    },
    target: $selectedCompletionDateRange,
});

// запись боковых фильтров на бэк
sample({
    clock: saveFilters,
    source: combine({
        store: $taskParamsStore,
        filters: $filtersStore,
        tasksColumnsStore: $tasksColumnsStore,
        processesColumnsStore: $processesColumnsStore,
    }),
    fn: ({store, filters, tasksColumnsStore, processesColumnsStore}) => {
        return {
            ...store,
            filters: {
                ...store.filters,
                page: 1,
                subtypes_document: filters.selectedDocumentTypeList
                    .map((item) => item.id)
                    .join(','),
                status_document: filters.selectedStatusList.map((item) => item.id).join(','),
                status: filters.selectedTaskStatusList.map((item) => item.id).join(','),
                gpo: filters.selectedMainContractorsList.map((item) => item.id).join(','),
                created_at: transformDateRangeToString(filters.selectedCreationDateRange),
                approved_at: transformDateRangeToString(filters.selectedCompletionDateRange),
            },
            tasksColumns: [...tasksColumnsStore],
            processesColumns: [...processesColumnsStore],
        };
    },
    target: $taskParamsStore,
});

// счетчик фильтров
sample({
    clock: $taskParamsStore,
    fn: (source) => {
        let result = 0;
        const {status, status_document, subtypes_document, gpo, created_at, approved_at} =
            source.filters;
        (status?.length ?? 0) > 0 && result++;
        (status_document?.length ?? 0) > 0 && result++;
        (subtypes_document?.length ?? 0) > 0 && result++;
        (gpo?.length ?? 0) > 0 && result++;
        (created_at?.length ?? 0) > 0 && result++;
        (approved_at?.length ?? 0) > 0 && result++;
        return result;
    },
    target: $appliedFiltersCount,
});

// гидрация колонок "процессы" с бэка
sample({
    source: $taskParamsStore,
    fn: (store) => [...store.processesColumns],
    target: $processesColumnsStore,
});

// гидрация колонок "задачи" с бэка
sample({
    source: $taskParamsStore,
    fn: (store) => [...store.tasksColumns],
    target: $tasksColumnsStore,
});

//
sample({
    clock: setProcessesColumns,
    source: $processesColumnsStore,
    fn: (store, clock) => {
        const result = [...store];
        const selectedColumn = result.find((item) => item.id === clock.id);
        if (selectedColumn) {
            selectedColumn.visible = !clock.visible;
        }
        return result;
    },
    target: $processesColumnsStore,
});

sample({
    clock: setTasksColumns,
    source: $tasksColumnsStore,
    fn: (store, clock) => {
        const result = [...store];
        const selectedColumn = result.find((item) => item.id === clock.id);
        if (selectedColumn) {
            selectedColumn.visible = !clock.visible;
        }
        return result;
    },
    target: $tasksColumnsStore,
});

forward({
    from: ProcessesPageGate.open,
    to: [
        getDocumentTypeListFx,
        getMainContractorsListFx,
        getTaskStatusListFx,
        getRegionDataFx,
        getBranchDataFx,
    ],
});

// вызов доступных статусов при выбранных типах документов
sample({
    source: $selectedDocumentTypeList,
    filter: (source) => source.length > 0,
    fn: (source) => source.map((item) => item.id).join(','),
    target: getLifeCycleStatusListFx,
});

// передача документов на страницу подписания
sample({
    clock: signButtonClicked,
    source: $tasksStore,
    filter: (store) => !!store,
    fn: (store) => store ?? [],
    target: documentDataTransferred,
});

// редирект пользователя на страницу подписания документов
sample({
    clock: signButtonClicked,
    source: $tasksStore,
    filter: (store) => !!store,
    fn: (_, func) => func,
    target: redirectUserFx,
});

//сброс селекта статус документа при изменении типа документа
forward({from: setSelectedDocumentTypeList, to: resetDocumentStatusSelect});

forward({
    from: resetAllData,
    to: [resetFilters, resetAllSelectedData],
});

forward({
    from: resetFilters,
    to: saveFilters,
});

// при изменении региона(oв), удаляет выбор ВСЕХ ранее выбранных филиалов региона(ов)
forward({from: setSelectedRegionData, to: resetSelectedBranchData});

resetDomainStoresByEvents(ProcessesPageDomain, ProcessesPageGate.close);
