import {ChangeEvent} from 'react';
import {Button, IconButton, Tooltip, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {formatDate} from 'shared/helpers/formatHelper';

import {FileRegistrationDateCalendar} from './FileRegistrationDateCalendar';
import {
    CALENDAR_BUTTON_TOOLTIP_BODY,
    FILE_ERROR_TEXT,
    REGISTRATION_DATE_FILE_ERROR_TEXT,
} from './constants';
import styles from './FileList.module.css';
import type {IFileListProps} from './FileList.types';
import {FILE_ICON_COMPONENTS} from '../config';
import {deleteFile, uploadFiles} from '../../store';
import {EFileUploadStatus} from '../../constants';

export const FileList = (props: IFileListProps): JSX.Element => {
    const {
        files = [],
        fileUploaderDispatch,
        isFilesDeleteAvailable,
        mode,
        withRegistrationDate,
        formTouched,
        onChangeFile,
        onChangeRegistrationDate,
    } = props;

    return (
        <div className={styles.container}>
            {files.map((file) => {
                const {status, name, fileLink, registration_at} = file;
                const hasError = status === EFileUploadStatus.Error;
                const hasNoRegistrationDate =
                    withRegistrationDate &&
                    formTouched &&
                    status === EFileUploadStatus.Upload &&
                    !registration_at;
                const hasUploaded = status === EFileUploadStatus.Upload;
                const IconComponent = !hasNoRegistrationDate
                    ? FILE_ICON_COMPONENTS[status]
                    : FILE_ICON_COMPONENTS[EFileUploadStatus.Error];

                const handleDeleteFile = (): void => {
                    deleteFile({file, fileUploaderDispatch, mode});
                };

                const handleReloadFile = (): void => {
                    uploadFiles({files: {[name]: file}, fileUploaderDispatch});
                };
                const oldFile = file;
                const handleChangeFile = (event: ChangeEvent<HTMLInputElement>): void => {
                    onChangeFile(oldFile, event);
                };

                const handleChangeRegistrationDate = (date: string) => {
                    onChangeRegistrationDate(file, date);
                };

                const handleDeleteRegistrationDate = () => {
                    handleChangeRegistrationDate('');
                };

                return (
                    <div key={name} className={styles.file__row}>
                        <IconComponent
                            className={styles.file__image}
                            fileLink={fileLink}
                            alt={name}
                        />
                        <div className={styles.file__name}>
                            <Tooltip title={name}>
                                <Typography variant="body2">{name}</Typography>
                            </Tooltip>
                            {(hasError || hasNoRegistrationDate) && (
                                <Typography className={styles.file__error_text} variant="body3">
                                    {hasNoRegistrationDate
                                        ? REGISTRATION_DATE_FILE_ERROR_TEXT
                                        : FILE_ERROR_TEXT}
                                </Typography>
                            )}
                            {withRegistrationDate && (
                                <WrapperFlex alignItems="center" gap="6px">
                                    {registration_at && (
                                        <>
                                            <Typography
                                                variant="body3"
                                                className="inactiveText"
                                            >{`${CALENDAR_BUTTON_TOOLTIP_BODY}: ${formatDate(
                                                registration_at,
                                            )}`}</Typography>
                                            <IconButton
                                                iconName={Icons.Delete}
                                                onClick={handleDeleteRegistrationDate}
                                            />
                                        </>
                                    )}
                                </WrapperFlex>
                            )}
                        </div>

                        <div className={styles.file__actions}>
                            {hasError && (
                                <IconButton
                                    onClick={handleReloadFile}
                                    size={'large'}
                                    className={styles.file__close_icon}
                                    iconName={Icons.Refresh}
                                />
                            )}
                            {mode === 'editing' && (
                                <Button size="medium">
                                    <label htmlFor={`change${name}`}>
                                        <input
                                            value={[]}
                                            type={'file'}
                                            id={`change${name}`}
                                            className={styles.file_input}
                                            onChange={handleChangeFile}
                                        />
                                        Заменить
                                    </label>
                                </Button>
                            )}

                            {withRegistrationDate && hasUploaded && (
                                <FileRegistrationDateCalendar
                                    selectedValue={registration_at}
                                    onSave={handleChangeRegistrationDate}
                                />
                            )}
                            {(mode === 'creation' ||
                                mode === 'additionalAgreementCreation' ||
                                files.length > 1) && (
                                <IconButton
                                    className={styles.file__close_icon}
                                    iconName={Icons.Delete}
                                    size="large"
                                    onClick={handleDeleteFile}
                                />
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
