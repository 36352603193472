import {useEffect} from 'react';
import {useGate, useStore} from 'effector-react';
import {useNavigate, useParams} from 'react-router-dom';
import {Icons} from '@beeline/design-tokens/js/iconfont';
import {Banner, Progress, Typography} from '@beeline/design-system-react';

import {ConstructorAttachmentUnit} from 'widgets/ConstructorAttachmentUnit';
import {AgreementConstructorHeader} from 'pages/AddWorkObjects/ui/Header';
import {
    $constructorPage,
    ConstructorGate,
    resetAdditionalAgreementCreation,
    setAttachmentToVir,
    setCommentToAdditionalAgreement,
    submitCreateAdditionalAgreement,
    updatePrepayment,
} from 'processes/contructor';
import {BodyContent, MainWrapper} from 'components/PageWraps';
import Button from 'shared/ui/deprecated/Button/Button';
import {ProgressCircularInfinite} from 'shared/ui/deprecated/Progress/Progress';
import {ProgressCircularWrap} from 'shared/ui/deprecated/Progress/style';

import {VirAccordion} from './VirAccordion';
import {TopPanel} from './TopPanel/TopPanel';
import * as S from './style';
import {$selectedProject} from '../VirStart/model';

export function VirConstructor() {
    const {agreementId} = useParams();
    const navigate = useNavigate();
    useGate(ConstructorGate, {agreementId});
    const {
        agreementType,
        success,
        createdAgreementId,
        ds,
        agreementCreationDisabled,
        errorBannerShowed,
        loadingAgreement: loading,
        isAllowToSetPrepayment,
        agreementErrorObject,
    } = useStore($constructorPage);
    const selectedProject = useStore($selectedProject);
    const {id, agreement_attachments, agreement_comments, is_prepayment} = ds;
    useEffect(() => {
        if (ds.status && !ds.status.is_editable) {
            navigate('/additional-agreements/');
        }
    }, [ds.status]);
    const isNotReleased = !ds.is_released;
    const projectId = ds.project_id || selectedProject?.project_id;

    if (success && (createdAgreementId || agreementId) && projectId) {
        resetAdditionalAgreementCreation();
        if (agreementId) {
            navigate(`/additional-agreements/${agreementId}`);
        }
        if (createdAgreementId) {
            navigate(`/additional-agreements/${createdAgreementId}`);
        }
    }

    if (loading) {
        return (
            <S.ProgressWrapper>
                <Progress cycled shape="circle" size={104} strokeWidth={4} type="solo" value={75} />
            </S.ProgressWrapper>
        );
    }


    return (
        <>
            <S.GlobalStyle />
            <MainWrapper>
                <AgreementConstructorHeader agreement={ds} agreementType={agreementType} />
                {loading && (
                    <ProgressCircularWrap className="p-5">
                        <ProgressCircularInfinite />
                    </ProgressCircularWrap>
                )}
                {!loading && (
                    <BodyContent>
                        <S.VirContent>
                            <Typography variant="h4">Ведомости работ</Typography>
                            <TopPanel />
                            {errorBannerShowed && (
                                <Banner
                                    color="error"
                                    title={agreementErrorObject.allErrors()}
                                    iconName={Icons.InfoCircled}
                                />
                            )}
                            <VirAccordion />
                            <ConstructorAttachmentUnit
                                id={id}
                                attachments={agreement_attachments}
                                comment={agreement_comments?.[0]?.comment}
                                onChangeComment={setCommentToAdditionalAgreement}
                                is_prepayment={is_prepayment}
                                isAllowToSetPrepayment={isAllowToSetPrepayment}
                                onChangeAttachments={setAttachmentToVir}
                                onChangePrepayment={updatePrepayment}
                            />
                            <S.BtnWrap>
                                <Button
                                    color="outline"
                                    disabled={agreementCreationDisabled}
                                    onClick={() => {
                                        submitCreateAdditionalAgreement({
                                            isDraft: true,
                                        });
                                    }}
                                >
                                    Сохранить черновик
                                </Button>
                                {isNotReleased && (
                                    <Button
                                        color="contained"
                                        disabled={agreementCreationDisabled}
                                        onClick={() => {
                                            submitCreateAdditionalAgreement({
                                                isReleased: false,
                                            });
                                        }}
                                    >
                                        Отправить на согласование
                                    </Button>
                                )}
                            </S.BtnWrap>
                        </S.VirContent>
                    </BodyContent>
                )}
            </MainWrapper>
        </>
    );
}
