import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';

import {fetchWhiteList} from 'shared/services/catalogs.service';
import {IWhiteList} from 'shared/types/whiteListTypes';
import {resetDomainStoresByEvents} from 'shared/helpers/effector';

const BusinessRulesPageDomain = createDomain();
export const BusinessRulesPageGate = createGate();

// effects
const getWhiteListFx = BusinessRulesPageDomain.createEffect(async () => {
    try {
        const result = await fetchWhiteList();
        return result.data;
    } catch (e) {
        console.warn(e);
    }
});

//stores
const $whiteList = BusinessRulesPageDomain.createStore<IWhiteList | null>(null);
export const $businessRulesPageStore = combine({
    whiteList: $whiteList,
});

sample({
    clock: BusinessRulesPageGate.open,
    target: getWhiteListFx,
});

sample({
    clock: getWhiteListFx.doneData,
    fn: (data) => data ?? null,
    target: $whiteList,
});

resetDomainStoresByEvents(BusinessRulesPageDomain, BusinessRulesPageGate.close);
