import {combine, sample} from 'effector';
import {
    $createAdditionalAgreementData,
    $createdAgreementId,
    $success,
    $validConstructor,
    createAdditionalAgreementFx,
    submitCreateAdditionalAgreement,
    updateAdditionalAgreementFx,
} from '../stores';
import {transformAgreementToCreation} from '../../helpers';
import {AdditionalAgreementPageGate} from '../../../../pages/DSPage/model';

// Создание ДС
sample({
    clock: submitCreateAdditionalAgreement,
    source: combine({
        rawAgreement: $createAdditionalAgreementData,
        valid: $validConstructor,
    }),
    filter: ({valid, rawAgreement}) => valid && !rawAgreement.id,
    fn: ({rawAgreement}, {isReleased, isDraft}) =>
        transformAgreementToCreation({
            ...rawAgreement,
            is_released: isReleased || false,
            meta: isDraft ? 'save_draft' : 'send_to_approve',
        }),
    target: createAdditionalAgreementFx,
});

$success
    .on(createAdditionalAgreementFx.doneData, (_, {data: {agreement_id}}) => !!agreement_id)
    .on(updateAdditionalAgreementFx.doneData, (_, data) => data.status === 200)
    .reset(AdditionalAgreementPageGate.open);

$createdAgreementId
    .on(createAdditionalAgreementFx.doneData, (state, payload) => payload?.data?.agreement_id)
    .on(updateAdditionalAgreementFx.doneData, (state, payload) => payload?.data?.agreement_id)
    .reset(AdditionalAgreementPageGate.open);
