import {useStore} from 'effector-react';
import {v4 as uuidv4} from 'uuid';
import {Button, Icon} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {$virs} from 'processes/contructor/model/stores';

import * as S from './style';
import {WorkObjectSelect} from './WorkObject';
import {WorkTypeSelect} from './WorkTypeSelect';
import {StatementSelect} from './StatementSelector';
import {
    $addedWorkObjectPage,
    deleteRow,
    selectStatement,
    selectWorkObject,
    selectWorkType,
} from '../../model';

export function WorkObjectItems() {
    const {selectedWorkObjects, workObjectOptions, workTypes} = useStore($addedWorkObjectPage);
    const statements = useStore($virs);
    return (
        <S.Wrapper>
            {selectedWorkObjects.map((el) => {
                return (
                    <S.Item key={el.id}>
                        <WorkObjectSelect
                            // error={touchedForm && !el.workObject.ts && !emptyFields}
                            options={workObjectOptions}
                            selected={el.workObject}
                            onSelect={(option) => {
                                if (option && el.id) {
                                    selectWorkObject({
                                        ...option,
                                        id: el.id,
                                    });
                                }
                            }}
                        />
                        <WorkTypeSelect
                            // error={touchedForm && !el.workType && !emptyFields}
                            options={workTypes}
                            selected={el.workType}
                            onSelect={(option) => {
                                if (option && el.id) {
                                    selectWorkType({
                                        ...option,
                                        id: el.id,
                                    });
                                }
                            }}
                        />
                        <StatementSelect
                            selectedWorkObject={el.workObject}
                            options={[
                                ...statements,
                                {id: `front -${uuidv4()}`, name: 'Создать новую ведомость'},
                            ]}
                            selected={el.statement}
                            onSelect={(option) => {
                                if (option && el.id && option.id) {
                                    selectStatement({
                                        ...option,
                                        id: el.id,
                                        virId: option.id,
                                    });
                                }
                            }}
                        />
                        <Button
                            variant="outlined"
                            startIcon={<Icon iconName={Icons.Delete} />}
                            size="medium"
                            onClick={() => el.id && deleteRow(el.id)}
                        />
                    </S.Item>
                );
            })}
        </S.Wrapper>
    );
}
