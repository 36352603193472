import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';

import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';
import {
    fetchAvailableDocumentSubtypeList,
    postDocumentCreation,
    putDocumentEditing,
} from 'shared/services/documents.service';
import {TDocumentCreation, TDocumentEditing, TDocumentType} from 'shared/types/documentTypes';
import {postJobAttachment} from 'shared/services/files.service';
import {TFileDataList} from 'shared/ui/use-file-uploader/useFileUploader.types';

import {
    checkObjectValidity,
    IDocumentOption,
    IOutputUploadedFile,
    IPreparedOutputEditingData,
    mapFileUploaderFilesForEditing,
    mapFileUploaderFilesToOutput,
} from './helpers';

export const DocumentCreationGate = createGate<string>();
export const DocumentCreationDomain = createDomain();

export const changeCommentValue = DocumentCreationDomain.createEvent<string>();
export const changeSelectedDocumentType = DocumentCreationDomain.createEvent<IDocumentOption>();
export const resetSelectedDocumentType = DocumentCreationDomain.createEvent();
export const creationButtonClicked = DocumentCreationDomain.createEvent<{
    files: TFileDataList;
    isError: boolean;
    isWorkflowButtonClicked?: boolean;
}>();
export const editingButtonClicked = DocumentCreationDomain.createEvent<{
    initialState: TFileDataList;
    files: TFileDataList;
    isError: boolean;
    projId: string | undefined;
    documentId: string | undefined;
    isWorkflowButtonClicked: boolean | undefined;
}>();
export const createDocument = DocumentCreationDomain.createEvent<IOutputUploadedFile[]>();
export const editDocument = DocumentCreationDomain.createEvent<IPreparedOutputEditingData>();

const createDocumentFx = DocumentCreationDomain.createEffect(async (data: TDocumentCreation) => {
    try {
        const result = await postDocumentCreation(data);
        if (result.status === 201) {
            return result.data.id;
        }
    } catch (e) {
        console.warn(e);
    }
});

const editDocumentFx = DocumentCreationDomain.createEffect(
    async (data: {data: TDocumentEditing; documentId: string}) => {
        try {
            const result = await putDocumentEditing(data.data, data.documentId);
            if (result.status === 200) {
                return result.data.id;
            }
        } catch (e) {
            console.warn(e);
        }
    },
);

const fetchDocumentTypeListFx = DocumentCreationDomain.createEffect(async (projectId: string) => {
    try {
        const result = await fetchAvailableDocumentSubtypeList(projectId);
        return result.data;
    } catch (e) {
        console.warn(e);
    }
});

const sendAttachmentFileFx = DocumentCreationDomain.createEffect(async (fileList: FileList) => {
    try {
        const fileIdList: string[] = [];
        for (let i = 0; i < fileList.length; i++) {
            const result = await postJobAttachment(fileList[i]);

            if (result.status === 201) {
                fileIdList.push(result.data.ids[0].id);
            }
        }
        return fileIdList;
    } catch (e) {
        console.log(e);
    }
});

// Stores
const $documentTypeList = DocumentCreationDomain.createStore<TDocumentType[]>([]).on(
    fetchDocumentTypeListFx.doneData,
    forwardPayload(),
);
const $attachmentDocumentIdList = DocumentCreationDomain.createStore<string[]>([]).on(
    sendAttachmentFileFx.doneData,
    forwardPayload(),
);

const $createdDocId = DocumentCreationDomain.createStore<string | null>(null).on(
    createDocumentFx.doneData,
    forwardPayload(),
);

const $editedDocId = DocumentCreationDomain.createStore<string | null>(null).on(
    editDocumentFx.doneData,
    forwardPayload(),
);

const $documentTypeAutocompleteList = DocumentCreationDomain.createStore<IDocumentOption[]>([]);
const $selectedDocumentType = DocumentCreationDomain.createStore<IDocumentOption | null>(null)
    .on(changeSelectedDocumentType, forwardPayload())
    .reset(resetSelectedDocumentType);
const $filesMaxCount = DocumentCreationDomain.createStore<number>(1);
const $commentValue = DocumentCreationDomain.createStore<string>('').on(
    changeCommentValue,
    forwardPayload(),
);
const $buttonClicked = DocumentCreationDomain.createStore<boolean>(false)
    .on([createDocumentFx.pending, editDocumentFx.pending], () => true)
    .on([createDocumentFx.doneData, editDocumentFx.doneData], () => false);
const $isFormTouched = DocumentCreationDomain.createStore(false);

export const $documentCreationStore = combine({
    documentTypeList: $documentTypeAutocompleteList,
    selectedDocumentType: $selectedDocumentType,
    commentValue: $commentValue,
    createdDocumentId: $createdDocId,
    editedDocumentId: $editedDocId,
    maxFiles: $filesMaxCount,
    buttonClicked: $buttonClicked,
    formTouched: $isFormTouched,
});

// загрузка списка типов документов при открытии страницы
sample({
    clock: DocumentCreationGate.state,
    filter: (clock) => typeof clock === 'string',
    target: fetchDocumentTypeListFx,
});

// активация проверки ошибок формы
sample({
    clock: [creationButtonClicked, editingButtonClicked],
    fn: () => true,
    target: $isFormTouched,
});

sample({
    clock: creationButtonClicked,
    source: combine({
        buttonClicked: $buttonClicked,
        selectedDocumentType: $selectedDocumentType,
    }),
    filter: ({buttonClicked, selectedDocumentType}, {files, isWorkflowButtonClicked, isError}) =>
        checkObjectValidity(
            buttonClicked || isWorkflowButtonClicked,
            files,
            isError,
            selectedDocumentType,
        ),
    fn: ({selectedDocumentType}, {files}) =>
        mapFileUploaderFilesToOutput(files, selectedDocumentType),
    target: createDocument,
});

sample({
    clock: editingButtonClicked,
    source: combine({
        buttonClicked: $buttonClicked,
        selectedDocumentType: $selectedDocumentType,
    }),
    filter: ({buttonClicked, selectedDocumentType}, {files, isWorkflowButtonClicked, isError}) =>
        checkObjectValidity(
            buttonClicked || isWorkflowButtonClicked,
            files,
            isError,
            selectedDocumentType,
        ),
    fn: (_, {files, initialState, projId, documentId}) =>
        mapFileUploaderFilesForEditing(initialState, files, projId, documentId),
    target: editDocument,
});

// создание неоцифрованного документа
sample({
    clock: createDocument,
    source: combine({
        projectId: DocumentCreationGate.state,
        selectedDocumentType: $selectedDocumentType,
        comment: $commentValue,
        document_attachments: $attachmentDocumentIdList,
    }),
    filter: (source) => source.selectedDocumentType !== null,
    fn: (source, clock) => ({
        project_id: source.projectId,
        subtypes_document_id: source.selectedDocumentType?.id.toString() ?? '',
        document_comment: source.comment,
        document_attachments: clock,
    }),
    target: createDocumentFx,
});

// редактирование неоцифрованного документа
sample({
    clock: editDocument,
    source: combine({
        projectId: DocumentCreationGate.state,
        selectedDocumentType: $selectedDocumentType,
        comment: $commentValue,
        document_attachments: $attachmentDocumentIdList,
    }),
    filter: (source) => source.selectedDocumentType !== null,
    fn: (source, clock) => {
        return {
            data: {
                project_id: clock.projectId,
                subtypes_document_id: source.selectedDocumentType?.id.toString() ?? '',
                document_comment: source.comment,
                document_attachments: clock.docs,
            },
            documentId: clock.documentId,
        };
    },
    target: editDocumentFx,
});

// преобразование данных от бека для автокомплита
sample({
    source: $documentTypeList,
    fn: (source) =>
        source.map((item) => ({
            id: item.id,
            value: item.name,
            isRegistration: item.is_registration,
        })),
    target: $documentTypeAutocompleteList,
});

// изменение максимального количества загружаемых файлов в зависимости от выбранного типа документа
sample({
    clock: changeSelectedDocumentType,
    source: $documentTypeList,
    fn: (source, clock) => source.find((item) => item.id === clock.id)?.quantity_files ?? 1,
    target: $filesMaxCount,
});

// сброс домена после ухода со страницы
resetDomainStoresByEvents(DocumentCreationDomain, DocumentCreationGate.close);
