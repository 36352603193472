import React from 'react';
import {useGate, useStore} from 'effector-react';
import {IconButton, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

import {CertificateRadioGroup} from './CertificateRadioGroup';
import {
    $certificateSignatureStore,
    certificateSelected,
    CertificateSignatureGate,
    positiveBtnClicked,
    windowClosed,
} from '../model';
import {ActionButtonsUnit} from '../../ActionButtonsUnit';

export const CertificateSignature = () => {
    useGate(CertificateSignatureGate);
    const {
        certificateList,
        selectedCertificateId,
        header,
        description,
        signBtnDisabled,
        certificateListIsLoading,
    } = useStore($certificateSignatureStore);

    return (
        <WrapperFlex flexDirection="column" margin="0 auto" gap="32px" width="628px">
            <WrapperFlex alignItems="center" gap="16px">
                <IconButton
                    iconName={Icons.ArrowLeft}
                    size="medium"
                    onClick={() => windowClosed()}
                />
                <Typography variant="h4">{header}</Typography>
            </WrapperFlex>
            <Typography variant="body2">{description}</Typography>
            <CertificateRadioGroup
                certificateList={certificateList}
                selectedCertificateId={selectedCertificateId}
                handleClick={certificateSelected}
                isLoading={certificateListIsLoading}
            />
            <ActionButtonsUnit
                negativeBtnLabel="Отменить"
                positiveBtnLabel="Подписать"
                handleNegativeBtn={() => windowClosed()}
                handlePositiveBtn={() => positiveBtnClicked()}
                alignSelf="flex-end"
                disabledPositiveBtn={signBtnDisabled}
            />
        </WrapperFlex>
    );
};
