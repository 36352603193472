import React from 'react';
import {useStore} from 'effector-react';
import {DatePicker, Select, Tab} from '@beeline/design-system-react';
import {ColumnCheckboxList} from 'widgets/ColumnCheckboxList';
import {ActionButtonsUnit} from 'Features/ActionButtonsUnit';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {
    APPLY_BUTTON_TITLE,
    COLUMN_SETTINGS_TITLE,
    CONTRACTOR_SHORT_TITLE,
    DISCARD_BUTTON_TITLE,
    FILTERS_TITLE,
    IS_ACTIVE_ON_DATE_TITLE,
    PROCUREMENT_EVENT_TITLE,
} from 'shared/constants';

import * as S from './style';
import {
    $tableSettings,
    filtersSaved,
    columnVisibilityChanged,
    filtersDiscarded,
    $contractListSidesheetStore,
    selectedContractorChanged,
    selectedPurchasingEventChanged,
    activityDateChanged,
} from '../model';

export const SidesheetContent = () => {
    const {
        contractorList,
        selectedContractorList,
        purchasingSelect: {purchasingEventList, selectedPurchasingEventList, isLoading},
        contractorListIsLoading,
        contractorSelectShown,
        selectedActionDate,
        linkColumnShown,
    } = useStore($contractListSidesheetStore);

    const {columns} = useStore($tableSettings);

    const visibleColumns = contractorSelectShown
        ? columns
        : columns
              .filter((column) => column.id !== 'contractor')
              .filter((column) => (linkColumnShown ? true : column.id !== 'external_link'));
    return (
        <WrapperFlex flexDirection="column" justifyContent="space-between" height="100%" gap="16px">
            <S.StyledTabs>
                <Tab key={1} label={FILTERS_TITLE}>
                    <WrapperFlex flexDirection="column" padding="24px 0" gap="32px">
                        <Select
                            label={PROCUREMENT_EVENT_TITLE}
                            onChange={selectedPurchasingEventChanged}
                            options={purchasingEventList}
                            values={selectedPurchasingEventList}
                            loading={isLoading}
                            multiple
                            fullWidth
                        />
                        {contractorSelectShown && (
                            <Select
                                label={CONTRACTOR_SHORT_TITLE}
                                loading={contractorListIsLoading}
                                onChange={selectedContractorChanged}
                                options={contractorList}
                                values={selectedContractorList}
                                filter
                                multiple
                                fullWidth
                            />
                        )}
                        <DatePicker
                            label={IS_ACTIVE_ON_DATE_TITLE}
                            value={selectedActionDate}
                            onChange={activityDateChanged}
                            fullWidth
                        />
                    </WrapperFlex>
                </Tab>
                <Tab key={2} label={COLUMN_SETTINGS_TITLE}>
                    <ColumnCheckboxList data={visibleColumns} onChange={columnVisibilityChanged} />
                </Tab>
            </S.StyledTabs>
            <ActionButtonsUnit
                negativeBtnLabel={DISCARD_BUTTON_TITLE}
                positiveBtnLabel={APPLY_BUTTON_TITLE}
                handlePositiveBtn={filtersSaved}
                handleNegativeBtn={filtersDiscarded}
                alignSelf="flex-end"
            />
        </WrapperFlex>
    );
};
