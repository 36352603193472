import React from 'react';
import {IconButton, Label, TableData, Typography} from '@beeline/design-system-react';
import {ITaskForSign} from 'shared/types/processesTypes';

import * as S from './style';
import {Icons} from '@beeline/design-tokens/js/iconfont';

type ProgressCellProps = Pick<ITaskForSign, 'progress'> & {
    onClick: (id: string) => void;
    id: string;
};

export const ProgressCell = ({id, onClick, progress}: ProgressCellProps) => {
    const result = () => {
        switch (progress) {
            case 'in progress':
                return (
                    <Typography variant="body3" className="inactiveText">
                        Идет подписание...
                    </Typography>
                );
            case 'done':
                return <Label type="success" title="Подписано" />;
            case 'failed':
                return (
                    <>
                        <Label type="error" title="Ошибка" />
                        <IconButton iconName={Icons.Refresh} onClick={() => onClick(id)} />
                    </>
                );
            case 'wrongUser':
                return (
                    <>
                        <Label type="error" title="Ошибка подписанта" />
                        <IconButton iconName={Icons.Refresh} onClick={() => onClick(id)} />
                    </>
                );
        }
    };

    return (
        <TableData>
            <S.LabelWrapper>{result()}</S.LabelWrapper>
        </TableData>
    );
};
