import {AdditionalAgreementQueryParams, AdditionalAgreementResponse} from 'pages/DSPage/types';
import {bindAllMethods} from 'shared/helpers/binder';
import {axiosInstance} from 'shared/api/api';
import {CreateAdditionalAgreementRequestType} from 'shared/types/additionalAgreementsTypes';

export const additionalAgreementService = bindAllMethods({
    async getAdditionalAgreement(
        params: AdditionalAgreementQueryParams,
    ): Promise<AdditionalAgreementResponse> {
        return axiosInstance.get(`/DS/agreements/${params.id}/`);
    },
    async createAdditionalAgreement(
        params: CreateAdditionalAgreementRequestType,
    ): Promise<{data: {agreement_id: string}}> {
        return axiosInstance.post('/DS/agreements/', params);
    },
    async updateAdditionalAgreement({data, id}: {data: any; id: string}) {
        return axiosInstance.put<{agreement_id: string}>(`/DS/agreements/${id}/`, data);
    },
});
