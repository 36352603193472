import {NavigateOptions, To} from 'react-router-dom';
import {createDomain} from 'effector';

import {forwardPayload} from 'shared/helpers/effector';

const AppNavigatorDomain = createDomain();

//events
export const navigationInvoked = AppNavigatorDomain.createEvent<{
    to: To | number;
    options?: NavigateOptions;
}>();

export const $navigationPath = AppNavigatorDomain.createStore<{
    to: To | number;
    options?: NavigateOptions;
} | null>(null);

$navigationPath.on(navigationInvoked, forwardPayload());
