import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Typography} from '@beeline/design-system-react';
import {BodyContent, MainWrapper} from 'components/PageWraps';
import {$selectedProject, VirCreatePageGate} from 'pages/VirStart/model';
import Button from 'shared/ui/deprecated/Button/Button';

import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {NonMvpItem} from 'shared/styles/commonStyle';

import {VirStartBlock, GlobalStyles} from './styled';
import {VIRData} from './VirStartData';
import {ActionWrap} from './VirStartActionWrap/VirStartActionWrap';
import {AgreementConstructorHeader} from '../../AddWorkObjects/ui/Header';
import {$agreementType, $createAdditionalAgreementData} from '../../../processes/contructor';

export function CreateVIRStart() {
    const {projectId} = useParams();
    useGate(VirCreatePageGate, {projectId});
    const project = useStore($selectedProject);
    const ds = useStore($createAdditionalAgreementData);
    const agreementMode = useStore($agreementType);
    const navigate = useNavigate();

    const navigateToConstructor = () => {
        navigate(`/additional-agreement/constructor`);
    };
    return (
        <>
            <GlobalStyles />
            <MainWrapper>
                <AgreementConstructorHeader agreement={ds} agreementType={agreementMode} />
                <BodyContent>
                    <WrapperOffset offset="32" className="w-100">
                        <VirStartBlock>
                            <VIRData />
                            <Typography variant="h4">
                                Чтобы упростить формирование ВИР, выберите одно из действий ниже
                            </Typography>
                            <Typography variant="h6">Далее они тоже будут вам доступны</Typography>
                            <ActionWrap />
                            <NonMvpItem>
                                <WrapperOffset offsetTop="16">
                                    <WrapperFlex justifyContent="flex-end">
                                        <Button
                                            disabled={!project}
                                            color="outline"
                                            onClick={navigateToConstructor}
                                        >
                                            Пропустить
                                        </Button>
                                    </WrapperFlex>
                                </WrapperOffset>
                            </NonMvpItem>
                        </VirStartBlock>
                    </WrapperOffset>
                </BodyContent>
            </MainWrapper>
        </>
    );
}
