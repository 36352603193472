import React from 'react';

import {ActionLabel} from 'Entities/ActionLabel';
import {WorkExecutionButtonGroup} from 'Entities/WorkExececutionButtonGroup';

interface IActionColumnProps {
    data: {
        id: string;
        work_specification_approval: boolean | null;
        work_specification_approval_total: boolean | null;
    };
    taskStatus: number;
    isEditable: boolean;
    onClick: ({id, action}: {id: string; action: boolean | null}) => void;
}

export const DocumentActionCell = ({data, isEditable, taskStatus, onClick}: IActionColumnProps) => {
    const {
        id,
        work_specification_approval: selectedButton,
        work_specification_approval_total,
    } = data;
    const handleAccept = () => {
        onClick({
            id: id,
            action: selectedButton !== true ? true : null,
        });
    };

    const handleReject = () => {
        onClick({
            id: id,
            action: selectedButton !== false ? false : null,
        });
    };

    return (
        <>
            {isEditable && <ActionLabel condition={work_specification_approval_total} />}
            {!isEditable && taskStatus === 2 && (
                <WorkExecutionButtonGroup
                    onAccept={handleAccept}
                    onReject={handleReject}
                    selected={selectedButton}
                />
            )}
            {!isEditable && taskStatus < 2 && (
                <ActionLabel condition={work_specification_approval_total} />
            )}
        </>
    );
};
