import {TAttachmentFile} from 'shared/types/FileTypes';
import {EFileUploadStatus, TFile} from 'shared/ui/use-file-uploader';
import {TFileDataList} from 'shared/ui/use-file-uploader/useFileUploader.types';

export function deleteFieldsFromObject(item, fieldToDelete: string[]) {
    const objectItem = {...item};
    for (const field of fieldToDelete) {
        delete objectItem?.[field];
    }
    return objectItem;
}

export function prepareTFileList(documentFileList?: TAttachmentFile[]): TFile[] {
    return (documentFileList ?? []).map((item) => ({
        name: item.file_name,
        guid: item.id,
        type: item.type,
        history_id: item.history_file,
        file_id: item.file_id,
        registration_at: item.registration_at ?? null,
        status: EFileUploadStatus.Upload,
    }));
}

export const deriveFileList = (files: TFileDataList): TAttachmentFile[] => {
    const result: TAttachmentFile[] = [];
    for (let prop in files) {
        if (files[prop].status === 'upload') {
            result.push({
                id: files[prop].guid ?? '',
                file_name: files[prop].name,
                history_id: files[prop].history_id ?? '',
                type: files[prop].type,
                registration_at: files[prop].registration_at,
            });
        }
    }
    return result;
};
