import React from 'react';

import {NotFound, NotFoundCaption, NotFoundTitle} from 'components/NotFound';
import {TableCell, TableString} from 'shared/ui/Table/Table';

export const EmptyObjectRow = () => {
    return (
        <TableString>
            <TableCell>
                <NotFound height="292px">
                    <NotFoundTitle>Объекты не добавлены</NotFoundTitle>
                    <NotFoundCaption>
                        Чтобы включить объекты в ведомость, нажмите на кнопку «Добавить объекты»
                    </NotFoundCaption>
                </NotFound>
            </TableCell>
        </TableString>
    );
};
 