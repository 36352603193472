import styled from 'styled-components/macro';
import {Typography} from '@beeline/design-system-react';

export const CardTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
`;

export const LinkedTypography = styled(Typography)`
    color: var(--color-text-link);
`;
