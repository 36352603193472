import styled, { css } from "styled-components";

export const FileItemStyled = styled.div`
	padding: 3px 0;
	align-items: center;
	display: flex;
	flex-wrap: nowrap;
	color:var(--body-color);
`


const FilePicMixin = (props) => css`
border-radius: 12px;
height: 40px;
margin-right: 16px;
padding: 8px;
width: 40px;

${props.variant == 'neutral' && css`
background-color: #eeeeee;
.icon{
	color:var(--secondary);
	font-size:24px;
}
`}
${props.variant == 'error' && css`
background-color: #FFECEF;
.icon{
	color:var(--red);
	font-size:24px;
}
`}
`

export const FilePic = styled.div`
	${(props) => FilePicMixin(props)}
`;


export const FileTitle = styled.div`
-webkit-box-orient: vertical;
-ms-box-orient: vertical;
box-orient: vertical;
-webkit-line-clamp: 2;
-moz-line-clamp: 2;
-ms-line-clamp: 2;
line-clamp: 2;
display: -webkit-box;
font-size: 17px;
line-height: 1.2941176471;
max-height: 44px;
max-width: 413px;
overflow: hidden;
word-break: break-all;
`
export const FileCaption = styled.div`
color: ${props => props.variant == 'error' ? "var(--red)" : "var(--secondary)"};
font-size: 15px;
line-height: 1.2;
`

export const FileEditableItemStyled = styled.div`
	padding: 12px 0;
	align-items: center;
	display: flex;
	max-width: 600px;
	justify-content: space-between;
	flex-wrap: nowrap;
`


export const FileInputWrapper = styled.div`
	position: relative;
	display: inline-flex;
`

export const FileInput = styled.input`
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
`