import React from 'react';
import {SmallIconButtonStyled} from 'shared/ui/deprecated/SmallIconButton/style';

export default function SmallIconButton(props) {
    const {children, icon, ...rest} = props;

    return (
        <SmallIconButtonStyled className={rest?.className} {...props}>
            {icon}
        </SmallIconButtonStyled>
    );
}
