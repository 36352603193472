import React, {ReactNode} from 'react';
import {Icon, IconButton, Progress} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {IColumn} from 'shared/types/tableTypes';
import {formatDate} from 'shared/helpers/formatHelper';
import {IContractPurchasingEvent, IPurchasingEventLot} from 'shared/types/purchasesTypes';
import {ITpiFileParams} from 'shared/services/types/paramsTypes';

import {tpiFileDownloaded} from '../model';

export interface IPurchasingEventColumn<T> extends IColumn<T> {
    render?: (data: T, userActions?: string[], isOpen?: boolean) => ReactNode;
}

export interface ILotColumn<T> extends IColumn<T> {
    render?: (data: T, tpiFileData?: ITpiFileParams, loadingTpiIdList?: string[]) => ReactNode;
}

export const purchaseEventColumns: IPurchasingEventColumn<IContractPurchasingEvent>[] = [
    {
        name: 'purchasingEventNumber',
        label: 'Номер',
        hasSorting: false,
        width: '222px',
        render: ({id, number}, _, isOpen) => (
            <WrapperFlex gap="8px">
                <Icon iconName={isOpen ? Icons.NavArrowUp : Icons.NavArrowDown} />
                {number}
            </WrapperFlex>
        ),
    },
    {
        name: 'year',
        label: 'Год',
        hasSorting: false,
        width: '222px',
        render: ({year}) => year,
    },
    {
        name: 'expiryDate',
        label: 'Дата окончания',
        hasSorting: false,
        width: '222px',
        render: ({end_date}) => formatDate(end_date),
    },
    {
        name: 'type',
        label: 'Тип',
        hasSorting: false,
        width: '222px',
        render: ({type}) => type,
    },
];

export const lotColumns: ILotColumn<IPurchasingEventLot>[] = [
    {
        name: 'lot',
        label: 'Лот',
        hasSorting: false,
        width: '90px',
        render: ({name}) => name,
    },
    {
        name: 'geography',
        label: 'География',
        hasSorting: false,
        width: '100px',
        render: ({geography_name}) => geography_name,
    },
    {
        name: 'branches',
        label: 'Филиалы',
        hasSorting: false,
        width: '300px',
        render: ({branches}) => {
            const data = branches.join(', ');
            return (
                <TooltipedSpan title={data} lineCount={2}>
                    {data}
                </TooltipedSpan>
            );
        },
    },
    {
        name: 'tpi',
        hasSorting: false,
        width: '45px',
    },
    {
        name: 'download',
        hasSorting: false,
        width: '35px',
        render: ({tpis}, tpiFileData, loadingTpiIdList) => {
            if (tpis?.id === undefined) return <></>;
            const isLoading = loadingTpiIdList?.includes(tpis.id) ?? false;
            const handleOnClick = () => !!tpiFileData && tpiFileDownloaded(tpiFileData);
            return !isLoading ? (
                <IconButton iconName={Icons.Download} onClick={handleOnClick} />
            ) : (
                <Progress shape="circle" size="mini" cycled />
            );
        },
    },
];
