import {Option} from '@beeline/design-system-react';

import {TContractListBackendParams, TContractListParams} from 'shared/services/types/paramsTypes';

export const countAppliedFilters = (params: TContractListParams): number => {
    let result = 0;
    const nonFilterArray = ['page', 'page_size', 'ordering', 'region', 'branch', 'search'];
    for (let key in params) {
        if (nonFilterArray.includes(key)) continue;
        if (!!params[key]) result++;
    }
    return result;
};

export const getSelectedPurchasingEventSelectArray = (
    string: string | undefined,
): Option<string>[] =>
    string
        ? string.split(',').map((item) => ({
              id: item,
              value: item,
          }))
        : [];

export const getPurchasingEventSelectArray = (array: string[] | undefined): Option<string>[] =>
    Array.isArray(array)
        ? array.map((item) => ({
              id: item,
              value: item,
          }))
        : [];

export const adaptFrontParamsToBackend = (
    params: TContractListParams,
): TContractListBackendParams => ({
    page: params.page,
    page_size: params.page_size,
    region: params.region,
    date_action: params.actionDate,
    pe_number: params.purchasingEvent,
    gpo_short_name: params.contractor,
    search: params.search,
    ordering: params.ordering,
});
