import {useNavigate} from 'react-router-dom';
import {Typography} from '@beeline/design-system-react';

import {selectTpiToConfigureCoefficient} from 'pages/ApplyCoefficientsPage/model';
import {
    deleteWorkSpecification,
    duplicateWorkSpecification,
    updateSpecDate,
    updateSpecPrice,
} from 'processes/contructor';
import {formatDate} from 'shared/helpers/formatHelper';
import {TableCell, TableString, TableStringSelected} from 'shared/ui/Table/Table';
import {EAdditionalAgreementType} from 'shared/types/additionalAgreementsTypes';

import {PriceCell} from './Cells/PriceCell';
import {TpiSpecification} from 'pages/VirSelect/types';
import {OrdinalCell} from './Cells/OrdinalCell';
import {PositionCell} from './Cells/PositionCell';
import {QuantityCell} from './Cells/QuantityCell';
import {CoefficientCell} from './Cells/CoefficientCell';
import {NameCell} from './Cells/NameCell';
import {DateCell} from './Cells/DateCell';
import {CommentCell} from './Cells/CommentCell';
import {ActionsCell} from './Cells/ActionsCell';
import {SumCell} from './Cells/SumCell';
import {StatusCell} from './Cells/StatusCell';
import {SpecAttachmentAndComment} from '../SpecAttachmentAndComment/SpecAttachmentAndComment';

export interface WorSpecificationProps {
    agreementType: EAdditionalAgreementType | null;
    index: number;
    data: TpiSpecification;
    showAdds: (id: string) => void;
    activeAdds: string | null;
    currentVirId: string;
    currentWorkObjectId: string;
    touchedConstructor: boolean;
    agreementOnRevision: boolean;
}

export function SpecificationRow(props: WorSpecificationProps) {
    const navigate = useNavigate();
    const {
        agreementType,
        index,
        data,
        showAdds,
        activeAdds,
        currentVirId,
        currentWorkObjectId,
        touchedConstructor,
        agreementOnRevision,
    } = props;

    const {tpi_specification_id, is_ws_deletable} = data;
    const specInfo = {
        spec: data,
        virId: currentVirId,
        workObjectId: currentWorkObjectId,
    };

    function handleDuplicateSpecification() {
        duplicateWorkSpecification({
            workObjectId: currentWorkObjectId,
            virId: currentVirId,
            index,
            specification: data,
        });
    }

    function handleDeleteSpecification() {
        deleteWorkSpecification({
            workObjectId: currentWorkObjectId,
            virId: currentVirId,
            specId: data.id,
        });
    }

    function handleConfigureCoefficient() {
        selectTpiToConfigureCoefficient(specInfo);
        navigate('/constructor/work_coefficients', {
            state: {
                tpi_specification_id: tpi_specification_id,
            },
        });
    }

    const appliedCoefficientNumbers = data.work_coefficients
        ?.filter((el) => !el.is_manual && el.number)
        .map((el) => el.number)
        .join(', ');
    const quantityReadOnly = data.is_quantity_editable === false;
    const priceReadOnly = data.is_price_editable === false;

    return (
        <>
            <TableStringSelected key={index} noBorder={activeAdds === data?.id}>
                <OrdinalCell ordinalNumber={index} />
                <PositionCell
                    number={data.number}
                    coefficient={data.coefficient}
                    appliedCoefficientNumbers={appliedCoefficientNumbers}
                />
                <NameCell name={data.name} note={data.note} />
                <QuantityCell
                    data={data}
                    specInfo={specInfo}
                    touched={touchedConstructor}
                    readOnly={quantityReadOnly}
                />
                <PriceCell
                    updateSpecPrice={updateSpecPrice}
                    data={data}
                    specInfo={specInfo}
                    touched={touchedConstructor}
                    readOnly={priceReadOnly}
                />
                <CoefficientCell
                    coefficient={data.coefficient}
                    handleConfigureCoefficient={handleConfigureCoefficient}
                    disabled={data.is_ws_deletable === false}
                />
                <SumCell sum={data.sum} />
                <TableCell>
                    <Typography variant="body3">
                        {data.begin_date ? formatDate(data.begin_date) : 'Дата подписания'}
                    </Typography>
                </TableCell>
                <DateCell
                    agreementType={agreementType}
                    data={data}
                    updateSpecDate={updateSpecDate}
                    specInfo={specInfo}
                    touched={touchedConstructor}
                    isBegin={false}
                />
                <CommentCell data={data} showAdds={showAdds} />
                <ActionsCell
                    handleDuplicateSpecification={handleDuplicateSpecification}
                    handleDeleteSpecification={
                        is_ws_deletable === false ? undefined : handleDeleteSpecification
                    }
                />
                {agreementOnRevision && (
                    <StatusCell confirmed={!!data?.work_specification_approval_total} />
                )}
            </TableStringSelected>
            <TableString disable={!(activeAdds === data?.id)}>
                <td colSpan={12}>
                    <SpecAttachmentAndComment
                        currentVirId={currentVirId}
                        currentWorkObjectId={currentWorkObjectId}
                        workSpecificationId={data?.id}
                        currentSpecification={data}
                    />
                </td>
            </TableString>
        </>
    );
}
