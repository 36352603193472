import React from 'react';

import * as S from './style';

interface WorkObjectNameRowProps {
    children: JSX.Element;
}

export const WorkObjectNameRow = ({children}: WorkObjectNameRowProps) => {
    return <S.StyledTableRow>{children}</S.StyledTableRow>;
};
