import {useStore} from 'effector-react';
import {IconButton, Popover} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {ModalFactory} from 'Features/modalFactory/ui';
import {
    $isDeleteStatementModalOpen,
    closeDeleteStatementModal,
    deleteVir,
    openDeleteStatementModal,
} from 'processes/contructor/model/stores';
import Button from 'shared/ui/deprecated/Button/Button';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {
    CANCEL_BUTTON_TITLE,
    DELETE_BUTTON_TITLE,
    IRREVERSIBLE_ACTION_HINT,
    PROHIBITED_SHEET_DELETION_HINT,
    SHEET_DELETION_CONFIRMING_HINT,
} from 'shared/constants';

export interface DeleteVirWorkProps {
    id: string;
    isDeletable: boolean;
}

export function DeleteVirWork({id, isDeletable}: DeleteVirWorkProps) {
    const open = useStore($isDeleteStatementModalOpen);
    return (
        <>
            <Popover
                content={
                    <WrapperOffset offset={8}>
                        {PROHIBITED_SHEET_DELETION_HINT}
                    </WrapperOffset>
                }
                disableHover={isDeletable}
            >
                <IconButton
                    iconName={Icons.Delete}
                    size="large"
                    onClick={(e) => {
                        e.stopPropagation();
                        openDeleteStatementModal();
                    }}
                    disabled={!isDeletable}
                />
            </Popover>

            <ModalFactory
                open={open}
                title={SHEET_DELETION_CONFIRMING_HINT}
                description={IRREVERSIBLE_ACTION_HINT}
            >
                <Button
                    color="outline"
                    onClick={(e) => {
                        e.stopPropagation();
                        closeDeleteStatementModal();
                    }}
                >
                    {CANCEL_BUTTON_TITLE}
                </Button>
                <Button
                    color="contained"
                    onClick={(e) => {
                        e.stopPropagation();
                        deleteVir(id);
                        closeDeleteStatementModal();
                    }}
                    className="ms-3"
                >
                    {DELETE_BUTTON_TITLE}
                </Button>
            </ModalFactory>
        </>
    );
}
