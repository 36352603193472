import React from 'react';
import BreadcrumbItem from 'shared/ui/deprecated/Breadcrumb/BreadcrumbItem';
import Breadcrumb from 'shared/ui/deprecated/Breadcrumb/Breadcrumb';
import {Skeleton} from '@beeline/design-system-react';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

export const SkeletonHeader = () => {
    return (
        <WrapperFlex
            justifyContent="space-between"
            alignItems="flex-start"
            gap="16px"
            flexWrap="wrap"
        >
            <WrapperFlex flexDirection="column" gap="12px">
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Skeleton width={70} height={18} />
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Skeleton width={170} height={18} />
                    </BreadcrumbItem>
                </Breadcrumb>
                <Skeleton height={32} width={260} variant="title" radius={6} />
            </WrapperFlex>
        </WrapperFlex>
    );
};
