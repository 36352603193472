import styled, {css} from 'styled-components/macro';

export const RowWrapper = styled.div`
    width: 692px;
    height: 64px;
    padding: 12px 0;
    display: flex;
    gap: 24px;

    :hover {
        background: var(--color-background-base-hover) !important;
    }

    div > p {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
    }
`;

export const AgreementNumberWrapper = styled.div<{addition?: boolean}>`
    flex: 0 0 180px;
    overflow: hidden;

    ${(props) =>
        props.addition &&
        css`
            padding-left: 24px;
        `}
`;
export const AgreementNameWrapper = styled.div`
    flex: 0 0 264px;
    overflow: hidden;
`;
export const AgreementBranchWrapper = styled.div`
    flex: 0 0 176px;
    overflow: hidden;
    white-space: normal;
`;
