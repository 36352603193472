import {createDomain, Domain, Effect, Unit} from 'effector';
import {forwardPayload} from 'shared/helpers/effector';
import {Pagination, ResponseType} from '../types';

interface Params<T extends object> {
    domain?: Domain;
    pageSizes?: Array<number>;
    loadDataFxHandler: Effect<Pagination, ResponseType<T>, Error>;
    resetTriggers: Unit<unknown>[];
}

export function paginationStateFactory<T extends object>(params?: Params<T>) {
    const {
        domain = createDomain(),
        pageSizes = [10, 20, 30],
        resetTriggers = [],
        loadDataFxHandler,
    } = params || {};
    const $page = domain.createStore(1);
    const $count = domain.createStore(0);
    const $pageSize = domain.createStore(pageSizes[0]);
    const setPage = domain.createEvent<number>();
    const setPageSize = domain.createEvent<number>();

    if (loadDataFxHandler) {
        //todo ADD SERVICE, not effect
        $count.on(loadDataFxHandler.doneData, (state, {count}) => count);
    }

    $page.on(setPage, forwardPayload()).reset([setPageSize, ...resetTriggers]);
    $pageSize.on(setPageSize, forwardPayload());

    return {
        $pagination: {
            $page,
            $pageSize,
            $count,
        },
        setPage,
        setPageSize,
    };
}
