import {createGate} from 'effector-react';
import {combine, createDomain, forward, sample} from 'effector';
import {Option} from '@beeline/design-system-react';

import {TDocumentsListItem, IDocumentsListQueryParams} from 'shared/types/documentTypes';
import {
    fetchDocumentSubtypeList,
    fetchPrimeContractorList,
    fetchDocumentsList,
} from 'shared/services/documents.service';
import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';

interface DocumentsListParamsType {
    project_id: string;
    page: number;
    page_size: number;
    selectedDocumentType: Option<string>[];
    selectedPrimeContractor: Option<string>[];
}

export interface ChangingSelectValueType {
    value: Option<string>[];
    targetItem: Option<string> | null;
    checked: boolean;
}

export const DocumentTableGate = createGate<string>();
export const DocumentTableDomain = createDomain();

// events

export const downloadDocument = DocumentTableDomain.createEvent();
export const changePrimeContractor = DocumentTableDomain.createEvent();
export const setPage = DocumentTableDomain.createEvent<number>();
export const setPageSize = DocumentTableDomain.createEvent<number>();
export const changeDocumentTypeSelect = DocumentTableDomain.createEvent<string>();
export const changePrimeContractorSelect = DocumentTableDomain.createEvent<string>();
export const resetDocumentTypeSelect = DocumentTableDomain.createEvent();
export const resetPrimeContractorSelect = DocumentTableDomain.createEvent();
export const changeSelectedPrimeContractor =
    DocumentTableDomain.createEvent<ChangingSelectValueType>();
export const changeSelectedDocumentType =
    DocumentTableDomain.createEvent<ChangingSelectValueType>();
export const changePrimeContractorSearchValue = DocumentTableDomain.createEvent<string>();
export const changeDocumentTypeSearchValue = DocumentTableDomain.createEvent<string>();
export const resetPrimeContractorSearchValue = DocumentTableDomain.createEvent();
export const resetDocumentTypeSearchValue = DocumentTableDomain.createEvent();
export const resetAllSelects = DocumentTableDomain.createEvent();

// effects
export const fetchDocumentsDataFx = DocumentTableDomain.createEffect(
    async (params: DocumentsListParamsType) => {
        try {
            const resultParams: IDocumentsListQueryParams = {
                project_id: params.project_id,
                page: params.page,
                page_size: params.page_size,
                subtypes_document: params.selectedDocumentType.map((item) => item.id).join(','),
                gpo: params.selectedPrimeContractor.map((item) => item.id).join(','),
            };
            const result = await fetchDocumentsList(resultParams);
            return result.data;
        } catch (e) {
            console.warn(e);
        }
    },
);

export const fetchPrimeContractorsDataFx = DocumentTableDomain.createEffect(
    async (projectId: string) => {
        try {
            const result = await fetchPrimeContractorList(projectId);
            return result.data.map((item) => ({id: item.id, value: item.short_name}));
        } catch (e) {
            console.warn(e);
        }
    },
);

export const fetchDocumentTypesFx = DocumentTableDomain.createEffect(async (projectId: string) => {
    try {
        const result = await fetchDocumentSubtypeList(projectId);
        return result.data.map((item) => ({id: item.id, value: item.name}));
    } catch (e) {
        console.warn(e);
    }
});

// stores
const $documentsStore = DocumentTableDomain.createStore<TDocumentsListItem[]>([]).on(
    fetchDocumentsDataFx.doneData,
    (state, payload) => payload?.results,
);

const $paginationCount = DocumentTableDomain.createStore<number>(0).on(
    fetchDocumentsDataFx.doneData,
    (_, payload) => payload?.count ?? 0,
);

const $documentTypesStore = DocumentTableDomain.createStore<Option<string>[]>([]).on(
    fetchDocumentTypesFx.doneData,
    forwardPayload(),
);

const $availableDocumentTypesStore = DocumentTableDomain.createStore<Option<string>[]>([]).on(
    fetchDocumentTypesFx.doneData,
    forwardPayload(),
);

const $primeContractorsStore = DocumentTableDomain.createStore<Option<string>[]>([]).on(
    fetchPrimeContractorsDataFx.doneData,
    forwardPayload(),
);

const $availablePrimeContractorsStore = DocumentTableDomain.createStore<Option<string>[]>([]).on(
    fetchPrimeContractorsDataFx.doneData,
    forwardPayload(),
);

const $primeContractorSearchValue = DocumentTableDomain.createStore<string>('')
    .on(changePrimeContractorSearchValue, forwardPayload())
    .reset(resetPrimeContractorSearchValue);

const $documentTypeSearchValue = DocumentTableDomain.createStore<string>('')
    .on(changeDocumentTypeSearchValue, forwardPayload())
    .reset(resetDocumentTypeSearchValue);

const $documentsListParams = DocumentTableDomain.createStore<DocumentsListParamsType>({
    project_id: '',
    page: 1,
    page_size: 10,
    selectedDocumentType: [],
    selectedPrimeContractor: [],
})
    .on(setPage, (state, payload) => ({...state, page: payload}))
    .on(setPageSize, (state, payload) => ({
        ...state,
        page_size: payload,
    }))
    .on(resetDocumentTypeSelect, (state, _) => ({
        ...state,
        selectedDocumentType: [],
    }))
    .on(resetPrimeContractorSelect, (state, _) => ({...state, selectedPrimeContractor: []}));

export const documentTableStore = combine({
    documentsStore: $documentsStore,
    documentTypesStore: $availableDocumentTypesStore,
    primeContractorsStore: $availablePrimeContractorsStore,
    documentListParams: $documentsListParams,
    paginationCount: $paginationCount,
    tableIsLoading: fetchDocumentsDataFx.pending,
    primeContractorSearchValue: $primeContractorSearchValue,
    documentTypeSearchValue: $documentTypeSearchValue,
});

// запись project_id при открытии страницы
sample({
    clock: DocumentTableGate.open,
    source: $documentsListParams,
    fn: (source, clock) => ({...source, project_id: clock}),
    target: $documentsListParams,
});

// получение данных для автокомплита "тип документа"
sample({
    clock: DocumentTableGate.open,
    fn: (clock) => clock,
    target: fetchDocumentTypesFx,
});

// получение данных для автокомплита "ГПО"
sample({
    clock: DocumentTableGate.state,
    filter: (clock) => typeof clock === 'string',
    target: fetchPrimeContractorsDataFx,
});

// refetch данных при изменении параметров
sample({
    source: $documentsListParams,
    filter: (source) => source.project_id !== '',
    target: fetchDocumentsDataFx,
});

// фильтрация элементов селекта "ГПО" при использовании поиска
sample({
    clock: $primeContractorSearchValue,
    source: $primeContractorsStore,
    fn: (store, clock) => {
        if (clock !== '') {
            return store.filter((item) => item.value.toLowerCase().includes(clock.toLowerCase()));
        }
        return store;
    },
    target: $availablePrimeContractorsStore,
});

// фильтрация элементов селекта "Тип документа" при использовании поиска
sample({
    clock: $documentTypeSearchValue,
    source: $documentTypesStore,
    fn: (store, clock) => {
        if (clock !== '') {
            return store.filter((item) => item.value.toLowerCase().includes(clock.toLowerCase()));
        }
        return store;
    },
    target: $availableDocumentTypesStore,
});

// работа селекта ГПО
sample({
    clock: changeSelectedPrimeContractor,
    source: combine({
        params: $documentsListParams,
        store: $primeContractorsStore,
    }),
    fn: (source, clock) => {
        const {params, store} = source;
        const {value, targetItem, checked} = clock;
        let result: Option<string>[] = [];
        if (targetItem === null) {
            if (checked) {
                result = [...store];
            }
        } else {
            if (checked) {
                result = [...params.selectedPrimeContractor, targetItem];
            } else {
                result = [...params.selectedPrimeContractor].filter(
                    (item) => item.value !== targetItem.value,
                );
            }
        }
        result.sort((a, b) => a.value.localeCompare(b.value));
        return {...params, page: 1, selectedPrimeContractor: result};
    },
    target: $documentsListParams,
});

// работа селекта "Тип документа"
sample({
    clock: changeSelectedDocumentType,
    source: combine({
        params: $documentsListParams,
        store: $documentTypesStore,
    }),
    fn: (source, clock) => {
        const {params, store} = source;
        const {value, targetItem, checked} = clock;
        let result: Option<string>[] = [];
        if (targetItem === null) {
            if (checked) {
                result = [...store];
            }
        } else {
            if (checked) {
                result = [...params.selectedDocumentType, targetItem];
            } else {
                result = [...params.selectedDocumentType].filter(
                    (item) => item.value !== targetItem.value,
                );
            }
        }
        result.sort((a, b) => a.value.localeCompare(b.value));
        return {...params, page: 1, selectedDocumentType: result};
    },
    target: $documentsListParams,
});

forward({
    from: resetAllSelects,
    to: [
        resetDocumentTypeSearchValue,
        resetPrimeContractorSearchValue,
        resetDocumentTypeSelect,
        resetPrimeContractorSelect,
    ],
});

resetDomainStoresByEvents(DocumentTableDomain, DocumentTableGate.close);
