import React from 'react';
import {Skeleton} from '@beeline/design-system-react';

import {InformationalUnitSkeleton} from 'Entities/InformationalUnit';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

import * as S from '../style';

export const SkeletonInfoUnit = () => {
    return (
        <WrapperFlex flexDirection="column" gap="20px">
            <Skeleton height={26} width={220} variant="title" radius={6} />
            <S.InfoUnitGrid>
                <InformationalUnitSkeleton gridArea="expiryRange" />
                <InformationalUnitSkeleton gridArea="number" />
                <InformationalUnitSkeleton gridArea="KZP" />
            </S.InfoUnitGrid>
        </WrapperFlex>
    );
};
