import styled from 'styled-components/macro';

export const OverallWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
`;

export const WindowWrapper = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
`;

export const BannerWrapper = styled.div`
    height: 52px;
    width: 100%;
`;
