import ModalWindow from 'shared/ui/deprecated/ModalWindow/ModalWindow';
import React, {useState, useContext} from 'react';
import Button from 'shared/ui/deprecated/Button/Button';
import {HeaderBlock, Title, ModalTitle, ModalText} from './style';
import {useParams, useNavigate} from 'react-router-dom';
import {ContextApp} from 'reducer.js';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {resetAdditionalAgreementCreation} from '../../processes/contructor';

const HeaderVir = () => {
    const {state, dispatch} = useContext(ContextApp);

    const [open, setOpen] = useState(false);

    const {projectId} = useParams();
    const navigate = useNavigate();

    const handleClose = () => {
        dispatch({
            type: 'clear',
        });
        setOpen(false);
        resetAdditionalAgreementCreation();
        navigate(`/additional-agreements`);
    };
    return (
        <>
            <HeaderBlock>
                <Title>Новый ВИР/ДС</Title>
                <Button color="outline" onClick={() => setOpen(true)}>
                    Отменить
                </Button>
            </HeaderBlock>

            <ModalWindow open={open}>
                <ModalTitle>Завершить создание ВИР/ДС?</ModalTitle>
                <ModalText>Все внесённые изменения не сохранятся.</ModalText>
                <WrapperOffset offsetTop={16}>
                    <WrapperFlex justifyContent="flex-end">
                        <Button color="outline" onClick={() => setOpen(false)}>
                            Продолжить создание
                        </Button>
                        <Button color="contained" onClick={handleClose} className="ms-3">
                            Завершить
                        </Button>
                    </WrapperFlex>
                </WrapperOffset>
            </ModalWindow>
        </>
    );
};

export default HeaderVir;
