import {combine, createDomain, forward, sample} from 'effector';
import {getNotifications} from 'shared/services/processes.service';
import {createGate} from 'effector-react';
import {TTaskList} from 'shared/types/tasksTypes';

export const headerDomain = createDomain();
export const headerGate = createGate();

// events
export const fetchTasks = headerDomain.createEvent();
export const increasePageNumber = headerDomain.createEvent();

// effects
export const getTasksFx = headerDomain.createEffect(async (pageNumber = 1) => {
    const result = await getNotifications(pageNumber, [1, 2]);
    return result.data;
});

//stores
export const $taskData = headerDomain
    .createStore<TTaskList>([])
    .on(getTasksFx.doneData, (state, payload) => [...state, ...payload.results]);

export const $taskCount = headerDomain
    .createStore<number>(0)
    .on(getTasksFx.doneData, (state, payload) => payload.count);

export const $pageNumber = headerDomain
    .createStore<number>(0)
    .on(increasePageNumber, (state, _) => {
        if (state === 0) return 2;
        return state + 1;
    });

export const $allTasksAreLoaded = headerDomain.createStore<boolean>(false);

export const $headerStore = combine({
    tasksData: $taskData,
    taskCount: $taskCount,
    allTasksAreLoaded: $allTasksAreLoaded,
});

// загрузка первых 10 уведомлений при открытии страницы
sample({
    clock: headerGate.open,
    source: $taskData,
    filter: (taskData) => taskData.length === 0,
    target: fetchTasks,
});

// Обращение к бэку
sample({
    clock: fetchTasks,
    source: combine({
        allTasksAreLoaded: $allTasksAreLoaded,
        pageNumber: $pageNumber,
        isLoading: getTasksFx.pending,
    }),
    filter: ({allTasksAreLoaded, isLoading}) => !allTasksAreLoaded && !isLoading,
    fn: ({pageNumber}) => pageNumber,
    target: getTasksFx,
});

forward({from: getTasksFx.doneData, to: increasePageNumber});

sample({
    source: combine({
        tasksData: $taskData,
        taskCount: $taskCount,
    }),
    fn: ({tasksData, taskCount}) => tasksData.length >= taskCount,
    target: $allTasksAreLoaded,
});
