import React from 'react';
import Breadcrumb from 'shared/ui/deprecated/Breadcrumb/Breadcrumb';
import BreadcrumbItem from 'shared/ui/deprecated/Breadcrumb/BreadcrumbItem';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import HeaderText from 'shared/ui/deprecated/HeaderText/HeaderText';
import {Skeleton} from 'shared/ui/deprecated/Skeleton/Skeleton';

export function DSPageTitleSkeleton() {
    return (
        <WrapperFlex justifyContent="space-between" alignItems="flex-start">
            <div className="me-3 flex-grow-1">
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Skeleton width={70} height={18} />
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Skeleton width={70} height={18} />
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Skeleton width={70} height={18} />
                    </BreadcrumbItem>
                </Breadcrumb>
                <WrapperOffset offsetTop={12}>
                    <WrapperFlex alignItems="center">
                        <HeaderText className="me-2" component="h4">
                            <Skeleton height={32} width={160} />
                        </HeaderText>
                        <Skeleton height={24} width={126} />
                    </WrapperFlex>
                </WrapperOffset>
                <WrapperOffset offsetTop={12}>
                    <WrapperFlex alignItems="center">
                        <Skeleton height={16} />
                    </WrapperFlex>
                </WrapperOffset>
            </div>
            <WrapperFlex alignItems="center">
                <Skeleton className="me-3" height={48} width={109} />
                <Skeleton height={48} width={187} />
            </WrapperFlex>
        </WrapperFlex>
    );
}
