import {Table} from '@beeline/design-system-react';
import styled from 'styled-components/macro';

export const Cell = styled.div`
    display: flex;
    position: relative;
    gap: 8px;
    align-items: center;
`;

export const OverallWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
`;

export const PaginationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`;

export const TableWrapper = styled.div`
    width: 100%;
    min-width: 600px;
    overflow-x: auto;
    margin-bottom: 8px;
    border: var(--size-border-width-regular) solid var(--color-border);
    border-radius: var(--size-border-radius-x2);
`;

export const StyledTable = styled(Table)`
    table-layout: fixed;
    min-width: 1020px;
    width: 100%;
    border-width: 0;
    border-bottom: var(--size-border-width-regular) solid var(--color-border);
`;
