import React, {useState} from 'react';
import {Calendar, IconButton, Tooltip} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';
import styles from './FileList.module.css';
import {CALENDAR_BUTTON_TOOLTIP_BODY} from './constants';

interface FileRegistrationDateCalendarProps {
    selectedValue: string | null;
    onSave: (date: string) => void;
}

export const FileRegistrationDateCalendar = ({
    selectedValue,
    onSave,
}: FileRegistrationDateCalendarProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleCalendar = () => {
        setIsOpen((prevState) => !prevState);
    };

    return (
        <div className={styles.file__calendar_wrapper}>
            <Tooltip title={CALENDAR_BUTTON_TOOLTIP_BODY}>
                <IconButton iconName={Icons.Calendar} size="large" onClick={toggleCalendar} />
            </Tooltip>
            {isOpen && (
                <Calendar
                    className={styles.file__registration_calendar}
                    value={selectedValue ?? new Date(Date.now()).toISOString()}
                    onSelectDate={(a) => {
                        onSave(a as string);
                    }}
                    onOutsideClick={toggleCalendar}
                />
            )}
        </div>
    );
};
