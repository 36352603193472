import {combine, createDomain} from 'effector';

import {forwardPayload} from 'shared/helpers/effector';
import {TypeSelectType} from 'shared/types/typeSelectTypes';
import {ProjectStatusType} from 'shared/types/statusSelectTypes';
import {YearSelectType} from 'shared/types/yearSelectTypes';

export const projectsFilterDomain = createDomain();

export const setAppliedFilterCount = projectsFilterDomain.createEvent<number>();
export const resetProjectsFilter = projectsFilterDomain.createEvent();
export const setTypeFilter = projectsFilterDomain.createEvent<Array<TypeSelectType>>();
export const setStatusFilter = projectsFilterDomain.createEvent<Array<ProjectStatusType>>();
export const setYearFilter = projectsFilterDomain.createEvent<Array<YearSelectType>>();

export const $appliedFiltersCount = projectsFilterDomain
    .createStore<number>(0)
    .on(setAppliedFilterCount, forwardPayload())
    .reset(resetProjectsFilter);

const $typeFilter = projectsFilterDomain
    .createStore<Array<TypeSelectType>>([])
    .on(setTypeFilter, forwardPayload())
    .reset(resetProjectsFilter);

const $statusFilter = projectsFilterDomain
    .createStore<Array<ProjectStatusType>>([])
    .on(setStatusFilter, forwardPayload())
    .reset(resetProjectsFilter);

const $yearFilter = projectsFilterDomain
    .createStore<Array<YearSelectType>>([])
    .on(setYearFilter, forwardPayload())
    .reset(resetProjectsFilter);

export const projectFilterStore = combine({
    typeFilter: $typeFilter,
    statusFilter: $statusFilter,
    yearFilter: $yearFilter,
});
