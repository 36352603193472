import React, {useRef, useState} from 'react';
import {
    FileCaption,
    FileItemStyled,
    FilePic,
    FileTitle,
    FileEditableItemStyled,
    FileInput,
    FileInputWrapper,
} from 'shared/ui/deprecated/File/style';
import FileIcon from 'icons/FileIcon';
import CloseIcon from 'icons/CloseIcon';
import ReloadIcon from 'icons/ReloadIcon';
import Button from 'shared/ui/deprecated/Button/Button';
import SmallIconButton from 'shared/ui/deprecated/SmallIconButton/SmallIconButton';

export const useFilesUpload = ({files = [], accept = [], maxSize = 200}) => {
    const [_files, setFiles] = useState(files);
    const [errors, setErrors] = useState([]);

    const acceptStr = accept.join();

    // const validateSize = (file) => (file.size / 1024) <= maxSize
    const validateSize = (file) => true;
    const validateType = (file) => accept.includes(file.type);

    const addFiles = (fileList) => {
        setErrors([]);
        const filesArr = [];
        const errorsArr = [];
        fileList.forEach((file) => {
            if (!accept.length && validateSize(file)) {
                filesArr.push(file);
                return;
            }

            const error = {
                fileName: file.name,
                sizeError: true,
                acceptError: false,
            };

            if (!accept.length && !validateSize(file)) {
                error.sizeError = true;
            }

            if (accept.length) {
                const error = {
                    fileName: file.name,
                    sizeError: false,
                    acceptError: false,
                };
                if (validateType(file) && validateSize(file)) {
                    filesArr.push(file);
                    return;
                }
                if (!validateType(file) && validateSize(file)) {
                    error.acceptError = true;
                }
                if (!validateType(file) && !validateSize(file)) {
                    error.acceptError = true;
                    error.sizeError = true;
                }
                if (validateType(file) && !validateSize(file)) {
                    error.acceptError = false;
                    error.sizeError = true;
                }
            }
            errorsArr.push(error);
        });

        setErrors([...errorsArr]);

        const newFiles = filesArr.filter(
            (file) => !~_files.findIndex((item) => file.name === item.name),
        );
        setFiles((prev) => [...prev, ...newFiles]);
    };

    const removeFile = (file) => {
        setFiles((prev) => prev.filter((item) => file.name !== item.name));
    };

    return {
        files: _files,
        addFiles,
        removeFile,
        acceptStr,
        errors,
        maxSize,
    };
};

export const FileUploadButton = ({
    children = 'Добавить файл',
    accept,
    name = 'file',
    files,
    onChange = (fileList) => {},
    multiple = false,
    ...rest
}) => {
    const inputRef = useRef();

    const handleChange = (e) => {
        onChange([...e.target.files]);
        inputRef.current.value = '';
    };

    return (
        <FileInputWrapper>
            <Button {...rest}>
                <FileInput
                    type="file"
                    name={name}
                    accept={accept}
                    onChange={(e) => handleChange(e)}
                    ref={inputRef}
                    multiple={multiple}
                />
                {children}
            </Button>
        </FileInputWrapper>
    );
};

export const FileItem = (props) => {
    const {children, caption, variant = 'neutral', ...rest} = props;

    return (
        <FileItemStyled {...rest}>
            <FilePic variant={variant}>
                <FileIcon />
            </FilePic>
            <div>
                <FileTitle>{children}</FileTitle>
                <FileCaption variant={variant}>{caption}</FileCaption>
            </div>
        </FileItemStyled>
    );
};

export const FileItemEditable = (props) => {
    const {name, children, caption, variant = 'neutral', onRemove, ...rest} = props;

    return (
        <FileEditableItemStyled {...rest}>
            <div style={{display: 'flex'}}>
                <FilePic variant={variant}>
                    <FileIcon />
                </FilePic>
                <div>
                    <FileTitle>{children}</FileTitle>
                    {caption && <FileCaption variant={variant}>{caption}</FileCaption>}
                </div>
            </div>
            <div style={{display: 'flex'}}>
                {variant == 'error' && <SmallIconButton icon={<ReloadIcon />} />}
                <SmallIconButton onClick={onRemove} icon={<CloseIcon />} />
            </div>
        </FileEditableItemStyled>
    );
};

export default FileItem;
