import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';
import {
    $isDeletePatternModalOpen,
    $searchTerm,
    $selectedPattern,
    $patterns,
    deletePattern,
    deletePatternFx,
    loadPatternsFx,
    searchByTerm,
    selectPattern,
    setIsDeletePatternModalOpen,
    setSearchTerm,
    patternsPageDomain,
    PatternsPageGate,
    $pagination,
} from './index';
import {combine, forward, sample} from 'effector';

resetDomainStoresByEvents(patternsPageDomain, PatternsPageGate.close);

forward({
    from: deletePattern,
    to: deletePatternFx,
});

sample({
    clock: [
        PatternsPageGate.open,
        $pagination.$page,
        $pagination.$pageSize,
        searchByTerm,
        deletePatternFx.done,
    ],
    source: combine({
        page: $pagination.$page,
        page_size: $pagination.$pageSize,
        search: $searchTerm,
    }),
    target: loadPatternsFx,
});

$patterns.on(loadPatternsFx.doneData, (state, payload) => payload.results);

$searchTerm.on(setSearchTerm, forwardPayload());

$selectedPattern.on(selectPattern, (prevId, selectedId) =>
    prevId === selectedId ? '' : selectedId,
);

$isDeletePatternModalOpen
    .on(setIsDeletePatternModalOpen, forwardPayload())
    .reset(deletePatternFx.done);
