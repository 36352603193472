import React from 'react';
import {ButtonStyled} from 'shared/ui/deprecated/Button/style';

function Button(props) {
    const {children, leftIcon, rightIcon, fullWidth, onlyIcon, ...rest} = props;

    return (
        <ButtonStyled {...props}>
            {leftIcon}
            <span>{children}</span>
            {rightIcon}
        </ButtonStyled>
    );
}

export default Button;
