import styled from 'styled-components/macro';

export const LoginWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
`;

export const Login = styled.div`
    flex-basis: 500px;
    height: 344px;
    border: 1px solid rgba(25, 28, 52, 0.18);
    border-radius: 12px;
    padding: 48px;
`;

export const LoginTitle = styled.div`
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: #191c34b2;
`;

export const ErrorMessage = styled.div`
    width: 100%;
    margin-top: 1rem;
    font-size: 1rem;
    color: red;
`;

export const AlignedText = styled.div`
    p {
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
    }
`;

export const NoteWrapper = styled.div`
    margin: 4px 0 12px;
    text-align: center;
`;
