import React, {useEffect, useRef, useState} from 'react';
import {DropdownMenu, Icon, Label, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {TableCell} from 'shared/ui/Table/Table';
import {ESpecificationAction, TCompletedWorksSpecAction} from 'shared/types/completedWorksTypes';

import * as S from './style';
import {selectedSpecificationApplied} from '../../../../../model/stage2';
import {ISpecificationLocation} from '../../../../../model/helpers';

interface ActionCellProps {
    action: TCompletedWorksSpecAction;
    specificationLocation: ISpecificationLocation;
}

type ActionLabelProps = Pick<ActionCellProps, 'action'>;

export const ActionCell = ({action, specificationLocation}: ActionCellProps) => {
    const [dropdownShowed, setDropdownShowed] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const showDropdown = () => setDropdownShowed(true);
    const hideDropdown = () => setDropdownShowed(false);

    useEffect(() => {
        hideDropdown();
    }, [action]);

    const handleSelectAction = (action: TCompletedWorksSpecAction) => {
        selectedSpecificationApplied({
            ...specificationLocation,
            action: action,
        });
    };

    return (
        <TableCell width="172px">
            <S.Wrapper ref={ref} onClick={showDropdown}>
                <ActionLabel action={action} />
                <Icon iconName={dropdownShowed ? Icons.NavArrowUp : Icons.NavArrowDown} />
                {dropdownShowed && (
                    <DropdownMenu
                        targetElement={ref}
                        applicationRootElementID="app"
                        onOutsideClick={hideDropdown}
                    >
                        <WrapperFlex flexDirection="column">
                            <S.LabelWrapper
                                onClick={() => handleSelectAction(ESpecificationAction.DONE)}
                            >
                                <Label title="Выполнено" variant="outline" type="success" />
                            </S.LabelWrapper>
                            <S.LabelWrapper
                                onClick={() =>
                                    handleSelectAction(ESpecificationAction.NOTHING)
                                }
                            >
                                <Label title="Не требуется" variant="outline" type="warning" />
                            </S.LabelWrapper>
                        </WrapperFlex>
                    </DropdownMenu>
                )}
            </S.Wrapper>
        </TableCell>
    );
};

const ActionLabel = ({action}: ActionLabelProps) => {
    if (!action) {
        return (
            <Typography variant="body3" className="inactiveText">
                Выберите
            </Typography>
        );
    }

    return action === ESpecificationAction.DONE ? (
        <Label title="Выполнено" variant="outline" type="success" />
    ) : (
        <Label title="Не требуется" variant="outline" type="warning" />
    );
};
