import React, {useState, useRef, useMemo} from 'react';

import Search from 'shared/ui/Search/Search';
import Checkbox, {CheckboxLabel} from 'shared/ui/deprecated/Checkbox/Checkbox';
import PortalWrapper from 'shared/ui/deprecated/Portal/Portal';
import {
    SelectInput,
    SelectStyled,
    SelectText,
    SelectTitle,
    SelectSearchWrap,
    SelectList,
    SelectItemSoloStyled,
    SelectItemSoloText,
    SelectItemCheckStyled,
    SelectItemAllStyled,
    SelectItemGroupText,
    SelectItemGroupStyled,
    Hint,
} from 'shared/ui/Select/style';

export default function Select(props) {
    const {
        id,
        title,
        search = false,
        multiple = false,
        handleEdit = () => {
        },
        value = [],
        valueKey,
        fullWidth,
        fullWidthList,
        valueLabel,
        children,
        searchValue,
        setSearch,
        notAll,
        error,
        hint,
        onSelected,
        position = 'top-start',
        vertical = 0,
        horyzontal = 0,
        className = '',
        handleResetSearch,
        selectDisabled,
    } = props;

    const [active, setActive] = useState(false);
    const [all, setAll] = useState(false);
    const disabled = children.length === 0 || selectDisabled;

    if (value?.length === 0 && all === true) {
        setAll(false);
    }
    const handleSetActive = (state) => {
        if (!state && handleResetSearch) {
            handleResetSearch();
        }
        setActive(state);
    };

    const label = useMemo(() => {
        if (Array.isArray(value)) {
            return valueLabel ? value.map((item) => item[valueLabel]).join(', ') : value.join(', ');
        }

        if (typeof value === 'object' && valueLabel) {
            return value?.[valueLabel];
        }

        return value.toString();
    }, [value, valueLabel]);

    const elem = useRef(null);
    return (
        <PortalWrapper
            portal={
                <div ref={elem}>
                    {search && (
                        <SelectSearchWrap>
                            <Search value={searchValue} onInput={setSearch} />
                        </SelectSearchWrap>
                    )}
                    <SelectList className="scrollbar-vertical">
                        {multiple && children && notAll && !searchValue && (
                            <SelectItemAll
                                checked={all}
                                setChecked={setAll}
                                selectChildren={children}
                                handleEdit={handleEdit}
                            >
                                Выбрать все
                            </SelectItemAll>
                        )}
                        {React.Children.map(children, (child) => {
                            return React.cloneElement(child, {
                                multiple,
                                handleEdit,
                                setActive: handleSetActive,
                                setAll,
                                values: value,
                                valueKey,
                            });
                        })}
                    </SelectList>
                </div>
            }
            trigger={
                <SelectStyled fullWidth={fullWidth}>
                    <SelectInput
                        filled={label?.length > 0}
                        // onClick={showContent}
                        active={active}
                        error={error}
                        id={id}
                        disabled={disabled}
                    >
                        <SelectTitle filled={label?.length > 0}>{title}</SelectTitle>
                        <SelectText filled={label?.length > 0}>{label}</SelectText>
                    </SelectInput>
                    {hint && <Hint error={error}>{hint} </Hint>}
                </SelectStyled>
            }
            type="click"
            position={position}
            vertical={vertical}
            horyzontal={horyzontal}
            elem={elem}
            setActive={(isActive) => {
                if (!disabled) {
                    handleSetActive(isActive);
                }
            }}
            className={className}
            select={!disabled}
            fullWidth={fullWidthList}
            active={active}
            disabled={selectDisabled}
        />
    );
}

const SelectItemSolo = function ({
    children,
    value,
    selected,
    valueKey,
    handleEdit,
    setActive,
    disabled = false,
    id,
    ...props
}) {
    const isCheck = () => (valueKey ? selected[valueKey] === value[valueKey] : selected === value);
    const selectSolo = () => {
        if (!disabled) {
            setActive(false);
            handleEdit(value);
        }
    };
    return (
        <SelectItemSoloStyled disabled={disabled} id={id}>
            <Checkbox onChange={selectSolo} checked={isCheck()} />
            <input type="radio" name="name" onChange={selectSolo} checked={isCheck()} />
            <SelectItemSoloText nested={props?.nested}>{children}</SelectItemSoloText>
        </SelectItemSoloStyled>
    );
};

const SelectItemCheck = function ({children, value, values, valueKey, setAll, handleEdit}) {
    const isValue = (item) => (valueKey ? item[valueKey] === value[valueKey] : item === value);

    const selectCheck = (e) => {
        if (e.target.checked) {
            handleEdit([...values, value], value, e.target.checked);
        } else {
            handleEdit(
                values.filter((item) => !isValue(item)),
                value,
                e.target.checked,
            );

            setAll(false);
        }
    };
    const isCheck = () => !!~values.findIndex(isValue);

    return (
        <SelectItemCheckStyled>
            <CheckboxLabel
                title={children}
                name="name"
                value={value}
                checked={isCheck()}
                onChange={selectCheck}
            />
        </SelectItemCheckStyled>
    );
};

const SelectItem = function ({multiple, ...rest}) {
    return multiple ? <SelectItemCheck {...rest} /> : <SelectItemSolo {...rest} />;
};

const SelectItemAll = function (props) {
    const {children, values, selectChildren, checked, setChecked, handleEdit, ...rest} = props;

    const selectAll = (e) => {
        if (e.target.checked) {
            const allItems = [];
            setChecked(true);
            for (const child of selectChildren) {
                if (child.type.name === 'SelectItemGroup') {
                    child.props.children.map((item) => allItems.push(item.props.value));
                } else {
                    allItems.push(child.props.value);
                }
            }
            handleEdit(allItems, null, e.target.checked);
        } else {
            handleEdit([], null, e.target.checked);
            setChecked(false);
        }
    };

    return (
        <SelectItemAllStyled>
            <CheckboxLabel title={children} name="name" checked={checked} onChange={selectAll} />
        </SelectItemAllStyled>
    );
};

const SelectItemGroup = function (props) {
    const {children, title, ...rest} = props;
    return (
        <div {...props}>
            <SelectItemGroupText>{title}</SelectItemGroupText>
            <SelectItemGroupStyled>
                {React.Children.map(children, (child) => {
                    return React.cloneElement(child, {
                        ...rest,
                    });
                })}
            </SelectItemGroupStyled>
        </div>
    );
};

export {
    SelectItemSolo,
    SelectItemCheck,
    SelectItem,
    SelectItemAll,
    SelectItemGroup,
    SelectItemCheckStyled,
};
