import styled from 'styled-components/macro';

export const TableWrapper = styled.div`
    border: 1px solid var(--color-divider-grey);
    border-radius: var(--size-border-radius-x2);
`;

export const TotalTableWrapper = styled.div`
    padding: 0 32px;
    background-color: var(--color-divider-grey);
`;
