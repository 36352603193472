import {
    ConstructorVirType,
    ConstructorVirWorkObject,
    IUpdatedAgreement,
} from 'shared/types/additionalAgreementsTypes';
import {set} from 'lodash';
import {getPathToWorks} from './path';
import {TpiSpecification} from 'pages/VirSelect/types';

export function setUpdatesToVirSpecs({
    ds,
    virId,
    workObjectId,
    newSpecifications,
}: {
    ds: IUpdatedAgreement;
    virId: string;
    workObjectId: string;
    newSpecifications: Record<string, TpiSpecification>;
}) {
    const dsClone = {...ds};

    set(
        dsClone,
        `works.${virId}.work_objects.${workObjectId}.work_specifications`,
        newSpecifications,
    );
    return dsClone;
}
export function setUpdatesToWorkObject({
    ds,
    virId,
    workObjectId,
    newWorkObject,
}: {
    ds: IUpdatedAgreement;
    virId: string;
    workObjectId: string;
    newWorkObject: Record<string, ConstructorVirWorkObject>;
}) {
    const dsClone = {...ds};

    set(dsClone, `works.${virId}.work_objects.${workObjectId}`, newWorkObject);
    return dsClone;
}

export function setUpdateToVir({
    ds,
    updatedWorks,
}: {
    ds: IUpdatedAgreement;
    updatedWorks: Record<string, ConstructorVirType>;
}) {
    const dsClone = {...ds};
    set(dsClone, getPathToWorks(), updatedWorks);
    return dsClone;
}
