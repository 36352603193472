import styled from 'styled-components/macro';
import Search from 'shared/ui/Search/Search';

export const Cell = styled.div`
    display: flex;
    position: relative;
    gap: 8px;
    align-items: center;
`;

export const Value = styled.span`
    min-width: 20px;
`;

export const StyledSearch = styled(Search)`
    min-width: 411px;
`;
