import React, {useMemo} from 'react';
import styled from 'styled-components/macro';
import FilterSearchBtn from 'components/FilterSearchBtn';
import SearchIcon from 'icons/SearchIcon';
import {SearchStyled} from 'shared/ui/Search/style';
import CloseBtn from 'components/CloseBtn';

const SearchBtn = styled.button`
    position: absolute;
    width: 24px;
    height: 24px;
    top: var(--tsearchbtn);
    left: 16px;
    padding: 0;
    background-color: transparent;
    border: none;
    transition-duration: 0.4s;

    .icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

export default function Search(props) {
    const {
        placeholder = 'Поиск',
        filter = false,
        value = '',
        onInput = () => {},
        onSearch,
        ...rest
    } = props;

    const active = useMemo(() => {
        return Boolean(value);
    }, [value]);
    const propsWithDefaults = {...rest, placeholder, active, filter: filter ? 1 : 0};
    return (
        <SearchStyled {...propsWithDefaults}>
            <SearchBtn id="searchIconBtn" onClick={() => value && onSearch(value)}>
                <SearchIcon />
            </SearchBtn>
            <input
                id="searchInput"
                value={value}
                onInput={(e) => {
                    onInput(e.target.value);
                }}
                onKeyPress={(event) => {
                    if (event.key === 'Enter' && onSearch) {
                        onSearch(event.target.value);
                    }
                }}
                type="text"
                placeholder={placeholder}
            />
            <CloseBtn
                id="searchCloseBtn"
                onClick={() => {
                    onInput('');
                    onSearch && onSearch('');
                }}
            />
            {filter ? <FilterSearchBtn /> : ''}
        </SearchStyled>
    );
}
