import React from 'react';
import {useStore} from 'effector-react';
import {Skeleton, TableBody, TableHead, TableRow} from '@beeline/design-system-react';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {$userActions} from 'shared/model/user';

import {taskColumns} from './taskColumns';
import * as S from '../style';
import {$ProcessesPageStore} from '../../../model';

interface TasksTableProps {
    changeTableSort: (data: string) => void;
}

export const TasksTable = ({changeTableSort}: TasksTableProps) => {
    const {
        tasksStore,
        isLoading,
        taskParams: {
            filters: {page_size, ordering},
            tasksColumns: tasksColumnsTest,
        },
    } = useStore($ProcessesPageStore);

    const userActions = useStore($userActions);

    const overallWidth = '1437px';
    return (
        <>
            <TableHead>
                <TableRow>
                    {taskColumns.map(
                        (column) =>
                            (tasksColumnsTest.find((taskItem) => taskItem.id === column.name)
                                ?.visible ||
                                column.noHiding) && (
                                <S.StyledTableHeaderData
                                    key={`${column.name}_head`}
                                    width={column.width ?? `calc((100%-${overallWidth}) / 2)`}
                                    onClick={() =>
                                        column.hasSorting && changeTableSort(column.name)
                                    }
                                    $alreadySorting={!!ordering}
                                    $arrowUp={column.name === ordering}
                                    $arrowDown={
                                        `-${column.name}` === ordering ||
                                        (ordering === '' && column.name === 'created_at')
                                    }
                                    $hover={column.hasSorting}
                                >
                                    <WrapperFlex>{column.label}</WrapperFlex>
                                </S.StyledTableHeaderData>
                            ),
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {!isLoading &&
                    tasksStore &&
                    tasksStore.map((item) => (
                        <TableRow key={`${item.created_at}_row`} dense>
                            {taskColumns.map(
                                (column) =>
                                    (tasksColumnsTest.find(
                                        (taskItem) => taskItem.id === column.name,
                                    )?.visible ||
                                        column.noHiding) && (
                                        <S.StyledTableData
                                            width={column.width}
                                            key={column.name}
                                            height="53px"
                                        >
                                            {column.render && column.render(item, userActions)}
                                        </S.StyledTableData>
                                    ),
                            )}
                        </TableRow>
                    ))}
                {isLoading &&
                    [...Array(page_size ?? 10)].map((item, index) => {
                        return (
                            <TableRow key={`skeleton_${index}`} dense>
                                {taskColumns.map(
                                    (column) =>
                                        (tasksColumnsTest.find(
                                            (taskItem) => taskItem.id === column.name,
                                        )?.visible ||
                                            column.noHiding) && (
                                            <S.StyledTableData
                                                width={
                                                    column.width ??
                                                    `calc((100%-${overallWidth}) / 2)`
                                                }
                                                key={column.name}
                                                height="53px"
                                            >
                                                <Skeleton variant="line" height={18} width="80%" />
                                            </S.StyledTableData>
                                        ),
                                )}
                            </TableRow>
                        );
                    })}
            </TableBody>
        </>
    );
};
