import React, {ReactNode, useState} from 'react';
import ButtonList from 'shared/ui/deprecated/ButtonList/ButtonList';

import {SubMenu} from 'shared/ui/Menu/ui/SubMenu';

interface MenuProps {
    title: string;
    width?: string;
    disabled?: boolean;
    options: Array<{
        item: ReactNode;
        action?: () => void;
        subMenu?: Array<{label: string; menuHandler: () => void}>;
    }>;
    maxHeight?: boolean;
}

export const Menu = ({title, options, width, disabled, maxHeight}: MenuProps) => {
    const [activeSubMenu, setActiveSubMenu] = useState<number>(0);

    const openSubMenu = (index: number) => {
        setActiveSubMenu(index);
    };

    const closeSubMenu = () => {
        setActiveSubMenu(0);
    };

    return (
        <ButtonList
            disabled={disabled}
            width={width}
            color="outline"
            title={title}
            handleCloseOutside={closeSubMenu}
            maxHeight={maxHeight}
            list={options.map((el, index) => {
                return {
                    item: (
                        <>
                            {el.item}
                            {activeSubMenu === index + 1 && !!el.subMenu?.length && (
                                <SubMenu>
                                    {el?.subMenu?.map((sub) => (
                                        <div
                                            key={sub.label}
                                            onClick={() => {
                                                closeSubMenu();
                                                sub.menuHandler();
                                            }}
                                        >
                                            {sub.label}
                                        </div>
                                    ))}
                                </SubMenu>
                            )}
                        </>
                    ),
                    action: el.action,
                    onMouseEnter: () => el.subMenu && openSubMenu(index + 1),
                    onMouseLeave: () => closeSubMenu(),
                };
            })}
        />
    );
};
