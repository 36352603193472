import {differenceInCalendarDays} from 'date-fns';

export const getFutureDate = (dayGap: number, yearGap?: number): Date => {
    const date = new Date();
    date.setDate(date.getDate() + dayGap);
    if (yearGap) {
        date.setFullYear(date.getFullYear() + yearGap);
    }
    return date;
};

export const compareDates = (firstDate: Date, secondDate: Date): boolean =>
    differenceInCalendarDays(secondDate, firstDate) >= 0;
