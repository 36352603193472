import styled from 'styled-components/macro';
import {Tooltip} from 'shared/ui/deprecated/Tooltip/Tooltip';
import {TableCell, TableCellContent} from 'shared/ui/Table/Table';

interface PositionCellProps {
    number: string;
    coefficient: number;
    appliedCoefficientNumbers: string;
}

export function PositionCell(props: PositionCellProps) {
    const {number, coefficient, appliedCoefficientNumbers} = props;
    return (
        <TableCell width="80px">
            <TableCellContent className="col-4">
                <Indexes>
                    <span>{number}</span>
                    {!!coefficient && (
                        <Tooltip
                            titleText={appliedCoefficientNumbers}
                            position="top-start"
                            vertical={12}
                            horyzontal={15}
                        >
                            <CoefficientIndex>{appliedCoefficientNumbers}</CoefficientIndex>
                        </Tooltip>
                    )}
                </Indexes>
            </TableCellContent>
        </TableCell>
    );
}

export const Indexes = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CoefficientIndex = styled.div`
    color: var(--secondary);
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    -ms-box-orient: vertical;
    -moz-line-clamp: 2;
    -ms-line-clamp: 2;
    line-clamp: 2;
    line-height: 1.2;
    overflow: hidden;
`;
