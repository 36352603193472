import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {CreationHeader} from 'widgets/CreationHeader';

import {Stage2} from './Stage2/Stage2';
import {TMode} from '../model/helpers';

export const CompletedWorksEditingProcess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    if (location.state === null) {
        navigate(location.pathname.split('/editing')[0]);
    }
    const mode = (location.state?.mode ?? 'editing') as TMode;
    return (
        <div>
            <CreationHeader
                title="Редактирование ВВР"
                onClose={() => navigate(-1)}
                modalTitle="Завершить редактирование ВВР?"
            />
            <Stage2 mode={mode} />
        </div>
    );
};
