import React from 'react';
import {IconButton, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

interface TitleWithBackButtonProps {
    title: string;
    onClick: () => void;
}

export const TitleWithBackButton = ({title, onClick}: TitleWithBackButtonProps) => {
    return (
        <WrapperFlex alignItems="center" gap="16px">
            <IconButton iconName={Icons.ArrowLeft} onClick={onClick} size="large" />
            <Typography variant="h4">{title}</Typography>
        </WrapperFlex>
    );
};
