import {forwardPayload} from 'shared/helpers/effector';
import {forward, sample} from 'effector';
import {addLabelToWorkOptionBasedOnType} from 'shared/helpers/formatHelper';
import {
    $contractOptions,
    $patternName,
    $projectId,
    $selectedContract,
    $selectedTpi,
    $selectedWorkObject,
    $tpiOptions,
    $templateName,
    $workObjectsOptions,
    changePatternName,
    loadContractsFx,
    loadTpisFx,
    loadCatalogWorkObjectsFx,
    NewPatterPageGate,
    selectContract,
    selectTpi,
    selectWorkObject,
} from './index';
import {ITpiCatalog} from 'pages/VirStart/types';

$patternName.on(changePatternName, (state, payload) => payload);

forward({
    from: NewPatterPageGate.open,
    to: loadContractsFx,
});

sample({
    clock: NewPatterPageGate.open,
    source: $projectId,
    filter: (id) => !!id,
    fn: (id) => ({project_id: id}),
    target: loadCatalogWorkObjectsFx,
});

sample({
    clock: selectContract,
    source: $selectedContract,
    filter: (contract) => !!contract?.contract_id,
    fn: (contract) => ({framework_contract_id: contract?.contract_id || ''}),
    target: loadTpisFx,
});
$projectId.on(NewPatterPageGate.open, forwardPayload());
$selectedWorkObject.on(selectWorkObject, forwardPayload());
$selectedContract
    .on(selectContract, forwardPayload())
    .on(loadContractsFx.doneData, (state, {data: {results}}) =>
        results.length === 1
            ? {contract_id: results[0].id, contract_number: results[0].number}
            : state,
    );
$selectedTpi.on(selectTpi, forwardPayload());

sample({
    source: $tpiOptions,
    fn: (source) => {
        return source.length === 1 ? source[0] : null;
    },
    target: $selectedTpi,
});

$tpiOptions.on(loadTpisFx.doneData, (state, {data: {results}}) => {
    const result: ITpiCatalog[] = [];
    results.forEach((el) => {
        if ((el.remote_territories?.length ?? 0) > 0) {
            el.remote_territories?.forEach((item) => {
                result.push({
                    tpis_id: el.id,
                    tpis_name: `${el.name} (${item.name})`,
                    remote_territory_id: item.id,
                    purchasing_event_number: el.purchasing_event_number,
                });
            });
        } else {
            result.push({
                tpis_id: el.id,
                tpis_name: el.name,
                purchasing_event_number: el.purchasing_event_number,
            });
        }
    });
    return result;
});

$templateName.on(changePatternName, forwardPayload());

$workObjectsOptions.on(loadCatalogWorkObjectsFx.doneData, (state, payload) =>
    addLabelToWorkOptionBasedOnType(payload.data),
);
$contractOptions.on(loadContractsFx.doneData, (state, {data: {results}}) =>
    results.map((el) => ({
        contract_id: el.id,
        contract_number: el.number,
    })),
);
