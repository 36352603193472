import {useState} from 'react';
import DeleteIcon from 'icons/DeleteIcon';
import {formatNumberToDecimalWithComma} from 'shared/helpers/formatHelper';
import {WorkCoefficientTypeNullish} from 'shared/types/additionalAgreementsTypes';
import PlusIcon from 'icons/PlusIcon';
import {useStore} from 'effector-react';
import * as S from './style';
import {
    $customCoefficientTab,
    addCoefficient,
    deleteCoefficient,
    updateExistingCoefficient,
} from '../../../model/otherCoeficientsTab';

export interface AddedCoefficientItemProps {
    coefficient: WorkCoefficientTypeNullish;
    index: number;
}

export function AddedCoefficientItem({coefficient, index}: AddedCoefficientItemProps) {
    const [inputValue, setInputValue] = useState('');
    const [editMode, setEditMode] = useState(false);
    const {customCoefficients} = useStore($customCoefficientTab);
    return (
        <S.AddCoefficientBlock>
            <S.StyledMaskInput
                value={
                    editMode ? inputValue : formatNumberToDecimalWithComma(coefficient.coefficient)
                }
                // mask={editMode ? 'numberWithComma' : ''}
                mask="numberWithComma"
                onInput={({target: {value}}) => {
                    setInputValue(value);
                    updateExistingCoefficient({id: coefficient.id, newValue: value || null});
                }}
                // onBlur={(e) => {
                //     setEditMode(false);
                // }}
                onAccept={(value) => setInputValue(value)}
                onFocus={() => {
                    setEditMode(true);
                }}
                size="large"
                placeholder="Введите значение"
                title="Коэффициент"
            />
            {index !== customCoefficients.length - 1 && (
                <S.StyledButton onClick={() => deleteCoefficient(coefficient.id)}>
                    <DeleteIcon />
                    Удалить
                </S.StyledButton>
            )}
            {index === customCoefficients.length - 1 && (
                <S.StyledButton
                    onClick={() => {
                        addCoefficient();
                    }}
                >
                    <PlusIcon />
                    Добавить
                </S.StyledButton>
            )}
        </S.AddCoefficientBlock>
    );
}
