import {createGate} from 'effector-react';
import {combine, createDomain} from 'effector';
import {additionalAgreementService} from '../service';
import {AdditionalAgreementFull, AdditionalAgreementQueryParams, ITaskResponse} from '../types';
import {ProjectType} from 'shared/services/types/types';
import {getProjectData} from 'shared/services/projects.service';

import {
    postApproveVirItem,
    postAgreementComment,
    fetchAgreementFile,
    fetchDiadocArchive,
} from 'shared/services/ds.service';
import {AgreementObjectType} from 'shared/types/agreementObjectTypes';
import {fetchFileWithValidation} from 'shared/services/files.service';
import {downloadBlobFile} from 'shared/helpers/blob';
import {TTaskList} from 'shared/types/tasksTypes';
import {EAttachmentType} from 'shared/types/FileTypes';
import {EAdditionalAgreementType} from 'shared/types/additionalAgreementsTypes';

export const additionalAgreementDomain = createDomain();
export const AdditionalAgreementPageGate = createGate<{projectId?: string; agreementId?: string}>();

export const getAdditionalAgreementFx = additionalAgreementDomain.createEffect(
    additionalAgreementService.getAdditionalAgreement,
);
export const getProjectDataFx = additionalAgreementDomain.createEffect(async (projectId) => {
    const result = await getProjectData(projectId);
    return result.data;
});
export const getTasksListFx = additionalAgreementDomain.createEffect(
    async (params: AdditionalAgreementQueryParams) => {
        const result = await additionalAgreementService.getTasksList(params);
        return result.data;
    },
);
export const getTaskFx = additionalAgreementDomain.createEffect(additionalAgreementService.getTask);

export const updateTaskFx = additionalAgreementDomain.createEffect(
    additionalAgreementService.updateTask,
);

export const acceptTaskFx = additionalAgreementDomain.createEffect(
    additionalAgreementService.acceptTask,
);

export const cancelTaskFx = additionalAgreementDomain.createEffect(
    additionalAgreementService.cancelTask,
);
export const downloadAttachmentFileFx = additionalAgreementDomain.createEffect(
    async (id: string) => {
        try {
            const result = await fetchAgreementFile(id);
            if (result.status === 200) {
                const blob = new Blob([result.data]);
                const fileName = result.request
                    .getResponseHeader('content-disposition')
                    .split("filename*=utf-8''")[1]
                    .split(';')[0];
                downloadBlobFile(blob, decodeURIComponent(fileName));
            }
        } catch (e) {
            console.warn(e);
        }
    },
);

export const sendApprovedItemFx = additionalAgreementDomain.createEffect(
    async (data: {id: string; action: boolean | null}) => {
        const result = await postApproveVirItem(data);
        return {data, result: result.status === 200};
    },
);

export const sendAgreementCommentFx = additionalAgreementDomain.createEffect(
    async (data: {id: string; comment: string}) => {
        const result = await postAgreementComment(data);
        return result.data;
    },
);

export const getDsAttachmentFx = additionalAgreementDomain.createEffect(
    async ({fileName, fileId, type}: {fileName: string; fileId: string; type: EAttachmentType}) => {
        try {
            const result = await fetchFileWithValidation(fileId, type);
            if (result.status === 200) {
                const blob = new Blob([result.data]);
                downloadBlobFile(blob, fileName);
            }
        } catch (e) {
            console.error(e);
        }
    },
);

export const downloadDiadocArchiveFx = additionalAgreementDomain.createEffect(
    async (id: string) => {
        try {
            const result = await fetchDiadocArchive(id);
            if (result.status === 200) {
                const blob = new Blob([result.data]);
                const fileName = result.request
                    .getResponseHeader('content-disposition')
                    .split(`filename*=utf-8"`)[1]
                    .split(';')[0];
                downloadBlobFile(blob, decodeURIComponent(fileName));
            }
        } catch (e) {
            console.warn(e);
        }
    },
);

export const getVirAttachmentFx = additionalAgreementDomain.createEffect(
    async ({fileName, fileId, type}: {fileName: string; fileId: string; type: EAttachmentType}) => {
        try {
            const result = await fetchFileWithValidation(fileId, type);

            if (result.status === 200) {
                const blob = new Blob([result.data]);
                downloadBlobFile(blob, fileName);
            }
        } catch (e) {
            console.error(e);
        }
    },
);

//events
export const setActiveVir = additionalAgreementDomain.createEvent<string>();
export const downloadButtonClicked = additionalAgreementDomain.createEvent();
export const handleBackendBtn = additionalAgreementDomain.createEvent<number>();
export const handleClickPositiveBtn = additionalAgreementDomain.createEvent<number>();
export const handleAcceptWorkflowBtn = additionalAgreementDomain.createEvent();
export const returnWorkflowBtnClicked = additionalAgreementDomain.createEvent();
export const resetTaskButtons = additionalAgreementDomain.createEvent();
export const sendApprovedItem = additionalAgreementDomain.createEvent<{
    id: string;
    action: boolean | null;
}>();
export const sendAgreementComment = additionalAgreementDomain.createEvent<{
    id: string;
    comment: string;
}>();
export const getDsAttachment = additionalAgreementDomain.createEvent<{
    fileName: string;
    fileId: string;
    type: EAttachmentType;
}>();
export const getVirAttachment = additionalAgreementDomain.createEvent<{
    fileName: string;
    fileId: string;
    type: EAttachmentType;
}>();
export const showWarningBanner = additionalAgreementDomain.createEvent();
export const diadocArchiveDownloaded = additionalAgreementDomain.createEvent<string>();
export const errorSubPageToggled = additionalAgreementDomain.createEvent<boolean>();
export const workflowButtonIndexSet = additionalAgreementDomain.createEvent<number>();
export const currentWidthChanged = additionalAgreementDomain.createEvent<number>();

//stores
export const $additionalAgreement =
    additionalAgreementDomain.createStore<AdditionalAgreementFull | null>(null);
export const $agreementType =
    additionalAgreementDomain.createStore<EAdditionalAgreementType | null>(null);
export const $project = additionalAgreementDomain.createStore<ProjectType | null>(null);
export const $isGPO = additionalAgreementDomain.createStore(false);
export const $isVK = additionalAgreementDomain.createStore(false);
export const $dsPageParams = additionalAgreementDomain.createStore<{
    projectId: string;
    id: string;
}>({
    projectId: '',
    id: '',
});
export const $activeVir = additionalAgreementDomain.createStore('');
export const $tasksData = additionalAgreementDomain.createStore<TTaskList>([]);
export const $taskButtons = additionalAgreementDomain.createStore<ITaskResponse | null>(null);
export const $error = additionalAgreementDomain.createStore(false);

export const $buttonsDisabled = additionalAgreementDomain.createStore<boolean>(false);
export const $agreementObject = additionalAgreementDomain.createStore<AgreementObjectType | null>(
    null,
);
export const $loadingAttachmentId = additionalAgreementDomain.createStore<string>('');
export const $virState = additionalAgreementDomain.createStore<
    {id: string; index: number; hasOpened: boolean}[]
>([]);
export const $warningBannerShowed = additionalAgreementDomain.createStore<boolean>(false);
export const $errorSubPageShowed = additionalAgreementDomain.createStore<boolean>(false);
export const $clickedWorkflowButtonIndex = additionalAgreementDomain.createStore<number | null>(
    null,
);
// стор используется для вычислений ширины блока итоговых сумм
export const $currentWidth = additionalAgreementDomain.createStore(0);

export const additionalAgreementPage = combine({
    agreement: $additionalAgreement,
    agreementType: $agreementType,
    project: $project,
    isLoadingAgreement: getAdditionalAgreementFx.pending,
    isLoadingProject: false,
    error: $error,
    activeVir: $activeVir,
    isGPO: $isGPO,
    isVK: $isVK,
    isLoadingAttachment: getDsAttachmentFx.pending,
    loadingAttachmentId: $loadingAttachmentId,
    virState: $virState,
    warningBannerShowed: $warningBannerShowed,
});
