import styled, {css} from 'styled-components/macro';
import {
    Icon,
    Table,
    TableData,
    TableHeaderData,
    TableRow,
    Typography,
} from '@beeline/design-system-react';

export const StyledSpan = styled.span<{
    lineCount?: number;
    noCursor?: boolean;
    noOverflow?: boolean;
    typography?: boolean;
    textAlign?: string;
    wordBreak?: string;
}>`
    ${(props) =>
        css`
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: ${props.lineCount ?? 2};
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
            word-wrap: break-word;
        `}
    ${(props) =>
        props.typography &&
        css`
            p {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: ${props.lineCount ?? 2};
                text-overflow: ellipsis;
                overflow: hidden;
            }
        `}
  ${(props) =>
        props.noCursor &&
        css`
            cursor: default;
        `}
  ${(props) =>
        props.noOverflow &&
        css`
            overflow: visible;
        `}
  ${(props) =>
        props.textAlign &&
        css`
            text-align: ${props.textAlign};
        `}${(props) =>
        props.wordBreak &&
        css`
            word-break: ${props.wordBreak};
        `}
`;

export const NonMvpItem = styled.div`
    display: none;
`;

export const StyledTableData = styled(TableData)`
    .dsb_table-cell__content {
        width: 100%;
    }
`;

export const LinkedTypography = styled(Typography)`
    color: var(--color-text-link);
    cursor: pointer;
`;

export const OuterTableRow = styled(TableRow)`
    height: 52px;
`;

export const InnerTable = styled(Table)`
    table-layout: fixed;
    padding: 32px;
    border-width: 0;
    min-width: 100%;
`;

export const InnerTableRow = styled(TableRow)`
    height: 52px;
    box-shadow: 0 0 0 0 #fdc435, inset 4px 0 0 0 #fdc435;
`;

export const ExpandedStyledInnerTableData = styled(TableData)`
    padding: 20px 20px 0;
    box-shadow: 0 0 0 0 #fdc435, inset 4px 0 0 0 #fdc435;

    .dsb_table-cell_flex {
        width: 100%;

        .dsb_table-cell__content {
            width: 100%;
        }
    }
`;

export const StyledTableHeaderData = styled(TableHeaderData)<{
    $hover?: boolean;
    $arrowUp?: boolean;
    $arrowDown?: boolean;
    $alreadySorting?: boolean;
    width?: string;
    minWidth?: string;
    $centerAlign?: boolean;
}>`
    ${({width}) =>
        width &&
        css`
            width: ${width};
            max-width: ${width};
        `}
    ${({$centerAlign}) =>
        $centerAlign &&
        css`
            > div {
                display: flex;
                justify-content: center;
            }
        `}
  ${({minWidth}) =>
        minWidth &&
        css`
            @media (max-width: 1200px) {
                width: ${minWidth};
                max-width: ${minWidth};
            }
        `}
  ${({$hover, $alreadySorting}) =>
        $hover &&
        css`
            cursor: pointer;

            > div {
                ::after {
                    content: '';
                    width: 20px;
                    height: 20px;
                }

                &:hover {
                    ::after {
                        content: 'arrow_up';
                        font-family: 'BeelineIcons', system-ui;
                        font-size: 20px;
                        color: var(--color-text-inactive);
                        ${$alreadySorting &&
                        css`
                            opacity: 0.37;
                        `}
                    }
                }
            }
        `}
  ${({$arrowUp}) =>
        $arrowUp &&
        css`
            > div {
                ::after {
                    content: 'arrow_up';
                    font-family: 'BeelineIcons', system-ui;
                    font-size: 20px;
                    color: var(--color-text-inactive);
                }
            }
        `}
  ${({$arrowDown}) =>
        $arrowDown &&
        css`
            > div {
                ::after {
                    content: 'arrow_down';
                    font-family: 'BeelineIcons', system-ui;
                    font-size: 20px;
                    color: var(--color-text-inactive);
                }
            }
        `}
`;

export const CustomTableData = styled(TableData)<{width?: string; height?: string}>`
    .dsb_table-cell_flex {
        width: 100%;

        .dsb_table-cell__content {
            width: 100%;
        }
    }

    ${({width}) =>
        width &&
        css`
            max-width: ${width};
            min-width: ${width};
        `}
    ${({height}) =>
        height &&
        css`
            max-height: ${height};
            min-height: ${height};
        `}
`;
// обертка для отображения компонентов по центру родителя
export const Wrapper = styled.div`
    max-width: 628px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const PlainIcon = styled(Icon)<{$withMargin?: boolean}>`
    color: var(--color-button-plain);
    ${(props) =>
        props.$withMargin &&
        css`
            margin-left: -12px;
        `}
`;
