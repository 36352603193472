import {createGate} from 'effector-react';
import {combine, createDomain} from 'effector';
import {coefficientsService} from 'pages/ApplyCoefficientsPage/service';
import {COEFFICIENTS_ACTIVE_TAB} from 'pages/ApplyCoefficientsPage/types';
import {UpdateCurrentSpec} from 'processes/contructor/model/stores';
import {PathToSpecWithSpec} from 'processes/contructor';
import {WorkCoefficientType} from 'shared/types/additionalAgreementsTypes';

export const applyCoefficientsDomain = createDomain();

export const ApplyCoefficientsGate = createGate<string>();

export const loadCoefficientsFx = applyCoefficientsDomain.createEffect(
    coefficientsService.getCoefficients,
);
export const selectTpiToConfigureCoefficient =
    applyCoefficientsDomain.createEvent<PathToSpecWithSpec>();
export const $tpiToConfigCoefficient = applyCoefficientsDomain.createStore<Omit<
    UpdateCurrentSpec,
    'valuesToUpdate'
> | null>(null);

export const setActiveTab = applyCoefficientsDomain.createEvent();

export const $branchName = applyCoefficientsDomain.createStore('');

export const $activeTab = applyCoefficientsDomain.createStore<COEFFICIENTS_ACTIVE_TAB>(
    COEFFICIENTS_ACTIVE_TAB.INCREASING,
);

export const $mainCoefficient = applyCoefficientsDomain.createStore<number>(1);
export const $formula = applyCoefficientsDomain.createStore<string>('');

export const $coefficients = applyCoefficientsDomain.createStore<{
    remoteTerritory: number | null;
    ams: number | null;
    foundation: number | null;
}>({
    remoteTerritory: null,
    ams: null,
    foundation: null,
});

export const $allSelectedCoefficients = applyCoefficientsDomain.createStore<WorkCoefficientType[]>(
    [],
);

export const coefficientsPage = combine({
    coefficients: $coefficients,
    activeTab: $activeTab,
    branchName: $branchName,
    mainCoefficient: $mainCoefficient,
    formula: $formula,
    tpiToConfigCoefficient: $tpiToConfigCoefficient,
    selectedCoefficients: $allSelectedCoefficients,
});
