import styled from 'styled-components/macro';
import {Icon} from '@beeline/design-system-react';

export const ItemPriceContainer = styled.div`
    min-width: 82px;
    max-width: 82px;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const EmptyIcon = styled.div`
    flex: 0 0 28px;
    height: 5px;
`;

export const StyledIcon = styled(Icon)`
    margin: 8px 8px 6px 0;
`;
