import React, {useState} from 'react';
import {useGate, useStore} from 'effector-react';
import {useNavigate, useParams} from 'react-router-dom';
import {BodyContent, MainWrapper} from 'components/PageWraps';
import HeaderVir from 'components/HeaderVir/HeaderVir';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {H4} from 'shared/ui/deprecated/HeaderText/HeaderText';
import {SelectItem} from 'shared/ui/Select/Select';
import Button from 'shared/ui/deprecated/Button/Button';
import {EWorkObjectTypes} from 'shared/types/additionalAgreementsTypes';
import {resetAdditionalAgreementCreation} from 'processes/contructor/model/stores';
import * as S from './styled';
import {
    $newPatternPage,
    changePatternName,
    NewPatterPageGate,
    selectContract,
    selectTpi,
    selectWorkObject,
} from '../model';

export function NewVirPattern() {
    const {projectId} = useParams();
    const navigate = useNavigate();
    useGate(NewPatterPageGate, projectId);
    const {
        selectedContract,
        selectedName,
        selectedTpi,
        contractOptions,
        tpiOptions,
        selectedWorkObject,
        workProjectOptions,
    } = useStore($newPatternPage);
    const [touched, setTouched] = useState(false);
    const errors = {
        name: touched && !selectedName,
        object: touched && !selectedWorkObject,
        contract: touched && !selectedContract,
        tpi: touched && !selectedTpi,
    };
    const noErrors = touched && !Object.values(errors).filter(Boolean).length;
    const filledForm = selectedContract && selectedName && selectedTpi && selectedWorkObject;
    const validForm = noErrors || filledForm;

    const handleCancelCreation = () => {
        navigate(`/vir/${projectId}/patterns`);
        resetAdditionalAgreementCreation();
    };
    const handleCreatePattern = () => {
        if (validForm) {
            navigate(`/projects/${projectId}/additional-agreement/select`);
        }
        setTouched(true);
    };
    return (
        <MainWrapper>
            <HeaderVir />
            <BodyContent>
                <WrapperOffset offset={32} className="w-100">
                    <S.Content>
                        <H4 className="mb-4">Создать шаблон</H4>
                        <S.StyledInput
                            helper={errors.name && 'Введите название шаблона'}
                            error={errors.name}
                            size="large"
                            placeholder="Название шаблона"
                            value={selectedName}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                changePatternName(e.target.value);
                            }}
                        />
                        <S.StyledSelect
                            error={errors.object}
                            value={selectedWorkObject}
                            valueLabel="label"
                            handleEdit={selectWorkObject}
                            title="Объект"
                            hint={errors.object && 'Выберите объект'}
                        >
                            {workProjectOptions.map((item) => {
                                return (
                                    <SelectItem
                                        key={item?.pos_code}
                                        child={!!item.nested}
                                        multiple={false}
                                        value={item}
                                        selected={selectedWorkObject}
                                    >
                                        <S.MainText>
                                            {item.label}
                                            {item.label && (
                                                <S.SubText>Пролёт (полный комплект)</S.SubText>
                                            )}
                                        </S.MainText>
                                        <S.SubText>
                                            {item.object_type === EWorkObjectTypes.MAIN_PART &&
                                                'Основная часть (полукомплект)'}
                                            {item.object_type === EWorkObjectTypes.RESPONSE_PART &&
                                                'Ответная часть (полукомплект)'}
                                        </S.SubText>
                                    </SelectItem>
                                );
                            })}
                        </S.StyledSelect>

                        <S.StyledSelect
                            error={errors.contract}
                            value={selectedContract}
                            valueLabel="contract_number"
                            handleEdit={selectContract}
                            title="Договор"
                            placeholder="Договор"
                            hint={errors.contract && 'Выберите договор для привязки к шаблону'}
                        >
                            {contractOptions.map((item) => (
                                <SelectItem
                                    selected={selectedContract}
                                    key={item.contract_id}
                                    value={item}
                                    multiple={false}
                                >
                                    {item.contract_number}
                                </SelectItem>
                            ))}
                        </S.StyledSelect>

                        <S.StyledSelect
                            disabled={!tpiOptions.length}
                            error={errors.tpi}
                            value={selectedTpi || ''}
                            valueLabel="tpis_name"
                            handleEdit={selectTpi}
                            title="ТЦП"
                            placeholder="ТЦП"
                            hint={errors.tpi && 'Выберите ТЦП'}
                        >
                            {tpiOptions.map((item, index) => (
                                <SelectItem
                                    selected={selectedTpi}
                                    key={`${item.tpis_id}_${index}`}
                                    value={item}
                                    multiple={false}
                                >
                                    {item.tpis_name}
                                </SelectItem>
                            ))}
                        </S.StyledSelect>

                        <S.Buttons>
                            <Button color="outline" onClick={handleCancelCreation}>
                                Отменить
                            </Button>
                            <Button type="submit" color="contained" onClick={handleCreatePattern}>
                                Создать
                            </Button>
                        </S.Buttons>
                    </S.Content>
                </WrapperOffset>
            </BodyContent>
        </MainWrapper>
    );
}
