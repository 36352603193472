import {createGate} from 'effector-react';
import {combine, createDomain} from 'effector';

import {getAllAdditionalAgreements} from 'shared/services/ds.service';
import {IAdditionalAgreementListItem} from 'shared/types/additionalAgreementsTypes';

import {Branch, Region} from '../types/agreement';
import {additionalAgreementsService} from '../service/agreements.service';

export const additionalAgreementsDomain = createDomain();

export const AdditionalAgreementsGate = createGate<string>();

export const loadAdditionalAgreementsFx = additionalAgreementsDomain.createEffect(
    getAllAdditionalAgreements,
);
export const loadRegionsFx = additionalAgreementsDomain.createEffect(
    additionalAgreementsService.getRegions,
);
export const loadBranchesFx = additionalAgreementsDomain.createEffect(
    additionalAgreementsService.getBranches,
);
export const loadExcel = additionalAgreementsDomain.createEffect();

//events
export const changeRegion = additionalAgreementsDomain.createEvent<Region[]>();
export const changeBranch = additionalAgreementsDomain.createEvent<Branch[]>();
export const loadBranches = additionalAgreementsDomain.createEvent();
export const searchRegionByTerm = additionalAgreementsDomain.createEvent();
export const selectBranchSearch = additionalAgreementsDomain.createEvent();
export const setSelectBranchSearch = additionalAgreementsDomain.createEvent();
export const setSelectedRegions = additionalAgreementsDomain.createEvent();

export const toggleFilter = additionalAgreementsDomain.createEvent();
export const editColumns = additionalAgreementsDomain.createEvent();
export const toggleOpenDetails = additionalAgreementsDomain.createEvent<string>();
export const setSearchTerm = additionalAgreementsDomain.createEvent<string>();
export const searchBySearchTerm = additionalAgreementsDomain.createEvent<string>();

// filters
export const $regionOptions = additionalAgreementsDomain.createStore<Region[]>([]);
export const $selectedRegions = additionalAgreementsDomain.createStore<Region[]>([]);
export const $branchOptions = additionalAgreementsDomain.createStore<Branch[]>([]);
export const $selectedBranches = additionalAgreementsDomain.createStore<Branch[]>([]);
export const $selectRegionData = additionalAgreementsDomain.createStore<Region[]>([]);
export const $regionsSearchTerm = additionalAgreementsDomain.createStore('');
export const $selectBranchData = additionalAgreementsDomain.createStore([]);
export const $searchValue = additionalAgreementsDomain.createStore('');
export const $inputSearchValue = additionalAgreementsDomain.createStore('');
export const $statusId = additionalAgreementsDomain.createStore('');
export const $implementors = additionalAgreementsDomain.createStore<string[]>([]);
export const $purchasingEvents = additionalAgreementsDomain.createStore<string[]>([]);
export const $contracts = additionalAgreementsDomain.createStore<string[]>([]);
export const $approvingAt = additionalAgreementsDomain.createStore<string | null>(null);

// table
export const $projectId = additionalAgreementsDomain.createStore('');
export const $agreements = additionalAgreementsDomain.createStore<
    Array<IAdditionalAgreementListItem>
>([]);
export const $statusesMap = additionalAgreementsDomain.createStore<Record<string, string>>({});
export const $sortBy = additionalAgreementsDomain.createStore('');
export const $openedDetailedIds = additionalAgreementsDomain.createStore<string[]>([]);

//pagination
export const setPage = additionalAgreementsDomain.createEvent<number>();
export const setPageSize = additionalAgreementsDomain.createEvent<number>();

export const $page = additionalAgreementsDomain.createStore<number>(1);
export const $pageSize = additionalAgreementsDomain.createStore<number>(10);
export const $count = additionalAgreementsDomain.createStore<number>(0);

export const $additionalAgreementsFilters = combine({
    regionOptions: $regionOptions,
    selectedRegions: $selectedRegions,
    branchOptions: $branchOptions,
    selectedBranches: $selectedBranches,
    regionsSearchTerm: $regionsSearchTerm,
    selectRegionData: $selectRegionData,
    selectBranchData: $selectBranchData,
    loadingRegions: loadRegionsFx.pending,
    loadingBranches: loadBranchesFx.pending,
    searchTerm: $inputSearchValue,
});

export const $additionalAgreementsTable = combine({
    loading: loadAdditionalAgreementsFx.pending,
    agreements: $agreements,
    sortBy: $sortBy,
    openedIds: $openedDetailedIds,
});

export const $additionalAgreementsPagination = combine({
    page: $page,
    pageSize: $pageSize,
    total: $count,
});
