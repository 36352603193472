import {bindAllMethods} from 'shared/helpers/binder';
import {GetTpiSpecificationRequest, GetTpiSpecificationResponse} from '../types';
import {axiosInstance} from 'shared/api/api';

export const tcpService = bindAllMethods({
    async getTpiSpecifications(
        params: GetTpiSpecificationRequest,
    ): Promise<GetTpiSpecificationResponse> {
        return axiosInstance.get('/contracts/tpis/tpi_specifications/', {params});
    },
});
