import {forwardPayload} from 'shared/helpers/effector';
import {$activeTab, getTpiSpecificationsFx, SelectTpisGate, setActiveTab} from './index';
import {combine, sample} from 'effector';
import {$allTpis, $allTpisPaginationParams, tableDataFetched} from '../AllTpisTab';
import {$createAdditionalAgreementData} from 'processes/contructor';

$activeTab.on(setActiveTab, forwardPayload()).reset(SelectTpisGate.close);

sample({
    clock: tableDataFetched,
    source: combine({
        tab: $activeTab,
        params: $allTpisPaginationParams,
        ds: $createAdditionalAgreementData,
    }),
    fn: ({params: {page, pageSize, searchAllTerm}, ds}) => {
        return {
            page,
            page_size: pageSize,
            tpi_id: ds?.tpi_id || '',
            remote_territory_id: ds?.remote_territory_id,
            project_id: ds?.project_id || '',
            ...(searchAllTerm ? {search: searchAllTerm} : {}),
        };
    },
    target: getTpiSpecificationsFx,
});

sample({
    clock: SelectTpisGate.open,
    source: combine({
        tab: $activeTab,
        params: $allTpisPaginationParams,
        ds: $createAdditionalAgreementData,
        allTpis: $allTpis,
    }),
    filter: ({allTpis}) => !allTpis.length,
    fn: ({params: {page, pageSize, searchAllTerm}, ds}) => {
        return {
            page,
            page_size: pageSize,
            tpi_id: ds?.tpi_id || '',
            remote_territory_id: ds?.remote_territory_id,
            project_id: ds?.project_id || '',
            ...(searchAllTerm ? {search: searchAllTerm} : {}),
        };
    },
    target: getTpiSpecificationsFx,
});
