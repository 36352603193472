import {createDomain, forward} from 'effector';
import {getLongPullingValidateAccount} from 'shared/services/auth.service';
import {forwardPayload} from 'shared/helpers/effector';
import {createGate} from 'effector-react';
import {authFailed, authSuccessful} from 'shared/model/auth';

export const mobileIdEntranceDomain = createDomain();
export const mobileIdEntranceGate = createGate();

export const getTokens = mobileIdEntranceDomain.createEvent();
export const resetTokensHaveReceived = mobileIdEntranceDomain.createEvent();

export const getTokensFx = mobileIdEntranceDomain.createEffect(async () => {
    const result = await getLongPullingValidateAccount();
    if (result) {
        localStorage.setItem('accessToken', result.accessToken);
        localStorage.setItem('refreshToken', result.refreshToken);
        localStorage.removeItem('ticketId');
        authSuccessful();
        return true;
    }
    localStorage.removeItem('ticketId');
    authFailed();
    return false;
});

export const $tokensHaveReceived = mobileIdEntranceDomain
    .createStore<boolean | null>(null)
    .on(getTokensFx.doneData, forwardPayload())
    .reset(resetTokensHaveReceived);

forward({from: mobileIdEntranceGate.open, to: getTokensFx});
forward({from: mobileIdEntranceGate.close, to: resetTokensHaveReceived});
forward({from: getTokens, to: getTokensFx});
