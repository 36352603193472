import React from 'react';
import {TableHead, TableRow} from '@beeline/design-system-react';

import {StyledTableHeaderData} from 'shared/styles/commonStyle';
import {IColumn} from 'shared/types/tableTypes';
import cn from 'classnames';

interface TableHeaderRowProps<T> {
    columns: IColumn<T>[];
    inner?: boolean;
    dense?: boolean;
}

export const TableHeader = <T,>({columns, inner, dense}: TableHeaderRowProps<T>) => {
    const hasBorderBottom = cn({'border-bottom': inner});
    return (
        <TableHead>
            <TableRow className={hasBorderBottom} dense={dense}>
                {columns.map((column) => (
                    <StyledTableHeaderData
                        width={column.width}
                        key={`${inner ? 'inner' : 'outer'}_${column.name.toString()}`}
                        dense={dense}
                        $centerAlign={column.name === 'completion'}
                    >
                        {column.label}
                    </StyledTableHeaderData>
                ))}
            </TableRow>
        </TableHead>
    );
};
