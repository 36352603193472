import styled from 'styled-components/macro';
import {TableCell} from 'shared/ui/Table/Table';
import {formatCurrency} from 'shared/helpers/formatHelper';
import {Tooltip} from 'shared/ui/deprecated/Tooltip/Tooltip';

interface SumCellProps {
    sum: number;
}

export function SumCell({sum}: SumCellProps) {
    const sumString = formatCurrency(sum.toString() || '0');
    return (
        <TableCell width="132px">
            <>
                {sumString.length > 14 ? (
                    <Tooltip titleText={sumString} position="top-end" horyzontal={0} vertical={7}>
                        <StyledSum>{sumString}</StyledSum>
                    </Tooltip>
                ) : (
                    <StyledSum>{sumString}</StyledSum>
                )}
            </>
        </TableCell>
    );
}

export const StyledSum = styled.div`
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: right;
`;
