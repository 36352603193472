import React from 'react';

import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';

import * as S from './style';


export function DSPageInfoSkeleton() {
    return (
        <WrapperOffset offsetTop={36}>
            <S.GridSkeleton height={28} width={270} variant="line" />
            <S.GridWrapper>
                <S.GridSkeleton $gridArea="address" height={40} variant="line" />
                <S.GridSkeleton height={40} variant="line" />
                <S.GridSkeleton height={40} variant="line" />
                <S.GridSkeleton height={40} variant="line" />
                <S.GridSkeleton height={40} variant="line" />
                <S.GridSkeleton height={40} variant="line" />
                <S.GridSkeleton height={40} variant="line" />
            </S.GridWrapper>
        </WrapperOffset>
    );
}
