import React, { Component } from 'react'
import styled from 'styled-components'
import CloseIcon from 'icons/CloseIcon'
const Button = styled.button`
	display:var(--dclosebtn);
	position:var(--pclosebtn);
	right:var(--rclosebtn);
	top:var(--tclosebtn);
	background-color:transparent;
	border:none;
	width:24px;
	height:24px;
	padding:0;
	transition-duration:0.4s;
	.icon{
		width:100%;
		height:100%
	}
`
export default function CloseBtn(props) {
	return (
		<Button {...props}><CloseIcon /></Button>
	)
}

const Button2 = styled.button`
	display:block !important;
	outline:none;
	transition-duration:0.4s;
	border: none;
	pointer-events:var(--close-pe,none);
	width:24px;
	height:24px;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	background-color:transparent;
	.icon{
		width:100%;
		height:100%;
		opacity:var(--close-op,0);
		transition-duration:0.4s;
	}
`

export function CloseBtnCategory(props) {
	return (
		<Button2 {...props}><CloseIcon /></Button2>
	)
}