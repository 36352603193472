import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';
import {createDetachedSignature, createHash} from 'crypto-pro';

import {IDetailedTask, ITaskForSign} from 'shared/types/processesTypes';
import {EAttachmentType} from 'shared/types/FileTypes';
import {resetDomainStoresByEvents} from 'shared/helpers/effector';
import {getTasksList, putAcceptTask} from 'shared/services/tasks.service';
import {fetchSignerActualization} from 'shared/services/ds.service';
import {fetchFileWithValidation, postJobAttachment} from 'shared/services/files.service';

import {additionalAgreementService} from '../../DSPage/service';
import {UpdateTaskProps} from '../../DSPage/types';

const GroupSignPageDomain = createDomain();
export const GroupSignPageGate = createGate();

// effects

const getTasksFx = GroupSignPageDomain.createEffect(async (id: string) => {
    try {
        const result = await getTasksList({id});
        return result.data;
    } catch (e) {
        console.warn(e);
        return false;
    }
});
const acceptDocumentWorkingFx = GroupSignPageDomain.createEffect(async (id: string) => {
    try {
        const result = await putAcceptTask(id);
        return result.status === 200;
    } catch (e) {
        console.warn(e);
        return false;
    }
});
const getFileForSigningFx = GroupSignPageDomain.createEffect(
    async ({fileId, fileType}: {fileId: string; fileType: EAttachmentType}) => {
        try {
            const result = await fetchFileWithValidation(fileId, fileType);
            return result.data ?? false;
        } catch (e) {
            console.warn(e);
            return false;
        }
    },
);
const createHashFx = GroupSignPageDomain.createEffect(async (file: Blob) => {
    try {
        const arrBuffer = await file.arrayBuffer();
        return await createHash(arrBuffer);
    } catch (e) {
        console.warn(e);
    }
});
const createDetachedSignatureFx = GroupSignPageDomain.createEffect(
    async ({thumbprint, hash}: {thumbprint: string; hash: string}) => {
        try {
            return await createDetachedSignature(thumbprint, hash);
        } catch (e) {
            console.warn(e);
            return false;
        }
    },
);
const sendPreparedFileFx = GroupSignPageDomain.createEffect(
    async ({
        detachedSignature,
        specialFileId,
    }: {
        detachedSignature: string;
        specialFileId: string;
    }) => {
        try {
            const testBlobFile = new Blob([detachedSignature]) as File;
            const response = await postJobAttachment(testBlobFile, specialFileId, undefined, 'sig');
            return response.status === 201;
        } catch (e) {
            console.warn(e);
            return false;
        }
    },
);
const clickBackendButtonFx = GroupSignPageDomain.createEffect(async (props: UpdateTaskProps) => {
    const result = await additionalAgreementService.updateTask(props);
    return result.status === 200;
});

const signDocumentsFx = GroupSignPageDomain.createEffect(
    async ({task, thumbprint}: {task: ITaskForSign; thumbprint: string}) => {
        const badResult = {id: task.object.id, progress: 'failed'};
        const wrongUserResult = {id: task.object.id, progress: 'wrongUser'};
        const goodResult = {id: task.object.id, progress: 'done'};
        try {
            const {
                object: {id, type_object, attachment_id, file_id},
            } = task;
            const taskData = await getTasksFx(id);
            if (!taskData || (taskData[0]?.actions.length ?? 0) === 0) return badResult;

            // актуализация подписанта
            const ifActual = await fetchSignerActualization(id);
            if (ifActual.status !== 200) {
                return wrongUserResult;
            }
            // взятие в работу
            if (taskData && taskData[0]?.status === 1) {
                const acceptTask = await acceptDocumentWorkingFx(taskData[0].id);
                if (!acceptTask) return badResult;
            }

            // получение файла с бэка
            const backendBlob = await getFileForSigningFx({
                fileId: attachment_id,
                fileType: type_object,
            });
            if (!backendBlob) return badResult;

            // получение хэша файла
            const hash = await createHashFx(backendBlob);
            if (!hash) return badResult;

            // получение открепленной подписи
            const detachedSignature = await createDetachedSignatureFx({thumbprint, hash});
            if (!detachedSignature) return badResult;

            // отправка файла на бэк
            const fileSent = sendPreparedFileFx({detachedSignature, specialFileId: file_id});
            if (!fileSent) return badResult;

            // проверка, что есть нужная кнопка
            const {id: taskId, actions} = taskData[0];
            const signTask = actions.find((action) => action.is_positive);
            if (!signTask) return badResult;

            // нажатие на воркфлоу кнопку
            const result = await clickBackendButtonFx({id: taskId, actionId: signTask.id});
            if (!result) return badResult;

            return goodResult;
        } catch (e) {
            console.warn(e);
            return badResult;
        }
    },
);

// events
export const documentDataTransferred = GroupSignPageDomain.createEvent<IDetailedTask[]>();
export const signatureSelected = GroupSignPageDomain.createEvent<string>();
export const signingRepeated = GroupSignPageDomain.createEvent<string>();

// stores
const $documentList = GroupSignPageDomain.createStore<ITaskForSign[]>([]);
const $signatureSelectionShown = GroupSignPageDomain.createStore(true);
const $thumbprint = GroupSignPageDomain.createStore<string>('');

export const $groupSignPageStore = combine({
    documentList: $documentList,
    documentListCount: $documentList.map((store) => store.length),
    signedCount: $documentList.map(
        (store) => store.filter((store) => store.progress === 'done').length,
    ),
    isDone: $documentList.map((store) => store.every((item) => item.progress !== 'in progress')),
    hasFailed: $documentList.map((store) =>
        store.some((item) => item.progress === 'failed' || item.progress === 'wrongUser'),
    ),
    signatureSelectionShown: $signatureSelectionShown,
});

// получение исходных данных для страницы (удалят дубли объектов)
sample({
    clock: documentDataTransferred,
    fn: (data) => {
        const idSet = new Set();
        const result: ITaskForSign[] = data
            .filter((item) => {
                const {id} = item.object;
                if (idSet.has(id)) {
                    return false;
                } else {
                    idSet.add(id);
                    return true;
                }
            })
            .map((item) => ({...item, progress: 'in progress'}));
        return result;
    },
    target: $documentList,
});

// переключение интерфейса после выбора сертификата
sample({
    clock: signatureSelected,
    fn: () => false,
    target: $signatureSelectionShown,
});

// передача отпечатка сертификата
sample({
    clock: signatureSelected,
    target: $thumbprint,
});

// отправка документов на подпись, пока есть документы с progress = 'in progress'
sample({
    clock: combine({
        thumbprint: $thumbprint,
        taskList: $documentList,
    }),
    filter: ({taskList, thumbprint}) =>
        !!thumbprint &&
        !taskList.some((task) => task.progress === 'wrongUser') &&
        !!taskList.find((task) => task.progress === 'in progress'),
    fn: ({taskList, thumbprint}) => {
        const task = taskList.find((task) => task.progress === 'in progress') ?? taskList[0];
        return {task, thumbprint};
    },
    target: signDocumentsFx,
});

sample({
    clock: signDocumentsFx.doneData,
    source: $documentList,
    fn: (source, result) => {
        const {id, progress} = result;

        return source.map(
            (item) =>
                ({
                    ...item,
                    progress: item.object.id === id ? progress : item.progress,
                } as ITaskForSign),
        );
    },
    target: $documentList,
});

// нажатие на кнопку "повторить", если подписание не произошло
sample({
    clock: signingRepeated,
    source: $documentList,
    filter: (list, id) => !!list.find((item) => item.object.id === id),
    fn: (list, id) =>
        list.map((item) =>
            item.object.id === id ? ({...item, progress: 'in progress'} as ITaskForSign) : item,
        ),
    target: $documentList,
});

resetDomainStoresByEvents(GroupSignPageDomain, GroupSignPageGate.close);
