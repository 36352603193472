export interface CoefficientsRequest {
    tpis_id: string;
    project_id: string;
    Tpi_specification_id?: string;
    search?: string;
}

export interface CoefficientsResponse {
    data: CoefficientResponseItem[];
}

export enum COEFFICIENT_TYPE_ENUM {
    Division = 1,
    Work = 2,
    Increasing = 3,
    IncreasingBySum = 4,
    Dismantling  = 5,
}

export enum COEFFICIENTS_ACTIVE_TAB {
    INCREASING = 1,
    DECREASING = 2,
    CUSTOM = 3,
}

export interface CoefficientResponseItem {
    coefficient_remote_territory: string;
    coefficient_ams: string;
    coefficient_foundation: string;
    branch_name: string;
    increasing_coefficients?: ICoefficient[];
    decreasing_coefficients?: ICoefficient[];
}

export interface ICoefficient {
    id: string;
    number: string;
    name: string;
    coefficient: number;
    note?: string;
    type: COEFFICIENT_TYPE_ENUM;
}
