import {axiosInstance} from '../api/api';
import {TLifeCycleListItem} from '../types/lifeCycleTypes';

export const fetchLifeCycleStatusList = (selectedSubtypes: string) => {
    return axiosInstance.get<TLifeCycleListItem[]>(`/life_cycles/statuses/`, {
        params: {
            subtypes_document: selectedSubtypes,
        },
    });
};

