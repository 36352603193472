import styled, {css} from 'styled-components/macro';

const VerticalTabBtnMixin = (props) => css`
    padding: 12px;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 5px;
    width: 100%;
    transition-duration: 0.4s;

    &:hover {
        background-color: rgba(25, 28, 52, 0.08);
    }

    ${props.active &&
    css`
        background-color: rgba(25, 28, 52, 0.05);
    `}
    ${props.disabled &&
    css`
        opacity: 0.5;
        cursor: default;

        &:hover {
            background-color: transparent;
        }
    `}
  ${props.hide &&
    css`
        display: none;
    `}
`;

export const VerticalTabBtnStyled = styled.div`
    ${(props) => VerticalTabBtnMixin(props)}
`;

const VerticalTabBlockMixin = (props) => css`
    display: none;
    ${props.active &&
    css`
        display: block;
    `}
`;

export const VerticalTabBlockStyled = styled.div`
    ${(props) => VerticalTabBlockMixin(props)}
`;

export const BtnTitle = styled.div`
    font-size: 17px;
    line-height: calc(22 / 17);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const BtnSubtitle = styled.div`
    color: var(--secondary);
    line-height: calc(18 / 15);
`;
