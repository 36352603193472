import React from 'react';
import PortalWrapper from 'shared/ui/deprecated/Portal/Portal';
import {TCPWrap, TooltipElement, TooltipText, TooltipTCPText} from 'shared/ui/deprecated/Tooltip/style';

function Tooltip(props) {
    const {
        titleText,
        children,
        position = 'top',
        vertical = 20,
        horyzontal = 20,
        maxWidth = 'none',
        isHide = false,
        ...rest
    } = props;
    if (isHide) return children;

    return (
        <PortalWrapper
            flex
            portal={<TooltipText maxWidth={maxWidth}>{titleText}</TooltipText>}
            type="hover"
            vertical={vertical}
            horyzontal={horyzontal}
            position={position}
            {...props}
        >
            <TooltipElement>{children}</TooltipElement>
        </PortalWrapper>
    );
}

function TooltipTCP(props) {
    const {
        titleText,
        children,
        position = 'top',
        vertical = 20,
        horyzontal = 20,
        maxWidth = 'none',
        ...rest
    } = props;

    return (
        <PortalWrapper
            flex
            portal={<TooltipTCPText maxWidth={maxWidth}>{titleText}</TooltipTCPText>}
            type="hover"
            vertical={vertical}
            horyzontal={horyzontal}
            position={position}
            {...props}
        >
            <TooltipElement>
                <TCPWrap>{children}</TCPWrap>
            </TooltipElement>
        </PortalWrapper>
    );
}

export {Tooltip, TooltipTCP};
