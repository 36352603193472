export enum EAccessActions {
    ProjectCard = 'projects_card',
    DocumentsCard = 'documents_card',
    DocumentsCreation = 'documents_create',
    FinancialDocumentsCreation = 'documents_create_financial',
    DocumentsList = 'documents_list',
    DocumentsEdit = 'documents_edit',
    JobTaskCreation = 'job_tasks_create',
    JobTaskCard = 'job_tasks_card',
    AgreementCard = 'agreement_card',
    AgreementList = 'agreement_list',
    ProcessesList = 'process_list',
    AgreementCreate = 'agreement_create',
    CompletedWorksList = 'completed_works_list',
    CompletedWorksCreation = 'completed_works_create',
    CompletedWorksEditing = 'completed_works_edit',
    CompletedWorksCard = 'completed_works_card',
    DocumentSigning = 'process_signature',
    ContractList = 'contracts_list',
    ContractCard = 'contracts_card',
    ContractListLinkShowing = 'contracts_show_hyperlink',
    ContractListTpiCard = 'contracts_tpi_card',
    Development = 'development',
    WhiteList = 'constants_whitelist_imProjectGroup',
}
