import {combine, createDomain, sample} from 'effector';

import {forwardPayload} from 'shared/helpers/effector';

export const authDomain = createDomain();

export const authSuccessful = authDomain.createEvent();
export const authFailed = authDomain.createEvent();
export const toggleLoading = authDomain.createEvent<boolean>();
export const setAuthFinished = authDomain.createEvent();

export const $isAuth = authDomain
    .createStore<boolean>(false)
    .on(authSuccessful, () => true)
    .on(authFailed, () => false);

export const $isAuthFinished = authDomain
    .createStore<boolean>(false)
    .on(setAuthFinished, () => true);

export const $isLoading = authDomain.createStore(true).on(toggleLoading, forwardPayload());

sample({
    clock: [authSuccessful, authFailed],
    target: setAuthFinished,
});

export const $authStore = combine({
    isAuth: $isAuth,
    isLoading: $isLoading,
    isAuthFinished: $isAuthFinished,
});
