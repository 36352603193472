import React, {useState} from 'react';
import {Icon, Progress, Tooltip, Typography} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {EAttachmentType, TAttachmentFile} from 'shared/types/FileTypes';
import {formatDate} from 'shared/helpers/formatHelper';

import * as S from './style';

interface DownloadableAttachmentProps {
    id: string;
    item?: TAttachmentFile;
    title: string;
    type: EAttachmentType;
    subtitle?: string;
    handleDownloadAttachment?: (fileId: string, fileName: string, type: EAttachmentType) => void;
    disabled?: boolean;
    isLoading?: boolean;
}

export const DownloadableAttachment = ({
    id,
    item,
    title,
    type,
    subtitle,
    handleDownloadAttachment,
    disabled,
    isLoading,
}: DownloadableAttachmentProps) => {
    const [isClicked, setIsClicked] = useState<boolean>(false);

    const handleOnClick = () => {
        if (handleDownloadAttachment && disabled === false) {
            handleDownloadAttachment(id, title, type);
            setIsClicked(true);
        }
    };

    if ((!isLoading || !disabled) && isClicked) {
        setIsClicked(false);
    }

    return (
        <S.Wrapper>
            <S.CustomBtn onClick={handleOnClick} disabled={disabled}>
                <Icon iconName={Icons.Page} color="grey" size="large" />
                <S.DataWrapper>
                    <S.TitleWrapper>
                        <Tooltip title={title}>
                            <S.StyledTitle
                                variant="body2"
                                className={disabled ? 'inactiveText' : ''}
                            >
                                {title}
                            </S.StyledTitle>
                        </Tooltip>
                    </S.TitleWrapper>

                    {subtitle && <Typography variant="body3">{subtitle}</Typography>}
                    {item && item.registration_at && (
                        <Typography variant="body3" className="inactiveText">
                            {`Дата оформления: ${formatDate(item.registration_at)}`}
                        </Typography>
                    )}
                </S.DataWrapper>
            </S.CustomBtn>
            {isClicked && <Progress value={33} size={36} shape="circle" cycled />}
        </S.Wrapper>
    );
};
