import {axiosInstance, axiosInstanceWithoutInterceptors} from 'shared/api/api';
import {
    AuthorizationResponse,
    EmailAuthorizationResponse,
    ValidationResponse,
} from 'shared/services/types/authTypes';
import {authFailed} from 'shared/model/auth';

export const getAuthorization = (url: string) => {
    return axiosInstance.get<AuthorizationResponse>('accounts/authorization', {
        params: {
            url,
        },
    });
};

export const postMobileIdAuthorization = (email: string) => {
    return axiosInstance.post<EmailAuthorizationResponse>('accounts/authorization', {
        email,
    });
};

export const getValidateAccount = (ticketId: string) => {
    return axiosInstance.get<ValidationResponse>('accounts/validate', {
        params: {
            ticket_id: ticketId,
        },
    });
};

export const getLongPullingValidateAccount = async () => {
    try {
        for (let i = 0; i < 24; i++) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            const ticketId = localStorage.getItem('ticketId') ?? '';
            if (!ticketId) {
                continue;
            }
            const result = await getValidateAccount(ticketId);

            // @ts-ignore
            const status = result.status ?? result.response.status;
            switch (status) {
                case 202: {
                    await new Promise((resolve) => setTimeout(resolve, 3000));
                    break;
                }
                case 200: {
                    return result.data;
                }
                case 403: {
                    return null;
                }
                default:
                    break;
            }
        }
        await new Promise((resolve) => setTimeout(resolve, 72000));
        return null;
    } catch (e) {
        console.error(e);
    }
};

export const postRefreshToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
            const result = await axiosInstanceWithoutInterceptors.post<ValidationResponse>(
                'accounts/refresh_token',
                {
                    refreshToken,
                },
            );
            if (result.status === 200) {
                localStorage.setItem('accessToken', result.data.accessToken);
                localStorage.setItem('refreshToken', result.data.refreshToken);
                return result.status;
            }
        }
        throw Error();
    } catch (e) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        authFailed();
    }
};
