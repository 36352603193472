import styled, {css} from 'styled-components/macro';

export const TooltipElementMixin = () => css`
    position: relative;
    display: inline-block;
    width: 100%;
`;

export const TooltipElement = styled.div`
    ${TooltipElementMixin()}
`;

export const TooltipTextMixin = (props) => css`
     {
        font-size: 13px;
        max-width: 348px;
        width: max-content;
        background-color: #141414;
        color: #fff;
        border-radius: 8px;
        padding: 4px 8px;
        ${props.maxWidth != null &&
        css`
            max-width: ${props.maxWidth};
        `}
    }
`;

export const TooltipText = styled.div`
    ${(props) => TooltipTextMixin(props)}
`;

export const TooltipTCPText = styled(TooltipText)`
    padding: 16px;
    border-radius: 16px;
`;

export const TCPWrap = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    -ms-box-orient: vertical;
    -moz-line-clamp: 3;
    -ms-line-clamp: 3;
    line-clamp: 3;
    line-height: 1.2;
    overflow: hidden;
`;
