import React from 'react'
import { H1Styled, H2Styled, H3Styled, H4Styled, H5Styled, H6Styled } from 'shared/ui/deprecated/HeaderText/style';

export default function HeaderText(props) {
    const {
        component = 'h6',
        children,
        ...rest
    } = props;

    return (
        <>
            {component === 'h6' && <H6Styled {...props}>{children}</H6Styled>}
            {component === 'h5' && <H5Styled {...props}>{children}</H5Styled>}
            {component === 'h4' && <H4Styled {...props}>{children}</H4Styled>}
            {component === 'h3' && <H3Styled {...props}>{children}</H3Styled>}
            {component === 'h2' && <H2Styled {...props}>{children}</H2Styled>}
            {component === 'h1' && <H1Styled {...props}>{children}</H1Styled>}
        </>
    );
}

export const H1 = (props) => <HeaderText component="h1" {...props} />
export const H2 = (props) => <HeaderText component="h2" {...props} />
export const H3 = (props) => <HeaderText component="h3" {...props} />
export const H4 = (props) => <HeaderText component="h4" {...props} />
export const H5 = (props) => <HeaderText component="h5" {...props} />
export const H6 = (props) => <HeaderText component="h6" {...props} />
