import {EAdditionalAgreementType, EWorkObjectTypes} from '../types/additionalAgreementsTypes';
import {IStatus} from '../types/statusTypes';
import {IAgreementWorkObject} from '../../pages/DSPage/types';
import {ICompletedWorkObject, TAdaptedCompletedWorksObject} from '../types/completedWorksTypes';

export const getAgreementType = (
    id?: string,
    isTermination?: boolean,
): EAdditionalAgreementType => {
    if (!id) {
        return EAdditionalAgreementType.Agreement;
    }
    return isTermination ? EAdditionalAgreementType.Termination : EAdditionalAgreementType.Addition;
};

export const isCreationConstructorMode = (status?: IStatus) => {
    return !status;
};

export const createAgreementWorkObjectName = (
    agreementObject: IAgreementWorkObject | ICompletedWorkObject | TAdaptedCompletedWorksObject,
): string => {
    return `${agreementObject.object_type === EWorkObjectTypes.BS ? 'БС ' : ''}${
        agreementObject.gfk
    } ${agreementObject.name}`;
};
