import React, { Component } from 'react'

export default class FilterIcon extends Component {
	render() {
		return (
			<svg className="icon">
				<use xlinkHref="/img/sprite.svg#filter" />
			</svg>
		)
	}
}