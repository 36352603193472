import {IUpdatedAgreement} from 'shared/types/additionalAgreementsTypes';
import {getAllDatesFromSpecs} from '../../helpers';
import {max, min} from 'date-fns';
import {formatDate, formatNumber} from 'shared/helpers/formatHelper';
import {deleteFieldsFromObject} from 'shared/helpers/transformObject';
import {isEqual} from 'lodash';
import {fieldsToDeleteFromAgreement} from './fieldsToDelete';

export function detectAgreementChanges({
    newDs,
    oldDs,
}: {
    newDs: IUpdatedAgreement;
    oldDs: IUpdatedAgreement;
}): IUpdatedAgreement {
    const filteredOldWorks = oldDs.works;
    const filteredNewWorks = newDs.works;
    const oldDates = getAllDatesFromSpecs(filteredOldWorks);
    const oldBeginDate = oldDates.length ? formatDate(min(oldDates), 'yyyy-MM-dd') : '';
    const oldEndDate = oldDates.length ? formatDate(max(oldDates), 'yyyy-MM-dd') : '';
    const newDates = getAllDatesFromSpecs(filteredNewWorks);
    const newBeginDate = newDates.length
        ? formatDate(min(newDates), 'yyyy-MM-dd') !== '1970-01-01'
            ? formatDate(min(newDates), 'yyyy-MM-dd')
            : null
        : null;
    const newEndDate = newDates.length ? formatDate(max(newDates), 'yyyy-MM-dd') : '';
    const dsSum = Object.values(filteredNewWorks).reduce((acc, curr) => acc + curr.sum, 0);
    const dsSumTax = Object.values(filteredNewWorks).reduce((acc, curr) => acc + curr.sum_tax, 0);
    const dsTotalSum = Object.values(filteredNewWorks).reduce(
        (acc, curr) => acc + curr.total_sum,
        0,
    );
    const sum = formatNumber(dsSum);
    const sum_tax = formatNumber(dsSumTax);
    const total_sum = formatNumber(dsTotalSum);

    const newAgreement = deleteFieldsFromObject(
        {
            ...newDs,
            signing_date: newDs.signing_date ?? undefined,
            parent_signing_date: newDs.parent_signing_date || undefined,
            sum,
            sum_tax,
            total_sum,
            begin_date: newBeginDate,
            end_date: newEndDate,
        },
        fieldsToDeleteFromAgreement,
    );

    const oldAgreement = deleteFieldsFromObject(
        {
            ...oldDs,
            begin_date: oldBeginDate || null,
            end_date: oldEndDate || null,
        },
        fieldsToDeleteFromAgreement,
    );
    if (!isEqual(newAgreement, oldAgreement)) {
        newAgreement.update_action = 'update';
        return {...newDs, ...newAgreement};
    }
    newAgreement.update_action = 'nothing';
    return newAgreement;
}
