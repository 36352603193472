import {
    ConstructorVirType,
    ConstructorVirWorkObject,
    IUpdatedAgreement,
} from 'shared/types/additionalAgreementsTypes';
import {TpiSpecification} from 'pages/VirSelect/types';

// св-ва, которые не нужны бэку при обновлении
export const fieldsToDeleteFromAgreement: Array<keyof IUpdatedAgreement> = [
    'contract_name',
    'id',
    'project_number',
    'default_begin_date',
    'default_end_date',
    'update_action',
    'status',
    'agreement_number',
];
export const fieldsToDeleteFromWork: Array<keyof ConstructorVirType> = [
    'work_objects',
    'update_action',
];
export const fieldsToDeleteFromWorkObject: Array<keyof ConstructorVirWorkObject> = [
    'work_specifications',
    'virId',
    'update_action',
];
export const fieldsToDeleteFromTpi: Array<keyof TpiSpecification> = [
    'id',
    'update_action',
    'work_coefficients',
    'work_detail',
    'is_tax',
    'sort_order',
    'workObjectId',
    'default_end_date',
    'default_begin_date',
    'work_specification_approval_total',
    'work_specification_approval',
    'selected_id',
    'virId',
    'tpi_specification',
    'work_object',
    'update_action',
    'accuracy',
    'type_position',
    'is_ws_deletable',
    'is_quantity_editable',
    'is_price_editable',
];
