import React from 'react';
import {Chip} from '@beeline/design-system-react';

import {JobTaskTypesType} from 'shared/services/types/configuratorTypes';
import {LabelRadioSkeleton} from 'Features/LabelRadio/ui/LabelRadioSkeleton';

import * as S from './style';

interface LabelRadioProps {
    data: JobTaskTypesType[];
    activeLabelCode: string;
    onSelectActiveLabel: (code: string) => void;
}

export const LabelRadio = ({data, activeLabelCode, onSelectActiveLabel}: LabelRadioProps) => {
    if (data.length === 0) return <LabelRadioSkeleton />;

    const isActive = (code: string) => activeLabelCode === code;
    const handleOnClick = (code: string) => {
        onSelectActiveLabel(code);
    };

    return (
        <S.ChipContainer>
            {data.map((item) => (
                <Chip
                    key={item.name}
                    label={item.name}
                    active={isActive(item.code)}
                    onClick={() => handleOnClick(item.code)}
                />
            ))}
        </S.ChipContainer>
    );
};
