import {Progress} from '@beeline/design-system-react';
import type {IFilePreviewProps} from '../../../useFileUploader.types';

const Loading = ({className}: IFilePreviewProps): JSX.Element => (
    <div className={className}>
        <Progress shape="circle" value={37} cycled />
    </div>
);

export default Loading;
