import Pagination from 'shared/ui/Pagination/Pagination';
import React, {FC} from 'react';

type ProjectPagePaginationProps = {
    total: number;
    page: number;
    setPage: (number) => void;
    pageSize: number;
    setPageSize: (number) => void;
};
export const ProjectPagePagination: FC<ProjectPagePaginationProps> = ({
    total,
    pageSize,
    page,
    setPage,
    setPageSize,
}) => {
    if (total === 0) return null;
    return (
        <Pagination
            total={total}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            className="py-3"
        />
    );
};
