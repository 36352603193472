import React from 'react';
import {useStore} from 'effector-react';

import {$projectListColumnFilter, updateColumnItem} from 'pages/ProjectPage/model';
import {ProjectSettings} from './style';
import {SettingItem} from './ProjectFilterSettingsItem/ProjectFilterSettingsItem';

export function ProjectFilterSettings() {
    const {editColumns} = useStore($projectListColumnFilter);
    return (
        <ProjectSettings>
            {editColumns.map((item, index) => (
                <SettingItem
                    key={item.name}
                    index={index}
                    value={item.name}
                    checked={item.visible}
                    handleEdit={(e) => {
                        updateColumnItem({index, item: {...item, visible: e.target.checked}});
                    }}
                >
                    {item.label}
                </SettingItem>
            ))}
        </ProjectSettings>
    );
}
