import {axiosInstance} from 'shared/api/api';
import {TaskListResponseType, IDetailedTask, TListedProcess} from 'shared/types/processesTypes';
import {IPaginationResponse} from '../types/apiTypes';
import {TTaskParams} from './types/taskParamsTypes';
import {IResponseWithPagination} from '../types/apiParamsTypes';
import {TDocumentTypeListItem} from '../types/commonTypes';
import {TCreateProcessParams} from './types/processesParamsTypes';

export function getNotifications(pageNumber: number, statuses: number[]) {
    const page = pageNumber === 0 ? 1 : pageNumber;
    const status = statuses.join(',');

    return axiosInstance.get<TaskListResponseType>(`/processes/tasks/notifications/`, {
        params: {
            page,
            status,
        },
    });
}

export const fetchTasks = (params: TTaskParams) => {
    return axiosInstance.get<IPaginationResponse<IDetailedTask>>(`/processes/tasks/`, {
        params: params,
    });
};

export const fetchProcesses = (params: TTaskParams) => {
    return axiosInstance.get<IResponseWithPagination<TListedProcess>>(`/processes/processes/`, {
        params: params,
    });
};

export const fetchDocumentTypes = () => {
    return axiosInstance.get<TDocumentTypeListItem[]>('/processes/document_types/');
};

export const postCreateProcess = (params: TCreateProcessParams) => {
    return axiosInstance.post<unknown>('/processes/create_process/', params);
};