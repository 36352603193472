import {ETaskType} from '../types/taskTypes';
import {EAccessActions} from '../const/actions';

export const hasLinkPermission = (mode: ETaskType, userActions: string[]): boolean => {
    switch (mode) {
        case ETaskType.JOB: {
            return userActions.includes(EAccessActions.JobTaskCard);
        }
        case ETaskType.DOCUMENT: {
            return userActions.includes(EAccessActions.DocumentsCard);
        }
        case ETaskType.ADDITIONAL_AGREEMENT: {
            return userActions.includes(EAccessActions.AgreementCard);
        }
        case ETaskType.COMPLETED_WORKS_SHEET: {
            return userActions.includes(EAccessActions.CompletedWorksCard);
        }
    }
    return false;
};

export const userHasPermission = (
    permission: EAccessActions | undefined,
    userActions: string[],
): boolean => {
    if (permission) {
        return userActions.includes(permission);
    }
    return false;
};
