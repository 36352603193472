import styled from 'styled-components/macro';
import {TableData} from '@beeline/design-system-react';

export const EditableCell = styled(TableData)<{error?: boolean; $isEditable?: boolean}>`
    position: relative;
    text-align: center;

    &:hover,
    &:focus-within {
        ${(p) =>
            p.$isEditable &&
            !p.error &&
            `background: rgba(25, 28, 52, 0.08); box-shadow:inset 0 0 0 1px black;`};
    }

    ${(p) => p.error && `background:var(--red-avatar); box-shadow:inset 0 0 0 1px  var(--red);`}
    .dsb_table-cell_flex {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .dsb_table-cell__content {
            width: 100%;
            height: 100%;
            padding: 0 8px;
            display: flex;
            justify-content: center;

            .wrapper {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
`;


export const StyledInput = styled.input`
    width: 100%;
    text-align: center;
    border: none;
    background-color: transparent;
    outline: none;
`;
