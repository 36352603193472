import {ReactNode} from 'react';
import {ModalText, ModalTitle} from 'components/HeaderVir/style';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import ModalWindow from 'shared/ui/deprecated/ModalWindow/ModalWindow';

interface ModalFactoryProps {
    open: boolean;
    title: string;
    description: string;
    children: ReactNode;
}

export function ModalFactory({open, title, description, children}: ModalFactoryProps) {
    return (
        <ModalWindow open={open}>
            <ModalTitle>{title}</ModalTitle>
            <ModalText>{description}</ModalText>
            <WrapperOffset offsetTop={16}>
                <WrapperFlex justifyContent="flex-end">{children}</WrapperFlex>
            </WrapperOffset>
        </ModalWindow>
    );
}
