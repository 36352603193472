import React from 'react';

import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {H5} from 'shared/ui/deprecated/HeaderText/HeaderText';
import {Skeleton} from 'shared/ui/deprecated/Skeleton/Skeleton';
import Row from 'shared/ui/deprecated/Row/Row';

export const ViewProjectMainInfoSkeleton = () => {
    return (
        <WrapperOffset offsetTop="36">
            <H5 className="mb-3">
                <Skeleton width={200} height={28} />
            </H5>
            <Row gutterY="24" gutterX="16">
                <div className="col-4">
                    <Row gutterX="16">
                        <div className="col-6">
                            <Skeleton height={40} />
                        </div>
                        <div className="col-6">
                            <Skeleton height={40} />
                        </div>
                    </Row>
                </div>
                <div className="col-3">
                    <Row gutterX="16">
                        <div className="col-8">
                            <Skeleton height={40} />
                        </div>
                        <div className="col-4">
                            <Skeleton height={40} />
                        </div>
                    </Row>
                </div>
                <div className="col-5">
                    <Row gutterX="16">
                        <div className="col-12">
                            <Skeleton height={40} />
                        </div>
                    </Row>
                </div>
                <div className="col-4">
                    <Row gutterX="16">
                        <div className="col-12">
                            <Skeleton height={40} />
                        </div>
                    </Row>
                </div>
                <div className="col-3">
                    <Row gutterX="16">
                        <div className="col-12">
                            <Skeleton height={40} />
                        </div>
                    </Row>
                </div>
                <div className="col-5">
                    <Row gutterX="16">
                        <div className="col-12">
                            <Skeleton height={40} />
                        </div>
                    </Row>
                </div>
            </Row>
        </WrapperOffset>
    );
};
