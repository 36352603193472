import styled from 'styled-components/macro';
import {TableString} from 'shared/ui/Table/Table';

export const AgreementString = styled(TableString)`
    &:hover {
        background-color: #ededef;
    }

    cursor: pointer;
`;
