import styled, { css } from "styled-components";

export const H1Styled = styled.h1`
font-size: 52px;
line-height: calc(70/52);
font-weight: 500;
margin-bottom:0;
`;
export const H2Styled = styled.h2`
font-size: 40px;
line-height: calc(50/40);
font-weight: 500;
margin-bottom:0;
`;
export const H3Styled = styled.h3`
font-size: 34px;
line-height: calc(44/34);
font-weight: 500;
margin-bottom:0;
`;
export const H4Styled = styled.h4`
font-size: 26px;
line-height: calc(32 / 26);
font-weight: 500;
margin-bottom:0;
`;
export const H5Styled = styled.h5`
font-size:20px;
line-height:calc(28/20);
font-weight:700;
margin-bottom:0;
`;
export const H6Styled = styled.h6`
font-size: 17px;
line-height: 22px;
font-weight: 700;
margin-bottom:0;
`;