import React from 'react';
import {TableHead} from '@beeline/design-system-react';
import {EmptyObjectRow} from 'Entities/EmptyObjectRow';
import {TAdaptedCompletedWorksSheet} from 'shared/types/completedWorksTypes';
import * as S from 'pages/VirConstructor/VirAccordion/style';
import {Accordion} from 'shared/ui/deprecated/Accordion/Accordion';
import {Table} from 'shared/ui/Table/Table';

import {WorkObject} from './WorkObject';
import {TableHeader} from './TableHead/TableHeader';

interface AgreementAccordionProps {
    works: TAdaptedCompletedWorksSheet[];
}

export const AgreementAccordion = ({works}: AgreementAccordionProps) => {
    return (
        <Accordion>
            {works.map((worksheet, index) => (
                <S.StyledAccordionItem
                    key={worksheet.id}
                    id={worksheet.id}
                    title={`Ведомость ${index + 1}`}
                    active={true}
                >
                    <Table minWidth="1100px">
                        <TableHead>
                            {Object.keys(worksheet?.work_objects || [])?.length > 0 && (
                                <TableHeader
                                    worksheetId={worksheet.id}
                                    checked={worksheet.checked}
                                    checkboxType={worksheet.checkboxType}
                                />
                            )}
                        </TableHead>
                        <tbody>
                            {Object.keys(worksheet.work_objects || []).length > 0 ? (
                                Object.values(worksheet.work_objects || []).map((workObject) => (
                                    <WorkObject
                                        key={workObject.id}
                                        touchedConstructor={false}
                                        workId={worksheet?.id ?? ''}
                                        workObject={workObject}
                                    />
                                ))
                            ) : (
                                <EmptyObjectRow />
                            )}
                        </tbody>
                    </Table>
                </S.StyledAccordionItem>
            ))}
        </Accordion>
    );
};
