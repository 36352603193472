import React from 'react';
import {Icons} from '@beeline/design-tokens/js/iconfont';
import {Banner} from '@beeline/design-system-react';

interface NotOpenedSheetsBannerProps {
    openedSheetList: {id: string; index: number; hasOpened: boolean}[];
    showBanner: boolean;
}

export const NotOpenedSheetsBanner = ({
    openedSheetList,
    showBanner,
}: NotOpenedSheetsBannerProps) => {
    const notOpenedSheetArray = openedSheetList
        .filter((item) => !item.hasOpened)
        .map((item) => item.index);
    if (!showBanner || notOpenedSheetArray.length === 0) return null;

    const bannerTitle = `Посмотрите работы в ведомост${
        notOpenedSheetArray.length > 1 ? 'ях' : 'и'
    } ${notOpenedSheetArray.join(', ')}`;

    return <Banner title={bannerTitle} color="error" iconName={Icons.InfoCircled} />;
};
