import {useGate, useStore} from 'effector-react';
import {ApplyDecreasingCoefficientsGate} from 'pages/ApplyCoefficientsPage/model/decreasingCoefficientsTab';
import {$activeTab} from 'pages/ApplyCoefficientsPage/model';
import {TabsBlock} from 'shared/ui/deprecated/Tabs/Tabs';
import {COEFFICIENTS_ACTIVE_TAB} from 'pages/ApplyCoefficientsPage/types';
import {$customCoefficientTab} from '../../model/otherCoeficientsTab';
import {AddedCoefficientItem} from './AddedCoefficientItem';
import * as S from './style';

export function OtherCoefficientsTab() {
    useGate(ApplyDecreasingCoefficientsGate);
    const activeTab = useStore($activeTab);
    const {customCoefficients} = useStore($customCoefficientTab);
    return (
        <TabsBlock value={COEFFICIENTS_ACTIVE_TAB.CUSTOM} activeTab={activeTab}>
            <S.Wrapper>
                {customCoefficients.map((el, index) => (
                    <AddedCoefficientItem key={el.id} index={index} coefficient={el} />
                ))}
            </S.Wrapper>
        </TabsBlock>
    );
}
