import {combine, createDomain} from 'effector';
import {createGate} from 'effector-react';
import {
    IContractCatalog,
    IProjectCatalog,
    ITpiCatalog,
    CatalogWorkObjectOption,
    CatalogWorkType,
    WorkType,
} from '../types';
import {catalogService} from 'shared/services/catalogs.service';

export const virCreatePageDomain = createDomain('virCreatePageDomain');
export const userInfoDomain = createDomain();
export const VirCreatePageGate = createGate<{projectId?: string}>();
// отдельный гейт для кейса, когда ДС создается со страницы списка ДС
export const ProjectSelectGate = createGate();

export const selectWorkObject = virCreatePageDomain.createEvent<CatalogWorkObjectOption>();
export const selectProject = virCreatePageDomain.createEvent<IProjectCatalog>();
export const selectWorkType = virCreatePageDomain.createEvent<WorkType>();
export const selectContract = virCreatePageDomain.createEvent<IContractCatalog>();
export const selectTpi = virCreatePageDomain.createEvent<ITpiCatalog>();

export const loadCatalogProjectsFx = virCreatePageDomain.createEffect(
    catalogService.getCatalogProjects,
);
export const loadCatalogWorkObjectsFx = virCreatePageDomain.createEffect(
    catalogService.getCatalogWorkObjects,
);
export const loadWorkTypesFx = virCreatePageDomain.createEffect(catalogService.getCatalogWorkTypes);
export const loadContractsFx = virCreatePageDomain.createEffect(catalogService.getCatalogContracts);
export const loadTpisFx = virCreatePageDomain.createEffect(catalogService.getCatalogTpis);

export const validateForm = virCreatePageDomain.createEvent();
export const saveOwnerId = virCreatePageDomain.createEvent<string | null>();

export const $workObjectsOptions = virCreatePageDomain.createStore<Array<CatalogWorkObjectOption>>(
    [],
);
export const $projectId = virCreatePageDomain.createStore<string>('');
export const $ownerId = userInfoDomain.createStore<string | null>(null);
export const $tpiId = virCreatePageDomain.createStore<string>('');
export const $workTypeId = virCreatePageDomain.createStore<string>('');
export const $contractId = virCreatePageDomain.createStore<string>('');
export const $workProjectOptions = virCreatePageDomain.createStore<IProjectCatalog[]>([]);
export const $workTypOptions = virCreatePageDomain.createStore<WorkType[]>([]);
export const $contractOptions = virCreatePageDomain.createStore<IContractCatalog[]>([]);
export const $tpiOptions = virCreatePageDomain.createStore<ITpiCatalog[]>([]);
export const $objectsLoaded = virCreatePageDomain.createStore(false);

export const $selectedWorkObject = virCreatePageDomain.createStore<CatalogWorkObjectOption | null>(
    null,
);
export const $selectedProject = virCreatePageDomain.createStore<IProjectCatalog | null>(null);
export const $selectedWorkType = virCreatePageDomain.createStore<CatalogWorkType | null>(null);
export const $selectedContract = virCreatePageDomain.createStore<IContractCatalog | null>(null);
export const $selectedTpi = virCreatePageDomain.createStore<ITpiCatalog | null>(null);
export const $fromProject = virCreatePageDomain.createStore(true);
export const $touchedForm = virCreatePageDomain.createStore(false);
export const $formErrors = virCreatePageDomain.createStore<Record<string, boolean>>({});
export const $isFormValid = virCreatePageDomain.createStore(false);

export const $createVirStartPageData = combine({
    objectsLoaded: $objectsLoaded,
    selectedObject: $selectedWorkObject,
    selectedProject: $selectedProject,
    selectedWorkType: $selectedWorkType,
    selectedContract: $selectedContract,
    selectedTpi: $selectedTpi,
    fromProject: $fromProject,
    projectId: $projectId,
});

export const $createVirStartPageOptions = combine({
    projectOptions: $workProjectOptions,
    objectOptions: $workObjectsOptions,
    workTypeOptions: $workTypOptions,
    contractOptions: $contractOptions,
    tpiOptions: $tpiOptions,
    loadingWorkObjects: loadCatalogWorkObjectsFx.pending,
    loadingCatalogProject: loadCatalogProjectsFx.pending,
    loadingCatalogContracts: loadContractsFx.pending,
    loadingCatalogWorkTypes: loadWorkTypesFx.pending,
    loadingTpis: loadTpisFx.pending,
    touchedForm: $touchedForm,
    formErrors: $formErrors,
    isFormValid: $isFormValid,
});

export const $constructorStage1SelectedOptions = combine({
    selectedWorkObject: $selectedWorkObject,
    selectedProject: $selectedProject,
    selectedWorkType: $selectedWorkType,
    selectedContract: $selectedContract,
    selectedTpi: $selectedTpi,
    workObjectList: $workObjectsOptions,
    workTypeList: $workTypOptions,
});
