import React, {useEffect, useRef, useState} from 'react';
import {useStore} from 'effector-react';

import {$tasksData} from 'pages/DSPage/model';
import {IAgreementWorksheet} from 'pages/DSPage/types';
import {IStatus} from 'shared/types/statusTypes';
import {TableCellContent, TableHead} from 'shared/ui/Table/Table';
import {WorkExecutionDataRow} from 'pages/DSPage/ui/WorkExecutionDataRow/WorkExecutionDataRow';
import {WorkExecutionActionCell} from 'pages/DSPage/ui/WorkExecutionActionCell/WorkExecutionActionCell';
import {createAgreementWorkObjectName} from 'shared/helpers/additionalAgreements';
import {StyledSpan} from 'shared/styles/commonStyle';
import {CustomTableCell} from 'pages/DSPage/ui/AdditionalAgreementTable/style';

import * as S from 'pages/DSPage/ui/customTableStyle';

interface WorkExecutionObjectListProps {
    vir: IAgreementWorksheet;
    agreementStatus: IStatus;
    isVK: boolean;
    currentWidth: number;
}

export const WorkExecutionObjectList = ({
    vir,
    agreementStatus,
    isVK,
    currentWidth,
}: WorkExecutionObjectListProps) => {
    const taskData = useStore($tasksData);

    const [heightArray, setHeightArray] = useState<number[]>([]);
    const [activeAdds, setActiveAdds] = useState<string | null>(null);
    const showAdds = (id: string) => {
        if (id === activeAdds) {
            setActiveAdds(null);
        } else {
            setActiveAdds(id);
        }
    };
    //метод для получения корректной сквозной нумерации строк в ведомости
    const calcRowSum = (index) =>
        Object.values(vir.work_objects || [])
            .slice(0, index)
            .reduce((sum, workObj) => sum + (workObj.work_specifications ?? []).length, 0);

    const ref = useRef<HTMLDivElement>(null);

    // Собираем коллекцию строк "Вложения и комментарии", чтобы узнать высоту каждой строки
    useEffect(() => {
        const tempArray: number[] = [];
        if (ref.current) {
            const collection: HTMLCollection = ref.current.getElementsByClassName('virCommentRow');

            for (let i = 0; i < collection.length; i++) {
                tempArray.push(collection[i].clientHeight);
            }
            setHeightArray(tempArray);
        }
    }, [ref, activeAdds]);

    const taskStatus = taskData[0]?.status ?? 0;

    // переменная для подстановки высоты строкам комментария
    let currentRowIndex = 0;
    return (
        <S.CustomTableRow noBorder>
            <S.CustomTableCell
                ref={ref}
                flex="1 1 100%"
                justifyContent="flex-start"
                noPadding
                scroll
            >
                {Object.values(vir.work_objects || []).map((workObject, index) => {
                    const currentRowSum = calcRowSum(index);
                    const {gfk, work_specifications} = workObject;
                    const specsArr = Object.values(work_specifications || []);
                    const title = createAgreementWorkObjectName(workObject);
                    return (
                        <React.Fragment key={workObject.id}>
                            <>
                                {index === 0 && (
                                    <S.CustomTableRow minWidth="100%" noBorder>
                                        <S.CustomTableCell flex="0 0 100%" noPadding>
                                            <S.CustomTableRow width="100%" noBorder>
                                                <S.CustomTableCell flex="0 0 90px"></S.CustomTableCell>
                                                <S.CustomTableCell flex="0 0 80px">
                                                    <TableCellContent>
                                                        <TableHead>№ п.п.</TableHead>
                                                    </TableCellContent>
                                                </S.CustomTableCell>
                                                <S.CustomTableCell
                                                    id="target-width"
                                                    flex="1 0 200px"
                                                >
                                                    <TableHead>Наименование работ</TableHead>
                                                </S.CustomTableCell>
                                                <S.CustomTableCell flex="0 0 78px">
                                                    <TableHead>Кол-во</TableHead>
                                                </S.CustomTableCell>
                                                <S.CustomTableCell
                                                    flex="0 0 142px"
                                                    alignItems="flex-end"
                                                >
                                                    <TableHead>Цена за ед., ₽</TableHead>
                                                </S.CustomTableCell>
                                                <S.CustomTableCell flex="0 0 104px">
                                                    <TableHead>Коэф-т</TableHead>
                                                </S.CustomTableCell>
                                                <S.CustomTableCell
                                                    flex="0 0 132px"
                                                    alignItems="flex-end"
                                                >
                                                    <TableHead right>Стоимость, ₽</TableHead>
                                                </S.CustomTableCell>
                                                <S.CustomTableCell flex="0 0 161px">
                                                    <TableHead>Срок выполнения</TableHead>
                                                </S.CustomTableCell>
                                                <S.CustomTableCell flex="0 0 50px" />
                                            </S.CustomTableRow>
                                        </S.CustomTableCell>
                                    </S.CustomTableRow>
                                )}
                                <S.CustomTableRow flex="0 0 52px" minWidth="100%" noBorder sticky>
                                    <S.WorkSpecificationsTitleRow>
                                        <CustomTableCell flex="1 1 100%">
                                            <TableHead>
                                                <StyledSpan lineCount={1}>{title}</StyledSpan>
                                            </TableHead>
                                        </CustomTableCell>
                                    </S.WorkSpecificationsTitleRow>
                                </S.CustomTableRow>
                            </>
                            {specsArr?.length > 0 &&
                                specsArr.map((item, index) => (
                                    <WorkExecutionDataRow
                                        currentWorkObjectId={gfk}
                                        currentVirId={vir?.id}
                                        currentWidth={currentWidth}
                                        key={item.number}
                                        data={item}
                                        index={currentRowSum + index}
                                        activeAdds={activeAdds}
                                        showAdds={showAdds}
                                        taskStatus={taskStatus}
                                        isEditable={agreementStatus.is_editable}
                                        isVK={isVK}
                                    />
                                ))}
                        </React.Fragment>
                    );
                })}
            </S.CustomTableCell>
            <S.CustomTableCell
                flex="0 0 100px"
                alignItems="stretch"
                justifyContent="flex-start"
                noPadding
                hasElevation
            >
                <>
                    <S.CustomTableRow noBorder>
                        <S.CustomTableCell flex="0 0 100px">
                            <TableHead>{isVK && 'Действие'}</TableHead>
                        </S.CustomTableCell>
                    </S.CustomTableRow>
                </>
                {Object.values(vir.work_objects || []).map((workObject, index) => {
                    const {work_specifications} = workObject;
                    const specsArr = Object.values(work_specifications || []);
                    return (
                        <React.Fragment key={`${workObject.id}_${index}`}>
                            {specsArr?.length > 0 &&
                                specsArr.map((item, innerIndex) => {
                                    return (
                                        <WorkExecutionActionCell
                                            key={`${item.number}${innerIndex}`}
                                            data={item}
                                            index={innerIndex}
                                            taskStatus={taskStatus}
                                            isEditable={agreementStatus.is_editable}
                                            commentHeight={heightArray[currentRowIndex++] ?? 0}
                                        />
                                    );
                                })}
                        </React.Fragment>
                    );
                })}
            </S.CustomTableCell>
        </S.CustomTableRow>
    );
};
