import styled, {css} from "styled-components";

const CIRCULAR_ANIMATION_DURATION = '1.0s';
const LINEAR_ANIMATION_DURATION = '2.0s';

const baseProgressCircularInfiniteMixin = () => css`
  border: 4px solid #FDD835;
  border-radius: 50%;
  border-top: 4px solid transparent;
  width: 44px;
  height: 44px;
  -webkit-animation: spin ${CIRCULAR_ANIMATION_DURATION} linear infinite; /* Safari */
  animation: spin ${CIRCULAR_ANIMATION_DURATION} linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const progressCircularInfiniteMixin = (props) => css`
  ${baseProgressCircularInfiniteMixin()}
`

export const ProgressCircularInfiniteStyled = styled.div`
  ${(props) => progressCircularInfiniteMixin(props)}
`

export const ProgressCircularWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const baseProgressLinear = () => css`
  position: relative;
  height: 4px;
  background-color: #191C341F;
  overflow: hidden;

  div {
    position: relative;
    background: linear-gradient(88.87deg, #FECA48 -2.46%, #FF7D5D 20.58%, #B732A2 49.09%, #621A9F 80.89%, #030013 115.95%);
    height: 4px;
  }
`

const baseProgressLinearInfiniteMixin = () => css`
  ${baseProgressLinear()}
  div {
    width: 60%;
    animation: progress ${LINEAR_ANIMATION_DURATION} infinite linear;

    @keyframes progress {
      0% {
        margin-left: -60%;
      }
      100% {
        margin-left: 100%;
      }
    };
  }
`

export const progressLinearInfiniteMixin = (props) => css`
  ${baseProgressLinearInfiniteMixin()}
`

const baseProgressLinearMixin = (props) => css`
  ${baseProgressLinear()}
  div {
    width: ${props.progress}%;
  }
`

export const progressLinearMixin = (props) => css`
  ${Number.isInteger(props.progress) && props.progress >= 0 && props.progress <= 100 ? baseProgressLinearMixin(props) : baseProgressLinearInfiniteMixin()}
`

export const ProgressLinearStyled = styled.div`
  ${(props) => progressLinearMixin(props)}
`

export const progressLinearContainerMixin = (props) => css`
  ${progressLinearMixin(props)}
  ${css`
    position: absolute;
    width: 100%;
    bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  `}
`

export const ProgressLinearContainerStyled = styled.div`
  ${(props) => progressLinearContainerMixin(props)}
`
