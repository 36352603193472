import React from 'react';
import {Icons} from '@beeline/design-tokens/js/iconfont';
import * as S from './style';
import {PopoverHelper} from '@beeline/design-system-react';
import {PROHIBITED_OBJECT_HELPER_TEXT, PROHIBITED_SPECIFICATION_HELPER_TEXT} from '../const';

interface ProhibitedItemIconProps {
    externallyDisabled?: boolean;
}

export const ProhibitedItemIcon = ({externallyDisabled}: ProhibitedItemIconProps) => {
    const helperBody = externallyDisabled ? PROHIBITED_OBJECT_HELPER_TEXT : PROHIBITED_SPECIFICATION_HELPER_TEXT;
    return (
        <PopoverHelper body={helperBody}>
            <S.StyledIcon iconName={Icons.Cancel} color="orange" size="large" />
        </PopoverHelper>
    );
};
