import {Button} from '@beeline/design-system-react';
import styled, {createGlobalStyle} from 'styled-components/macro';

export const ContentWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
`;

export const StyledButton = styled(Button)`
    margin-top: 24px;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 24px;
`;

export const GlobalStyles = createGlobalStyle`
  div[data-portal] {
    --selectWidth: 300px
  }
`;
