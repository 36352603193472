import {Checkbox} from '@beeline/design-system-react';
import React, {useRef} from 'react';

import {TableCell} from 'shared/ui/TableCell';
import {useHover} from 'shared/hooks/useHover';

interface OrdinalCellProps {
    index: number;
    checked: boolean;
    disabled: boolean;
    handleCheck: () => void;
}

export const OrdinalCell = ({index, checked, disabled, handleCheck}: OrdinalCellProps) => {
    const hoverRef = useRef<HTMLTableCellElement>(null);
    const isHovering = useHover(hoverRef);

    return (
        <TableCell ref={hoverRef} alignRight>
            {(!disabled && isHovering) || checked ? (
                <Checkbox checked={checked} onChange={handleCheck} />
            ) : (
                index
            )}
        </TableCell>
    );
};
