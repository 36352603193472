import {CatalogWorkObjectOption} from 'pages/VirStart/types';
import {CatalogWorkType} from 'pages/AddWorkObjects/types';
import {TpiSpecification} from 'pages/VirSelect/types';

import {v4 as uuidv4} from 'uuid';
import {getCalculatedSpecsWithDefaultQuantity} from './calcultation';
import {ConstructorVirWorkObject, EWorkObjectTypes} from 'shared/types/additionalAgreementsTypes';

export function extractWorkObjects({
    workObjectIdToAdd,
    workObject,
    workType,
    specs,
    virId,
}: {
    workObjectIdToAdd?: string;
    workObject: CatalogWorkObjectOption;
    workType: CatalogWorkType;
    specs: TpiSpecification[];
    virId: string;
}): Record<string, ConstructorVirWorkObject> {
    const objectId = workObjectIdToAdd || `front -${uuidv4()}`;
    // define work objects if lav, then add two objects, if not, just one
    const isWorkObjectLav = !!workObject?.lavParts?.length;
    if (!isWorkObjectLav) {
        const notLavWorkObject: Record<string, ConstructorVirWorkObject> = {
            [`${objectId}`]: {
                virId,
                work_type_id: workType.work_types_id,
                object_type: workObject.object_type as EWorkObjectTypes,
                object_name: workObject.label,
                object_id: objectId,
                transport_net_id: workObject.transport_net_id,
                is_half_set: workObject?.is_half_set || false,
                work_specifications: getCalculatedSpecsWithDefaultQuantity(specs),
            },
        };
        return notLavWorkObject;
    } else {
        const lavWorkObject: Record<string, ConstructorVirWorkObject> =
            workObject.lavParts?.reduce((acc, curr) => {
                const id = `front -${uuidv4()}`;
                return {
                    ...acc,
                    [`${id}`]: {
                        ...curr,
                        virId,
                        object_id: id,
                        work_type_id: workType.work_types_id,
                        transport_net_id: curr.transport_net_id,
                        work_specifications:
                            curr.object_type === EWorkObjectTypes.MAIN_PART
                                ? getCalculatedSpecsWithDefaultQuantity(
                                      specs.map((el) => ({
                                          ...el,
                                          virId: virId,
                                          workObjectId: id,
                                      })),
                                  )
                                : [],
                    },
                };
            }, {}) || {};
        return lavWorkObject;
    }
}
