import React from 'react'
import { WrapperOffsetStyled } from 'shared/ui/Wrappers/style'

function WrapperOffset(props) {
	const {
		children,
		offset,
		offsetTop,
		offsetBottom,
		offsetLeft,
		offsetRight,
		...rest
	} = props
	return (
		<WrapperOffsetStyled {...props}>
			{children}
		</WrapperOffsetStyled>
	)
}

export default WrapperOffset;