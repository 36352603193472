import React, {useMemo, useState} from 'react';
import {Icon} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import styles, {
    AccordionBody,
    AccordionTitleBlock,
    AccordionTitleBlockWrap,
    AccordionWrapper,
    RightSlot,
} from './style';
import {activeAccordionSelected} from './model';

export const Accordion = styles.Accordion;
export const AccordionFooter = styles.AccordionFooter;

export function AccordionItem(props) {
    const {
        id = '',
        active = false,
        setActive,
        children,
        title,
        status,
        noOverflow,
        ...rest
    } = props;
    const [_active, _setActive] = useState(active);

    const toggleMenu = () => {
        id !== '' && activeAccordionSelected(id);
        if (setActive) {
            setActive(!active);
        } else {
            _setActive(!_active);
        }
    };

    const activeAccordion = useMemo(() => {
        if (setActive) {
            return active;
        }
        return _active;
    }, [setActive, active, _active]);

    return (
        <AccordionWrapper id={id} {...rest}>
            <AccordionTitleBlock onClick={toggleMenu} $active={activeAccordion}>
                <AccordionTitleBlockWrap>
                    <Icon iconName={active ? Icons.ArrowUp : Icons.ArrowDown} size="small" />
                    {title}
                    {rest?.rightslot && <RightSlot>{rest?.rightslot}</RightSlot>}
                </AccordionTitleBlockWrap>
                {status}
            </AccordionTitleBlock>
            <AccordionBody $active={activeAccordion} noOverflow={noOverflow}>
                {activeAccordion && children}
            </AccordionBody>
        </AccordionWrapper>
    );
}

export default AccordionItem;
