import styled, {css} from 'styled-components/macro';
import Counter from 'shared/ui/deprecated/Counter/Counter';

export const CustomTableRow = styled.div<{
    width?: string;
    maxWidth?: string;
    minWidth?: number | string;
    height?: string;
    justifyContent?: string;
    alignItems?: string;
    noBorder?: boolean;
    flex?: string;
    flexDirection?: string;
    disable?: boolean;
    sticky?: boolean;
    hidden?: boolean;
}>`
    display: flex;
    border-bottom: 1px solid #e0e0e0;
    transition-duration: 0.2s;
    ${(props) =>
        props.width &&
        css`
            width: ${props.width};
        `}
    ${(props) =>
        props.minWidth &&
        css`
            min-width: ${props.minWidth};
        `}
  ${(props) =>
        props.height &&
        css`
            height: ${props.height};
        `}
  ${(props) =>
        props.justifyContent &&
        css`
            justify-content: ${props.justifyContent};
        `}
  ${(props) =>
        props.alignItems &&
        css`
            align-items: ${props.alignItems};
        `}

  ${(props) =>
        props.noBorder &&
        css`
            border-bottom: none;
        `}
  ${(props) =>
        props.flex &&
        css`
            flex: ${props.flex};
        `}
  ${(props) =>
        props.flexDirection &&
        css`
            flex-direction: ${props.flexDirection};
        `}
  ${(props) =>
        props.disable &&
        css`
            display: none;
        `}${(props) =>
        props.sticky &&
        css`
            position: sticky;
            left: 0;
        `}${(props) =>
        props.disable &&
        css`
            display: none;
        `}
`;

export const CustomTableCell = styled.div<{
    maxWidth?: string;
    justifyContent?: string;
    alignItems?: string;
    flex?: string;
    flexGrow?: number;
    flexBasis?: string;
    flexShrink?: string;
    noPadding?: boolean;
    scroll?: boolean;
    noFlex?: boolean;
    hasElevation?: boolean;
    height?: string;
}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 52px;
    padding: 8px 16px;

    ${(props) =>
        props.justifyContent &&
        css`
            justify-content: ${props.justifyContent};
        `}
    ${(props) =>
        props.alignItems &&
        css`
            align-items: ${props.alignItems};
        `}
  ${(props) =>
        props.flex &&
        css`
            flex: ${props.flex};
        `}
  ${(props) =>
        props.noPadding &&
        css`
            padding: 0;
        `}
  ${(props) =>
        props.scroll &&
        css`
            overflow-x: auto;
        `}
  ${(props) =>
        props.noFlex &&
        css`
            display: block;
        `}
  ${(props) =>
        props.hasElevation &&
        css`
            box-shadow: -8px 0 10px -6px rgba(0, 0, 0, 0.08);
        `}
  ${(props) =>
        props.maxWidth &&
        css`
            max-width: ${props.maxWidth};
        `}
  ${(props) =>
        props.height &&
        css`
            height: ${props.height};
        `}
  ${(props) =>
        props.hidden &&
        css`
            display: none;
        `}
`;

export const TableDiv = styled.div`
    flex: 0 0 108px;
    width: 100%;
    display: table;
`;

export const IconContainter = styled.div`
    padding: 0;
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-right: 8px;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    .icon {
        font-size: 24px;
        color: var(--secondary);
        pointer-events: none;
    }
`;


export const CommentBtn = styled.button`
    --db: none;
    position: relative;
    background-color: transparent;
    border: none;
    height: 24px;
    padding: 0;
    width: 24px;
    --icon-color: var(--secondary);

    &:hover {
        --icon-color: var(--body-color);
        --db: block;
    }

    .icon {
        color: var(--icon-color);
        font-size: 24px;
        transition-duration: 0.4s;
    }
`;

export const CounterStatement = styled(Counter)`
    position: absolute;
    z-index: 2;
    bottom: 16px;
    left: 14px;
`;

export const WorkSpecificationsTitleRow = styled.div`
    background: rgba(25, 28, 52, 0.08);
    border-bottom: 1px solid #e0e0e0;
    transition-duration: 0.2s;
    width: 100%;
`;
