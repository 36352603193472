import type { TFileIconComponents } from '../useFileUploader.types';
import { Error as ErrorComponent } from './statusComponents/Error';
import { Loading } from './statusComponents/Loading';
import { Upload } from './statusComponents/Upload';

export const FILE_ICON_COMPONENTS: TFileIconComponents = {
  error: ErrorComponent,
  upload: Upload,
  loading: Loading,
};
