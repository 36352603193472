import styled from 'styled-components/macro';
import MaskInput from 'shared/ui/deprecated/MaskInput/MaskInput';
import Button from 'shared/ui/deprecated/Button/Button';

export const StyledMaskInput = styled(MaskInput)`
    max-width: 242px;
`;

export const AddCoefficientBlock = styled.div`
    display: flex;
    gap: 12px;
`;

export const StyledButton = styled(Button)`
    border: none;

    span {
        display: flex;
        gap: 8px;
        color: var(--bs-primary);

        svg {
            fill: var(--bs-primary);
        }
    }
`;
