import React from 'react'
import { LabelStyled } from 'shared/ui/deprecated/Label/style';

function Label(props) {
	const {
		variant = 'error',
		children,
		icon = false,
		onlyicon = false,
		outline = false,
		...rest
	} = props;
	return (
		<LabelStyled {...props}>
			{icon}
			<span>{children}</span>
		</LabelStyled>
	)
}
export default Label