import React from 'react';
import {CommonLayout} from 'shared/ui/CommonLayout'
import {Button} from '@beeline/design-system-react';

import * as S from './style';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {useNavigate} from 'react-router-dom';

export const ForbiddenPage = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-2);
    };

    const handleGoToMainPage = () => {
        navigate('/');
    };

    return (
        <CommonLayout hasSideBar={false}>
            <S.ForbiddenContent>
                <img src="/img/man.png" alt="Нет доступа" />
                <S.StyledTypography variant="h4" margin="48px 0 8px">
                    Нет доступа
                </S.StyledTypography>
                <S.StyledTypography variant="body1" className="inactiveText" margin="0 0 24px">
                    К сожалению, у вас недостаточно прав для просмотра этой страницы
                </S.StyledTypography>
                <WrapperFlex gap="16px">
                    <Button size="medium" onClick={handleGoBack}>
                        Назад
                    </Button>
                    <Button variant="contained" size="medium" onClick={handleGoToMainPage}>
                        Перейти на главную
                    </Button>
                </WrapperFlex>
            </S.ForbiddenContent>
        </CommonLayout>
    );
};
