import styled, {css} from 'styled-components/macro';

const PortalStyledMixin = (props) => css`
    position: absolute;
    display: none;
    left: 0;
    top: 0;
    z-index: 1000;
    ${props.visible &&
    css`
        display: block;
    `}
    ${props.position === 'top-start' &&
    css`
        top: ${props.top + props.vertical}px;
        left: ${props.left + props.horyzontal}px;
    `}
  ${props.position === 'bottom-start' &&
    css`
        top: ${props.bottom - props.vertical - props.height}px;
        left: ${props.left + props.horyzontal}px;
    `}
  ${props.position === 'top-end' &&
    css`
        top: ${props.top + props.vertical}px;
        left: ${props.right - props.horyzontal - props.width}px;
    `}
  ${props.position === 'bottom-end' &&
    css`
        top: ${props.bottom - props.vertical - props.height}px;
        left: ${props.right - props.horyzontal - props.width}px;
    `}
  ${props.select &&
    css`
        width: var(--selectWidth, 280px);
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 0 0 10px var(--light-control), 0 4px 30px var(--light-control);
        overflow: hidden;
        padding-bottom: 12px;
        padding-top: 12px;
    `}
  ${props.select == 'search' &&
    css`
        width: ${props.widthParent}px;
    `}
  ${props.fullWidth &&
    css`
        width: ${props.widthParent}px;
    `}
`;

export const PortalStyled = styled.div`
    ${(props) => PortalStyledMixin(props)}
`;

const PortalWrapperStyledMixin = (props) => css`
    ${props.flex &&
    css`
        display: flex;
    `}
    ${props.select === 'search' &&
    css`
        width: var(--width-search-category, 100%);
        max-width: var(--width-search-category, 100%);
    `}
  ${props.width &&
    css`
        width: ${props.width};
    `}
`;

export const PortalWrapperStyled = styled.div`
    ${(props) => PortalWrapperStyledMixin(props)}
`;
