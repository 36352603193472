import React from 'react'
import styled from 'styled-components/macro'

const BreadcrumbWrap = styled.div`
display: flex;
padding: 0;
margin-bottom: 0;
`
export default function Breadcrumb(props) {
	const {
		children
	} = props
	return (
		<BreadcrumbWrap>{children}</BreadcrumbWrap>
	)
}
