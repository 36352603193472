import {Domain} from 'effector';

// @ts-ignore
export function resetDomainStoresByEvents(
    domain: Domain,
    // @ts-ignore
    ...triggers: Parameters<Store<unknown>['reset']>[0]
): void {
    // @ts-ignore
    domain.onCreateStore((store) => store.reset(triggers));
}

export function forwardPayload<State, E>() {
    return (state: State, payload: E) => payload;
}
