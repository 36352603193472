import styled, {css} from 'styled-components/macro';

export const Wrapper = styled.div`
    width: 100%;
    background-color: var(--color-divider-grey);
    padding: 0 32px 0 31px;
`;

export const RowWrapper = styled.div<{width: number}>`
  display: flex;
  justify-content: flex-start;
  height: 52px;
  ${(props) =>
      css`
          width: ${props.width !== 0 ? `${props.width}px` : '80%'};
      `}
}

@media (max-width: 1025px) {
  width: auto;
}
`;

export const TitleCell = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
`;

export const SumCell = styled.div`
    flex-basis: 137px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 16px;
`;
