import styled from 'styled-components/macro';
import {Button, Typography} from '@beeline/design-system-react';

export const Subtitle = styled(Typography)`
    margin-top: 8px;
`;

export const StyledButton = styled(Button)`
    align-self: flex-end;
    justify-self: flex-end;
`;
