import React from 'react';
import {Icon, Popover} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont/icons';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';

import * as S from './style';

interface CompletedWorksCompletionCardProps {
    approvedCompletion: number;
    totalCompletion: number;
    children: JSX.Element;
}

export const CompletedWorksCompletionPopover = ({
    approvedCompletion,
    totalCompletion,
    children,
}: CompletedWorksCompletionCardProps) => {
    const iconColor = approvedCompletion === 100 ? 'green' : 'orange';
    return (
        <Popover
            content={
                <S.Wrapper>
                    <WrapperFlex alignItems="center" gap="8px">
                        <S.StyledTypography variant="body3" flex="0 0 160px">
                            По согласованным ВВР
                        </S.StyledTypography>
                        <Icon iconName={Icons.Dot} color={iconColor} />
                        <S.StyledTypography
                            variant="body3"
                            flex="0 0 30px"
                        >{`${approvedCompletion}%`}</S.StyledTypography>
                    </WrapperFlex>
                    <WrapperFlex alignItems="center" gap="8px">
                        <S.StyledTypography variant="body3" flex="0 0 160px">
                            По всем ВВР
                        </S.StyledTypography>
                        <Icon iconName={Icons.Dot} color={iconColor} />
                        <S.StyledTypography
                            variant="body3"
                            flex="0 0 30px"
                        >{`${totalCompletion}%`}</S.StyledTypography>
                    </WrapperFlex>
                </S.Wrapper>
            }
        >
            {children}
        </Popover>
    );
};
