import ModalWindow from 'shared/ui/deprecated/ModalWindow/ModalWindow';
import {ModalText, ModalTitle} from 'components/HeaderVir/style';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import Button from 'shared/ui/deprecated/Button/Button';
import {
    $patternsPage,
    deletePattern,
    setIsDeletePatternModalOpen,
} from 'pages/VirPatternsPage/model';
import React from 'react';
import {useStore} from 'effector-react';

export const DeleteTemplateModal = () => {
    const {selectedPattern, isDeletePatternModalOpen, loadingForDeletePattern} =
        useStore($patternsPage);

    return (
        <ModalWindow open={isDeletePatternModalOpen}>
            <ModalTitle> Вы уверены, что хотите удалить шаблон?</ModalTitle>
            <ModalText>Отменить это действие будет невозможно</ModalText>
            <WrapperOffset offsetTop={16}>
                {' '}
                <WrapperFlex justifyContent="flex-end">
                    <Button color="outline" onClick={() => setIsDeletePatternModalOpen(false)}>
                        Отменить
                    </Button>
                    <Button
                        disabled={loadingForDeletePattern}
                        color="contained"
                        onClick={() => deletePattern(selectedPattern)}
                        className="ms-3"
                    >
                        Удалить
                    </Button>
                </WrapperFlex>
            </WrapperOffset>
        </ModalWindow>
    );
};
