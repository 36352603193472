import React from 'react';
import {Typography} from '@beeline/design-system-react';

import {AgreementAddendumCard} from 'Entities/AgreementAddendumCard';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {COMPLETED_WORKS_TITLE} from 'shared/constants';

import {IShortCompletedWorksSheet} from '../../types';

interface CompletedWorksUnitProps {
    completedWorksSheetList: IShortCompletedWorksSheet[];
}

export const CompletedWorksUnit = ({completedWorksSheetList}: CompletedWorksUnitProps) => {
    if (completedWorksSheetList.length === 0) return null;
    return (
        <div>
            <Typography variant="h5">{COMPLETED_WORKS_TITLE}</Typography>
            <WrapperFlex mt="8px" gap="24px">
                {completedWorksSheetList.map((item) => (
                    <AgreementAddendumCard
                        key={item.id}
                        id={item.id}
                        objectType={5}
                        addendumNumber={item.number}
                        createdAt={item.created_at}
                        approvedAt={item.approved_at}
                        status={item.status}
                    />
                ))}
            </WrapperFlex>
        </div>
    );
};
