import React, {useRef, useState, useMemo} from 'react';
import SearchIcon from 'icons/SearchIcon';
import {CloseBtnCategory} from 'components/CloseBtn';
import PortalWrapper from 'shared/ui/deprecated/Portal/Portal';
import {
    SearchStyled,
    SearchCategoryBlock,
    CloseBtnWrap,
    SearchBtn,
} from 'shared/ui/SearchCategory/style';

export default function SearchCategory(props) {
    const {
        placeholder = 'Поиск',
        children,
        categoryValue = {},
        onEditCategory = () => {},
        categoryValueKey,
        categoryValueLabel,
        inputValue,
        setInputValue,
        position = 'top-start',
        vertical = 0,
        horyzontal = 0,
        onResetCategory,
        onClick,
        ...rest
    } = props;

    const [activeCategory, setActiveCategory] = useState(false);

    const elem = useRef(null);

    const active = useMemo(() => {
        return Boolean(inputValue);
    }, [inputValue]);

    const propsWithDefaults = {...rest, placeholder, active};

    const title = useMemo(() => {
        return categoryValueLabel ? categoryValue[categoryValueLabel] : categoryValue;
    }, [categoryValue]);

    const sBtn = useRef(null);
    const inputSearch = useRef(null);
    const closeBtn = useRef(null);

    const ignoreTrigger = [sBtn, inputSearch, closeBtn];

    return (
        <PortalWrapper
            // fullWidth
            type="click"
            flex
            portal={
                <div ref={elem}>
                    {React.Children.map(children, (child) => {
                        return React.cloneElement(child, {
                            handleEdit: onEditCategory,
                            selected: categoryValue,
                            valueKey: categoryValueKey,
                            valueLabel: categoryValueLabel,
                            setActive: setActiveCategory,
                        });
                    })}
                </div>
            }
            trigger={
                <SearchStyled {...propsWithDefaults}>
                    <SearchBtn id="searchBtn" ref={sBtn} onClick={onClick}>
                        <SearchIcon />
                    </SearchBtn>
                    <input
                        type="text"
                        placeholder={placeholder}
                        value={inputValue}
                        onInput={(e) => setInputValue(e.target.value)}
                        onClick={() => {
                            setActiveCategory(false);
                        }}
                        ref={inputSearch}
                        id="searchInput"
                    />
                    <CloseBtnWrap ref={closeBtn}>
                        {inputValue && (
                            <CloseBtnCategory
                                onClick={() => {
                                    setInputValue('');
                                    if (onResetCategory) {
                                        onResetCategory();
                                    }
                                }}
                                id="resetSearchBtn"
                            />
                        )}
                    </CloseBtnWrap>
                    <SearchCategoryBlock ref={elem}>{title || 'Категории'}</SearchCategoryBlock>
                </SearchStyled>
            }
            select="search"
            setActive={setActiveCategory}
            elem={elem}
            ignore={ignoreTrigger}
            position={position}
            vertical={vertical}
            horyzontal={horyzontal}
        />
    );
}
