import React from 'react';

import {ActionLabel} from 'Entities/ActionLabel';
import {WorkExecutionButtonGroup} from 'Entities/WorkExececutionButtonGroup';
import {CustomTableCell} from 'pages/DSPage/ui/AdditionalAgreementTable/style';
import {sendApprovedItemFx} from 'pages/DSPage/model';

import * as S from '../customTableStyle';

import {IAgreementWorkSpecification} from 'pages/DSPage/types';

export const WorkExecutionActionCell = ({
    index,
    data,
    isEditable,
    taskStatus,
    commentHeight,
}: IActionColumnProps) => {
    const {work_specification_approval: selectedButton, work_specification_approval_total} = data;
    const handleAccept = async () => {
        await sendApprovedItemFx({
            id: data.id,
            action: selectedButton !== true ? true : null,
        });
    };

    const handleReject = async () => {
        await sendApprovedItemFx({
            id: data.id,
            action: selectedButton !== false ? false : null,
        });
    };

    return (
        <S.CustomTableCell justifyContent="flex-start" noPadding>
            {index === 0 && (
                <S.CustomTableRow minWidth="100%" noBorder>
                    <S.WorkSpecificationsTitleRow>
                        <CustomTableCell flex="1 1 100%"></CustomTableCell>
                    </S.WorkSpecificationsTitleRow>
                </S.CustomTableRow>
            )}

            <S.CustomTableRow minWidth="100%" flex="0 0 108px">
                <S.CustomTableCell justifyContent="flex-start">
                    {isEditable && <ActionLabel condition={work_specification_approval_total} />}
                    {!isEditable && taskStatus === 2 && (
                        <WorkExecutionButtonGroup
                            onAccept={handleAccept}
                            onReject={handleReject}
                            selected={selectedButton}
                        />
                    )}
                    {!isEditable && taskStatus < 2 && (
                        <ActionLabel condition={work_specification_approval_total} />
                    )}
                </S.CustomTableCell>
            </S.CustomTableRow>
            <S.CustomTableRow
                width="100%"
                flex={`0 0 ${commentHeight}px`}
                hidden={commentHeight === 0}
                noBorder
            />
        </S.CustomTableCell>
    );
};

interface IActionColumnProps {
    index: number;
    data: IAgreementWorkSpecification;
    taskStatus: number;
    isEditable: boolean;
    commentHeight: number;
}
