import React from 'react';
import {useStore} from 'effector-react';
import {
    Skeleton,
    TableBody,
    TableHead,
    TableHeaderData,
    TableRow,
} from '@beeline/design-system-react';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {$userActions} from 'shared/model/user';
import {ExpandedStyledInnerTableData} from 'shared/styles/commonStyle';

import {innerProcessesColumns, processesColumns} from './processesColumns';
import * as S from '../style';
import {$ProcessesPageStore} from '../../../model';

interface ProcessesTableProps {
    changeTableSort: (data: string) => void;
}

export const ProcessesTable = ({changeTableSort}: ProcessesTableProps) => {
    const {processesStore, isLoading, openId, taskParams} = useStore($ProcessesPageStore);
    const userActions = useStore($userActions);
    const {
        filters: {page_size, ordering},
    } = taskParams;
    const overallWidth = '1192px';
    const rowHeight = '53px';

    const visibleProcessesColumns = processesColumns.filter(
        (column) =>
            taskParams.processesColumns.find((taskItem) => taskItem.id === column.name)?.visible ||
            column.noHiding,
    );
    const innerColspan = visibleProcessesColumns.length;
    return (
        <>
            <TableHead>
                <TableRow>
                    {visibleProcessesColumns.map((column) => (
                        <S.StyledTableHeaderData
                            key={`${column.name}_head`}
                            width={column.width ?? `calc((100%-${overallWidth}) / 2)`}
                            onClick={() => column.hasSorting && changeTableSort(column.name)}
                            $alreadySorting={!!ordering}
                            $arrowUp={column.name === ordering}
                            $arrowDown={
                                `-${column.name}` === ordering ||
                                (ordering === '' && column.name === 'created_at')
                            }
                            $hover={column.hasSorting}
                        >
                            <WrapperFlex>{column.label}</WrapperFlex>
                        </S.StyledTableHeaderData>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {!isLoading &&
                    processesStore &&
                    processesStore.map((item, index) => (
                        <React.Fragment key={`${item.created_at}_row_${index}`}>
                            <TableRow dense>
                                {visibleProcessesColumns.map((column) => (
                                    <S.StyledTableData
                                        key={`${item.created_at}_${column.name}_${index}`}
                                        width={column.width}
                                        height={rowHeight}
                                    >
                                        {column.render &&
                                            column.render(item, userActions, openId === item.id)}
                                    </S.StyledTableData>
                                ))}
                            </TableRow>
                            {openId === item.id && (
                                <TableRow>
                                    <ExpandedStyledInnerTableData colSpan={innerColspan}>
                                        <S.InnerTable>
                                            <TableHead>
                                                <TableRow className="border-bottom">
                                                    <TableHeaderData dense>
                                                        <S.StyledInnerTitle variant="h6">
                                                            Задачи
                                                        </S.StyledInnerTitle>
                                                    </TableHeaderData>
                                                </TableRow>
                                                <TableRow className="border-bottom">
                                                    {innerProcessesColumns.map((column) => (
                                                        <S.StyledInnerTableHeaderData
                                                            key={`title_${column.name}_${item.id}`}
                                                            width={column.width}
                                                        >
                                                            {column.label}
                                                        </S.StyledInnerTableHeaderData>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {item.tasks.map((item, index) => (
                                                    <TableRow
                                                        key={`${item.created_at}${index}_`}
                                                        dense
                                                    >
                                                        {innerProcessesColumns.map((column) => (
                                                            <S.StyledTableData
                                                                key={`${item.created_at}_cell_${column.name}${index}`}
                                                                width={column.width}
                                                                height={rowHeight}
                                                                dense
                                                            >
                                                                {column.render &&
                                                                    column.render(item)}
                                                            </S.StyledTableData>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </S.InnerTable>
                                    </ExpandedStyledInnerTableData>
                                </TableRow>
                            )}
                        </React.Fragment>
                    ))}
                {isLoading &&
                    [...Array(page_size ?? 10)].map((item, index) => {
                        return (
                            <TableRow key={`skeleton_${index}`} dense>
                                {visibleProcessesColumns.map((column) => (
                                    <S.StyledTableData
                                        width={column.width ?? `calc((100%-${overallWidth}) / 2)`}
                                        key={column.name}
                                        height={rowHeight}
                                    >
                                        <Skeleton variant="line" height={18} width="80%" />
                                    </S.StyledTableData>
                                ))}
                            </TableRow>
                        );
                    })}
            </TableBody>
        </>
    );
};
