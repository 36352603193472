import styled, {css} from 'styled-components/macro';
import {CheckboxLabel} from 'shared/ui/deprecated/Checkbox/Checkbox';
import {Checkbox} from '@beeline/design-system-react';

export const Index = styled.div`
    font-size: 17px;
    font-weight: 500;
    align-self: center;
    text-align: center;
    width: 24px;
`;

export const SelectItemCheckStyled = styled.div`
    display: flex;
    padding-left: 16px;
    background-color: #fff;
    cursor: pointer;
    min-height: 1.5rem;
    transition-duration: 0.4s;

    &:hover {
        background-color: #ededef;
    }
`;

export const StyledCheckbox = styled(Checkbox)`
    padding: 12px 16px;
`;

export const CheckboxLabelStyled = styled(CheckboxLabel)`
    &&& {
        background-color: unset;
    }
`;
