import React from 'react';
import {useStore} from 'effector-react';

import {$projectListColumnFilter} from 'pages/ProjectPage/model';
import ArrowUp from 'icons/ArrowUp';
import {Table, TableCell, TableCellContent, TableHead, TableString} from 'shared/ui/Table/Table';
import {Skeleton} from 'shared/ui/deprecated/Skeleton/Skeleton';

import {FilterString, ProjectString, ProjectTableWrap} from './style';

function TableInfoContent() {
    const {columns} = useStore($projectListColumnFilter);
    return (
        <ProjectString>
            {columns.map(
                (item, index) =>
                    item.visible && (
                        <TableCell key={index}>
                            <TableCellContent>
                                <Skeleton height={20} />
                            </TableCellContent>
                        </TableCell>
                    ),
            )}
        </ProjectString>
    );
}

export default function ProjectPageTableSkeleton({filterDisplay, filterPosition}) {
    const {columns} = useStore($projectListColumnFilter);
    return (
        <ProjectTableWrap className="scrollbar-horizontal">
            <Table minWidth="unset">
                <TableString>
                    {columns.map(
                        (item, index) =>
                            item.visible && (
                                <TableCell
                                    width={item.width}
                                    key={item.name}
                                    minWidth={item.minWidth}
                                >
                                    <TableHead>
                                        {item.label}
                                        <>
                                            {index == filterDisplay && filterPosition && (
                                                <FilterString>
                                                    <ArrowUp />
                                                </FilterString>
                                            )}
                                            {index == filterDisplay && !filterPosition && (
                                                <FilterString reverse>
                                                    <ArrowUp />
                                                </FilterString>
                                            )}
                                        </>
                                    </TableHead>
                                </TableCell>
                            ),
                    )}
                </TableString>

                {[...Array(10)].map((x, i) => (
                    <TableInfoContent key={i} />
                ))}
            </Table>
        </ProjectTableWrap>
    );
}
