import React, {useState} from 'react';
import {useStore} from 'effector-react';
import cn from 'classnames';

import {Button, TableData} from '@beeline/design-system-react';
import {CompletedWorksActionLabel} from 'Entities/CompletedWorksActionLabel';
import {CoefficientUnit} from 'Entities/CoefficientUnit';
import {SealedPriceData} from 'Entities/TableCells/SealedPriceData';
import {CommentCell} from 'Entities/TableCells/CommentCell';
import {PositionCell} from 'pages/VirConstructor/SpecificationRow/Cells/PositionCell';
import {NameCell} from 'pages/VirConstructor/SpecificationRow/Cells/NameCell';
import {SumCell} from 'pages/VirConstructor/SpecificationRow/Cells/SumCell';
import {IAdaptedCompletedWorksSpecification} from 'shared/types/completedWorksTypes';
import {TableCell, TableStringSelected} from 'shared/ui/Table/Table';
import {TAttachmentFile} from 'shared/types/FileTypes';

import {OrdinalCell} from './OrdinalCell/OrdinalCell';
import {ActionCell} from './ActionCell/ActionCell';
import {DeleteCell} from './DeleteCell/DeleteCell';
import {AttachmentRow} from './AttachmentRow/AttachmentRow';
import {QuantityCell} from './QuantityCell/QuantityCell';
import {
    handleDownloadAttachment,
    IObjectLocation,
    ISpecificationLocation,
} from '../../../../model/helpers';
import {
    $constructorMode,
    $loadingAttachmentId,
    checkedSpecificationDeleted,
    singleSpecificationAdded,
    specificationAttachmentsChanged,
    specificationCommentChanged,
    specificationQuantityChanged,
    specificationSelected,
} from '../../../../model/stage2';

interface ObjectSpecificationProps {
    specification: IAdaptedCompletedWorksSpecification;
    objectLocation: IObjectLocation;
    index: number;
    disabled?: boolean;
    inactive?: boolean;
    finished?: boolean;
}

export const ObjectSpecificationRow = ({
    specification,
    objectLocation,
    index,
    disabled = false,
    inactive = false,
    finished = false,
}: ObjectSpecificationProps) => {
    const [showAttachmentRow, setShowAttachmentRow] = useState(false);
    const loadingAttachmentId = useStore($loadingAttachmentId);
    const constructorMode = useStore($constructorMode);
    const {
        id,
        accuracy,
        name,
        checked,
        note,
        number,
        category_name,
        coefficient,
        coefficient_foundation,
        coefficient_ams,
        coefficient_remote_territory,
        is_agreed_price,
        is_foundation,
        is_ams,
        is_quantity_editable,
        link_document_id,
        price,
        unit,
        work_coefficients,
        work_detail,
        sum,
        quantity,
        quantity_completed,
        quantity_max,
        quantity_full,
        mode,
        result,
        completed_work_specification_comment,
        completed_work_specification_comments,
        completed_work_specification_attachments,
        vvr_number,
        work_specification_comments,
        work_specification_attachments,
    } = specification;

    const specificationLocation: ISpecificationLocation = {...objectLocation, specificationId: id};

    const toggleAttachmentRow = () => {
        setShowAttachmentRow((prevState) => !prevState);
    };

    const handleCheckSpecification = () => {
        specificationSelected({
            ...specificationLocation,
            checked: !checked,
        });
    };
    const handleAddSingleSpecification = () => {
        singleSpecificationAdded(specificationLocation);
    };
    const handleDeleteCheckedSpecification = () => {
        checkedSpecificationDeleted(specificationLocation);
    };

    const handleChangeComment = (comment: string) => {
        specificationCommentChanged({...specificationLocation, comment: comment});
    };

    const handleChangeSpecAttachments = (attachmentList: TAttachmentFile[]) => {
        specificationAttachmentsChanged({...specificationLocation, attachments: attachmentList});
    };

    const handleChangeQuantity = (quantity: string) => {
        specificationQuantityChanged({...specificationLocation, quantity});
    };

    const inactiveClassName = cn({inactiveText: inactive});
    const appliedCoefficientNumbers = work_coefficients
        ?.filter((el) => !el.is_manual && el.number)
        .map((el) => el.number)
        .join(', ');
    const notChecked = mode === 'available' && !finished;
    const isReadOnly = mode !== 'current';
    const hasOwnComments =
        !!completed_work_specification_comment && completed_work_specification_comment !== '';
    const attachmentCount = completed_work_specification_attachments?.length ?? 0;
    const hasAgreementAttachmentsOrComments =
        !!work_specification_comments?.length || !!work_specification_attachments?.length;
    const hasOwnAttachmentsOrComments =
        !!completed_work_specification_comments?.length ||
        !!completed_work_specification_attachments?.length;
    const hasAnyInformation = hasAgreementAttachmentsOrComments || hasOwnAttachmentsOrComments;
    const quantityModifyAllowed = constructorMode === 'drafting' || (is_quantity_editable ?? true);
    return (
        <>
            <TableStringSelected noBorder={false} className={inactiveClassName}>
                <OrdinalCell
                    index={index}
                    checked={checked}
                    disabled={disabled}
                    handleCheck={handleCheckSpecification}
                />
                <PositionCell
                    number={number}
                    coefficient={+coefficient}
                    appliedCoefficientNumbers={appliedCoefficientNumbers}
                />
                <NameCell name={name} note={note ?? undefined} withoutWidth />

                <QuantityCell
                    accuracy={accuracy}
                    quantity={quantity}
                    quantity_completed={quantity_completed}
                    quantity_full={quantity_full}
                    quantity_max={quantity_max}
                    onChange={handleChangeQuantity}
                    modifyAllowed={quantityModifyAllowed}
                    disabled={notChecked || finished}
                    mode={mode}
                />
                <TableData className={inactiveClassName} width="146px">
                    <SealedPriceData isAgreedPrice={is_agreed_price} price={price} unit={unit} />
                </TableData>
                <TableData width="104px">
                    <CoefficientUnit
                        coefficients={work_coefficients}
                        foundationCoefficient={is_foundation ? +coefficient_foundation : undefined}
                        territoryCoefficient={+coefficient_remote_territory}
                        amsCoefficient={is_ams ? +coefficient_ams : undefined}
                        totalCoefficient={+coefficient}
                        className={inactiveClassName}
                    />
                </TableData>

                <SumCell sum={+sum} />
                {notChecked ? (
                    <>
                        <TableCell width="172px">
                            <Button variant="plain" onClick={handleAddSingleSpecification}>
                                Добавить
                            </Button>
                        </TableCell>
                        <CommentCell
                            onClick={toggleAttachmentRow}
                            hasComments={hasOwnComments}
                            attachmentCount={attachmentCount}
                            showButton={hasAnyInformation}
                        />
                        <TableData width="52px" />
                    </>
                ) : (
                    <>
                        {!finished && (
                            <>
                                <ActionCell
                                    action={result}
                                    specificationLocation={specificationLocation}
                                />
                                <CommentCell
                                    onClick={toggleAttachmentRow}
                                    hasComments={hasOwnComments}
                                    attachmentCount={attachmentCount}
                                />
                                <DeleteCell onClick={handleDeleteCheckedSpecification} />
                            </>
                        )}
                    </>
                )}
                {finished && (
                    <>
                        <TableCell width="198px">
                            <CompletedWorksActionLabel
                                action={result}
                                mode={mode}
                                completelyClosed={quantity_completed === quantity_full}
                                completedWorksId={link_document_id}
                                completedWorksNumber={vvr_number}
                            />
                        </TableCell>

                        <CommentCell
                            onClick={toggleAttachmentRow}
                            hasComments={hasOwnComments}
                            attachmentCount={attachmentCount}
                            showButton={hasAnyInformation}
                        />

                        <TableData width="52px" />
                    </>
                )}
            </TableStringSelected>
            {showAttachmentRow && (
                <AttachmentRow
                    id={id}
                    comment={completed_work_specification_comment}
                    readonlyComments={completed_work_specification_comments}
                    attachments={completed_work_specification_attachments}
                    parentComment={work_specification_comments}
                    parentAttachments={work_specification_attachments}
                    isReadonly={isReadOnly}
                    loadingAttachmentId={loadingAttachmentId}
                    onChangeComment={handleChangeComment}
                    onChangeAttachments={handleChangeSpecAttachments}
                    handleDownloadAttachment={handleDownloadAttachment}
                    jobCategory={work_detail}
                    jobCategoryDescription={category_name}
                />
            )}
        </>
    );
};
