import React from 'react';
import {useStore} from 'effector-react';
import {Button, Counter, Icon, Search, Select} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {CONTRACT_NUMBER_TITLE, REGION_TITLE} from 'shared/constants';

import {
    $contractListPageStore,
    $contractListTerritorialStore,
    $innerSearchValue,
    searchValueApplied,
    searchValueChanged,
    searchValueReset,
    selectedRegionDataChanged,
} from '../model';

interface ContractFilterUnitProps {
    toggleSidebar: () => void;
}

export const ContractFilterUnit = ({toggleSidebar}: ContractFilterUnitProps) => {
    const {appliedFiltersCount} = useStore($contractListPageStore);
    const searchValue = useStore($innerSearchValue);
    const {regionData, selectedRegionData} = useStore($contractListTerritorialStore);
    return (
        <WrapperFlex justifyContent="space-between" alignItems="flex-start" gap="16px" width="100%">
            <WrapperFlex gap="16px">
                <Select
                    id="RegionSelect"
                    label={REGION_TITLE}
                    onChange={selectedRegionDataChanged}
                    options={regionData}
                    values={selectedRegionData}
                    dropdownClassName="whiteSpace ds-theme--light"
                    multiple
                    fullWidth
                    filter
                />
            </WrapperFlex>
            <WrapperFlex justifyContent="flex-end" alignItems="flex-start" gap="16px">
                <Counter
                    count={appliedFiltersCount}
                    className={`${appliedFiltersCount === 0 && 'emptyCounter'}`}
                >
                    <Button
                        startIcon={<Icon iconName={Icons.Tune} />}
                        variant="outlined"
                        size="medium"
                        onClick={toggleSidebar}
                        id="projectsSettingsBtn"
                    />
                </Counter>
                <Search
                    value={searchValue}
                    onClear={searchValueReset}
                    onChange={searchValueChanged}
                    onSearch={(value) => searchValueApplied((value ?? '').toString())}
                    placeholder={CONTRACT_NUMBER_TITLE}
                    size="medium"
                    isAdaptive
                />
            </WrapperFlex>
        </WrapperFlex>
    );
};
