import styled, { css } from "styled-components";

const RowStyledMixin = (props) => css`
	${props.gutterX && css`
	--bs-gutter-x: ${props.gutterX}px;
	`}
	${props.gutterY && css`
	--bs-gutter-y: ${props.gutterY}px;
	`}
`
export const RowStyled = styled.div`
	${(props) => RowStyledMixin(props)}
`;