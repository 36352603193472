import styled from 'styled-components/macro';
import {PopoverHelper} from '@beeline/design-system-react';
import {TableCell, TableCellContent} from 'shared/ui/Table/Table';
import CommentIcon, {CommentAddIcon} from 'icons/CommentIcon';
import Counter from 'shared/ui/deprecated/Counter/Counter';
import {TpiSpecification} from 'pages/VirSelect/types';
import {
    checkSpecificationHasIncreasingCoefficientsWithNoAttachmentsOrComments,
    checkSpecificationHasManualCoefficientsWithNoAttachmentsOrComments,
} from '../../../../processes/contructor';

interface CommentCellProps {
    data: TpiSpecification;
    showAdds: (id: string) => void;
}

export function CommentCell(props: CommentCellProps) {
    const {data, showAdds} = props;
    const isEmptyCommentAndAttachment =
        checkSpecificationHasIncreasingCoefficientsWithNoAttachmentsOrComments(data);
    const isEmptyAttachment =
        checkSpecificationHasManualCoefficientsWithNoAttachmentsOrComments(data);
    const isErrorState =
        isEmptyCommentAndAttachment ||
        isEmptyAttachment ||
        ((data.is_agreed_price || data.is_category) &&
            (!+data.price ||
                !data.work_category_id ||
                !data.category_name ||
                !data.work_specification_comments));
    const errorMessage = isEmptyCommentAndAttachment
        ? 'Заполенение комментария и вложений обязательно!'
        : data.is_agreed_price || isEmptyAttachment
        ? 'Заполнение комментария обязательно!'
        : 'Заполнение доп. работ и комментария обязательно!';
    return (
        <TableCell width="56px">
            <TableCellContent>
                <PopoverHelper disableHover={!isErrorState} body={errorMessage}>
                    <CommentBtn
                        onClick={() => {
                            showAdds(data?.id);
                        }}
                    >
                        {data?.work_specification_comments?.[0]?.comment ? (
                            <CommentAddIcon />
                        ) : (
                            <CommentIcon />
                        )}
                        {isErrorState && <CounterStatement error>!</CounterStatement>}
                        {!!data.work_specification_attachments?.length && (
                            <CounterStatement>
                                {data.work_specification_attachments?.length}
                            </CounterStatement>
                        )}
                    </CommentBtn>
                </PopoverHelper>
            </TableCellContent>
        </TableCell>
    );
}

export const CounterStatement = styled(Counter)`
    position: absolute;
    z-index: 2;
    bottom: 16px;
    left: 14px;
`;
export const CommentBtn = styled.button`
    --db: none;
    position: relative;
    background-color: transparent;
    border: none;
    height: 24px;
    padding: 0;
    width: 24px;
    --icon-color: var(--secondary);

    &:hover {
        --icon-color: var(--body-color);
        --db: block;
    }

    .icon {
        color: var(--icon-color);
        font-size: 24px;
        transition-duration: 0.4s;
    }
`;
