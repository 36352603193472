import styled from 'styled-components/macro';

export const Menu = styled.div`
    display: flex;
    gap: 16px;
    @media (min-width: 1200px) {
        justify-content: space-between;
        flex-direction: row;
    }
    flex-direction: column;
    margin-bottom: 24px;
`;

export const FunctionWrap = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    @media (min-width: 1600px) {
        --width-search-category: 411px;
    }
    @media (max-width: 1600px) and (min-width: 1200px) {
        --width-search-category: 370px;
    }
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    flex-grow: 1;
`;
