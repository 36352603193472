import React from 'react';
import {Progress, TextArea, Typography} from '@beeline/design-system-react';

import {downloadAttachmentFile} from 'processes/CreationJobProcess/model/stage3';
import {DownloadableAttachment} from 'Features/DownloadableAttachment';
import {NriRllFileType} from 'shared/types/jobsTypes';
import {EAttachmentType, TAttachmentFile} from 'shared/types/FileTypes';
import {FileUploadButton} from 'shared/ui/deprecated/File/File';

import * as S from './style';

interface AttachmentsAndCommentsBlockProps {
    attachmentFilesList: TAttachmentFile[];
    comment: string;
    files: NriRllFileType[];
    downloadBsFile: (id: string) => void;
    downloadRllFile: ({
        id,
        fileName,
        mimeType,
    }: {
        id: number;
        fileName: string;
        mimeType: string;
    }) => void;
    handleChangeComment: (value: string) => void;
    isLoading: boolean;
    buttonsState: {
        bsButtonIsLoading: boolean;
        rllButtonIsLoading: boolean;
        attachmentButtonIsLoading: boolean;
        buttonsAreDisabled: boolean;
    };
    projectId: string;
    onAddFile: (list: FileList) => void;
}

export const AttachmentsAndCommentsBlock = ({
    attachmentFilesList,
    comment,
    files,
    isLoading,
    downloadBsFile,
    downloadRllFile,
    handleChangeComment,
    buttonsState,
    projectId,
    onAddFile,
}: AttachmentsAndCommentsBlockProps) => {
    const handleDownloadRllAttachment = (id: string, fileName: string, type: EAttachmentType) => {
        const mimeType = files.find((item) => item.id === +id)?.mimeType ?? '';
        downloadRllFile({id: +id, fileName, mimeType});
    };
    const handleDownloadBsAttachment = (id: string, fileName: string, type: EAttachmentType) => {
        downloadBsFile(projectId);
    };

    const handleDownloadAttachment = (id: string, fileName: string, type: EAttachmentType) => {
        downloadAttachmentFile({id, file_name: fileName, type: type});
    };

    return (
        <S.Wrapper className="mb-5">
            <S.Container flexBasis="602px">
                <div className="pb-2">
                    <Typography className="mb-2" variant="h5">
                        Вложения
                    </Typography>
                    <div className="pe-4 mb-2">
                        <DownloadableAttachment
                            id={'0'}
                            title="Исходные данные по БС"
                            type={EAttachmentType.Error}
                            handleDownloadAttachment={handleDownloadBsAttachment}
                            disabled={buttonsState.buttonsAreDisabled}
                            isLoading={buttonsState.bsButtonIsLoading}
                        />
                        {files.length > 0 && !isLoading ? (
                            files?.map((file, index) => (
                                <DownloadableAttachment
                                    key={file.id}
                                    id={file.id.toString()}
                                    title={file.fileName}
                                    type={EAttachmentType.Error}
                                    handleDownloadAttachment={handleDownloadRllAttachment}
                                    disabled={buttonsState.buttonsAreDisabled}
                                    isLoading={buttonsState.rllButtonIsLoading}
                                />
                            ))
                        ) : (
                            <Progress shape="circle" />
                        )}
                        {attachmentFilesList.length > 0 &&
                            attachmentFilesList.map((file) => (
                                <DownloadableAttachment
                                    key={file.id}
                                    id={file.id}
                                    title={file.file_name}
                                    type={file.type}
                                    handleDownloadAttachment={handleDownloadAttachment}
                                    //disabled={buttonsState.buttonsAreDisabled}
                                    // временно заблокировано
                                    disabled
                                    isLoading={buttonsState.attachmentButtonIsLoading}
                                />
                            ))}
                    </div>
                    <FileUploadButton
                        onChange={onAddFile}
                        accept={'*'}
                        color="outline"
                        files={files}
                    >
                        Добавить файлы
                    </FileUploadButton>
                </div>
            </S.Container>
            <S.Container flexBasis="626px">
                <Typography className="mb-2" variant="h5">
                    Комментарии к документу
                </Typography>

                <TextArea
                    value={comment}
                    placeholder="Комментарий (при необходимости)"
                    onChange={(e) => handleChangeComment(e.currentTarget.value)}
                    fullWidth
                />
            </S.Container>
        </S.Wrapper>
    );
};
