import {createGate} from 'effector-react';
import {combine, createDomain} from 'effector';
import {ICoefficient} from 'pages/ApplyCoefficientsPage/types';
import {loadCoefficientsFx} from '../index';

export const increasingCoefficientDomain = createDomain('increasingCoefficientDomain');

export const ApplyIncreasingCoefficientsGate = createGate<string>();

export const setIncreasingSearchTerm = increasingCoefficientDomain.createEvent();
export const filterIncreasingBySearchTerm = increasingCoefficientDomain.createEvent();

export const toggleAllIncreasingSelect = increasingCoefficientDomain.createEvent();
export const toggleIncreasingSelect = increasingCoefficientDomain.createEvent<ICoefficient>();

export const $increasingSearchTerm = increasingCoefficientDomain.createStore('');
export const $allIncreasingCoefficients = increasingCoefficientDomain.createStore<
    Array<ICoefficient>
>([]);

export const $filteredIncreasingCoefficients = increasingCoefficientDomain.createStore<
    Array<ICoefficient>
>([]);

export const $selectedIncreasingCoefficients = increasingCoefficientDomain.createStore<
    Array<ICoefficient>
>([]);

export const $increasingCoefficientTab = combine({
    loading: loadCoefficientsFx.pending,
    increasingCoefficients: $filteredIncreasingCoefficients,
    selectedIncreasingCoefficients: $selectedIncreasingCoefficients,
    increasingSearchTerm: $increasingSearchTerm,
});
