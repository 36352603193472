import {combine, createDomain, sample} from 'effector';

import {AdditionalAgreementFull} from 'pages/DSPage/types';
import {$isUserGpo} from 'shared/model/user';
import {EAdditionalAgreementType, IUpdatedAgreement} from 'shared/types/additionalAgreementsTypes';

import {
    $agreementId,
    $agreementOnRevision,
    $createAdditionalAgreementData,
    $lavParts,
    $signingDateIsEditable,
    $validConstructor,
    ConstructorGate,
    getAdditionalAgreementForConstructorFx,
    initialState,
    submitCreateAdditionalAgreement,
    updateAdditionalAgreementFx,
} from '../stores';
import {adaptAgreementToConstructor, getUpdatedAgreement} from '../../helpers';

export const updateAgreementDomain = createDomain();
export const updateAgreement = updateAgreementDomain.createEvent<AdditionalAgreementFull>();
export const createAgreementAddition = updateAgreementDomain.createEvent<AdditionalAgreementFull>();
export const setSigningDateAvailability =
    updateAgreementDomain.createEvent<AdditionalAgreementFull>();

export const $agreementBeforeUpdatesAndTransforms =
    updateAgreementDomain.createStore<IUpdatedAgreement>(initialState);
$agreementId.on(ConstructorGate.open, (state, {agreementId}) => agreementId);

// load agreement
sample({
    clock: ConstructorGate.open,
    source: $createAdditionalAgreementData,
    filter: (ds, {agreementId}) => !ds.project_id && !!agreementId,
    fn: (_, {agreementId}) => ({id: agreementId || ''}),
    target: getAdditionalAgreementForConstructorFx,
});

// save agreement to compare with updated later
$agreementBeforeUpdatesAndTransforms
    .on(updateAgreement, (state, payload) => adaptAgreementToConstructor(payload))
    .on(getAdditionalAgreementForConstructorFx.doneData, (state, {data}) =>
        adaptAgreementToConstructor(data),
    );

// adapt agreement to constructor
sample({
    clock: updateAgreement,
    fn: (data) => (data ? adaptAgreementToConstructor(data) : initialState),
    target: [$createAdditionalAgreementData, setSigningDateAvailability],
});

sample({
    clock: createAgreementAddition,
    fn: (data) =>
        data ? adaptAgreementToConstructor(data, EAdditionalAgreementType.Addition) : initialState,
    target: [$createAdditionalAgreementData, setSigningDateAvailability],
});

sample({
    clock: getAdditionalAgreementForConstructorFx.doneData,
    fn: ({data}) => adaptAgreementToConstructor(data),
    target: [$createAdditionalAgreementData, setSigningDateAvailability],
});

// check if editing is allowed
sample({
    clock: [ConstructorGate.open, $isUserGpo, getAdditionalAgreementForConstructorFx.doneData],
    source: combine({
        isGpo: $isUserGpo,
        ds: $createAdditionalAgreementData,
    }),
    filter: ({ds, isGpo}) => isGpo && !!ds.id,
    fn: ({ds, isGpo}) => {
        return isGpo && ds?.status?.name === 'На доработке';
    },
    target: $agreementOnRevision,
});

// редактирование ДС
sample({
    clock: submitCreateAdditionalAgreement,
    source: combine({
        agreementId: $agreementId,
        rawNewAgreement: $createAdditionalAgreementData,
        rawOldAgreement: $agreementBeforeUpdatesAndTransforms,
        valid: $validConstructor,
        lavParts: $lavParts,
    }),
    filter: ({valid, rawNewAgreement, agreementId}) =>
        valid && !!rawNewAgreement.status && !!agreementId,
    fn: ({rawNewAgreement, rawOldAgreement, lavParts}, {isDraft}) => {
        rawNewAgreement.meta = isDraft ? 'save_draft' : 'send_to_approve';
        let updatedAgreement = getUpdatedAgreement(rawNewAgreement, rawOldAgreement, lavParts);
        return {data: updatedAgreement, id: rawNewAgreement.id || ''};
    },
    target: updateAdditionalAgreementFx,
});

sample({
    clock: setSigningDateAvailability,
    fn: (agreement) => agreement.number_addendum === 1 && !agreement.parent_signing_date,
    target: $signingDateIsEditable,
});
