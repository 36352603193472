import React from 'react'
import { TabsBlockStyled, TabsBtnStyled, TabsBtnWrap } from 'shared/ui/deprecated/Tabs/style'

function TabsBtn(props) {
	const {
		title='Кнопка',
		value,
		activeTab,
		onClick,
		...rest
	} = props
	return (
		<TabsBtnStyled active={value === activeTab} onClick={() => onClick(value)} {...rest}>
			<span>{title}</span>
		</TabsBtnStyled>
	)
}

const TabsBlock = (props) =>{
	const{
		children,
		// active,
		value,
		activeTab,
		...rest
	} = props
	return(
		<TabsBlockStyled active={value === activeTab} {...rest}>{children}</TabsBlockStyled>
	)
}

export {
	TabsBtn,
	TabsBlock,
	TabsBtnWrap,
}